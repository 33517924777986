import React from 'react';
import axios from 'axios';
import Select from 'react-select';
import { Link } from 'react-router-dom';
// import Sidebar from "../../Component/Sidebar/Sidebar";
// import Header from "../../Component/Header/Header";
import Footer from '../../Component/Footer/Footer';
import global from '../../global';
import DatePicker from 'react-datepicker';
//import moment from "moment";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import './style.css';
var seller = {};
var productError = '';
var startDateError = '';
var endDateError = '';
var submitDisable = false;
var imageError = '';
var imageError2 = '';

var sendImage = '';
var sendImage2 = '';

toast.configure();
const CustomToast = () => {
  return (
    <div>
      <i class="fa fa-check success-toast"></i>
      Banner Created Successfully
    </div>
  );
};
class EditBanner extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: this.props.match.params.id,
      productId: '',
      bannerImage: '',
      bannerImage2: '',
      edate: false,
      sdate: false,
      products: [],
      startDate: '',
      endDate: '',
      bannerpricecharge: '',
      location: '',
      locationError: '',
    };
    imageError = '';
    imageError2 = '';
    submitDisable = false;
    productError = '';
  }
  componentWillMount() {
    if (localStorage.getItem('sellerdata')) {
      seller = JSON.parse(localStorage.getItem('sellerdata'));
      this.setState({ seller: JSON.parse(localStorage.getItem('sellerdata')) });
      axios
        .get(
          global.baseUrl + 'getaboutdetail',
          {},
          {
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
          }
        )
        .then((result) => {
          console.log(result.data.data[0], 'about');
          if (result.data.data[0]) {
            // if (result.data.data[0]) {
            this.setState({
              bannerpricecharge: result.data.data[0].sponcer_banner_price,
            });
            // this.setState({ a: "" });
            // }
          }
        });

      var urlencoded2 = new URLSearchParams();
      console.log(this.state.id, '===id');
      urlencoded2.append('id', this.state.id);
      axios
        .post(global.baseUrl + 'getbannerbyid', urlencoded2, {
          headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        })
        .then((result) => {
          console.log(result.data);
          if (result.data.data[0]) {
            //   this.setState({ bannerPages: result.data.data });
            this.setState({ sdate: true });
            this.setState({ edate: true });
            this.setState({ startDate: result.data.data[0].start_date });
            this.setState({ endDate: result.data.data[0].end_date });
            this.setState({
              bannerImage: result.data.data[0].banner_image_app,
            });
            this.setState({
              bannerImage2: result.data.data[0].banner_image_web,
            });
            this.setState({
              location: result.data.data[0].banner_location
                ? result.data.data[0].banner_location
                : '',
            });
            this.setState({ productId: result.data.data[0].productId });
            // this.setState({ Video: result.data.data[0].video });
            //localStorage.setItem('userdata', JSON.stringify(result.data))
            // this.props.history.push("/offerlist");
            //           startDate
            // endDate
            // bannerImage
            // bannerImage2
            // bannerLocation
            // Video
          }
        });
      //this.dashboardAPI(seller.id);
    }

    var urlencoded = new URLSearchParams();
    urlencoded.append('userId', seller.id);
    axios
      .post(global.baseUrl + 'sellerproductuserlist', urlencoded, {
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      })
      .then((result) => {
        if (result.data.status) {
          var option = [];
          option.push({
            label: 'Select',
            value: '',
          });
          for (let i = 0; i < result.data.data.length; i++) {
            option.push({
              label: result.data.data[i].product_name,
              value: result.data.data[i].id,
            });
          }
          this.setState({
            products: option,
          });
        }
      });
  }
  handleProductChange = (event) => {
    this.setState({ productId: event.value });
  };

  onImageChange = (event) => {
    console.log('image1');
    if (event.target.files && event.target.files[0]) {
      var Extension = ['jpg', 'jpeg', 'png', 'gif', 'tiff'];
      var extArray = event.target.files[0].name.split('.');
      var ext = extArray[extArray.length - 1];
      var flag = 0;
      for (let i = 0; i < Extension.length; i++) {
        if (ext.toLowerCase() === Extension[i]) {
          flag = 1;
        }
      }
      if (flag) {
        this.setState({ imagError: '' });
        imageError = '';

        let fileName = event.target.files[0].name;

        let reader = new FileReader();

        reader.onload = (e) => {
          var image = new Image();
          let flag = 1;
          image.src = e.target.result;
          image.onload = () => {
            var height = image.height;
            var width = image.width;
            console.log(height, width);
            if (width > 1280 && height > 590) {
              this.setState({
                imagError: 'Please select image(1280*590px)',
              });
              imageError = 'Please select image(1280*590px)';
              fileName = '';
              this.setState({
                bannerImage: '',
                fileName: '',
              });
            } else if (width < 1280 && height < 590) {
              this.setState({
                imagError: 'Please select image(1280*590px)',
              });
              imageError = 'Please select image(1280*590px)';
              fileName = '';
              this.setState({
                bannerImage: '',
                fileName: '',
              });
            } else {
              this.setState({
                bannerImage: e.target.result,
                fileName: fileName,
              });
            }
          };
        };

        reader.readAsDataURL(event.target.files[0]);
        sendImage = event.target.files[0];
        console.log('image', sendImage);
      } else {
        this.setState({
          bannerImage: '',
          imagError: 'Please select only image',
          fileName: '',
        });
        sendImage = '';
        imageError = 'Please select only image';
      }
    } else {
      sendImage = '';
    }
  };
  onImageChange2 = (event) => {
    console.log('image2');
    if (event.target.files && event.target.files[0]) {
      var Extension = ['jpg', 'jpeg', 'png', 'gif', 'tiff'];
      var extArray = event.target.files[0].name.split('.');
      var ext = extArray[extArray.length - 1];
      var flag = 0;
      for (let i = 0; i < Extension.length; i++) {
        if (ext.toLowerCase() === Extension[i]) {
          flag = 1;
        }
      }
      if (flag) {
        this.setState({ imagError2: '' });
        imageError2 = '';

        let fileName2 = event.target.files[0].name;

        let reader = new FileReader();

        reader.onload = (e) => {
          var image2 = new Image();
          let flag = 1;
          image2.src = e.target.result;
          image2.onload = () => {
            var height = image2.height;
            var width = image2.width;
            console.log(height, width);
            if (width > 1500 && height > 400) {
              this.setState({
                imagError2: 'Please select image(1500*400px)',
              });
              imageError2 = 'Please select image(1500*400px)';
              fileName2 = '';
              this.setState({
                bannerImage2: '',
                fileName2: '',
              });
            } else if (width < 1500 && height < 400) {
              this.setState({
                imagError2: 'Please select image(1500*400px)',
              });
              imageError2 = 'Please select image(1500*400px)';
              fileName2 = '';
              this.setState({
                bannerImage2: '',
                fileName2: '',
              });
            } else {
              this.setState({
                bannerImage2: e.target.result,
                fileName2: fileName2,
              });
            }
          };
        };

        reader.readAsDataURL(event.target.files[0]);
        sendImage2 = event.target.files[0];
        console.log('image', sendImage2);
      } else {
        this.setState({
          bannerImage2: '',
          imagError2: 'Please select only image',
          fileName2: '',
        });
        sendImage2 = '';
        imageError2 = 'Please select only image';
      }
    } else {
      sendImage2 = '';
    }
  };
  addImage2 = () => {
    console.log('addimgae2');
    if (sendImage2) {
      const apiUrl = global.baseUrl + 'sellerImageUpload';
      const formData = new FormData();
      formData.append('myFile', sendImage2);
      const config = {
        headers: {
          'content-type': 'multipart/form-data',
        },
      };
      axios
        .post(apiUrl, formData, config)
        .then((response) => {
          console.log('image add', response.data.path);
          this.setState({ bannerImage2: response.data.path });
          this.APICall();
        })
        .catch((error) => {});
    } else {
      this.APICall();
    }
  };
  APICall = () => {
    var urlencoded = new URLSearchParams();
    urlencoded.append('id', this.state.id);
    urlencoded.append('sellerId', this.state.seller.id);
    urlencoded.append('productId', this.state.productId);
    urlencoded.append('banner_image_app', this.state.bannerImage);
    urlencoded.append('banner_image_web', this.state.bannerImage2);
    urlencoded.append('start_date', this.state.startDate);
    urlencoded.append('location', this.state.location);
    urlencoded.append('end_date', this.state.endDate);
    let finaltotal = '';
    let time_difference =
      new Date(this.state.endDate).getTime() -
      new Date(this.state.startDate).getTime();
    let days_difference = time_difference / (1000 * 60 * 60 * 24);
    finaltotal =
      parseInt(days_difference) * parseInt(this.state.bannerpricecharge);
    console.log(finaltotal, 'final total');
    urlencoded.append('price', finaltotal);
    axios
      .post(global.baseUrl + 'neweditBannerRetailer', urlencoded, {
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      })
      .then((result) => {
        console.log(result.data, 'dataa');
        if (result.data) {
          toast.success(<CustomToast />, {
            position: toast.POSITION.TOP_RIGHT,
          });
          this.props.history.push('/bannerlist');
          //   this.props.history.push('/payperclickbannerlist');
        }
      });
    //localStorage.setItem('userdata', JSON.stringify(result.data))
    ///
  };

  addImage = () => {
    console.log('addimgae');

    if (sendImage) {
      const apiUrl = global.baseUrl + 'sellerImageUpload';
      const formData = new FormData();
      formData.append('myFile', sendImage);
      const config = {
        headers: {
          'content-type': 'multipart/form-data',
        },
      };
      axios
        .post(apiUrl, formData, config)
        .then((response) => {
          console.log('image add', response.data.filename);
          this.setState({ bannerImage: response.data.path });
          // this.APICall();
          this.addImage2();
        })
        .catch((error) => {});
    } else {
      this.addImage2();
    }
  };
  handleSubmit = (e) => {
    if (this.state.bannerImage === '') {
      this.setState({ imageError: 'Please select file' });
      imageError = 'Please select file';
    } else {
      this.setState({ imageError: '' });
      imageError = '';
    }
    if (this.state.bannerImage2 === '') {
      this.setState({ imageError2: 'Please select file' });
      imageError2 = 'Please select file';
    } else {
      this.setState({ imageError2: '' });
      imageError2 = '';
    }
    if (this.state.productId === '') {
      this.setState({ productError: 'Please select product' });
      productError = 'Please select product';
    } else {
      this.setState({ productError: '' });
      productError = '';
    }
    if (this.state.location === '') {
      this.setState({ locationError: 'Please select location' });
      // locationError = 'Please select product';
    } else {
      this.setState({ locationError: '' });
      // locationError = '';
    }

    if (
      this.state.productId &&
      this.state.bannerImage &&
      this.state.bannerImage2 &&
      this.state.locationError === '' &&
      productError === '' &&
      imageError === '' &&
      imageError2 === ''
    ) {
      submitDisable = true;
      this.addImage();
    }
  };

  //  resetHandler = () => {
  //     this.setState({
  //       percentage: "",
  //       startDate: "",
  //       endDate: "",
  //       minQty: "",
  //       maxQty: "",
  //       offerType: "",
  //       productId: "",
  //       categoryId: "",
  //       brandId: "",
  //       products: [],
  //       categories: [],
  //       brands: []
  //     });
  //     startDateError = "";
  //     endDateError = "";
  //     typeError = "";
  //     submitDisable = false;
  //   };

  handleFromDateChange = (date) => {
    this.setState({
      startDate: date,
    });
  };
  handleEndDateChange = (date) => {
    this.setState({
      endDate: date,
    });
  };

  setlocation = (e) => {
    this.setState({
      location: e.target.value,
    });
  };
  render() {
    return (
      <body
        class="vertical-layout vertical-menu-modern semi-dark-layout 2-columns  navbar-floating footer-static  "
        data-open="click"
        data-menu="vertical-menu-modern"
        data-col="2-columns"
        data-layout="semi-dark-layout"
      >
        {/* <Header />
        <Sidebar /> */}

        <div class="app-content content">
          <div class="content-overlay"></div>
          <div class="header-navbar-shadow"></div>
          <div class="content-wrapper">
            <div class="content-header row">
              <div class="col-12">
                <h2 class="content-header-title float-left mb-0">
                  <Link to={'/bannerlist'} className="back-arrow  mr-2">
                    <i className="feather icon-arrow-left"></i>
                  </Link>
                  Add Banner
                </h2>
                {/* <div class="breadcrumb-wrapper col-12">
                  <ol class="breadcrumb">
                    <li class="breadcrumb-item">
                      <Link to="/payperclickbannerlist">Pay Per Click</Link>
                    </li>
                    <li class="breadcrumb-item active">Add Banner</li>
                  </ol>
                </div> */}
              </div>
            </div>
            <div class="content-body">
              <section id="dashboard-analytics">
                <div class="card">
                  <div class="card-body">
                    <div
                      class="tab-pane active"
                      id="account"
                      aria-labelledby="account-tab"
                      role="tabpanel"
                    >
                      <div className="row mb-1">
                        <div className="col-12 col-lg-2">
                          <label className="b-label">
                            Start Date<span className="required-span1">*</span>
                          </label>
                        </div>
                        <div className="col-12 col-lg-4">
                          <div className="form-group sr-left  ma-left-minus">
                            {/* <DatePicker
                              selected={this.state.startDate}
                              onChange={this.handleFromDateChange}
                              showMonthDropdown
                              showYearDropdown
                              dropdownMode="select"
                              style={{ width: '100%' }}
                              className="form-control"
                              placeholderText="08/14/2021"
                            /> */}
                            {this.state.sdate === true ? (
                              <DatePicker
                                // selected={this.state.startDate}
                                value={new Date(
                                  this.state.startDate
                                ).toLocaleDateString()}
                                onChange={this.handleFromDateChange}
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                                style={{ width: '100%' }}
                                className="form-control"
                                placeholderText="08/14/2021"
                              />
                            ) : (
                              <DatePicker
                                selected={this.state.startDate}
                                onChange={this.handleFromDateChange}
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                                style={{ width: '100%' }}
                                className="form-control"
                                placeholderText="08/14/2021"
                              />
                            )}

                            <p className="error-msg">{startDateError} </p>
                          </div>
                        </div>
                        <div className="col-12 col-lg-2">
                          <label className="b-label">
                            End Date<span className="required-span1">*</span>
                          </label>
                        </div>
                        <div className="col-12 col-lg-4">
                          <div className="form-group sr-left  ma-left-minus">
                            {/* <DatePicker
                              selected={this.state.endDate}
                              onChange={this.handleEndDateChange}
                              showMonthDropdown
                              showYearDropdown
                              dropdownMode="select"
                              className="form-control"
                              placeholderText="08/15/2021"
                              style={{ width: '100% ' }}
                            /> */}
                            {this.state.edate === true ? (
                              <DatePicker
                                // selected={this.state.endDate}
                                // selected={moment(this.state.endDate, 'MM-DD-YYYY')}
                                // selected="10/15/2021"
                                // selected={this.state.endDate}
                                onChange={this.handleEndDateChange}
                                showMonthDropdown
                                showYearDropdown
                                value={new Date(
                                  this.state.endDate
                                ).toLocaleDateString()}
                                dropdownMode="select"
                                className="form-control"
                                placeholderText="08/15/2021"
                                style={{ width: '100% ' }}
                              />
                            ) : (
                              <DatePicker
                                // selected={this.state.endDate}
                                // selected={moment(this.state.endDate, 'MM-DD-YYYY')}
                                // selected="10/15/2021"
                                selected={this.state.endDate}
                                onChange={this.handleEndDateChange}
                                showMonthDropdown
                                showYearDropdown
                                // value={this.state.endDate}
                                dropdownMode="select"
                                className="form-control"
                                placeholderText="08/15/2021"
                                style={{ width: '100% ' }}
                              />
                            )}
                            <p className="error-msg">{endDateError} </p>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-12 col-xl-3">
                          <label className="b-label">
                            App banner upload
                            <span class="required-span1">*</span>
                          </label>
                        </div>
                        <div class="banner-upload-btn pb-2 col-12 col-lg-4">
                          <label
                            class="btn btn-sm btn-primary-green ml-30 mb-50 mb-sm-0 cursor-pointer up-btn"
                            for="account-upload"
                            style={{ padding: '10px' }}
                          >
                            Upload banner(1280*590px)
                          </label>
                          <input
                            type="file"
                            id="account-upload"
                            accept="image/*"
                            name="imageUpload"
                            onChange={this.onImageChange}
                            multiple
                            hidden
                          />
                        </div>
                        <div class="col-3 " style={{ marginTop: '5px' }}>
                          {this.state.fileName === undefined ||
                          this.state.fileName === '' ? (
                            // <p>{this.state.bannerImage.substr('http://3.141.68.71:5000/upload/'.length,this.state.bannerImage.length)}</p>
                            <img
                              style={{ height: '65px', width: '100px' }}
                              src={this.state.bannerImage}
                            ></img>
                          ) : (
                            <p>{this.state.fileName}</p>
                          )}
                          {/* <p>{this.state.fileName}</p> */}
                        </div>
                      </div>
                      <div class="row mb-1">
                        <div class="col-3 "></div>
                        <div>
                          <p class="error-msg up-banner-btn">{imageError}</p>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-12 col-xl-3">
                          <label className="b-label">
                            Web banner upload
                            <span class="required-span1">*</span>
                          </label>
                        </div>
                        <div class="banner-upload-btn pb-2 col-12 col-lg-4">
                          <label
                            class="btn btn-sm btn-primary-green ml-30 mb-50 mb-sm-0 cursor-pointer up-btn"
                            for="account-upload2"
                            style={{ padding: '10px' }}
                          >
                            Upload banner(1500*400px)
                          </label>
                          <input
                            type="file"
                            id="account-upload2"
                            accept="image/*"
                            name="imageUpload2"
                            onChange={this.onImageChange2}
                            multiple
                            hidden
                          />
                        </div>
                        <div class="col-3 " style={{ marginTop: '5px' }}>
                          {this.state.fileName2 === undefined ||
                          this.state.fileName2 === '' ? (
                            // <p>{this.state.bannerImage2.substr('/upload/'.length,this.state.bannerImage2.length)}</p>
                            <img
                              style={{ height: '65px', width: '100px' }}
                              src={this.state.bannerImage2}
                            ></img>
                          ) : (
                            <p>{this.state.fileName2}</p>
                          )}
                          {/* <p>{this.state.fileName2}</p> */}
                        </div>
                      </div>

                      <div class="row mb-1">
                        <div class="col-3 "></div>
                        <div>
                          <p class="error-msg up-banner-btn">{imageError2}</p>
                        </div>
                      </div>

                      <div class="row mb-1">
                        <div class="col-12 col-lg-3">
                          <label className="b-label">
                            Choose Product<span class="required-span1">*</span>
                          </label>
                        </div>
                        <div class="col-12 col-lg-4">
                          <Select
                            maxMenuHeight={150}
                            value={this.state.products.filter(
                              (option) => option.value === this.state.productId
                            )}
                            theme={(theme) => ({
                              ...theme,
                              borderRadius: '5px',
                              border: '0px solid',
                              colors: {
                                ...theme.colors,
                                text: 'orangered',
                                primary25: '#9cf59c',
                                primary: '#47A547',
                              },
                            })}
                            options={this.state.products}
                            name="category"
                            onChange={(val) => this.handleProductChange(val)}
                          />

                          <p class="error-msg">{productError}</p>
                        </div>
                      </div>
                      <div class="row mb-1">
                        <div class="col-12 col-lg-3">
                          <label className="b-label">
                            Banner Location<span class="required-span1">*</span>
                          </label>
                        </div>
                        <div class="col-12 col-lg-4">
                          <select
                            style={{
                              height: '41px',
                              width: '336px',
                              borderRadius: '6px',
                              borderColor: '#cccccc',
                              outlineWidth: '0px',
                            }}
                            onChange={(e) => {
                              this.setlocation(e);
                            }}
                            value={this.state.location}
                          >
                            <option value="Select Banner Location">
                              Select Banner Location
                            </option>
                            <option value="Top">Top</option>
                            <option value="Bottom">Bottom</option>
                          </select>

                          <p class="error-msg">{this.state.locationError}</p>
                        </div>
                      </div>

                      <div className="row">
                        <div
                          className="col-md-12 mt-1 "
                          style={{ overflow: 'auto' }}
                        >
                          <p
                            style={{
                              fontSize: '17px',
                              color: '#000',
                              fontWeight: '600',
                            }}
                          >{`Amount $ ${this.state.bannerpricecharge} per day`}</p>
                          <p
                            style={{
                              fontSize: '17px',
                              color: '#000',
                              fontWeight: '600',
                              whiteSpace: 'nowrap',
                            }}
                          >
                            We will write the guideline in description `{'>>'}`
                            Check garagelane guideline
                          </p>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-3"></div>
                        <div class="col-4  mt-1">
                          <button
                            type="submit"
                            disabled={submitDisable}
                            class="btn btn-primary glow mb-1 mb-sm-0 mr-0 mr-sm-1 login-btn"
                            onClick={this.handleSubmit}
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>

        <div class="sidenav-overlay"></div>
        <div class="drag-target"></div>
        <Footer />
      </body>
    );
  }
}
export default EditBanner;
