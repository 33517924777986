import React from 'react'
import { Link } from 'react-router-dom'
import './style.css'
import Avtar from "../images/avtar.jpg"
import axios from 'axios'
import global from '../../global'


var seller = {}
class ChatAdmin extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            seller: {
                id: ""
            },
            users: [],
            userlist: [],
            selectedUser: {},
            selectUser: false,
            message: "",
            userMessage: []
        }
    }
    componentDidMount() {
        if (localStorage.getItem("sellerdata")) {
            seller = JSON.parse(localStorage.getItem("sellerdata"));

            this.setState({ seller: JSON.parse(localStorage.getItem("sellerdata")) }, () => {

                this.getadminAPI()

            });
        }

    }

    handleSearchChange = e => {
        let search = e.target.value;
        let users = this.state.userlist;
        this.setState({ search: e.target.value });
        if (search) {
            console.log(search, "ssgdhjg");

            users = users.filter(order => {
                console.log(order)
                if (order.first_name) {
                    const first_name = order.first_name;
                    console.log("Working");
                    return first_name && first_name.toLowerCase().includes(search.toLowerCase());
                }
                else if (order.name) {
                    const name = order.name;
                    console.log("Working");
                    return name && name.toLowerCase().includes(search.toLowerCase());
                }
                else
                    return users

            });
            this.setState({ users: users });
        } else {
            this.setState({ users: this.state.userlist });
        }
    };
    // listAPI() {
    //     var urlencoded = new URLSearchParams();
    //     urlencoded.append("userId", this.state.seller.id);

    //     axios
    //         .post(global.baseUrl + "sellerfollowuserlist", urlencoded, {
    //             headers: { "Content-Type": "application/x-www-form-urlencoded" }
    //         })
    //         .then(result => {
    //             console.log(result.data, "result1");
    //             if (result.data.status) {
    //                 this.setState(
    //                     {
    //                         users: result.data.data,
    //                         userlist: result.data.data
    //                     },
    //                     () => {
    //                         console.log(this.state.users);
    //                     }
    //                 );
    //                 // result.data.data.forEach((element) => {

    //                 //     if (this.props.match.params.id === element.id) {
    //                 //         console.log("match")
    //                 //         this.onChatChange(element)
    //                 //     }
    //                 // })
    //                 this.onChatChange()
    //             }
    //         });
    // }
    getadminAPI() {
        var urlencoded = new URLSearchParams();
        urlencoded.append("id", "615ebff6833c954d3bf68089");

        axios
            .post(global.baseUrl + "adminIdList", urlencoded, {
                headers: { "Content-Type": "application/x-www-form-urlencoded" }
            })
            .then(result => {
                console.log(result.data, "result2");
                if (result.data.status) {
                    this.setState(
                        {
                            selectedUser: result.data.data,

                        },
                        () => {
                            console.log(this.state.users);
                        }
                    );
                    // result.data.data.forEach((element) => {

                    //     if (this.props.match.params.id === element.id) {
                    //         console.log("match")

                    // this.onBuyerChatChange()
                    this.onChatChange()
                    //     }
                    // })
                }
            });
    }
    onChatChange = () => {
        // this.setState({ selectedUser: user, selectUser: true, userMessage: [] })
        var urlencoded = new URLSearchParams();

        console.log(seller.id, "id")
        console.log(this.state.selectedUser.id)
        urlencoded.append("userId", seller.id);
        urlencoded.append("recieverId", this.state.selectedUser.id);

        axios
            .post(global.baseUrl + "SellerChatList", urlencoded, {
                headers: { "Content-Type": "application/x-www-form-urlencoded" }
            })
            .then(result => {
                console.log(result.data, "result chat");
                if (result.data.status) {
                    this.setState({ userMessage: result.data.data, message: "" })
                }
            });
    }
    onBuyerChatChange = () => {
        // this.setState({ selectedUser: user, selectUser: true, userMessage: [] })
        var urlencoded = new URLSearchParams();

        console.log(seller.id, "id")

        urlencoded.append("recieverId", this.state.selectedUser.id);
        urlencoded.append("userId", this.state.id);

        axios
            .post(global.baseUrl + "SellerChatList", urlencoded, {
                headers: { "Content-Type": "application/x-www-form-urlencoded" }
            })
            .then(result => {
                console.log(result.data, "result chat");
                if (result.data.status) {
                    this.setState({ userMessage: result.data.data, message: "" })
                }
            });
    }
    createChatAPI() {
        // console.log(this.state.selectedUser)
        var urlencoded = new URLSearchParams();
        urlencoded.append("senderId", this.state.seller.id);
        urlencoded.append("recieverId", this.state.selectedUser.id);
        urlencoded.append("message", this.state.message);

        axios
            .post(global.baseUrl + "SellerChatCreate", urlencoded, {
                headers: { "Content-Type": "application/x-www-form-urlencoded" }
            })
            .then(result => {
                console.log(result.data, "result");
                if (result.data.status) {
                    this.setState({ message: "" })
                    let userMessage = this.state.userMessage
                    userMessage.push(result.data.data[0])
                    this.setState({ userMessage })
                }
            });
    }
    onSendChange = (e) => {
        this.setState({ message: e.target.value })
    }
    render() {
        return (
            <body
                class="vertical-layout vertical-menu-modern semi-dark-layout 2-columns  navbar-floating footer-static  "
                data-open="click"
                data-menu="vertical-menu-modern"
                data-col="2-columns"
                data-layout="semi-dark-layout"
            >

                <div class="app-content content">
                    <div class="content-overlay"></div>
                    <div class="header-navbar-shadow"></div>
                    <div class="content-wrapper">
                        <div class="content-header-left col-md-9 col-12 mb-2">
                            <div class="row breadcrumbs-top">
                                <div class="col-12">
                                    <h2 class="content-header-title float-left mb-0">
                                        Chat
                                        </h2>
                                    <div class="breadcrumb-wrapper col-12">
                                        <ol class="breadcrumb">
                                            <li class="breadcrumb-item">
                                                <Link to="/dashboard">Dashboard</Link>
                                            </li>
                                            <li class="breadcrumb-item active">Chat With Admin</li>
                                        </ol>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="content-body">
                            <section id="dashboard-analytics">
                                <div class="card">
                                    <div class="card-body" style={{ padding: "0px" }}>
                                        <div>
                                            <div class="row">
                                                <div class="col lg-8">
                                                    {this.state.users ?
                                                        <div>
                                                            <div class="d-flex align-items-center chat-selected-user ml-0">

                                                                <div style={{ paddingLeft: "10px", paddingTop: "10px" }}>
                                                                    <div class="avatar" >

                                                                        {this.state.users.profile_image ?
                                                                            <img
                                                                                className="media-object rounded-circle"
                                                                                src={this.state.seller.profile_image}
                                                                                alt="user_avatar"
                                                                                height="40"
                                                                                width="40"
                                                                            /> :
                                                                            <img
                                                                            className="media-object rounded-circle"
                                                                                src={Avtar}
                                                                                alt="user_avatar"
                                                                                height="40"
                                                                                width="40"
                                                                            />}
                                                                        <span class="avatar-status-online"></span>
                                                                    </div>

                                                                    <div class="bullet-success bullet-sm position-absolute"></div>
                                                                </div>
                                                                <fieldset class=" form-group position-relative has-icon-left mx-1 my-0  w-100 chatusr-name pl-0">

                                                                    {this.state.users.name !== "" ? this.state.users.name : null}
                                                                    {this.state.users.first_name !== "" ? this.state.users.first_name : null}

                                                                    <p style={{ paddingTop: "15px", paddingLeft: "15px" }}>{this.state.selectedUser.name}</p>
                                                                </fieldset>
                                                            </div>
                                                            <div style={{ height: "280px" ,overflow:"auto"}} class="chat-box">
                                                                {this.state.userMessage.length ?
                                                                    this.state.userMessage.map((data, index) => (

                                                                        data.senderId === this.state.seller.id ?
                                                                            <p className="right-chat">{data.message}</p> :
                                                                            <p className="left-chat">{data.message}</p>

                                                                    ))
                                                                    : "No chat"
                                                                }
                                                            </div>
                                                            <fieldset class=" form-start col-lg-12 mb-2  position-relative has-icon-left mx-1 my-0 ml-2 w-100 chatusr-name" style={{ display: "flex" }}>
                                                                <input
                                                                    type="text"
                                                                    class="form-control round search1 chat-search"
                                                                    id="chat-search"
                                                                    placeholder="Enter message"
                                                                    value={this.state.message}
                                                                    name="message"
                                                                    onChange={this.onSendChange}
                                                                    style={{ width: "90%" }}
                                                                    onKeyPress={event => {if (event.key === 'Enter') {this.createChatAPI()}}}
                                                                />

                                                                <i class="fa fa-paper-plane" aria-hidden="true" style={{ padding: "10px", fontSize: "18px", cursor: "pointer" }} onClick={() => this.createChatAPI()}></i>
                                                            </fieldset>
                                                        </div>
                                                        : <div style={{ textAlign: "center", marginTop: "30%" }}>
                                                            <h4>No Chat</h4>
                                                        </div>}
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                </div>

            </body>
        )
    }
}
export default ChatAdmin