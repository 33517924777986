import React from 'react';
import axios from 'axios';
//import Select from "react-select";
import { Link } from 'react-router-dom';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
  Button,
} from 'reactstrap';
import Footer from '../../Component/Footer/Footer';
import global from '../../global';
//import Avtar from "./avtar.jpg";
import moment from 'moment';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import $ from 'jquery';
import Select from 'react-select';

import './style.css';
var seller = {};
var startDateError = '';
var endDateError = '';
var typeError = '';
var pageError = '';
var submitDisable = false;
var imageError = '';
var imageError2 = '';
var imageError3 = '';
var bannerLocationError = '';
var sizeError = '';
var sendImage = '';
var sendImage2 = '';
var sendImage3 = '';

toast.configure();
const CustomToast = () => {
  return (
    <div>
      <i class="fa fa-check success-toast"></i>
      Sponsor Banner Created Successfully
    </div>
  );
};
class AddBannerProduct extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pages: [''],
      selectedPage: [],
      rates: [''],
      bannerPageName: [],
      bannerPrice: [],
      bannerImage: '',
      bannerImage2: '',
      bannerImage3: '',
      bannerpricecharge: '',
      totalAmount: '',
      fileName: '',
      bannerPages: [],
      autoProductOptions: [],
      s: [],
      days: '',
      percentage: '',
      startDate: '',
      endDate: '',
      minQty: '',
      maxQty: '',
      bannerLocation: '',
      desiredLocation: '',
      offerType: '',
      productId: '',
      categoryId: '',
      brandId: '',
      products: [],
      categories: [],
      brands: [],
      banners: [],
      addOption: '',
      productlist: [],
      removeOption: '',
      addradio: 'Big',
      productLink: 0,
      value2: '',
      nextOptions: [],
      val: '',
      link: '',
      BannerLocations: [
        {
          label: 'Select Banner Location',
          value: '',
        },
        {
          label: 'Banner Picture 1st Page',
          value: 'Banner_Picture_1st_Page',
        },
        {
          label: 'Banner Picture 1st Page',
          value: 'Banner_Picture_1st_Page',
        },
        {
          label: 'Banner Picture 1st Page',
          value: 'Banner_Picture_1st_Page',
        },
        {
          label: 'Banner Picture 1st Page',
          value: 'Banner_Picture_1st_Page',
        },
        {
          label: 'Banner Picture 1st Page',
          value: 'Banner_Picture_1st_Page',
        },
        {
          label: 'Banner Picture 2nd Page',
          value: 'Banner_Picture_2nd_Page',
        },
        {
          label: 'Banner Video 1st Page',
          value: 'Banner_Video_1st_Page',
        },
        {
          label: 'Banner Bottom Page 1st Page',
          value: 'Banner_Bottom_1st_Page',
        },
        {
          label: 'Banner Bottom Page 1st Page',
          value: 'Banner_Bottom_1st_Page',
        },
      ],
      DesiredLocations: [
        {
          label: 'Select Banner Location',
          value: '',
        },
        {
          label: 'Top',
          value: 'Top',
        },
        {
          label: 'Bottom',
          value: 'Bottom',
        },
      ],
    };
    startDateError = '';
    endDateError = '';
    submitDisable = false;
    pageError = '';
    bannerLocationError = '';
    imageError = '';
    imageError2 = '';
    imageError3 = '';
    sizeError = '';
  }
  componentWillMount() {
    if (localStorage.getItem('sellerdata')) {
      seller = JSON.parse(localStorage.getItem('sellerdata'));
      this.setState({ seller: JSON.parse(localStorage.getItem('sellerdata')) });
    }
    axios
      .get(
        global.baseUrl + 'getaboutdetail',
        {},
        {
          headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        }
      )
      .then((result) => {
        console.log(result.data.data[0], 'about');
        if (result.data.data[0]) {
          // if (result.data.data[0]) {
          this.setState({
            bannerpricecharge: result.data.data[0].sponcer_product_price,
          });
          // this.setState({ a: "" });
          // }
        }
      });
    var urlencoded = new URLSearchParams();

    axios
      .post(global.baseUrl + 'sellerbannerpagelist', urlencoded, {
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      })
      .then((result) => {
        console.log(result.data);
        if (result.data.status) {
          this.setState({ bannerPages: result.data.data });
          //localStorage.setItem('userdata', JSON.stringify(result.data))
          // this.props.history.push("/offerlist");
        }
      });
    this.ProductListAPICall();
  }
  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value }, () => {
      this.setDays();
    });
  };
  OnChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };
  setDays = () => {
    let days = moment(this.state.endDate).diff(
      moment(this.state.startDate),
      'days'
    );
    this.setState({ days: days });
    let bannerPrice = this.state.bannerPrice;

    let total = 0;
    for (let j = 0; j < bannerPrice.length; j++) {
      total = total + parseInt(bannerPrice[j]);
    }
    total = total * (days + 1);
    this.setState({ total: total });
  };
  addPageHandler = () => {
    let pages = this.state.pages;
    pages.push('');
    this.setState({ pages: pages });
    let rates = this.state.rates;
    rates.push('');
    this.setState({ rates: rates });
  };
  handleBannerChange = (event, index) => {
    console.log(this.state.selectedPage, 'index');
    let flag = 1;
    let selectedPage = this.state.selectedPage;
    for (let i = 0; i < selectedPage.length; i++) {
      if (selectedPage[i] === event.target.value) {
        flag = 0;
        console.log('working');
      }
    }
    console.log(flag);
    let bannerPageName = this.state.bannerPageName;
    let bannerPrice = this.state.bannerPrice;

    if (flag === 1) {
      console.log('Working', flag);
      selectedPage[index] = event.target.value;
      bannerPageName = this.state.bannerPageName;
      bannerPrice = this.state.bannerPrice;
      for (let i = 0; i < this.state.bannerPages.length; i++) {
        if (event.target.value === this.state.bannerPages[i].id) {
          bannerPageName[index] = this.state.bannerPages[i].page;
          bannerPrice[index] = this.state.bannerPages[i].price;
        }
      }

      this.setState({
        selectedPage: selectedPage,
        bannerPageName: bannerPageName,
        bannerPrice: bannerPrice,
      });
    } else {
      selectedPage[index] = '';
      bannerPrice[index] = 0;
      this.setState({
        selectedPage: selectedPage,
      });
    }
    if (event.target.value === '') {
      bannerPrice[index] = 0;
    }
    console.log(bannerPrice);
    let total = 0;
    for (let j = 0; j < bannerPrice.length; j++) {
      total = total + parseInt(bannerPrice[j]);
    }
    total = total * (this.state.days + 1);
    this.setState({ total: total });
  };
  onImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      var Extension = ['jpg', 'jpeg', 'png', 'gif', 'tiff'];
      var extArray = event.target.files[0].name.split('.');
      var ext = extArray[extArray.length - 1];
      var flag = 0;
      for (let i = 0; i < Extension.length; i++) {
        if (ext.toLowerCase() === Extension[i]) {
          flag = 1;
        }
      }
      if (flag) {
        this.setState({ imagError: '' });
        imageError = '';

        let reader = new FileReader();
        reader.onload = (e) => {
          this.setState({
            bannerImage: e.target.result,
          });
        };
        this.setState({
          fileName: event.target.files[0].name,
        });
        reader.readAsDataURL(event.target.files[0]);
        sendImage = event.target.files[0];
        console.log('image', sendImage);
      } else {
        this.setState({
          bannerImage: '',
          imagError: 'Please select only image',
          fileName: '',
        });
        sendImage = '';
        imageError = 'Please select only image';
      }
    } else {
      sendImage = '';
    }
  };
  APICall = () => {
    console.log('API');
    var urlencoded = new URLSearchParams();
    urlencoded.append('sellerId', seller.id);
    urlencoded.append('start_date', this.state.startDate);
    urlencoded.append('end_date', this.state.endDate);
    urlencoded.append('banner_image_app', this.state.bannerImage);
    urlencoded.append('banner_image_web', this.state.bannerImage2);
    urlencoded.append('banner_location', this.state.bannerLocation);

    axios
      .post(global.baseUrl + 'newAddBanner', urlencoded, {
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      })
      .then((result) => {
        console.log(result.data, 'product submit');
        if (result.data) {
          this.setState({ banners: result.data.data }, () => {
            //   this.AddBannerAPI();
          });
          //localStorage.setItem('userdata', JSON.stringify(result.data))
          ///this.props.history.push("/offerlist");
        }
      });
  };

  AddBannerAPI() {
    let days = moment(this.state.endDate).diff(
      moment(this.state.startDate),
      'days'
    );
    for (let i = 0; i < this.state.selectedPage.length; i++) {
      if (this.state.selectedPage[i] !== '') {
        let urlencoded = new URLSearchParams();
        urlencoded.append('pageId', this.state.selectedPage[i]);
        urlencoded.append('bannerId', this.state.bannerId);
        urlencoded.append('sellerId', this.state.seller.id);
        urlencoded.append('days', parseInt(days) + 1);
        urlencoded.append('price', '0');

        axios
          .post(global.baseUrl + 'selleraddbanner', urlencoded, {
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
          })
          .then((result) => {
            console.log(result.data);
            if (result.data.status) {
              //this.AddBannerAPI()
              //localStorage.setItem('userdata', JSON.stringify(result.data))
              ///this.props.history.push("/offerlist");
            }
          });
      }
      console.log(i, this.state.selectedPage.length);
      if (i === this.state.selectedPage.length - 1) {
        toast.success(<CustomToast />, { position: toast.POSITION.TOP_RIGHT });

        this.props.history.push('/bannerlist');
      }
    }
  }
  addImage = () => {
    console.log('add image ');
    if (sendImage) {
      const apiUrl = global.baseUrl + 'sellerImageUpload';
      const formData = new FormData();
      formData.append('myFile', sendImage);
      const config = {
        headers: {
          'content-type': 'multipart/form-data',
        },
      };
      axios
        .post(apiUrl, formData, config)
        .then((response) => {
          console.log('image add', response.data.filename);
          this.setState({ bannerImage: response.data.path });
          this.addImage2();
        })
        .catch((error) => {});
    }
  };
  handleSubmit2 = (e) => {
    let numberCheck = /^[0-9]\d*(\.\d+)?$/;

    if (this.state.startDate === '') {
      this.setState({ startDateError: 'Please select start date' });
      startDateError = 'Please select start date';
    } else if (this.state.startDate.length > 10) {
      this.setState({ startDateError: 'Please select valid start date' });
      startDateError = 'Please select valid start date';
    } else {
      this.setState({ startDateError: '' });
      startDateError = '';
    }
    // if (this.state.endDate === "") {
    //   this.setState({ endDateError: "Please select end date" });
    //   endDateError = "Please select end date";
    // }
    // else if (this.state.endDate.length > 10) {
    //   this.setState({ endDateError: "Please select valid end date" });
    //   endDateError = "Please select valid end date";
    // }
    // else {
    //   if (Date.parse(this.state.endDate) < Date.parse(this.state.startDate)) {
    //     this.setState({ endDateError: "End date less then start date" });
    //     endDateError = "End date less then start date";
    //   } else {
    //     this.setState({ endDateError: "" });
    //     endDateError = "";
    //   }
    // }

    // if (this.state.bannerLocation === "") {
    //   this.setState({ bannerLocationError: "Please select banner location" });
    //   bannerLocationError = "Please select banner location";
    // } else {
    //   this.setState({ bannerLocationError: "" });
    //   bannerLocationError = "";
    // }
    if (this.state.addradio === '') {
      this.setState({ sizeError: 'Please select size' });
      sizeError = 'Please select size';
    } else {
      this.setState({ sizeError: '' });
      sizeError = '';
    }
    // if (this.state.selectedPage.length < 1) {
    //   this.setState({ pageError: "Please select page" });
    //   pageError = "Please select desired page";
    // } else if (this.state.selectedPage.length > 0) {
    //   let flag = 0;
    //   for (let i = 0; i < this.state.selectedPage.length; i++) {
    //     console.log(this.state.selectedPage[i], "djkgfhg");
    //     if (this.state.selectedPage[i]) {
    //       flag = 1;
    //     }
    //   }
    //   if (flag === 0) {
    //     this.setState({ pageError: "Please selects page" });
    //     pageError = "Please selects desired page";
    //   } else {
    //     this.setState({ pageError: "" });
    //     pageError = "";
    //   }
    // } else {
    //   this.setState({ pageError: "" });
    //   pageError = "";
    // }
    if (
      this.state.startDate &&
      // this.state.endDate &&
      // this.state.bannerLocation &&
      this.state.addradio &&
      startDateError === '' &&
      // endDateError === "" &&
      imageError3 === '' &&
      // bannerLocationError === "" &&
      sizeError === ''
    ) {
      submitDisable = true;
      this.addImage3();
    }
  };
  RadioTypeChange = (event) => {
    this.setState({
      addradio: parseInt(event.target.value),
    });
  };
  ProductRadioTypeChange = (event) => {
    this.setState({
      productLink: parseInt(event.target.value),
    });
    if (this.state.productLink === 2) {
      console.log('if');
      this.removeAllOptionHandler();
    } else {
      console.log('else');
      this.setState({
        bannerImage3: '',
      });
    }
  };
  resetHandler = () => {
    this.setState({
      percentage: '',
      startDate: '',
      endDate: '',
      minQty: '',
      maxQty: '',
      offerType: '',
      productId: '',
      categoryId: '',
      brandId: '',
      products: [],
      categories: [],
      brands: [],
    });
    startDateError = '';
    endDateError = '';
    typeError = '';
    pageError = '';
    imageError = '';
    submitDisable = false;
  };
  handleFromDateChange = (date) => {
    this.setState({
      startDate: date,
    });
  };
  handleEndDateChange = (date) => {
    this.setState({
      endDate: date,
    });
  };
  RemoveDropdownItem = (event) => {
    console.log('remoe');
    setTimeout(function () {
      $('option[value="banner_first"]:selected').attr('hidden', 'true');
      $('option[value="banner_two"]:selected').attr('hidden', 'true');
      $('option[value="banner_third"]:selected').attr('hidden', 'true');
      $('option[value="banner_four"]:selected').attr('hidden', 'true');
    }, 10);
  };
  onImageChange3 = (event) => {
    console.log('image3');
    if (event.target.files && event.target.files[0]) {
      var Extension = ['jpg', 'jpeg', 'png', 'gif', 'tiff'];
      var extArray = event.target.files[0].name.split('.');
      var ext = extArray[extArray.length - 1];
      var flag = 0;
      for (let i = 0; i < Extension.length; i++) {
        if (ext.toLowerCase() === Extension[i]) {
          flag = 1;
        }
      }
      if (flag) {
        this.setState({ imageError3: '' });
        imageError3 = '';

        let fileName3 = event.target.files[0].name;

        let reader = new FileReader();

        reader.onload = (e) => {
          var image3 = new Image();
          let flag = 1;
          image3.src = e.target.result;
          image3.onload = () => {
            var height = image3.height;
            var width = image3.width;
            console.log(height, width);
            if (width > 1500 && height > 400) {
              this.setState({
                imagError3: 'Please select image(1500*400px)',
              });
              imageError3 = 'Please select image(1500*400px)';
              fileName3 = '';
              this.setState({
                bannerImage3: '',
                fileName3: '',
              });
            } else if (width < 1500 && height < 400) {
              this.setState({
                imagError3: 'Please select image(1500*400px)',
              });
              imageError3 = 'Please select image(1500*400px)';
              fileName3 = '';
              this.setState({
                bannerImage3: '',
                fileName3: '',
              });
            } else {
              this.setState({
                bannerImage3: e.target.result,
                fileName3: fileName3,
              });
            }
          };
        };

        reader.readAsDataURL(event.target.files[0]);
        sendImage3 = event.target.files[0];
        console.log('image', sendImage3);
      } else {
        this.setState({
          bannerImage3: '',
          imagError3: 'Please select only image',
          fileName3: '',
        });
        sendImage3 = '';
        imageError3 = 'Please select only image';
      }
    } else {
      sendImage3 = '';
    }
  };

  addImage3 = () => {
    console.log('addimgae3');
    if (sendImage3) {
      const apiUrl = global.baseUrl + 'sellerImageUpload';
      const formData = new FormData();
      formData.append('myFile', sendImage3);
      const config = {
        headers: {
          'content-type': 'multipart/form-data',
        },
      };
      axios
        .post(apiUrl, formData, config)
        .then((response) => {
          console.log('image add3', response.data.path);
          this.setState({ bannerImage3: response.data.path });
          this.ProductAPICall();
        })
        .catch((error) => {});
    } else {
      this.ProductAPICall();
    }
  };
  BannerDropChangeHandler = async (event) => {
    this.setState({
      bannerLocation: event.target.value,
      // BannerLocations: this.state.BannerLocations.filter(item => item.value !== this.state.val)
    });
    let newValue = this.state.BannerLocations.filter(
      (item) => item.value === event.target.value
    );
    console.log(newValue);
    newValue[0].isDisable = true;
  };
  DesiredLocationDropChangeHandler = async (event) => {
    this.setState({
      desiredLocation: event.target.value,
    });
  };
  defaultOptionHandler = (e) => {
    console.log(e.target, 'select');
    console.log(e.target.value, 'select');
    this.setState({ addOption: e.target.value });
  };
  autoOptionHandler = (e) => {
    console.log('remove', e.target.value);
    this.setState({ removeOption: e.target.value });
  };
  ProductListAPICall = () => {
    console.log('Product API');
    let urlencoded = new URLSearchParams();
    urlencoded.append('sellerId', seller.id);

    axios
      .post(global.baseUrl + 'sellerProductList', urlencoded, {
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      })
      .then((result) => {
        console.log(result.data, 'product');
        if (result.data) {
          let productlist = this.state.productlist;
          let addOption = this.state.nextOptions;
          for (let i = 0; i < result.data.data.length; i++) {
            if (this.state.productlist) {
              productlist.push({
                id: result.data.data[i].id,
                label: result.data.data[i].product_name,
              });
            } else {
              this.state.nextOptions.push({
                id: result.data.data[i].id,
                label: result.data.data[i].product_name,
              });
            }
          }
          this.setState({
            productlist,
            addOption,
          });
        }
      });
  };
  addOptionHandler = () => {
    let addOption = '';
    let nextOption = '';
  
    console.log(this.state.addOption, 'check');
    if (this.state.addOption !== '') {
      this.setState({messageto:'Are you sure for product?'})
      this.setState({showLogOut: true})
      // let r = window.confirm('Are you sure for product?');
      // if (r) {
      //   let productlist = this.state.productlist;

      //   for (let i = 0; i < productlist.length; i++) {
      //     if (this.state.addOption === productlist[i].id) {
      //       console.log(addOption, 'addd');

      //       addOption = productlist[i];
      //       let urlencoded = new URLSearchParams();
      //       urlencoded.append('sellerId', seller.id);
      //       urlencoded.append('productId', productlist[i].id);

      //       axios
      //         .post(global.baseUrl + 'multiProductAdd', urlencoded, {
      //           headers: {
      //             'Content-Type': 'application/x-www-form-urlencoded',
      //           },
      //         })
      //         .then((result) => {
      //           console.log(result.data, 'amount');
      //           if (result.data.status) {
      //             //defaultOptions.splice(i, 1)
      //           }
      //         });
      //       if (i !== productlist.length - 1) {
      //         nextOption = productlist[i + 1].id;
      //       }
      //       console.log(nextOption, 'll');
      //       productlist.splice(i, 1);
      //     }
      //   }

      //   let productOption = this.state.productlist;
      //   this.state.nextOptions.push(addOption);

      //   this.setState({ addOption: this.state.nextOptions });
      //   this.setState({
      //     productlist,
      //   });
      // }
    } else {
      console.log('not working');
      // toast.warn(<WarnToast />, {
      //   position: toast.POSITION.TOP_RIGHT
      // });
    }
  };

  addOptionHandler2=()=>{
    let addOption = '';
    let nextOption = '';
    
    let productlist = this.state.productlist;

    for (let i = 0; i < productlist.length; i++) {
      if (this.state.addOption === productlist[i].id) {
        console.log(addOption, 'addd');

        addOption = productlist[i];
        let urlencoded = new URLSearchParams();
        urlencoded.append('sellerId', seller.id);
        urlencoded.append('productId', productlist[i].id);

        axios
          .post(global.baseUrl + 'multiProductAdd', urlencoded, {
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
            },
          })
          .then((result) => {
            console.log(result.data, 'amount');
            if (result.data.status) {
              //defaultOptions.splice(i, 1)
            }
          });
        if (i !== productlist.length - 1) {
          nextOption = productlist[i + 1].id;
        }
        console.log(nextOption, 'll');
        productlist.splice(i, 1);
      }
    }

    let productOption = this.state.productlist;
    this.state.nextOptions.push(addOption);

    this.setState({ addOption: this.state.nextOptions });
    this.setState({
      productlist,
    });
  }
  removeOptionHandler = () => {
    let removeOption = '';
    let nextOption = '';
    let lenthdata=this.state.nextOptions.length;
    if (this.state.removeOption !== '') {
      console.log('remove2', this.state.removeOption);
      let productOptions = this.state.nextOptions;
      console.log(productOptions, 'product');
      let defaultOptions = this.state.productlist;
      console.log(defaultOptions, 'product3254');
      let fdata = productOptions.filter((item) => {
        if (item.id !== this.state.removeOption) {
          return true;
        } else {
        }
        defaultOptions.push(item);
      });

      this.setState({
        defaultOptions,
        nextOptions: lenthdata===0?fdata:[],
      });
      // defaultOptions.push(this.state.nextOptions)
      // console.log(fdata, 'product32');
      // productOptions.map((item) => {
      //   console.log(item, 'loop');
      //   if (item.id == this.state.removeOption) {
      //     let check = productOptions.findIndex(
      //       (val) => val.id == this.state.removeOption
      //     );
      //     console.log('check satyam :::', check);
      //     productOptions.splice(check, 1);
      //     this.setState({ nextOptions: productOptions });
      //   }
      // });
      // this.setState({ nextOptions: fdata })
      let urlencoded = new URLSearchParams();
      urlencoded.append('sellerId', seller.id);
      // for (let i = 0; i < addOption.length; i++) {
      urlencoded.append('productId', this.state.removeOption);
      //   defaultOptions.push(addOption[i]);
      // }
      // urlencoded.append("renewal", "manual");
      axios
        .post(global.baseUrl + 'MultiProductRemove', urlencoded, {
          headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        })
        .then((result) => {
          console.log(result.data, 'remove');
          // this.ProductListAPICall()
          this.setState({
            defaultOptions,
            nextOptions: fdata,
          });
        });
      // for (let i = 0; i < productOptions.length; i++) {
      //  console.log(productOptions,"remove option")
      //   if (this.state.removeOption == productOptions[i].id) {
      //     removeOption = productOptions[i]
      //     let urlencoded = new URLSearchParams();
      //     urlencoded.append("sellerId", seller.id);
      //     urlencoded.append("productId", productOptions[i].id);
      //     // urlencoded.append("sponsoredproduct");
      //     axios
      //       .post(global.baseUrl + "MultiProductRemove", urlencoded, {
      //         headers: { "Content-Type": "application/x-www-form-urlencoded" }
      //       })
      //       .then(result => {
      //         console.log(result.data, "remove item");
      //         if (result.data) {
      //           // productOptions.splice(i, 1)

      //         }
      //       });
      //     if (i !== (productOptions.length - 1)) {
      //       nextOption = productOptions[i + 1].id

      //     }
      //     productOptions.splice(i, 1)
      //     console.log(productOptions, "option")
      //   } else {
      //     console.log("else")
      //   }
      // }
      // let temp = this.state.nextOptions
      // console.log(temp, "nextoptions....")
      // temp.push(removeOption)

      // this.setState({ removeOption: nextOption })
      // this.setState({
      //   temp
      // })
    }
  };
  addAllOptionHandler = () => {
    let defaultOptions = this.state.defaultOptions;
    let autoRenewalOptions = this.state.autoRenewalOptions;
    this.setState({messageto:'Are you sure for Allproduct?'})
    this.setState({showLogOut: true})
    // let total = defaultOptions.length * this.state.productCharge
    // if (total <= this.state.productCharge) {
    // let r = window.confirm('Are you sure for product?');
    // if (r) {
    //   let urlencoded = new URLSearchParams();
    //   urlencoded.append('sellerId', seller.id);
    //   for (let i = 0; i < this.state.productlist.length; i++) {
    //     urlencoded.append('productId', this.state.productlist[i].id);
    //     this.state.nextOptions.push(this.state.productlist[i]);
    //   }
    //   // urlencoded.append("renewal", "auto");
    //   axios
    //     .post(global.baseUrl + 'multiProductAdd', urlencoded, {
    //       headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    //     })
    //     .then((result) => {
    //       console.log(result.data, 'add all');
    //     });

    //   this.setState({
    //     productlist: [],
    //   });
    // }
    // }
    // else {
    // toast.warn(<WarnToast />, {
    //   position: toast.POSITION.TOP_RIGHT
    // });
    // }
  };

  addAllOptionHandler2=()=>{
    let urlencoded = new URLSearchParams();
    urlencoded.append('sellerId', seller.id);
    for (let i = 0; i < this.state.productlist.length; i++) {
      urlencoded.append('productId', this.state.productlist[i].id);
      this.state.nextOptions.push(this.state.productlist[i]);
    }
    // urlencoded.append("renewal", "auto");
    axios
      .post(global.baseUrl + 'multiProductAdd', urlencoded, {
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      })
      .then((result) => {
        console.log(result.data, 'add all');
      });

    this.setState({
      productlist: [],
    });
  }
  removeAllOptionHandler = () => {
    console.log('remove all');
    let defaultOptions = this.state.productlist;
    let addOption = this.state.addOption;

    let urlencoded = new URLSearchParams();
    urlencoded.append('sellerId', seller.id);
    for (let i = 0; i < addOption.length; i++) {
      urlencoded.append('productId', addOption[i].id);
      defaultOptions.push(addOption[i]);
    }
    // urlencoded.append("renewal", "manual");
    axios
      .post(global.baseUrl + 'MultiProductRemove', urlencoded, {
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      })
      .then((result) => {
        console.log(result.data, 'remove');
      });

    this.setState({
      defaultOptions,
      nextOptions: [],
    });
  };
  ProductAPICall = async () => {
    console.log('Product API', this.state.nextOptions[0]);
    let option = [];
    await this.state.nextOptions.map((element, index) => {
      option.push(element.id);
    });
    var urlencoded = new URLSearchParams();
    urlencoded.append('sellerId', seller.id);
    urlencoded.append('start_date', this.state.startDate);
    urlencoded.append('end_date', this.state.endDate);
    if (this.state.nextOptions) {
      urlencoded.append('productId', JSON.stringify(option));
    } else {
      urlencoded.append('productId', {});
    }
    urlencoded.append('desired_location', this.state.desiredLocation);
    if (this.state.link) {
      console.log('link');
      urlencoded.append('Link', this.state.link);
    } else {
      urlencoded.append('Link', '');
    }
    let finaltotal = '';
    let time_difference =
      this.state.endDate.getTime() - this.state.startDate.getTime();
    let days_difference = time_difference / (1000 * 60 * 60 * 24);
    finaltotal =
      parseInt(days_difference) * parseInt(this.state.bannerpricecharge);
    urlencoded.append('product_size', this.state.addradio);
    urlencoded.append('image', this.state.bannerImage3);
    urlencoded.append('price', finaltotal);

    axios
      .post(global.baseUrl + 'sponsoredAdvertise', urlencoded, {
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      })
      .then((result) => {
        if (result.data) {
          console.log(result.data, 'product result');
          this.setState({ SponsoredProducts: result.data.data });
        }
        this.props.history.push('/bannerlist');
      });
  };

  // componentWillReceiveProps = (nextProps) => {
  //   {this.setState({productlist:JSON.parse(this.state.productlist.split(/\s/).join(''))})}
  // }
  render() {
    return (
      <body
        class="vertical-layout vertical-menu-modern semi-dark-layout 2-columns  navbar-floating footer-static  "
        data-open="click"
        data-menu="vertical-menu-modern"
        data-col="2-columns"
        data-layout="semi-dark-layout"
      >
        {/* <Header />
        <Sidebar /> */}
        <div class="app-content content">
          <div class="content-overlay"></div>
          <div class="header-navbar-shadow"></div>
          <div class="content-wrapper">
            <div class="content-header-left col-md-9 col-12 mb-2">
              <div class="row breadcrumbs-top">
                <div class="col-12">
                <Modal isOpen={this.state.showLogOut} fade="false"  toggle={() => this.setState({showLogOut: false})}>
<ModalHeader toggle={() => this.setState({showLogOut: false})}>Warning</ModalHeader>
    <ModalBody>   
        <p>{this.state.messageto}</p>                     
    </ModalBody>
<ModalFooter>

    <Button onClick={() => this.setState({showLogOut: false})}>Cancel</Button>
    <Button color="info" onClick={()=>{this.setState({showLogOut: false,r:true});this.state.messageto.includes("Allproduct")?this.addAllOptionHandler2():this.addOptionHandler2();}}>Ok</Button>
</ModalFooter>
</Modal>
                  <h2 class="content-header-title float-left mb-0">
                    <Link to={'/bannerlist'} className="back-arrow  mr-2">
                      <i className="feather icon-arrow-left"></i>
                    </Link>
                    Add Banner
                  </h2>
                  <div class="breadcrumb-wrapper col-12">
                    <ol class="breadcrumb">
                      <li class="breadcrumb-item">
                        <Link to="/offerlist">Banners</Link>
                      </li>
                      <li class="breadcrumb-item active">Add Banner</li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>
            <div class="content-body">
              <section id="dashboard-analytics">
                <div class="card">
                  <div class="card-body">
                    <div class="product-secs">
                      <div class="row">
                        <div class="col-md-12">
                          <div class="Products-title">
                            <h2 class="">Product</h2>
                            <h5>
                              Your Product will show at the sponsor page for
                              15min. every hour till the end date.
                            </h5>
                          </div>
                        </div>
                      </div>
                      <div className="row mt-3">
                        <div class="col-12 col-lg-2">
                          <label className="b-label">
                            Start Date<span class="required-span1">*</span>
                          </label>
                        </div>
                        <div class="col-12 col-lg-4">
                          <div class="form-group sr-left  ma-left-minus">
                            <DatePicker
                              selected={this.state.startDate}
                              onChange={this.handleFromDateChange}
                              showMonthDropdown
                              showYearDropdown
                              dropdownMode="select"
                              style={{ width: '100%' }}
                              className="form-control"
                              placeholderText="08/14/2021"
                            />

                            <p class="error-msg">{startDateError} </p>
                          </div>
                        </div>
                        <div class="col-12 col-lg-2">
                          <label className="b-label">
                            End Date<span class="required-span1">*</span>
                          </label>
                        </div>
                        <div class="col-12 col-lg-4">
                          <div class="form-group sr-left  ma-left-minus">
                            <DatePicker
                              selected={this.state.endDate}
                              onChange={this.handleEndDateChange}
                              showMonthDropdown
                              showYearDropdown
                              dropdownMode="select"
                              className="form-control"
                              placeholderText="08/15/2021"
                              style={{ width: '100% ' }}
                            />
                            <p class="error-msg">{endDateError} </p>
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6">
                          <div className="form-group res-form-group">
                            <label
                              className="b-label"
                              style={{
                                whiteSpace: 'nowrap',
                                marginRight: '11px',
                              }}
                            >
                              Desired Location
                            </label>

                            <div style={{ display: 'block', width: '100%' }}>
                              <select
                                class="form-control"
                                id="selecter"
                                value={this.state.desiredLocation}
                                onChange={(e) =>
                                  this.DesiredLocationDropChangeHandler(e)
                                }
                              >
                                {this.state.DesiredLocations.map(
                                  (element, index) => (
                                    <option value={element.value}>
                                      {element.label}
                                    </option>
                                  )
                                )}
                              </select>
                              <p class="error-msg">{bannerLocationError} </p>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="row">
                        {this.state.productLink == 1 ? (
                          <>
                            <div
                              class="col-md-1"
                              style={{
                                whiteSpace: 'nowrap',
                                marginRight: '26px!important',
                              }}
                            >
                              <input
                                type="radio"
                                class="prod-radios"
                                id="productLink"
                                name="productLink"
                                value={1}
                                onChange={this.ProductRadioTypeChange}
                                checked={
                                  this.state.productLink === 1 ? true : false
                                }
                              />
                              <label
                                className="b-label"
                                style={{
                                  marginLeft: '38px',
                                  position: 'relative',
                                }}
                              >
                                Product
                              </label>
                            </div>
                            <div className="col-lg-12 col-md-12 mt-1 mb-2">
                              <div class="wallet-drop-area">
                                <div class="form-control wallet-drop-down select-text  option-select">
                                  {/* <select
                                    class="form-control wallet-drop-down option-select"
                                    size={5}
                                    onChange={(e) =>
                                      this.defaultOptionHandler(e)
                                    }
                                  >
                                    {this.state.productlist.map(
                                      (data, index) => (
                                        <option value={data.id} key={index}>
                                          {data.label}
                                        </option>
                                      )
                                    )}
                                  </select> */}
                                  {this.state.productlist.map((data, index) => (
                                    <div
                                      class="form-check"
                                      key={index}
                                      onChange={(e) =>
                                        this.defaultOptionHandler(e)
                                      }
                                    >
                                      <div className="input-product-div">
                                        <input
                                          class="form-check-input"
                                          type="radio"
                                          name="flexRadioDefault"
                                          id="flexRadioDefault1"
                                          value={data.id}
                                        />

                                        <label
                                          class="form-check-label"
                                          for="flexRadioDefault1"
                                        >
                                          {data.label}
                                        </label>
                                      </div>
                                    </div>
                                  ))}
                                </div>
                                <div class="wallet-move-btn wallet-mar">
                                  <button
                                    class="btn btn-outline-primary float-left btn-inline res-btn w-btn"
                                    onClick={() => this.addOptionHandler()}
                                  >
                                    Add
                                  </button>
                                  <button
                                    class="btn btn-outline-primary float-left btn-inline res-btn w-btn"
                                    onClick={() => this.addAllOptionHandler()}
                                  >
                                    Add All
                                  </button>
                                  <button
                                    class="btn btn-outline-primary float-left btn-inline res-btn w-btn"
                                    onClick={() => this.removeOptionHandler()}
                                  >
                                    Remove
                                  </button>
                                  <button
                                    class="btn btn-outline-primary float-left btn-inline res-btn w-btn"
                                    onClick={() =>
                                      this.removeAllOptionHandler()
                                    }
                                  >
                                    Remove All
                                  </button>
                                </div>
                                <div class="form-control select-text wallet-drop-down">
                                  {/* <select
                                    class="form-control wallet-drop-down"
                                    size={5}
                                    onChange={(e) => {
                                      this.autoOptionHandler(e);
                                    }}
                                  >
                                    {this.state.nextOptions.map(
                                      (data, index) => (
                                        <option value={data.id} key={index}>
                                          {data.label}
                                        </option>
                                      )
                                    )}
                                  </select> */}
                                  {this.state.nextOptions.map((data, index) => (
                                    <div
                                      class="form-check"
                                      key={index}
                                      onChange={(e) => {
                                        this.autoOptionHandler(e);
                                      }}
                                    >
                                      <div class="input-product-div ">
                                        <input
                                          class="form-check-input"
                                          type="radio"
                                          name="flexRadioDefault"
                                          id="flexRadioDefault1"
                                          value={data.id}
                                        />
                                        <label
                                          class="form-check-label"
                                          for="flexRadioDefault1"
                                        >
                                          {data.label}
                                        </label>
                                      </div>
                                    </div>
                                  ))}
                                </div>
                              </div>
                            </div>
                          </>
                        ) : (
                          <>
                            <div
                              class="col-md-1"
                              style={{
                                whiteSpace: 'nowrap',
                                marginRight: '38px',
                              }}
                            >
                              <input
                                type="radio"
                                class="prod-radios"
                                id="productLink"
                                name="productLink"
                                value={1}
                                onChange={this.ProductRadioTypeChange}
                                checked={
                                  this.state.productLink === 1 ? true : false
                                }
                              />
                              <label
                                className="b-label"
                                style={{
                                  marginLeft: '38px',
                                  position: 'relative',
                                }}
                              >
                                Product
                              </label>
                            </div>
                            <div className="col-lg-12 col-md-12 mt-1 mb-2">
                              <div class="wallet-drop-area">
                                <div class="form-control wallet-drop-down select-text option-select">
                                  {/* <select
                                    class="form-control wallet-drop-down option-select"
                                    size={5}
                                    onChange={(e) =>
                                      this.defaultOptionHandler(e)
                                    }
                                  >
                                    {this.state.productlist.map(
                                      (data, index) => (
                                        <option value={data.id} key={index}>
                                          {data.label}
                                        </option>
                                      )
                                    )}
                                  </select> */}
                                  
                                  {this.state.productlist.map((data, index) => (
                                    data.id&&
                                    <div
                                      class="form-check"
                                      key={index}
                                      onChange={(e) =>
                                        this.defaultOptionHandler(e)
                                      }
                                    >
                                      <div className="input-product-div">
                                        <input
                                          class="form-check-input"
                                          type="radio"
                                          name="flexRadioDefault"
                                          id="flexRadioDefault1"
                                          value={data.id}
                                        />

                                        <label
                                          class="form-check-label"
                                          for="flexRadioDefault1"
                                        >
                                          {data.label}
                                        </label>
                                      </div>
                                    </div>
                                  ))}
                                  {/* <div class="form-check">
  <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1"/>
  <label class="form-check-label" for="flexRadioDefault1">
    Default radio
  </label>
</div>
<div class="form-check">
  <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" checked/>
  <label class="form-check-label" for="flexRadioDefault2">
    Default checked radio
  </label>
</div> */}
                                </div>
                                <div class="wallet-move-btn wallet-mar">
                                  <button
                                    disabled={true}
                                    class="btn btn-outline-primary float-left btn-inline res-btn w-btn"
                                    onClick={() => this.addOptionHandler()}
                                  >
                                    Add
                                  </button>
                                  <button
                                    disabled={true}
                                    class="btn btn-outline-primary float-left btn-inline res-btn w-btn"
                                    onClick={() => this.addAllOptionHandler()}
                                  >
                                    Add All
                                  </button>
                                  <button
                                    disabled={true}
                                    class="btn btn-outline-primary float-left btn-inline res-btn w-btn"
                                    onClick={() => this.removeOptionHandler()}
                                  >
                                    Remove
                                  </button>
                                  <button
                                    disabled={true}
                                    class="btn btn-outline-primary float-left btn-inline res-btn w-btn"
                                    onClick={() =>
                                      this.removeAllOptionHandler()
                                    }
                                  >
                                    Remove All
                                  </button>
                                </div>
                                <div class="form-control select-text wallet-drop-down">
                                  {/* <select
                                    class="form-control wallet-drop-down"
                                    size={5}
                                    onChange={(e) => {
                                      this.autoOptionHandler(e);
                                    }}
                                  >
                                    {this.state.nextOptions.map(
                                      (data, index) => (
                                        <option value={data.id} key={index}>
                                          {data.label}
                                        </option>
                                      )
                                    )}
                                  </select> */}
                                  {this.state.nextOptions.map((data, index) => (
                                    <div
                                      class="form-check"
                                      key={index}
                                      onChange={(e) => {
                                        this.autoOptionHandler(e);
                                      }}
                                    >
                                      <div className="input-product-div ">
                                        <input
                                          class="form-check-input"
                                          type="radio"
                                          name="flexRadioDefault"
                                          id="flexRadioDefault1"
                                          value={data.id}
                                        />
                                        <label
                                          class="form-check-label"
                                          for="flexRadioDefault1"
                                        >
                                          {data.label}
                                        </label>
                                      </div>
                                    </div>
                                  ))}
                                </div>
                              </div>
                            </div>
                          </>
                        )}
                        {this.state.productLink == 2 ? (
                          <>
                            <div
                              class="col-12 col-xl-2 col-md-4 max-single pb-2"
                              style={{
                                display: 'inline-flex',
                                alignItems: 'center',
                                marginLeft: '-7px',
                              }}
                            >
                              <input
                                type="radio"
                                class="file-radios"
                                id="productLink"
                                name="productLink"
                                value={2}
                                onChange={this.ProductRadioTypeChange}
                                checked={
                                  this.state.productLink === 2 ? true : false
                                }
                              />

                              <label
                                className="b-label"
                                style={{
                                  marginLeft: '38px',
                                  position: 'relative',
                                  whiteSpace: 'nowrap',
                                }}
                              >
                                Single file Upload
                                {/* <span class="required-span1">*</span> */}
                              </label>
                            </div>

                            <div class="product-upload-btn pb-2 col-12 col-md-5 col-xl-7">
                              <label
                                class="btn btn-sm btn-primary-green  mb-50 mb-sm-0 cursor-pointer up-btn ml-sm-1"
                                for="account-upload3"
                              >
                                Upload Single file
                              </label>

                              <input
                                type="file"
                                id="account-upload3"
                                accept="image/*"
                                name="imageUpload"
                                onChange={this.onImageChange3}
                                multiple
                                hidden
                              />
                              <p
                                class="error-msg"
                                style={{ paddingLeft: '10px' }}
                              >
                                {imageError3}
                              </p>
                            </div>
                            <div class="col-3 " style={{ marginTop: '5px' }}>
                              <p>{this.state.fileName3}</p>
                            </div>

                            <div
                              class="col-lg-1 col-md-1"
                              style={{
                                marginRight: '68px',
                                marginLeft: '30px',
                              }}
                            >
                              <label class="b-label">Link</label>
                            </div>
                            <div class="col-lg-6 col-md-10">
                              <div class="form-group mb-1">
                                <input
                                  type="text"
                                  class="form-control"
                                  name="link"
                                  value={this.state.link}
                                  onChange={this.OnChange}
                                  placeholder="Enter the link"
                                />
                              </div>
                              <label class="notes-pro">
                                (Use this when you don't have product to sell)
                                Ex. store If you want to show only your website.
                              </label>
                            </div>
                          </>
                        ) : (
                          <>
                            <div className="d-flex upload-product-file">
                              <div
                                className="col-12 col-xl-2 col-md-4 max-single pb-2"
                                style={{
                                  display: 'inline-flex',
                                  alignItems: 'center',
                                  marginLeft: '-7px',
                                }}
                              >
                                <input
                                  type="radio"
                                  className="file-radios"
                                  id="productLink"
                                  name="productLink"
                                  value={2}
                                  onChange={this.ProductRadioTypeChange}
                                  checked={
                                    this.state.productLink === 2 ? true : false
                                  }
                                  style={{
                                    verticalAlign: 'baseline',
                                    marginTop: '6px',
                                  }}
                                />

                                <label
                                  className="b-label"
                                  style={{
                                    marginLeft: '38px',
                                    position: 'relative',
                                    whiteSpace: 'nowrap',
                                  }}
                                >
                                  Single file Upload
                                  {/* <span class="required-span1">*</span> */}
                                </label>
                              </div>

                              <div class="product-upload-btn pb-2 col-12 col-md-5 col-xl-7">
                                <label
                                  class="btn btn-sm btn-primary-green  mb-50 mb-sm-0 cursor-pointer up-btn ml-20"
                                  for="account-upload3"
                                >
                                  Upload Single file
                                </label>

                                <input
                                  type="file"
                                  id="account-upload3"
                                  accept="image/*"
                                  name="imageUpload"
                                  onChange={this.onImageChange3}
                                  multiple
                                  disabled={true}
                                  hidden
                                />
                                <p
                                  class="error-msg"
                                  style={{ paddingLeft: '10px' }}
                                >
                                  {imageError3}
                                </p>
                              </div>
                              <div class="col-3 " style={{ marginTop: '5px' }}>
                                <p>{this.state.fileName3}</p>
                              </div>
                            </div>

                            <div className="d-flex overflow-auto">
                              <div class="col-lg-1 col-md-1 link-textbox">
                                <label class="b-label">Link</label>
                              </div>

                              <div class="col-lg-6 col-md-8 col-12 ml-29">
                                <div class="form-group mb-1">
                                  <input
                                    type="text"
                                    class="form-control"
                                    name="link"
                                    value={this.state.link}
                                    onChange={this.OnChange}
                                    placeholder="Enter the link"
                                    disabled={true}
                                  />
                                </div>
                                <label class="notes-pro">
                                  (Use this when you don't have product to sell)
                                  Ex. store If you want to show only your
                                  website.
                                </label>
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                    </div>

                    <div class="row mt-2 overflows-res">
                      <div class="col-md-12 d-flex overflow-auto">
                        <label
                          class=""
                          style={{
                            marginLeft: '30px',
                            fontWeight: '500',
                            fontSize: '15px',
                            whiteSpace: 'nowrap',
                          }}
                        >
                          Please Choose Size
                        </label>
                        <div
                          class="d-flex"
                          style={{ marginLeft: '19px', marginTop: '6px' }}
                        >
                          <input
                            type="radio"
                            id="addradio"
                            name="addradio"
                            value={1}
                            onChange={this.RadioTypeChange}
                            checked={this.state.addradio === 1 ? true : false}
                          ></input>
                          <div class="div-length1 mr-3 ml-1"></div>
                        </div>
                        <div class="d-flex" style={{ marginTop: '6px' }}>
                          <input
                            type="radio"
                            id="addradio"
                            name="addradio"
                            value={2}
                            onChange={this.RadioTypeChange}
                            checked={this.state.addradio === 2 ? true : false}
                          ></input>

                          <div>
                            <div class="div-length2 mr-2 ml-1"></div>
                            <p class="your-banner-text">
                              (Your banner will not run until they are reviewed
                              by GaragaLane. we review most ads within 3
                              business days)
                            </p>
                          </div>
                        </div>
                        <p class="error-msg">{sizeError} </p>
                      </div>
                    </div>
                    <div className="row">
                      <div
                        className="col-md-12 mt-1 "
                        style={{ overflow: 'auto' }}
                      >
                        <p
                          style={{
                            fontSize: '17px',
                            color: '#000',
                            fontWeight: '600',
                          }}
                        >{`Amount $ ${this.state.bannerpricecharge} per day`}</p>
                        <p
                          style={{
                            fontSize: '17px',
                            color: '#000',
                            fontWeight: '600',
                            whiteSpace: 'nowrap',
                          }}
                        >
                          We will write the guideline in description `{'>>'}`
                          Check garagelane guideline
                        </p>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-12 mt-2" style={{ textAlign: 'end' }}>
                        <button
                          class="btn btn-primary add-product-link"
                          type="submit"
                          // disabled={submitDisable}
                          // class="btn btn-primary glow mb-1 mb-sm-0 mr-0 mr-sm-1 login-btn"
                          onClick={this.handleSubmit2}
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>

        <div class="sidenav-overlay"></div>
        <div class="drag-target"></div>
        <Footer />
      </body>
    );
  }
}
export default AddBannerProduct;
