import React from "react";
import axios from "axios";
import StarRatings from "react-star-ratings";
import Footer from "../../Component/Footer/Footer";
import global from "../../global";
import "./Product.css";
const $ = require("jquery");
$.DataTable = require("datatables.net");

class ViewProduct extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            id: this.props.match.params.id,
            product: {},
            colorSize: [],
            reviews: [],
            activeTab: "1",
            photoIndex: 0,
            isOpen: false
        };
    }

    componentWillMount() {
        let user = {}
        if (localStorage.getItem('sellerdata')) {
            user = JSON.parse(localStorage.getItem("sellerdata"))
            var urlencoded = new URLSearchParams();
            urlencoded.append("sellerId", user.id);
            axios
                .post(global.baseUrl + "SellerAllProductReview", urlencoded, {
                    headers: { "Content-Type": "application/x-www-form-urlencoded" }
                })
                .then(result => {
                    if (result.data.status) {
                        this.setState({ reviews: result.data.data });
                    }
                    this.dataTable()
                });
        }
    }
    dataTable() {
        $(document).ready(function () {
            $("#list").DataTable();
        });
    }
    render() {
        return (
            <body
                class="vertical-layout vertical-menu-modern semi-dark-layout 2-columns  navbar-floating footer-static  "
                data-open="click"
                data-menu="vertical-menu-modern"
                data-col="2-columns"
                data-layout="semi-dark-layout"
            >
                {/* <Header />
        <Sidebar /> */}

                <div class="app-content content" style={{ minHeight: "91vh" }}>
                    <div class="content-overlay"></div>
                    <div class="header-navbar-shadow"></div>
                    <div class="content-wrapper">
                        <div class="content-header row">
                            <div class="content-header-left col-md-9 col-12 mb-2">
                                <div class="row breadcrumbs-top">
                                    <div class="col-12">
                                        <h2 class="content-header-title float-left mb-0">
                                            Products Review
                                        </h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="content-body">
                            <section class="users-edit">
                                <div class="card">
                                    <div class="card-content">
                                        <div class="card-body">
                                            <div class="col-md-12 col-12 ">
                                                <div class="card" >
                                                    <div class="card-header">
                                                        <div class="card-title mb-2">Total Reviews</div>
                                                    </div>
                                                    <div class="card-body" style={{ paddingLeft: "0px", paddingRight: "0px" }}>
                                                        <div style={{ overflow: "auto" }}>
                                                            <table id="list">
                                                                <thead>
                                                                    <tr>
                                                                        <th style={{ minWidth: "50px" }}>Id</th>
                                                                        <th style={{ minWidth: "170px" }}>User Name</th>
                                                                        <th style={{ minWidth: "190px" }}>Product Name</th>

                                                                        <th style={{ minWidth: "210px" }}>Communication To Seller</th>
                                                                        <th style={{ minWidth: "190px" }}>Product As Described</th>
                                                                        <th style={{ minWidth: "150px" }}>Shipping Delivery</th>
                                                                        <th style={{ minWidth: "140px" }}>Shipping Charges</th>
                                                                        <th style={{ minWidth: "200px" }}>Review Headline</th>
                                                                        <th style={{ minWidth: "250px" }}>Description</th>

                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {this.state.reviews.map((review, index) => (

                                                                        <tr key={index}>
                                                                            <td>{index + 1}</td>
                                                                            <td>
                                                                                {review.userId
                                                                                    ? review.userId.name
                                                                                    : null}
                                                                            </td>
                                                                            <td>
                                                                                {review.productId
                                                                                    ? review.productId.product_name
                                                                                    : null}
                                                                            </td>
                                                                            <td>
                                                                                <StarRatings
                                                                                    rating={parseFloat(
                                                                                        review.communicationToSeller
                                                                                    )}
                                                                                    starDimension="15px"
                                                                                    starSpacing="3px"
                                                                                />
                                                                                <span className="r-box">({parseFloat(
                                                                                    review.communicationToSeller
                                                                                ) * 5})</span>
                                                                                {/* {review.rating} */}
                                                                            </td>
                                                                            <td>
                                                                                <StarRatings
                                                                                    rating={parseFloat(
                                                                                        review.productAsDescribed
                                                                                    )}
                                                                                    starDimension="15px"
                                                                                    starSpacing="3px"
                                                                                />
                                                                                <span className="r-box">({parseFloat(
                                                                                    review.productAsDescribed
                                                                                ) * 5})</span>
                                                                                {/* {review.size} */}
                                                                            </td>
                                                                            <td>
                                                                                <StarRatings
                                                                                    rating={parseFloat(
                                                                                        review.shippingDelivery
                                                                                    )}
                                                                                    starDimension="15px"
                                                                                    starSpacing="3px"
                                                                                />
                                                                                <span className="r-box">({parseFloat(
                                                                                    review.shippingDelivery
                                                                                ) * 5})</span>
                                                                                {/* {review.value} */}
                                                                            </td>
                                                                            <td>
                                                                                <StarRatings
                                                                                    rating={parseFloat(
                                                                                        review.shippingCharges
                                                                                    )}
                                                                                    starDimension="15px"
                                                                                    starSpacing="3px"
                                                                                />
                                                                                <span className="r-box">({parseFloat(
                                                                                    review.shippingCharges
                                                                                ) * 5})</span>
                                                                            </td>

                                                                            <td>{review.review_headline}</td>
                                                                            <td>
                                                                                {review.review_description}
                                                                            </td>
                                                                        </tr>
                                                                    ))}
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                </div>

                <div class="sidenav-overlay"></div>
                <div class="drag-target"></div>
                <Footer />
            </body>
        );
    }
}
export default ViewProduct;
