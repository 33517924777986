import React from "react";
import axios from "axios";
import Select from "react-select";
import { Link } from "react-router-dom";
// import Sidebar from "../../Component/Sidebar/Sidebar";
// import Header from "../../Component/Header/Header";
import Footer from "../../Component/Footer/Footer";
import global from "../../global";
import moment from "moment";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import "./style.css";
var seller = {};
var startDateError = "";
var endDateError = "";
var submitDisable = false;
var imageError = "";
var sendImage = "";
var adsTypeError = "";
var categoryError = "";
var budgetError = "";
var dataTypeError = "";
var type = "";
toast.configure();
const CustomToast = () => {
  return (
    <div>
      <i class="fa fa-check success-toast"></i>
      Live stream Created Successfully
    </div>
  );
};
class AddBanner extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fileName: "",
      adsType: "",
      startDate: "",
      endDate: "",
      categories: [],
      bannerImage: "",
      categoryId: "",
      budget: "",
      dataType: ""
    };
    startDateError = "";
    endDateError = "";
    submitDisable = false;
    sendImage = "";
    adsTypeError = "";
    categoryError = "";
    budgetError = "";
    dataTypeError = "";
    type = "";
  }
  componentWillMount() {
    if (localStorage.getItem("sellerdata")) {
      seller = JSON.parse(localStorage.getItem("sellerdata"));
      this.setState({ seller: JSON.parse(localStorage.getItem("sellerdata")) });
      //this.dashboardAPI(seller.id);
    }
    this.listAPI();
  }
  listAPI() {
    var urlencoded = new URLSearchParams();

    axios
      .post(global.baseUrl + "sellercategorylist", urlencoded, {
        headers: { "Content-Type": "application/x-www-form-urlencoded" }
      })
      .then(result => {
        console.log(result.data);
        if (result.data.status) {
          var option = [];
          option.push({
            label: "Select Category",
            value: ""
          });
          for (let i = 0; i < result.data.data.length; i++) {
            option.push({
              label: result.data.data[i].name,
              value: result.data.data[i].id
            });
          }
          this.setState({
            categories: option
          });
        }
      });

    let urlencoded1 = new URLSearchParams();

    axios
      .post(global.baseUrl + "sellerbrandlist", urlencoded1, {
        headers: { "Content-Type": "application/x-www-form-urlencoded" }
      })
      .then(result => {
        console.log(result.data);
        if (result.data.status) {
          var option = [];
          option.push({
            label: "Select Brand",
            value: ""
          });
          for (let i = 0; i < result.data.data.length; i++) {
            option.push({
              label: result.data.data[i].name,
              value: result.data.data[i].id
            });
          }
          this.setState({
            brands: option
          });
        }
      });
  }
  handleChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };
  setDays = () => {
    let days = moment(this.state.endDate).diff(
      moment(this.state.startDate),
      "days"
    );
    this.setState({ days: days });
    let bannerPrice = this.state.bannerPrice;

    let total = 0;
    for (let j = 0; j < bannerPrice.length; j++) {
      total = total + parseInt(bannerPrice[j]);
    }
    total = total * (days + 1);
    this.setState({ total: total });
  };
  dropChangeHandler = (event) => {
    this.setState({ categoryId: event.value })
  }
  addPageHandler = () => {
    let pages = this.state.pages;
    pages.push("");
    this.setState({ pages: pages });
    let rates = this.state.rates;
    rates.push("");
    this.setState({ rates: rates });
  };
  handleBannerChange = (event, index) => {
    console.log(this.state.selectedPage, "index");
    let flag = 1;
    let selectedPage = this.state.selectedPage;
    for (let i = 0; i < selectedPage.length; i++) {
      if (selectedPage[i] === event.target.value) {
        flag = 0;
        console.log("working");
      }
    }
    console.log(flag);
    let bannerPageName = this.state.bannerPageName;
    let bannerPrice = this.state.bannerPrice;

    if (flag === 1) {
      console.log("Working", flag);
      selectedPage[index] = event.target.value;
      bannerPageName = this.state.bannerPageName;
      bannerPrice = this.state.bannerPrice;
      for (let i = 0; i < this.state.bannerPages.length; i++) {
        if (event.target.value === this.state.bannerPages[i].id) {
          bannerPageName[index] = this.state.bannerPages[i].page;
          bannerPrice[index] = this.state.bannerPages[i].price;
        }
      }

      this.setState({
        selectedPage: selectedPage,
        bannerPageName: bannerPageName,
        bannerPrice: bannerPrice
      });
    } else {
      selectedPage[index] = "";
      bannerPrice[index] = 0;
      this.setState({
        selectedPage: selectedPage
      });
    }
    if (event.target.value === "") {
      bannerPrice[index] = 0;
    }
    console.log(bannerPrice);
    let total = 0;
    for (let j = 0; j < bannerPrice.length; j++) {
      total = total + parseInt(bannerPrice[j]);
    }
    total = total * (this.state.days + 1);
    this.setState({ total: total });
  };
  onImageChange = event => {
    if (event.target.files && event.target.files[0]) {
      type = ""
      this.setState({ a: "" })
      if (event.target.files[0].size / (1024 * 1024) <= 5) {
        if (event.target.files[0].type === "video/mp4") {
          let file = event.target.files[0];
          let reader = new FileReader();
          reader.onload = () => {
            var media = new Audio(reader.result);
            let fileName = "";
            media.onloadedmetadata = () => {
              // console.log("Working,1",media)
              if (
                parseInt(media.duration) === 5 ||
                parseInt(media.duration) === 15 ||
                parseInt(media.duration) === 30
              ) {
                sendImage = file;
                type = "video"

                this.setState({
                  fileName: file.name,
                  dataType: ""

                });
              } else {
                imageError = "Video should be 5,15 or 30 secs.";
                sendImage = "";
                this.setState({
                  fileName: "",

                });
              }
            };
          };
          reader.readAsDataURL(event.target.files[0]);
          this.setState({ a: "" });
        } else {
          type = "";
          var Extension = ["jpg", "jpeg", "png", "gif", "tiff"];
          var extArray = event.target.files[0].name.split(".");
          var ext = extArray[extArray.length - 1];
          var flag = 0;
          for (let i = 0; i < Extension.length; i++) {
            if (ext.toLowerCase() === Extension[i]) {
              flag = 1;
            }
          }
          if (flag) {
            this.setState({ imagError: "" });
            imageError = "";

            let reader = new FileReader();
            reader.onload = e => { };
            this.setState({
              fileName: event.target.files[0].name
            });
            reader.readAsDataURL(event.target.files[0]);
            sendImage = event.target.files[0];
            console.log("image", sendImage);
          } else {
            this.setState({
              bannerImage: "",
              imagError: "Please select only image",
              fileName: ""
            });
            sendImage = "";
            imageError = "Please select only image or  mp4 video";
          }
        }
      } else {
        sendImage = "";
        imageError = "File should less then 5 MB";
        this.setState({ fileName: "" });
      }
    } else {
      sendImage = "";
      this.setState({ fileName: "" });
    }
  };
  APICall = () => {
    var urlencoded = new URLSearchParams();

    urlencoded.append("sellerId", this.state.seller.id);
    urlencoded.append("ads_type", this.state.adsType);
    urlencoded.append("start_date", this.state.startDate);
    urlencoded.append("end_date", this.state.endDate);
    urlencoded.append("video_file", this.state.bannerImage);
    urlencoded.append("page_category", this.state.categoryId);
    urlencoded.append("budget", this.state.budget);
    urlencoded.append("data_type", this.state.dataType);

    axios
      .post(global.baseUrl + "SellerAddVideoAds", urlencoded, {
        headers: { "Content-Type": "application/x-www-form-urlencoded" }
      })
      .then(result => {
        console.log(result.data);
        if (result.data.status) {
          toast.success(<CustomToast />, { position: toast.POSITION.TOP_RIGHT })

          //localStorage.setItem('userdata', JSON.stringify(result.data))
          this.props.history.push("/adslist");
        }
      });
  };
  addImage = () => {
    if (sendImage) {
      const apiUrl = global.baseUrl + "sellerImageUpload";
      const formData = new FormData();
      formData.append("myFile", sendImage);
      const config = {
        headers: {
          "content-type": "multipart/form-data"
        }
      };
      axios
        .post(apiUrl, formData, config)
        .then(response => {
          console.log("image add", response.data.filename);
          this.setState({ bannerImage: response.data.path });
          this.APICall();
        })
        .catch(error => { });
    }
  };
  handleSubmit = e => {
    let numberCheck = /^[0-9]\d*(\.\d+)?$/;
    if (this.state.startDate === "") {
      this.setState({ startDateError: "Please select start date" });
      startDateError = "Please select start date";
    } 
    // else if (this.state.startDate.length > 10) {
    //   this.setState({ startDateError: "Please select valid start date" });
    //   startDateError = "Please select valid start date";
    // } 
    else {
      this.setState({ startDateError: "" });
      startDateError = "";
    }
    if (this.state.endDate === "") {
      this.setState({ endDateError: "Please select end date" });
      endDateError = "Please select end date";
    } 
    // else if (this.state.endDate.length > 10) {
    //   this.setState({ endDateError: "Please select valid end date" });
    //   endDateError = "Please select valid end date";
    // }
     else {
      if (Date.parse(this.state.endDate) < Date.parse(this.state.startDate)) {
        this.setState({ endDateError: "End date less then start date" });
        endDateError = "End date less then start date";
      } else {
        this.setState({ endDateError: "" });
        endDateError = "";
      }
    }
    if (this.state.sendImage === "") {
      this.setState({ imageError: "Please select file" });
      imageError = "Please select file";
    } else {
      this.setState({ imageError: "" });
      imageError = "";
    }
    if (this.state.adsType === "") {
      this.setState({ adsTypeError: "Please select adsType" });
      adsTypeError = "Please select adsType";
    } else {
      this.setState({ adsTypeError: "" });
      adsTypeError = "";
    }
    if (this.state.budget === "") {
      this.setState({ budgetError: "Please set budget" });
      budgetError = "Please set budget";
    }
    else if (!numberCheck.test(this.state.budget)) {
      this.setState({ budgetError: "Please set valid budget" });
      budgetError = "Please set valid budget";
    }
    else {
      this.setState({ budgetError: "" });
      budgetError = "";
    }
    if (this.state.categoryId === "") {
      this.setState({ categoryError: "Please select category" })
      categoryError = "Please select category"
    }
    else {
      this.setState({ categoryError: "" })
      categoryError = ""
    }
    if (this.state.dataType === "") {
      this.setState({ dataTypeError: "Please select type" })
      dataTypeError = "Please select type"
    }
    else {
      this.setState({ dataTypeError: "" })
      dataTypeError = ""
    }
    if (
      this.state.startDate &&
      this.state.endDate &&
      sendImage &&
      this.state.adsType &&
      this.state.budget &&
      this.state.dataType &&
      this.state.categoryId &&
      imageError === "" &&
      adsTypeError === "" &&
      dataTypeError === "" &&
      budgetError === "" &&
      startDateError === "" &&
      endDateError === "" &&
      imageError === "" &&
      categoryError === ""
    ) {
      submitDisable = true;
      this.addImage();
    }
  };
  resetHandler = () => {
    this.setState({
      fileName: "",
      adsType: "",
      startDate: "",
      endDate: "",
      bannerImage: "",
      categoryId: "",
      budget: "",
      dataType: ""
    })
    startDateError = "";
    endDateError = "";
    submitDisable = false;
    sendImage = "";
    adsTypeError = "";
    categoryError = "";
    budgetError = "";
    dataTypeError = "";
    type = "";
  };
  handleFromDateChange = date => {
    this.setState({
      startDate: date
    })
  };
  handleEndDateChange = date => {
    this.setState({
      endDate: date
    });
  };
  render() {
    return (
      <body
        class="vertical-layout vertical-menu-modern semi-dark-layout 2-columns  navbar-floating footer-static  "
        data-open="click"
        data-menu="vertical-menu-modern"
        data-col="2-columns"
        data-layout="semi-dark-layout"
      >
        {/* <Header />
        <Sidebar /> */}
        <div class="app-content content">
          <div class="content-overlay"></div>
          <div class="header-navbar-shadow"></div>
          <div class="content-wrapper">
            <div class="content-header-left col-md-9 col-12 mb-2">
              <div class="row breadcrumbs-top">
                <div class="col-12">
                  <h2 class="content-header-title float-left mb-0">
                  <Link to={"/adslist"} className="back-arrow  mr-2"><i className="feather icon-arrow-left"></i></Link> 
                    
                    Add Live stream Ads
                  </h2>
                  <div class="breadcrumb-wrapper col-12">
                    <ol class="breadcrumb">
                      <li class="breadcrumb-item">
                        <Link to="/offerlist">Live stream Advertise</Link>
                      </li>
                      <li class="breadcrumb-item active">Add Live stream Ads</li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>
            <div class="content-body">
              <section id="dashboard-analytics">
                <div class="card">
                  <div class="card-body">
                    <div
                      class="tab-pane active"
                      id="account"
                      aria-labelledby="account-tab"
                      role="tabpanel"
                    >
                      <div class="row mb-1">
                        <div class="col-xl-2"></div>
                        <div class="col-lg-10 col-12">
                          {" "}
                          <b>
                            Ads can be run up to 30 Days.Up to 30 second
                            ads.Please make sure your ads is 6,15 or 30 seconds.{" "}
                          </b>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-2">
                          <label className="b-label white-space">Types Of Ads<span class="required-span">*</span></label>
                        </div>
                        <div class="col-md-10">
                          <ul class="list-unstyled mb-0 ">
                            <li class="tax-li">
                              <fieldset>
                                <div class="vs-radio-con ">
                                  <input
                                    type="radio"
                                    name="adsType"
                                    value="image"
                                    onChange={this.handleChange}
                                    checked={
                                      this.state.adsType === "image"
                                        ? true
                                        : false
                                    }
                                  />
                                  <span class="vs-radio">
                                    <span class="vs-radio--border"></span>
                                    <span class="vs-radio--circle"></span>
                                  </span>
                                </div>
                              </fieldset>{" "}
                              <span
                                style={{
                                  marginRight: "5px",
                                  marginTop: "2px"
                                }}
                              >
                                <b>IMAGE ADS</b>
                              </span>{" "}
                            </li>

                            <p>
                              Image ads are static image ads that display under
                              the content. Image ads help you earn money from
                              video that don't have a good place for mid-roll
                              ads ,such a comedy skits.
                            </p>
                            <li class="tax-li">
                              <fieldset>
                                <div class="vs-radio-con">
                                  <input
                                    type="radio"
                                    name="adsType"
                                    value="preroll"
                                    onChange={this.handleChange}
                                    checked={
                                      this.state.adsType === "preroll"
                                        ? true
                                        : false
                                    }
                                  />
                                  <span class="vs-radio">
                                    <span class="vs-radio--border"></span>
                                    <span class="vs-radio--circle"></span>
                                  </span>
                                </div>
                              </fieldset>{" "}
                              <span
                                style={{
                                  marginRight: "5px",
                                  marginTop: "2px"
                                }}
                              >
                                <b>PRE-ROLL ADS</b>
                              </span>{" "}
                            </li>
                            <p>
                              Pre-roll ads play before your video starts.These
                              are shown to people who actively seek out the
                              content .The more payouts you receive.
                            </p>
                          </ul>
                          <p class="error-msg">{adsTypeError}</p>
                        </div>
                      </div>
                      <div class="row mb-1">
                        <div class="col-12 col-lg-2">
                          <label className="b-label">Start Date<span class="required-span">*</span></label>
                        </div>
                        <div class="col-12 col-lg-4">
                          <div class="form-group sr-left">
                            {/* <input
                              type="date"
                              class="form-control"
                              placeholder="Start date"
                              name="startDate"
                              value={this.state.startDate}
                              onChange={this.handleChange}
                              maxLength="10"
                            /> */}
                            <DatePicker
                              selected={this.state.startDate}
                              onChange={this.handleFromDateChange}
                              showMonthDropdown
                              showYearDropdown
                              dropdownMode="select"
                              style={{ width: "100%" }}
                              className="form-control"

                            />
                            <p class="error-msg">{startDateError} </p>
                          </div>
                        </div>
                        <div class="col-12 col-lg-2">
                          <label className="b-label">End Date<span class="required-span">*</span></label>
                        </div>
                        <div class="col-12 col-lg-4">
                          <div class="form-group  sr-left">
                            {/* <input
                              type="date"
                              class="form-control"
                              placeholder="End date"
                              name="endDate"
                              value={this.state.endDate}
                              onChange={this.handleChange}
                              maxLength="10"
                            /> */}
                            <DatePicker
                              selected={this.state.endDate}
                              onChange={this.handleEndDateChange}
                              showMonthDropdown
                              showYearDropdown
                              dropdownMode="select"
                              className="form-control"

                              style={{ width: "100% " }}

                            />
                            <p class="error-msg">{endDateError} </p>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-12 col-xl-2">
                          <label className="b-label" style={{whiteSpace: "nowrap"}}>Single file Upload<span class="required-span">*</span></label>
                        </div>
                        <div class="product-upload-btn pb-2 col-12 col-md-4">
                          <div>
                            <label
                              class="btn btn-sm btn-primary-green ml-50 mb-50 mb-sm-0 cursor-pointer up-btn"
                              for="account-upload"
                            >
                              Upload Single file
                            </label>
                            <input
                              type="file"
                              id="account-upload"
                              name="imageUpload"
                              onChange={this.onImageChange}
                              hidden
                            />

                          </div>
                          <p style={{ marginLeft: "10px" }}>{this.state.fileName}</p>
                          <p
                            class="error-msg"
                            style={{ paddingLeft: "10px" }}
                          >
                            {imageError}
                          </p>
                        </div>
                        {/* <div class="col-1 f-name">
                          
                        </div> */}

                        <div class="col-12 col-lg-2">
                          <label className="b-label">Set Budget<span class="required-span">*</span></label>
                        </div>
                        <div class="col-12 col-lg-4">
                          <input
                            type="text"
                            class="form-control"
                            name="budget"
                            placeholder="Set Budget "
                            value={this.state.budget}
                            onChange={this.handleChange}
                          />
                          <p class="error-msg">{budgetError}</p>
                        </div>
                      </div>
                      <div class="row mb-1">
                        <div class="col-12 col-lg-2">
                          <label className="b-label">Landing Page<span class="required-span">*</span></label>
                        </div>
                        <div
                          class="col-12 col-lg-4"
                          style={{ height: "200px" }}
                        >
                          <ul class="list-unstyled mb-0 tax-li">
                            <li class="tax-li">
                              <fieldset>
                                <div class="vs-radio-con ">
                                  <input
                                    type="radio"
                                    name="dataType"
                                    value="recorded"
                                    onChange={this.handleChange}
                                    checked={
                                      this.state.dataType === "recorded"
                                        ? true
                                        : false
                                    }
                                  />
                                  <span class="vs-radio">
                                    <span class="vs-radio--border"></span>
                                    <span class="vs-radio--circle"></span>
                                  </span>
                                </div>
                              </fieldset>{" "}
                              <span
                                style={{
                                  marginRight: "5px",
                                  marginTop: "2px"
                                }}
                              >
                                Recorded
                              </span>{" "}
                            </li>

                            <li class="tax-li">
                              <fieldset>
                                <div class="vs-radio-con">
                                  <input
                                    type="radio"
                                    name="dataType"
                                    value={type === "" ? "livestream" : ""}
                                    onChange={this.handleChange}
                                    checked={
                                      this.state.dataType === "livestream"
                                        ? true
                                        : false
                                    }
                                  />
                                  <span class="vs-radio">
                                    <span class="vs-radio--border"></span>
                                    <span class="vs-radio--circle"></span>
                                  </span>
                                </div>
                              </fieldset>{" "}
                              <span
                                style={{
                                  marginRight: "5px",
                                  marginTop: "2px"
                                }}
                              >
                                LiveStream (Image only)
                              </span>{" "}
                            </li>
                          </ul>

                          <p class="error-msg">{dataTypeError}</p>
                          <div class="form-group sr-left">
                            <Select
                              value={this.state.categories.filter(
                                option => option.value === this.state.categoryId
                              )}
                              theme={theme => ({
                                ...theme,
                                borderRadius: "5px",
                                border: "0px solid",
                                colors: {
                                  ...theme.colors,
                                  text: "orangered",
                                  primary25: "#9cf59c",
                                  primary: "#47A547"
                                }
                              })}
                              options={this.state.categories}
                              name="category"
                              onChange={val =>
                                this.dropChangeHandler(val)
                              }
                            />
                          </div>

                          <p class="error-msg">{categoryError}</p>
                        </div>
                      </div>
                      <p>
                        (Your banner will not run until they are reviewed by
                        GaragaLane.We review most ads within 3 business days)
                      </p>
                      <div class="row">
                        <div class="col-12 d-flex flex-sm-row flex-column justify-content-end mt-1">
                          <button
                            type="submit"
                            disabled={submitDisable}
                            class="btn btn-primary glow mb-1 mb-sm-0 mr-0 mr-sm-1 login-btn"
                            onClick={this.handleSubmit}
                          >
                            Submit
                          </button>
                          <button
                            type="reset"
                            onClick={this.resetHandler}
                            class="btn btn-outline-primary float-left btn-inline res-btn"
                          >
                            Reset
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>

        <div class="sidenav-overlay"></div>
        <div class="drag-target"></div>
        <Footer />
      </body>
    );
  }
}
export default AddBanner;
