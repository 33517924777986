import React from "react";

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      seller: ""
    };
    if (localStorage.getItem("sellerdata")) {
      this.state = {
        seller: JSON.parse(localStorage.getItem("sellerdata"))
      };
    }
  }
  componentWillMount() {
    if (localStorage.getItem("sellerdata")) {
      this.setState({ seller: JSON.parse(localStorage.getItem("sellerdata")) });
    }
  }
  render() {
    return <>{this.props.children}</>;
  }
}
export default App;
