import React from "react";
import axios from "axios";
import Select from "react-select";
import { Link } from "react-router-dom";
// import Sidebar from "../../Component/Sidebar/Sidebar";
// import Header from "../../Component/Header/Header";
import Footer from "../../Component/Footer/Footer";
import global from "../../global";
//import moment from "moment";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import "./style.css";
var seller = {};
var productError = "";
var submitDisable = false;
var imageError = "";
var imageError2 = "";

var sendImage = "";
var sendImage2 = "";

toast.configure();
const CustomToast = () => {
  return (
    <div>
      <i class="fa fa-check success-toast"></i>
      Banner Created Successfully
    </div>
  );
};
class AddBanner extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      productId: "",
      bannerImage: "",
      bannerImage2: "",
      products: []
    };
    imageError = "";
    imageError2 = "";
    submitDisable = false;
    productError = "";
  }
  componentWillMount() {
    if (localStorage.getItem("sellerdata")) {
      seller = JSON.parse(localStorage.getItem("sellerdata"));
      this.setState({ seller: JSON.parse(localStorage.getItem("sellerdata")) });
      //this.dashboardAPI(seller.id);
    }

    var urlencoded = new URLSearchParams();
    urlencoded.append("userId", seller.id);
    axios
      .post(global.baseUrl + "sellerproductuserlist", urlencoded, {
        headers: { "Content-Type": "application/x-www-form-urlencoded" }
      })
      .then(result => {
        if (result.data.status) {
          var option = [];
          option.push({
            label: "Select",
            value: ""
          });
          for (let i = 0; i < result.data.data.length; i++) {
            option.push({
              label: result.data.data[i].product_name,
              value: result.data.data[i].id
            });
          }
          this.setState({
            products: option
          });
        }
      });
  }
  handleProductChange = event => {
    this.setState({ productId: event.value });
  };
  
  onImageChange = event => {
    console.log("image1")
    if (event.target.files && event.target.files[0]) {
      var Extension = ["jpg", "jpeg", "png", "gif", "tiff"];
      var extArray = event.target.files[0].name.split(".");
      var ext = extArray[extArray.length - 1];
      var flag = 0;
      for (let i = 0; i < Extension.length; i++) {
        if (ext.toLowerCase() === Extension[i]) {
          flag = 1;
        }
      }
      if (flag) {
        this.setState({ imagError: "" });
        imageError = "";

        let fileName = event.target.files[0].name;

        let reader = new FileReader();

        reader.onload = e => {
          var image = new Image();
          let flag = 1;
          image.src = e.target.result;
          image.onload = () => {
            var height = image.height;
            var width = image.width;
            console.log(height, width);
            if (width > 1280 && height > 590) {
              this.setState({
                imagError: "Please select image(1280*590px)"
              });
              imageError = "Please select image(1280*590px)";
              fileName = "";
              this.setState({
                bannerImage: "",
                fileName: ""
              });
            } else if (width < 1280 && height < 590) {
              this.setState({
                imagError: "Please select image(1280*590px)"
              });
              imageError = "Please select image(1280*590px)";
              fileName = "";
              this.setState({
                bannerImage: "",
                fileName: ""
              });
              
            }else {
              this.setState({
                bannerImage: e.target.result,
                fileName: fileName
              });
            }
          };
        };

        reader.readAsDataURL(event.target.files[0]);
        sendImage = event.target.files[0];
        console.log("image", sendImage);
      } else {
        this.setState({
          bannerImage: "",
          imagError: "Please select only image",
          fileName: ""
        });
        sendImage = "";
        imageError = "Please select only image";
      }
    } else {
      sendImage = "";
    }
  };
  onImageChange2 = event => {
    console.log("image2")
    if (event.target.files && event.target.files[0]) {
      var Extension = ["jpg", "jpeg", "png", "gif", "tiff"];
      var extArray = event.target.files[0].name.split(".");
      var ext = extArray[extArray.length - 1];
      var flag = 0;
      for (let i = 0; i < Extension.length; i++) {
        if (ext.toLowerCase() === Extension[i]) {
          flag = 1;
        }
      }
      if (flag) {
        this.setState({ imagError2: "" });
        imageError2 = "";

        let fileName2 = event.target.files[0].name;

        let reader = new FileReader();

        reader.onload = e => {
          var image2 = new Image();
          let flag = 1;
          image2.src = e.target.result;
          image2.onload = () => {
            var height = image2.height;
            var width = image2.width;
            console.log(height, width);
            if (width > 1500 && height > 400) {
              this.setState({
                imagError2: "Please select image(1500*400px)"
              });
              imageError2 = "Please select image(1500*400px)";
              fileName2 = "";
              this.setState({
                bannerImage2: "",
                fileName2: ""
              });
            } else if (width < 1500 && height < 400) {
              this.setState({
                imagError2: "Please select image(1500*400px)"
              });
              imageError2 = "Please select image(1500*400px)";
              fileName2 = "";
              this.setState({
                bannerImage2: "",
                fileName2: ""
              });
              
            } else {
              this.setState({
                bannerImage2: e.target.result,
                fileName2: fileName2
              });
            }
          };
        };

    
        reader.readAsDataURL(event.target.files[0]);
        sendImage2 = event.target.files[0];
        console.log("image", sendImage2);
      } else {
        this.setState({
          bannerImage2: "",
          imagError2: "Please select only image",
          fileName2: ""
        });
        sendImage2 = "";
        imageError2 = "Please select only image";
      }
    } else {
      sendImage2 = "";
    }
  };
  addImage2 = () => {
    console.log("addimgae2")
    if (sendImage2) {
      const apiUrl = global.baseUrl + "sellerImageUpload";
      const formData = new FormData();
      formData.append("myFile", sendImage2);
      const config = {
        headers: {
          "content-type": "multipart/form-data"
        }
      };
      axios
        .post(apiUrl, formData, config)
        .then(response => {
          console.log("image add", response.data.path);
          this.setState({ bannerImage2: response.data.path });
          this.APICall();
        })
        .catch(error => {});
    }
  };
  APICall = () => {
    var urlencoded = new URLSearchParams();
    urlencoded.append("sellerId", this.state.seller.id);
    urlencoded.append("productId", this.state.productId);
    urlencoded.append("image", this.state.bannerImage);
    urlencoded.append("image2", this.state.bannerImage2);

    axios
      .post(global.baseUrl + "SellerAddPayPerClickBanner", urlencoded, {
        headers: { "Content-Type": "application/x-www-form-urlencoded" }
      })
      .then(result => {
        console.log(result.data);
        if (result.data) {
          toast.success(<CustomToast />, {
            position: toast.POSITION.TOP_RIGHT
          });
          this.props.history.push("/payperclickbannerlist");
        }
      });
    //localStorage.setItem('userdata', JSON.stringify(result.data))
    ///
  };

  addImage = () => {
    console.log("addimgae")

    // if (sendImage) {
      const apiUrl = global.baseUrl + "sellerImageUpload";
      const formData = new FormData();
      formData.append("myFile", sendImage);
      const config = {
        headers: {
          "content-type": "multipart/form-data"
        }
      };
      axios
        .post(apiUrl, formData, config)
        .then(response => {
          console.log("image add", response.data.filename);
          this.setState({ bannerImage: response.data.path });
          // this.APICall();
          this.addImage2()
        })
        .catch(error => {});
    // }
  };
  handleSubmit = e => {
    if (this.state.bannerImage === "") {
      this.setState({ imageError: "Please select file" });
      imageError = "Please select file";
    } else {
      this.setState({ imageError: "" });
      imageError = "";
    }
    if (this.state.bannerImage2 === "") {
      this.setState({ imageError2: "Please select file" });
      imageError2 = "Please select file";
    } else {
      this.setState({ imageError2: "" });
      imageError2 = "";
    }
    if (this.state.productId === "") {
      this.setState({ productError: "Please select product" });
      productError = "Please select product";
    } else {
      this.setState({ productError: "" });
      productError = "";
    }
    if (
      this.state.productId &&
      this.state.bannerImage &&
      this.state.bannerImage2 &&
      productError === "" &&
      imageError === "" &&
      imageError2===""
    ) {
      submitDisable = true;
      this.addImage();
    }
  };

  //  resetHandler = () => {
  //     this.setState({
  //       percentage: "",
  //       startDate: "",
  //       endDate: "",
  //       minQty: "",
  //       maxQty: "",
  //       offerType: "",
  //       productId: "",
  //       categoryId: "",
  //       brandId: "",
  //       products: [],
  //       categories: [],
  //       brands: []
  //     });
  //     startDateError = "";
  //     endDateError = "";
  //     typeError = "";
  //     submitDisable = false;
  //   };
  render() {
    return (
      <body
        class="vertical-layout vertical-menu-modern semi-dark-layout 2-columns  navbar-floating footer-static  "
        data-open="click"
        data-menu="vertical-menu-modern"
        data-col="2-columns"
        data-layout="semi-dark-layout"
      >
        {/* <Header />
        <Sidebar /> */}

        <div class="app-content content">
          <div class="content-overlay"></div>
          <div class="header-navbar-shadow"></div>
          <div class="content-wrapper">
            <div class="content-header row">
              <div class="col-12">
                <h2 class="content-header-title float-left mb-0">
                <Link to={"/payperclickbannerlist"} className="back-arrow  mr-2"><i className="feather icon-arrow-left"></i></Link> 
                   
                  
                  Add Banner</h2>
                <div class="breadcrumb-wrapper col-12">
                  <ol class="breadcrumb">
                    <li class="breadcrumb-item">
                      <Link to="/payperclickbannerlist">Pay Per Click</Link>
                    </li>
                    <li class="breadcrumb-item active">Add Banner</li>
                  </ol>
                </div>
              </div>
            </div>
            <div class="content-body">
              <section id="dashboard-analytics">
                <div class="card">
                  <div class="card-body" style={{ height: "64vh" }}>
                    <div
                      class="tab-pane active"
                      id="account"
                      aria-labelledby="account-tab"
                      role="tabpanel"
                    >
                      <div class="row">
                        <div class="col-12 col-xl-3">
                          <label className="b-label">
                            App banner upload
                            <span class="required-span1">*</span>
                          </label>
                        </div>
                        <div class="banner-upload-btn pb-2 col-12 col-lg-4">
                          <label
                            class="btn btn-sm btn-primary-green ml-30 mb-50 mb-sm-0 cursor-pointer up-btn"
                            for="account-upload"
                            style={{padding:"10px"}}
                          >
                            Upload banner(1280*590px)
                          </label>
                          <input
                            type="file"
                            id="account-upload"
                            accept="image/*"
                            name="imageUpload"
                            onChange={this.onImageChange}
                            multiple
                            hidden
                          />
                        </div>
                        <div class="col-3 " style={{marginTop:"5px"}}>
                          <p>{this.state.fileName}</p>
                        </div>
                      </div>
                      <div class="row mb-1">
                      <div class="col-3 "></div>
                      <div>
                        <p class="error-msg up-banner-btn" >
                          {imageError}
                        </p>
                      </div>
                      </div>
                 
                      <div class="row">
                        <div class="col-12 col-xl-3">
                          <label className="b-label">
                           Web banner upload
                            <span class="required-span1">*</span>
                          </label>
                        </div>
                        <div class="banner-upload-btn pb-2 col-12 col-lg-4">
                          <label
                            class="btn btn-sm btn-primary-green ml-30 mb-50 mb-sm-0 cursor-pointer up-btn"
                            for="account-upload2"
                            style={{padding:"10px"}}
                          >
                            Upload banner(1500*400px)
                          </label>
                          <input
                            type="file"
                            id="account-upload2"
                            accept="image/*"
                            name="imageUpload2"
                            onChange={this.onImageChange2}
                            multiple
                            hidden
                          />
                        </div>
                        <div class="col-3 " style={{marginTop:"5px"}}>
                          <p>{this.state.fileName2}</p>
                        </div>
                      </div>

                      <div class="row mb-1">
                      <div class="col-3 "></div>
                      <div>
                        <p class="error-msg up-banner-btn" >
                          {imageError2}
                        </p>
                      </div>
                      </div>






                    
                      <div class="row mb-1">
                        <div class="col-12 col-lg-3">
                          <label className="b-label">
                            Choose Product<span class="required-span1">*</span>
                          </label>
                        </div>
                        <div class="col-12 col-lg-4">
                          <Select
                            value={this.state.products.filter(
                              option => option.value === this.state.productId
                            )}
                            theme={theme => ({
                              ...theme,
                              borderRadius: "5px",
                              border: "0px solid",
                              colors: {
                                ...theme.colors,
                                text: "orangered",
                                primary25: "#9cf59c",
                                primary: "#47A547"
                              }
                            })}
                            options={this.state.products}
                            name="category"
                            onChange={val => this.handleProductChange(val)}
                          />

                          <p class="error-msg">{productError}</p>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-3"></div>
                        <div class="col-4  mt-1">
                          <button
                            type="submit"
                            disabled={submitDisable}
                            class="btn btn-primary glow mb-1 mb-sm-0 mr-0 mr-sm-1 login-btn"
                            onClick={this.handleSubmit}
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>

        <div class="sidenav-overlay"></div>
        <div class="drag-target"></div>
        <Footer />
      </body>
    );
  }
}
export default AddBanner;
