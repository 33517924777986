import React from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { Modal, ModalHeader, ModalBody, ModalFooter, Table } from 'reactstrap';
import Footer from '../../Component/Footer/Footer';
import global from '../../global';
import { toast } from 'react-toastify';
import './Product.css';
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment';
import ReactPaginate from 'react-paginate';
import ReactToPrint from 'react-to-print';
import ReactToPdf from 'react-to-pdf';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Spinner } from "reactstrap"
const targetRef = React.createRef();
const fileType =
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const fileExtension = '.xlsx';

const $ = require('jquery');
$.DataTable = require('datatables.net');
var seller = {};
var modal = false;
var modal2 = false;
var isActive = false;
var orderList = [];
var allSalesCheck = false;
var mainProduct = [];
toast.configure();
const CustomToast = () => {
  return (
    <div>
      <i className="fa fa-check success-toast"></i>
      Product Created Successfully.
    </div>
  );
};
const WarnToast = () => {
  return (
    <div style={{ display: 'flex' }}>
      <i className="fa fa-info info-toast"></i>
      You have insufficient amount in wallet.
    </div>
  );
};
const ProductWarnToast = () => {
  return (
    <div>
      <i className="fa fa-info info-toast"></i>
      Please select product.
    </div>
  );
};
const ActionWarnToast = () => {
  return (
    <div>
      <i className="fa fa-info info-toast"></i>
      Please select Action.
    </div>
  );
};
//var Loader = require("react-loader");
class ComponentToPrint extends React.Component {
  render() {
    return (
      <Table
        responsive
        hover
        className="display border-product-list-table"
        style={{ fontSize: '12px' }}
      >
        <thead>
          <tr>
            <th scope="col">No</th>
            <th scope="col">Order</th>
            <th scope="col">Date/Time</th>
            <th scope="col">Customer</th>
            <th scope="col">Product Name</th>
            <th scope="col">Total</th>
            <th scope="col">Payment</th>
            <th scope="col">Shipping</th>
            <th scope="col">Note</th>
            <th scope="col">Carrier</th>
            <th scope="col">Tracking</th>
          </tr>
        </thead>
        <tbody>
          {orderList.map((order, index) => (
            <tr key={index}>
              <td>{index + 1}</td>
              <td>{order.order_Id}</td>
              <td>{moment(order.createdAt).format('DD/MM/YYYY,h:mm a ')}</td>
              <th>{order.userId ? order.userId.name : null}</th>
              <td>{order.productId ? order.productId.product_name : null}</td>
              <td>{order.bag_total}</td>
              <td>Not done</td>
              <td>Shipping</td>
              <td>Note</td>
              <td>Carrier</td>
              <td>Tracking</td>
              <td>View</td>
            </tr>
          ))}
        </tbody>
      </Table>
    );
  }
}
class SalesReport extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      orders: [],
      loaded: true,
      seller: '',
      actionTask: '',
      loading:false,
      loadingprint:false,
      lodingconfirm:false,
      offset: 1,
      perPage: 10,
      allSalesCheck: false,
      currentPage: 1,
      totalCount: 0,
      searchProduct: '',
      startDate: '',
      endDate: '',
      listdata: [],
      listView: '1',
      checkBoxHandleChange: false,
      paymentCheckbox: false,
      shippingCheckbox: false,
      noteCheckbox: false,
      carrierCheckbox: false,
      trackingCheckbox: false,
      viewCheckbox: false,
      sales: [],
    };
    seller = {};
    isActive = false;
    modal = false;
    modal2 = false;
    orderList = [];
    allSalesCheck = false;
  }
  componentWillMount() {
    if (localStorage.getItem('sellerdata')) {
      this.setState({ seller: JSON.parse(localStorage.getItem('sellerdata')) });
      seller = JSON.parse(localStorage.getItem('sellerdata'));
    }
    if (this.props.match.params.id) {
      this.productWiseOrderList();
    } else {
      this.listAPI();
    }
  }
  firstListView = () => {
    this.setState({ listView: '1' });
  };
  secondListView = () => {
    if (this.state.listView !== '2') {
      this.setState({ listView: '2' }, () => {
        // let newTableRow = [];
        // let dataTableRow = this.state.datatable.rows;
        // for (let i = 0; i < dataTableRow.length; i++) {
        //   // console.log(dataTableRow[i].image.props.className)
        //   //  dataTableRow[i].image.props.className="product-second-image"
        //   dataTableRow[i].image = (
        //     <img
        //       src={dataTableRow[i].image.props.src}
        //       alt=".."
        //       className={
        //         this.state.listView === "1"
        //           ? "product-first-image"
        //           : "product-second-image"
        //       }
        //     ></img>
        //   );
        // }
        // let dataTable = {
        //   columns: this.state.datatable.columns,
        //   rows: dataTableRow
        // };
        // this.setState({
        //   datatable: this.state.backupDataTable
        // });
      });
    }
  };
  listAPI() {
    mainProduct = [];

    var urlencoded = new URLSearchParams();
    urlencoded.append('sellerId', seller.id);
    // urlencoded.append("sellerId", seller.id);
    console.log('data');
    axios
      .post(global.baseUrl + 'salesReportList', urlencoded, {
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      })
      .then((result) => {
        console.log(result.data, 'sales report');
        if (result.data.data) {
          orderList = result.data.data;
          this.setState({
            orders: result.data.data,
            postData: [],
          });
          this.setState({
            totalCount: result.data.data.length,
            pageCount: Math.ceil(result.data.data.length / this.state.perPage),
          });
          this.pagination();
        }
      });
  }
  productWiseOrderList() {
    var urlencoded = new URLSearchParams();
    urlencoded.append('sellerId', seller.id);
    urlencoded.append('productId', this.props.match.params.id);

    axios
      .post(global.baseUrl + 'prountIdOrderList', urlencoded, {
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      })
      .then((result) => {
        console.log(result.data, 'djfg');
        if (result.data.data) {
          orderList = result.data.data;
          this.setState({
            orders: result.data.data,
            postData: [],
          });
          this.setState({
            totalCount: result.data.data.length,
            pageCount: Math.ceil(result.data.data.length / this.state.perPage),
          });
          this.pagination();
        }
      });
  }

  pagination() {
    console.log(this.state.offset, 'offsetcheck');
    const slice = this.state.orders
      .sort((a, b) => {
        return new Date(a.createdAt) - new Date(b.createdAt);
      })
      .slice(this.state.offset - 1, this.state.offset - 1 + this.state.perPage);

    // For displaying Data
    this.setState({ listdata: slice });
  }

  handlePageClick = (e) => {
    const selectedPage = e.selected;
    console.log(selectedPage, 'selected page');
    const offset = selectedPage * this.state.perPage;

    this.setState(
      {
        currentPage: selectedPage + 1,
        offset: offset + 1,
      },
      () => {
        this.pagination();
      }
    );
  };
  disableHandler(id, index) {
    var r;
    if (this.state.products[index].activeStatus == 1) {
      r = window.confirm('Are you sure to hide product?');
    } else {
      r = window.confirm('Are you sure to un hide product?');
    }
    if (r) {
      var urlencoded = new URLSearchParams();
      urlencoded.append('id', id);
      urlencoded.append('sellerId', this.state.seller.id);
      axios
        .post(global.baseUrl + 'SellerProductActiveStatusChange', urlencoded, {
          headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        })
        .then((result) => {
          let item = this.state.products;
          if (result.data.status) {
            if (result.data.data) {
              console.log(result.data.data);
              if (result.data.data[0]) {
                this.setState({
                  datatable: {
                    columns: [],
                    rows: [],
                  },
                });
                // this.setState({loaded:false})
                item[index].activeStatus = result.data.data[0].activeStatus;
                this.setState({ product: item }, () => {
                  this.setProduct();
                });
              }
            }
          } else {
            console.log('result', result.data);
          }
        });
    }
  }


  mobilepdf=()=>{

    if(window.innerWidth <= 767){

//     <th scope="col">Disc. Code</th>
// <th scope="col">Disc.Amount</th>
// <th scope="col">Tax City</th>
// <th scope="col">Tax Rate</th>
// <th scope="col">Tax Value</th>
// <th scope="col">Shipping Method</th>
// <th scope="col">Billing Name</th>
    let data=`<div id="pdfTableDiv" style={{ display: 'none' }}>
    <Table
      responsive
      hover
      className="display border-product-list-table"
      style={{ fontSize: '12px' }}
      id="pdfDiv"
    >
      <thead>
        <tr>
          <th scope="col">No</th>
          <th scope="col">Name</th>
          <th scope="col">Email</th>
          <th scope="col">Product Name</th>
          <th scope="col">Date Paid</th>
<th scope="col">Currency</th>
<th scope="col">Amount</th>
           
        </tr>
      </thead>
      <tbody>
        ${this.state.totalCount ? (
          this.state.listdata.map((order, index) => (
            `<tr key=${index}>
              <td>${index + 1}</td>
              <td style={{ minWidth: '144px' }}>
                ${order.userId?order.userId.name:'-'}
              </td>
              <td style={{ minWidth: '250px' }}>
                ${order.userId?order.userId.email?order.userId.email:'-':'-'}
              </td>
              <th>
                ${order.productId
                  ? order.productId.product_name
                  :'-'}
              </th>
              
              <td>${order.payment_status==="success" && moment(order.updatedAt).format(
                'MM/DD/YYYY,h:mm a '
              )}</td>
              <td style={{ text-align:'center' }}>$</td>
              <td>${order.bag_total}</td>
             
              
            </tr>`
          ))
        ) : (
          `<tr>
            <td colSpan={6}>No Product Available.</td>
          </tr>`
        )}
      </tbody>
    </Table>
  </div>`;

  var urlencoded = new URLSearchParams();
      // urlencoded.append('id', id);
      urlencoded.append('body', data);
      axios
        .post(global.baseUrl + 'pdfgenratelink', urlencoded, {
          headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        })
        .then((result) => {
         console.log(result.data.data,"ddddddddlink")
         var message = {'type':'1', 'data' : [  { "pdfLink" : result.data.data } ]};
        //  window.webkit.messageHandlers.derp.postMessage(message);
        // window.open(result.data.data,"_self")
        window.postMessage(message)
        this.setState({loading:false})
        this.setState({loadingprint:false})
        this.setState({lodingconfirm:false})
        });
      }else{
        this.setState({loading:false})
        this.setState({loadingprint:false})
        this.setState({lodingconfirm:false})
      }
  }

  mobilepdf2=(selcedata)=>{
    if(window.innerWidth <= 767){
    console.log(selcedata,"pdf2select")

    //     <th scope="col">Disc. Code</th>
    // <th scope="col">Disc.Amount</th>
    // <th scope="col">Tax City</th>
    // <th scope="col">Tax Rate</th>
    // <th scope="col">Tax Value</th>
    // <th scope="col">Shipping Method</th>
    // <th scope="col">Billing Name</th>
    // <th scope="col">Fulfillment Status</th>
    // <th scope="col">Accepts Marketing</th>
        let data=`<div id="pdfTableDiv" style={{ display: 'none' }}>
        <Table
          responsive
          hover
          className="display border-product-list-table"
          style={{ fontSize: '12px' }}
          id="pdfDiv"
        >
          <thead>
            <tr>
              <th scope="col">No</th>
              <th scope="col">Name</th>
              <th scope="col">Email</th>
              <th scope="col">Product Name</th>
              <th scope="col">Date Paid</th>
    <th scope="col">Currency</th>
    <th scope="col">Amount</th>
    <th scope="col">Sub-Total Shipping Taxes</th>
               
            </tr>
          </thead>
          <tbody>
            ${selcedata ? (
              selcedata.map((order, index) => (
                `<tr key=${index}>
                  <td>${index + 1}</td>
                  <td style={{ minWidth: '144px' }}>
                    ${order.userId?order.userId.name:'-'}
                  </td>
                  <td style={{ minWidth: '250px' }}>
                    ${order.userId?order.userId.email?order.userId.email:'-':'-'}
                  </td>
                  <th>
                    ${order.productId
                      ? order.productId.product_name
                      :'-'}
                  </th>
                  <td>${order.payment_status==="success" && moment(order.updatedAt).format(
                    'MM/DD/YYYY,h:mm a '
                  )}</td>
                  
                  <td>$</td>
                  <td>${order.bag_total}</td>
                  <td>-</td>
                  
                </tr>`
              ))
            ) : (
              `<tr>
                <td colSpan={6}>No Product Available.</td>
              </tr>`
            )}
          </tbody>
        </Table>
      </div>`;
    
      var urlencoded = new URLSearchParams();
          // urlencoded.append('id', id);
          urlencoded.append('body', data);
          axios
            .post(global.baseUrl + 'pdfgenratelink', urlencoded, {
              headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            })
            .then((result) => {
             console.log(result.data.data,"ddddddddlink")
             var message = {'type':'1', 'data' : [  { "pdfLink" : result.data.data } ]};
            //  window.webkit.messageHandlers.derp.postMessage(message);
            // window.open(result.data.data,"_self")
            window.postMessage(message)
            this.setState({loading:false})
            this.setState({loadingprint:false})
            this.setState({lodingconfirm:false})
            });
          }else{
            this.setState({loading:false})
            this.setState({loadingprint:false})
            this.setState({lodingconfirm:false})
          }
      }

  productCheck = (index) => {
    allSalesCheck = false;

    let orders = this.state.orders;
    if (orders[index].checked === true) {
      orders[index].checked = false;
    } else {
      orders[index].checked = true;
    }
    this.setState({ orders: orders, allSalesCheck: false });
  };
  modalToggle = (id, index) => {
    console.log('Working');
    if (modal === false) {
      modal = true;
      let charge = this.state.products[index].image.length - 5;
      if (charge >= 1) {
        charge = charge * parseFloat(this.state.imageCharge);
      } else {
        charge = 0;
      }
      this.setState({
        productId: id,
        productImageCharge: charge,
        finalTotal: parseFloat(this.state.productCharge) + parseFloat(charge),
      });
    } else {
      modal = false;
    }
    this.setState({ A: '' });
  };
  closeModalToggle = () => {
    console.log('close working');
    if (modal === false) {
      modal = true;
    } else {
      modal = false;
    }
    this.setState({ A: '' });
  };
  modal2Toggle = () => {
    if (modal2 === false) {
      modal2 = true;
    } else {
      modal2 = false;
    }
    this.setState({ A: '' });
  };
  paymentOpen = () => {
    modal = false;
    modal2 = true;
    this.setState({ a: '' });
  };
  handleSearchProduct = (e) => {
    this.setState({
      searchProduct: e.target.value,
    });
    this.setState(
      {
        currentPage: 1,
        offset: 10,
      },
      () => {
        this.listAPI();
      }
    );
  };
  actionChange = (e) => {
    this.setState({ actionTask: e.target.value });
  };
  checkAction = () => {
    mainProduct = [];

    this.setState({
      selectedProduct: [],
    });
    if (this.state.actionTask === '') {
      this.setState({lodingconfirm:false})
      toast.warn(<ActionWarnToast />, {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else if (this.state.actionTask === 'printSelectedDates') {
      if (
        this.state.startDate !== '' &&
        this.state.endDate !== '' 
        // this.state.endDate.length === 10 &&
        // this.state.startDate.length === 10
      ) {
        this.printButtonElement.click();
        this.mobilepdf()
      }
    } else if (this.state.actionTask === 'exportAll') {
      this.exportAllHandler();
    } else if (this.state.actionTask === 'exportSelected') {
      this.exportAllHandler2();
    }else if (this.state.actionTask === 'outofstock') {
      this.exportAllHandler();
      this.mobilepdf()
    } 
    // else if (this.state.actionTask === 'MarkAsShipped') {
    //   this.UpdateOrderStatusAPICall();
    // } else if (this.state.actionTask === 'MarkAsPaymentReceived') {
    //   this.UpdatePaymentStatusAPICall();
    // } else if (this.state.actionTask === 'addEditNotes') {
    //   this.OrderCheckBoxAPICall();
    // }
  };
  multipleOutOfStockHandlerHandler = () => {
    let products = this.state.products;
    let newProduct = [];
    let i = 0;
    for (i = 0; i < products.length; i++) {
      if (products[i].checked === true) {
        newProduct.push(products[i]);
      }
    }
    if (i === products.length && newProduct.length) {
      this.setState({ newProduct: newProduct }, () => {
        this.multiOutOfStockAPI();
      });
    } else {
      toast.warn(<ProductWarnToast />, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };
  multiOutOfStockAPI = () => {
    let r = '';
    if (this.state.actionTask === 'outofstock') {
      r = window.confirm('Are you sure to set out of stock product?');
    }
    if (r) {
      let urlencoded = new URLSearchParams();
      for (let i = 0; i < this.state.newProduct.length; i++) {
        urlencoded.append('productId', this.state.newProduct[i].id);
      }
      urlencoded.append('sellerId', this.state.seller.id);
      axios
        .post(global.baseUrl + 'SellerMultiOutOfStock', urlencoded, {
          headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        })
        .then((result) => {
          allSalesCheck = false;
          this.setState({ newProduct: [], product: [] }, () => {
            this.listAPI();
          });
        });
    }
  };
  printClick = () => {
    this.printButtonElement.click();
  };
  pdfContent = () => {
    var pdfContents = document.getElementById('pdfTableDiv').innerHTML;
    console.log(pdfContents, 'klffh');
    var originalContents = document.getElementById('pdfDiv').innerHTML;

    document.getElementById('pdfDiv').innerHTML = pdfContents;

    this.pdfElement.click();
    document.getElementById('pdfDiv').innerHTML = originalContents;
  };
  handlerChange = (e) => {
    this.setState({ [e.target.name]: e.target.value }, () => {
      if (
        this.state.startDate !== '' &&
        this.state.endDate !== '' &&
        this.state.startDate.length === 10 &&
        this.state.endDate.length === 10
      ) {
        console.log(this.state.startDate, this.state.endDate);
        var urlencoded = new URLSearchParams();
        urlencoded.append('sellerId', seller.id);
        urlencoded.append('fromDate', this.state.startDate);
        urlencoded.append('toDate', this.state.endDate);

        axios
          .post(global.baseUrl + 'SellerOrderDateList', urlencoded, {
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
          })
          .then((result) => {
            console.log(result.data, 'djfg');
            if (result.data.data) {
              orderList = result.data.data;
              this.setState({
                orders: result.data.data,
                postData: [],
              });
              this.setState({
                totalCount: result.data.data.length,
                pageCount: Math.ceil(
                  result.data.totalCount / this.state.perPage
                ),
              });
              this.pagination();
            }
          });
      } else if (this.state.startDate === '' && this.state.endDate === '') {
        this.listAPI();
      }
    });
  };
  exportAllHandler2 = () => {
    let orders = this.state.orders;
    let newProduct = [];
    let i = 0;
    for (i = 0; i < orders.length; i++) {
      if (orders[i].checked === true) {
      newProduct.push(
      //   {
      //   id: orders[i].id,
      //   product_name: orders[i].productId.product_name,
      //   buyer_name: orders[i].name,
      //   total: orders[i].bag_total,
      // }
      orders[i]
      );
      }
    }
    if (i === orders.length && newProduct.length) {
      allSalesCheck = false;

      this.setState({ selectedProduct: newProduct }, () => {
        this.exportAll(this.state.selectedProduct);
        this.mobilepdf2(this.state.selectedProduct)
        this.listAPI();
      });
    } else {
      toast.warn(<ProductWarnToast />, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };
  exportAllHandler = () => {
    let orders = this.state.orders;
    let newProduct = [];
    let i = 0;
    for (i = 0; i < orders.length; i++) {
      // if (orders[i].checked === true) {
      newProduct.push(
      //   {
      //   id: orders[i].id,
      //   product_name: orders[i].productId.product_name,
      //   buyer_name: orders[i].name,
      //   total: orders[i].bag_total,
      // }
      orders[i]
      );
      // }
    }
    if (i === orders.length && newProduct.length) {
      allSalesCheck = false;

      this.setState({ selectedProduct: newProduct }, () => {
        this.exportAll(this.state.selectedProduct);
        this.mobilepdf2(this.state.selectedProduct)
        this.listAPI();
      });
    } else {
      toast.warn(<ProductWarnToast />, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };
  exportAll = (csvData) => {
    const ws = XLSX.utils.json_to_sheet(csvData);
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, 'download' + fileExtension);
  };
  exportSelected = (csvData) => {
    const ws = XLSX.utils.json_to_sheet(csvData);
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, 'download' + fileExtension);
  };
  handleFromDateChange = (date) => {
    this.setState(
      {
        startDate: date,
      },
      () => {
        if (this.state.startDate !== '' && this.state.endDate !== '') {
          console.log(this.state.startDate, this.state.endDate);
          var urlencoded = new URLSearchParams();
          urlencoded.append('sellerId', seller.id);
          urlencoded.append('fromDate', this.state.startDate);
          urlencoded.append('toDate', this.state.endDate);

          axios
            .post(global.baseUrl + 'SellerOrderDateList', urlencoded, {
              headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            })
            .then((result) => {
              console.log(result.data, 'djfg');
              if (result.data.data) {
                orderList = result.data.data;
                this.setState({
                  orders: result.data.data,
                  postData: [],
                });
                this.setState({
                  totalCount: result.data.data.length,
                  pageCount: Math.ceil(
                    result.data.totalCount / this.state.perPage
                  ),
                });
                this.pagination();
              }
            });
        } else if (this.state.startDate === '' && this.state.endDate === '') {
          this.listAPI();
        }
      }
    );
  };

  handleEndDateChange = (date) => {
    this.setState(
      {
        endDate: date,
      },
      () => {
        if (this.state.startDate !== '' && this.state.endDate !== '') {
          console.log(this.state.startDate, this.state.endDate);
          var urlencoded = new URLSearchParams();
          urlencoded.append('sellerId', seller.id);
          urlencoded.append('fromDate', this.state.startDate);
          urlencoded.append('toDate', this.state.endDate);

          axios
            .post(global.baseUrl + 'SellerOrderDateList', urlencoded, {
              headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            })
            .then((result) => {
              console.log(result.data, 'djfg');
              if (result.data.data) {
                orderList = result.data.data;
                this.setState({
                  orders: result.data.data,
                  postData: [],
                });
                this.setState({
                  totalCount: result.data.data.length,
                  pageCount: Math.ceil(
                    result.data.totalCount / this.state.perPage
                  ),
                });
                this.pagination();
              }
            });
        } else if (this.state.startDate === '' && this.state.endDate === '') {
          this.listAPI();
        }
      }
    );
  };
  checkBoxHandleChange = (e) => {
    console.log(e.target.value, '');
    if (e.target.value === 'true') {
      this.setState({ [e.target.name]: false });
    } else {
      this.setState({ [e.target.name]: true });
    }
  };
  allSaleSelection = () => {
    let orders = this.state.orders;
    if (allSalesCheck === false) {
      for (let i = 0; i < orders.length; i++) {
        orders[i].checked = true;
      }
      allSalesCheck = true;
      this.setState({
        orders: orders,
      });
    } else {
      for (let i = 0; i < orders.length; i++) {
        orders[i].checked = false;
      }
      allSalesCheck = false;
      this.setState({
        orders: orders,
      });
    }
  };
  render() {
    return (
      <body
        className="vertical-layout vertical-menu-modern semi-dark-layout 2-columns  navbar-floating footer-static  "
        data-open="click"
        data-menu="vertical-menu-modern"
        data-col="2-columns"
        data-layout="semi-dark-layout"
      >
        {/* <Header />
        <Sidebar />  */}
        <div className="app-content content" style={{ minHeight: '91vh' }}>
          <div className="content-overlay"></div>
          <div className="header-navbar-shadow"></div>
          <div className="content-wrapper">
            <div className="content-header row">
              <div className="content-header-left col-md-9 col-12 mb-2">
                <div className="row breadcrumbs-top">
                  <div className="col-12">
                    <h2 className="content-header-title float-left mb-0">
                      Sales Report
                    </h2>
                    <div className="breadcrumb-wrapper col-12">
                      <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                          <Link to="/dashboard">Home</Link>
                        </li>
                        <li className="breadcrumb-item active">Sales Report</li>
                      </ol>
                    </div>
                  </div>
                </div>
              </div>
              <div className="content-header-right text-md-right col-md-3 col-12 d-md-block d-none">
                <div className="form-group breadcrum-right"></div>
              </div>
            </div>
            <div className="content-body">
              <Modal isOpen={modal} toggle={this.closeModalToggle}>
                <ModalHeader toggle={this.closeModalToggle}>
                  <h5 className="info-modal-title">
                    You are about to publishing a copy listing
                  </h5>
                </ModalHeader>
                <ModalBody>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: this.state.productInfo,
                    }}
                  />
                  <table className="table table-bordered">
                    <thead>
                      <tr>
                        <th>No</th>
                        <th>Type</th>
                        <th>Cost</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>1</td>
                        <td>Product</td>
                        <td>${this.state.productCharge}</td>
                      </tr>
                      {this.state.productImageCharge ? (
                        <tr>
                          <td>2</td>
                          <td>Image</td>
                          <td>${this.state.productImageCharge}</td>
                        </tr>
                      ) : null}
                      {this.state.productImageCharge ? (
                        <tr>
                          <td colSpan={2}>Total Cost</td>
                          <td>
                            $
                            {parseFloat(this.state.productImageCharge) +
                              parseFloat(this.state.productCharge)}
                          </td>
                        </tr>
                      ) : (
                        <tr>
                          <td colSpan={2}>Total Cost</td>
                          <td>${parseFloat(this.state.productCharge)}</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </ModalBody>
                <ModalFooter>
                  <button
                    type="button"
                    className="btn btn-success"
                    onClick={this.paymentOpen}
                  >
                    OK
                  </button>
                  <button
                    type="button"
                    className="btn btn-success "
                    onClick={this.closeModalToggle}
                  >
                    Cancel
                  </button>
                </ModalFooter>
              </Modal>
              <section id="add-row">
                <div className="row">
                  <div className="col-12">
                    <div className="card">
                      <div className="card-content">
                        <div className="card-body">
                          <div className="over-date">
                            <div className="order-list-header custom-picker sales-report-olh">
                              {/* <span className="order-span font16 order-sa-span">
                                Date <br></br> From{' '}
                              </span> */}
                              <span className="order-span font16" style={{ marginRight: '5px' }}>
                              {' '}Date From{' '}
                              </span>
                              {/* <input
                              type="date"
                              id="first-name-floating-icon"
                              className="form-control order-control"
                              name="startDate"
                              value={this.state.startDate}
                              onChange={this.handlerChange}
                              placeholder="Date To"
                            /> */}
                              <DatePicker
                                selected={this.state.startDate}
                                onChange={this.handleFromDateChange}
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                                style={{ width: '90px' }}
                              />
                              <span
                                className="order-span font16"
                                style={{ marginRight: '5px' }}
                              >
                                {' '}
                                To{' '}
                              </span>
                              {/* <input
                              type="date"
                              id="first-name-floating-icon"
                              className="form-control order-control"
                              name="endDate"
                              value={this.state.endDate}
                              onChange={this.handlerChange}
                              placeholder="Date To"
                            /> */}
                              <DatePicker
                                selected={this.state.endDate}
                                onChange={this.handleEndDateChange}
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                                style={{ width: '90px !important' }}
                              />
                              {this.state.totalCount ? (
                                <>
                                  <select
                                    className="form-control order-control font16"
                                    style={{
                                      paddingTop: '0',
                                      paddingBottom: '0',
                                      width: '200px',
                                    }}
                                    value={this.state.actionTask}
                                    onChange={(e) => this.actionChange(e)}
                                  >
                                    <option>Choose an action</option>
                                    <option value="printSelectedDates">
                                      Print Selected dates
                                    </option>
                                    {/* <option value="notvisible">
                                      Print Selected Invoice
                                    </option> */}
                                    <option value="exportAll">
                                      Export All
                                    </option>
                                    <option value="exportSelected">
                                      Export Selected
                                    </option>
                                    <option value="outofstock">
                                      Export Selected Dates
                                    </option>
                                    {/* <option value="multiediting">
                                      Email Selected File
                                    </option> */}
                                    {/* <option value="multiediting">
                                      Add Tracking
                                    </option> */}
                                    {/* <option value="multiediting">
                                      Mark as shipped
                                    </option>
                                    <option value="multiediting">
                                      Mark as payment received
                                    </option>
                                    <option value="multiediting">
                                      Add/Edit Notes
                                    </option> */}
                                  </select>
                                  {this.state.lodingconfirm===false?
                                  <button
                                    className="btn btn-outline-primary float-left btn-inline res-btn cnf-btn order-confirm-btn"
                                    onClick={()=>{this.setState({lodingconfirm:true});this.checkAction()}}
                                    style={{ paddingTop: '10px' }}
                                  >
                                    Confirm
                                  </button>
                                  :<Spinner/>
                                }
                                {this.state.loadingprint===false?
                                  <i
                                    className="fa fa-print order-print"
                                    onClick={() => {this.setState({loadingprint:true});this.printClick();this.mobilepdf()}}
                                    style={{ cursor: 'pointer' }}
                                  ></i>
                                  :<Spinner/>
                                }
                                {this.state.loading===false?
                                  <i
                                    className="fa fa-download order-print"
                                    onClick={() => {this.setState({loading:true});this.pdfContent(); this.mobilepdf()}}
                                    style={{ cursor: 'pointer' }}
                                  ></i>
                                  :<Spinner/>
                                }
                                  {/* <select
                                    className="form-control action-select order-sort font16"
                                    style={{
                                      paddingTop: '0',
                                      paddingBottom: '0',
                                      width: '200px',
                                    }}
                                    value={this.state.actionTask}
                                    onChange={(e) => this.actionChange(e)}
                                  >
                                    <option>SORT</option>
                                    <option value="visible">Date Sold</option>
                                    <option value="notvisible">
                                      Customers
                                    </option>
                                    <option value="freeshipping">Total</option>
                                    <option value="outofstock">Pending</option>
                                    <option value="multiediting">
                                      Partially Shipped
                                    </option>
                                    <option value="multiediting">
                                      Completed
                                    </option>
                                    <option value="multiediting">
                                      Shipped
                                    </option>
                                    <option value="outofstock">
                                      Cancelled
                                    </option>
                                    <option value="outofstock">Decline</option>
                                    <option value="outofstock">Refunded</option>
                                    <option value="outofstock">Disputed</option>
                                    <option value="outofstock">
                                      Manual Verification Required
                                    </option>
                                    <option value="outofstock">
                                      Partially Refunded
                                    </option>
                                  </select>
                                   */}
                                  <input
                                    type="text"
                                    id="first-name-floating-icon"
                                    className="form-control order-control"
                                    name="searchProduct"
                                    value={this.state.searchProduct}
                                    onChange={this.handleSearchProduct}
                                    placeholder="Search"
                                    style={{ width: '80px' }}
                                  />
                                </>
                              ) : null}
                              <span class="dropdown dropdown-user nav-item ">
                                <a
                                  class="dropdown-toggle setting-toggle nav-link dropdown-user-link p-0"
                                  href="#"
                                  data-toggle="dropdown"
                                >
                                  <div class="user-nav d-sm-flex">
                                    <i
                                      className="fa fa-cog set-icon"
                                      style={{ marginLeft: '10px' }}
                                    />
                                  </div>
                                  <span></span>
                                </a>
                                <div
                                  class="dropdown-menu dropdown-menu-right"
                                  style={{ marginTop: '-15px' }}
                                >
                                  <div
                                    class="vs-checkbox-con vs-checkbox-primary"
                                    style={{ padding: '7px' }}
                                  >
                                    <input
                                      type="checkbox"
                                      name="totalCheckbox"
                                      value={this.state.totalCheckbox}
                                      onChange={(e) =>
                                        this.checkBoxHandleChange(e)
                                      }
                                      checked={this.state.totalCheckbox}
                                    />
                                    <span class="vs-checkbox">
                                      <span
                                        class="vs-checkbox--check"
                                        style={{ paddingLeft: '7px' }}
                                      >
                                        <i class="vs-icon feather icon-check"></i>
                                      </span>
                                    </span>
                                    <span class="" style={{ width: '150px' }}>
                                      Final Total
                                    </span>
                                  </div>
                                  <div
                                    class="vs-checkbox-con vs-checkbox-primary"
                                    style={{ padding: '7px' }}
                                  >
                                    <input
                                      type="checkbox"
                                      name="paymentCheckbox"
                                      value={this.state.paymentCheckbox}
                                      onChange={(e) =>
                                        this.checkBoxHandleChange(e)
                                      }
                                      checked={this.state.paymentCheckbox}
                                    />
                                    <span class="vs-checkbox">
                                      <span
                                        class="vs-checkbox--check"
                                        style={{ paddingLeft: '7px' }}
                                      >
                                        <i class="vs-icon feather icon-check"></i>
                                      </span>
                                    </span>
                                    <span class="" style={{ width: '150px' }}>
                                      Payment
                                    </span>
                                  </div>
                                  <div
                                    class="vs-checkbox-con vs-checkbox-primary"
                                    style={{ padding: '7px' }}
                                  >
                                    <input
                                      type="checkbox"
                                      name="shippingCheckbox"
                                      value={this.state.shippingCheckbox}
                                      onChange={(e) =>
                                        this.checkBoxHandleChange(e)
                                      }
                                      checked={this.state.shippingCheckbox}
                                    />
                                    <span class="vs-checkbox">
                                      <span
                                        class="vs-checkbox--check"
                                        style={{ paddingLeft: '7px' }}
                                      >
                                        <i class="vs-icon feather icon-check"></i>
                                      </span>
                                    </span>
                                    <span class="" style={{ width: '150px' }}>
                                      Shipping
                                    </span>
                                  </div>
                                  <div
                                    class="vs-checkbox-con vs-checkbox-primary"
                                    style={{ padding: '7px' }}
                                  >
                                    <input
                                      type="checkbox"
                                      name="noteCheckbox"
                                      value={this.state.noteCheckbox}
                                      onChange={(e) =>
                                        this.checkBoxHandleChange(e)
                                      }
                                      checked={this.state.noteCheckbox}
                                    />
                                    <span class="vs-checkbox">
                                      <span
                                        class="vs-checkbox--check"
                                        style={{ paddingLeft: '7px' }}
                                      >
                                        <i class="vs-icon feather icon-check"></i>
                                      </span>
                                    </span>
                                    <span class="" style={{ width: '150px' }}>
                                      Note
                                    </span>
                                  </div>
                                  <div
                                    class="vs-checkbox-con vs-checkbox-primary"
                                    style={{ padding: '7px' }}
                                  >
                                    <input
                                      type="checkbox"
                                      name="carrierCheckbox"
                                      value={this.state.carrierCheckbox}
                                      onChange={(e) =>
                                        this.checkBoxHandleChange(e)
                                      }
                                      checked={this.state.carrierCheckbox}
                                    />
                                    <span class="vs-checkbox">
                                      <span
                                        class="vs-checkbox--check"
                                        style={{ paddingLeft: '7px' }}
                                      >
                                        <i class="vs-icon feather icon-check"></i>
                                      </span>
                                    </span>
                                    <span class="" style={{ width: '150px' }}>
                                      Carrier
                                    </span>
                                  </div>
                                  <div
                                    class="vs-checkbox-con vs-checkbox-primary"
                                    style={{ padding: '7px' }}
                                  >
                                    <input
                                      type="checkbox"
                                      name="trackingCheckbox"
                                      value={this.state.trackingCheckbox}
                                      onChange={(e) =>
                                        this.checkBoxHandleChange(e)
                                      }
                                      checked={this.state.trackingCheckbox}
                                    />
                                    <span class="vs-checkbox">
                                      <span
                                        class="vs-checkbox--check"
                                        style={{ paddingLeft: '7px' }}
                                      >
                                        <i class="vs-icon feather icon-check"></i>
                                      </span>
                                    </span>
                                    <span class="" style={{ width: '150px' }}>
                                      Tracking
                                    </span>
                                  </div>
                                  <div
                                    class="vs-checkbox-con vs-checkbox-primary"
                                    style={{ padding: '7px' }}
                                  >
                                    <input
                                      type="checkbox"
                                      name="viewCheckbox"
                                      value={this.state.viewCheckbox}
                                      onChange={(e) =>
                                        this.checkBoxHandleChange(e)
                                      }
                                      checked={this.state.viewCheckbox}
                                    />
                                    <span class="vs-checkbox">
                                      <span
                                        class="vs-checkbox--check"
                                        style={{ paddingLeft: '7px' }}
                                      >
                                        <i class="vs-icon feather icon-check"></i>
                                      </span>
                                    </span>
                                    <span class="" style={{ width: '150px' }}>
                                      view
                                    </span>
                                  </div>
                                </div>
                              </span>
                            </div>
                          </div>
                          {this.state.listView === '1' ? (
                            <>
                              <Table
                                responsive
                                hover
                                className="display border-product-list-table table16"
                                style={{ fontSize: '16px' }}
                              >
                                <thead>
                                  <tr>
                                    <th scope="col">
                                      <label for="checkbox1" class="checkbox">
                                        <input
                                          type="checkbox"
                                          checked={allSalesCheck}
                                          onClick={() =>
                                            this.allSaleSelection()
                                          }
                                          style={{
                                            height: '20px',
                                            width: '16px',
                                          }}
                                        />
                                      </label>
                                    </th>
                                    <th
                                      className="white-space"
                                      scope="col"
                                      style={{ fontSize: '16px' }}
                                    >
                                      Name
                                    </th>
                                    <th
                                      className="white-space"
                                      scope="col"
                                      style={{ fontSize: '16px' }}
                                    >
                                      Email
                                    </th>
                                    <th
                                      className="white-space"
                                      scope="col"
                                      style={{ fontSize: '16px' }}
                                    >
                                      Product Name
                                    </th>
                                    <th
                                      className="white-space"
                                      scope="col"
                                      style={{ fontSize: '16px' }}
                                    >
                                      Date Paid
                                    </th>
                                    
                                    <th
                                      className="white-space"
                                      scope="col"
                                      style={{ fontSize: '16px' }}
                                    >
                                      Fulfillment Status
                                    </th>
                                    <th
                                      className="white-space"
                                      scope="col"
                                      style={{ fontSize: '16px' }}
                                    >
                                      Accepts Marketing
                                    </th>
                                    <th
                                      className="white-space"
                                      scope="col"
                                      style={{ fontSize: '16px' }}
                                    >
                                      Currency
                                    </th>
                                    <th
                                      className="white-space"
                                      scope="col"
                                      style={{ fontSize: '16px' }}
                                    >
                                      Sub-Total Shipping Taxes
                                    </th>
                                    <th
                                      className="white-space"
                                      scope="col"
                                      style={{ fontSize: '16px' }}
                                    >
                                      Disc. Code
                                    </th>
                                    <th
                                      className="white-space"
                                      scope="col"
                                      style={{ fontSize: '16px' }}
                                    >
                                      Disc.Amount
                                    </th>
                                    <th
                                      className="white-space"
                                      scope="col"
                                      style={{ fontSize: '16px' }}
                                    >
                                      Tax City
                                    </th>
                                    <th
                                      className="white-space"
                                      scope="col"
                                      style={{ fontSize: '16px' }}
                                    >
                                      Tax Rate
                                    </th>
                                    <th
                                      className="white-space"
                                      scope="col"
                                      style={{ fontSize: '16px' }}
                                    >
                                      Tax Value
                                    </th>
                                    <th
                                      className="white-space"
                                      scope="col"
                                      style={{ fontSize: '16px' }}
                                    >
                                      Shipping Method
                                    </th>
                                    <th
                                      className="white-space"
                                      scope="col"
                                      style={{ fontSize: '16px' }}
                                    >
                                      Billing Name
                                    </th>
                                    <th
                                      className="white-space"
                                      scope="col"
                                      style={{ fontSize: '16px' }}
                                    >
                                      Billing Street
                                    </th>
                                    <th
                                      className="white-space"
                                      scope="col"
                                      style={{ fontSize: '16px' }}
                                    >
                                      Billing Address
                                    </th>
                                    <th
                                      className="white-space"
                                      scope="col"
                                      style={{ fontSize: '16px' }}
                                    >
                                      Billing City
                                    </th>
                                    <th
                                      className="white-space"
                                      scope="col"
                                      style={{ fontSize: '16px' }}
                                    >
                                      Billing Province
                                    </th>
                                    <th
                                      className="white-space"
                                      scope="col"
                                      style={{ fontSize: '16px' }}
                                    >
                                      Billing Zip
                                    </th>
                                    <th
                                      className="white-space"
                                      scope="col"
                                      style={{ fontSize: '16px' }}
                                    >
                                      Billing Country
                                    </th>
                                    <th
                                      className="white-space"
                                      scope="col"
                                      style={{ fontSize: '16px' }}
                                    >
                                      Billing Phone
                                    </th>
                                    <th
                                      className="white-space"
                                      scope="col"
                                      style={{ fontSize: '16px' }}
                                    >
                                      Shipping Name
                                    </th>
                                    <th
                                      className="white-space"
                                      scope="col"
                                      style={{ fontSize: '16px' }}
                                    >
                                      Shipping Street
                                    </th>
                                    <th
                                      className="white-space"
                                      scope="col"
                                      style={{ fontSize: '16px' }}
                                    >
                                      Shipping Address
                                    </th>
                                    <th
                                      className="white-space"
                                      scope="col"
                                      style={{ fontSize: '16px' }}
                                    >
                                      Shipping Address2
                                    </th>
                                    <th
                                      className="white-space"
                                      scope="col"
                                      style={{ fontSize: '16px' }}
                                    >
                                      Shipping City
                                    </th>
                                    <th
                                      className="white-space"
                                      scope="col"
                                      style={{ fontSize: '16px' }}
                                    >
                                      Shipping Zip Code
                                    </th>
                                    <th
                                      className="white-space"
                                      scope="col"
                                      style={{ fontSize: '16px' }}
                                    >
                                      Shipping Province
                                    </th>
                                    <th
                                      className="white-space"
                                      scope="col"
                                      style={{ fontSize: '16px' }}
                                    >
                                      Shipping Country
                                    </th>
                                    <th
                                      className="white-space"
                                      scope="col"
                                      style={{ fontSize: '16px' }}
                                    >
                                      Shipping Phone No.
                                    </th>
                                    <th
                                      className="white-space"
                                      scope="col"
                                      style={{ fontSize: '16px' }}
                                    >
                                      Payment Method
                                    </th>
                                    <th
                                      className="white-space"
                                      scope="col"
                                      style={{ fontSize: '16px' }}
                                    >
                                      Payment Reference
                                    </th>
                                    <th
                                      className="white-space"
                                      scope="col"
                                      style={{ fontSize: '16px' }}
                                    >
                                      Refunded
                                    </th>
                                    <th
                                      className="white-space"
                                      scope="col"
                                      style={{ fontSize: '16px' }}
                                    >
                                      Vendor
                                    </th>

                                    {this.state.totalCheckbox ? (
                                      <th
                                        scope="col"
                                        style={{ fontSize: '16px' }}
                                      >
                                        Total
                                      </th>
                                    ) : null}
                                    {this.state.paymentCheckbox ? (
                                      <th
                                        scope="col"
                                        style={{ fontSize: '16px' }}
                                      >
                                        Payment
                                      </th>
                                    ) : null}
                                    {this.state.shippingCheckbox ? (
                                      <th
                                        scope="col"
                                        style={{ fontSize: '16px' }}
                                      >
                                        Shipping
                                      </th>
                                    ) : null}
                                    {this.state.noteCheckbox ? (
                                      <th
                                        scope="col"
                                        style={{ fontSize: '16px' }}
                                      >
                                        Note
                                      </th>
                                    ) : null}
                                    {this.state.carrierCheckbox ? (
                                      <th
                                        scope="col"
                                        style={{ fontSize: '16px' }}
                                      >
                                        Carrier
                                      </th>
                                    ) : null}
                                    {this.state.trackingCheckbox ? (
                                      <th
                                        scope="col"
                                        style={{ fontSize: '16px' }}
                                      >
                                        Tracking
                                      </th>
                                    ) : null}
                                    {this.state.viewCheckbox ? (
                                      <th
                                        scope="col"
                                        style={{ fontSize: '16px' }}
                                      >
                                        View
                                      </th>
                                    ) : null}
                                  </tr>
                                </thead>
                                <tbody>
                                  {this.state.totalCount ? (
                                    this.state.listdata.map((order, index) => (
                                      <tr key={order.id.toString()}>
                                        <td>
                                          {' '}
                                          <label
                                            for="checkbox1"
                                            class="checkbox"
                                          >
                                            <input
                                              type="checkbox"
                                              checked={order.checked}
                                              onChange={() =>
                                                this.productCheck(index)
                                              }
                                              style={{
                                                height: '20px',
                                                width: '15px',
                                              }}
                                            />
                                          </label>
                                        </td>
                                        <th
                                          className="white-space"
                                          scope="col"
                                          style={{ fontSize: '16px' }}
                                        >
                                          {order.userId
                                            ? order.userId.name
                                            : null}
                                        </th>
                                        <td
                                          className="white-space"
                                          scope="col"
                                          style={{ fontSize: '16px' }}
                                        >
                                          {order.userId
                                            ? order.userId.email
                                            : null}
                                        </td>
                                        <td
                                          className="sale-ellips"
                                          scope="col"
                                          style={{ fontSize: '16px' }}
                                        >
                                          {order.productId
                                            ? order.productId.product_name
                                            : null}
                                        </td>{' '}
                                        <td
                                          className="white-space"
                                          scope="col"
                                          style={{ fontSize: '16px' }}
                                        >
                                          -
                                        </td>
                                        <td
                                          className="white-space"
                                          scope="col"
                                          style={{ fontSize: '16px' }}
                                        >
                                          -
                                        </td>
                                        <td
                                          className="white-space"
                                          scope="col"
                                          style={{ fontSize: '16px' }}
                                        >
                                          -
                                        </td>
                                        <td
                                          className="white-space"
                                          scope="col"
                                          style={{ fontSize: '16px' }}
                                        >
                                          -
                                        </td>
                                        <td
                                          className="white-space"
                                          scope="col"
                                          style={{ fontSize: '16px' }}
                                        >
                                          -
                                        </td>
                                        <td
                                          className="white-space"
                                          scope="col"
                                          style={{ fontSize: '16px' }}
                                        >
                                          -
                                        </td>
                                        <td
                                          className="white-space"
                                          scope="col"
                                          style={{ fontSize: '16px' }}
                                        >
                                          -
                                        </td>
                                        <td
                                          className="white-space"
                                          scope="col"
                                          style={{ fontSize: '16px' }}
                                        >
                                          -
                                        </td>
                                        <td
                                          className="white-space"
                                          scope="col"
                                          style={{ fontSize: '16px' }}
                                        >
                                          -
                                        </td>
                                        <td
                                          className="white-space"
                                          scope="col"
                                          style={{ fontSize: '16px' }}
                                        >
                                          -
                                        </td>
                                        <td
                                          className="white-space"
                                          scope="col"
                                          style={{ fontSize: '16px' }}
                                        >
                                          -
                                        </td>
                                        <td
                                          className="white-space"
                                          scope="col"
                                          style={{ fontSize: '16px' }}
                                        >
                                          -
                                        </td>
                                        <td
                                          className="white-space"
                                          scope="col"
                                          style={{ fontSize: '16px' }}
                                        >
                                          -
                                        </td>
                                        <td
                                          className="white-space"
                                          scope="col"
                                          style={{ fontSize: '16px' }}
                                        >
                                          {order.userId
                                            ? order.userId.street_address
                                            : null}
                                        </td>
                                        <td
                                          className="white-space"
                                          scope="col"
                                          style={{ fontSize: '16px' }}
                                        >
                                          -
                                        </td>
                                        <td
                                          className="white-space"
                                          scope="col"
                                          style={{ fontSize: '16px' }}
                                        >
                                          -
                                        </td>
                                        <td
                                          className="white-space"
                                          scope="col"
                                          style={{ fontSize: '16px' }}
                                        >
                                          -
                                        </td>
                                        <td
                                          className="white-space"
                                          scope="col"
                                          style={{ fontSize: '16px' }}
                                        >
                                          -
                                        </td>
                                        <td
                                          className="white-space"
                                          scope="col"
                                          style={{ fontSize: '16px' }}
                                        >
                                          {order.userId
                                            ? order.userId.phone_number
                                            : null}
                                        </td>
                                        <td
                                          className="white-space"
                                          scope="col"
                                          style={{ fontSize: '16px' }}
                                        >
                                          -
                                        </td>
                                        <td
                                          className="white-space"
                                          scope="col"
                                          style={{ fontSize: '16px' }}
                                        >
                                          -
                                        </td>
                                        <td
                                          className="white-space"
                                          scope="col"
                                          style={{ fontSize: '16px' }}
                                        >
                                          -
                                        </td>
                                        <td
                                          className="white-space"
                                          scope="col"
                                          style={{ fontSize: '16px' }}
                                        >
                                          -
                                        </td>
                                        <td
                                          className="white-space"
                                          scope="col"
                                          style={{ fontSize: '16px' }}
                                        >
                                          -
                                        </td>
                                        <td
                                          className="white-space"
                                          scope="col"
                                          style={{ fontSize: '16px' }}
                                        >
                                          -
                                        </td>
                                        <td
                                          className="white-space"
                                          scope="col"
                                          style={{ fontSize: '16px' }}
                                        >
                                          -
                                        </td>
                                        <td
                                          className="white-space"
                                          scope="col"
                                          style={{ fontSize: '16px' }}
                                        >
                                          -
                                        </td>
                                        <td
                                          className="white-space"
                                          scope="col"
                                          style={{ fontSize: '16px' }}
                                        >
                                          -
                                        </td>
                                        <td
                                          className="white-space"
                                          scope="col"
                                          style={{ fontSize: '16px' }}
                                        >
                                          -
                                        </td>
                                        <td
                                          className="white-space"
                                          scope="col"
                                          style={{ fontSize: '16px' }}
                                        >
                                          -
                                        </td>
                                        <td
                                          className="white-space"
                                          scope="col"
                                          style={{ fontSize: '16px' }}
                                        >
                                          -
                                        </td>
                                        <td
                                          className="white-space"
                                          scope="col"
                                          style={{ fontSize: '16px' }}
                                        >
                                          -
                                        </td>
                                        {this.state.totalCheckbox ? (
                                          <td>{order.bag_total}</td>
                                        ) : null}
                                        {this.state.paymentCheckbox ? (
                                          <td>Not done</td>
                                        ) : null}
                                        {this.state.shippingCheckbox ? (
                                          <td>Shipping</td>
                                        ) : null}
                                        {this.state.noteCheckbox ? (
                                          <td>Note</td>
                                        ) : null}
                                        {this.state.carrierCheckbox ? (
                                          <td>Carrier</td>
                                        ) : null}
                                        {this.state.trackingCheckbox ? (
                                          <td>Tracking</td>
                                        ) : null}
                                        {this.state.viewCheckbox ? (
                                          <td>
                                            <Link
                                              to={
                                                '/vieworder/' + order.order_Id
                                              }
                                            >
                                              View
                                            </Link>
                                          </td>
                                        ) : null}
                                      </tr>
                                    ))
                                  ) : (
                                    <tr>
                                      <td colSpan={12}>No sales Available.</td>
                                    </tr>
                                  )}
                                </tbody>
                              </Table>
                            </>
                          ) : this.state.listView === '2' ? (
                            <div class="row grid-view">
                              {this.state.totalCount ? (
                                this.state.gridData
                              ) : (
                                <tr>
                                  <td colSpan={6}>No Sales Available.</td>
                                </tr>
                              )}
                            </div>
                          ) : null}
                          {this.state.totalCount ? (
                            <ReactPaginate
                              previousLabel={'prev'}
                              nextLabel={'next'}
                              breakLabel={'...'}
                              breakClassName={'break-me'}
                              pageCount={this.state.pageCount}
                              marginPagesDisplayed={2}
                              pageRangeDisplayed={5}
                              onPageChange={this.handlePageClick}
                              containerClassName={'pagination'}
                              subContainerClassName={'pages pagination'}
                              activeClassName={'active'}
                            />
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <ReactToPrint
                trigger={() => (
                  <button
                    ref={(printButton) =>
                      (this.printButtonElement = printButton)
                    }
                    style={{ display: 'none' }}
                  >
                    Print this out!
                  </button>
                )}
                content={() => this.componentRef}
                style={{ display: 'none' }}
              />
              <div id="printMe" style={{ display: 'none' }}>
                <ComponentToPrint
                  ref={(el) => (this.componentRef = el)}
                  style={{ display: 'none' }}
                />
              </div>
              <div>
                {/* <button variant="warning" onClick={() => this.exportAll(this.state.allProduct,"download")}>Export</button> */}

                <ReactToPdf targetRef={targetRef} filename="download.pdf">
                  {({ toPdf }) => (
                    <button
                      onClick={toPdf}
                      ref={(pdfButton) => (this.pdfElement = pdfButton)}
                      style={{ display: 'none' }}
                    >
                      Generate pdf
                    </button>
                  )}
                </ReactToPdf>
                <div
                  style={{ background: 'white' }}
                  ref={targetRef}
                  id="pdfDiv"
                ></div>
                <div id="pdfTableDiv" style={{ display: 'none' }}>
                  <Table
                    responsive
                    hover
                    className="display border-product-list-table"
                    style={{ fontSize: '12px' }}
                    id="pdfDiv"
                  >
                    <thead>
                      <tr>
                        <th scope="col">No</th>
                        <th scope="col">Order</th>
                        <th scope="col">Date/Time</th>
                        <th scope="col">Customer</th>
                        <th scope="col">Product Name</th>
                        <th scope="col">Total</th>
                        <th scope="col">Payment</th>
                        <th scope="col">Shipping</th>
                        <th scope="col">Note</th>
                        <th scope="col">Carrier</th>
                        <th scope="col">Tracking</th>
                        <th scope="col">View</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.totalCount ? (
                        this.state.listdata.map((order, index) => (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{order.order_Id}</td>
                            <td>
                              {moment(order.createdAt).format(
                                'DD/MM/YYYY,h:mm a '
                              )}
                            </td>
                            <th>
                              {order.userId
                                ? order.userId.first_name +
                                  ' ' +
                                  order.userId.last_name
                                : null}
                            </th>
                            <td>
                              {order.productId
                                ? order.productId.product_name
                                : null}
                            </td>
                            <td>{order.bag_total}</td>
                            <td>Not done</td>
                            <td>Shipping</td>
                            <td>Note</td>
                            <td>Carrier</td>
                            <td>Tracking</td>
                            <td>View</td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan={6}>No Product Available.</td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="sidenav-overlay"></div>
        <div className="drag-target"></div>

        <Footer />
      </body>
    );
  }
}

export default SalesReport;
