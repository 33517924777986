import React from "react";
import axios from "axios";
//import Select from "react-select";
import { Link } from "react-router-dom";
//import Sidebar from "../../Component/Sidebar/Sidebar";
//import Header from "../../Component/Header/Header";
import Footer from "../../Component/Footer/Footer";
import global from "../../global";
import moment from "moment";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import "./style.css";
var seller = {};
var startDateError = "";
var endDateError = "";
var typeError = "";
var pageError = "";
var submitDisable = false;
var imageError = "";
var sendImage = "";
toast.configure();
const CustomToast = () => {
  return (
    <div>
      <i class="fa fa-check success-toast"></i>
      Sponsor Banner Created Successfully
    </div>
  );
};
class AddCoupon extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pages: [""],
      selectedPage: [],
      rates: [""],
      bannerPageName: [],
      bannerPrice: [],
      bannerImage: "",
      totalAmount: "",
      fileName: "",
      bannerPages: [],
      s: [],
      days: "",
      percentage: "",
      startDate: "",
      endDate: "",
      minQty: "",
      maxQty: "",
      offerType: "",
      productId: "",
      categoryId: "",
      brandId: "",
      products: [],
      categories: [],
      brands: []
    };
    startDateError = "";
    endDateError = "";
    submitDisable = false;
    pageError = "";
  }
  componentWillMount() {
    if (localStorage.getItem("sellerdata")) {
      seller = JSON.parse(localStorage.getItem("sellerdata"));
      this.setState({ seller: JSON.parse(localStorage.getItem("sellerdata")) });
      //this.dashboardAPI(seller.id);
    }

    var urlencoded = new URLSearchParams();

    axios
      .post(global.baseUrl + "sellerbannerpagelist", urlencoded, {
        headers: { "Content-Type": "application/x-www-form-urlencoded" }
      })
      .then(result => {
        console.log(result.data);
        if (result.data.status) {
          this.setState({ bannerPages: result.data.data });
          //localStorage.setItem('userdata', JSON.stringify(result.data))
          // this.props.history.push("/offerlist");
        }
      });
  }
  handleChange = event => {
    this.setState({ [event.target.name]: event.target.value }, () => {
      this.setDays();
    });
  };
  setDays = () => {
    let days = moment(this.state.endDate).diff(
      moment(this.state.startDate),
      "days"
    );
    this.setState({ days: days });
    let bannerPrice = this.state.bannerPrice;

    let total = 0;
    for (let j = 0; j < bannerPrice.length; j++) {
      total = total + parseInt(bannerPrice[j]);
    }
    total = total * (days + 1);
    this.setState({ total: total });
  };
  addPageHandler = () => {
    let pages = this.state.pages;
    pages.push("");
    this.setState({ pages: pages });
    let rates = this.state.rates;
    rates.push("");
    this.setState({ rates: rates });
  };
  handleBannerChange = (event, index) => {
    console.log(this.state.selectedPage, "index");
    let flag = 1;
    let selectedPage = this.state.selectedPage;
    for (let i = 0; i < selectedPage.length; i++) {
      if (selectedPage[i] === event.target.value) {
        flag = 0;
        console.log("working");
      }
    }
    console.log(flag);
    let bannerPageName = this.state.bannerPageName;
    let bannerPrice = this.state.bannerPrice;

    if (flag === 1) {
      console.log("Working", flag);
      selectedPage[index] = event.target.value;
      bannerPageName = this.state.bannerPageName;
      bannerPrice = this.state.bannerPrice;
      for (let i = 0; i < this.state.bannerPages.length; i++) {
        if (event.target.value === this.state.bannerPages[i].id) {
          bannerPageName[index] = this.state.bannerPages[i].page;
          bannerPrice[index] = this.state.bannerPages[i].price;
        }
      }

      this.setState({
        selectedPage: selectedPage,
        bannerPageName: bannerPageName,
        bannerPrice: bannerPrice
      });
    } else {
      selectedPage[index] = "";
      bannerPrice[index] = 0;
      this.setState({
        selectedPage: selectedPage
      });
    }
    if (event.target.value === "") {
      bannerPrice[index] = 0;
    }
    console.log(bannerPrice);
    let total = 0;
    for (let j = 0; j < bannerPrice.length; j++) {
      total = total + parseInt(bannerPrice[j]);
    }
    total = total * (this.state.days + 1);
    this.setState({ total: total });
  };
  onImageChange = event => {
    if (event.target.files && event.target.files[0]) {
      var Extension = ["jpg", "jpeg", "png", "gif", "tiff"];
      var extArray = event.target.files[0].name.split(".");
      var ext = extArray[extArray.length - 1];
      var flag = 0;
      for (let i = 0; i < Extension.length; i++) {
        if (ext.toLowerCase() === Extension[i]) {
          flag = 1;
        }
      }
      if (flag) {
        this.setState({ imagError: "" });
        imageError = "";

        let reader = new FileReader();
        reader.onload = e => {
          this.setState({
            bannerImage: e.target.result
          });
        };
        this.setState({
          fileName: event.target.files[0].name
        });
        reader.readAsDataURL(event.target.files[0]);
        sendImage = event.target.files[0];
        console.log("image", sendImage);
      } else {
        this.setState({
          bannerImage: "",
          imagError: "Please select only image",
          fileName: ""
        });
        sendImage = "";
        imageError = "Please select only image";
      }
    } else {
      sendImage = "";
    }
  };
  APICall = () => {
    var urlencoded = new URLSearchParams();
    urlencoded.append("sellerId", this.state.seller.id);
    urlencoded.append("start_date", this.state.startDate);
    urlencoded.append("end_date", this.state.endDate);
    urlencoded.append("image", this.state.bannerImage);
    axios
      .post(global.baseUrl + "selleraddbannerform", urlencoded, {
        headers: { "Content-Type": "application/x-www-form-urlencoded" }
      })
      .then(result => {
        console.log(result.data);
        if (result.data.status) {
          this.setState({ bannerId: result.data.data.id }, () => {
            this.AddBannerAPI();
          });
          //localStorage.setItem('userdata', JSON.stringify(result.data))
          ///this.props.history.push("/offerlist");
        }
      });
  };
  AddBannerAPI() {
    let days = moment(this.state.endDate).diff(
      moment(this.state.startDate),
      "days"
    );
    for (let i = 0; i < this.state.selectedPage.length; i++) {
      if (this.state.selectedPage[i] !== "") {
        let urlencoded = new URLSearchParams();
        urlencoded.append("pageId", this.state.selectedPage[i]);
        urlencoded.append("bannerId", this.state.bannerId);
        urlencoded.append("sellerId", this.state.seller.id);
        urlencoded.append("days", parseInt(days) + 1);
        urlencoded.append("price", "0");

        axios
          .post(global.baseUrl + "selleraddbanner", urlencoded, {
            headers: { "Content-Type": "application/x-www-form-urlencoded" }
          })
          .then(result => {
            console.log(result.data);
            if (result.data.status) {
              //this.AddBannerAPI()
              //localStorage.setItem('userdata', JSON.stringify(result.data))
              ///this.props.history.push("/offerlist");
            }
          });
      }
      console.log(i, this.state.selectedPage.length);
      if (i === this.state.selectedPage.length - 1) {
        toast.success(<CustomToast />, { position: toast.POSITION.TOP_RIGHT });

        this.props.history.push("/bannerlist");
      }
    }
  }
  addImage = () => {
    if (sendImage) {
      const apiUrl = global.baseUrl + "sellerImageUpload";
      const formData = new FormData();
      formData.append("myFile", sendImage);
      const config = {
        headers: {
          "content-type": "multipart/form-data"
        }
      };
      axios
        .post(apiUrl, formData, config)
        .then(response => {
          console.log("image add", response.data.filename);
          this.setState({ bannerImage: response.data.path });
          this.APICall();
        })
        .catch(error => {});
    }
  };
  handleSubmit = e => {
    let numberCheck = /^[0-9]\d*(\.\d+)?$/;

    ///^[0-9]+$/;
    if (this.state.startDate === "") {
      this.setState({ startDateError: "Please select start date" });
      startDateError = "Please select start date";
    } else if (this.state.startDate.length > 10) {
      this.setState({ startDateError: "Please select valid start date" });
      startDateError = "Please select valid start date";
    } else {
      this.setState({ startDateError: "" });
      startDateError = "";
    }
    if (this.state.endDate === "") {
      this.setState({ endDateError: "Please select end date" });
      endDateError = "Please select end date";
    } else if (this.state.endDate.length > 10) {
      this.setState({ endDateError: "Please select valid end date" });
      endDateError = "Please select valid end date";
    } else {
      if (Date.parse(this.state.endDate) < Date.parse(this.state.startDate)) {
        this.setState({ endDateError: "End date less then start date" });
        endDateError = "End date less then start date";
      } else {
        this.setState({ endDateError: "" });
        endDateError = "";
      }
    }
    if (this.state.bannerImage === "") {
      this.setState({ imageError: "Please select file" });
      imageError = "Please select file";
    } else {
      this.setState({ imageError: "" });
      imageError = "";
    }
    if (this.state.selectedPage.length < 1) {
      this.setState({ pageError: "Please select page" });
      pageError = "Please select desired page";
    } else if (this.state.selectedPage.length > 0) {
      let flag = 0;
      for (let i = 0; i < this.state.selectedPage.length; i++) {
        console.log(this.state.selectedPage[i], "djkgfhg");
        if (this.state.selectedPage[i]) {
          flag = 1;
        }
      }
      if (flag === 0) {
        this.setState({ pageError: "Please selects page" });
        pageError = "Please selects desired page";
      } else {
        this.setState({ pageError: "" });
        pageError = "";
      }
    } else {
      this.setState({ pageError: "" });
      pageError = "";
    }
    if (
      this.state.startDate &&
      this.state.endDate &&
      this.state.bannerImage &&
      pageError === "" &&
      startDateError === "" &&
      endDateError === "" &&
      imageError === ""
    ) {
      submitDisable = true;
      this.addImage();
    }
  };

  resetHandler = () => {
    this.setState({
      percentage: "",
      startDate: "",
      endDate: "",
      minQty: "",
      maxQty: "",
      offerType: "",
      productId: "",
      categoryId: "",
      brandId: "",
      products: [],
      categories: [],
      brands: []
    });
    startDateError = "";
    endDateError = "";
    typeError = "";
    pageError = "";
    imageError = "";
    submitDisable = false;
  };
  render() {
    return (
      <body
        class="vertical-layout vertical-menu-modern semi-dark-layout 2-columns  navbar-floating footer-static  "
        data-open="click"
        data-menu="vertical-menu-modern"
        data-col="2-columns"
        data-layout="semi-dark-layout"
      >
        {/* <Header />
        <Sidebar /> */}
        <div class="app-content content">
          <div class="content-overlay"></div>
          <div class="header-navbar-shadow"></div>
          <div class="content-wrapper">
            <div class="content-header-left col-md-9 col-12 mb-2">
              <div class="row breadcrumbs-top">
                <div class="col-12">
                  <h2 class="content-header-title float-left mb-0">
                    Add Coupon
                  </h2>
                  <div class="breadcrumb-wrapper col-12">
                    <ol class="breadcrumb">
                      <li class="breadcrumb-item">
                        <Link to="/offerlist">Coupons</Link>
                      </li>
                      <li class="breadcrumb-item active">Add Coupon</li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>
            <div class="content-body">
              <section id="dashboard-analytics">
                <div class="card">
                  <div class="card-body">
                    <div
                      class="tab-pane active"
                      id="account"
                      aria-labelledby="account-tab"
                      role="tabpanel"
                    >
                      <div class="row mb-1">
                        <div class="col-12 col-lg-2">
                          <label className="b-label">
                            Start Date/Time<span class="required-span1">*</span>
                          </label>
                        </div>
                        <div class="col-12 col-lg-3">
                          <div class="form-group">
                            <input
                              type="date"
                              class="form-control"
                              placeholder="Start date"
                              name="startDate"
                              value={this.state.startDate}
                              onChange={this.handleChange}
                              maxLength="10"
                            />
                            <p class="error-msg">{startDateError} </p>
                          </div>
                        </div>
                        <div class="col-12 col-lg-2">
                          <div class="form-group">
                            <input
                              type="taxt"
                              class="form-control"
                              placeholder="Start date"
                              name="startDate"
                              value={this.state.startDate}
                              onChange={this.handleChange}
                              maxLength="10"
                            />
                            <p class="error-msg">{startDateError} </p>
                          </div>
                        </div>
                      </div>
                      <div class="row mb-1">
                        <div class="col-12 col-lg-2">
                          <label className="b-label">
                            End Date/Time<span class="required-span1">*</span>
                          </label>
                        </div>

                        <div class="col-12 col-lg-3">
                          <div class="form-group">
                            <input
                              type="date"
                              class="form-control"
                              placeholder="Start date"
                              name="startDate"
                              value={this.state.startDate}
                              onChange={this.handleChange}
                              maxLength="10"
                            />
                            <p class="error-msg">{startDateError} </p>
                          </div>
                        </div>
                        <div class="col-12 col-lg-2">
                          <div class="form-group">
                            <input
                              type="taxt"
                              class="form-control"
                              placeholder="Start date"
                              name="startDate"
                              value={this.state.startDate}
                              onChange={this.handleChange}
                              maxLength="10"
                            />
                            <p class="error-msg">{startDateError} </p>
                          </div>
                        </div>
                      </div>
                      <div class="row mb-1">
                        <div class="col-12 col-lg-2">
                          <label className="b-label">
                            Coupon Name<span class="required-span1">*</span>
                          </label>
                        </div>

                        <div class="col-12 col-lg-5">
                          <div class="form-group">
                            <input
                              type="taxt"
                              class="form-control"
                              placeholder="Start date"
                              name="startDate"
                              value={this.state.startDate}
                              onChange={this.handleChange}
                              maxLength="10"
                            />
                            <p class="error-msg">{startDateError} </p>
                          </div>
                        </div>
                      </div>
                      <div class="row mb-1">
                        <div class="col-12 col-lg-2">
                          <label className="b-label">
                            Discount Type<span class="required-span1">*</span>
                          </label>
                        </div>
                        <div class="col-12 col-lg-3">
                          <div class="form-group">
                            <input
                              type="text"
                              class="form-control"
                              placeholder="Start date"
                              name="startDate"
                              value={this.state.startDate}
                              onChange={this.handleChange}
                              maxLength="10"
                            />
                            <p class="error-msg">{startDateError} </p>
                          </div>
                        </div>
                        <div class="col-12 col-lg-2">
                          <div class="form-group">
                            <input
                              type="taxt"
                              class="form-control"
                              placeholder="Start date"
                              name="startDate"
                              value={this.state.startDate}
                              onChange={this.handleChange}
                              maxLength="10"
                            />
                            <p class="error-msg">{startDateError} </p>
                          </div>
                        </div>
                      </div>
                      
                      <div class="row">
                        <div class="col-12 d-flex flex-sm-row flex-column justify-content-end mt-1">
                          <button
                            type="submit"
                            disabled={submitDisable}
                            class="btn btn-primary glow mb-1 mb-sm-0 mr-0 mr-sm-1 login-btn"
                            onClick={this.handleSubmit}
                          >
                            Submit
                          </button>
                          <button
                            type="reset"
                            onClick={this.resetHandler}
                            class="btn btn-outline-primary float-left btn-inline res-btn"
                          >
                            Reset
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>

        <div class="sidenav-overlay"></div>
        <div class="drag-target"></div>
        <Footer />
      </body>
    );
  }
}
export default AddCoupon;
