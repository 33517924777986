import React from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import moment from "moment";
// import Sidebar from "../../Component/Sidebar/Sidebar";
//import Header from "../../Component/Header/Header";
import Footer from "../../Component/Footer/Footer";
import global from "../../global";
import {toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
  Button,
} from 'reactstrap';
import "./Product.css";
import "./treck2.css"
var sellerData = {};

const $ = require("jquery");
$.DataTable = require("datatables.net");
var products=[]

const CustomToast=()=>{
  return(
  <div>
    <i class="fa fa-check success-toast"></i>
    Offer Deleted Successfully
  </div>
  )
}
class OfferProduct extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      seller: "",
      products_id:'',
      products:[],
      day:[],
    };
  }

  componentWillMount() {
    if (localStorage.getItem("sellerdata")) {
      this.setState({ seller: JSON.parse(localStorage.getItem("sellerdata")) });
      sellerData = JSON.parse(localStorage.getItem("sellerdata"));
    }
    var urlencoded = new URLSearchParams();
    // console.log(this.props,"vvvvv");
    urlencoded.append("trackid", this.props.match.params.id);

    axios
      .post(global.baseUrl + "productiffind", urlencoded, {
        headers: { "Content-Type": "application/x-www-form-urlencoded" }
      })
      .then(result => {
        if (result.data.data) {
            console.log(result.data.data[0].product_id,"111222");         
            this.setState({ products_id: result.data.data[0].product_id });
        }})
    this.listAPI()
  }

  // dataTable() {
  //   $(document).ready(function() {
  //     $("#list").DataTable();
  //   });
  //   console.log("working");
  // }
  myFunction=()=> {
    document.getElementById("myDropdown").classList.toggle("show");
  }

  listAPI() {
    console.log(this.state.seller);
    var urlencoded = new URLSearchParams();
    console.log(this.props,"vvvvv");
    urlencoded.append("trackid", this.props.match.params.id);

    axios
      .post(global.baseUrl + "track", urlencoded, {
        headers: { "Content-Type": "application/x-www-form-urlencoded" }
      })
      .then(result => {
        console.log(result.data);
        if (result.data.status) {
            // products = result.data.data;
          result.data.data[0].tracking_details.reverse()
          this.setState({ products: result.data.data });
          let apprr=[];
          result.data.data[0].tracking_details.map((element,index)=>{
              apprr.push(new Date(element.datetime).getDay())
              this.setState({day:apprr});
          })
         
          //localStorage.setItem('userdata', JSON.stringify(result.data))
          //this.props.history.push("/setotp/" + result.data.data.id);

          //this.dataTable(
        }else{
          window.location.reload()
        }
      });
  }
  
  invoicegenerate=()=>{
    var urlencoded = new URLSearchParams();
    console.log(this.state.order,"ffffffffffffffffff")
    // urlencoded.append('product_id',this.state.product_id);
    urlencoded.append('User_id', JSON.parse(localStorage.getItem('treackdata')).user_ID);
    urlencoded.append('order_Id', JSON.parse(localStorage.getItem('treackdata')).orderId.slice(3,JSON.parse(localStorage.getItem('treackdata')).orderId.length));

    axios
      .post(global.baseUrl + 'invoice', urlencoded, {
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      })
      .then((result) => {
        console.log(result.data);
        let url=result.data.data[0].Url;
        var message = {'type':'1', 'data' : [  { "pdfLink" : url } ]};
        window.postMessage(message)
        
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute(
          'download',
          `FileName.pdf`,
        );
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
          // window.location.reload();
        // window.location.reload();
      });
  }

  render() {

    return (
      <body
        class="vertical-layout vertical-menu-modern semi-dark-layout 2-columns  navbar-floating footer-static body1 body2"
        data-open="click"
        data-menu="vertical-menu-modern"
        data-col="2-columns"
        data-layout="semi-dark-layout"
      >
        {/* <Header />
        <Sidebar /> */}
        <div class="app-content content" style={{minHeight: "91vh"}}>
          <div class="content-overlay"></div>
          <div class="header-navbar-shadow"></div>
          <div class="content-wrapper">
            <div class="content-header pow">
              <div class="content-header-left pol-md-9 pol-12 mb-2">
                <div class="pow breadcrumbs-top">
                  <div class="pol-12" style={{display:"flex"}}>
                    <h2 class="content-header-title float-left mb-0">
                    track details
                    </h2>
                    <div class="breadcrumb-wrapper pol-12" style={{width:"60%"}}>
                      <ol class="breadcrumb">
                        <li class="breadcrumb-item li">
                          <Link to="/dashboard">Home</Link>
                        </li>
                        <li class="breadcrumb-item active li">track details</li>
                      </ol>
                    </div>
                    <div class="dropdown">
                      {/* <button onClick={() => this.myFunction()} class="btn btn-primary add-product-link">Purchase shipping lable</button>
                      <div id="myDropdown" class="dropdown-content">
                        <a onClick={() => this.invoicegenerate()}>Purchase shipping lable</a>
                        <Link to={`/contectbuyer/${JSON.parse(localStorage.getItem('treackdata')).user_ID}`}>Contact Buyer</Link>
                      </div> */}
                      <Link class="btn btn-primary add-product-link" to={`/contectbuyer/${JSON.parse(localStorage.getItem('treackdata')).user_ID}/${this.state.products_id}`}>Contact Buyer</Link>
                    </div>
                  </div>
                </div>
              </div>
              <div class="content-header-right text-md-right pol-md-3 pol-12 d-md-block">
                {/* <div class="form-group breadcrum-right xs-text-right">
                  <Link
                    to="/addoffer"
                    id="addRow"
                    class="btn btn-primary mb-2 add-product-link"
                  >
                    <i class="feather icon-plus"></i>Add Offer
                  </Link>
                </div> */}
              </div>
            </div>
            <div class="content-body">
              <section id="add-row">
                <div class="pow">
                  <div class="pol-12">
                    <div class="card">
                      {/* <div class="card-header">
                                    <h4 class="card-title">Add rows</h4>
                                </div> */}
                                {/* {this.state.products[0].final_status===} */}
                                        <div class="card-content">
                                            <div class="card-body">
                                            <div class="pow por">
      <div class="pol-10 pol-md-8 pol-lg-6 mx-auto">
        <div class="tracker-progress-bar tracker-progress-bar-with-dots">
          <div class="progress">
            <div class="progress-bar bg-success" style={{width:`${this.state.products[0]&&this.state.products[0].tracking_details.length<=1?'5%':this.state.products[0]&&this.state.products[0].tracking_details.length==2?'17%':this.state.products[0]&&this.state.products[0].tracking_details.length==3?'35%':this.state.products[0]&&this.state.products[0].tracking_details.length==4?"42%":this.state.products[0]&&this.state.products[0].tracking_details.length==5?"49%":this.state.products[0]&&this.state.products[0].tracking_details.length==6?"57%":this.state.products[0]&&this.state.products[0].tracking_details.length==7?"67%":"100%"}`}}></div>
          </div>

          <div class="success">
            <span class="dot state-0"></span>

            <span class={`state-label state-0 past-state ${this.state.products[0]&&this.state.products[0].final_status==='pre_transit'?'current-state':''}`}>Pre-Transit</span>

            <span class="dot state-1"></span>

            <span class={`state-label state-1 past-state ${this.state.products[0]&&this.state.products[0].final_status==='in_transit'?'current-state':''}`}>In Transit</span>

            <span class="dot state-2"></span>

            <span class={`state-label state-2 past-state ${this.state.products[0]&&this.state.products[0].final_status==='out_for_delivery'?'current-state':''}`}>Out for Delivery</span>

            <span class="dot state-3"></span>

            <span class={`state-label state-3 ${this.state.products[0]&&this.state.products[0].final_status==='delivered'?'current-state':''}`}>Delivered</span>
          </div>
        </div>
      </div>
    </div>
  
                                            </div>
                                            <div class="pol-md">



                                                <ul class="tracking-details list-unstyled mb-4 border border-light">

                                                {this.state.products[0]&&this.state.products[0].tracking_details.map((element,index)=>(<>
                                                {/* {console.log(element,"dddddd")} */}
    {this.state.day[index]==this.state.day[index-1]&&index!==0&&<li class="px-3 py-2 mb-3 font-weight-bold bg-gray-100 text-secondary li">
                                                       {element.datetime}
                                                    </li>}
                                                {index==0&&<li class="px-3 py-2 mb-3 font-weight-bold bg-gray-100 text-secondary li">
                                                       {element.datetime}
                                                    </li>}
                                                    
                                                <li class="d-flex align-items-center mb-3 li">
                                                        <div class="tracking-details-time text-gray-300 font-size-h5 text123">
                                                        {new Date(element.datetime).toLocaleTimeString('en-US', { hour12: false, 
                                             hour: "numeric", 
                                             minute: "numeric"})}
                                                        </div>
                                                        
                                                        <div style={{marginLeft:"2%",marginRight:"2%"}}> {index==0?<i style={{fontSize:"24px",color:'#5cb85c'}} className="far fa-check-circle"></i>:<i  style={{fontSize:"24px",color:'#2396FB'}} class="far fa-clock"></i>}</div>
                                                        <div>
                                                            <div class="font-size-h5">
                                                                <strong>{element.status}</strong>
                                                            </div>
                                                            <div class="text-gray-300">


                                                            {element.message}



                                                            </div>
                                                        </div>
                                                    </li>
                                                    </>    ))}

                                                    {/* <li class="px-3 py-2 mb-3 font-weight-bold bg-gray-100 text-secondary">
                                                        January 13, 2022
                                                    </li>


                                                    <li class="d-flex align-items-center mb-3">
                                                        <div class="tracking-details-time text-gray-300 font-size-h5 text-right">
                                                            3:12am
                                                        </div>
                                                        <div class="font-size-h3 px-3 px-lg-4 ep-icon-theme1-delivered text-success"></div>
                                                        <div>
                                                            <div class="font-size-h5">
                                                                <strong>Delivered</strong>
                                                            </div>
                                                            <div class="text-gray-300">


                                                                CHARLESTON,
                                                                SC
                                                                29407



                                                            </div>
                                                        </div>
                                                    </li>



                                                    <li class="px-3 py-2 mb-3 font-weight-bold bg-gray-100 text-secondary">
                                                        January 12, 2022
                                                    </li>


                                                    <li class="d-flex align-items-center mb-3">
                                                        <div class="tracking-details-time text-gray-300 font-size-h5 text-right">
                                                            10:20pm
                                                        </div>
                                                        <div class="font-size-h3 px-3 px-lg-4 ep-icon-theme1-clock text-secondary"></div>
                                                        <div>
                                                            <div class="font-size-h5">
                                                                <strong>Out for Delivery</strong>
                                                            </div>
                                                            <div class="text-gray-300">


                                                                CHARLESTON,
                                                                SC
                                                                29407



                                                            </div>
                                                        </div>
                                                    </li>




                                                    <li class="d-flex align-items-center mb-3">
                                                        <div class="tracking-details-time text-gray-300 font-size-h5 text-right">
                                                            10:10pm
                                                        </div>
                                                        <div class="font-size-h3 px-3 px-lg-4 ep-icon-theme1-default "></div>
                                                        <div>
                                                            <div class="font-size-h5">
                                                                <strong>Sorting Complete</strong>
                                                            </div>
                                                            <div class="text-gray-300">


                                                                CHARLESTON,
                                                                SC
                                                                29407



                                                            </div>
                                                        </div>
                                                    </li>




                                                    <li class="d-flex align-items-center mb-3">
                                                        <div class="tracking-details-time text-gray-300 font-size-h5 text-right">
                                                            4:30pm
                                                        </div>
                                                        <div class="font-size-h3 px-3 px-lg-4 ep-icon-theme1-default "></div>
                                                        <div>
                                                            <div class="font-size-h5">
                                                                <strong>Arrived at Post Office</strong>
                                                            </div>
                                                            <div class="text-gray-300">


                                                                CHARLESTON,
                                                                SC
                                                                29407



                                                            </div>
                                                        </div>
                                                    </li>




                                                    <li class="d-flex align-items-center mb-3">
                                                        <div class="tracking-details-time text-gray-300 font-size-h5 text-right">
                                                            1:39pm
                                                        </div>
                                                        <div class="font-size-h3 px-3 px-lg-4 ep-icon-theme1-default "></div>
                                                        <div>
                                                            <div class="font-size-h5">
                                                                <strong>Arrived at USPS Facility</strong>
                                                            </div>
                                                            <div class="text-gray-300">


                                                                COLUMBIA,
                                                                SC
                                                                29201



                                                            </div>
                                                        </div>
                                                    </li>



                                                    <li class="px-3 py-2 mb-3 font-weight-bold bg-gray-100 text-secondary">
                                                        January 11, 2022
                                                    </li>


                                                    <li class="d-flex align-items-center mb-3">
                                                        <div class="tracking-details-time text-gray-300 font-size-h5 text-right">
                                                            12:03pm
                                                        </div>
                                                        <div class="font-size-h3 px-3 px-lg-4 ep-icon-theme1-default "></div>
                                                        <div>
                                                            <div class="font-size-h5">
                                                                <strong>Arrived at USPS Origin Facility</strong>
                                                            </div>
                                                            <div class="text-gray-300">


                                                                NORTH HOUSTON,
                                                                TX
                                                                77315



                                                            </div>
                                                        </div>
                                                    </li>




                                                    <li class="d-flex align-items-center mb-3">
                                                        <div class="tracking-details-time text-gray-300 font-size-h5 text-right">
                                                            1:58am
                                                        </div>
                                                        <div class="font-size-h3 px-3 px-lg-4 ep-icon-theme1-barcode text-secondary"></div>
                                                        <div>
                                                            <div class="font-size-h5">
                                                                <strong>Shipping Label Created</strong>
                                                            </div>
                                                            <div class="text-gray-300">


                                                                HOUSTON,
                                                                TX
                                                                77063



                                                            </div>
                                                        </div>
                                                    </li>



                                                    <li class="px-3 py-2 mb-3 font-weight-bold bg-gray-100 text-secondary">
                                                        January 10, 2022
                                                    </li>


                                                    <li class="d-flex align-items-center mb-3">
                                                        <div class="tracking-details-time text-gray-300 font-size-h5 text-right">
                                                            1:21pm
                                                        </div>
                                                        <div class="font-size-h3 px-3 px-lg-4 ep-icon-theme1-barcode text-secondary"></div>
                                                        <div>
                                                            <div class="font-size-h5">
                                                                <strong>Pre-Shipment Info Sent to USPS</strong>
                                                            </div>
                                                            <div class="text-gray-300">



                                                            </div>
                                                        </div>
                                                    </li> */}

                                                </ul>


                                            </div>
                                        </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
        <div class="sidenav-overlay"></div>
        <div class="drag-target"></div>

        <Footer />
      </body>
    );
  }
}

export default OfferProduct;