import React from 'react';
import { withRouter } from 'react-router';
// import { Bar, Line } from 'react-chartjs-2';
// import { CustomTooltips } from '@coreui/coreui-plugin-chartjs-custom-tooltips';

import axios from 'axios';
import { Link, Redirect } from 'react-router-dom';
//import Sidebar from "../../Component/Sidebar/Sidebar";
//import Header from "../../Component/Header/Header";
import Footer from '../../Component/Footer/Footer';
import global from '../../global';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import Avtar from '../images/avtar.jpg';
import './style.css';
import {
  // Button,
  // ButtonGroup,
  // ButtonToolbar,
  // Card,
  // CardBody,
  // CardFooter,
  // CardTitle,
  // Col,
  // Progress,
  Row,
} from 'reactstrap';
import {
  // CChartBar,
  CChartLine,
  // CChartDoughnut,
  // CChartRadar,
  // CChartPie,
  // CChartPolarArea,
} from '@coreui/react-chartjs';
import { CCard, CCardBody, CCardHeader } from '@coreui/react';
// import { getStyle } from '@coreui/coreui/dist/js/coreui-utilities';
var URL = require('url').URL;

var seller = {};

var sendImage = '';
var imageError = '';
toast.configure();
const CustomToast = () => {
  return (
    <div>
      <i class="fa fa-check success-toast"></i>
      Please select image( 851*315px)
    </div>
  );
};
// toast.configure();
const CustomToast2 = () => {
  return (
    <div>
      <i class="fa fa-check success-toast"></i>
      You are not allow to open buyer app.
    </div>
  );
};
class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      freeproduct: 0,
      product: 0,
      subscribed: 0,
      soldProduct: 0,
      profileImage: '',
      mainProfileImage: '',
      totalView: 0,
      totalViewfree: 0,
      totalVisit: 0,
      visitor: 0,
      totalOrder: 0,
      totalRevanue: 0,
      totalRevanueFree:0,
      followers: 0,
      following: 0,
      title: '',
      file: '',
      uniquevisitor: 0,
      selectedFile: null,
      imagePreviewUrl: null,
      graph: [],
      graphf: [],
      freetotalOrder: 0,
    };
    sendImage = '';
    imageError = '';
    this.onImageChange = this.onImageChange.bind(this);
  }
  componentDidMount() {
    console.log(this.props, 'recievedMessage');
    const recievedMessage = this.props.location.hash;
    // console.log(recievedMessage,'recievedMessage');
    recievedMessage === '#1' &&
      toast.success(<CustomToast2 />, {
        position: toast.POSITION.TOP_RIGHT,
      });
  }
  componentWillMount() {
    if (localStorage.getItem('sellerdata')) {
      seller = JSON.parse(localStorage.getItem('sellerdata'));
      this.setState({ seller: JSON.parse(localStorage.getItem('sellerdata')) });
      this.dashboardAPI(seller.id);
      this.dashboardGraphAPI(seller.id);
      this.getcourentmonthprice()
      this.state.profileImage = seller.banner_image;
      this.state.mainProfileImage = seller.profile_image;

      if (seller.type === 'Retailer') {
        this.setState({ title: 'Retailer Dashboard' });
      } else {
        this.setState({ title: 'Free Listing Dashboard ' });
      }
    }
  }
  dashboardAPI(Id) {
    console.log(Id, 'id', seller.id);
    var urlencoded = new URLSearchParams();
    urlencoded.append('sellerId', Id);
    axios
      .post(global.baseUrl + 'SellerOrderList', urlencoded, {
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      })
      .then(async(result) => {
        console.log(result.data.data, 'order list');

        if (result.data.data) {
          let temporder = [];
          result.data.data=[...new Map(result.data.data.map(item =>
              [item["order_Id"], item])).values()]
          let seller = this.state.seller;
          
            console.log('enter12')
            let retaildata= await result.data.data.filter(d => 
  
              d.productId.sellerType  === 'Retailer'
          )
          this.setState({totalOrder:retaildata.length})
          
            console.log('enter1234')
            let freeorder= await result.data.data.filter(d => 
  
              d.productId.sellerType  ==="Freelisting"
          )
          this.setState({freetotalOrder:freeorder.length})
         
          // let temporder = [];
          // console.log(result.data.data,"check====1234===")
          // orderList = result.data.data;
        
          // this.setState({
          //   orders: result.data.data,
          //   sortOrder: result.data.data,
          //   orderSummary: temporder,
          //   postData: [],
          // });
          // this.setState({
          //   totalCount: result.data.data.length,
          //   pageCount: Math.ceil(result.data.data.length / this.state.perPage),
          // });
          // this.pagination();
        }
      });
    var urlencoded = new URLSearchParams();
    urlencoded.append('userId', Id);
    axios
      .post(global.baseUrl + 'sellerdashboard', urlencoded, {
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      })
      .then((result) => {
        console.log('countorder==', result.data.data);
        if (result.data) {
          this.setState({
            freeproduct: result.data.data[0].FreeProduct,
            product: result.data.data[0].Product,
            subscribed: result.data.data[0].Subscribed,
            soldProduct: result.data.data[0].Soldproduct,
            totalView: result.data.data[0].Total_view,
            totalViewfree: result.data.data[0].Total_view_Free,
            totalVisit: result.data.data[0].Total_visit,
            // totalOrder: result.data.data[0].Total_order,
            // freetotalOrder: result.data.data[0].Free_Total_order,
            totalRevanue: result.data.data[0].Total_revenue_generated,
            following: result.data.data[0].following,
            followers: result.data.data[0].followers,
            visitor: result.data.data[0].visitors,
            uniquevisitor: result.data.data[0].unique_visitors,
            profileImage: this.state.profileImage,
            mainProfileImage: this.state.mainProfileImage,
          });
          this.forceUpdate();
        }
      });
  }

  getcourentmonthprice=()=>{
    let urlencoded = new URLSearchParams();
    urlencoded.append("id", seller.id);
    axios
      .post(global.baseUrl + "totalrevenue", urlencoded, {
        headers: { "Content-Type": "application/x-www-form-urlencoded" }
      })
      .then(result => {
        console.log(result.data, "amount");
        if (result.data.data[0]) {
          
            // this.setState({totalRevanue :result.data.data[0].retailer,totalRevanueFree :result.data.data[0].freelisting,
            //  });
            // this.setState({paymentAmount : parseInt(result.data.data[0].total) });
          
        }
      });
  }
  // addDefaultSrc(ev){
  //   ev.style.display='none';
  // }

  dashboardGraphAPI(Id) {
    var urlencoded = new URLSearchParams();
    urlencoded.append('userId', Id);
    axios
      .post(global.baseUrl + 'sellerDashboardGraph', urlencoded)
      .then((result) => {
        if (result.data.data) {
          let index1 = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
          index1.map((element, index) => {
            let index2 = result.data.data.findIndex(
              (x) => parseInt(x._id.month) === parseInt(element)
            );
            if (index2 !== -1) {
              this.state.graph.push(result.data.data[index2]._id.count);
            } else {
              this.state.graph.push(null);
            }
          });
          // this.setState({
          //   product: result.data.data.Product,
          //   subscribed: result.data.data.Subscribed,
          //   soldProduct: result.data.data.Soldproduct,
          //   totalView: result.data.data.Total_view,
          //   totalVisit: result.data.data.Total_visit,
          //   totalOrder: result.data.data.Total_order,
          //   totalRevanue: result.data.data.Total_revenue_generated,
          //   following: result.data.data.following,
          //   followers: result.data.data.followers,
          //   visitor: result.data.data.visitors,
          //   uniquevisitor: result.data.data.unique_visitors,
          //   profileImage: this.state.profileImage,
          //   mainProfileImage: this.state.mainProfileImage

          // });
        }
      });

    axios
      .post(global.baseUrl + 'sellerDashboardGraphFree', urlencoded)
      .then((result) => {
        if (result.data.data) {
          let index1 = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
          index1.map((element, index) => {
            let index2 = result.data.data.findIndex(
              (x) => parseInt(x._id.month) === parseInt(element)
            );
            if (index2 !== -1) {
              this.state.graphf.push(result.data.data[index2]._id.count);
            } else {
              this.state.graphf.push(null);
            }
          });
          // this.setState({
          //   product: result.data.data.Product,
          //   subscribed: result.data.data.Subscribed,
          //   soldProduct: result.data.data.Soldproduct,
          //   totalView: result.data.data.Total_view,
          //   totalVisit: result.data.data.Total_visit,
          //   totalOrder: result.data.data.Total_order,
          //   totalRevanue: result.data.data.Total_revenue_generated,
          //   following: result.data.data.following,
          //   followers: result.data.data.followers,
          //   visitor: result.data.data.visitors,
          //   uniquevisitor: result.data.data.unique_visitors,
          //   profileImage: this.state.profileImage,
          //   mainProfileImage: this.state.mainProfileImage

          // });
        }
      });
  }

  onImageChange = (event) => {
    console.log(event.target.files[0], 'check');
    if (event.target.files && event.target.files[0]) {
      var Extension = ['jpg', 'jpeg', 'png', 'gif', 'tiff'];
      var extArray = event.target.files[0].name.split('.');
      var ext = extArray[extArray.length - 1];
      var flag = 0;
      for (let i = 0; i < Extension.length; i++) {
        if (ext.toLowerCase() === Extension[i]) {
          flag = 1;
        }
      }
      if (flag) {
        this.setState({ imageError: '' });
        imageError = '';

        let reader = new FileReader();
        reader.onload = (e) => {
          // this.setState({ profileImage: e.target.result });
          var image = new Image();
          image.src = e.target.result;
          image.onload = () => {
            var height = image.height;
            var width = image.width;
            if (height !== 315 && width !== 851) {
              this.setState({
                imageError: 'Please select image( 851*315px)',
              });
              toast.success(<CustomToast />, {
                position: toast.POSITION.TOP_RIGHT,
              });
              // alert('Please select image( 851*315px)')
              sendImage = '';
            } else {
              this.setState({ profileImage: e.target.result });
              this.addImage();
            }
          };
        };
        reader.readAsDataURL(event.target.files[0]);
        sendImage = event.target.files[0];
      } else {
        this.setState({
          profileImage: '',
          imageError: 'Please select only image',
        });
        sendImage = '';
        imageError = 'Please select only image';
      }
      // this.addImage();
    }
  };
  onProfileImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      var Extension = ['jpg', 'jpeg', 'png', 'gif', 'tiff'];
      var extArray = event.target.files[0].name.split('.');
      var ext = extArray[extArray.length - 1];
      var flag = 0;
      for (let i = 0; i < Extension.length; i++) {
        if (ext.toLowerCase() === Extension[i]) {
          flag = 1;
        }
      }
      if (flag) {
        this.setState({ imageError: '' });
        imageError = '';

        let reader = new FileReader();
        reader.onload = (e) => {
          this.setState({ mainProfileImage: e.target.result });
        };
        reader.readAsDataURL(event.target.files[0]);
        sendImage = event.target.files[0];
        this.addProfileImage();
      } else {
        this.setState({
          mainProfileImage: '',
          imageError: 'Please select only image',
        });
        sendImage = '';
        imageError = 'Please select only image';
      }
      // this.addProfileImage();
    }
  };
  addImage = () => {
    if (sendImage) {
      const apiUrl = global.baseUrl + 'sellerImageUpload';
      const formData = new FormData();
      formData.append('myFile', sendImage);
      const config = {
        headers: {
          'content-type': 'multipart/form-data',
        },
      };
      axios
        .post(apiUrl, formData, config)
        .then((response) => {
          this.setState({
            profileImage: response.data.path,
          });
          const profile = JSON.parse(localStorage.getItem('sellerdata'));
          profile.banner_image = response.data.path;

          localStorage.setItem('sellerdata', JSON.stringify(profile));
          this.UpdateBannerAPI();
        })
        .catch((error) => {});
    } else {
      this.UpdateBannerAPI();
    }
  };
  addProfileImage = () => {
    if (sendImage) {
      const apiUrl = global.baseUrl + 'sellerImageUpload';
      const formData = new FormData();
      formData.append('myFile', sendImage);
      const config = {
        headers: {
          'content-type': 'multipart/form-data',
        },
      };
      axios
        .post(apiUrl, formData, config)
        .then((response) => {
          this.setState({
            mainProfileImage: response.data.path,
          });
          const profile = JSON.parse(localStorage.getItem('sellerdata'));
          profile.profile_image = response.data.path;

          localStorage.setItem('sellerdata', JSON.stringify(profile));
          this.UpdateProfileAPI();
        })
        .catch((error) => {});
    } else {
      this.UpdateProfileAPI();
    }
  };
  UpdateBannerAPI() {
    var urlencoded = new URLSearchParams();
    urlencoded.append('id', this.state.seller.id);
    urlencoded.append('banner_image', this.state.profileImage);

    axios
      .post(global.baseUrl + 'sellerBannerUpdate', urlencoded, {
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      })
      .then((result) => {
        window.location.reload();
      });
  }

  UpdateProfileAPI() {
    var urlencoded = new URLSearchParams();
    urlencoded.append('id', this.state.seller.id);
    urlencoded.append('profile_image', this.state.mainProfileImage);

    axios
      .post(global.baseUrl + 'profileUpdate', urlencoded, {
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      })
      .then((result) => {
        window.location.reload();
      });
  }

  GraphAPI() {
    // var urlencoded = new URLSearchParams();
    // urlencoded.append("id", this.state.seller.id);
    // urlencoded.append("profile_image", this.state.mainProfileImage);

    axios
      .post(
        global.baseUrl + 'Graph',
        {},
        {
          headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        }
      )
      .then((result) => {
        if (result.data) {
          this.setState({
            graph: result.data.data.product,
          });
        }
        // window.location.reload()
      });
  }

  render() {
    if (!localStorage.getItem('sellerdata')) {
      return <Redirect to="/login" />;
    }
    return (
      <body
        class="vertical-layout vertical-menu-modern semi-dark-layout 2-columns  navbar-floating footer-static  "
        data-open="click"
        data-menu="vertical-menu-modern"
        data-col="2-columns"
        data-layout="semi-dark-layout"
      >
        {/* <Header />
        <Sidebar /> */}
        <div class="app-content content">
          <div class="content-overlay"></div>
          <div class="header-navbar-shadow"></div>
          <div class="content-wrapper">
            {this.state.seller.type === 'Retailer' ? (
              <>
                <div
                  className="seller-bg-cover"
                  style={{ backgroundImage: `URL(${this.state.profileImage})` }}
                >
                  {/* <img src={this.state.seller.profile_image}></img> */}
                  <div className="inner">
                    <button className="fa fa-pencil"></button>

                    <input
                      type="file"
                      name="profile_image"
                      onChange={this.onImageChange}
                    />
                    <span className="in">851 X 315</span>
                  </div>
                </div>
                <div className="usersmallprofile">
                  <div className="usp">
                    <button className="fa fa-pencil">
                      <input
                        type="file"
                        name="profile_image"
                        onChange={this.onProfileImageChange}
                      />
                    </button>
                    <img
                      class=""
                      src={
                        this.state.seller
                          ? this.state.seller.profile_image
                          : null
                      }
                      // src={"https://www.venmond.com/demo/vendroid/img/avatar/big.jpg"}
                      // height="32"
                      // width="32"
                      alt="..."
                      // onError={this.addDefaultSrc}
                    />
                    {/* <p>{this.state.imageError}</p> */}
                  </div>
                  {/* <h6>{this.state.imageError}</h6> */}
                  <Link to={'/editprofile'}>
                    <h6>
                      {this.state.seller.name}
                      <button className="fa fa-pencil"> </button>
                    </h6>
                  </Link>
                </div>
                <h6
                  style={{
                    marginTop: '-20px',
                    marginLeft: '50px',
                    color: 'red',
                    marginBottom: '20px',
                  }}
                >
                  {this.state.imageError}
                </h6>
                <div class="row sa-rows">
                  <div class="col-12">
                    <h2
                      class="content-header-title float-left mb-2"
                      style={{ marginLeft: '10px' }}
                    >
                      Retailer Dashboard
                    </h2>
                  </div>

                  <div class="col-lg-3 col-sm-6 col-6 rows-colsa">
                    <div
                      class="card sa-card card-mbs-0"
                      style={{ backgroundColor: '#47a547' }}
                    >
                      <div class="card-header sa-dash-card d-flex align-items-start pb-0">
                        <div class="width-sa-calc">
                          <h2
                            class="text-bold-700 mb-0"
                            style={{ color: '#fff' }}
                          >
                            {this.state.product}
                          </h2>
                          <p style={{ color: '#fff' }}>Total Products</p>
                        </div>
                        <div
                          class="avatar p-50 m-0"
                          style={{ background: '#fff' }}
                        >
                          <div class="avatar-content">
                            <Link to="/productlist">
                              <i class="feather icon-credit-card text-success font-medium-5"></i>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-3 col-sm-6 col-6 rows-colsa">
                    <div
                      class="card sa-card card-mbs-0"
                      style={{ backgroundColor: '#47a547' }}
                    >
                      <div class="card-header sa-dash-card d-flex align-items-start pb-0">
                        <div class="width-sa-calc">
                          <h2
                            class="text-bold-700 mb-0"
                            style={{ color: '#fff' }}
                          >
                            {this.state.totalView}
                          </h2>
                          <p style={{ color: '#fff' }}>Total Views</p>
                        </div>
                        <div
                          class="avatar p-50 m-0"
                          style={{ background: '#fff' }}
                        >
                          <div class="avatar-content">
                            <Link to="/viewedproduct">
                              <i class="feather icon-credit-card text-success font-medium-5"></i>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-3 col-sm-6 col-6 rows-colsa">
                    <div
                      class="card sa-card card-mbs-0"
                      style={{ backgroundColor: '#47a547' }}
                    >
                      <div class="card-header sa-dash-card d-flex align-items-start pb-0">
                        <div class="width-sa-calc">
                          <h2
                            class="text-bold-700 mb-0"
                            style={{ color: '#fff' }}
                          >
                            {this.state.totalOrder}
                          </h2>
                          <p style={{ color: '#fff' }}>Total Order</p>
                        </div>
                        <div
                          class="avatar p-50 m-0"
                          style={{ background: '#fff' }}
                        >
                          <div class="avatar-content">
                            <Link to="/orderlist">
                              <i class="feather icon-credit-card text-success font-medium-5"></i>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-3 col-sm-6 col-6 rows-colsa">
                    <div
                      class="card sa-card card-mbs-0"
                      style={{ backgroundColor: '#47a547' }}
                    >
                      <div class="card-header sa-dash-card d-flex align-items-start pb-0">
                        <div class="width-sa-calc">
                          <h2
                            class="text-bold-700 mb-0"
                            style={{ color: '#fff' }}
                          >
                            {this.state.totalRevanue}
                          </h2>
                          <p class="p-font" style={{ color: '#fff' }}>
                            Revenue generated
                          </p>
                        </div>
                        <div
                          class="avatar p-50 m-0"
                          style={{ background: '#fff' }}
                        >
                          <div class="avatar-content">
                            {/* <Link to="/viewedproduct"> */}
                              <i class="feather icon-credit-card text-success font-medium-5"></i>
                            {/* </Link> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row sa-rows">
                  <div class="col-lg-3 col-sm-6 col-6">
                    {/* <div class="card sa-card" style={{ backgroundColor: "#47a547" }}>
                      <div class="card-header d-flex align-items-start pb-0">
                        <div>
                          <h2 class="text-bold-700 mb-0" style={{ color: "#fff" }}>
                            {this.state.totalOrder}
                          </h2>
                          <p style={{ color: "#fff" }}>Total Order</p>
                        </div>
                        <div class="avatar p-50 m-0" style={{ background: "#fff" }}>
                          <div class="avatar-content">
                            <Link to="/orderlist">
                              <i class="feather icon-credit-card text-success font-medium-5"></i>
                            </Link>
                  <div class="col-lg-3 col-sm-6 col-6 rows-colsa">
                        <div class="card sa-card card-mbs-0" style={{ backgroundColor: "#47a547" }}>
                          <div class="card-header  sa-dash-card d-flex align-items-start pb-0">
                            <div class="width-sa-calc">
                              <h2 class="text-bold-700 mb-0" style={{ color: "#fff" }}>
                                {this.state.totalView}
                              </h2>
                              <p style={{ color: "#fff" }}>Total Views</p>
                            </div>
                            <div class="avatar p-50 m-0" style={{ background: "#fff" }}>
                              <div class="avatar-content">
                                <Link to="/viewedproduct">
                                  <i class="feather icon-credit-card text-success font-medium-5"></i>
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="col-lg-3 col-sm-6 col-6 rows-colsa">
                        <div class="card sa-card card-mbs-0" style={{ backgroundColor: "#47a547" }}>
                          <div class="card-header  sa-dash-card d-flex align-items-start pb-0">
                            <div class="width-sa-calc">
                              <h2 class="text-bold-700 mb-0" style={{ color: "#fff" }}>
                                {this.state.totalOrder}
                              </h2>
                              <p style={{ color: "#fff" }}>Total Order</p>
                            </div>
                            <div class="avatar p-50 m-0" style={{ background: "#fff" }}>
                              <div class="avatar-content">
                                <Link to="/orderlist">
                                  <i class="feather icon-credit-card text-success font-medium-5"></i>
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div> */}
                  </div>

                  <div class="col-lg-3 col-sm-6 col-6">
                    {/* <div class="card sa-card" style={{ backgroundColor: "#47a547" }}>
                      <div class="card-header d-flex align-items-start pb-0">
                        <div>
                          <h2 class="text-bold-700 mb-0" style={{ color: "#fff" }}>
                            {this.state.totalView}
                          </h2>
                          <p class="p-font" style={{ color: "#fff" }}>Total Visited User</p>
                        </div>
                        <div class="avatar p-50 m-0" style={{ background: "#fff" }}>
                          <div class="avatar-content">
                            <Link to="/viewedproduct">
                              <i class="feather icon-credit-card text-success font-medium-5"></i>
                            </Link>
                      <div class="col-lg-3 col-sm-6 col-6 rows-colsa">
                        <div class="card sa-card card-mbs-0" style={{ backgroundColor: "#47a547" }}>
                          <div class="card-header sa-dash-card d-flex align-items-start pb-0">
                            <div class="width-sa-calc">
                              <h2 class="text-bold-700 mb-0" style={{ color: "#fff" }}>
                                {this.state.totalView}
                              </h2>
                              <p style={{ color: "#fff" }}>Total Visited User</p>
                            </div>
                            <div class="avatar p-50 m-0" style={{ background: "#fff" }}>
                              <div class="avatar-content">
                                <Link to="/viewedproduct">
                                  <i class="feather icon-credit-card text-success font-medium-5"></i>
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div> */}
                  </div>

                  <div class="col-lg-3 col-sm-6 col-6">
                    {/* <div class="card sa-card" style={{ backgroundColor: "#47a547" }}>
                      <div class="card-header d-flex align-items-start pb-0">
                        <div>
                          <h2 class="text-bold-700 mb-0" style={{ color: "#fff" }}>
                            {this.state.totalRevanue}
                          </h2>
                          <p class="p-font" style={{ color: "#fff" }}>Revenue generated</p>
                        </div>
                        <div class="avatar p-50 m-0" style={{ background: "#fff" }}>
                          <div class="avatar-content">
                            <Link to="/viewedproduct">
                              <i class="feather icon-credit-card text-success font-medium-5"></i>
                            </Link>
                      <div class="col-lg-3 col-sm-6 col-6 rows-colsa">
                        <div class="card sa-card card-mbs-0" style={{ backgroundColor: "#47a547" }}>
                          <div class="card-header  sa-dash-card d-flex align-items-start pb-0">
                            <div class="width-sa-calc">
                              <h2 class="text-bold-700 mb-0" style={{ color: "#fff" }}>
                                {this.state.totalRevanue}
                              </h2>
                              <p style={{ color: "#fff" }}>Revenue generated</p>
                            </div>
                            <div class="avatar p-50 m-0" style={{ background: "#fff" }}>
                              <div class="avatar-content">
                                <Link to="/viewedproduct">
                                  <i class="feather icon-credit-card text-success font-medium-5"></i>
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div> */}
                  </div>
                </div>
                <div className="product_graphs">
                  <div className="product_graphs_inner">
                    <CCard>
                      <CCardHeader>
                        Sold Product Graph({new Date().getFullYear()})
                      </CCardHeader>
                      <CCardBody>
                        <CChartLine
                          datasets={[
                            {
                              label: 'Sold Product',
                              backgroundColor: 'rgb(71, 165, 71)',
                              // data: [30, 39, 10, 50, 30, 70, 35, 33, 56, 32, 21, 42]
                              data: this.state.graph,
                            },
                          ]}
                          options={{
                            tooltips: {
                              enabled: true,
                            },
                          }}
                          labels="months"
                        />
                      </CCardBody>
                    </CCard>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div
                  className="seller-bg-cover"
                  style={{
                    backgroundImage: `URL(${this.state.profileImage})`,
                    borderRadius: '7px',
                  }}
                >
                  {/* <img src={this.state.seller.profile_image}></img> */}
                  <div className="inner">
                    <button className="fa fa-pencil"></button>

                    <input
                      type="file"
                      name="profile_image"
                      onChange={this.onImageChange}
                    />
                    <span className="in">851 X 315</span>
                  </div>
                </div>
                <div className="usersmallprofile">
                  <div className="usp">
                    <button className="fa fa-pencil">
                      <input
                        type="file"
                        name="profile_image"
                        onChange={this.onProfileImageChange}
                      />
                    </button>
                    <img
                      class=""
                      src={this.state.seller.profile_image}
                      loading="lazy"
                      // src={"https://www.venmond.com/demo/vendroid/img/avatar/big.jpg"}
                      // height="32"
                      // width="32"
                      // onerror={this.style.display='none'}
                      alt="..."
                    />
                    {/* <p>{this.state.imageError}</p> */}
                  </div>
                  <Link to={'/editprofile'}>
                    <h6>
                      {this.state.seller.name}
                      <button className="fa fa-pencil"> </button>
                    </h6>
                  </Link>
                </div>
                <h6
                  style={{
                    marginTop: '-20px',
                    marginLeft: '50px',
                    color: 'red',
                    marginBottom: '20px',
                  }}
                >
                  {this.state.imageError}
                </h6>
                <div class="content-header row">
                  <div class="content-header-left col-md-9 col-12 mb-2">
                    <div class="row breadcrumbs-top">
                      <div class="col-12">
                        <h2 class="content-header-title float-left mb-0">
                          {this.state.title}
                        </h2>
                      </div>
                    </div>
                  </div>
                  <div class="content-header-right text-md-right col-md-3 col-12 d-md-block d-none"></div>
                </div>

                <div class="content-body">
                  <section id="statistics-card">
                    <div class="row sa-rows">
                      <div class="col-lg-3 col-sm-6 col-6 rows-colsa">
                        <div
                          class="card sa-card  card-mbs-0"
                          style={{ backgroundColor: '#47a547' }}
                        >
                          <div class="card-header sa-dash-card d-flex align-items-start pb-0">
                            <div class="width-sa-calc">
                              <h2
                                class="text-bold-700 mb-0"
                                style={{ color: '#fff' }}
                              >
                                {this.state.freeproduct}
                              </h2>
                              <p style={{ color: '#fff' }}>Total Products</p>
                            </div>
                            <div
                              class="avatar p-50 m-0"
                              style={{ background: '#fff' }}
                            >
                              <div class="avatar-content">
                                <Link to="/productFreelistinglist">
                                  <i class="feather icon-credit-card text-success font-medium-5"></i>
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-3 col-sm-6 col-6 rows-colsa">
                        <div
                          class="card sa-card  card-mbs-0"
                          style={{ backgroundColor: '#47a547' }}
                        >
                          <div class="card-header  sa-dash-card d-flex align-items-start pb-0">
                            <div class="width-sa-calc">
                              <h2
                                class="text-bold-700 mb-0"
                                style={{ color: '#fff' }}
                              >
                                {this.state.freetotalOrder}
                              </h2>
                              <p style={{ color: '#fff' }}>Total Order</p>
                            </div>
                            <div
                              class="avatar p-50 m-0"
                              style={{ background: '#fff' }}
                            >
                              <div class="avatar-content">
                                <Link to="viewads/5ef1f6c32aface06e8f0b5b5">
                                  <i class="feather icon-shopping-cart text-danger font-medium-5"></i>
                                </Link>
                                {/* <i class="feather icon-server text-success font-medium-5"></i> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-3 col-sm-6 col-6 rows-colsa">
                        <div
                          class="card sa-card card-mbs-0"
                          style={{ backgroundColor: '#47a547' }}
                        >
                          <div class="card-header sa-dash-card  d-flex align-items-start pb-0">
                            <div class="width-sa-calc">
                              <h2
                                class="text-bold-700 mb-0"
                                style={{ color: '#fff' }}
                              >
                                {this.state.totalViewfree}
                              </h2>
                              <p style={{ color: '#fff' }}>Total View Free</p>
                            </div>
                            <div
                              class="avatar p-50 m-0"
                              style={{ background: '#fff' }}
                            >
                              <div class="avatar-content">
                                <Link to="/viewedproduct">
                                  <i class="feather icon-users text-primary font-medium-5"></i>
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-3 col-sm-6 col-6 rows-colsa">
                        <div
                          class="card sa-card card-mbs-0"
                          style={{ backgroundColor: '#47a547' }}
                        >
                          <div class="card-header  sa-dash-card d-flex align-items-start pb-0">
                            <div class="width-sa-calc">
                              <h2
                                class="text-bold-700 mb-0"
                                style={{ color: '#fff' }}
                              >
                                {this.state.totalRevanueFree}
                              </h2>
                              <p style={{ color: '#fff' }}>Revenue generated</p>
                            </div>
                            <div class="avatar  p-50 m-0" style={{ backgroundColor: '#fff' }}>
                              <div
                                class="avatar-content"
                                style={{ background: '#fff' }}
                              >
                                {/* <Link to="/userlist"> */}
                                  <i class="feather icon-users text-primary font-medium-5"></i>
                                {/* </Link> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-10"></div>

                      <div class="col-lg-3 col-sm-6 col-6">
                        {/* <div class="card sa-card" style={{ backgroundColor: "#47a547" }}>
                      <div class="card-header d-flex align-items-start pb-0">
                        <div>
                          <h2 class="text-bold-700 mb-0" style={{ color: "#fff" }}>
                            {this.state.totalOrder}
                          </h2>
                          <p style={{ color: "#fff" }}>Total Order</p>
                        </div>
                        <div class="avatar p-50 m-0" style={{ background: "#fff" }}>
                          <div class="avatar-content">
                            <Link to="/orderlist">
                              <i class="feather icon-credit-card text-success font-medium-5"></i>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div> */}
                      </div>

                      <div class="col-lg-3 col-sm-6 col-6">
                        {/* <div class="card sa-card" style={{ backgroundColor: "#47a547" }}>
                      <div class="card-header d-flex align-items-start pb-0">
                        <div>
                          <h2 class="text-bold-700 mb-0" style={{ color: "#fff" }}>
                            {this.state.totalView}
                          </h2>
                          <p class="p-font" style={{ color: "#fff" }}>Total Visited User</p>
                        </div>
                        <div class="avatar p-50 m-0" style={{ background: "#fff" }}>
                          <div class="avatar-content">
                            <Link to="/viewedproduct">
                              <i class="feather icon-credit-card text-success font-medium-5"></i>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div> */}
                      </div>

                      <div class="col-lg-3 col-sm-6 col-6">
                        {/* <div class="card sa-card" style={{ backgroundColor: "#47a547" }}>
                      <div class="card-header d-flex align-items-start pb-0">
                        <div>
                          <h2 class="text-bold-700 mb-0" style={{ color: "#fff" }}>
                            {this.state.totalRevanue}
                          </h2>
                          <p class="p-font" style={{ color: "#fff" }}>Revenue generated</p>
                        </div>
                        <div class="avatar p-50 m-0" style={{ background: "#fff" }}>
                          <div class="avatar-content">
                            <Link to="/viewedproduct">
                              <i class="feather icon-credit-card text-success font-medium-5"></i>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div> */}
                      </div>
                    </div>
                    <div>
                      <Row></Row>
                    </div>
                  </section>
                </div>

                <div className="product-graphs">
                  <div className="product_graphs_inner">
                    <CCard>
                      <CCardHeader>
                        Sold Product Graph({new Date().getFullYear()})
                      </CCardHeader>
                      <CCardBody>
                        <CChartLine
                          datasets={[
                            {
                              label: 'Sold Product',
                              backgroundColor: 'rgb(71, 165, 71)',
                              // data: [30, 39, 10, 50, 30, 70, 35, 33, 56, 32, 21, 42]
                              data: this.state.graphf,
                            },
                          ]}
                          options={{
                            tooltips: {
                              enabled: true,
                            },
                          }}
                          labels="months"
                        />
                      </CCardBody>
                    </CCard>
                  </div>
                </div>
              </>
            )}{' '}
          </div>
        </div>

        <div class="sidenav-overlay"></div>
        <div class="drag-target"></div>

        <Footer />
      </body>
    );
  }
}
export default withRouter(Dashboard);
