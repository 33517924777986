import React from 'react'
import {Link} from 'react-router-dom'
class NotFound404 extends React.Component{

    render(){
        return(
            <body class="vertical-layout vertical-menu-modern semi-dark-layout 1-column  navbar-floating footer-static bg-full-screen-image  blank-page blank-page" data-open="click" data-menu="vertical-menu-modern" data-col="1-column" data-layout="semi-dark-layout">
            <div class="app-content content">
                <div class="content-overlay"></div>
                <div class="header-navbar-shadow"></div>
                <div class="content-wrapper">
                    <div class="content-header row">
                    </div>
                    <div class="content-body">
                        <section class="row flexbox-container">
                            <div class="col-xl-7 col-md-8 col-12 d-flex justify-content-center">
                                <div class="card auth-card bg-transparent shadow-none rounded-0 mb-0 w-100">
                                    <div class="card-content">
                                        <div class="card-body text-center">
                                            <img src="../../../app-assets/images/pages/404.png" class="img-fluid align-self-center" alt="branding logo"/>
                                            <h1 class="font-large-2 my-1">404 - Page Not Found!</h1>
                                            <p class="p-2">
                                            Garagelane page not found
                                            </p>
                                            <Link to="/dashboard" class="btn btn-primary btn-lg mt-2" >Back to Home</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
        
                    </div>
                </div>
            </div>
        </body>
        
        )
    }
}
export default NotFound404