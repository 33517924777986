import React from "react";
import axios from "axios";
import { Link } from "react-router-dom";
// import Sidebar from "../../Component/Sidebar/Sidebar";
// import Header from "../../Component/Header/Header";
import Footer from "../../Component/Footer/Footer";
import global from "../../global";
import "./Follow.css";

import Avtar from "../images/avtar.jpg";
var seller = {};
var msg = "No data found";
class FollowList extends React.Component {
  constructor(PROPS) {
    super(PROPS);
    this.state = {
      users: [],
      userlist: [],
      search: ""
    };
  }
  handleSearchChange = e => {
    let search = e.target.value;
    let users = this.state.userlist;
    this.setState({ search: e.target.value });
    if (search) {
      console.log(search, "ssgdhjg");

      users = users.filter(order => {
        const name = order.name;
       // console.log("Working", first_name);
        return (
          name && name.toLowerCase().includes(search.toLowerCase())
        );
      });
      this.setState({ users: users });
    } else {
      this.setState({ users: this.state.userlist });
    }
  };
  componentWillMount() {
    if (localStorage.getItem("sellerdata")) {
      this.setState({ seller: JSON.parse(localStorage.getItem("sellerdata")) });
      seller = JSON.parse(localStorage.getItem("sellerdata"));
      //this.dashboardAPI(seller.id);
    }
    this.listAPI();
  }
  listAPI() {
    var urlencoded = new URLSearchParams();
    urlencoded.append("userId", seller.id);

    axios
      .post(global.baseUrl + "sellerfollowuserlist", urlencoded, {
        headers: { "Content-Type": "application/x-www-form-urlencoded" }
      })
      .then(result => {
        console.log(result.data, "result");
        if (result.data.status) {
          let users = [];
          for (let i = 0; i < result.data.data.length; i++) {
            if (result.data.data[i].name !== "") {
              users[i] = result.data.data[i];
            }
          }
          this.setState(
            {
              users: users,
              userlist: users
            },
            () => {
              console.log(this.state.users,"users");
            }
          );
        }
      });
  }

  handleFollow = (followId, status) => {
    console.log(status);
    if (status === "follow") {
      this.setState({
        followHeader: "Follow User?",
        followContent: "Are you sure you want to follow user?",
        followStatus: "Follow",
        followerId: followId
      });
    } else {
      this.setState({
        followHeader: "Unfollow User?",
        followContent: "Are you sure you want to unfollow user?",
        followStatus: "Unfollow",
        followerId: followId
      });
    }
  };
  handleFollowClick() {
    console.log("working", this.state.seller.id, this.state.followerId);

    var urlencoded = new URLSearchParams();
    urlencoded.append("userId", this.state.seller.id);
    urlencoded.append("followerId", this.state.followerId);

    axios
      .post(global.baseUrl + "sellercreatefollowing", urlencoded, {
        headers: { "Content-Type": "application/x-www-form-urlencoded" }
      })
      .then(result => {
        console.log(result.data);

        this.listAPI();
      });
  }
  render() {
    return (
      <body
        class="vertical-layout vertical-menu-modern semi-dark-layout content-left-sidebar chat-application navbar-floating footer-static  "
        data-open="click"
        data-menu="vertical-menu-modern"
        data-col="content-left-sidebar"
        data-layout="semi-dark-layout"
      >
        {/* <Header />
        <Sidebar /> */}

        <div class="app-content content">
          <div class="content-overlay"></div>
          <div class="header-navbar-shadow"></div>
          <div class="content-wrapper">
            <div class="content-header row">
              <div class="content-header-left col-md-9 col-12 mb-2">
                <div class="row breadcrumbs-top">
                  <div class="col-12">
                    <h2 class="content-header-title float-left mb-0">
                      Following
                    </h2>
                    <div class="breadcrumb-wrapper col-12">
                      <ol class="breadcrumb">
                        <li class="breadcrumb-item">
                          <Link to="/dashboard">Home</Link>
                        </li>
                        <li class="breadcrumb-item active">Following</li>
                      </ol>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="content-body">
              <section id="add-row">
                <div class="row">
                  <div class="col-12">
                    <div class="card follow-box">
                      <div class="card-content ">
                        <div class="card col-12">
                          <span class="sidebar-close-icon"></span>
                          <div class="chat-fixed-search follow-search ">
                            <div class="d-flex align-items-center">
                              <div class="sidebar-profile-toggle position-relative d-inline-flex">
                                <div class="avatar">
                                  {this.state.seller.profile_image ? (
                                    <img
                                    className="media-object rounded-circle"
                                      src={this.state.seller.profile_image}
                                      alt="user_avatar"
                                      height="40"
                                      width="40"
                                    />
                                  ) : (
                                    <img
                                    className="media-object rounded-circle"
                                      src={Avtar}
                                      alt="user_avatar"
                                      height="40"
                                      width="40"
                                    />
                                  )}
                                  <span class="avatar-status-online"></span>
                                </div>

                                <div class="modal-success mr-1 mb-1 d-inline-block">
                                  <div
                                    class="modal fade text-left"
                                    id="success"
                                    tabindex="-1"
                                    role="dialog"
                                    aria-labelledby="myModalLabel110"
                                    aria-hidden="true"
                                  >
                                    <div
                                      class="modal-dialog modal-dialog-centered modal-dialog-scrollable"
                                      role="document"
                                    >
                                      <div class="modal-content">
                                        <div class="modal-header bg-success white">
                                          <h5
                                            class="modal-title"
                                            id="myModalLabel110"
                                          >
                                            {this.state.followHeader}
                                          </h5>
                                          <button
                                            type="button"
                                            class="close"
                                            data-dismiss="modal"
                                            aria-label="Close"
                                          >
                                            <span aria-hidden="true">
                                              &times;
                                            </span>
                                          </button>
                                        </div>
                                        <div class="modal-body">
                                          {this.state.followContent}
                                        </div>
                                        <div class="modal-footer">
                                          <button
                                            type="button"
                                            class="btn btn-success"
                                            data-dismiss="modal"
                                            onClick={() =>
                                              this.handleFollowClick()
                                            }
                                          >
                                            OK
                                          </button>
                                          <button
                                            type="button"
                                            class="btn btn-success "
                                            data-dismiss="modal"
                                            aria-label="Close"
                                          >
                                            Cancel
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div class="bullet-success bullet-sm position-absolute"></div>
                              </div>
                              <fieldset class="form-group position-relative has-icon-left mx-1 my-0 w-100">
                                <input
                                  type="text"
                                  class="form-control round search1"
                                  id="chat-search"
                                  placeholder="Search to follow users"
                                  value={this.state.search}
                                  onChange={this.handleSearchChange}
                                />
                                <div class="form-control-position">
                                  <i class="feather icon-search"></i>
                                </div>
                              </fieldset>
                            </div>
                          </div>
                          <div
                            id="users-list"
                            class="chat-user-list list-group position-relative"
                            style={{ overflow: "auto" }}
                          >
                            <ul class="chat-users-list-wrapper media-list chat-user-list1">
                              {console.log(this.state.users,"uuuu")}
                              {this.state.users.length
                                ? this.state.users.map((user, index) =>
                                    user.name ? (
                                      <li className="chat-li1">
                                        <div class="row">
                                          <div class="col-8 flex-display1">
                                            <div class="pr-1">
                                              <span class="avatar m-0 avatar-md">
                                                {user.profile_image ? (
                                                  <img
                                                    class="media-object rounded-circle"
                                                    src={user.profile_image}
                                                    height="42"
                                                    width="42"
                                                    alt="..."
                                                  />
                                                ) : (
                                                  <img
                                                    class="media-object rounded-circle"
                                                    src={Avtar}
                                                    height="42"
                                                    width="42"
                                                    alt="..."
                                                  />
                                                )}
                                                <i></i>
                                              </span>
                                            </div>
                                            <div class="user-chat-info">
                                              <div class="contact-info">
                                                <h5 class="font-weight-bold mb-0">
                                                  {user.name}
                                                </h5>

                                                <p class="truncate">
                                                  {user.email}
                                                </p>
                                              </div>
                                            </div>
                                          </div>
                                          <div class="col-4">
                                            <div class="contact-meta">
                                              <span class="float-right mb-25">
                                                {user.followStatus ===
                                                "follow" ? (
                                                  <button
                                                    type="button"
                                                    data-toggle="modal"
                                                    data-target="#success"
                                                    onClick={() =>
                                                      this.handleFollow(
                                                        user.id,
                                                        user.followStatus
                                                      )
                                                    }
                                                    class="btn btn-primary float-right btn-inline waves-effect waves-light login-btn follow-btn1"
                                                  >
                                                    {user.followStatus}
                                                  </button>
                                                ) : (
                                                  <button
                                                    type="button"
                                                    data-toggle="modal"
                                                    data-target="#success"
                                                    onClick={() =>
                                                      this.handleFollow(
                                                        user.id,
                                                        user.followStatus
                                                      )
                                                    }
                                                    class="btn btn-outline-primary float-left btn-inline res-btn follow-btn1"
                                                  >
                                                    {user.followStatus}
                                                  </button>
                                                )}
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      </li>
                                    ) : null
                                  )
                                : msg}
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>

        <Footer />
        <div class="sidenav-overlay"></div>
        <div class="drag-target"></div>
      </body>
    );
  }
}
export default FollowList;
