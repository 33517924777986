import React from "react";
import axios from "axios";
//import StarRatings from "react-star-ratings";
//import Slider from "react-slick";

//import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";

import { Link } from "react-router-dom";
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink
} from "reactstrap";

import classnames from "classnames";
import Sidebar from "../../Component/Sidebar/Sidebar";
import Header from "../../Component/Header/Header";
import Footer from "../../Component/Footer/Footer";
import global from "../../global";
import "./style.css";
class SellerForum extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: this.props.match.params.id,
      activeTab: "1",
      topics: []
    };
  }
  componentWillMount() {
    if (localStorage.getItem("sellerdata") !== "") {
      let seller = JSON.parse(localStorage.getItem("sellerdata"));
      let urlencoded = new URLSearchParams();
      urlencoded.append("sellerId", seller.id);
      axios
        .post(global.baseUrl + "sellerpointlist", urlencoded, {
          headers: { "Content-Type": "application/x-www-form-urlencoded" }
        })
        .then(result => {
          console.log(result.data);
          if (result.data.status) {
            this.setState({ topics: result.data.data });
          }
        });
    }
  }
  toggle = tab => {
    if (tab === "1") {
      this.setState({ activeTab: "1" });
    } else if (tab === "2") {
      this.setState({ activeTab: "2" });
    } else if (tab === "3") {
      this.setState({ activeTab: "3" });
    } else if (tab === "4") {
      this.setState({ activeTab: "4" });
    } else if (tab === "5") {
      this.setState({ activeTab: "5" });
    } else if (tab === "6") {
      this.setState({ activeTab: "6" });
    } else if (tab === "7") {
      this.setState({ activeTab: "7" });
    }
    else if (tab === "8") {
      this.setState({ activeTab: "8" });
    }
  };
  render() {
    return (
      <body
        class="vertical-layout vertical-menu-modern semi-dark-layout 2-columns  navbar-floating footer-static  "
        data-open="click"
        data-menu="vertical-menu-modern"
        data-col="2-columns"
        data-layout="semi-dark-layout"
      >
        {/* <Header />
        <Sidebar /> */}

        <div class="app-content content">
          <div class="content-overlay"></div>
          <div class="header-navbar-shadow"></div>
          <div class="content-wrapper">
            <div class="content-header row">
              <div class="content-header-left col-md-9 col-12 mb-2">
                <div class="row breadcrumbs-top">
                  <div class="col-12">
                    <h2 class="content-header-title float-left mb-0">
                      Forum
                    </h2>
                    <div class="breadcrumb-wrapper col-12">
                      <ol class="breadcrumb">
                        <li class="breadcrumb-item">
                          <Link to="/">Home</Link>
                        </li>
                        <li class="breadcrumb-item active">Forum</li>
                      </ol>
                    </div>
                  </div>
                </div>
              </div>
              <div class="content-header-right text-md-right col-md-3 col-12 d-md-block d-none">
                <div class="form-group breadcrum-right">
                  <Link
                    to="/addfaq"
                    id="addRow"
                    class="btn btn-primary mb-2 add-product-link"
                  >
                    <i class="feather icon-plus "></i>Add Topic
                  </Link>
                </div>
              </div>
            </div>
            <div class="content-body">
              <section class="users-edit">
                <div class="card card-vh">
                  <div class="card-content">
                    <div class="card-body">
                      <Nav tabs>
                        <NavItem>
                          <NavLink
                            className={classnames({
                              active: this.state.activeTab === "1"
                            })}
                            onClick={() => {
                              this.toggle("1");
                            }}
                          >
                            <span class="d-none d-sm-block">All</span>
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className={classnames({
                              active: this.state.activeTab === "2"
                            })}
                            onClick={() => {
                              this.toggle("2");
                            }}
                          >
                            <span class="d-none d-sm-block">Ordering</span>
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className={classnames({
                              active: this.state.activeTab === "3"
                            })}
                            onClick={() => {
                              this.toggle("3");
                            }}
                          >
                            <span class="d-none d-sm-block">
                              Shipping & Exchange
                            </span>
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className={classnames({
                              active: this.state.activeTab === "4"
                            })}
                            onClick={() => {
                              this.toggle("4");
                            }}
                          >
                            <span class="d-none d-sm-block">Return & Exchanges</span>
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className={classnames({
                              active: this.state.activeTab === "5"
                            })}
                            onClick={() => {
                              this.toggle("5");
                            }}
                          >
                            <span class="d-none d-sm-block">Auto Delivery</span>
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className={classnames({
                              active: this.state.activeTab === "6"
                            })}
                            onClick={() => {
                              this.toggle("6");
                            }}
                          >
                            <span class="d-none d-sm-block">EasyPay</span>
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className={classnames({
                              active: this.state.activeTab === "7"
                            })}
                            onClick={() => {
                              this.toggle("7");
                            }}
                          >
                            <span class="d-none d-sm-block">GiftCard</span>
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className={classnames({
                              active: this.state.activeTab === "8"
                            })}
                            onClick={() => {
                              this.toggle("8");
                            }}
                          >
                            <span class="d-none d-sm-block">Payment</span>
                          </NavLink>
                        </NavItem>
                     
                      </Nav>

                      <TabContent activeTab={this.state.activeTab}>
                        <TabPane tabId="1">
                          <div
                            class="tab-pane active"
                            id="account"
                            aria-labelledby="account-tab"
                            role="tabpanel"
                          >
                            <div>
                              <div class="card-header">
                                <div class="card-title mb-2">All Topics</div>
                              </div>
                              <div class="card bg-transparent border-0 shadow-none collapse-icon accordion-icon-rotate">
                                <div class="card-body">
                                  <div class="col-xl-12 col-md-12 col-12">
                                    <div class="card bg-transparesnt border-0 shadow-none collapse-icon accordion-icon-rotate">
                                      <div class="card-body p-0">
                                        <div
                                          class="accordion search-content-info"
                                          id="accordionExample"
                                        >
                                          {this.state.topics.map(
                                            (topic, index) => (
                                              <div class="collapse-margin search-content mt-0 que-ans">
                                                <div
                                                  class="card-header"
                                                  id={"heading" + index}
                                                  role="button"
                                                  data-toggle="collapse"
                                                  data-target={
                                                    "#collapse" + index
                                                  }
                                                  aria-expanded="false"
                                                  aria-controls={
                                                    "collapse" + index
                                                  }
                                                >
                                                  <span class="lead collapse-title">
                                                    {topic.question}
                                                  </span>
                                                </div>
                                                <div
                                                  id={"collapse" + index}
                                                  class="collapse"
                                                  aria-labelledby={
                                                    "heading" + index
                                                  }
                                                  data-parent="#accordionExample"
                                                >
                                                  <div class="card-body">
                                                    {topic.answer}
                                                  </div>
                                                </div>
                                              </div>
                                            )
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </TabPane>
                        <TabPane tabId="2">
                          <div>
                            <div class="card-header">
                              <div class="card-title mb-2">Ordering</div>
                            </div>
                            <div class="card bg-transparent border-0 shadow-none collapse-icon accordion-icon-rotate">
                              <div class="card-body">
                                <div class="col-xl-12 col-md-12 col-12">
                                  <div class="card bg-transparesnt border-0 shadow-none collapse-icon accordion-icon-rotate">
                                    <div class="card-body p-0">
                                      <div
                                        class="accordion search-content-info"
                                        id="accordionExample"
                                      >
                                        {this.state.topics.map((topic, index) =>
                                          topic.topic_type === "ordering" ? (
                                            <div class="collapse-margin search-content mt-0 que-ans">
                                              <div
                                                class="card-header"
                                                id={"heading" + index}
                                                role="button"
                                                data-toggle="collapse"
                                                data-target={
                                                  "#collapse" + index
                                                }
                                                aria-expanded="false"
                                                aria-controls={
                                                  "collapse" + index
                                                }
                                              >
                                                <span class="lead collapse-title">
                                                  {topic.question}
                                                </span>
                                              </div>
                                              <div
                                                id={"collapse" + index}
                                                class="collapse"
                                                aria-labelledby={
                                                  "heading" + index
                                                }
                                                data-parent="#accordionExample"
                                              >
                                                <div class="card-body">
                                                  {topic.answer}
                                                </div>
                                              </div>
                                            </div>
                                          ) : null
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </TabPane>
                        <TabPane tabId="3">
                          <div>
                            <div class="card-header">
                              <div class="card-title mb-2">
                                Shipping & Delivery
                              </div>
                            </div>
                            <div class="card bg-transparent border-0 shadow-none collapse-icon accordion-icon-rotate">
                              <div class="card-body">
                                <div class="col-xl-12 col-md-12 col-12">
                                  <div class="card bg-transparesnt border-0 shadow-none collapse-icon accordion-icon-rotate">
                                    <div class="card-body p-0">
                                      <div
                                        class="accordion search-content-info"
                                        id="accordionExample"
                                      >
                                        {this.state.topics.map((topic, index) =>
                                          topic.topic_type ===
                                          "shipping_delivery" ? (
                                            <div class="collapse-margin search-content mt-0 que-ans">
                                              <div
                                                class="card-header"
                                                id={"heading" + index}
                                                role="button"
                                                data-toggle="collapse"
                                                data-target={
                                                  "#collapse" + index
                                                }
                                                aria-expanded="false"
                                                aria-controls={
                                                  "collapse" + index
                                                }
                                              >
                                                <span class="lead collapse-title">
                                                  {topic.question}
                                                </span>
                                              </div>
                                              <div
                                                id={"collapse" + index}
                                                class="collapse"
                                                aria-labelledby={
                                                  "heading" + index
                                                }
                                                data-parent="#accordionExample"
                                              >
                                                <div class="card-body">
                                                  {topic.answer}
                                                </div>
                                              </div>
                                            </div>
                                          ) : null
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </TabPane>
                        <TabPane tabId="4">
                          <div>
                            <div class="card-header">
                              <div class="card-title mb-2">
                                Return & Exchange
                              </div>
                            </div>
                            <div class="card bg-transparent border-0 shadow-none collapse-icon accordion-icon-rotate">
                              <div class="card-body">
                                <div class="col-xl-12 col-md-12 col-12">
                                  <div class="card bg-transparesnt border-0 shadow-none collapse-icon accordion-icon-rotate">
                                    <div class="card-body p-0">
                                      <div
                                        class="accordion search-content-info"
                                        id="accordionExample"
                                      >
                                        {this.state.topics.map((topic, index) =>
                                          topic.topic_type ===
                                          "auto_delivery" ? (
                                            <div class="collapse-margin search-content mt-0 que-ans">
                                              <div
                                                class="card-header"
                                                id={"heading" + index}
                                                role="button"
                                                data-toggle="collapse"
                                                data-target={
                                                  "#collapse" + index
                                                }
                                                aria-expanded="false"
                                                aria-controls={
                                                  "collapse" + index
                                                }
                                              >
                                                <span class="lead collapse-title">
                                                  {topic.question}
                                                </span>
                                              </div>
                                              <div
                                                id={"collapse" + index}
                                                class="collapse"
                                                aria-labelledby={
                                                  "heading" + index
                                                }
                                                data-parent="#accordionExample"
                                              >
                                                <div class="card-body">
                                                  {topic.answer}
                                                </div>
                                              </div>
                                            </div>
                                          ) : null
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </TabPane>
                        
                        <TabPane tabId="5">
                          <div>
                            <div class="card-header">
                              <div class="card-title mb-2">
                                Auto Delivery
                              </div>
                            </div>
                            <div class="card bg-transparent border-0 shadow-none collapse-icon accordion-icon-rotate">
                              <div class="card-body">
                                <div class="col-xl-12 col-md-12 col-12">
                                  <div class="card bg-transparesnt border-0 shadow-none collapse-icon accordion-icon-rotate">
                                    <div class="card-body p-0">
                                      <div
                                        class="accordion search-content-info"
                                        id="accordionExample"
                                      >
                                        {this.state.topics.map((topic, index) =>
                                          topic.topic_type ===
                                          "auto_delivery" ? (
                                            <div class="collapse-margin search-content mt-0 que-ans">
                                              <div
                                                class="card-header"
                                                id={"heading" + index}
                                                role="button"
                                                data-toggle="collapse"
                                                data-target={
                                                  "#collapse" + index
                                                }
                                                aria-expanded="false"
                                                aria-controls={
                                                  "collapse" + index
                                                }
                                              >
                                                <span class="lead collapse-title">
                                                  {topic.question}
                                                </span>
                                              </div>
                                              <div
                                                id={"collapse" + index}
                                                class="collapse"
                                                aria-labelledby={
                                                  "heading" + index
                                                }
                                                data-parent="#accordionExample"
                                              >
                                                <div class="card-body">
                                                  {topic.answer}
                                                </div>
                                              </div>
                                            </div>
                                          ) : null
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </TabPane>
                        <TabPane tabId="6">
                          <div>
                            <div class="card-header">
                              <div class="card-title mb-2">EasyPay</div>
                            </div>
                            <div class="card bg-transparent border-0 shadow-none collapse-icon accordion-icon-rotate">
                              <div class="card-body">
                                <div class="col-xl-12 col-md-12 col-12">
                                  <div class="card bg-transparesnt border-0 shadow-none collapse-icon accordion-icon-rotate">
                                    <div class="card-body p-0">
                                      <div
                                        class="accordion search-content-info"
                                        id="accordionExample"
                                      >
                                        {this.state.topics.map((topic, index) =>
                                          topic.topic_type === "easypay" ? (
                                            <div class="collapse-margin search-content mt-0 que-ans">
                                              <div
                                                class="card-header"
                                                id={"heading" + index}
                                                role="button"
                                                data-toggle="collapse"
                                                data-target={
                                                  "#collapse" + index
                                                }
                                                aria-expanded="false"
                                                aria-controls={
                                                  "collapse" + index
                                                }
                                              >
                                                <span class="lead collapse-title">
                                                  {topic.question}
                                                </span>
                                              </div>
                                              <div
                                                id={"collapse" + index}
                                                class="collapse"
                                                aria-labelledby={
                                                  "heading" + index
                                                }
                                                data-parent="#accordionExample"
                                              >
                                                <div class="card-body">
                                                  {topic.answer}
                                                </div>
                                              </div>
                                            </div>
                                          ) : null
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </TabPane>
                        <TabPane tabId="7">
                          <div>
                            <div class="card-header">
                              <div class="card-title mb-2">GiftCard</div>
                            </div>
                            <div class="card bg-transparent border-0 shadow-none collapse-icon accordion-icon-rotate">
                              <div class="card-body">
                                <div class="col-xl-12 col-md-12 col-12">
                                  <div class="card bg-transparesnt border-0 shadow-none collapse-icon accordion-icon-rotate">
                                    <div class="card-body p-0">
                                      <div
                                        class="accordion search-content-info"
                                        id="accordionExample"
                                      >
                                        {this.state.topics.map((topic, index) =>
                                          topic.topic_type === "giftcard" ? (
                                            <div class="collapse-margin search-content mt-0 que-ans">
                                              <div
                                                class="card-header"
                                                id={"heading" + index}
                                                role="button"
                                                data-toggle="collapse"
                                                data-target={
                                                  "#collapse" + index
                                                }
                                                aria-expanded="false"
                                                aria-controls={
                                                  "collapse" + index
                                                }
                                              >
                                                <span class="lead collapse-title">
                                                  {topic.question}
                                                </span>
                                              </div>
                                              <div
                                                id={"collapse" + index}
                                                class="collapse"
                                                aria-labelledby={
                                                  "heading" + index
                                                }
                                                data-parent="#accordionExample"
                                              >
                                                <div class="card-body">
                                                  {topic.answer}
                                                </div>
                                              </div>
                                            </div>
                                          ) : null
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </TabPane>
                        <TabPane tabId="8">
                          <div>
                            <div class="card-header">
                              <div class="card-title mb-2">Payment</div>
                            </div>
                            <div class="card bg-transparent border-0 shadow-none collapse-icon accordion-icon-rotate">
                              <div class="card-body">
                                <div class="col-xl-12 col-md-12 col-12">
                                  <div class="card bg-transparesnt border-0 shadow-none collapse-icon accordion-icon-rotate">
                                    <div class="card-body p-0">
                                      <div
                                        class="accordion search-content-info"
                                        id="accordionExample"
                                      >
                                        {this.state.topics.map((topic, index) =>
                                          topic.topic_type === "payment" ? (
                                            <div class="collapse-margin search-content mt-0 que-ans">
                                              <div
                                                class="card-header"
                                                id={"heading" + index}
                                                role="button"
                                                data-toggle="collapse"
                                                data-target={
                                                  "#collapse" + index
                                                }
                                                aria-expanded="false"
                                                aria-controls={
                                                  "collapse" + index
                                                }
                                              >
                                                <span class="lead collapse-title">
                                                  {topic.question}
                                                </span>
                                              </div>
                                              <div
                                                id={"collapse" + index}
                                                class="collapse"
                                                aria-labelledby={
                                                  "heading" + index
                                                }
                                                data-parent="#accordionExample"
                                              >
                                                <div class="card-body">
                                                  {topic.answer}
                                                </div>
                                              </div>
                                            </div>
                                          ) : null
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </TabPane>
                      </TabContent>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>

        <div class="sidenav-overlay"></div>
        <div class="drag-target"></div>
        <Footer />
      </body>
    );
  }
}
export default SellerForum;
