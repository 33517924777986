import React from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { Modal, ModalHeader, ModalBody, ModalFooter, Table } from 'reactstrap';
import Footer from '../../Component/Footer/Footer';
import global from '../../global';
import { toast } from 'react-toastify';
import './Product.css';
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment';
import ReactPaginate from 'react-paginate';
import ReactToPrint from 'react-to-print';
import ReactToPdf from 'react-to-pdf';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Spinner } from "reactstrap"
import orderby from 'lodash.orderby';
import { element } from 'prop-types';
// import {Spinner } from 'react-bootstrap'
// import 'bootstrap/dist/css/bootstrap.min.css';
// const $ = require('jquery');
const targetRef = React.createRef();
const fileType =
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const fileExtension = '.xlsx';

var seller = {};
// var orderdata = {};

var modal = false;
var modal2 = false;
var isActive = false;
var orderList = [];
var allOrderCheck = false;
const $ = require('jquery');
$.DataTable = require('datatables.net');

toast.configure();
const CustomToast = () => {
  return (
    <div>
      <i className="fa fa-check success-toast"></i>
      Product Created Successfully.
    </div>
  );
};
const WarnToast = () => {
  return (
    <div style={{ display: 'flex' }}>
      <i className="fa fa-info info-toast"></i>
      You have insufficient amount in wallet.
    </div>
  );
};
const ProductWarnToast = () => {
  return (
    <div>
      <i className="fa fa-info info-toast"></i>
      Please select product.
    </div>
  );
};
const ActionWarnToast = () => {
  return (
    <div>
      <i className="fa fa-info info-toast"></i>
      Please select Action.
    </div>
  );
};
const ActionselectToast= () => {
  return (
    <div>
      <i className="fa fa-info info-toast"></i>
      Please select Dates.
    </div>
  );
};
const OrderShippedWarnToast = () => {
  return (
    <div>
      <i className="fa fa-info info-toast"></i>
      Your order has been shipped.
    </div>
  );
};
const OrderPaymentWarnToast = () => {
  return (
    <div>
      <i className="fa fa-info info-toast"></i>
      Payment has been received successfully!
    </div>
  );
};
const OrderNoteWarnToast = () => {
  return (
    <div>
      <i className="fa fa-info info-toast"></i>
      Order note has been added!
    </div>
  );
};
//var Loader = require("react-loader");
class ComponentToPrint extends React.Component {
  render() {
    return (
      <Table
        responsive
        hover
        className="display border-product-list-table"
        style={{ fontSize: '12px' }}
      >
        <thead>
          <tr>
            <th scope="col">No</th>
            <th scope="col">Order</th>
            <th scope="col">Date/Time</th>
            <th scope="col">Customer</th>
            <th scope="col">Product Name</th>
            <th scope="col">Order Total</th>
            <th scope="col">Total</th>
            <th scope="col">Payment</th>
            <th scope="col">Shipping</th>
            <th scope="col">Note</th>
            <th scope="col">Carrier</th>
            <th scope="col">Tracking</th>
          </tr>
        </thead>
        <tbody>
          {orderList.sort((a,b)=>new Date(a.createdAt).getTime()-new Date(b.createdAt).getTime()).map((order, index) => (
            <tr key={index}>
              <td>{index + 1}</td>

              <Link to={'/vieworder/' + order.order_Id}>
                {' '}
                <td style={{ minWidth: '144px' }}>{order.order_Id}</td>
              </Link>
              <td style={{ minWidth: '250px' }}>
                {moment(order.createdAt).format('MM/DD/YYYY,h:mm a ')}
              </td>
              <th>{order.userId ? order.userId.name : null}</th>
              <td className="prod-ellip">
                {order.productId ? order.productId.product_name : null}
              </td>
              <td>{order ? order.bag_total : null}</td>
              <td>{order ? order.bag_total : null}</td>
              <td>{order.order ? order.order.payment_status : null}</td>
              <td>{order.order ? order.order.order_status : null}</td>
              <td>Note</td>
              <td>Carrier</td>
              <td>Tracking</td>
              <td>View</td>
            </tr>
          ))}
        </tbody>
      </Table>
    );
  }
}
class OrderList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      orders: [],
      sortOrder: [],
      orderSummary: [],
      loaded: true,
      loading:false,
      loadingprint:false,
      lodingconfirm:false,
      seller: '',
      actionTask: '',
      actionSortTask: '',
      offset: 1,
      perPage: 10,
      downlodelink:'',
      currentPage: 1,
      totalCount: 0,
      searchProduct: '',
      startDate: '',
      endDate: '',
      listdata: [],
      checkBoxHandleChange: false,
      paymentCheckbox: false,
      shippingCheckbox: false,
      noteCheckbox: false,
      carrierCheckbox: false,
      trackingCheckbox: false,
      viewCheckbox: false,
      allOrderCheck: false,
      AddEditNote: '',
      total: '',
      temp: [],
      ref: {
        shipped: '0',
        pending: '1',
      },
      sorters: [
        {
          property: 'shipped',
        },
        {
          property: 'pending',
        },
      ],
    };
    // this.resizeScreen = this.resizeScreen.bind(this); 
    this.handleChange = this.handleChange.bind(this);

    seller = {};
    isActive = false;
    modal = false;
    modal2 = false;
    orderList = [];
    allOrderCheck = false;
  }
  componentDidMount(){
    // window.addEventListener("resize", this.resizeScreen());
  }
  componentWillMount() {
    // console.log(this.state.orders.id, 'id');
    if (localStorage.getItem('sellerdata')) {
      this.setState({ seller: JSON.parse(localStorage.getItem('sellerdata')) });
      seller = JSON.parse(localStorage.getItem('sellerdata'));
    }
    if (this.props.match.params.id) {
      this.productWiseOrderList();
    } else {
      this.listAPI();
    }
  }
  orderCheck = (index) => {
    allOrderCheck = false;
    let orders = this.state.orders;
    if (orders[index].checked === true) {
      orders[index].checked = false;
    } else {
      orders[index].checked = true;
    }
    this.setState({ orders: orders, allOrderCheck: false });
  };
  listAPI() {
    var urlencoded = new URLSearchParams();
    urlencoded.append('sellerId', seller.id);
    axios
      .post(global.baseUrl + 'SellerOrderList', urlencoded, {
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      })
      .then(async (result) => {
        console.log(result.data.data, 'order list');

        if (result.data.data) {
          let temporder = [];
          // result.data.data = [...new Map(result.data.data.map(item =>
          //   [item["order_Id"], item])).values()]
          let seller = this.state.seller;
          console.log(this.state.seller,"1211111");
          if (this.state.seller.type == 'Retailer') {
            // result.data.data = [...new Map(result.data.data.map(item =>
            //   [item["order_Id"], item])).values()]
            console.log('enter12')
            result.data.data = await result.data.data.filter(d =>

              d.productId.sellerType == 'Retailer'
            )
            result.data.data = [...new Map(result.data.data.map(item =>
              [item["order_Id"], item])).values()]
            orderList = result.data.data;

            this.setState({
              orders: result.data.data,
              sortOrder: result.data.data,
              orderSummary: temporder,
              postData: [],
            });
            this.setState({
              totalCount: result.data.data.length,
              pageCount: Math.ceil(result.data.data.length / this.state.perPage),
            });
            this.pagination();
          } else {
            // result.data.data = [...new Map(result.data.data.map(item =>
            //   [item["order_Id"], item])).values()]
            console.log( result.data.data,'enter1234')
            result.data.data = await result.data.data.filter(d =>{
              console.log(d.productId.sellerType,'4')
              return d.productId.sellerType == "Freelisting"
            })
            result.data.data = [...new Map(result.data.data.map(item =>
              [item["order_Id"], item])).values()]
            orderList = result.data.data;
            console.log(result.data.data,"343434343");
            this.setState({
              orders: result.data.data,
              sortOrder: result.data.data,
              orderSummary: temporder,
              postData: [],
            });
            this.setState({
              totalCount: result.data.data.length,
              pageCount: Math.ceil(result.data.data.length / this.state.perPage),
            });
            this.pagination();
          }
          // let temporder = [];
          console.log(result.data.data, "check====1234===")
          // orderList = result.data.data;

          // this.setState({
          //   orders: result.data.data,
          //   sortOrder: result.data.data,
          //   orderSummary: temporder,
          //   postData: [],
          // });
          // this.setState({
          //   totalCount: result.data.data.length,
          //   pageCount: Math.ceil(result.data.data.length / this.state.perPage),
          // });
          // this.pagination();
        }
      });
  }

  pagination() {
    console.log(this.state.offset, 'offsetcheck');
    const slice = this.state.orders.slice(
      this.state.offset - 1,
      this.state.offset - 1 + this.state.perPage
    );

    // For displaying Data
    this.setState({ listdata: slice });
  }
  productWiseOrderList() {
    var urlencoded = new URLSearchParams();
    urlencoded.append('sellerId', seller.id);
    urlencoded.append('productId', this.props.match?.params.id);

    axios
      .post(global.baseUrl + 'prountIdOrderList', urlencoded, {
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      })
      .then(async (result) => {
        console.log(result.data, 'djfg');
        if (result.data.data) {
          
          if (seller.type === 'Retailer') {
            console.log('enter12')
            result.data.data = await result.data.data.filter(d =>

              d.productId.sellerType === 'Retailer'
            )
            orderList = result.data.data;
            this.setState({
              orders: result.data.data,
              postData: [],
            });
            this.setState({
              totalCount: result.data.data.length,
              pageCount: Math.ceil(result.data.data.length / this.state.perPage),
            });

          } else {
            console.log('enter1234')
            result.data.data = await result.data.data.filter(d =>

              d.productId.sellerType === "Freelisting"
            )
            orderList = result.data.data;
            this.setState({
              orders: result.data.data,
              postData: [],
            });
            this.setState({
              totalCount: result.data.data.length,
              pageCount: Math.ceil(result.data.data.length / this.state.perPage),
            });
            
          }
          // this.setState({
          //   orders: result.data.data,
          //   postData: [],
          // });
          // this.setState({
          //   totalCount: result.data.data.length,
          //   pageCount: Math.ceil(result.data.data.length / this.state.perPage),
          // });
          this.pagination();
        }
      });
  }
  handlePageClick = (e) => {
    const selectedPage = e.selected;
    console.log(e.selected, 'selectedpage');
    console.log(selectedPage, 'selected page');
    const offset = selectedPage * this.state.perPage;

    this.setState(
      {
        currentPage: selectedPage + 1,
        offset: offset + 1,
      },
      () => {
        this.pagination();
      }
    );
  };
  disableHandler(id, index) {
    var r;
    if (this.state.products[index].activeStatus == 1) {
      r = window.confirm('Are you sure to hide product?');
    } else {
      r = window.confirm('Are you sure to un hide product?');
    }
    if (r) {
      var urlencoded = new URLSearchParams();
      urlencoded.append('id', id);
      urlencoded.append('sellerId', this.state.seller.id);
      axios
        .post(global.baseUrl + 'SellerProductActiveStatusChange', urlencoded, {
          headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        })
        .then((result) => {
          let item = this.state.products;
          if (result.data.status) {
            if (result.data.data) {
              console.log(result.data.data);
              if (result.data.data[0]) {
                this.setState({
                  datatable: {
                    columns: [],
                    rows: [],
                  },
                });
                // this.setState({loaded:false})
                item[index].activeStatus = result.data.data[0].activeStatus;
                this.setState({ product: item }, () => {
                  this.setProduct();
                });
              }
            }
          } else {
            console.log('result', result.data);
          }
        });
    }
  }
  productCheck = (index) => {
    let products = this.state.products;
    if (products[index].checked === true) {
      products[index].checked = false;
    } else {
      products[index].checked = true;
    }
    this.setState({ products: products });
  };
 
  exportmobilepdf=(orderdata)=>{
    if(window.innerWidth <= 767){
    console.log(orderdata,"dataexectpdf");
    let data=`<div id="pdfTableDiv" style={{ display: 'none' }}>
    <Table
      responsive
      hover
      className="display border-product-list-table"
      style={{ fontSize: '12px' }}
      id="pdfDiv"
    >
      <thead>
        <tr>
          <th scope="col">No</th>
          <th scope="col">Order</th>
          <th scope="col">Date/Time</th>
          <th scope="col">Customer</th>
          <th scope="col">Product Name</th>
          <th scope="col">Order Total</th>
          <th scope="col">Total</th>
        </tr>
      </thead>
      <tbody>
        ${this.state.totalCount ? (
          orderdata.sort((a,b)=>a.createdAt.getTime()-b.createdAt.getTime()).map((order, index) => (
            `<tr key=${index}>
              <td>${index + 1}</td>
              <td style={{ minWidth: '144px' }}>
                ${order.order_Id}
              </td>
              <td style={{ minWidth: '250px' }}>
                ${moment(order.createdAt).format(
                  'MM/DD/YYYY,h:mm a '
                )}
              </td>
              <th>
                ${order.userId? order.userId.name:'-'}
              </th>
              <td className="prod-ellip">
                ${order.productId
                  ? order.productId.product_name
                  :'-'}
              </td>
              <td>${order && order.bag_total}</td>
              <td>${order.bag_total}</td>
              
            </tr>`
          ))
        ) : (
          `<tr>
            <td colSpan={6}>No Product Available.</td>
          </tr>`
        )}
      </tbody>
    </Table>
  </div>`;

  var urlencoded = new URLSearchParams();
      // urlencoded.append('id', id);
      urlencoded.append('body', data);
      axios
        .post(global.baseUrl + 'pdfgenratelink', urlencoded, {
          headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        })
        .then((result) => {
         console.log(result.data.data,"ddddddddlink")
         var message = {'type':'1', 'data' : [  { "pdfLink" : result.data.data } ]};
        //  window.webkit.messageHandlers.derp.postMessage(message);
        // window.open(result.data.data,"_self")
        window.postMessage(message)
        this.setState({loading:false})
        this.setState({loadingprint:false})
        this.setState({lodingconfirm:false})
        });
      }else{
        this.setState({loading:false})
        this.setState({loadingprint:false})
        this.setState({lodingconfirm:false})
      }
  }

  mobilepdf=()=>{

    if(window.innerWidth <= 767){
    let data=`<div id="pdfTableDiv" style={{ display: 'none' }}>
    <Table
      responsive
      hover
      className="display border-product-list-table"
      style={{ fontSize: '12px' }}
      id="pdfDiv"
    >
      <thead>
        <tr>
          <th scope="col">No</th>
          <th scope="col">Order</th>
          <th scope="col">Date/Time</th>
          <th scope="col">Customer</th>
          <th scope="col">Product Name</th>
          <th scope="col">Order Total</th>
          <th scope="col">Total</th>
        </tr>
      </thead>
      <tbody>
        ${this.state.totalCount ? (
          this.state.listdata.sort((a,b)=>new Date(a.createdAt).getTime()-new Date(b.createdAt).getTime()).map((order, index) => (
            `<tr key=${index}>
              <td>${index + 1}</td>
              <td style={{ minWidth: '144px' }}>
                ${order.order_Id}
              </td>
              <td style={{ minWidth: '250px' }}>
                ${moment(order.createdAt).format(
                  'MM/DD/YYYY,h:mm a '
                )}
              </td>
              <th>
                ${order.userId? order.userId.name:'-'}
              </th>
              <td className="prod-ellip">
                ${order.productId
                  ? order.productId.product_name
                  :'-'}
              </td>
              <td>${order && order.bag_total}</td>
              <td>${order.bag_total}</td>
              
            </tr>`
          ))
        ) : (
          `<tr>
            <td colSpan={6}>No Product Available.</td>
          </tr>`
        )}
      </tbody>
    </Table>
  </div>`;

  var urlencoded = new URLSearchParams();
      // urlencoded.append('id', id);
      urlencoded.append('body', data);
      axios
        .post(global.baseUrl + 'pdfgenratelink', urlencoded, {
          headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        })
        .then((result) => {
         console.log(result.data.data,"ddddddddlink")
         var message = {'type':'1', 'data' : [  { "pdfLink" : result.data.data } ]};
        //  window.webkit.messageHandlers.derp.postMessage(message);
        // window.open(result.data.data,"_self")
        window.postMessage(message)
        this.setState({loading:false})
        this.setState({loadingprint:false})
        this.setState({lodingconfirm:false})
        });
      }else{
        this.setState({loading:false})
        this.setState({loadingprint:false})
        this.setState({lodingconfirm:false})
      }
  }

  downlodeall=(pdfContents)=>{
  //   $(window).resize(function() {
    if(window.innerWidth <= 767){
   let data=`<div>${pdfContents}</div>`
    var urlencoded = new URLSearchParams();
      // urlencoded.append('id', id);
      urlencoded.append('body', data);
      axios
        .post(global.baseUrl+ 'pdfgenratelink', urlencoded, {
          headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        })
        .then((result) => {
         console.log(result.data.data,"ddddddddlink")
         var message = {'type':'1', 'data' : [  { "pdfLink" : result.data.data } ]};
        //  window.webkit.messageHandlers.derp.postMessage(message);
        // <iframe src={result.data.data} />
        // window.open(result.data.data,"_self")
        window.postMessage(message)
        this.setState({loading:false});
        // this.props.history.push({
        //   pathname: '/download',
        //   // search: '?query=abc',
        //   state: { downlodelink: result.data.data}
        // })
        // this.setState({downlodelink:result.data.data})
        // return <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d470029.1604841957!2d72.29955005258641!3d23.019996818380896!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x395e848aba5bd449%3A0x4fcedd11614f6516!2sAhmedabad%2C%20Gujarat!5e0!3m2!1sen!2sin!4v1641299931417!5m2!1sen!2sin" />
        // this.setState({loading:false})
        // this.setState({loadingprint:false})
        // this.setState({lodingconfirm:false})
        });
    }else{
      this.setState({loading:false})
        this.setState({loadingprint:false})
        this.setState({lodingconfirm:false})
    }
  // })
  }
  modalToggle = (id, index) => {
    console.log('Working');
    if (modal === false) {
      modal = true;
      let charge = this.state.products[index].image.length - 5;
      if (charge >= 1) {
        charge = charge * parseFloat(this.state.imageCharge);
      } else {
        charge = 0;
      }
      this.setState({
        productId: id,
        productImageCharge: charge,
        finalTotal: parseFloat(this.state.productCharge) + parseFloat(charge),
      });
    } else {
      modal = false;
    }
    this.setState({ A: '' });
  };
  closeModalToggle = () => {
    console.log('close working');
    if (modal === false) {
      modal = true;
    } else {
      modal = false;
    }
    this.setState({ A: '' });
  };
  modal2Toggle = () => {
    if (modal2 === false) {
      modal2 = true;
    } else {
      modal2 = false;
    }
    this.setState({ A: '' });
  };
  paymentOpen = () => {
    modal = false;
    modal2 = true;
    this.setState({ a: '' });
  };
  handleSearchProduct = (e) => {
    this.setState({
      searchProduct: e.target.value,
    });
    this.setState(
      {
        currentPage: 1,
        offset: 10,
      },
      () => {
        this.listAPI();
      }
    );
  };
  actionChange = (e) => {
    console.log('test');
    this.setState({ actionTask: e.target.value });
    // this.handleTotalSort(this.state.orders);
  };
  checkAction =async () => {
    console.log(this.state.actionTask,"ddddrrrrrrdddd");
    if (this.state.actionTask === ''||this.state.actionTask ==='Choose an action') {
      // this.setState({lodingconfirm:true})
      this.setState({lodingconfirm:false})
      console.log(this.state.lodingconfirm);
      // this.forceUpdate()
      toast.warn(<ActionWarnToast />, {
        position: toast.POSITION.TOP_RIGHT,
      });
      // this.setState({lodingconfirm:false})
    } else if (this.state.actionTask === 'printSelectedDates') {
      if (
        this.state.startDate !== '' &&
        this.state.endDate !== '' 
        // this.state.endDate.length === 10 &&
        // this.state.startDate.length === 10
      ) {
        await this.printButtonElement.click();
        // console.log(targetRef,"tttttttttttttttttttttttttt");
         this.mobilepdf()
      }else{
        this.setState({lodingconfirm:false})
        toast.warn(<ActionselectToast />, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } else if (this.state.actionTask === 'exportAll') {
      this.exportAllHandler();
    } else if (this.state.actionTask === 'outofstock') {
      this.exportAllHandler();
    } 
    else if (this.state.actionTask === 'MarkAsShipped') {
      this.UpdateOrderStatusAPICall();
    } else if (this.state.actionTask === 'MarkAsPaymentReceived') {
      this.UpdatePaymentStatusAPICall();
    } else if (this.state.actionTask === 'addEditNotes') {
      this.OrderCheckBoxAPICall();
    }
  };
  OrderCheckBoxAPICall = () => {
    let orders = this.state.orders;
    let newOrder = [];
    let i = 0;
    for (i = 0; i < orders.length; i++) {
      if (orders[i].checked === true) {
        console.log(orders[i].Order_add_edit_notes);
        this.setState({
          AddEditNote: this.state.orders[i].Order_add_edit_notes,
        });
        newOrder.push(orders[i]);
      }
    }
    if (i === orders.length && newOrder.length) {
      this.setState({ newOrder: newOrder }, () => {
        // this.addNoteAPI()
      });
    } else {
      toast.warn(<ProductWarnToast />, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };
  addNoteAPI = () => {
    let urlencoded = new URLSearchParams();
    for (let i = 0; i < this.state.newOrder.length; i++) {
      urlencoded.append('order_Id', this.state.newOrder[i].order_Id);
      urlencoded.append('Order_add_edit_notes', this.state.AddEditNote);
      console.log(this.state.newOrder[i].order_Id, 'orderid');
    }
    axios
      .post(global.baseUrl + 'addOrderNote', urlencoded, {
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      })
      .then((result) => {
        allOrderCheck = false;
        this.setState({ newOrder: [], order: [] }, () => {
          this.listAPI();
        });
      });
  };

  UpdateOrderStatusAPICall = () => {
    console.log('update order status');
    let orders = this.state.orders;
    let neworders = [];
    let i = 0;
    for (i = 0; i < orders.length; i++) {
      if (orders[i].checked === true) {
        neworders.push(orders[i].order_Id);
      }
    }
    console.log(neworders, 'ordersss');
    var urlencoded = new URLSearchParams();
    urlencoded.append('order_Id', neworders);
    axios
      .post(global.baseUrl + 'updateOrderStatus', urlencoded, {
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      })
      .then((result) => {
        if (result.data) {
          toast.warn(<OrderShippedWarnToast />, {
            position: toast.POSITION.TOP_RIGHT,
          });
          this.props.history.push('/orderlist');
          // window.location.reload()
        }
      });
  };
  UpdatePaymentStatusAPICall = () => {
    console.log('update payment status');
    let orders = this.state.orders;
    let neworders = [];
    let i = 0;
    for (i = 0; i < orders.length; i++) {
      if (orders[i].checked === true) {
        neworders.push(orders[i].order_Id);
      }
    }
    var urlencoded = new URLSearchParams();
    urlencoded.append('order_Id', neworders);
    axios
      .post(global.baseUrl + 'sellerUpdatePaymentStatus', urlencoded, {
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      })
      .then((result) => {
        if (result.data) {
          toast.warn(<OrderPaymentWarnToast />, {
            position: toast.POSITION.TOP_RIGHT,
          });
          this.props.history.push('/orderlist');
        }
      });
  };
  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };
  multipleOutOfStockHandlerHandler = () => {
    let products = this.state.products;
    let newProduct = [];
    let i = 0;
    for (i = 0; i < products.length; i++) {
      if (products[i].checked === true) {
        newProduct.push(products[i]);
      }
    }
    if (i === products.length && newProduct.length) {
      this.setState({ newProduct: newProduct }, () => {
        this.multiOutOfStockAPI();
      });
    } else {
      toast.warn(<ProductWarnToast />, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };
  multiOutOfStockAPI = () => {
    let r = '';
    if (this.state.actionTask === 'outofstock') {
      r = window.confirm('Are you sure to set out of stock product?');
    }
    if (r) {
      let urlencoded = new URLSearchParams();
      for (let i = 0; i < this.state.newProduct.length; i++) {
        urlencoded.append('productId', this.state.newProduct[i].id);
      }
      urlencoded.append('sellerId', this.state.seller.id);
      axios
        .post(global.baseUrl + 'SellerMultiOutOfStock', urlencoded, {
          headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        })
        .then((result) => {
          this.setState({ newProduct: [], product: [] }, () => {
            this.listAPI();
          });
        });
    }
  };
  printClick = () => {
    this.printButtonElement.click();
  };
  pdfContent =  () => {
    this.setState({loading:true});
    var pdfContents = document.getElementById('pdfTableDiv').innerHTML;
    // console.log(pdfContents, "klffh")
    var originalContents = document.getElementById('pdfDiv').innerHTML;

    document.getElementById('pdfDiv').innerHTML = pdfContents;

    this.pdfElement.click();
    document.getElementById('pdfDiv').innerHTML = originalContents;
    this.downlodeall(pdfContents)
  };
  handlerChange = (e) => {
    this.setState({ [e.target.name]: e.target.value }, () => {
      if (
        this.state.startDate !== '' &&
        this.state.endDate !== '' &&
        this.state.startDate.length === 10 &&
        this.state.endDate.length === 10
      ) {
        console.log(this.state.startDate, this.state.endDate);
        var urlencoded = new URLSearchParams();
        urlencoded.append('sellerId', seller.id);
        urlencoded.append('fromDate', this.state.startDate);
        urlencoded.append('toDate', this.state.endDate);

        axios
          .post(global.baseUrl + 'SellerOrderDateList', urlencoded, {
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
          })
          .then(async (result) => {
            console.log(result.data, 'djfg');
            if (result.data.data) {
              if (seller.type === 'Retailer') {
                console.log('enter12')
                result.data.data = await result.data.data.filter(d =>
    
                  d.productId.sellerType === 'Retailer'
                )
                orderList = result.data.data;
                this.setState({
                  orders: result.data.data,
                  postData: [],
                });
                this.setState({
                  totalCount: result.data.data.length,
                  pageCount: Math.ceil(result.data.data.length / this.state.perPage),
                });
    
              } else {
                console.log('enter1234')
                result.data.data = await result.data.data.filter(d =>
    
                  d.productId.sellerType === "Freelisting"
                )
                orderList = result.data.data;
                this.setState({
                  orders: result.data.data,
                  postData: [],
                });
                this.setState({
                  totalCount: result.data.data.length,
                  pageCount: Math.ceil(result.data.data.length / this.state.perPage),
                });
                
              }
              // orderList = result.data.data;
              // this.setState({
              //   orders: result.data.data,
              //   postData: [],
              // });
              // this.setState({
              //   totalCount: result.data.data.length,
              //   pageCount: Math.ceil(
              //     result.data.data.length / this.state.perPage
              //     // result.data.totalCount / this.state.perPage
              //   ),
              // });
              this.pagination();
            }
          });
      } else if (this.state.startDate === '' && this.state.endDate === '') {
        this.listAPI();
      }
    });
  };
  exportAllHandler = () => {
    let orders = this.state.orders;
    let newProduct = [];
    let i = 0;
    for (i = 0; i < orders.length; i++) {
      newProduct.push({
        id: orders[i].id,
        product_name: orders[i].productId.product_name,
        buyer_name: orders[i].name,
        total: orders[i].bag_total,
      });
    }
    if (i === orders.length && newProduct.length) {
      this.setState({ selectedProduct: newProduct }, () => {
        this.exportAll(this.state.selectedProduct);
        this.exportmobilepdf(this.state.orders)
      });
    } else {
      toast.warn(<ProductWarnToast />, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };
  exportAll = (csvData) => {
    const ws = XLSX.utils.json_to_sheet(csvData);
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, 'download' + fileExtension);
  };
  handleFromDateChange = (date) => {
    this.setState(
      {
        startDate: date,
      },
      () => {
        if (this.state.startDate !== '' && this.state.endDate !== '') {
          console.log(this.state.startDate, this.state.endDate);
          var urlencoded = new URLSearchParams();
          urlencoded.append('sellerId', seller.id);
          urlencoded.append('fromDate', this.state.startDate);
          urlencoded.append('toDate', this.state.endDate);

          axios
            .post(global.baseUrl + 'SellerOrderDateList', urlencoded, {
              headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            })
            .then(async(result) => {
              console.log(result.data, 'djfg');
              if (result.data.data) {
                if (seller.type === 'Retailer') {
                  console.log('enter12')
                  result.data.data = await result.data.data.filter(d =>
      
                    d.productId.sellerType === 'Retailer'
                  )
                  orderList = result.data.data;
                  this.setState({
                    orders: result.data.data,
                    postData: [],
                  });
                  this.setState({
                    totalCount: result.data.data.length,
                    pageCount: Math.ceil(result.data.data.length / this.state.perPage),
                  });
      
                } else {
                  console.log('enter1234')
                  result.data.data = await result.data.data.filter(d =>
      
                    d.productId.sellerType === "Freelisting"
                  )
                  orderList = result.data.data;
                  this.setState({
                    orders: result.data.data,
                    postData: [],
                  });
                  this.setState({
                    totalCount: result.data.data.length,
                    pageCount: Math.ceil(result.data.data.length / this.state.perPage),
                  });
                  
                }
                // orderList = result.data.data;
                // this.setState({
                //   orders: result.data.data,
                //   postData: [],
                // });
                // this.setState({
                //   totalCount: result.data.data.length,
                //   pageCount: Math.ceil(
                //     result.data.data.length / this.state.perPage
                //   ),
                // });
                this.pagination();
              }
            });
        } else if (this.state.startDate === '' && this.state.endDate === '') {
          this.listAPI();
        }
      }
    );
  };

  handleEndDateChange = (date) => {
    this.setState(
      {
        endDate: date,
      },
      () => {
        if (this.state.startDate !== '' && this.state.endDate !== '') {
          console.log(this.state.startDate, this.state.endDate);
          var urlencoded = new URLSearchParams();
          urlencoded.append('sellerId', seller.id);
          urlencoded.append('fromDate', this.state.startDate);
          urlencoded.append('toDate', this.state.endDate);

          axios
            .post(global.baseUrl + 'SellerOrderDateList', urlencoded, {
              headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            })
            .then(async(result) => {
              console.log(result.data, 'djfg');
              if (result.data.data) {
                if (seller.type === 'Retailer') {
                  console.log('enter12')
                  result.data.data = await result.data.data.filter(d =>
      
                    d.productId.sellerType === 'Retailer'
                  )
                  orderList = result.data.data;
                  this.setState({
                    orders: result.data.data,
                    postData: [],
                  });
                  this.setState({
                    totalCount: result.data.data.length,
                    pageCount: Math.ceil(result.data.data.length / this.state.perPage),
                  });
      
                } else {
                  console.log('enter1234')
                  result.data.data = await result.data.data.filter(d =>
      
                    d.productId.sellerType === "Freelisting"
                  )
                  orderList = result.data.data;
                  this.setState({
                    orders: result.data.data,
                    postData: [],
                  });
                  this.setState({
                    totalCount: result.data.data.length,
                    pageCount: Math.ceil(result.data.data.length / this.state.perPage),
                  });
                  
                }
                // orderList = result.data.data;
                // this.setState({
                //   orders: result.data.data,
                //   postData: [],
                // });
                // this.setState({
                //   totalCount: result.data.data.length,
                //   pageCount: Math.ceil(
                //     result.data.data.length / this.state.perPage
                //   ),
                // });
                this.pagination();
              }
            });
        } else if (this.state.startDate === '' && this.state.endDate === '') {
          this.listAPI();
        }
      }
    );
  };
  checkBoxHandleChange = (e) => {
    console.log(e.target.value, '');
    if (e.target.value === 'true') {
      this.setState({ [e.target.name]: false });
    } else {
      this.setState({ [e.target.name]: true });
    }
  };
  allOrderSelection = () => {
    let orders = this.state.orders;
    if (allOrderCheck === false) {
      for (let i = 0; i < orders.length; i++) {
        orders[i].checked = true;
      }
      allOrderCheck = true;
      this.setState({
        orders: orders,
      });
    } else {
      for (let i = 0; i < orders.length; i++) {
        orders[i].checked = false;
      }
      allOrderCheck = false;
      this.setState({
        orders: orders,
      });
    }
  };
  dataTable() {
    $(document).ready(function () {
      $('#list').DataTable();
    });
  }
  formSortObject = async (fieldName) => {
    console.log(fieldName, 'field');

    {
      this.state.orders.map(async (data, index) => {
        console.log(data.bag_total, 'bag');
        let { orders } = this.state;
        if (!orders.bag_total || orders.bag_total !== data.bag_total) {
          await Object.assign(orders, {
            field: data.bag_total,
            order: 'asc',
          });

          console.log('jbjbjb');
          return orders;
        } else if (orders.bag_total === data.bag_total) {
          console.log('else if');
          Object.assign(orders, {
            ...orders,
            order: orders.bag_total === 'desc' ? 'asc' : 'desc',
          });
          return orders;
        } else {
          console.log('else ');
        }
      });
    }
  };
  actionSortChange = (e) => {
    this.setState({ actionSortTask: e.target.value });
    this.handleTotalSort(this.state.orders);
    if (e.target.value == 'Pending') {
      this.handlePendingSort(this.state.orders);
    } else if (e.target.value == 'Shipped') {
      this.handleShippedSort(this.state.orders);
    } else if (e.target.value == 'Delivered') {
      this.handleSoldSort(this.state.orders);
    } else if (e.target.value == 'Refunded') {
      this.handleRefundSort(this.state.orders);
    } else if (e.target.value == 'Cancelled') {
      this.handleCancelledSort(this.state.orders);
    }
  };
  handleTotalSort() {
    const filterdata = this.state.orders.sort(function (obj1, obj2) {
      return obj2.bag_total - obj1.bag_total;
    });
    this.setState({ orders: filterdata });
  }

  handleShippedSort() {
    console.log('shipped');
    let tempsort = this.state.sortOrder;

    let orderTemp = [];
    tempsort.map((temp) => {
      console.log(temp, 'temp');
      if (temp.order.order_status == 'shipped') {
        orderTemp.push(temp);
      }
    });
    console.log(orderTemp, 'order temp');
    this.setState({ orders: orderTemp });
  }
  handlePendingSort() {
    let pendingsort = this.state.sortOrder;
    let Temp = [];
    pendingsort.map((temp) => {
      if (temp.order.order_status == 'pending') {
        Temp.push(temp);
      }
    });
    console.log(Temp, 'temp pending');
    this.setState({
      orders: Temp,
    });
  }
  handleSoldSort() {
    let tempsold = this.state.sortOrder;
    let Temp = [];
    tempsold.map((tempsold) => {
      if (tempsold.order.order_status == 'Delivered') {
        Temp.push(tempsold);
      }
    });
    this.setState({ orders: Temp });
  }
  handleRefundSort() {
    let refund = this.state.sortOrder;
    let Temp = [];
    refund.map((paymentrefund) => {
      if (paymentrefund.order.payment_status == 'received') {
        Temp.push(paymentrefund);
      }
    });
    this.setState({ orders: Temp });
  }
  handleCancelledSort() {
    console.log('cancelOrder');
    let cancelOrder = this.state.sortOrder;

    let Temp = [];
    cancelOrder.map((ordercancel) => {
      if (ordercancel.order.order_status == 'cancelled') {
        Temp.push(ordercancel);
      }
    });
    this.setState({ orders: Temp });
  }
  render() {
    return (
      <body
        className="vertical-layout vertical-menu-modern semi-dark-layout 2-columns  navbar-floating footer-static  "
        data-open="click"
        data-menu="vertical-menu-modern"
        data-col="2-columns"
        data-layout="semi-dark-layout"
      >
        {/* <Header />
        <Sidebar />  */}
        <div className="app-content content">
          <div className="content-overlay"></div>
          <div className="header-navbar-shadow"></div>
          <div className="content-wrapper" style={{ minHeight: '79vh' }}>
            <div className="content-header row">
              <div className="content-header-left col-md-9 col-12 mb-2">
                <div className="row breadcrumbs-top">
                  <div className="col-12">
                    <h2 className="content-header-title float-left mb-0">
                      Orders
                    </h2>
                    <div className="breadcrumb-wrapper col-12">
                      <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                          <Link to="/dashboard">Home</Link>
                        </li>
                        <li className="breadcrumb-item active">Orders</li>
                      </ol>
                    </div>
                  </div>
                </div>
              </div>
              <div class="content-header-right text-md-right col-md-3 col-12 d-md-block">
                <div class="form-group breadcrum-right xs-text-right"></div>
              </div>
            </div>
            <div className="content-body">
              <Modal isOpen={modal} toggle={this.closeModalToggle}>
                <ModalHeader toggle={this.closeModalToggle}>
                  <h5 className="info-modal-title">
                    You are about to publishing a copy listing
                  </h5>
                </ModalHeader>
                <ModalBody>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: this.state.productInfo,
                    }}
                  />
                  <table className="table table-bordered">
                    <thead>
                      <tr>
                        <th>No</th>
                        <th>Type</th>
                        <th>Cost</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>1</td>
                        <td>Product</td>
                        <td>${this.state.productCharge}</td>
                      </tr>
                      {this.state.productImageCharge ? (
                        <tr>
                          <td>2</td>
                          <td>Image</td>
                          <td>${this.state.productImageCharge}</td>
                        </tr>
                      ) : null}
                      {this.state.productImageCharge ? (
                        <tr>
                          <td colSpan={2}>Total Cost</td>
                          <td>
                            $
                            {parseFloat(this.state.productImageCharge) +
                              parseFloat(this.state.productCharge)}
                          </td>
                        </tr>
                      ) : (
                        <tr>
                          <td colSpan={2}>Total Cost</td>
                          <td>${parseFloat(this.state.productCharge)}</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </ModalBody>
                <ModalFooter>
                  <button
                    type="button"
                    className="btn btn-success"
                    onClick={this.paymentOpen}
                  >
                    OK
                  </button>
                  <button
                    type="button"
                    className="btn btn-success "
                    onClick={this.closeModalToggle}
                  >
                    Cancel
                  </button>
                </ModalFooter>
              </Modal>
              <section id="add-row">
                <div className="row">
                  <div className="col-12">
                    <div className="card">
                      <div className="card-content">
                        <div className="card-body">
                          <div className="over-date">
                            <div className="order-list-header sa-order-nowrap custom-picker">
                              <span className="order-span font16" style={{ marginRight: '5px' }}>
                                {' '}Date From{' '}
                              </span>
                              {/* <input
                              type="date"
                              id="first-name-floating-icon"
                              className="form-control order-control"
                              name="startDate"
                              value={this.state.startDate}
                              onChange={this.handlerChange}
                              placeholder="Date To"
                            /> */}
                              <DatePicker
                                selected={this.state.startDate}
                                onChange={this.handleFromDateChange}
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                                style={{ width: '90px' }}
                              />
                              <span
                                className="order-span font16"
                                style={{ marginRight: '5px' }}
                              >
                                {' '}
                                To{' '}
                              </span>
                              {/* <input
                              type="date"
                              id="first-name-floating-icon"
                              className="form-control order-control"
                              name="endDate"
                              value={this.state.endDate}
                              onChange={this.handlerChange}
                              placeholder="Date To"
                            /> */}
                              <DatePicker
                                selected={this.state.endDate}
                                onChange={this.handleEndDateChange}
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                                style={{ width: '90px !important' }}
                              />
                              {this.state.totalCount ? (
                                <>
                                  <select
                                    className="form-control order-control font16"
                                    style={{
                                      paddingTop: '0',
                                      paddingBottom: '0',
                                      width: '200px',
                                    }}
                                    value={this.state.actionTask}
                                    id="change"
                                    onChange={(e) => this.actionChange(e)}
                                  >
                                    <option>Choose an action</option>
                                    <option value="printSelectedDates">
                                      Print Selected dates
                                    </option>
                                    {/* <option value="notvisible">
                                      Print Selected Invoice
                                    </option> */}
                                    <option value="exportAll">
                                      Export All
                                    </option>
                                    <option value="outofstock">
                                      Export Selected Dates
                                    </option>
                                    {/* <option value="multiediting">
                                      Email Selected File
                                    </option> */}
                                    {/* <option value="multiediting">Add Tracking</option> */}
                                    <option value="MarkAsShipped">
                                      Mark as shipped
                                    </option>
                                    <option value="MarkAsPaymentReceived">
                                      Mark as payment received
                                    </option>
                                    <option
                                      id="addEditNotes"
                                      value="addEditNotes"
                                    >
                                      Add/Edit Notes
                                    </option>
                                  </select>

                                  <div
                                    class="modal fade"
                                    id="exampleModalCenter"
                                    tabIndex="-1"
                                    role="dialog"
                                    aria-labelledby="exampleModalCenterTitle"
                                    aria-hidden="true"
                                  >
                                    <div
                                      class="modal-dialog modal-dialog-centered"
                                      role="document"
                                    >
                                      <div class="modal-content">
                                        <div class="modal-header">
                                          <h5
                                            class="modal-title"
                                            id="exampleModalLongTitle"
                                          >
                                            Add/Edit Notes
                                          </h5>
                                          <button
                                            type="button"
                                            class="close"
                                            data-dismiss="modal"
                                            aria-label="Close"
                                          >
                                            <span aria-hidden="true">
                                              &times;
                                            </span>
                                          </button>
                                        </div>
                                        <div class="modal-body">
                                          <textarea
                                            type="text"
                                            class="form-control"
                                            placeholder="AddEditNote"
                                            name="AddEditNote"
                                            value={this.state.AddEditNote}
                                            onChange={this.handleChange}
                                            aria-label="Amount"
                                          />
                                        </div>
                                        <div class="modal-footer">
                                          <button
                                            type="button"
                                            class="btn btn-secondary"
                                            data-dismiss="modal"
                                          >
                                            Close
                                          </button>
                                          <button
                                            type="button"
                                            data-dismiss="modal"
                                            class="btn btn-primary"
                                            onClick={() => {
                                              this.addNoteAPI();
                                            }}
                                          >
                                            Save changes
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  {console.log(this.state.lodingconfirm)}
                                  {this.state.downlodelink&&this.state.downlodelink!==''&&(<div style={{itemAlign:'center'}}><iframe height="300" src={this.state.downlodelink}/> ddddd</div>)}
                                  {this.state.actionTask == 'addEditNotes' ? (
                                    <>
                                      <button
                                        className="btn btn-outline-primary float-left btn-inline res-btn cnf-btn order-confirm-btn"
                                        data-toggle="modal"
                                        data-target="#exampleModalCenter"
                                        onClick={()=>this.checkAction()}
                                        style={{ paddingTop: '10px' }}
                                      >
                                        Confirm
                                      </button>
                                    </>
                                  ) : (

                                this.state.lodingconfirm===false?
                                    <button
                                      className="btn btn-outline-primary float-left btn-inline res-btn cnf-btn order-confirm-btn"
                                      onClick={(e)=>{this.setState({lodingconfirm:true});this.checkAction()}}
                                      style={{ paddingTop: '10px' }}
                                    >
                                      Confirm
                                    </button>
                                    :<Spinner/>
                                  
                                  )}

                                  {/* <button
                                  className="btn btn-outline-primary float-left btn-inline res-btn cnf-btn order-confirm-btn"
                                  // data-toggle="modal" 
                                  // data-target="#exampleModalCenter" 
                                  onClick={this.checkAction} style={{ paddingTop: "10px" }}>
                                  Confirm
                              </button> */}
                              {this.state.loadingprint===false?
                                  <i
                                    className="fa fa-print order-print"
                                    onClick={() => {this.setState({loadingprint:true});this.printClick();this.mobilepdf()}}
                                    style={{ cursor: 'pointer' }}
                                  ></i>
                                  :<Spinner/>
                                }
                                  {this.state.loading===false?<i
                                    className="fa fa-download order-print"
                                    onClick={() => {this.setState({loading:true});this.pdfContent()}}
                                    style={{ cursor: 'pointer' }}
                                  ></i>:<Spinner/>
                                }

                                  {/* <select
                                    className="form-control action-select order-sort font16"
                                    style={{
                                      paddingTop: '0',
                                      paddingBottom: '0',
                                      width: '200px',
                                    }}
                                    value={this.state.actionSortTask}
                                    onChange={(e) => this.actionSortChange(e)}
                                  >
                                    <option>SORT</option>
                                    <option value="Delivered">Date Sold</option>
                                    <option value="notvisible">
                                      Customers
                                    </option>
                                    <option value="total">Total</option>
                                    <option value="Pending">Pending</option>
                                    <option value="multiediting">
                                      Partially Shipped
                                    </option>
                                    <option value="Completed">Completed</option>
                                    <option value="Shipped">Shipped</option>
                                    <option value="Cancelled">Cancelled</option>
                                    <option value="outofstock">Decline</option>
                                    <option value="Refunded">Refunded</option>
                                    <option value="outofstock">Disputed</option>
                                    <option value="outofstock">
                                      Manual Verification Required
                                    </option>
                                    <option value="outofstock">
                                      Partially Refunded
                                    </option>
                                  </select>
                                   */}
                                  <input
                                    type="text"
                                    id="first-name-floating-icon"
                                    className="form-control order-control"
                                    name="searchProduct"
                                    value={this.state.searchProduct}
                                    onChange={this.handleSearchProduct}
                                    placeholder="Search"
                                    style={{ width: '80px' }}
                                  />
                                </>
                              ) : null}
                              <span class="dropdown dropdown-user nav-item">
                                <a
                                  class="dropdown-toggle setting-toggle nav-link dropdown-user-link p-0"
                                  href="#"
                                  data-toggle="dropdown"
                                >
                                  <div class="user-nav d-sm-flex">
                                    <i
                                      className="fa fa-cog set-icon"
                                      style={{ marginLeft: '10px' }}
                                    />
                                  </div>
                                  <span></span>
                                </a>
                                <div
                                  class="dropdown-menu dropdown-menu-right"
                                  style={{ marginTop: '-15px' }}
                                >
                                  <div
                                    class="vs-checkbox-con vs-checkbox-primary"
                                    style={{ padding: '7px' }}
                                  >
                                    <input
                                      type="checkbox"
                                      name="totalCheckbox"
                                      value={this.state.totalCheckbox}
                                      onChange={(e) =>
                                        this.checkBoxHandleChange(e)
                                      }
                                      checked={this.state.totalCheckbox}
                                    />
                                    <span class="vs-checkbox">
                                      <span
                                        class="vs-checkbox--check"
                                        style={{ paddingLeft: '7px' }}
                                      >
                                        <i class="vs-icon feather icon-check"></i>
                                      </span>
                                    </span>
                                    <span class="" style={{ width: '150px' }}>
                                      Final Total
                                    </span>
                                  </div>
                                  <div
                                    class="vs-checkbox-con vs-checkbox-primary"
                                    style={{ padding: '7px' }}
                                  >
                                    <input
                                      type="checkbox"
                                      name="paymentCheckbox"
                                      value={this.state.paymentCheckbox}
                                      onChange={(e) =>
                                        this.checkBoxHandleChange(e)
                                      }
                                      checked={this.state.paymentCheckbox}
                                    />
                                    <span class="vs-checkbox">
                                      <span
                                        class="vs-checkbox--check"
                                        style={{ paddingLeft: '7px' }}
                                      >
                                        <i class="vs-icon feather icon-check"></i>
                                      </span>
                                    </span>
                                    <span class="" style={{ width: '150px' }}>
                                      Payment
                                    </span>
                                  </div>
                                  <div
                                    class="vs-checkbox-con vs-checkbox-primary"
                                    style={{ padding: '7px' }}
                                  >
                                    <input
                                      type="checkbox"
                                      name="shippingCheckbox"
                                      value={this.state.shippingCheckbox}
                                      onChange={(e) =>
                                        this.checkBoxHandleChange(e)
                                      }
                                      checked={this.state.shippingCheckbox}
                                    />
                                    <span class="vs-checkbox">
                                      <span
                                        class="vs-checkbox--check"
                                        style={{ paddingLeft: '7px' }}
                                      >
                                        <i class="vs-icon feather icon-check"></i>
                                      </span>
                                    </span>
                                    <span class="" style={{ width: '150px' }}>
                                      Shipping
                                    </span>
                                  </div>
                                  <div
                                    class="vs-checkbox-con vs-checkbox-primary"
                                    style={{ padding: '7px' }}
                                  >
                                    <input
                                      type="checkbox"
                                      name="noteCheckbox"
                                      value={this.state.noteCheckbox}
                                      onChange={(e) =>
                                        this.checkBoxHandleChange(e)
                                      }
                                      checked={this.state.noteCheckbox}
                                    />
                                    <span class="vs-checkbox">
                                      <span
                                        class="vs-checkbox--check"
                                        style={{ paddingLeft: '7px' }}
                                      >
                                        <i class="vs-icon feather icon-check"></i>
                                      </span>
                                    </span>
                                    <span class="" style={{ width: '150px' }}>
                                      Note
                                    </span>
                                  </div>
                                  <div
                                    class="vs-checkbox-con vs-checkbox-primary"
                                    style={{ padding: '7px' }}
                                  >
                                    <input
                                      type="checkbox"
                                      name="carrierCheckbox"
                                      value={this.state.carrierCheckbox}
                                      onChange={(e) =>
                                        this.checkBoxHandleChange(e)
                                      }
                                      checked={this.state.carrierCheckbox}
                                    />
                                    <span class="vs-checkbox">
                                      <span
                                        class="vs-checkbox--check"
                                        style={{ paddingLeft: '7px' }}
                                      >
                                        <i class="vs-icon feather icon-check"></i>
                                      </span>
                                    </span>
                                    <span class="" style={{ width: '150px' }}>
                                      Carrier
                                    </span>
                                  </div>
                                  <div
                                    class="vs-checkbox-con vs-checkbox-primary"
                                    style={{ padding: '7px' }}
                                  >
                                    <input
                                      type="checkbox"
                                      name="trackingCheckbox"
                                      value={this.state.trackingCheckbox}
                                      onChange={(e) =>
                                        this.checkBoxHandleChange(e)
                                      }
                                      checked={this.state.trackingCheckbox}
                                    />
                                    <span class="vs-checkbox">
                                      <span
                                        class="vs-checkbox--check"
                                        style={{ paddingLeft: '7px' }}
                                      >
                                        <i class="vs-icon feather icon-check"></i>
                                      </span>
                                    </span>
                                    <span class="" style={{ width: '150px' }}>
                                      Tracking
                                    </span>
                                  </div>
                                  <div
                                    class="vs-checkbox-con vs-checkbox-primary"
                                    style={{ padding: '7px' }}
                                  >
                                    <input
                                      type="checkbox"
                                      name="viewCheckbox"
                                      value={this.state.viewCheckbox}
                                      onChange={(e) =>
                                        this.checkBoxHandleChange(e)
                                      }
                                      checked={this.state.viewCheckbox}
                                    />
                                    <span class="vs-checkbox">
                                      <span
                                        class="vs-checkbox--check"
                                        style={{ paddingLeft: '7px' }}
                                      >
                                        <i class="vs-icon feather icon-check"></i>
                                      </span>
                                    </span>
                                    <span class="" style={{ width: '150px' }}>
                                      view
                                    </span>
                                  </div>
                                </div>
                              </span>
                            </div>
                          </div>
                          <Table
                            responsive
                            hover
                            className="display border-product-list-table table16"
                            style={{ fontSize: '16px' }}
                          >
                            {this.state.finalTotal ? (
                              <>
                                <thead>
                                  <tr
                                    style={{
                                      verticalAlign: 'middle',
                                    }}
                                  >
                                    {/* <th scope="col" style={{ fontSize: "16px" }}>Check</th> */}
                                    <label for="checkbox1" class="checkbox">
                                      <input
                                        type="checkbox"
                                        checked={allOrderCheck}
                                        onClick={() => this.allOrderSelection()}
                                        style={{
                                          marginTop: '16px',
                                          marginLeft: '14px',
                                          height: '20px',
                                          width: '15px',
                                        }}
                                      />
                                    </label>
                                    <th
                                      scope="col"
                                      style={{ fontSize: '16px' }}
                                    >
                                      Order
                                    </th>
                                    <th
                                      scope="col"
                                      style={{ fontSize: '16px' }}
                                    >
                                      Date/Time
                                    </th>
                                    <th
                                      scope="col"
                                      style={{ fontSize: '16px' }}
                                    >
                                      Customer
                                    </th>
                                    <th
                                      scope="col"
                                      style={{ fontSize: '16px' }}
                                    >
                                      Product Name
                                    </th>
                                    <th
                                      scope="col"
                                      style={{ fontSize: '16px' }}
                                    >
                                      Order_Total
                                      </th>
                                    {this.state.totalCheckbox ? (
                                      <th
                                        scope="col"
                                        style={{ fontSize: '16px' }}
                                      >
                                        Total
                                      </th>
                                    ) : null}
                                    {this.state.paymentCheckbox ? (
                                      <th
                                        scope="col"
                                        style={{ fontSize: '16px' }}
                                      >
                                        Payment
                                      </th>
                                    ) : null}
                                    {this.state.shippingCheckbox ? (
                                      <th
                                        scope="col"
                                        style={{ fontSize: '16px' }}
                                      >
                                        Shipping
                                      </th>
                                    ) : null}
                                    {this.state.noteCheckbox ? (
                                      <th
                                        scope="col"
                                        style={{ fontSize: '16px' }}
                                      >
                                        Note
                                      </th>
                                    ) : null}
                                    {this.state.carrierCheckbox ? (
                                      <th
                                        scope="col"
                                        style={{ fontSize: '16px' }}
                                      >
                                        Carrier
                                      </th>
                                    ) : null}
                                    {this.state.trackingCheckbox ? (
                                      <th
                                        scope="col"
                                        style={{ fontSize: '16px' }}
                                      >
                                        Tracking
                                      </th>
                                    ) : null}
                                    {this.state.viewCheckbox ? (
                                      <th
                                        scope="col"
                                        style={{ fontSize: '16px' }}
                                      >
                                        View
                                      </th>
                                    ) : null}
                                  </tr>
                                </thead>
                                <tbody>
                                  {this.state.totalCount ? (
                                    this.state.listdata.sort((a,b)=>a.createdAt.getTime()-b.createdAt.getTime()).map((order, index) => (
                                      <tr key={index}>
                                        {/* <td>
                                      <label htmlFor="checkbox1" className="checkbox">
                                        <input
                                          type="checkbox"
                                          style={{ height: "20px", width: "16px" }}
                                        />
                                      </label>
                                    </td> */}

                                        <td>
                                          {' '}
                                          <label
                                            for="checkbox1"
                                            class="checkbox"
                                          >
                                            <input
                                              type="checkbox"
                                              checked={order.checked}
                                              onChange={() =>
                                                this.orderCheck(index)
                                              }
                                              style={{
                                                height: '20px',
                                                width: '15px',
                                              }}
                                            />
                                          </label>
                                        </td>

                                        <Link
                                          to={'/vieworder/' + order.order_Id}
                                        >
                                          <td style={{ minWidth: '144px' }}>
                                            {order.order_Id}
                                          </td>
                                        </Link>
                                        <td style={{ minWidth: '250px' }}>
                                          {moment(order.createdAt).format(
                                            'MM/DD/YYYY,h:mm a '
                                          )}
                                        </td>
                                        <th style={{ minWidth: '160px' }}>
                                          {order.userId
                                            ? order.userId.name
                                            : null}
                                        </th>
                                        <td style={{ minWidth: '200px' }}>
                                          {order.productId
                                            ? order.productId.product_name
                                            : null}
                                        </td>
                                        <td style={{ minWidth: '200px' }}>
                                          {order
                                            ? order.bag_total
                                            : null}
                                        </td>
                                        {this.state.totalCheckbox ? (
                                          <td>{order.bag_total}</td>
                                        ) : null}
                                        {this.state.paymentCheckbox ? (
                                          <td>
                                            {order.order
                                              ? order.order.payment_status
                                              : null}
                                          </td>
                                        ) : null}

                                        {this.state.shippingCheckbox ? (
                                          <td>
                                            {order.order
                                              ? order.order.order_status
                                              : null}
                                          </td>
                                        ) : null}

                                        {this.state.noteCheckbox ? (
                                          <td className="add-edit-note">
                                            {order
                                              ? order.Order_add_edit_notes
                                              : null}
                                          </td>
                                        ) : null}
                                        {this.state.carrierCheckbox ? (
                                          <td>Carrier</td>
                                        ) : null}

                                        {this.state.trackingCheckbox ? (
                                          <td>
                                            {order.order
                                              ? order.order.order_status
                                              : null}
                                          </td>
                                        ) : null}

                                        {this.state.viewCheckbox ? (
                                          <td>
                                            <Link
                                              to={
                                                '/vieworder/' + order.order_Id
                                              }
                                            >
                                              View
                                            </Link>
                                          </td>
                                        ) : null}
                                      </tr>
                                    ))
                                  ) : (
                                    <tr style={{ textAlign: "center" }}>
                                      <td colSpan={12}>No Order Available.</td>
                                    </tr>
                                  )}
                                </tbody>
                              </>
                            ) : (
                              <>
                                <thead>
                                  <tr
                                    style={{
                                      verticalAlign: 'middle',
                                    }}
                                  >
                                    {/* <th scope="col" style={{ fontSize: "16px" }}>Check</th> */}
                                    <label for="checkbox1" class="checkbox">
                                      <input
                                        type="checkbox"
                                        checked={allOrderCheck}
                                        onClick={() => this.allOrderSelection()}
                                        style={{
                                          marginTop: '16px',
                                          marginLeft: '14px',
                                          height: '20px',
                                          width: '15px',
                                        }}
                                      />
                                    </label>
                                    <th
                                      scope="col"
                                      style={{ fontSize: '16px' }}
                                    >
                                      Order
                                    </th>
                                    <th
                                      scope="col"
                                      style={{ fontSize: '16px' }}
                                    >
                                      Date/Time
                                    </th>
                                    <th
                                      scope="col"
                                      style={{ fontSize: '16px' }}
                                    >
                                      Customer
                                    </th>
                                    <th
                                      scope="col"
                                      style={{ fontSize: '16px' }}
                                    >
                                      Product Name
                                    </th>
                                    <th
                                      scope="col"
                                      style={{ fontSize: '16px' }}
                                    >
                                      Order Total
                                      </th>
                                    {this.state.totalCheckbox ? (
                                      <th
                                        scope="col"
                                        style={{ fontSize: '16px' }}
                                      >
                                        Total
                                      </th>
                                    ) : null}
                                    {this.state.paymentCheckbox ? (
                                      <th
                                        scope="col"
                                        style={{ fontSize: '16px' }}
                                      >
                                        Payment
                                      </th>
                                    ) : null}
                                    {this.state.shippingCheckbox ? (
                                      <th
                                        scope="col"
                                        style={{ fontSize: '16px' }}
                                      >
                                        Shipping
                                      </th>
                                    ) : null}
                                    {this.state.noteCheckbox ? (
                                      <th
                                        scope="col"
                                        style={{ fontSize: '16px' }}
                                      >
                                        Note
                                      </th>
                                    ) : null}
                                    {this.state.carrierCheckbox ? (
                                      <th
                                        scope="col"
                                        style={{ fontSize: '16px' }}
                                      >
                                        Carrier
                                      </th>
                                    ) : null}
                                    {this.state.trackingCheckbox ? (
                                      <th
                                        scope="col"
                                        style={{ fontSize: '16px' }}
                                      >
                                        Tracking
                                      </th>
                                    ) : null}
                                    {this.state.viewCheckbox ? (
                                      <th
                                        scope="col"
                                        style={{ fontSize: '16px' }}
                                      >
                                        View
                                      </th>
                                    ) : null}
                                  </tr>
                                </thead>
                                <tbody>
                                  {this.state.totalCount ? (
                                    this.state.listdata.sort((a,b)=>new Date(a.createdAt).getTime()-new Date(b.createdAt).getTime()).map((order, index) => (
                                      <tr key={index}>
                                        {/* <td>
                                      <label htmlFor="checkbox1" className="checkbox">
                                        <input
                                          type="checkbox"
                                          style={{ height: "20px", width: "16px" }}
                                        />
                                      </label>
                                    </td> */}
                                        <td>
                                          {' '}
                                          <label
                                            for="checkbox1"
                                            class="checkbox"
                                          >
                                            <input
                                              type="checkbox"
                                              checked={order.checked}
                                              onChange={() =>
                                                this.orderCheck(index)
                                              }
                                              style={{
                                                height: '20px',
                                                width: '15px',
                                              }}
                                            />
                                          </label>
                                        </td>

                                        <Link
                                          to={'/vieworder/' + order.order_Id}
                                        >
                                          {' '}
                                          <td style={{ minWidth: '144px' }}>
                                            {order.order_Id}
                                          </td>
                                        </Link>
                                        <td style={{ minWidth: '250px' }}>
                                          {moment(order.createdAt).format(
                                            'MM/DD/YYYY,h:mm a '
                                          )}
                                        </td>
                                        <th style={{ minWidth: '160px' }}>
                                          {order.userId
                                            ? order.userId.name
                                            : null}
                                        </th>
                                        <td
                                          className="prod-ellip"
                                          style={{ minWidth: '200px' }}
                                        >
                                          {order.productId
                                            ? order.productId.product_name
                                            : null}
                                        </td>
                                        <td>{order && order.bag_total}</td>
                                        {this.state.totalCheckbox ? (
                                          <td>{order.bag_total}</td>
                                        ) : null}
                                        {this.state.paymentCheckbox ? (
                                          <td>
                                            {order.order
                                              ? order.order.payment_status
                                              : null}
                                          </td>
                                        ) : null}
                                        {this.state.shippingCheckbox ? (
                                          <td>
                                            {order.order
                                              ? order.order.order_status
                                              : null}
                                          </td>
                                        ) : null}
                                        {this.state.noteCheckbox ? (
                                          <td className="add-edit-note">
                                            {order
                                              ? order.Order_add_edit_notes
                                              : null}
                                          </td>
                                        ) : null}
                                        {this.state.carrierCheckbox ? (
                                          <td>Carrier</td>
                                        ) : null}

                                        {this.state.trackingCheckbox ? (
                                          <td>
                                            {order.order
                                              ? order.order.order_status
                                              : null}
                                          </td>
                                        ) : null}

                                        {this.state.viewCheckbox ? (
                                          <td>
                                            <Link
                                              to={
                                                '/vieworder/' + order.order_Id
                                              }
                                            >
                                              View
                                            </Link>
                                          </td>
                                        ) : null}
                                      </tr>
                                    ))
                                  ) : (
                                    <tr style={{ textAlign: "center" }}>
                                      <td colSpan={12}>No Order Available.</td>
                                    </tr>
                                  )}
                                </tbody>
                              </>
                            )}
                          </Table>

                          {this.state.totalCount ? (
                            <ReactPaginate
                              previousLabel={'prev'}
                              nextLabel={'next'}
                              breakLabel={'...'}
                              breakClassName={'break-me'}
                              pageCount={this.state.pageCount}
                              marginPagesDisplayed={2}
                              pageRangeDisplayed={5}
                              onPageChange={this.handlePageClick}
                              containerClassName={'pagination'}
                              subContainerClassName={'pages pagination'}
                              activeClassName={'active'}
                            />
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <ReactToPrint
                trigger={() => (
                  <button
                    ref={(printButton) =>
                      (this.printButtonElement = printButton)
                    }
                    style={{ display: 'none' }}
                  >
                    Print this out!
                  </button>
                )}
                content={() => this.componentRef}
                style={{ display: 'none' }}
              />
              <div id="printMe" style={{ display: 'none' }}>
                <ComponentToPrint
                  ref={(el) => (this.componentRef = el)}
                  style={{ display: 'none' }}
                />
              </div>
              <div>
                {/* <button variant="warning" onClick={() => this.exportAll(this.state.allProduct,"download")}>Export</button> */}

                <ReactToPdf targetRef={targetRef} filename="download.pdf">
                  {({ toPdf }) => (
                    <button
                      onClick={toPdf}
                      ref={(pdfButton) => (this.pdfElement = pdfButton)}
                      style={{ display: 'none' }}
                    >
                      Generate pdf
                    </button>
                  )}
                </ReactToPdf>
                <div
                  style={{ background: 'white' }}
                  ref={targetRef}
                  id="pdfDiv"
                ></div>
                <div id="pdfTableDiv" style={{ display: 'none' }}>
                  <Table
                    responsive
                    hover
                    className="display border-product-list-table"
                    style={{ fontSize: '12px' }}
                    id="pdfDiv"
                  >
                    <thead>
                      <tr>
                        <th scope="col">No</th>
                        <th scope="col">Order</th>
                        <th scope="col">Date/Time</th>
                        <th scope="col">Customer</th>
                        <th scope="col">Product Name</th>
                        <th scope="col">Order Total</th>
                        <th scope="col">Total</th>
                        <th scope="col">Payment</th>
                        <th scope="col">Shipping</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.totalCount ? (
                        this.state.listdata.sort((a,b)=>new Date(a.createdAt).getTime()-new Date(b.createdAt).getTime()).map((order, index) => (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td style={{ minWidth: '144px' }}>
                              {order.order_Id}
                            </td>
                            <td style={{ minWidth: '250px' }}>
                              {moment(order.createdAt).format(
                                'MM/DD/YYYY,h:mm a '
                              )}
                            </td>
                            <th>
                              {order.userId
                                ? order.userId.first_name +
                                ' ' +
                                order.userId.last_name
                                : null}
                            </th>
                            <td className="prod-ellip">
                              {order.productId
                                ? order.productId.product_name
                                : null}
                            </td>
                            <td>{order && order.bag_total}</td>
                            <td>{order.bag_total}</td>
                            <td>
                              {order.order ? order.order.payment_status : null}
                            </td>
                            <td>
                              {order.order ? order.order.order_status : null}
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan={6}>No Product Available.</td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* {this.state.downlodelink&&this.state.downlodelink!==''&&(<div style={{itemAlign:'center'}}><iframe src={this.state.downlodelink}/> ddddd</div>)} */}
        <div className="sidenav-overlay"></div>
        <div className="drag-target"></div>
                      
        <Footer />
      </body>
    );
  }
}

export default OrderList;
