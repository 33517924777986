import React from "react";
import { Link,  } from "react-router-dom";
import axios from "axios";
import 'react-toastify/dist/ReactToastify.css'
import './style.css'
// ASSETS
import sideImage from "../../../app-assets/images/pages/login.png";
import "../../../app-assets/vendors/css/vendors.min.css";
import "../../../app-assets/css/bootstrap.css";
import "../../../app-assets/css/bootstrap-extended.css";
import "../../../app-assets/css/colors.css";
import "../../../app-assets/css/components.css";
import "../../../app-assets/css/themes/dark-layout.css";
import "../../../app-assets/css/themes/semi-dark-layout.css";
import "../../../app-assets/css-rtl/pages/authentication.css";
import "../../../app-assets/css-rtl/pages/app-chat.css";
import "../../../assets/css/style.css";
import "../../../app-assets/css/pages/faq.css";
import global from "../../global";
import { toast } from 'react-toastify'
var passwordError = "";
var emailError = "";
var isActive=false
toast.configure()
const CustomToast = () => {
  return (
    <div>
      <i class="fa fa-check success-toast"></i>
    Your Account Is Not Approved!
    </div>
  )
}
class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      passwordError: "",
      emailError: "",
      id:"",
     // isAlreadyLogin: false
    };
    isActive=false
    passwordError = "";
    emailError = "";
  }
  componentDidMount = () => {
    if (localStorage.getItem("sellerdata")) {
      this.setState({
     //   isAlreadyLogin: true
      });
    }
  };

  handleChange = event => {
    this.setState({ [event.target.name]: event.target.value });
    
  };
  loginApiCall = () => {
    var urlencoded = new URLSearchParams();
    urlencoded.append("email", this.state.email);
    urlencoded.append("password", this.state.password);
    axios
      .post(global.baseUrl + "sellerlogin", urlencoded, {
        headers: { "Content-Type": "application/x-www-form-urlencoded" }
      })
      .then(result => {
        console.log(result.data);
        if (result.data.status===1) {
          if (result.data.data[0]) {
            if(result.data.data[0].selerpaymentstatus==="0"&&result.data.data[0].type==="Retailer"){
              toast.success(<CustomToast />, { position: toast.POSITION.TOP_RIGHT })
            }else{
            localStorage.setItem(
              "sellerdata",
              JSON.stringify(result.data.data[0])
            );
              
            this.props.history.push("/dashboard");
            window.location.reload();
            }
          }
        } 
        else if(result.data.status===2){
          isActive=true
          passwordError=result.data.message
          this.setState({id:result.data.data[0].id})
        }
        else {
          passwordError = result.data.message;
          // passwordError= "username and password are invalid"
          this.setState({
            passwordError: "username and password are invalid"
          });
        }
      });
  };
  removeuserdata=()=>{
    localStorage.removeItem("userdata")
  }
  handleSubmit = e => {
    e.preventDefault();
    if (this.state.email === "") {
      this.setState({ emailError: "Please enter email id" });
      emailError = "Please enter email id";
    } else {
      this.setState({ emailError: "" });
      emailError = "";
    }
    if (this.state.password === "") {
      this.setState({ passwordError: "Please enter password " });
      passwordError = "Please enter password ";
    } else {
      this.setState({ passwordError: "" });
      passwordError = "";
    }
    if (
      this.state.email &&
      this.state.password &&
      passwordError === "" &&
      emailError === ""
    ) {
      this.loginApiCall();
    }
  };

  activeAccountHandler=()=>{
    var urlencoded = new URLSearchParams();
    urlencoded.append("id", this.state.id);
    
    axios
      .post(global.baseUrl + "sellerresendotp", urlencoded, {
        headers: { "Content-Type": "application/x-www-form-urlencoded" }
      })
      .then(result => {
        console.log(result.data);
        if (result.data.status) {
            this.props.history.push('/setotp/'+this.state.id);
        } 
      });
  }
  render() {
    //const { isAlreadyLogin } = this.state;

    return (
      <React.Fragment>
        {/* {isAlreadyLogin ? (
          <Redirect to="/dashboard" />
        ) : ( */}
          <body
            class="vertical-layout vertical-menu-modern semi-dark-layout 1-column  navbar-floating footer-static bg-full-screen-image  blank-page blank-page"
            data-open="click"
            data-menu="vertical-menu-modern"
            data-col="1-column"
            data-layout="semi-dark-layout"
          >
            <div class="app-content content">
              <div class="content-overlay"></div>
              <div class="header-navbar-shadow"></div>
              <div class="content-wrapper">
                <div class="content-header row"></div>
                <div class="content-body">
                  <section class="row flexbox-container">
                    <div class="col-xl-8 col-11 d-flex justify-content-center">
                      <div class="card bg-authentication rounded-0 mb-0">
                        <div class="row m-0">
                          <div class="col-lg-6 d-lg-block d-none text-center align-self-center px-1 py-0">
                            <img src={sideImage} alt="branding logo" />
                          </div>
                          <div class="col-lg-6 col-12 p-0">
                            <div class="card rounded-0 mb-0 px-2">
                              <div class="card-header pb-1">
                                <div class="card-title">
                                  <h4 class="mb-0">Login</h4>
                                </div>
                              </div>
                              <p class="px-2">
                                Welcome back, please login to your account.
                              </p>
                              <div class="card-content">
                                <div class="card-body pt-1">
                                    {/* action="index.html"> */}
                                    <fieldset class="form-label-group form-group position-relative has-icon-left">
                                      <input
                                        type="text"
                                        class="form-control"
                                        id="user-name"
                                        placeholder="Email Id"
                                        name="email"
                                        value={this.state.email}
                                        onChange={this.handleChange}
                                      />
                                      <div class="form-control-position pos-user">
                                        <i class="feather icon-user"></i>
                                      </div>
                                      <label for="user-name">Email Id</label>
                                      <p class="error-msg">{emailError}</p>
                                    </fieldset>
                                    <fieldset class="form-label-group position-relative has-icon-left">
                                      <input
                                        type="password"
                                        class="form-control"
                                        id="user-password"
                                        placeholder="Password"
                                        name="password"
                                        value={this.state.password}
                                        onChange={this.handleChange}
                                        onKeyPress={event => {
                                          if (event.key === 'Enter') {
                                            this.loginApiCall()
                                          }}
                                        }
                                      />
                                      <div class="form-control-position">
                                        <i class="feather icon-lock"></i>
                                      </div>
                                      <label for="user-password">
                                        Password
                                      </label>
                                      <p class="error-msg">{passwordError} {isActive?
                                      <>
                                       <button class="click-btn" onClick={this.activeAccountHandler}><u>Click here</u></button> to activate your account.
                                      </>:null}</p>
                                    </fieldset>
                                    <div class="form-group d-flex justify-content-between align-items-center remember-row">
                                      <div class="text-left">
                                        <div class="checkbox">
                                          <div class="vs-checkbox-con vs-checkbox-primary">
                                            <input type="checkbox" />
                                            <span class="vs-checkbox">
                                              <span class="vs-checkbox--check">
                                                <i class="vs-icon feather icon-check"></i>
                                              </span>
                                            </span>
                                            <span class="">Remember me</span>
                                          </div>
                                        </div>
                                      </div>
                                      <div class="text-right">
                                        <Link
                                          to="/forgotpassword"
                                          class="card-link1"
                                        >
                                          Forgot Password?
                                        </Link>
                                      </div>
                                    </div>
                                    <Link
                                      to="/register"
                                      class="btn btn-outline-primary float-left btn-inline res-btn"
                                      onClick={this.removeuserdata}
                                    >
                                      Register
                                    </Link>
                                    <button
                                      type="submit"
                                      onClick={this.handleSubmit}
                                      class="btn btn-primary float-right btn-inline waves-effect waves-light login-btn"
                                    >
                                      Login
                                    </button>
                                </div>
                              </div>
                              <div class="login-footer">
                                <div class="divider">
                              
                                </div>
                         
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </div>
          </body>
        {/* )} */}
      </React.Fragment>
    );
  }
}
export default Login;
