import React from "react";
import {Link} from 'react-router-dom'
import global from '../../global'
import sideImage from '../../../app-assets/images/pages/forgot-password.png'
import axios from 'axios'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
var emailError = ""
toast.configure()
const CustomToast = () => {
  return (
    <div>
      <i class="fa fa-check success-toast"></i>
     Please check your mail to set new password
    </div>
  )
}
class ForgotPassword extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      email: '',
      emailError: ''
    }
    emailError=""
  }
  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value })
  }
  ApiCall = () => {
    var urlencoded = new URLSearchParams();
    urlencoded.append("email", this.state.email);
    axios.post(global.baseUrl+"sellerforgotpassword", urlencoded, {
      headers: { "Content-Type": 'application/x-www-form-urlencoded' }
    })
      .then((result) => {
        console.log(result.data)
        if (result.data.status) {
          //localStorage.setItem('userdata', JSON.stringify(result.data))
        //  this.props.history.push('/resetpassword');
        toast.success(<CustomToast />, { position: toast.POSITION.TOP_RIGHT })
        }
        else{
          emailError="Please enter valid email id"
          this.setState({emailError:"Please enter valid email id"})
        }
      })
  }
  handleSubmit = (e) => {
    e.preventDefault()
    if (this.state.email === '') {
      this.setState({ emailError: 'Please enter email id' })
      emailError='Please enter email id'
    }
    else {
      this.setState({ emailError: '' })
      emailError=''
    }
    console.log(this.state.email)
    if (this.state.email  && emailError==='') {
      console.log("working")
      this.ApiCall();
    }
  }
  render() {
    return (
      <body
        class="vertical-layout vertical-menu-modern semi-dark-layout 1-column  navbar-floating footer-static bg-full-screen-image  blank-page blank-page"
        data-open="click"
        data-menu="vertical-menu-modern"
        data-col="1-column"
        data-layout="semi-dark-layout"
      >
        <div class="app-content content">
          <div class="content-overlay"></div>
          <div class="header-navbar-shadow"></div>
          <div class="content-wrapper">
            <div class="content-header row"></div>
            <div class="content-body">
              <section class="row flexbox-container">
                <div class="col-xl-7 col-md-9 col-10 d-flex justify-content-center px-0">
                  <div class="card bg-authentication rounded-0 mb-0">
                    <div class="row m-0">
                      <div class="col-lg-6 d-lg-block d-none text-center align-self-center">
                        <img
                          src={sideImage}
                          alt="branding logo"
                        />
                      </div>
                      <div class="col-lg-6 col-12 p-0">
                        <div class="card rounded-0 mb-0 px-2 py-1">
                          <div class="card-header pb-1">
                            <div class="card-title">
                              <h4 class="mb-0">Recover your password</h4>
                            </div>
                          </div>
                          <p class="px-2 mb-0">
                            Please enter your email address and we'll send you
                            instructions on how to reset your password.
                          </p>
                          <div class="card-content">
                            <div class="card-body">
                              <form action="index.html">
                                <div class="form-label-group">
                                  <input
                                    type="email"
                                    id="inputEmail"
                                    class="form-control"
                                    placeholder="Email"
                                    name="email"
                                    value={this.state.email}
                                    onChange={this.handleChange}
                                  />
                                  <label for="inputEmail">Email</label>
                                  <p class="error-msg">{emailError}</p>
                                </div>
                              </form>
                              <div class="float-md-left d-block mb-1">
                                <Link
                                  to="/login"
                                  class="btn btn-outline-primary btn-block px-75 res-btn"
                                >
                                  Back to Login
                                </Link>
                              </div>
                              <div class="float-md-right d-block mb-1">
                                <button
                                  onClick={this.handleSubmit}
                                  class="btn btn-primary btn-block px-75 login-btn"
                                >
                                  Recover Password
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </body>
    );
  }
}
export default ForgotPassword