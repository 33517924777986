import React from "react";
//import { BrowserRouter } from "react-router-dom";
import Header from "./Seller/Component/Header/Header";
import Sidebar from "./Seller/Component/Sidebar/Sidebar";
// import Footer from "./Seller/Component/Footer/Footer";
// import BeforLogin from "./BeforeLoginWrapper";
// import AfterLogin from "./AfterLoginWrapper";
class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      seller: ""
    };
    if (localStorage.getItem("sellerdata")) {
      this.state = {
        seller: JSON.parse(localStorage.getItem("sellerdata"))
      };
    }
  }
  componentWillMount() {
    if (localStorage.getItem("sellerdata")) {
      this.setState({ seller: JSON.parse(localStorage.getItem("sellerdata")) });
    }
  }
  render() {
    return (
      <>
        <Header />
        <Sidebar />
        {this.props.children}
      </>
    );
  }
}
export default App;
