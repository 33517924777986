import React from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import Footer from "../../Component/Footer/Footer";
import global from "../../global";
import "./style.css";
const $ = require("jquery");
$.DataTable = require("datatables.net");
var sellerData = {};
class List extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      seller: "",
      products: []
    };
  }

  componentWillMount() {
    if (localStorage.getItem("sellerdata")) {
      this.setState({ seller: JSON.parse(localStorage.getItem("sellerdata")) });
      sellerData = JSON.parse(localStorage.getItem("sellerdata"));
    }
    this.listAPI();
  }
  listAPI() {
    var urlencoded = new URLSearchParams();
    urlencoded.append("userId", sellerData.id);

    axios
      .post(global.baseUrl + "sellerproductuserlist", urlencoded, {
        headers: { "Content-Type": "application/x-www-form-urlencoded" }
      })
      .then(result => {
        console.log(result.data);
        if (result.data.status) {
          this.setState({
            products: result.data.data
          });
          this.dataTable();
        }
      });
  }
  dataTable() {
    $(document).ready(function() {
      $("#list").DataTable();
    });
  }
  render() {
    return (
      <body
        class="vertical-layout vertical-menu-modern semi-dark-layout 2-columns  navbar-floating footer-static  "
        data-open="click"
        data-menu="vertical-menu-modern"
        data-col="2-columns"
        data-layout="semi-dark-layout"
      >
        {/* <Header />
        <Sidebar /> */}
        <div class="app-content content">
          <div class="content-overlay"></div>
          <div class="header-navbar-shadow"></div>
          <div class="content-wrapper">
            <div class="content-header row">
              <div class="content-header-left col-md-9 col-12 mb-2">
                <div class="row breadcrumbs-top">
                  <div class="col-12">
                    <h2 class="content-header-title float-left mb-0">
                      ABANDONED CART
                    </h2>
                    <div class="breadcrumb-wrapper col-12">
                      <ol class="breadcrumb">
                        <li class="breadcrumb-item">
                          <Link to="/dashboard">Home</Link>
                        </li>
                        <li class="breadcrumb-item active">ABANDONED CART</li>
                      </ol>
                    </div>
                  </div>
                </div>
              </div>
              <div class="content-header-right text-md-right col-md-3 col-12 d-md-block d-none">
                <div class="form-group breadcrum-right">
                  <Link
                    to="/addproduct"
                    id="addRow"
                    class="btn btn-primary mb-2 add-product-link"
                  >
                    Edit Email
                  </Link>
                </div>
              </div>
            </div>
            <div class="content-body">
              <section id="add-row">
                <div class="row">
                  <div class="col-12">
                    <div class="card">
                      <div class="card-content">
                        <div class="card-body">
                          <div class="table-responsive">
                            <table id="list">
                              <thead>
                                <tr>
                                  <th>Id</th>
                                  <th>Buyer Name</th>
                                  <th>Product Name</th>
                                  <th>Email</th>
                                  <th>HRS/DAYS-in Cart</th>
                                  <th>No Of Email Send</th>
                                  <th>Send Email</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>Id</td>
                                  <td>abc</td>
                                  <td>mobile</td>
                                  <td>abc@gmail.com</td>
                                  <td>2 hour</td>
                                  <td>2</td>
                                  <td>
                                    <button>send mail</button>
                                  </td>
                                </tr>
                              </tbody>

                              {/* {this.state.products ? (
                                <tbody>
                                  {this.state.products.map((product, index) => (
                                    <tr>
                                      <td style={{ width: "200px" }}>
                                        {index + 1}
                                      </td>
                                      <td style={{ width: "200px" }}>
                                        {product.product_name}
                                      </td>
                                      <td style={{ width: "200px" }}>
                                        <img
                                          src={product.image[0]}
                                          class="round-p-img"
                                          alt=".."
                                          style={{
                                            marginTop: "10px",
                                            marginBottom: "10px"
                                          }}
                                        />
                                      </td>
                                      <td style={{ width: "200px" }}>
                                        {product.category
                                          ? product.category.name
                                          : null}
                                      </td>
                                      <td style={{ width: "200px" }}>
                                        {product.price}
                                      </td>
                                      <td style={{ width: "200px" }}>
                                        <Link to={`/editproduct/${product.id}`}>
                                          <i
                                            className="fa fa-pencil"
                                            style={{
                                              marginLeft: "10px",
                                              marginRight: "10px",
                                              color: "green"
                                            }}
                                          ></i>
                                        </Link>
                                        <Link to={`/viewproduct/${product.id}`}>
                                          <i
                                            className="fa fa-eye"
                                            style={{
                                              marginLeft: "10px",
                                              marginRight: "10px",
                                              color: "black"
                                            }}
                                          ></i>
                                        </Link>
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              ) : null} */}
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
        <div class="sidenav-overlay"></div>
        <div class="drag-target"></div>

        <Footer />
      </body>
    );
  }
}

export default List;
