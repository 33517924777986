import React from "react";
import axios from "axios";
//import { Link } from 'react-router-dom'
import "react-image-lightbox/style.css";
import Footer from "../../Component/Footer/Footer";
import global from "../../global";
import CKEditor from "react-ckeditor-component";
import {
    Button,

    Col,
    FormGroup,
    FormText,

} from "reactstrap";
var abountContent = ""
var aboutError = ""
var submitDisable = false;
var seller = {}
class AboutUs extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            abountContent: "",
            aboutError: ""

        };
        abountContent = "";
        aboutError = ""

    }
    componentDidMount() {
        let url = global.baseUrl + 'SellerSettingDetail'
        axios
            .get(url).then(result => {
                if (result.data.status) {
                    this.setState({ aboutUs: result.data.data.about_us });
                }
            });
    }
    onAboutChange = evt => {
        let about = this.state.aboutContent;

        about = evt.editor.getData();
        this.setState({ aboutContent: about });
        console.log("onChange fired with event info: ", evt.editor.getData());
    }
    resetHandler = () => {
        this.setState({
            aboutContent: "",
            aboutError: ""
        })

        aboutError = ""
        submitDisable = false;

    }
    addAboutCall = () => {
        console.log("djfjdf")
        if (localStorage.getItem("sellerdata")) {
            console.log("local")

            seller = JSON.parse(localStorage.getItem("sellerdata"));
            this.setState({ seller: JSON.parse(localStorage.getItem("sellerdata")) });
            let urlencoded = new URLSearchParams();
            urlencoded.append('id', seller.id)

            urlencoded.append("about_us", this.state.aboutContent);
            axios
                .post(global.baseUrl + "addAboutDetail", urlencoded, {
                    headers: { "Content-Type": "application/x-www-form-urlencoded" }
                })
                .then(result => {
                    console.log(result.data);

                    if (result.data.status) {
                        this.props.history.push("/dashboard");
                        //window.location.reload()
                    } else {
                        console.log(result.data);
                    }
                });
        }
    }
    submitHandler = () => {
        if (this.state.aboutContent === "") {
            this.setState({ aboutError: "Please enter about us" });
            aboutError = "Please enter about us";
        } else {
            this.setState({ aboutError: "" });
            aboutError = "";
        }
        if (this.state.abountContent == "" && aboutError === "") {
            console.log("working")

            submitDisable = true
            this.addAboutCall()
        }
    }
    render() {
        return (
            <body
                class="vertical-layout vertical-menu-modern semi-dark-layout 2-columns  navbar-floating footer-static  "
                data-open="click"
                data-menu="vertical-menu-modern"
                data-col="2-columns"
                data-layout="semi-dark-layout"
            >

                <div class="app-content content" style={{ minHeight: "91vh" }}>
                    <div class="content-overlay"></div>
                    <div class="header-navbar-shadow"></div>
                    <div class="content-wrapper">
                        <div class="content-header row">
                            <div class="content-header-left col-md-9 col-12 mb-2">
                                <div class="row breadcrumbs-top">
                                    <div class="col-12">
                                        <h2 class="content-header-title float-left mb-0">
                                            About Us
                                        </h2>
                                        {/* <div class="breadcrumb-wrapper col-12">
                                            <ol class="breadcrumb">
                                                <li class="breadcrumb-item">
                                                    <Link to="/dashboard">Dashboard</Link>
                                                </li>
                                                <li class="breadcrumb-item active">
                                                    About Us
                                                </li>
                                            </ol>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="content-body">
                            <section class="users-edit" style={{ marginLeft: "15px" }} >

                                <p
                                    dangerouslySetInnerHTML={{
                                        __html: this.state.aboutUs
                                    }}
                                />
                            </section>
                        </div>
                        <div>
                            <FormGroup row className="sr-left">

                                <Col md="8">
                                    <CKEditor activeClass="p10"
                                        content={this.state.aboutContent}
                                        events={{
                                            change: this.onAboutChange
                                        }}
                                    />
                                    <FormText className="help-block">
                                        <p className="login-error">{aboutError}</p>
                                    </FormText>
                                </Col>
                            </FormGroup>
                            <Button
                                // type="submit"
                                className="dd"
                                style={{ fontSize: "14px", margin: "4px" ,backgroundColor:"#47a547" }}
                                size="sm"
                                disabled={submitDisable}
                                onClick={() => this.submitHandler()}
                            >
                                {" "}
                    Add
                  </Button>
                            <Button
                                className="dd"
                                size="sm"
                                style={{ fontSize: "14px", margin: "4px",backgroundColor:"#47a547"  }}
                                type="button" onClick={this.resetHandler}>
                                Reset
                                 </Button>


                        </div>


                    </div>
                </div>
                <div class="sidenav-overlay"></div>
                <div class="drag-target"></div>
                <Footer />
            </body>
        );
    }
}
export default AboutUs;
