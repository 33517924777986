import React from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import global from '../../global'
import sideImage from "../../../app-assets/images/pages/forgot-password.png";
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import "./OTP.css";
var OTPError = "";
const CustomToast = () => {
  return (
    <div>
      <i class="fa fa-check success-toast"></i>
    Send Otp Successfully
    </div>
  )
}
class SetOTP extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      OTP: "",
      OTPError: ""
    };
    OTPError = "";
  }
  handleChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };
  ApiCall = () => {
    var urlencoded = new URLSearchParams();
    urlencoded.append("id", this.props.match.params.id);
    urlencoded.append("OTP", this.state.OTP);
    
    axios
      .post(global.baseUrl + "sellerverifyotp", urlencoded, {
        headers: { "Content-Type": "application/x-www-form-urlencoded" }
      })
      .then(result => {
        console.log(result.data);
        if (result.data.status) {
          //localStorage.setItem('userdata', JSON.stringify(result.data))
          let sellar=JSON.parse(localStorage.getItem("userdata"));
          if(sellar.type==="Retailer"){
            
            this.props.history.push('/sellarinformation');
          }else{
            var urlencoded = new URLSearchParams();
            urlencoded.append("email", sellar.email);
            urlencoded.append("password", sellar.password);
            axios
              .post(global.baseUrl + "sellerlogin", urlencoded, {
                headers: { "Content-Type": "application/x-www-form-urlencoded" }
              })
              .then(result => {
                console.log(result.data);
                if (result.data.status===1) {
                  if (result.data.data[0]) {
                    if(result.data.data[0].selerpaymentstatus==="0"&&result.data.data[0].type==="Retailer"){
                      toast.success(<CustomToast />, { position: toast.POSITION.TOP_RIGHT })
                    }else{
                    localStorage.setItem(
                      "sellerdata",
                      JSON.stringify(result.data.data[0])
                    );
                      
                    this.props.history.push('/editprofile');
                    window.location.reload();
                    }
                  }
                  // this.props.history.push('/editprofile');
                }
              })
          }
        } else {
          OTPError = "Invalid OTP";
          this.setState({ OTPError: "Invalid OTP" });
        }
      });
  };
  resendApiCall = () => {
    var urlencoded = new URLSearchParams();
    urlencoded.append("id", this.props.match.params.id);
    
    axios
      .post(global.baseUrl + "sellerresendotp", urlencoded, {
        headers: { "Content-Type": "application/x-www-form-urlencoded" }
      })
      .then(result => {
        console.log(result.data);
        if (result.data.status) {
          toast.success(<CustomToast />, { position: toast.POSITION.TOP_RIGHT })
          //localStorage.setItem('userdata', JSON.stringify(result.data))
            //this.props.history.push('/login');
        } else {
          OTPError = "Invalid OTP";
          this.setState({ OTPError: "Invalid OTP" });
        }
      });
  };
  handleSubmit = e => {
    e.preventDefault();
    if (this.state.OTP === "") {
      this.setState({ OTPError: "Please enter OTP" });
      OTPError = "Please enter OTP";
    } else {
      this.setState({ OTPError: "" });
      OTPError = "";
    }
    if (this.state.OTP && OTPError === "") {
      console.log("working");
      this.ApiCall();
    }
  };
  render() {
    return (
      <body
        class="vertical-layout vertical-menu-modern semi-dark-layout 1-column  navbar-floating footer-static bg-full-screen-image  blank-page blank-page"
        data-open="click"
        data-menu="vertical-menu-modern"
        data-col="1-column"
        data-layout="semi-dark-layout"
      >
        <div class="app-content content">
          <div class="content-overlay"></div>
          <div class="header-navbar-shadow"></div>
          <div class="content-wrapper">
            <div class="content-header row"></div>
            <div class="content-body">
              <section class="row flexbox-container">
                <div class="col-xl-7 col-md-9 col-10 d-flex justify-content-center px-0">
                  <div class="card bg-authentication rounded-0 mb-0">
                    <div class="row m-0">
                      <div class="col-lg-6 d-lg-block d-none text-center align-self-center">
                        <img src={sideImage} alt="branding logo" />
                      </div>
                      <div class="col-lg-6 col-12 p-0">
                        <div class="card rounded-0 mb-0 px-2 py-1">
                          <div class="card-header pb-1">
                            <div class="card-title">
                              <h4 class="mb-0">Account verification</h4>
                            </div>
                          </div>
                          <p class="px-2 mb-0">
                            Please enter OTP which sended by email or mobile
                            number.
                          </p>
                          <div class="card-content">
                            <div class="card-body">
                              {/* <form > */}
                                <div class="form-label-group">
                                  <input
                                    type="OTP"
                                    id="inputEmail"
                                    class="form-control"
                                    placeholder="OTP"
                                    name="OTP"
                                    value={this.state.OTP}
                                    onChange={this.handleChange}
                                  />
                                  <label for="inputEmail">OTP</label>
                                  <p class="error-msg">{OTPError}</p>

                                  <div class="reset-div">
                                    <button class="resetotp-btn" onClick={this.resendApiCall}>Resend OTP</button>
                                  </div>
                                </div>
                              {/* </form> */}
                              {/* <div class="float-md-left d-block mb-1">
                                <Link
                                  to="/information"
                                  class="btn btn-outline-primary btn-block px-75 res-btn"
                                >
                                  Login
                                </Link>
                              </div> */}
                                                            <div class="float-md-left d-block mb-1">
                                <button
                                  onClick={()=>this.props.history.push('/register')}
                                  class="btn btn-primary btn-block px-75 login-btn"
                                >
                                 Back
                                </button>
                              </div>
                              <div class="float-md-right d-block mb-1">
                                <button
                                  onClick={this.handleSubmit}
                                  class="btn btn-primary btn-block px-75 login-btn"
                                >
                                  Verify
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </body>
    );
  }
}
export default SetOTP;
