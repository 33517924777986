import React from "react";
import axios from 'axios';
//import Select from "react-select";
import { Link } from 'react-router-dom';
import {
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Table,
    Button,
} from 'reactstrap';
import Footer from '../../Component/Footer/Footer';
import global from '../../global';
//import Avtar from "./avtar.jpg";
import moment from 'moment';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import $ from 'jquery';
var startDateError = '';
var endDateError = '';
var imageError3 = '';
var sendImage3 = '';
var producterroe='';
var bannerLocationError="";
export default class Addpromote extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            startDate: '',
            selecttime: 0,
            seller: {},
            productlist: [],
            productid: '',
            locationlist:[],
            banner_location:'',
            bannerImage3:'',
            fileName3:'',
            bannerpricecharge:0,
            day:0,
            bannerLocationError:''
        }
    }
    componentWillMount() {

        axios
        .get(
          global.baseUrl + 'getaboutdetail',
          {},
          {
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
          }
        )
        .then((result) => {
          console.log(result.data.data[0], 'about');
          if (result.data.data[0]) {
            // if (result.data.data[0]) {
            this.setState({
              bannerpricecharge: result.data.data[0].sponcer_product_price,
            });
            // this.setState({ a: "" });
            // }
          }
        });

        this.setState({ seller: JSON.parse(localStorage.getItem('sellerdata')) });
        console.log('Product API');
        let urlencoded = new URLSearchParams();
        urlencoded.append('sellerId', JSON.parse(localStorage.getItem('sellerdata')).id);

        axios
            .post(global.baseUrl + 'sellerProductList', urlencoded, {
                headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            })
            .then(async (result) => {
                console.log(result.data, 'product');
                if (result.data) {
                    let productlist = await result.data.data.filter(function (el) {
                        return el.sellerType === "Freelisting";
                    })
                    console.log(productlist, "product")
                    this.setState({ productlist: productlist })
                }
            });
    }
    handleFromDateChange = (date) => {
        console.log(new Date(date).getTime(), 'date');
        let urlencoded = new URLSearchParams();
        // urlencoded.append('sellerid',JSON.parse(localStorage.getItem('sellerdata')).id);
        // urlencoded.append('date',this.state.startDate);
        // urlencoded.append('product',this.state.productid);
        urlencoded.append('end_mili', new Date(date).getTime());
        // urlencoded.append('sellerId',JSON.parse(localStorage.getItem('sellerdata')).id);

        axios
            .post(global.baseUrl + 'checkrowavailability', urlencoded, {
                headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            })
            .then(async (result) => {
                console.log(result.data, 'product');
                if (result.data) {
                   this.setState({locationlist:result.data.data})
                }
                //  let productlist=await result.data.data.filter(function (el) {
                //     return el.sellerType==="Freelisting";
                //   })
                //   console.log(productlist,"product")
                //   this.setState({productlist:productlist})
                // }
            });
        this.setState({
            startDate: date,
        });
    };
    DesiredLocationDropChangeHandler = async (event) => {
        //   let second=86400000
        console.log(new Date(this.state.startDate).getTime() + (parseInt(event.target.value) * 86400000), "milisecon")
        this.setState({
            selecttime: new Date(this.state.startDate).getTime() + (parseInt(event.target.value) * 86400000),
            day:parseInt(event.target.value),
        });
    };

    DesiredLocationDropChangeHandler2 = async (event) => {
        //   let second=86400000
        this.setState({
            banner_location: event.target.value ,
        });
    };
    productselect = async (event) => {
        //   let second=86400000
        this.setState({ productid: event.target.value })

    };

    onImageChange3 = (event) => {
        console.log('image3');
        if (event.target.files && event.target.files[0]) {
          var Extension = ['jpg', 'jpeg', 'png', 'gif', 'tiff'];
          var extArray = event.target.files[0].name.split('.');
          var ext = extArray[extArray.length - 1];
          var flag = 0;
          for (let i = 0; i < Extension.length; i++) {
            if (ext.toLowerCase() === Extension[i]) {
              flag = 1;
            }
          }
          if (flag) {
            this.setState({ imageError3: '' });
            imageError3 = '';
    
            let fileName3 = event.target.files[0].name;
    
            let reader = new FileReader();
    
            reader.onload = (e) => {
              var image3 = new Image();
              let flag = 1;
              image3.src = e.target.result;
              image3.onload = () => {
                var height = image3.height;
                var width = image3.width;
                console.log(height, width);
                if (width > 375 && height > 70) {
                  this.setState({
                    imagError3: 'Please select image(375*70px)',
                  });
                  imageError3 = 'Please select image(375*70px)';
                  fileName3 = '';
                  this.setState({
                    bannerImage3: '',
                    fileName3: '',
                  });
                } else if (width < 375 && height < 70) {
                  this.setState({
                    imagError3: 'Please select image(375*70px)',
                  });
                  imageError3 = 'Please select image(375*70px)';
                  fileName3 = '';
                  this.setState({
                    bannerImage3: '',
                    fileName3: '',
                  });
                } else {
                  this.setState({
                    bannerImage3: e.target.result,
                    fileName3: fileName3,
                  });
                }
              };
            };
    
            reader.readAsDataURL(event.target.files[0]);
            sendImage3 = event.target.files[0];
            console.log('image', sendImage3);
          } else {
            this.setState({
              bannerImage3: '',
              imagError3: 'Please select only image',
              fileName3: '',
            });
            sendImage3 = '';
            imageError3 = 'Please select only image';
          }
        } else {
          sendImage3 = '';
        }
      };
    
      addImage3 = () => {
        console.log('addimgae3');
        if (sendImage3) {
          const apiUrl = global.baseUrl + 'sellerImageUpload';
          const formData = new FormData();
          formData.append('myFile', sendImage3);
          const config = {
            headers: {
              'content-type': 'multipart/form-data',
            },
          };
          axios
            .post(apiUrl, formData, config)
            .then((response) => {
              console.log('image add3', response.data.path);
              this.setState({ bannerImage3: response.data.path });
              this.addbbaner();
            })
            .catch((error) => {});
        } else {
          this.addbbaner();
        }
      };

      addbbaner=()=>{
        let urlencoded = new URLSearchParams();
        urlencoded.append('sellerid', JSON.parse(localStorage.getItem('sellerdata')).id);
        urlencoded.append('date', this.state.startDate);
        urlencoded.append('product', this.state.productid);
        urlencoded.append('day', this.state.selecttime);
        urlencoded.append('location',this.state.banner_location);
        urlencoded.append('image',this.state.bannerImage3);
        urlencoded.append('price',(parseInt(this.state.bannerpricecharge)*parseInt(this.state.day)));
        axios
            .post(global.baseUrl + 'selleraddpromotion', urlencoded, {
                headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            })
            .then(async (result) => {
                console.log(result.data, 'product');
                this.props.history.push('/bannerlist');
                // if (result.data) {
                //  let productlist=await result.data.data.filter(function (el) {
                //     return el.sellerType==="Freelisting";
                //   })
                //   console.log(productlist,"product")
                //   this.setState({productlist:productlist})
                // }
            });
      }
    handleSubmit2 = () => {
        // let numberCheck = /^[0-9]\d*(\.\d+)?$/;

        if (this.state.startDate === '') {
          this.setState({ startDateError: 'Please select start date' });
          startDateError = 'Please select start date';
        }  else {
          this.setState({ startDateError: '' });
          startDateError = '';
        }

        if (this.state.productid === '') {
            this.setState({producterroe : 'Please select product' });
            producterroe = 'Please select product';
          }else{
            this.setState({producterroe : '' });
            producterroe = '';
          }

          if (this.state.banner_location === '') {
            this.setState({bannerLocationError : 'Please select bannerLocation' });
            bannerLocationError = 'Please select bannerLocation';
          }else{
            this.setState({bannerLocationError : '' });
            bannerLocationError = '';
          }
          if (this.state.bannerImage3 === '') {
            this.setState({ imageError3: 'Please select image' });
            imageError3 = 'Please select image';
          }else{
            this.setState({imageError3 : '' });
            imageError3 = '';
          }
        console.log(this.state.selecttime, "chhhhhh");
        if(startDateError===''&&producterroe===''){
            this.addImage3()
        }
       
    }
    render() {
        return (
            <body
                class="vertical-layout vertical-menu-modern semi-dark-layout 2-columns  navbar-floating footer-static  "
                data-open="click"
                data-menu="vertical-menu-modern"
                data-col="2-columns"
                data-layout="semi-dark-layout"
            >
                {/* <Header />
        <Sidebar /> */}
                <div class="app-content content">
                    <div class="content-overlay"></div>
                    <div class="header-navbar-shadow"></div>
                    <div class="content-wrapper">
                        <div class="content-header-left col-md-9 col-12 mb-2">
                            <div class="row breadcrumbs-top">
                                <div class="col-12">
                                    <Modal isOpen={this.state.showLogOut} fade="false" toggle={() => this.setState({ showLogOut: false })}>
                                        <ModalHeader toggle={() => this.setState({ showLogOut: false })}>Warning</ModalHeader>
                                        <ModalBody>
                                            <p>{this.state.messageto}</p>
                                        </ModalBody>
                                        <ModalFooter>

                                            <Button onClick={() => this.setState({ showLogOut: false })}>Cancel</Button>
                                            <Button color="info" onClick={() => { this.setState({ showLogOut: false, r: true }); this.state.messageto.includes("Allproduct") ? this.addAllOptionHandler2() : this.addOptionHandler2(); }}>Ok</Button>
                                        </ModalFooter>
                                    </Modal>
                                    <h2 class="content-header-title float-left mb-0">
                                        <Link to={'/bannerlist'} className="back-arrow  mr-2">
                                            <i className="feather icon-arrow-left"></i>
                                        </Link>
                                        Add promote Banner
                                    </h2>
                                    <div class="breadcrumb-wrapper col-12">
                                        <ol class="breadcrumb">
                                            {/* <li class="breadcrumb-item">
                                                <Link to="/offerlist">Banners</Link>
                                            </li> */}
                                            <li class="breadcrumb-item active">Add promote Banner</li>
                                        </ol>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="content-body">
                            <section id="dashboard-analytics">
                                <div class="card">
                                    <div class="card-body">
                                        <div class="product-secs">
                                            <div class="row">
                                                <div class="col-md-12">
                                                    <div class="Products-title">
                                                        {/* <h2 class="">Product</h2> */}
                                                        {/* <h5>
                                                            Your Product will show at the sponsor page for
                                                            15min. every hour till the end date.
                                                        </h5> */}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row mt-3">
                                                <div class="col-12 col-lg-2">
                                                    <label className="b-label">
                                                        Start Date<span class="required-span1">*</span>
                                                    </label>
                                                </div>
                                                <div class="col-12 col-lg-4">
                                                    <div class="form-group sr-left  ma-left-minus">
                                                        <DatePicker
                                                            selected={this.state.startDate}
                                                            onChange={this.handleFromDateChange}
                                                            showMonthDropdown
                                                            showYearDropdown
                                                            dropdownMode="select"
                                                            style={{ width: '100%' }}
                                                            className="form-control"
                                                            placeholderText="08/14/2021"
                                                        />

                                                        <p class="error-msg">{startDateError} </p>
                                                    </div>
                                                </div>
                                                <div class="col-12 col-lg-2">
                                                    <label className="b-label">
                                                        Select days<span class="required-span1">*</span>
                                                    </label>
                                                </div>
                                                {/* <div class="col-12 col-lg-4">
                          <div class="form-group sr-left  ma-left-minus">
                            <DatePicker
                              selected={this.state.endDate}
                              onChange={this.handleEndDateChange}
                              showMonthDropdown
                              showYearDropdown
                              dropdownMode="select"
                              className="form-control"
                              placeholderText="08/15/2021"
                              style={{ width: '100% ' }}
                            />
                            <p class="error-msg">{endDateError} </p>
                          </div>
                        </div> */}
                                                <div class="col-12 col-lg-4">
                                                    <div class="form-group sr-left  ma-left-minus">
                                                        <select
                                                            class="form-control"
                                                            id="selecter"
                                                            value={this.state.desiredLocation}
                                                            onChange={(e) =>
                                                                this.DesiredLocationDropChangeHandler(e)
                                                            }
                                                        >
                                                            <option value="1">1</option>
                                                            <option value="3">3</option>
                                                            <option value="5">5</option>
                                                            <option value="7">7</option>
                                                        </select>
                                                        {/* <p class="error-msg">{bannerLocationError} </p> */}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row mt-3">
                                                <div class="col-12 col-lg-2">
                                                    <label className="b-label">
                                                        Select Product<span class="required-span1">*</span>
                                                    </label>
                                                </div>
                                                <div class="col-12 col-lg-4">
                                                    <div class="form-group sr-left  ma-left-minus">
                                                        <select
                                                            class="form-control"
                                                            id="selecter"
                                                            // value={this.state.desiredLocation}
                                                            onChange={(e) =>
                                                                this.productselect(e)
                                                            }
                                                            
                                                        ><option value=''>Plese select product</option>
                                                            {console.log(this.state.productlist, "productfffffff")}
                                                            {this.state.productlist.map((element, index) => (
                                                                <option value={element.id}>{element.product_name}</option>
                                                            ))}
                                                            {/* <option value="1">1</option>
                                    <option value="3">3</option>
                                    <option value="5">5</option>
                                    <option value="7">7</option> */}
                                                        </select>
                                                        <p class="error-msg">{producterroe} </p>
                                                    </div>
                                                </div>
                                                <div class="col-12 col-lg-2">
                                                    <label className="b-label">
                                                        Desired Location<span class="required-span1">*</span>
                                                    </label>
                                                </div>
                                                <div class="col-12 col-lg-4">
                                                    <div class="form-group sr-left  ma-left-minus">
                                                        <select
                                                            class={`form-control ${this.state.locationlist[0]?'':'disablelocation'}`}
                                                            id="selecter"
                                                            // value={this.state.banner_location}
                                                            onChange={(e) =>
                                                                this.DesiredLocationDropChangeHandler2(e)
                                                            }
                                                        >
                                                           <option value=''>Plese select location</option>
                                                           { this.state.locationlist.map((element,index)=>(
                                                               <option value={element}>{element}</option>
                                                           ))}
                                                            {/* <option value="1">1</option>
                                                            <option value="3">3</option>
                                                            <option value="5">5</option>
                                                            <option value="7">7</option> */}
                                                        </select>
                                                        <p class="error-msg">{bannerLocationError} </p>
                                                    </div>
                                                </div>
                                                <div class="col-12 col-lg-2">
                                                    <label className="b-label">
                                                    Single file Upload<span class="required-span1">*</span>
                                                    </label>
                                                </div>
                                                <div
                              class="col-12 col-xl-2 col-md-4 max-single pb-2"
                              style={{
                                display: 'inline-flex',
                                alignItems: 'center',
                                marginLeft: '-7px',
                              }}
                            ></div>
                                                <div class="product-upload-btn pb-2 col-12 col-md-5 col-xl-7">
                                                    <label
                                                        class="btn btn-sm btn-primary-green  mb-50 mb-sm-0 cursor-pointer up-btn ml-sm-1"
                                                        for="account-upload3"
                                                    >
                                                        Upload Single file
                                                    </label>

                                                    <input
                                                        type="file"
                                                        id="account-upload3"
                                                        accept="image/*"
                                                        name="imageUpload"
                                                        onChange={this.onImageChange3}
                                                        multiple
                                                        hidden
                                                    />
                                                    <p
                                                        class="error-msg"
                                                        style={{ paddingLeft: '10px' }}
                                                    >
                                                        {imageError3}
                                                    </p>
                                                </div>
                                                <div class="col-3 " style={{ marginTop: '5px' }}>
                                                    <p>{this.state.fileName3}</p>
                                                </div>
                                                <div class="col-3 " style={{ marginTop: '5px' }}>
                                                {this.state.bannerImage3!==''&&<img src={this.state.bannerImage3} ></img>}
                                                </div>
                                                <div class="row">

                                                </div>
                                                <div class="col-md-12 mt-2" style={{ textAlign: 'end' }}>
                                                    <button
                                                        class="btn btn-primary add-product-link"
                                                        type="submit"
                                                        // disabled={submitDisable}
                                                        // class="btn btn-primary glow mb-1 mb-sm-0 mr-0 mr-sm-1 login-btn"
                                                        onClick={this.handleSubmit2}
                                                    >
                                                        Submit
                                                    </button>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                </div>

                <div class="sidenav-overlay"></div>
                <div class="drag-target"></div>
                <Footer />
            </body>
        );
    }
}