import React from "react";
import { Link } from "react-router-dom";
import global from "../../global";
import axios from "axios";
import { Alert } from 'reactstrap'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import sideImage from "../../../app-assets/images/pages/register.jpg";
var emailError = "";
var passwordError = "";
var nameError = "";
var cPasswordError = "";
var mobileError = "";
var sendImage = ""
var imagError = ""
var typeError = ""
var submitDisable = false;
var termError = ""
var msg = ""
var addressError = ""
var zipcodeError =""
toast.configure()
const CustomToast = () => {
  return (
    <div>
      <i class="fa fa-check success-toast"></i>
    Register Successfully
    </div>
  )
}
class Register extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      userName: "",
      cPassword: "",
      passwordError: "",
      emailError: "",
      cPasswordError: "",
      mobileError: "",
      profileImage: '',
      mobileNumber: '',
      sellerType: '',
      term: false,
      address:'',
      zipcode:'',
      back:false,
      sellerid:'',
    };
    emailError = "";
    addressError = "";
    zipcodeError = "";
    passwordError = "";
    nameError = "";
    cPasswordError = "";
    submitDisable = false;
    sendImage = ""
    imagError = ""
    typeError = ""
    msg = ""
  }

  componentWillMount() {
    let sellerdata={};
    if (localStorage.getItem('userdata')) {
       sellerdata=JSON.parse(localStorage.getItem('userdata'))
       this.setState({
         userName:sellerdata.name,
         email:sellerdata.email,
         password:sellerdata.password,
         sellerType:sellerdata.type,
         profileImage:sellerdata.profile_image,
         mobileNumber:sellerdata.phone_number,
         address:sellerdata.address,
         zipcode:sellerdata.zipCode,
         back:true,
         sellerid:sellerdata.id,
       })
    }
  }
  handleChange = event => {
    console.log(event.target.value ,"value==")
    this.setState({ [event.target.name]: event.target.value });

  };
  handleTermChange = event => {
    this.setState({ term: !this.state.term },
      () => console.log(this.state.term));

  };

  registerApiCall = () => {
    var urlencoded = new URLSearchParams();
    urlencoded.append("name", this.state.userName);
    urlencoded.append("email", this.state.email);
    urlencoded.append("password", this.state.password);
    urlencoded.append("type", this.state.sellerType);
    urlencoded.append("profile_image", this.state.profileImage);
    urlencoded.append("phone_number", this.state.mobileNumber);
    urlencoded.append("address", this.state.address);
    urlencoded.append("zipcode", this.state.zipcode);
    if(this.state.back===true){
      urlencoded.append("userId", this.state.sellerid);
      axios
      .post(global.baseUrl + "sellerregisterback", urlencoded, {
        headers: { "Content-Type": "application/x-www-form-urlencoded" }
      })
      .then(result => {

        if (result.data.status === 1) {
          msg = "";
          this.setState({
            passwordError: ""
          });
          toast.success(<CustomToast />, { position: toast.POSITION.TOP_RIGHT })

          localStorage.setItem('userdata', JSON.stringify(result.data.data[0]))
          this.props.history.push("/setotp/" + result.data.data[0].id);
        } else if (result.data.status === 2) {
          msg = result.data.message;
          submitDisable = false
          this.setState({
            passwordError: ""
          });
        }
      });
    }else{
      axios
      .post(global.baseUrl + "sellerregister", urlencoded, {
        headers: { "Content-Type": "application/x-www-form-urlencoded" }
      })
      .then(result => {

        if (result.data.status === 1) {
          msg = "";
          this.setState({
            passwordError: ""
          });
          toast.success(<CustomToast />, { position: toast.POSITION.TOP_RIGHT })

          localStorage.setItem('userdata', JSON.stringify(result.data.data))
          this.props.history.push("/setotp/" + result.data.data.id);
        } else if (result.data.status === 2) {
          msg = result.data.message;
          submitDisable = false
          this.setState({
            passwordError: ""
          });
        }
      });
    }

    // axios
    //   .post(global.baseUrl + "sellerregister", urlencoded, {
    //     headers: { "Content-Type": "application/x-www-form-urlencoded" }
    //   })
    //   .then(result => {

    //     if (result.data.status === 1) {
    //       msg = "";
    //       this.setState({
    //         passwordError: ""
    //       });
    //       toast.success(<CustomToast />, { position: toast.POSITION.TOP_RIGHT })

    //       localStorage.setItem('userdata', JSON.stringify(result.data.data))
    //       this.props.history.push("/setotp/" + result.data.data.id);
    //     } else if (result.data.status === 2) {
    //       msg = result.data.message;
    //       submitDisable = false
    //       this.setState({
    //         passwordError: ""
    //       });
    //     }
    //   });
  };
  onImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      var Extension = ["jpg", "jpeg", "png", "gif", "tiff"];
      var extArray = event.target.files[0].name.split(".");
      var ext = extArray[extArray.length - 1];
      var flag = 0
      for (let i = 0; i < Extension.length; i++) {
        if (ext.toLowerCase() === Extension[i]) {
          flag = 1
        }
      }
      if (flag) {
        this.setState({ imagError: '' })
        imagError = ''

        let reader = new FileReader();
        reader.onload = (e) => {
          this.setState({ profileImage: e.target.result });
        };
        reader.readAsDataURL(event.target.files[0]);
        sendImage = event.target.files[0]
        console.log('image', sendImage)
      }
      else {
        this.setState({
          profileImage: '',
          imagError: "Please select only image"
        })
        sendImage = ''
        imagError = "Please select only image"

      }
    }
  }
  handleSubmit = e => {
    e.preventDefault();
    // if (this.state.userName === "") {
    //   this.setState({ nameError: "Please enter name" });
    //   nameError = "Please enter name";
    // } else if (
    //   !this.state.userName.match(/^[a-zA-Z-,]+(\s{0,1}[a-zA-Z-, ])*$/)
    // ) {
    //   this.setState({ nameError: "Please enter only character" });
    //   nameError = "Please enter only character";
    // } else {
    //   this.setState({ nameError: "" });
    //   nameError = "";
    // }
    if (this.state.email === "") {
      this.setState({ emailError: "Please enter email id" });
      emailError = "Please enter email id";
    } else if (
      /^\w+([.-]?\w+)*@\w+([-]?\w+)*(\.\w{2,3})+$/.test(this.state.email)
    ) {
      this.setState({ emailError: "" });
      emailError = "";
    } else {
      this.setState({ emailError: "Please enter valid email id" });
      emailError = "Please enter valid email id";
    }
    if (this.state.password === "") {
      this.setState({ passwordError: "Please enter password " });
      passwordError = "Please enter password ";
    } else {
      this.setState({ passwordError: "" });
      passwordError = "";
    }
    // let numberCheck = /^[0-9]+$/;
    // if (this.state.mobileNumber === "") {
    //   this.setState({ mobileError: "Please enter mobile number" });
    //   mobileError = "Please enter mobile number";
    // }
    // else if (
    //   (!numberCheck.test(this.state.mobileNumber)) || this.state.mobileNumber.length <= 8||this.state.mobileNumber.length >= 12) {
    //   this.setState({ mobileError: "Please enter valid mobile number" });
    //   mobileError = "Please enter valid mobile number";
    // }
    // else {
    //   this.setState({ mobileError: "" });
    //   mobileError = "";
    // }
    if (this.state.cPassword === "") {
      this.setState({ cPasswordError: "Please enter confirm password " });
      cPasswordError = "Please enter confirm password ";
    } else {
      if (this.state.password !== this.state.cPassword) {
        this.setState({
          cPasswordError: "Password & confirm password are different"
        });
        cPasswordError = "Password & confirm password are different";
      } else {
        this.setState({ cPasswordError: "" });
        cPasswordError = "";
      }
    }
    // if (this.state.address === "") {
    //   // this.setState({ passwordError: "Please enter password " });
    //   addressError = "Please enter Adress";
    // } else {
    //   // this.setState({ passwordError: "" });
    //   addressError = "";
    // }
    // if (this.state.zipcode === "") {
    //   // this.setState({ passwordError: "Please enter password " });
    //   zipcodeError= "Please enter Zipcode ";
    // } else {
    //   // this.setState({ zipcodeError: "" });
    //   zipcodeError = "";
    // }


    // if (this.state.sellerType === "") {
    //   this.setState({ typeError: "Please select seller type " });
    //   typeError = "Please select seller type ";
    // } else {
    //   this.setState({ typeError: "" });
    //   typeError = "";
    // }
    if (this.state.term) {

      this.setState({ termError: "" })
      termError = ""
    }
    else {
      this.setState({ termError: "Please accept term & condition" })
      termError = "Please accept terms & conditions"
    }
    if (
      // this.state.userName &&
      // this.state.mobileNumber &&
      this.state.email &&
      this.state.password &&
      this.state.cPassword &&
      //this.state.sellerType &&
      passwordError === "" &&
      emailError === "" &&
      // nameError === "" &&
      cPasswordError === "" &&
      // mobileError === "" &&
      typeError === ''
    ) {
      if (this.state.term) {

        this.setState({ termError: "" })
        termError = ""
        submitDisable = true;
        this.addImage();
      }
      else {
        this.setState({ termError: "Please accept term & condition" })
        termError = "Please accept terms & conditions"
      }
    }
  };
  addImage = () => {
    if (sendImage) {
      const apiUrl = global.baseUrl + "sellerImageUpload";
      const formData = new FormData();
      formData.append("myFile", sendImage);
      const config = {
        headers: {
          "content-type": "multipart/form-data"
        }
      };
      axios
        .post(apiUrl, formData, config)
        .then(response => {
          console.log("image add", response.data.filename);
          this.setState({ profileImage: response.data.path });
          this.registerApiCall();
        })
        .catch(error => { });
    } else {
      this.registerApiCall();
    }
  }
  render() {
    return (
      <body
        class="vertical-layout vertical-menu-modern semi-dark-layout 1-column  navbar-floating footer-static bg-full-screen-image  blank-page blank-page"
        data-open="click"
        data-menu="vertical-menu-modern"
        data-col="1-column"
        data-layout="semi-dark-layout"
      >
        <div class="app-content content">
          <div class="content-overlay"></div>
          <div class="header-navbar-shadow"></div>
          <div class="content-wrapper">
            <div class="content-header row"></div>
            <div class="content-body">
              <section class="row flexbox-container">
                <div class="col-xl-9 col-10 d-flex justify-content-center ">
                  <div class="card bg-authentication rounded-0 mb-0 register-form">
                    <div class="row m-0">
                      <div class="col-lg-6 d-lg-block d-none text-center align-self-center pl-0 pr-3 py-0">
                        <img src={sideImage} alt="branding logo" />
                      </div>
                      <div class="col-lg-6 col-12 p-0">

                        <div class="card rounded-0 mb-0 p-2">

                          <div class="card-header pt-50 pb-1">

                            <div class="card-title">
                              <h4 class="mb-0">Create Account</h4>
                            </div>
                          </div>
                          {msg !== "" ?
                            <Alert color="danger" style={{ marginLeft: "15px", marginRight: "15px", fontWeight: "normal" }}>
                              {msg}
                            </Alert> : null}
                          <p class="px-2">
                            Fill the below form to create a new account.
                          </p>

                          <div class="card-content">
                            <div class="card-body pt-0">
                              <form >
                                {/* <div class="form-label-group">
                                  <input
                                    type="text"
                                    id="inputName"
                                    class="form-control"
                                    placeholder="Name"
                                    name="userName"
                                    value={this.state.userName}
                                    onChange={this.handleChange}
                                  />
                                  <label for="inputName">Name</label>
                                  <p class="error-msg">{nameError}</p>
                                </div> */}
                                {/* <div class="form-label-group">
                                  <input
                                    type="text"
                                    minLength={8}
                                    maxLength={11}

                                    id="inputName"
                                    class="form-control"
                                    placeholder="Mobile Number"
                                    name="mobileNumber"

                                    value={this.state.mobileNumber}
                                    onChange={this.handleChange}
                                  />
                                  <label for="inputName">Mobile Number</label>
                                  <p class="error-msg">{mobileError}</p>
                                </div> */}
                                <div class="form-label-group">
                                  <input
                                    type="email"
                                    id="inputEmail"
                                    class="form-control"
                                    placeholder="Email Id"
                                    name="email"
                                    value={this.state.email}
                                    onChange={this.handleChange}
                                  />
                                  <label for="inputEmail">Email Id</label>
                                  <p class="error-msg">{emailError}</p>
                                </div>
                                <div class="form-label-group">
                                  <input
                                    type="password"
                                    id="inputPassword"
                                    class="form-control"
                                    placeholder="Password"
                                    name="password"
                                    value={this.state.password}
                                    onChange={this.handleChange}
                                  />
                                  <label for="inputPassword">Password</label>
                                  <p class="error-msg">{passwordError}</p>
                                </div>
                                <div class="form-label-group">
                                  <input
                                    type="password"
                                    id="inputConfPassword"
                                    class="form-control"
                                    placeholder="Confirm Password"
                                    name="cPassword"
                                    value={this.state.cPassword}
                                    onChange={this.handleChange}
                                  />
                                  <label for="inputConfPassword">
                                    Confirm Password
                                  </label>
                                  <p class="error-msg">{cPasswordError}</p>
                                </div>

                                {/* <div class="form-label-group">
                                  <input
                                    type="file"
                                    id="inputConfPassword"
                                    class="form-control"
                                    accept="image/*"
                                    onChange={this.onImageChange}
                                  />
                                </div> */}
                                {this.state.profileImage ?
                                  <div class="product-img">
                                    <img src={this.state.profileImage}
                                      class="profile-circle"
                                      alt="profile"
                                    />
                                  </div> : null}






                                  {/* <div class="form-label-group">
                                  <input
                                    type="text"
                                    // maxLength={9}

                                    id="inputName"
                                    class="form-control"
                                    placeholder="Address"
                                    name="address"

                                    value={this.state.address}
                                    onChange={this.handleChange}
                                  />
                                  <label for="inputName">Address</label>
                                  <p class="error-msg">{addressError}</p>
                                </div><div class="form-label-group">
                                  <input
                                    type="text"
                                    // maxLength={9}

                                    id="inputName"
                                    class="form-control"
                                    placeholder="Zipcode"
                                    name="zipcode"

                                    value={this.state.zipcode }
                                    onChange={this.handleChange}
                                  />
                                  <label for="inputName">Zipcode</label>
                                  <p class="error-msg">{zipcodeError}</p>
                                </div> */}





                                <div class="form-label-group">
                                  <fieldset class="form-group">
                                    <select
                                      class="form-control"
                                      id="basicSelect"
                                      placeholder="Select seller type "
                                      name="sellerType"
                                      value={this.state.sellerType}
                                      onChange={this.handleChange}
                                    >
                                      <option value="">Select Seller Type</option>
                                      <option value="Retailer">Retailer</option>
                                      <option value="Freelisting">
                                        Freelisting
                                      </option>
                                      
                                      </select>
                                    <p class="error-msg">{typeError}</p>
                                  </fieldset>
                                </div>

                                <div class="form-group row">
                                  <div class="col-12">
                                    <fieldset class="checkbox">
                                      <div class="vs-checkbox-con vs-checkbox-primary">
                                        <input type="checkbox" checked={this.state.term} name="term" onChange={this.handleTermChange} />
                                        <span class="vs-checkbox">
                                          <span class="vs-checkbox--check" >
                                            <i class="vs-icon feather icon-check"></i>
                                          </span>
                                        </span>
                                        <span class="">
                                          {" "}
                                          I accept the terms & conditions.
                                        </span>

                                      </div>
                                      <p class="error-msg">{termError}</p>

                                    </fieldset>

                                  </div>
                                </div>

                                <Link
                                  to="/login"
                                  class="btn btn-outline-primary float-left btn-inline mb-50 res-btn"
                                >
                                  Login
                                </Link>

                                <button
                                  type="submit"
                                  onClick={this.handleSubmit}
                                  disabled={submitDisable}
                                  class="btn btn-primary float-right btn-inline mb-50 login-btn"
                                >
                                  Register
                                </button>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </body>
    );
  }
}
export default Register;
