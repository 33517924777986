import React from "react";
import axios from "axios";
import Select from "react-select";
// import { Link } from "react-router-dom";
// import Sidebar from "../../Component/Sidebar/Sidebar";
// import Header from "../../Component/Header/Header";
import Footer from "../../Component/Footer/Footer";
import global from "../../global";
import "./style.css";
var formError = "";
//var submitDisable = false;

var isOpen = false;
var otherError = "";
var imageError = ""
var sendImage = ""
class TaxForms extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      taxForm: "",
      taxForms: [],
      otherForm: "",
      document: "",
      existingDoc: ""
    };
    imageError = ""
    sendImage = ""
    formError = "";
    otherError = "";
  }

  componentWillMount() {
    axios.get(global.baseUrl + "GetTaxDescription", {
      headers: { "Content-Type": "application/x-www-form-urlencoded" }
    })
      .then(result => {
        if (result.data.status) {
          this.setState({ taxDescription: result.data.data.description })

        }
      });

    if (localStorage.getItem("sellerdata")) {
      this.setState({ seller: JSON.parse(localStorage.getItem("sellerdata")) });
      let seller = JSON.parse(localStorage.getItem("sellerdata"));
      let urlencoded = new URLSearchParams();
      urlencoded.append("sellerId", seller.id);
      axios
        .post(global.baseUrl + "sellergettaxform", urlencoded, {
          headers: { "Content-Type": "application/x-www-form-urlencoded" }
        })
        .then(result => {
          console.log(result.data);
          if (result.data.status) {
            if (result.data.data.tax_form || result.data.data.tax_form === "") {
              this.setState({
                taxForm: result.data.data.tax_form,
                otherForm: result.data.data.other_form,
                document: result.data.data.form,
                existingDoc: result.data.data.existingDoc
              });
              let existingDoc = result.data.data.form.split("/")
              existingDoc = existingDoc[existingDoc.length - 1]
              this.setState({
                existingDoc: existingDoc
              })
              if (result.data.data.other_form !== "") {
                isOpen = true;
                this.setState({
                  taxForm: "other"
                });
              }
            }
          }
        });
      let urlencoded1 = new URLSearchParams();
      axios
        .post(global.baseUrl + "sellertaxformlist", urlencoded1, {
          headers: { "Content-Type": "application/x-www-form-urlencoded" }
        })
        .then(result => {
          console.log(result.data);
          if (result.data.status) {
            this.setState({
              taxForms: result.data.data
            });
          }
        });
    }
  }
  handleChange = event => {
    //    this.setState({ isOpen: !this.state.isOpen });
    isOpen = false;
    this.setState({ [event.target.name]: event.target.value, otherForm: "" });
  };
  handleOtherChange = event => {
    //    this.setState({ isOpen: !this.state.isOpen });
    isOpen = true;
    this.setState({ [event.target.name]: event.target.value });
  };
  addApiCall = () => {
    if (this.state.taxForm === "other") {
      var urlencoded = new URLSearchParams();
      urlencoded.append("sellerId", this.state.seller.id);
      urlencoded.append("tax_form", "");
      urlencoded.append("other_form", this.state.otherForm);
      urlencoded.append("form", this.state.document);

      axios
        .post(global.baseUrl + "sellerFourthTaxForm", urlencoded, {
          headers: { "Content-Type": "application/x-www-form-urlencoded" }
        })
        .then(result => {
          console.log(result.data);
          if (result.data.status) {
            this.setState({ taxForm: "other" });
            this.props.history.push("/information");
          }
        });
    } else {
      let urlencoded = new URLSearchParams();
      urlencoded.append("sellerId", this.state.seller.id);
      urlencoded.append("tax_form", this.state.taxForm);
      urlencoded.append("other_form", this.state.otherForm);
      urlencoded.append("form", this.state.document);

      axios
        .post(global.baseUrl + "sellerFourthTaxForm", urlencoded, {
          headers: { "Content-Type": "application/x-www-form-urlencoded" }
        })
        .then(result => {
          console.log(result.data);
          if (result.data.status) {
            this.setState({ taxForm: "other" });
            this.props.history.push("/information");
          }
        });
    }
  };
  handleSubmit = e => {
    e.preventDefault();
    if (this.state.taxForm === "other") {
      if (this.state.otherForm === "") {
        this.setState({ otherError: "Please enter tax form" });
        otherError = "Please enter tax form";
      } else {
        this.setState({ otherError: "" });
        otherError = "";
      }
    } else {
      this.setState({ otherError: "" });
      otherError = "";
      if (this.state.taxForm === "") {
        this.setState({ formError: "Please select form" });
        formError = "Please select form";
      } else {
        this.setState({ formError: "" });
        formError = "";
      }
    }
    if (this.state.document === "") {
      this.setState({ imageError: "Please select document" })
      imageError = "Please select document"
    }
    else {

      this.setState({ imageError: "" })
      imageError = ""
    }

    if (this.state.taxForm && formError === "" && otherError === "" && this.state.document !== "" && imageError === "") {
      console.log("working")
      this.addImage();
    }
  };

  resetHandler = () => {
    this.setState({
      question: "",
      answer: "",
      topic: ""
    });
  };
  backUrl = () => {
    this.props.history.push("/paymentmethod");
  };

  onImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      let reader = new FileReader();
      reader.onload = (e) => {
        this.setState({
          document: e.target.result,
          existingDoc: ""
        });
      };
      reader.readAsDataURL(event.target.files[0]);
      sendImage = event.target.files[0]
    } else {
      this.setState({
        document: '',
      })
      sendImage = ''
    }
  }
  addImage = () => {
    if (sendImage) {
      const apiUrl = global.baseUrl + "sellerImageUpload";
      const formData = new FormData();
      formData.append("myFile", sendImage);
      const config = {
        headers: {
          "content-type": "multipart/form-data"
        }
      };
      axios
        .post(apiUrl, formData, config)
        .then(response => {
          console.log("image add", response.data.filename);
          this.setState({ document: response.data.path });
          this.addApiCall();
        })
        .catch(error => { });
    } else {
      this.addApiCall();
    }
  }
  render() {
    return (
      <body
        class="vertical-layout vertical-menu-modern semi-dark-layout 2-columns  navbar-floating footer-static  "
        data-open="click"
        data-menu="vertical-menu-modern"
        data-col="2-columns"
        data-layout="semi-dark-layout"
      >
        {/* <Header />
        <Sidebar /> */}
        <div class="app-content content">
          <div class="content-overlay"></div>
          <div class="header-navbar-shadow"></div>
          <div class="content-wrapper">
            <div class="content-header row">
              <p className="info-detail"
                dangerouslySetInnerHTML={{
                  __html: this.state.taxDescription
                }}
              />
            </div>
            <div class="content-body">
              <section id="dashboard-analytics">
                <div className="row step-box">
                  <div>
                    <div className="round-color-number-box c-box1">
                      <p className="round-color-number-page c-sub-box1">1</p>
                    </div>
                    <div className="t-point">Seller & Business <br />Information</div>
                  </div>
                  <div>
                    <div className="round-color-number-box c-box2">
                      <p className="round-color-number-page">2</p>
                    </div>
                    <div className="t-point c-sub-box2" >Address</div>
                  </div>
                  <div>
                    <div className="round-color-number-box c-box3">
                      <p className="round-color-number-page">3</p>
                    </div>
                    <div class="p-text t-point">
                      Payment
                      <br /> Method
                    </div>
                  </div>
                  {this.state.seller.type === "Retailer" ? <>
                    <div>
                      <div className="round-color-number-box c-box4">
                        <p className="round-color-number-page">4</p>
                      </div>
                      <div class="p-text t-point">Tax Forms</div>
                    </div>
                  </> : null}

                  <div>
                    <div className="round-none-number-box">
                      <p className="round-number-page">{this.state.seller.type === "Retailer" ? 5 : 4}</p>
                    </div>
                    <div class="p-text t-point">Done</div>
                  </div>
                </div>

                <div class="card">
                  <div class="card-header" style={{ display: "initial" }}>
                    <div>
                      <h4 style={{ width: "100%" }}>Enter your information</h4>
                    </div>
                    <div>
                      <p>
                        Please enter your details as they appear on your bank
                        record. P.O.Box is not allowed.{" "}
                      </p>{" "}
                    </div>
                  </div>
                  <div class="card-body">
                    <div class="row">
                      <div class="col-xl-12  add-field">
                        In order to comply with the Internal Revenue Service Regulation.We are asking that you please complete
                        the enclosed form.Failure to provide this information may result in delayed payment or backup.
                      </div>
                      <div class="col-xl-10 col-md-3 mt-1 mb-1">
                        The form on this page provided in Adobe Acrobat Portable Document format.(.PDF).Please
                        save,print,Fill up and Submit the form
                      </div>
                      <div class="col-xl-12 col-md-12 mb-1">
                        Please select a tax form:
                      </div>
                      <div class="col-md-6">
                        <ul class="list-unstyled mb-0">
                          {this.state.taxForms.map((form, index) => (
                            <li class=" mr-2" className="tax-li">
                              <fieldset>
                                <div class="vs-radio-con">
                                  <input
                                    type="radio"
                                    name="taxForm"
                                    value={form.id}
                                    onChange={this.handleChange}
                                    checked={
                                      this.state.taxForm === form.id
                                        ? true
                                        : false
                                    }
                                  />
                                  <span class="vs-radio">
                                    <span class="vs-radio--border"></span>
                                    <span class="vs-radio--circle"></span>
                                  </span>
                                </div>
                              </fieldset>
                              <a href={form.form} target="_blank"  rel="noopener noreferrer" style={{ color: "#111", marginTop: "2px" }}>
                                {" "}
                                <span
                                  style={{
                                    fontWeight: "bold",
                                    marginRight: "5px"
                                  }}
                                >
                                  {form.type}
                                </span>{" "}
                              </a>
                            </li>
                          ))}
                          <li class=" mr-2">
                            <fieldset>
                              <div class="vs-radio-con">
                                <input
                                  type="radio"
                                  name="taxForm"
                                  value="other"
                                  onChange={this.handleOtherChange}
                                  checked={
                                    this.state.taxForm === "other"
                                      ? true
                                      : false
                                  }
                                />
                                <span class="vs-radio">
                                  <span class="vs-radio--border"></span>
                                  <span class="vs-radio--circle"></span>
                                </span>
                                <span
                                  style={{
                                    fontWeight: "bold",
                                    marginRight: "5px",
                                    color: "#111"
                                  }}
                                >
                                  Other
                                </span>{" "}
                              </div>
                            </fieldset>
                          </li>
                          {isOpen ? (
                            <>
                              <input
                                type="text"
                                class="form-control"
                                name="otherForm"
                                placeholder="Other form name"
                                value={this.state.otherForm}
                                onChange={this.handleOtherChange}
                              />
                              <p> {otherError}</p>
                            </>
                          ) : null}
                        </ul>
                        <p class="error-msg">{formError}</p>
                      </div>
                    </div>

                    {this.state.isOpen ? (
                      <div class="row">
                        <div class="col-xl-6 col-12">
                          <Select
                            theme={theme => ({
                              ...theme,
                              borderRadius: "5px",
                              border: "0px solid",
                              colors: {
                                ...theme.colors,
                                text: "orangered",
                                primary25: "#9cf59c",
                                primary: "#47A547"
                              }
                            })}
                            options={this.state.topics}
                            onChange={val => this.dropChangeHandler(val)}
                          />
                        </div>
                      </div>
                    ) : null}
                    <div class="row">
                      <div class="col-xl-2 col-md-3 add-field">
                        Submit Form<span class="required-span1">*</span>
                      </div>
                      <div class="col-md-6">
                        <div className="add-filed">File Upload</div>
                        <input
                          type="file"
                          class="form-control"
                          onChange={(e) => this.onImageChange(e)}
                        />
                        <p>{this.state.existingDoc}</p>
                        <p class="p-error">{imageError} </p>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-5 mt-1">
                        <button
                          type="reset"
                          class="btn btn-outline-primary float-left btn-inline res-btn"
                          onClick={this.backUrl}
                        >
                          Back
                        </button>
                      </div>
                      <div class="col-7 d-flex flex-sm-row justify-content-end mt-1">
                        <button
                          type="submit"
                          class="btn btn-primary glow mb-1 mb-sm-0 mr-0 mr-sm-1 login-btn"
                          onClick={this.addImage}
                        >
                          Finish Form
                        </button>
                      </div>
                    </div>
                  </div>

                </div>
              </section>
            </div>
          </div>
        </div>

        <div class="sidenav-overlay"></div>
        <div class="drag-target"></div>

        <Footer />
      </body>
    );
  }
}

export default TaxForms;
