import React from "react";
import axios from "axios";
//import Sidebar from "../../Component/Sidebar/Sidebar";
//import Header from "../../Component/Header/Header";
import Footer from "../../Component/Footer/Footer";
import global from "../../global";
import Avtar from "./avtar.jpg";
import "./EditProfile.css";
var seller = {};
var nameError = "";
var userNameError = "";
var emailError = "";
var mobileError = "";
var imageError = "";
var bioError = "";
var genderError = "";
var cmpNameError = "";
var yearError = "";
var contactError = "";
var imageError = "";
var sendImage = "";
var submitDisable = false;
var confirmPasswordError = "";
var newPasswordError = "";
var oldPasswordError = "";
var msg = "";
var zipCodeError="";
var passwordDisable = false;

class EditProfile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      userName: "",
      profileImage: "",
      email: "",
      mobileNumber: "",
      bio: "",
      gender: "",
      companyName: "",
      contactDetail: "",
      yearOfEstablish: "",
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
      StreetAddress:"",
      zipCode:""
    };
    msg = "";
    passwordDisable = false;
    confirmPasswordError = "";
    newPasswordError = "";
    oldPasswordError = "";
    nameError = "";
    userNameError = "";
    emailError = "";
    mobileError = "";
    imageError = "";
    bioError = "";
    genderError = "";
    cmpNameError = "";
    yearError = "";
    contactError = "";
    imageError = "";
    sendImage = "";
    zipCodeError="";
    submitDisable = false;
  }
  componentWillMount() {
    if (localStorage.getItem("sellerdata")) {
      seller = JSON.parse(localStorage.getItem("sellerdata"));
      this.setState({ seller: JSON.parse(localStorage.getItem("sellerdata")) });
      //this.dashboardAPI(seller.id);
      this.profileAPI();
    }

    // this.profileAPI();
  }
  profileAPI() {
    var urlencoded = new URLSearchParams();
    urlencoded.append("userId", seller.id);
    axios
      .post(global.baseUrl + "selleridlist", urlencoded, {
        headers: { "Content-Type": "application/x-www-form-urlencoded" }
      })
      .then(async result => {
        console.log(result.data);
        if (result.data.status) {
          this.setState({
            userId: result.data.data[0].id,
            name: result.data.data[0].name,
            email: result.data.data[0].email,
            mobileNumber: result.data.data[0].phone_number,
            profileImage: result.data.data[0].profile_image
          });
          if (result.data.data[0].company_name) {
           await this.setState({
              userName: result.data.data[0].user_name,
              bio: result.data.data[0].bio,
              gender: result.data.data[0].gender,
              companyName: result.data.data[0].company_name,
              yearOfEstablish: result.data.data[0].year_of_establish,
              StreetAddress:result.data.data[0].street_address,
              zipCode:result.data.data[0].zipCode,
              contactDetail: result.data.data[0].company_contact
            });
            this.forceUpdate()
          }
        }
      });
  }
  handleChange = event => {
    msg = "";
    this.setState({ [event.target.name]: event.target.value });
  };
  registerApiCall = () => {
    var urlencoded = new URLSearchParams();

    urlencoded.append("userId", this.state.userId);
    urlencoded.append("name", this.state.name);
    urlencoded.append("user_name", this.state.userName);
    urlencoded.append("email", this.state.email);
    urlencoded.append("phone_number", this.state.mobileNumber);
    urlencoded.append("bio", this.state.bio);
    urlencoded.append("gender", this.state.gender);
    urlencoded.append("company_name", this.state.companyName);
    urlencoded.append("street_address", this.state.StreetAddress);

    urlencoded.append("year_of_establish", this.state.yearOfEstablish);
    urlencoded.append("company_contact", this.state.contactDetail);
    urlencoded.append("profile_image", this.state.profileImage);
    urlencoded.append("zipCode", this.state.zipCode);

    axios
      .post(global.baseUrl+ "sellerupdateregister", urlencoded, {
        headers: { "Content-Type": "application/x-www-form-urlencoded" }
      })
      .then(result => {
        console.log(result.data);
        if (result.data.status) {
          seller.profile_image=result.data.data[0].profile_image;
          localStorage.setItem("sellerdata",JSON.stringify(seller));
          //localStorage.setItem('userdata', JSON.stringify(result.data))
          // window.location.reload()
          this.props.history.push("/dashboard");
          window.location.reload()
        }
      });
  };
  onImageChange = event => {
    if (event.target.files && event.target.files[0]) {
      var Extension = ["jpg", "jpeg", "png", "gif", "tiff"];
      var extArray = event.target.files[0].name.split(".");
      var ext = extArray[extArray.length - 1];
      var flag = 0;
      for (let i = 0; i < Extension.length; i++) {
        if (ext.toLowerCase() === Extension[i]) {
          flag = 1;
        }
      }
      if (flag) {
        this.setState({ imageError: "" });
        imageError = "";

        let reader = new FileReader();
        reader.onload = e => {
          this.setState({ profileImage: e.target.result });
        };
        reader.readAsDataURL(event.target.files[0]);
        sendImage = event.target.files[0];
        console.log("image", sendImage);
      } else {
        this.setState({
          profileImage: "",
          imageError: "Please select only image"
        });
        sendImage = "";
        imageError = "Please select only image";
      }
    }
  };
  handleSubmit = e => {
    //e.preventDefault();

    if (this.state.name === "") {
      this.setState({ nameError: "Please enter name" });
      nameError = "Please enter name";
    } else if (!this.state.name.match(/^[a-zA-Z-,]+(\s{0,1}[a-zA-Z-, ])*$/)) {
      this.setState({ nameError: "Please enter only character" });
      nameError = "Please enter only character";
    } else {
      this.setState({ nameError: "" });
      nameError = "";
    }
    if (this.state.userName === "") {
      this.setState({ userNameError: "Please enter user name" });
      userNameError = "Please enter user name";
    } else if (this.state.userName.trim() === "") {
      this.setState({ userNameError: "Please enter only character" });
      userNameError = "Please enter only character";
    } else {
      this.setState({ userNameError: "" });
      userNameError = "";
    }
    if (this.state.zipCode === "") {
      this.setState({ zipCodeError: "Please enter zipCode" });
      zipCodeError = "Please enter zipCode";
    } else if (this.state.zipCode.trim() === "") {
      this.setState({ zipCodeError: "Please enter valid zipCode" });
      zipCodeError = "Please enter valid zipCode";
    } else {
      this.setState({ zipCodeError: "" });
      zipCodeError = "";
    }
    if (this.state.email === "") {
      this.setState({ emailError: "Please enter email id" });
      emailError = "Please enter email id";
    } else if (
      /^\w+([.-]?\w+)*@\w+([-]?\w+)*(\.\w{2,3})+$/.test(this.state.email)
    ) {
      this.setState({ emailError: "" });
      emailError = "";
    } else {
      this.setState({ emailError: "Please enter valid email id" });
      emailError = "Please enter valid email id";
    }
    let numberCheck=/^[0-9]+$/
      
    if (this.state.mobileNumber === "") {
      this.setState({ mobileError: "Please enter mobile number" });
      mobileError = "Please enter mobile number";
    } else if (
      (!numberCheck.test(this.state.mobileNumber)) ||
      this.state.mobileNumber.length !== 10
    ) {
      this.setState({ mobileError: "Please enter valid number" });
      mobileError = "Please enter valid number";
    } else {
      this.setState({ mobileError: "" });
      mobileError = "";
    }
    if (this.state.bio === "") {
      this.setState({ bioError: "Please enter bio" });
      bioError = "Please enter bio";
    } else if (this.state.bio.trim() === "") {
      this.setState({ bioError: "Please enter valid bio" });
      bioError = "Please enter valid bio";
    } else {
      this.setState({ bioError: "" });
      bioError = "";
    }
    if (this.state.gender === "") {
      this.setState({ genderError: "Please select gender" });
      genderError = "Please select gender";
    } else {
      this.setState({ genderError: "" });
      genderError = "";
    }
    if (this.state.companyName === "") {
      this.setState({ cmpNameError: "Please enter company name" });
      cmpNameError = "Please enter company name";
    } else if (this.state.companyName.trim() === "") {
      this.setState({ cmpNameError: "Please enter valid company name" });
      cmpNameError = "Please enter valid name";
    } else {
      this.setState({ cmpNameError: "" });
      cmpNameError = "";
    }
    if (this.state.yearOfEstablish === "") {
      this.setState({ yearError: "Please enter year of establishment" });
      yearError = "Please enter year of establishment";
    } else if (
      isNaN(this.state.yearOfEstablish) ||
      this.state.yearOfEstablish < 1 ||
      this.state.yearOfEstablish.length > 5
    ) {
      this.setState({ yearError: "Please enter valid year of establishment" });
      yearError = "Please enter valid year of establishment";
    } else {
      this.setState({ yearError: "" });
      yearError = "";
    }
    if (this.state.contactDetail === "") {
      this.setState({ contactError: "Please enter contact detail" });
      contactError = "Please enter contact detail";
    } else if (
      (!numberCheck.test(this.state.contactDetail)) ||
      this.state.contactDetail.length !== 10
    ) {
      this.setState({ contactError: "Please enter valid contact detail" });
      contactError = "Please enter valid contact detail";
    } else {
      this.setState({ contactError: "" });
      contactError = "";
    }
    if (this.state.profileImage === "") {
      this.setState({ imageError: "Please select profile Image" });
      imageError = "Please select profile Image";
    } else {
      this.setState({ imageError: "" });
      imageError = "";
    }
    if (
      this.state.name &&
      this.state.userName &&
      this.state.mobileNumber &&
      this.state.email &&
      this.state.gender &&
      this.state.bio &&
      this.state.companyName &&
      this.state.yearOfEstablish &&
      this.state.contactDetail &&
      this.state.profileImage &&
      this.state.zipCode &&
      zipCodeError===""&&
      userNameError === "" &&
      emailError === "" &&
      nameError === "" &&
      genderError === "" &&
      bioError === "" &&
      mobileError === "" &&
      cmpNameError === "" &&
      yearError === "" &&
      contactError === "" &&
      imageError === ""
    ) {
      submitDisable = true;
      this.addImage();
    }
  };
  addImage = () => {
    if (sendImage) {
      const apiUrl = global.baseUrl + "sellerImageUpload";
      const formData = new FormData();
      formData.append("myFile", sendImage);
      const config = {
        headers: {
          "content-type": "multipart/form-data"
        }
      };
      axios
        .post(apiUrl, formData, config)
        .then(response => {
          console.log("image add", response.data.filename);
          this.setState({ profileImage: response.data.path });
          this.registerApiCall();
        })
        .catch(error => {});
    } else {
      this.registerApiCall();
    }
  };

  changePasswordApiCall = () => {
    var urlencoded = new URLSearchParams();
    urlencoded.append("id", this.state.seller.id);
    urlencoded.append("oldPassword", this.state.oldPassword);
    urlencoded.append("newPassword", this.state.newPassword);
    axios
      .post(global.baseUrl + "sellerchangepassword", urlencoded, {
        headers: { "Content-Type": "application/x-www-form-urlencoded" }
      })
      .then(result => {
        console.log("result are", result.data);
        if (result.data.status) {
          //this.props.history.push('/dashboard');
          passwordDisable = false;
          this.setState({ a: "" });
          this.passwordReset();
          msg = "Password has been changed successfully.";

          this.setState({ msg: "Password has been changed Successfully." });
          localStorage.setItem("seller", JSON.stringify(result.data));
        } else {
          oldPasswordError = "Please enter valid old password";
          passwordDisable = false;
          this.setState({ a: "" });

          this.setState({
            oldPasswordError: "Please enter valid old password"
          });
        }
      });
  };
  handlePasswordChange = () => {
    if (this.state.oldPassword === "") {
      this.setState({ oldPasswordError: "Please enter old password" });
      oldPasswordError = "Please enter old password";
    }
    // else if(this.state.oldPassword.match(/\s/g)){
    //   this.setState({o:'Enter valid new password'})
    //   opasserror='Please enter valid new password'
    // }
    else {
      this.setState({ oldPasswordError: "" });
      oldPasswordError = "";
    }
    if (this.state.newPassword === "") {
      this.setState({ newPasswordError: "Please enter new password" });
      newPasswordError = "Please enter new password";
    } else if (this.state.newPassword.match(/\s/g)) {
      this.setState({ newPasswordError: "Enter valid new password" });
      newPasswordError = "Please enter valid new password";
    } else {
      this.setState({ newPasswordError: "" });
      newPasswordError = "";
    }
    if (this.state.confirmPassword === "") {
      this.setState({ confirmPasswordError: "Please Enter confirm password" });
      confirmPasswordError = "Please enter confirm password";
    } else if (this.state.confirmPassword.match(/\s/g)) {
      this.setState({ confirmPasswordError: "Enter  valid confirm password" });
      confirmPasswordError = "Please enter valid confirm password";
    } else {
      if (this.state.newPassword !== this.state.confirmPassword) {
        this.setState({
          newPasswordError: "New password & confirm password are different",
          confirmPasswordError: "New password & confirm password are different"
        });
        //  npasserror= 'Password & confirm password are different'
        confirmPasswordError = "New password & confirm password are different";
      } else {
        this.setState({
          confirmPasswordError: ""
        });
        confirmPasswordError = "";
        if (
          this.state.oldPassword &&
          this.state.newPassword &&
          this.state.confirmPassword &&
          newPasswordError === "" &&
          oldPasswordError === "" &&
          confirmPasswordError === ""
        ) {
          this.changePasswordApiCall();
          passwordDisable = true;
        }
      }
    }
  };
  passwordReset = () => {
    this.setState({
      oldPassword: "",
      newPassword: "",
      confirmPassword: ""
    });
    oldPasswordError = "";
    confirmPasswordError = "";
    newPasswordError = "";
    msg = "";
  };
  profileReset = () => {
    this.setState({
      name: "",
      userName: "",
      profileImage: "",
      email: "",
      mobileNumber: "",
      bio: "",
      gender: "",
      companyName: "",
      contactDetail: "",
      yearOfEstablish: ""
    });
    msg = "";
    nameError = "";
    userNameError = "";
    emailError = "";
    mobileError = "";
    imageError = "";
    bioError = "";
    genderError = "";
    cmpNameError = "";
    yearError = "";
    contactError = "";
    imageError = "";
    sendImage = "";
    submitDisable = false;
    this.profileAPI();
  };
  render() {
    return (
      <body
        class="vertical-layout vertical-menu-modern semi-dark-layout 2-columns  navbar-floating footer-static  "
        data-open="click"
        data-menu="vertical-menu-modern"
        data-col="2-columns"
        data-layout="semi-dark-layout"
      >
        {/* <Header />
        <Sidebar /> */}
        <div class="app-content content">
          <div class="content-overlay"></div>
          <div class="header-navbar-shadow"></div>
          <div class="content-wrapper">
            <div class="content-header row"></div>
            <div class="content-body">
              <section class="users-edit">
                <div class="card">
                  <div class="card-content">
                    <div class="card-body">
                      <ul class="nav nav-tabs mb-3" role="tablist">
                        <li class="nav-item">
                          <a
                            class="nav-link d-flex align-items-center active"
                            id="account-tab"
                            data-toggle="tab"
                            href="#account"
                            aria-controls="account"
                            role="tab"
                            aria-selected="true"
                            onClick={this.passwordReset}
                          >
                            <i class="feather icon-user mr-25"></i>
                            <span class="d-none d-sm-block">Profile</span>
                          </a>
                        </li>
                        <li class="nav-item">
                          <a
                            class="nav-link d-flex align-items-center"
                            id="information-tab"
                            data-toggle="tab"
                            href="#information"
                            aria-controls="information"
                            role="tab"
                            aria-selected="false"
                            onClick={this.passwordReset}
                          >
                            <i class="feather icon-lock mr-25"></i>
                            <span class="d-none d-sm-block">Security</span>
                          </a>
                        </li>
                        {/* <li class="nav-item">
                          <a
                            class="nav-link d-flex align-items-center"
                            id="social-tab"
                            data-toggle="tab"
                            href="#social"
                            aria-controls="social"
                            role="tab"
                            aria-selected="false"
                          >
                            <i class="feather icon-share-2 mr-25"></i>
                            <span class="d-none d-sm-block">Social</span>
                          </a>
                        </li> */}
                      </ul>
                      <div class="tab-content">
                        <div
                          class="tab-pane active"
                          id="account"
                          aria-labelledby="account-tab"
                          role="tabpanel"
                        >
                          <div class="media mb-2">
                            <a class="mr-2 my-25" >
                              {this.state.profileImage ? (
                                <img
                                  src={this.state.profileImage}
                                  alt="users avatar"
                                  class="users-avatar-shadow rounded"
                                  height="90"
                                  width="90"
                                />
                              ) : (
                                <img
                                  src={Avtar}
                                  alt="users avatar"
                                  class="users-avatar-shadow rounded"
                                  height="90"
                                  width="90"
                                />
                              )}
                            </a>
                            <div class="media-body mt-50">
                              <h4 class="media-heading">{this.state.name}</h4>
                              <div class="col-12 d-flex mt-1 px-0">
                                {/* <button class="btn btn-primary d-none d-sm-block mr-75">
                                                    <input type="file" class="form-control" placeholder="Name" value={this.state.name} name="name" onChange={this.handleChange} hidden/>
                                                       
                                                    Change</button> */}
                                <div
                                  class="product-upload-btn pt-2"
                                  // class="product-upload-button"
                                  
                                >
                                  <label
                                    class="btn btn-sm btn-primary-green ml-50 mb-50 mb-sm-0 cursor-pointer add-profile-btn"
                                    for="account-upload"
                                  >
                                    Change Profile
                                  </label>
                                  <input
                                    type="file"
                                    id="account-upload"
                                    onChange={this.onImageChange}
                                    hidden
                                  />
                                </div>
                              </div>

                              <p class="error-msg">{imageError}</p>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-12 col-sm-6">
                              <div class="form-group form-dnone">
                                <div class="controls">
                                  <label>Name</label>
                                  <input
                                    type="text"
                                    class="form-control"
                                    placeholder="Name"
                                    value={this.state.name}
                                    name="name"
                                    onChange={this.handleChange}
                                    maxLength={50}
                                  />
                                  <p class="error-msg">{nameError} </p>
                                </div>
                              </div>
                              <div class="form-group form-dnone">
                                <div class="controls">
                                  <label>User Name</label>
                                  <input
                                    type="text"
                                    class="form-control"
                                    placeholder="User Name"
                                    value={this.state.userName}
                                    onChange={this.handleChange}
                                    name="userName"
                                    maxLength={50}
                                  />
                                  <p class="error-msg">{userNameError} </p>
                                </div>
                              </div>
                              <div class="form-group form-dnone">
                                <div class="controls">
                                  <label>Bio</label>
                                  <textarea
                                    type="text"
                                    class="form-control"
                                    placeholder="Bio"
                                    value={this.state.bio}
                                    name="bio"
                                    onChange={this.handleChange}
                                  />
                                  <p class="error-msg">{bioError} </p>
                                </div>
                              </div>
                            </div>
                            <div class="col-12 col-sm-6">
                              <div class="form-group form-dnone">
                                <label>Mobile Number</label>
                                <input
                                  type="text"
                                  class="form-control"
                                  placeholder="Mobile number"
                                  name="mobileNumber"
                                  value={this.state.mobileNumber}
                                  onChange={this.handleChange}
                                  maxLength="11"
                                  minLength="8"
                                />
                                <p class="error-msg">{mobileError} </p>
                              </div>
                              <div class="form-group form-dnone">
                                <label>Email Id</label>
                                <input
                                  type="text"
                                  class="form-control"
                                  placeholder="Email"
                                  value={this.state.email}
                                  name="email"
                                />
                                <p class="error-msg">{emailError} </p>
                              </div>
                              <div class="form-group form-dnone">
                                <label>Gender</label>
                                <ul class="list-unstyled mb-0">
                                  <li class="d-inline-block mr-2">
                                    <fieldset>
                                      <div class="vs-radio-con">
                                        <input
                                          type="radio"
                                          name="gender"
                                          value="male"
                                          onChange={this.handleChange}
                                          checked={
                                            this.state.gender === "male"
                                              ? true
                                              : false
                                          }
                                        />
                                        <span class="vs-radio">
                                          <span class="vs-radio--border"></span>
                                          <span class="vs-radio--circle"></span>
                                        </span>
                                        Male
                                      </div>
                                    </fieldset>
                                  </li>
                                  <li class="d-inline-block mr-2">
                                    <fieldset>
                                      <div class="vs-radio-con">
                                        <input
                                          type="radio"
                                          name="gender"
                                          value="female"
                                          onChange={this.handleChange}
                                          checked={
                                            this.state.gender === "female"
                                              ? true
                                              : false
                                          }
                                        />
                                        <span class="vs-radio">
                                          <span class="vs-radio--border"></span>
                                          <span class="vs-radio--circle"></span>
                                        </span>
                                        Female
                                      </div>
                                    </fieldset>
                                  </li>
                                </ul>
                                <p class="error-msg">{genderError} </p>
                              </div>
                            </div>

                            <div class="col-12 col-sm-6">
                              <div class="form-group form-dnone">
                                <label>Company Name</label>
                                <input
                                  type="text"
                                  class="form-control"
                                  placeholder="Company name"
                                  name="companyName"
                                  value={this.state.companyName}
                                  onChange={this.handleChange}
                                />
                                <p class="error-msg">{cmpNameError} </p>
                              </div>
                              <div class="form-group form-dnone">
                                <label>Year Of Establishment</label>
                                <input
                                  type="text"
                                  class="form-control"
                                  placeholder="Year Of Establishment"
                                  value={this.state.yearOfEstablish}
                                  name="yearOfEstablish"
                                  onChange={this.handleChange}
                                />
                                <p class="error-msg">{yearError} </p>
                                
                              </div>
                              <div class="form-group form-dnone">
                                <label>Street Address</label>
                                <input
                                  type="text"
                                  class="form-control"
                                  placeholder="Street Address"
                                  value={this.state.StreetAddress}
                                  name="StreetAddress"
                                  onChange={this.handleChange}
                                />
                                <p class="error-msg">{''} </p>
                              </div>
                            </div>
                            <div class="col-12 col-sm-6">
                              <div class="form-group form-dnone">
                                <label>Contact Detail</label>
                                <input
                                  type="text"
                                  class="form-control"
                                  placeholder="Contact Detail"
                                  name="contactDetail"
                                  value={this.state.contactDetail}
                                  onChange={this.handleChange}
                                  maxLength="10"
                                />
                                <p class="error-msg">{contactError} </p>
                              </div>
                            {/* </div> */}
                            {/* <div class="col-12 col-sm-6"> */}
                              <div class="form-group form-dnone">
                                <label>ZipCode</label>
                                <input
                                  type="text"
                                  class="form-control"
                                  placeholder="ZipCode"
                                  name="zipCode"
                                  value={this.state.zipCode}
                                  onChange={this.handleChange}
                                  maxLength="10"
                                />
                                <p class="error-msg">{zipCodeError} </p>
                              </div>
                            </div>

                            <div class="col-12 d-flex flex-sm-row flex-column justify-content-end mt-1">
                              <button
                                type="submit"
                                class="btn btn-primary glow mb-1 mb-sm-0 mr-0 mr-sm-1 login-btn"
                                onClick={this.handleSubmit}
                              >
                                Save Changes
                              </button>
                              <button
                                type="reset"
                                class="btn btn-outline-primary float-left btn-inline res-btn"
                                onClick={this.profileReset}
                              >
                                Reset
                              </button>
                            </div>
                          </div>
                        </div>
                        <div
                          class="tab-pane"
                          id="information"
                          aria-labelledby="information-tab"
                          role="tabpanel"
                        >
                          <h4 class="media-heading">Change Password</h4>

                          <div class="row">
                            <div class="col-12 col-sm-6">
                              <div class="form-group form-dnone">
                                <div class="controls">
                                  <label>Old Password</label>
                                  <input
                                    type="password"
                                    class="form-control"
                                    placeholder="Old password"
                                    value={this.state.oldPassword}
                                    name="oldPassword"
                                    onChange={this.handleChange}
                                  />
                                  <p class="error-msg">{oldPasswordError} </p>
                                </div>
                              </div>
                              <div class="form-group form-dnone">
                                <div class="controls">
                                  <label>New Password</label>
                                  <input
                                    type="password"
                                    class="form-control"
                                    placeholder="New password"
                                    value={this.state.newPassword}
                                    onChange={this.handleChange}
                                    name="newPassword"
                                  />
                                  <p class="error-msg">{newPasswordError} </p>
                                </div>
                              </div>
                              <div class="form-group form-dnone">
                                <div class="controls">
                                  <label>Confirm Password</label>
                                  <input
                                    type="password"
                                    class="form-control"
                                    placeholder="Confirm Password"
                                    value={this.state.confirmPassword}
                                    onChange={this.handleChange}
                                    name="confirmPassword"
                                  />
                                  <p class="error-msg">
                                    {confirmPasswordError}{" "}
                                  </p>

                                  <p
                                    class="error-msg"
                                    style={{ color: "green" }}
                                  >
                                    {msg}{" "}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-lg-6 col-md-6 col-12 d-flex flex-sm-row flex-column justify-content-end mt-1">
                            <button
                              type="submit"
                              class="btn btn-primary glow mb-1 mb-sm-0 mr-0 mr-sm-1 login-btn"
                              onClick={this.handlePasswordChange}
                              disabled={passwordDisable}
                            >
                              Save Changes
                            </button>
                            <button
                              type="reset"
                              class="btn btn-outline-primary float-left btn-inline res-btn"
                              onClick={this.passwordReset}
                            >
                              Reset
                            </button>
                          </div>
                          {/* <form novalidate>
                            <div class="row mt-1">
                              <div class="col-12 col-sm-6">
                                <h5 class="mb-1">
                                  <i class="feather icon-user mr-25"></i>
                                  Personal Information
                                </h5>
                                <div class="row">
                                  <div class="col-12">
                                    <div class="form-group">
                                      <div class="controls">
                                        <label>Birth date</label>
                                        <input
                                          type="text"
                                          class="form-control birthdate-picker"
                                          required
                                          placeholder="Birth date"
                                          data-validation-required-message="This birthdate field is required"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div class="form-group">
                                  <div class="controls">
                                    <label>Mobile</label>
                                    <input
                                      type="text"
                                      class="form-control"
                                      value="&#43;6595895857"
                                      placeholder="Mobile number here..."
                                      data-validation-required-message="This mobile number is required"
                                    />
                                  </div>
                                </div>

                                <div class="form-group">
                                  <div class="controls">
                                    <label>Website</label>
                                    <input
                                      type="text"
                                      class="form-control"
                                      required
                                      placeholder="Website here..."
                                      value="https://rowboat.com/insititious/Angelo"
                                      data-validation-required-message="This Website field is required"
                                    />
                                  </div>
                                </div>
                                <div class="form-group">
                                  <label>Languages</label>
                                  <select
                                    class="form-control"
                                    id="users-language-select2"
                                    multiple="multiple"
                                  >
                                    <option value="English" selected>
                                      English
                                    </option>
                                    <option value="Spanish">Spanish</option>
                                    <option value="French">French</option>
                                    <option value="Russian">Russian</option>
                                    <option value="German">German</option>
                                    <option value="Arabic" selected>
                                      Arabic
                                    </option>
                                    <option value="Sanskrit">Sanskrit</option>
                                  </select>
                                </div>
                                <div class="form-group">
                                  <label>Gender</label>
                                  <ul class="list-unstyled mb-0">
                                    <li class="d-inline-block mr-2">
                                      <fieldset>
                                        <div class="vs-radio-con">
                                          <input
                                            type="radio"
                                            name="vueradio"
                                            checked
                                            value="false"
                                          />
                                          <span class="vs-radio">
                                            <span class="vs-radio--border"></span>
                                            <span class="vs-radio--circle"></span>
                                          </span>
                                          Male
                                        </div>
                                      </fieldset>
                                    </li>
                                    <li class="d-inline-block mr-2">
                                      <fieldset>
                                        <div class="vs-radio-con">
                                          <input
                                            type="radio"
                                            name="vueradio"
                                            value="false"
                                          />
                                          <span class="vs-radio">
                                            <span class="vs-radio--border"></span>
                                            <span class="vs-radio--circle"></span>
                                          </span>
                                          Female
                                        </div>
                                      </fieldset>
                                    </li>
                                    <li class="d-inline-block mr-2">
                                      <fieldset>
                                        <div class="vs-radio-con">
                                          <input
                                            type="radio"
                                            name="vueradio"
                                            value="false"
                                          />
                                          <span class="vs-radio">
                                            <span class="vs-radio--border"></span>
                                            <span class="vs-radio--circle"></span>
                                          </span>
                                          Other
                                        </div>
                                      </fieldset>
                                    </li>
                                  </ul>
                                </div>
                                <div class="form-group">
                                  <label>Contact Options</label>
                                  <ul class="list-unstyled mb-0">
                                    <li class="d-inline-block mr-2">
                                      <fieldset>
                                        <div class="custom-control custom-checkbox">
                                          <input
                                            type="checkbox"
                                            class="custom-control-input"
                                            checked
                                            name="customCheck1"
                                            id="customCheck1"
                                          />
                                          <label
                                            class="custom-control-label"
                                            for="customCheck1"
                                          >
                                            Email
                                          </label>
                                        </div>
                                      </fieldset>
                                    </li>
                                    <li class="d-inline-block mr-2">
                                      <fieldset>
                                        <div class="custom-control custom-checkbox">
                                          <input
                                            type="checkbox"
                                            class="custom-control-input"
                                            checked
                                            name="customCheck2"
                                            id="customCheck2"
                                          />
                                          <label
                                            class="custom-control-label"
                                            for="customCheck2"
                                          >
                                            Message
                                          </label>
                                        </div>
                                      </fieldset>
                                    </li>
                                    <li class="d-inline-block mr-2">
                                      <fieldset>
                                        <div class="custom-control custom-checkbox">
                                          <input
                                            type="checkbox"
                                            class="custom-control-input"
                                            name="customCheck3"
                                            id="customCheck3"
                                          />
                                          <label
                                            class="custom-control-label"
                                            for="customCheck3"
                                          >
                                            Phone
                                          </label>
                                        </div>
                                      </fieldset>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                              <div class="col-12 col-sm-6">
                                <h5 class="mb-1 mt-2 mt-sm-0">
                                  <i class="feather icon-map-pin mr-25"></i>
                                  Address
                                </h5>
                                <div class="form-group">
                                  <div class="controls">
                                    <label>Address Line 1</label>
                                    <input
                                      type="text"
                                      class="form-control"
                                      value="A-65, Belvedere Streets"
                                      required
                                      placeholder="Address Line 1"
                                      data-validation-required-message="This Address field is required"
                                    />
                                  </div>
                                </div>
                                <div class="form-group">
                                  <div class="controls">
                                    <label>Address Line 2</label>
                                    <input
                                      type="text"
                                      class="form-control"
                                      required
                                      placeholder="Address Line 2"
                                      data-validation-required-message="This Address field is required"
                                    />
                                  </div>
                                </div>
                                <div class="form-group">
                                  <div class="controls">
                                    <label>Postcode</label>
                                    <input
                                      type="text"
                                      class="form-control"
                                      required
                                      placeholder="postcode"
                                      value="1868"
                                      data-validation-required-message="This Postcode field is required"
                                    />
                                  </div>
                                </div>
                                <div class="form-group">
                                  <div class="controls">
                                    <label>City</label>
                                    <input
                                      type="text"
                                      class="form-control"
                                      required
                                      value="New York"
                                      data-validation-required-message="This Time Zone field is required"
                                    />
                                  </div>
                                </div>
                                <div class="form-group">
                                  <div class="controls">
                                    <label>State</label>
                                    <input
                                      type="text"
                                      class="form-control"
                                      required
                                      value="New York"
                                      data-validation-required-message="This Time Zone field is required"
                                    />
                                  </div>
                                </div>
                                <div class="form-group">
                                  <div class="controls">
                                    <label>Country</label>
                                    <input
                                      type="text"
                                      class="form-control"
                                      required
                                      value="United Kingdom"
                                      data-validation-required-message="This Time Zone field is required"
                                    />
                                  </div>
                                </div>
                              </div>
                              <div class="col-12 d-flex flex-sm-row flex-column justify-content-end mt-1">
                                <button
                                  type="submit"
                                  class="btn btn-primary glow mb-1 mb-sm-0 mr-0 mr-sm-1"
                                >
                                  Save Changes
                                </button>
                                <button
                                  type="reset"
                                  class="btn btn-outline-warning"
                                >
                                  Reset
                                </button>
                              </div>
                            </div>
                          </form> */}
                        </div>
                        {/* <div
                          class="tab-pane"
                          id="social"
                          aria-labelledby="social-tab"
                          role="tabpanel"
                        >
                          <form novalidate>
                            <div class="row">
                              <div class="col-12 col-sm-6">
                                <fieldset>
                                  <label>Twitter</label>
                                  <div class="input-group mb-75">
                                    <div class="input-group-prepend">
                                      <span
                                        class="input-group-text feather icon-twitter"
                                        id="basic-addon3"
                                      ></span>
                                    </div>
                                    <input
                                      type="text"
                                      class="form-control"
                                      value="https://www.twitter.com/adoptionism744"
                                      placeholder="https://www.twitter.com/"
                                      aria-describedby="basic-addon3"
                                    />
                                  </div>

                                  <label>Facebook</label>
                                  <div class="input-group mb-75">
                                    <div class="input-group-prepend">
                                      <span
                                        class="input-group-text feather icon-facebook"
                                        id="basic-addon4"
                                      ></span>
                                    </div>
                                    <input
                                      type="text"
                                      class="form-control"
                                      value="https://www.facebook.com/adoptionism664"
                                      placeholder="https://www.facebook.com/"
                                      aria-describedby="basic-addon4"
                                    />
                                  </div>
                                  <label>Instagram</label>
                                  <div class="input-group mb-75">
                                    <div class="input-group-prepend">
                                      <span
                                        class="input-group-text feather icon-instagram"
                                        id="basic-addon5"
                                      ></span>
                                    </div>
                                    <input
                                      type="text"
                                      class="form-control"
                                      value="https://www.instagram.com/adopt-ionism744"
                                      placeholder="https://www.instagram.com/"
                                      aria-describedby="basic-addon5"
                                    />
                                  </div>
                                </fieldset>
                              </div>
                              <div class="col-12 col-sm-6">
                                <label>Github</label>
                                <div class="input-group mb-75">
                                  <div class="input-group-prepend">
                                    <span
                                      class="input-group-text feather icon-github"
                                      id="basic-addon9"
                                    ></span>
                                  </div>
                                  <input
                                    type="text"
                                    class="form-control"
                                    value="https://www.github.com/madop818"
                                    placeholder="https://www.github.com/"
                                    aria-describedby="basic-addon9"
                                  />
                                </div>
                                <label>Codepen</label>
                                <div class="input-group mb-75">
                                  <div class="input-group-prepend">
                                    <span
                                      class="input-group-text feather icon-codepen"
                                      id="basic-addon12"
                                    ></span>
                                  </div>
                                  <input
                                    type="text"
                                    class="form-control"
                                    value="https://www.codepen.com/adoptism243"
                                    placeholder="https://www.codepen.com/"
                                    aria-describedby="basic-addon12"
                                  />
                                </div>
                                <label>Slack</label>
                                <div class="input-group mb-75">
                                  <div class="input-group-prepend">
                                    <span
                                      class="input-group-text feather icon-slack"
                                      id="basic-addon11"
                                    ></span>
                                  </div>
                                  <input
                                    type="text"
                                    class="form-control"
                                    value="@adoptionism744"
                                    placeholder="https://www.slack.com/"
                                    aria-describedby="basic-addon11"
                                  />
                                </div>
                              </div>
                              <div class="col-12 d-flex flex-sm-row flex-column justify-content-end mt-1">
                                <button
                                  type="submit"
                                  class="btn btn-primary glow mb-1 mb-sm-0 mr-0 mr-sm-1"
                                >
                                  Save Changes
                                </button>
                                <button
                                  type="reset"
                                  class="btn btn-outline-warning"
                                >
                                  Reset
                                </button>
                              </div>
                            </div>
                          </form>
                        </div>
                      */}
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>

        <div class="sidenav-overlay"></div>
        <div class="drag-target"></div>
        <Footer />
      </body>
    );
  }
}
export default EditProfile;
