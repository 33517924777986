import React from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
  Button,
} from 'reactstrap';
import Footer from '../../../Component/Footer/Footer';
import global from '../../../global';
import { toast } from 'react-toastify';
import Paypal from './PaypalBtn';
import StripeCheckout from 'react-stripe-checkout';
import ReactPaginate from 'react-paginate';
import ReactToPrint from 'react-to-print';
import ReactToPdf from 'react-to-pdf';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { Spinner } from "reactstrap"
import './Product.css';
import 'react-toastify/dist/ReactToastify.css';
// import confirm from "reactstrap-confirm";

const targetRef = React.createRef();
const CURRENCY = 'USD';
const fromDollarToCent = (amount) => parseInt(amount * 100);
const $ = require('jquery');
$.DataTable = require('datatables.net');
var seller = {};
var charge = {};
var modal = false;
var modal2 = false;
var modal3 = false;
var isActive = false;
var allProductCheck = false;
let mainProduct = [];
toast.configure();
const fileType =
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const fileExtension = '.xlsx';

class ComponentToPrint extends React.Component {
  render() {
    return (
      <Table responsive hover className="display border-product-list-table">
        <thead>
          <tr>
            <th scope="col">Product Image</th>
            <th scope="col">Product Name</th>
            <th scope="col">SKU</th>
            <th scope="col">Stock</th>
            <th scope="col">Price</th>
          </tr>
        </thead>
        <tbody>
          {mainProduct.map((product, index) => {
            console.log(product, "kk12")

          return(
            <>
            <tr key={product.id.toString()}>
              <td>
                <img
                  src={product.image[0]}
                  alt=".."
                  className="product-first-image"
                ></img>
              </td>
              <td className="prod-ellip">{product.product_name}</td>
              <td>{product.sku}</td>
              <td>{product.quantity}</td>
              <td className="price-nowrap">
                $ {product.price ? product.price : product.color_size[0].price}
              </td>
            </tr>
            </>
          )})}
        </tbody>
      </Table>
    );
  }
}
const CustomToast = () => {
  return (
    <div>
      <i class="fa fa-check success-toast"></i>
      Product Created Successfully.
    </div>
  );
};

const WarnToast = () => {
  return (
    <div style={{ display: 'flex' }}>
      <i class="fa fa-info info-toast"></i>
      You have insufficient amount in wallet.
    </div>
  );
};
const ProductWarnToast = () => {
  return (
    <div>
      <i class="fa fa-info info-toast"></i>
      Please select product.
    </div>
  );
};
const ActionWarnToast = () => {
  return (
    <div>
      <i class="fa fa-info info-toast"></i>
      Please select Action.
    </div>
  );
};
const AddProductWarnToast = () => {
  return (
    <div style={{ display: 'flex' }}>
      <i class="fa fa-info info-toast"></i>
      You have exceed the limit to add please switch to retailer.
    </div>
  );
};
//var Loader = require("react-loader");
class AddProduct extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      wallerPaymentId: '',
      showLogOut: false,
      showLogOut2: false,
      showLogOut3: false,
      showLogOut4: false,
      status: '',
      walletAmount: 0,
      loadingprint:false,
      lodingconfirm:false,
      productList: [],
      paypalPaymentId: '',
      transactionId: '',
      paymentType: '',
      loaded: true,
      seller: '',
      id1: 0,
      r: false,
      index1: -1,
      messageto: '',
      products: [],
      datatable: {
        columns: [],
        rows: [],
      },
      actionTask: '',
      gridData: [],
      listView: '1',
      productInfo: '',
      productCharge: '',
      imageCharge: '',
      productId: '',
      productImageCharge: 0,
      finalTotal: 0,
      offset: 1,
      perPage: 10,
      currentPage: 1,
      data: [],
      listdata: [],
      totalCount: 0,
      searchProduct: '',
      allProductCheck: false,
      allProduct: [],
      freeListingCount: 0,
      pageCount: 0,
      c_id:'',
      c_index:0,
      copyno:1,
      modal3:false,
    };
    seller = {};
    isActive = false;
    modal = false;
    modal2 = false;
    modal3 = false;
    allProductCheck = false;
  }
  componentWillMount() {
    if (localStorage.getItem('sellerdata')) {
      this.setState({ seller: JSON.parse(localStorage.getItem('sellerdata')) });
      seller = JSON.parse(localStorage.getItem('sellerdata'));
      this.getWalletAPI();
      let urlencoded = new URLSearchParams();
      axios
        .post(global.baseUrl + 'SellerProductCharge', urlencoded, {
          headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        })
        .then((result) => {
          // console.log(result.data, "charge amount");
          if (result.data.status) {
            this.setState({
              productCharge: parseFloat(result.data.data.product_charge),
              imageCharge: parseFloat(result.data.data.image_charge),
            });
          }
        });
      this.sellertypeList();
    }
    this.listAPI();

    let urlencoded1 = new URLSearchParams();
    urlencoded1.append('sellerId', seller.id);
    axios
      .post(global.baseUrl + 'SellerAllProductList', urlencoded1, {
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      })
      .then((result) => {
        if (result.data.status) {
          let allProduct = [];
          let tempdata = [];
          result.data.data.map((temp) => {
            console.log(temp, 'tempproduct123');
            let tempcolors = [];
            temp.color_size.map((temp1) => {
              tempcolors.push(JSON.parse(temp1));
            });
            temp.color_size = tempcolors;
            tempdata.push(temp);
          });
          // console.log(tempdata,"23")
          // for (let i = 0; i < result.data.data.length; i++) {
          //   //allProduct[i].id=result.data.data[i].id
          //   allProduct.push({
          //     id: result.data.data[i].id,
          //     product_name: result.data.data[i].product_name,
          //     sku: result.data.data[i].sku,
          //     price: result.data.data[i].price,
          //     quantity: result.data.data[i].quantity,
          //     msrp: result.data.data[i].msrp,
          //     color_size: tempdata[i].color_size[0]
          //   })
          // }
          // console.log(allProduct, "product")
          // this.setState({
          //   totalCount: result.data.data.length,
          //   pageCount: Math.ceil(result.data.data.length / this.state.perPage),
          // });
          this.setState({ allProduct: tempdata });
        }
      });
  }
  getWalletAPI = () => {
    let urlencoded = new URLSearchParams();
    urlencoded.append('sellerId', seller.id);
    axios
      .post(global.baseUrl + 'SellerGetWalletAmount', urlencoded, {
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      })
      .then((result) => {
        console.log(result.data, 'amount');
        if (result.data.status) {
          if (result.data.amount > 0) {
            this.setState({ walletAmount: parseFloat(result.data.amount).toFixed(2) });
          }
        }
      });
  };
  sellertypeList() {
    if ((seller.type = 'FreeListing')) {
      var urlencoded = new URLSearchParams();
      urlencoded.append('sellerId', seller.id);

      axios
        .post(global.baseUrl + 'sellerTypeList', urlencoded, {
          headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        })
        .then((result) => {
          console.log('Working', result.data);
          this.setState({ freeListingCount: result.data.data });
        });
    }
  }

  listAPI() {
    mainProduct = [];
    var urlencoded = new URLSearchParams();
    urlencoded.append('userId', seller.id);
    urlencoded.append('page', this.state.currentPage);
    urlencoded.append('searchProduct', this.state.searchProduct);
    urlencoded.append('sortType', this.state.sortType);

    axios
      .post(global.baseUrl + 'sellerproductuserlist', urlencoded, {
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      })
      .then((result) => {
        // console.log("Working", result.data)

        if (result.data.data) {
          let tempdata = [];
          result.data.data.map((temp) => {
            // console.log(temp, "tempproduct123")
            let tempcolors = [];
            temp.color_size.map((temp1) => {
              tempcolors.push(JSON.parse(temp1));
            });
            temp.color_size = tempcolors;
            tempdata.push(temp);
          });
          // console.log(tempdata,"color")
          if (this.state.sortType === 'priceHighToLow') {
            result.data.data.sort((a, b) => {
              return +parseInt(b.final_price) - +parseInt(a.final_price);
            });
          }
          if (this.state.sortType === 'priceLowToHigh') {
            result.data.data.sort((a, b) => {
              return +parseInt(a.final_price) - +parseInt(b.final_price);
            });
          }
          this.setState({
            products: result.data.data,
            postData: [],
            gridData: [],
          });
          this.setState({
            totalCount: result.data.data.length,
            pageCount: Math.ceil(result.data.data.length / this.state.perPage),
          });
          const data = result.data.data;
          const postData = data.map((product, index) => {
            return (
            <tr key={product.id.toString()}>
              <td>
                {' '}
                <label for="checkbox1" class="checkbox">
                  <input
                    type="checkbox"
                    checked={product.checked}
                    onChange={() => this.productCheck(index)}
                  />
                </label>
                {product.checked + 'check'}
              </td>
              <td>
                {/* <img
                  src={product?.image[0]}
                  alt=".."
                  className="product-first-image"
                ></img> */}
              </td>
              <td>{product?.product_name}</td>
              <td>{product?.sku}</td>
              <td>{product?.quantity}</td>
              <td>
                $ {product.price ? product.price : product.color_size[0].price}
              </td>
              <td>
                {' '}
                <div style={{ display: 'flex' }}>
                  {product.activeStatus === '1' ? (
                    <button
                      className="newdlt_btn"
                      onClick={() => this.disableHandler(product.id, index)}
                    >
                      <i class="fa fa-eye-slash list-eye-icon"></i>
                    </button>
                  ) : (
                    // {/* <i
                    //   class="fa fa-eye list-eye-icon"
                    //   onClick={() => this.disableHandler(product.id, index)}
                    // ></i> */}
                    <button
                      className="newdlt_btn"
                      onClick={() => this.disableHandler(product.id, index)}
                    >
                      <i class="fa fa-eye-slash list-eye-icon"></i>
                    </button>
                  )}
                  <li
                    class="dropdown dropdown-user nav-item custom-drop"
                    style={{ width: '20px' }}
                  >
                    <a
                      class="dropdown-toggle nav-link dropdown-user-link custom-drop-toggle"
                      href="#"
                      data-toggle="dropdown"
                    >
                      <span>
                        <i class="fa fa-ellipsis-h" height="40" width="40" />
                      </span>
                    </a>
                    <div class="dropdown-menu dropdown-menu-right">
                      <Link
                        to={'/viewproduct/' + product.id}
                        class="dropdown-item"
                      >
                        View
                      </Link>
                      <Link to="/productlist" class="dropdown-item">
                        View Page Builder
                      </Link>
                      <Link
                        to={'/editproduct/' + product.id}
                        class="dropdown-item"
                      >
                        Edit
                      </Link>
                      <button
                        class="dropdown-item"
                        onClick={() => this.modelToggle3(product.id, index)}
                        style={{ width: '100%' }}
                      >
                        Copy
                      </button>
                      <Link
                        to={'/orderlist/' + product.id}
                        class="dropdown-item"
                      >
                        View Order
                      </Link>
                    </div>
                  </li>
                </div>
              </td>
            </tr>
          )});
          const gridData = data.map((product, index) => (
            <div class="col-md-3 col-lg-3 col-6">
              <div class="card" style={{ backgroundColor: '#f2f2f2' }}>
                <div class="card-content">
                  {/* <Link to={'/editproduct/' + product.id}>
                    <i
                      className="fa fa-pencil"
                      style={{
                        marginLeft: '10px',
                        marginRight: '10px',
                        marginTop: '7px',
                        color: 'green',
                      }}
                    ></i>
                  </Link>
                  <i
                    className="fa fa-trash"
                    onClick={() => this.Deleteproducthendeler(product.id)}
                    style={{ marginRight: '6px', marginTop: '7px' }}
                  ></i> */}
                  <div
                    class="card-img-top img-fluid p-img"
                    style={{
                      background: 'url(' + product?.image[0] + ')',
                      backgroundSize: 'cover',
                    }}
                  >
                    <Link
                      className="productlisteditbtn"
                      to={'/editproduct/' + product.id}
                    >
                      <i className="fa fa-pencil"></i>
                    </Link>
                    <i
                      className="fa fa-trash productlistdeletebtn"
                      onClick={() => this.Deleteproducthendeler(product.id)}
                    ></i>
                    <label
                                              for="checkbox1"
                                              class="checkbox"
                                            >
                                              <input
                                                type="checkbox"
                                                checked={product.checked}
                                                onChange={() =>
                                                  this.productCheck(index)
                                                }
                                                style={{
                                                  height: '20px',
                                                  width: '15px',
                                                }}
                                              />
                                            </label>
                  </div>
                  <div class="card-body">
                    <h6 class="card-text">{product.product_name}</h6>
                    <h6>Qty:{product.quantity}</h6>
                    <h6>
                      Price: ${' '}
                      {product.price
                        ? product.price
                        : product.color_size[0].price}
                    </h6>
                  </div>
                </div>
              </div>
            </div>
          ));
          this.setState({
            // totalCount: result.data.data.length,
            // pageCount: Math.ceil(result.data.data.length / this.state.perPage),
            postData,
            gridData,
          });
          // console.log(this.state.pageCount,"=page",result.data.data.length,"len")
          this.pagination();
        }
      });
  }

  pagination() {
    console.log(this.state.offset, 'offsetcheck');
    console.log(this.state.products.length, 'datalenth');
    const slice = this.state.products.slice(
      this.state.offset - 1,
      this.state.offset - 1 + this.state.perPage
    );
    console.log(slice, 'slicedata');
    // For displaying Data
    this.setState({ listdata: slice });
    // this.setState({ listdata: this.state.products });
  }
  handlePageClick = (e) => {
    const selectedPage = e.selected;
    console.log(selectedPage, 'selected page');
    const offset = selectedPage * this.state.perPage;

    this.setState(
      {
        currentPage: selectedPage + 1,
        offset: offset + 1,
      },
      () => {
        // this.pagination();
        this.listAPI();
      }
    );
  };
  firstListView = () => {
    this.setState({ listView: '1' });
  };
  secondListView = () => {
    if (this.state.listView !== '2') {
      this.setState({ listView: '2' }, () => {
        // let newTableRow = [];
        // let dataTableRow = this.state.datatable.rows;
        // for (let i = 0; i < dataTableRow.length; i++) {
        //   // console.log(dataTableRow[i].image.props.className)
        //   //  dataTableRow[i].image.props.className="product-second-image"
        //   dataTableRow[i].image = (
        //     <img
        //       src={dataTableRow[i].image.props.src}
        //       alt=".."
        //       className={
        //         this.state.listView === "1"
        //           ? "product-first-image"
        //           : "product-second-image"
        //       }
        //     ></img>
        //   );
        // }
        // let dataTable = {
        //   columns: this.state.datatable.columns,
        //   rows: dataTableRow
        // };
        // this.setState({
        //   datatable: this.state.backupDataTable
        // });
      });
    }
  };
  disableHandler = async (id, index) => {
    this.setState({ id1: id, index1: index });
    var r;
    if (this.state.products[index].activeStatus === 1) {
      // r = await confirm({message:'Are you sure to hide product?'});
      this.setState({ messageto: 'Are you sure to hide product?' })
      this.setState({ showLogOut: true })
    } else {
      // r = await confirm({message:'Are you sure to un hide product?'});
      this.setState({ messageto: 'Are you sure to un hide product?' })
      this.setState({ showLogOut: true })
    }
    // if (r) {
    //   var urlencoded = new URLSearchParams();
    //   urlencoded.append('id', id);
    //   urlencoded.append('sellerId', this.state.seller.id);
    //   axios
    //     .post(global.baseUrl + 'SellerProductActiveStatusChange', urlencoded, {
    //       headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    //     })
    //     .then((result) => {
    //       let item = this.state.products;
    //       if (result.data.status) {
    //         if (result.data.data) {
    //           console.log(result.data.data);
    //           if (result.data.data[0]) {
    //             this.setState({
    //               datatable: {
    //                 columns: [],
    //                 rows: [],
    //               },
    //             });
    //             // this.setState({loaded:false})
    //             item[index].activeStatus = result.data.data[0].activeStatus;
    //             this.setState({ product: item }, () => {
    //               //  this.setProduct();
    //             });
    //           }
    //         }
    //       } else {
    //         console.log('result', result.data);
    //       }
    //     });
    // }
  }

  disableHandler2 = () => {
    console.log(this.state.r, "done123")
    // if (this.state.r) {
    var urlencoded = new URLSearchParams();
    urlencoded.append('id', this.state.id1);
    urlencoded.append('sellerId', this.state.seller.id);
    axios
      .post(global.baseUrl + 'SellerProductActiveStatusChange', urlencoded, {
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      })
      .then((result) => {
        let item = this.state.products;
        if (result.data.status) {
          if (result.data.data) {
            console.log(result.data.data);
            if (result.data.data[0]) {
              this.setState({
                datatable: {
                  columns: [],
                  rows: [],
                },
              });
              // this.setState({loaded:false})
              item[this.state.index1].activeStatus = result.data.data[0].activeStatus;
              this.setState({ product: item }, () => {
                //  this.setProduct();
              });
            }
          }
        } else {
          console.log('result', result.data);
        }
      });
    // }
  }
  productCheck = (index) => {
    allProductCheck = false;
    let products = this.state.products;
    if (products[index].checked === true) {
      products[index].checked = false;
    } else {
      products[index].checked = true;
    }
    this.setState({ products: products, allProductCheck: false });
  };
  multipleDisableHandler = () => {
    let products = this.state.products;
    let newProduct = [];
    let i = 0;
    for (i = 0; i < products.length; i++) {
      if (products[i].checked === true) {
        newProduct.push(products[i]);
      }
    }
    console.log(i, products.length);
    if (i === products.length && newProduct.length) {
      this.setState({ newProduct: newProduct }, () => {
        this.multipleFalseHandler();
      });
    } else {
      toast.warn(<ProductWarnToast />, {
        position: toast.POSITION.TOP_RIGHT,
      });
      this.setState({lodingconfirm:false})
    }
  };
  multipleFalseHandler = async () => {
    let r = '';
    let status = '';
    if (this.state.actionTask === 'notvisible') {

      // r = await confirm({message:'Are you sure to hide product?'});
      this.setState({ messageto: 'Are you sure to hide product?', showLogOut4: true, status: '0' })
      status = '0';
    } else {
      // r = await confirm({message:'Are you sure to show product?'});
      this.setState({ messageto: 'Are you sure to show product?', showLogOut4: true, status: '1' })
      status = '1';
    }
    // if (r) {
    //   let urlencoded = new URLSearchParams();
    //   for (let i = 0; i < this.state.newProduct.length; i++) {
    //     urlencoded.append('productId', this.state.newProduct[i].id);
    //   }
    //   urlencoded.append('status', status);
    //   urlencoded.append('sellerId', this.state.seller.id);
    //   axios
    //     .post(global.baseUrl + 'SellerMultiProductDisable', urlencoded, {
    //       headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    //     })
    //     .then((result) => {
    //       allProductCheck = false;

    //       this.setState({ newProduct: [], product: [] }, () => {
    //         this.listAPI();
    //       });
    //     });
    // }
  };

  multipleFalseHandler2 = () => {
    let urlencoded = new URLSearchParams();
    for (let i = 0; i < this.state.newProduct.length; i++) {
      urlencoded.append('productId', this.state.newProduct[i].id);
    }
    urlencoded.append('status', this.state.status);
    urlencoded.append('sellerId', this.state.seller.id);
    axios
      .post(global.baseUrl + 'SellerMultiProductDisable', urlencoded, {
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      })
      .then((result) => {
        allProductCheck = false;

        this.setState({ newProduct: [], product: [] }, () => {
          this.listAPI();
        });
      });
  }
  allProductSelection = () => {
    let products = this.state.products;
    if (allProductCheck === false) {
      for (let i = 0; i < products.length; i++) {
        products[i].checked = true;
      }
      allProductCheck = true;
      this.setState({
        products: products,
      });
    } else {
      for (let i = 0; i < products.length; i++) {
        products[i].checked = false;
      }
      allProductCheck = false;
      this.setState({
        products: products,
      });
    }
  };
  modelToggle3=(id,index)=>{
    console.log(id,"pppppp");
    if(this.state.modal3===false){
      this.setState({modal3:true})
      this.setState({c_id:id,c_index:index});
    }else{
      modal3=false
    }
  }
  modalToggle = (id, index) => {
    console.log('Working');
    this.setState({modal3:false})
    if (modal === false) {
      modal = true;
      let charge = this.state.products[index]?.image.length - 5;
      if (charge >= 1) {
        charge = charge * parseFloat(this.state.imageCharge);
      } else {
        charge = 0;
      }
      this.setState({
        productId: id,
        productImageCharge: (parseInt(charge)*parseInt(this.state.copyno)),
        finalTotal: ((parseFloat(this.state.productCharge) + parseFloat(charge))*parseInt(this.state.copyno)),
      });
    } else {
      modal = false;
    }
    this.setState({ A: '' });
  };
  closeModalToggle = () => {
    console.log('close working');
    if (modal === false) {
      modal = true;
    } else {
      modal = false;
    }
    this.setState({ A: '' });
  };
  closeModalToggle3 = () => {
    console.log('close working',modal3);
    if (this.state.modal3 === false) {
      this.setState({modal3:true})
    } else {
      this.setState({modal3:false})
    }
  };
  modal2Toggle = () => {
    if (modal2 === false) {
      modal2 = true;
    } else {
      modal2 = false;
    }
    this.setState({ A: '' });
  };
  paymentOpen = () => {
    modal = false;
    modal2 = true;
    this.setState({ a: '' });
  };
  onSuccess = (data) => {
    modal2 = false;
    isActive = true;
    this.setState({
      paypalPaymentId: data.paymentID,
      paymentType: 'paypal',
    });

    this.copyProduct();
  };
  transactionCanceled = () => {
    console.log('transaction cancel');
  };
  transactionError = () => {
    console.log('transaction error');
  };
  onToken = (amount, description) => (token) => {
    modal2 = false;
    isActive = true;
    this.setState({ a: '' });
    let urlencoded = new URLSearchParams();
    urlencoded.append('email', token.email);
    urlencoded.append('source', token.id);
    urlencoded.append('token', JSON.stringify(token));
    urlencoded.append('description', description);
    urlencoded.append('amount', parseInt(parseFloat(amount) * 100));
    urlencoded.append('currency', CURRENCY);
    urlencoded.append('cardName', token.card.name);

    axios
      .post(global.baseUrl + 'SellerProductStripePayment', urlencoded)
      .then((result) => {
        console.log(result.data);
        if (result.data.status) {
          this.setState({
            transactionId: result.data.data.balance_transaction,
            paymentType: 'credit_debit',
          });
          this.copyProduct();
        }
        //successPayment();
      })
      .catch();
  };
  copyProduct = () => {
    modal2 = false;
    modal = false;
    this.setState({ a: '' });
    let urlencoded = new URLSearchParams();
    urlencoded.append('id', this.state.c_id);
    urlencoded.append('payment_type', this.state.paymentType);
    urlencoded.append('paymentID', this.state.paypalPaymentId);
    urlencoded.append('transactionId', this.state.transactionId);
    urlencoded.append('walletId', this.state.wallerPaymentId);
    // urlencoded.append('copyno', this.state.copyno);
    for (let i = 1; i <=this.state.copyno; i++) {
      if(i!=this.state.copyno){
      axios
      .post(global.baseUrl + 'SellerCopyProduct', urlencoded, {
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      })
      .then((result) => {
        // if (result.data.status&&this.state.copyno==elsement) {
        //   this.getWalletAPI();
        //   toast.success(<CustomToast />, {
        //     position: toast.POSITION.TOP_RIGHT,
        //   });
        //   isActive = false;
        //   this.setState({
        //     wallerPaymentId: '',
        //     walletAmount: 0,
        //     paypalPaymentId: '',
        //     transactionId: '',
        //     paymentType: '',
        //   });
        //   this.listAPI();
        // }
      });
    }else{
      axios
      .post(global.baseUrl + 'SellerCopyProduct', urlencoded, {
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      })
      .then((result) => {
        if (result.data.status) {
          this.getWalletAPI();
          toast.success(<CustomToast />, {
            position: toast.POSITION.TOP_RIGHT,
          });
          isActive = false;
          this.setState({
            wallerPaymentId: '',
            walletAmount: 0,
            paypalPaymentId: '',
            transactionId: '',
            paymentType: '',
          });
          this.listAPI();
        }
      });
    }
    }
    
    
  };
  walletPayment = () => {
    if (
      parseFloat(this.state.finalTotal) <=
      parseFloat(this.state.walletAmount) &&
      parseFloat(this.state.walletAmount) > 0
    ) {
      modal2 = false;
      isActive = true;
      this.setState({ a: '' });

      let urlencoded = new URLSearchParams();

      urlencoded.append('sellerId', this.state.seller.id);
      urlencoded.append('credit', this.state.finalTotal);
      axios
        .post(global.baseUrl + 'SellerCreditWallet', urlencoded)
        .then((result) => {
          console.log(result.data);
          if (result.data.status) {
            this.setState({
              paymentType: 'wallet',
              wallerPaymentId: result.data.data.id,
            });
            this.copyProduct();
          }
          //successPayment();
        })
        .catch();
    } else {
      toast.error(<WarnToast />, {
        position: toast.POSITION.TOP_RIGHT,
      });
      console.log('working not');
    }
  };
  freeListingWarnHandler = () => {
    toast.error(<AddProductWarnToast />, {
      position: toast.POSITION.TOP_RIGHT,
    });
  };
  handleSearchProduct = (e) => {
    this.setState({
      searchProduct: e.target.value,
    });
    this.setState(
      {
        currentPage: 1,
        offset: 10,
      },
      () => {
        this.listAPI();
      }
    );
  };
  actionChange = (e) => {
    console.log(e);
    this.setState({ actionTask: e.target.value });
  };
  checkAction = () => {
    mainProduct = [];
    this.setState({
      selectedProduct: [],
    });
    if (this.state.actionTask === '') {
      toast.warn(<ActionWarnToast />, {
        position: toast.POSITION.TOP_RIGHT,
      });
      this.setState({lodingconfirm:false})
    } else if (
      this.state.actionTask === 'notvisible' ||
      this.state.actionTask === 'visible'
    ) {
      this.multipleDisableHandler();
    } else if (this.state.actionTask === 'freeshipping') {
      this.multipleFreeShippingHandler();
    } else if (this.state.actionTask === 'outofstock') {
      this.multipleOutOfStockHandlerHandler();
    } else if (this.state.actionTask === 'renewal') {
      this.multiProductRenewal();
    } else if (this.state.actionTask === 'print') {
      this.printContent();
    } else if (this.state.actionTask === 'pdf') {
      //this.pdfElement.click();
      this.pdfContent();
    } else if (this.state.actionTask === 'exportAll') {
      this.exportAll(this.state.allProduct, 'download');
      this.mobilepdf(this.state.allProduct)
    } else if (this.state.actionTask === 'exportSelected') {
      this.exportAllHandler();
    }
  };
  exportAllHandler = () => {
    let products = this.state.products;
    let newProduct = [];
    let i = 0;
    for (i = 0; i < products.length; i++) {
      if (products[i].checked === true) {
        newProduct.push({
          id: products[i].id,
          product_name: products[i].product_name,
          sku: products[i].sku,
          price: products[i].price,
          quantity: products[i].quantity,
          msrp: products[i].msrp,
        });
      }
    }
    if (i === products.length && newProduct.length) {
      this.setState({ selectedProduct: newProduct }, () => {
        this.exportSelected(this.state.selectedProduct);
        this.mobilepdf(this.state.selectedProduct)
      });
    } else {
      toast.warn(<ProductWarnToast />, {
        position: toast.POSITION.TOP_RIGHT,
      });
      this.setState({lodingconfirm:false})
    }
  };
  multipleFreeShippingHandler = () => {
    let products = this.state.products;
    let newProduct = [];
    let i = 0;
    for (i = 0; i < products.length; i++) {
      if (products[i].checked === true) {
        newProduct.push(products[i]);
      }
    }
    if (i === products.length && newProduct.length) {
      this.setState({ newProduct: newProduct }, () => {
        this.multipleFreeShippingAPI();
      });
    } else {
      toast.warn(<ProductWarnToast />, {
        position: toast.POSITION.TOP_RIGHT,
      });
      this.setState({lodingconfirm:false})
    }
  };
  multipleFreeShippingAPI = async () => {
    let r = '';
    if (this.state.actionTask === 'freeshipping') {
      r = window.confirm('Are you sure to set free shipping of product?');
    }
    if (r) {
      let urlencoded = new URLSearchParams();
      for (let i = 0; i < this.state.newProduct.length; i++) {
        urlencoded.append('productId', this.state.newProduct[i].id);
      }
      urlencoded.append('sellerId', this.state.seller.id);
      axios
        .post(global.baseUrl + 'SellerMultiFreeShipping', urlencoded, {
          headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        })
        .then((result) => {
          allProductCheck = false;

          this.setState({ newProduct: [], product: [] }, () => {
            this.listAPI();
          });
        });
    }
  };
  multipleOutOfStockHandlerHandler = () => {
    let products = this.state.products;
    let newProduct = [];
    let i = 0;
    for (i = 0; i < products.length; i++) {
      if (products[i].checked === true) {
        newProduct.push(products[i]);
      }
    }
    if (i === products.length && newProduct.length) {
      this.setState({ newProduct: newProduct }, () => {
        this.multiOutOfStockAPI();
      });
    } else {
      toast.warn(<ProductWarnToast />, {
        position: toast.POSITION.TOP_RIGHT,
      });
      this.setState({lodingconfirm:false})
    }
  };
  multiOutOfStockAPI = async () => {
    console.log('multi out of stock');
    let r = '';
    if (this.state.actionTask === 'outofstock') {
      r = window.confirm('Are you sure to set out of stock product?');
    }
    if (r) {
      let urlencoded = new URLSearchParams();
      for (let i = 0; i < this.state.newProduct.length; i++) {
        urlencoded.append('productId', this.state.newProduct[i].id);
      }
      urlencoded.append('sellerId', this.state.seller.id);
      axios
        .post(global.baseUrl + 'SellerMultiOutOfStock', urlencoded, {
          headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        })
        .then((result) => {
          allProductCheck = false;

          this.setState({ newProduct: [], product: [] }, () => {
            this.listAPI();
          });
        });
    }
  };
  multiProductDeleteHandler = () => {
    let products = this.state.products;
    let newProduct = [];
    let i = 0;
    for (i = 0; i < products.length; i++) {
      if (products[i].checked === true) {
        newProduct.push(products[i]);
      }
    }
    if (i === products.length && newProduct.length) {
      this.setState({ newProduct: newProduct }, () => {
        this.multiProductDeleteAPI();
      });
    } else {
      toast.warn(<ProductWarnToast />, {
        position: toast.POSITION.TOP_RIGHT,
      });
      this.setState({lodingconfirm:false})
    }
  };
  multiProductDeleteAPI = async () => {
    // let r = '';multipleDisableHandler
    // r = await confirm({message:'Are you sure to  delete  product?'});
    this.setState({ messageto: 'Are you sure to  delete  product?', showLogOut3: true })
    // if (r) {
    //   let urlencoded = new URLSearchParams();
    //   for (let i = 0; i < this.state.newProduct.length; i++) {
    //     urlencoded.append('productId', this.state.newProduct[i].id);
    //     console.log(this.state.newProduct[i].id, 'productId');
    //   }
    //   urlencoded.append('sellerId', this.state.seller.id);
    //   axios
    //     .post(global.baseUrl + 'SellerMultiProductDelete', urlencoded, {
    //       headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    //     })
    //     .then((result) => {
    //       allProductCheck = false;
    //       this.setState({ newProduct: [], product: [] }, () => {
    //         this.listAPI();
    //       });
    //     });
    // }
  };

  multiProductDeleteAPI2 = async () => {
    let urlencoded = new URLSearchParams();
    for (let i = 0; i < this.state.newProduct.length; i++) {
      urlencoded.append('productId', this.state.newProduct[i].id);
      console.log(this.state.newProduct[i].id, 'productId');
    }
    urlencoded.append('sellerId', this.state.seller.id);
    axios
      .post(global.baseUrl + 'SellerMultiProductDelete', urlencoded, {
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      })
      .then((result) => {
        allProductCheck = false;
        this.setState({ newProduct: [], product: [] }, () => {
          this.listAPI();
        });
      });
  }

  Deleteproducthendeler = async (id) => {
    // let r = '';

    this.setState({ messageto: 'Are you sure to  delete  product?', id1: id, showLogOut2: true })
    // r = await confirm({message:'Are you sure to  delete  product?'});
    // if (r) {
    //   let urlencoded = new URLSearchParams();

    //   urlencoded.append('productId', id);
    //   // console.log(this.state.newProduct[i].id, 'productId');

    //   urlencoded.append('sellerId', this.state.seller.id);
    //   axios
    //     .post(global.baseUrl + 'SellerMultiProductDelete', urlencoded, {
    //       headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    //     })
    //     .then((result) => {
    //       allProductCheck = false;
    //       this.setState({ newProduct: [], product: [] }, () => {
    //         this.listAPI();
    //       });
    //     });
    // }
  };

  Deleteproducthendeler2 = () => {
    let urlencoded = new URLSearchParams();

    urlencoded.append('productId', this.state.id1);
    // console.log(this.state.newProduct[i].id, 'productId');

    urlencoded.append('sellerId', this.state.seller.id);
    axios
      .post(global.baseUrl + 'SellerMultiProductDelete', urlencoded, {
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      })
      .then((result) => {
        allProductCheck = false;
        this.setState({ newProduct: [], product: [] }, () => {
          this.listAPI();
        });
      });
  }
  multiProductRenewal = () => {
    let products = this.state.products;
    let newProduct = [];
    let i = 0;
    for (i = 0; i < products.length; i++) {
      if (products[i].checked === true) {
        newProduct.push(products[i]);
      }
    }
    if (i === products.length && newProduct.length) {
      this.setState({ newProduct: newProduct }, () => {
        this.multiRenewalAPI();
      });
    } else {
      toast.warn(<ProductWarnToast />, {
        position: toast.POSITION.TOP_RIGHT,
      });
      this.setState({lodingconfirm:false})
    }
  };
  multiRenewalAPI = async () => {
    let products = this.state.newProduct;
    let total =
      this.state.newProduct.length * parseFloat(this.state.productCharge);
    console.log(total, this.state.productCharge, 'charge');
    if (total <= this.state.walletAmount) {
    // let r = window.confirm(
    //   'Are you sure for auto renewal product?It take $ ' + total
    // );
    this.setState({messageto:'Are you sure for auto renewal product?' + parseFloat(total).toFixed(2)})
    this.setState({showLogOut: true})
    } else {
      toast.warn(<WarnToast />, {
        position: toast.POSITION.TOP_RIGHT,
      });
      this.setState({lodingconfirm:false})
    }
  };
  addAllOptionHandler2 = async () => {
    let products = this.state.newProduct;
    let total =
      this.state.newProduct.length * parseFloat(this.state.productCharge);
    console.log(total, this.state.productCharge, 'charge');
      let data = [];
      let urlencoded = new URLSearchParams();
      urlencoded.append('sellerId', seller.id);
      // for (let i = 0; i < products.length; i++) {
      //   urlencoded.append('productId', products[i].id);
      // }
      for (let i = 0; i < products.length; i++) {
        data.push(products[i].id);
        // urlencoded.append("productId", defaultOptions[i].id);
        // autoRenewalOptions.push(products[i]);
      }
      urlencoded.append('productId', JSON.stringify(data));
      // urlencoded.append('renewal', 'auto');
      urlencoded.append('credit', this.state.productCharge);

      axios
        .post(global.baseUrl + 'SellerMultiProductmanualRenewal', urlencoded, {
          headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        })
        .then((result) => {
          console.log(result.data, 'amount');
          this.getWalletAPI();
          this.listAPI();
          this.setState({lodingconfirm:false})
        });

      this.setState({});
  };
 
  mobilepdf=(product)=>{
    if(window.innerWidth <= 767){
    let data=`<div id="pdfTableDiv" style={{ display: 'none' }}>
    <Table
      responsive
      hover
      className="display border-product-list-table"
      style={{ fontSize: '12px' }}
      id="pdfDiv"
    >
      <thead>
        <tr>
          <th scope="col">No</th>
          <th scope="col">Product Name</th>
          <th scope="col">SKU</th>
          <th scope="col">Stock</th>
          <th scope="col">Price</th>
        </tr>
      </thead>
      <tbody>
        ${product ? (
          product.map((order, index) => (
            `<tr key=${index}>
              <td>${index + 1}</td>
              <td style={{ minWidth: '144px' }}>
                ${order.product_name}
              </td>
              <td style={{ minWidth: '250px' }}>
                ${order.sku}
              </td>
   
              <td className="prod-ellip">
                ${order.quantity}
              </td>
              <td>${order.price}</td>
  
              
            </tr>`
          ))
        ) : (
          `<tr>
            <td colSpan={6}>No Product Available.</td>
          </tr>`
        )}
      </tbody>
    </Table>
  </div>`;

  var urlencoded = new URLSearchParams();
      // urlencoded.append('id', id);
      urlencoded.append('body', data);
      axios
        .post(global.baseUrl + 'pdfgenratelink', urlencoded, {
          headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        })
        .then((result) => {
         console.log(result.data.data,"ddddddddlink")
         var message = {'type':'1', 'data' : [  { "pdfLink" : result.data.data } ]};
        //  window.webkit.messageHandlers.derp.postMessage(message);
        // window.open(result.data.data,"_self")
        window.postMessage(message)
        this.setState({lodingconfirm:false})
        });

      }else{
        this.setState({lodingconfirm:false})
      }
  }

  downlodeall=(pdfContents)=>{
    if(window.innerWidth <= 767){
    console.log("enterrrrrrrrrrr")
    let data=`<div>${pdfContents}</div>`
     var urlencoded = new URLSearchParams();
       // urlencoded.append('id', id);
       urlencoded.append('body', data);
       axios
         .post(global.baseUrl+ 'pdfgenratelink', urlencoded, {
           headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
         })
         .then((result) => {
          console.log(result.data.data,"ddddddddlink")
          var message = {'type':'1', 'data' : [  { "pdfLink" : result.data.data } ]};
          console.log(result.data.data,"enterrrrrrrrrrr")
        //  window.open(result.data.data,"_self")
         window.postMessage(message)
         this.setState({loading:false});
         this.setState({lodingconfirm:false})
         });

        }else{
          this.setState({lodingconfirm:false})
        }
   }

  printContent = () => {
    let products = this.state.products;
    let newProduct = [];

    let i = 0;
    for (i = 0; i < products.length; i++) {
      if (products[i].checked === true) {
        newProduct.push(products[i]);
      }
    }
    if (i === products.length && newProduct.length) {
      mainProduct = newProduct;
      this.setState({ newProduct: newProduct }, () => {
        console.log(this.state.newProduct,"dddd11223344556677")
        this.mobilepdf(this.state.newProduct)
        this.printButtonElement.click();
      });
    } else {
      toast.warn(<ProductWarnToast />, {
        position: toast.POSITION.TOP_RIGHT,
      });
      this.setState({lodingconfirm:false})
    }
  };
  pdfContent = () => {
    var pdfContents = document.getElementById('pdfTableDiv').innerHTML;
    console.log(pdfContents, 'klffh');
    var originalContents = document.getElementById('pdfDiv').innerHTML;

    document.getElementById('pdfDiv').innerHTML = pdfContents;
    this.downlodeall(pdfContents)
    this.pdfElement.click();
    document.getElementById('pdfDiv').innerHTML = originalContents;
  };
  exportAll = (csvData, fileName) => {
    const ws = XLSX.utils.json_to_sheet(csvData);
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };
  exportSelected = (csvData) => {
    const ws = XLSX.utils.json_to_sheet(csvData);
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, 'download' + fileExtension);
  };
  sortHandleChange = (e) => {
    this.setState({ sortType: e.target.value, currentPage: 1 }, () => {
      this.listAPI();
    });
  };
  render() {
    return (
      <body
        class="vertical-layout vertical-menu-modern semi-dark-layout 2-columns  navbar-floating footer-static  "
        data-open="click"
        data-menu="vertical-menu-modern"
        data-col="2-columns"
        data-layout="semi-dark-layout"
      >
        {/* <Header />
        <Sidebar />  */}
        <div class="app-content content">
          <div class="content-overlay"></div>
          <div class="header-navbar-shadow"></div>
          <div class="content-wrapper">
            <div class="content-header row">
              <div class="content-header-left col-md-9 col-12 mb-2">
                <div class="row breadcrumbs-top">
                  <div class="col-12">
                    <h2 class="content-header-title float-left mb-0">
                      Products
                    </h2>
                    <div class="breadcrumb-wrapper col-12">
                      <ol class="breadcrumb">
                        <li class="breadcrumb-item">
                          <Link to="/dashboard">Home</Link>
                        </li>
                        <li class="breadcrumb-item active">Products</li>
                      </ol>
                    </div>
                  </div>
                </div>
              </div>
              <div class="content-header-right text-md-right col-md-3 col-12 d-md-block">
                <div class="form-group breadcrum-right xs-text-right">
                  <Modal isOpen={this.state.showLogOut} fade="false" toggle={() => this.setState({ showLogOut: false })}>
                    <ModalHeader toggle={() => this.setState({ showLogOut: false })}>Warning</ModalHeader>
                    <ModalBody>
                      <p>{this.state.messageto}</p>
                    </ModalBody>
                    <ModalFooter>

                      <Button onClick={() => this.setState({ showLogOut: false })}>Cancel</Button>
                      <Button color="info" onClick={() => { this.setState({ showLogOut: false, r: true }); this.disableHandler2(); }}>Ok</Button>
                    </ModalFooter>
                  </Modal>
                  <Modal isOpen={this.state.showLogOut2} fade="false" toggle={() => this.setState({ showLogOut2: false })}>
                    <ModalHeader toggle={() => this.setState({ showLogOut2: false })}>Warning</ModalHeader>
                    <ModalBody>
                      <p>{this.state.messageto}</p>
                    </ModalBody>
                    <ModalFooter>

                      <Button onClick={() => this.setState({ showLogOut2: false })}>Cancel</Button>
                      <Button color="info" onClick={() => { this.setState({ showLogOut2: false, r: true }); this.Deleteproducthendeler2(); }}>Ok</Button>
                    </ModalFooter>
                  </Modal>
                  <Modal isOpen={this.state.showLogOut} fade="false"  toggle={() => this.setState({showLogOut: false})}>
<ModalHeader toggle={() => this.setState({showLogOut: false})}>Warning</ModalHeader>
    <ModalBody>   
        <p>{this.state.messageto}</p>                     
    </ModalBody>
<ModalFooter>

    <Button onClick={() => this.setState({showLogOut: false})}>Cancel</Button>
    <Button color="info" onClick={()=>{this.setState({showLogOut: false,r:true});this.state.messageto.includes("It will take")?this.addOptionHandler2():this.addAllOptionHandler2();}}>Ok</Button>
</ModalFooter>
</Modal>
                   
                  <Modal isOpen={this.state.showLogOut3} fade="false" toggle={() => this.setState({ showLogOut3: false })}>
                    <ModalHeader toggle={() => this.setState({ showLogOut3: false })}>Warning</ModalHeader>
                    <ModalBody>
                      <p>{this.state.messageto}</p>
                    </ModalBody>
                    <ModalFooter>

                      <Button onClick={() => this.setState({ showLogOut3: false })}>Cancel</Button>
                      <Button color="info" onClick={() => { this.setState({ showLogOut3: false, r: true }); this.multiProductDeleteAPI2(); }}>Ok</Button>
                    </ModalFooter>
                  </Modal>

                  <Modal isOpen={this.state.showLogOut4} fade="false" toggle={() => this.setState({ showLogOut4: false })}>
                    <ModalHeader toggle={() => this.setState({ showLogOut4: false })}>Warning</ModalHeader>
                    <ModalBody>
                      <p>{this.state.messageto}</p>
                    </ModalBody>
                    <ModalFooter>

                      <Button onClick={() => this.setState({ showLogOut4: false })}>Cancel</Button>
                      <Button color="info" onClick={() => { this.setState({ showLogOut4: false, r: true }); this.multiProductDeleteAPI2(); }}>Ok</Button>
                    </ModalFooter>
                  </Modal>
                  {this.state.seller.type === 'Retailer' ? (
                    <Link
                      to="/addproduct"
                      id="addRow"
                      class="btn btn-primary mb-2 add-product-link"
                    >
                      <i class="feather icon-plus"></i>Add Product
                    </Link>
                  ) : this.state.freeListingCount < 20 ? (
                    <Link
                      to="/addproduct"
                      id="addRow"
                      class="btn btn-primary mb-2 add-product-link"
                    >
                      <i class="feather icon-plus"></i>Add Product
                    </Link>
                  ) : (
                    <Button
                      id="addRow"
                      class="btn btn-primary mb-2 add-product-link"
                      onClick={this.freeListingWarnHandler}
                    >
                      <i class="feather icon-plus"></i>Add Product
                    </Button>
                  )}
                </div>
              </div>
            </div>
            <div class="content-body">
              <Modal isOpen={modal} toggle={this.closeModalToggle}>
                <ModalHeader toggle={this.closeModalToggle}>
                  <h5 class="info-modal-title">
                    You are about to publishing a copy listing
                  </h5>
                </ModalHeader>
                <ModalBody>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: this.state.productInfo,
                    }}
                  />
                  <table class="table table-bordered">
                    <thead>
                      <tr>
                        <th>No</th>
                        <th>Type</th>
                        <th>Number Of Copy</th>
                        <th>Cost</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>1</td>
                        <td>Product</td>
                        <td>{parseInt(this.state.copyno)}</td>
                        <td>${this.state.productCharge}</td>
                      </tr>
                      {this.state.productImageCharge ? (
                        <tr>
                          <td>2</td>
                          <td>Image</td>
                          <td>{parseInt(this.state.copyno)}</td>
                          <td>${this.state.productImageCharge}</td>
                        </tr>
                      ) : null}
                      {this.state.productImageCharge ? (
                        <tr>
                          <td colSpan={3}>Total Cost</td>
                          <td>
                            $
                            {((parseFloat(this.state.productImageCharge) +parseFloat(this.state.productCharge))*parseInt(this.state.copyno))}
                          </td>
                        </tr>
                      ) : (
                        <tr>
                          <td colSpan={3}>Total Cost</td>
                          {/* <td colSpan={2}></td> */}
                          <td>${parseFloat(this.state.productCharge)*parseInt(this.state.copyno)}</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </ModalBody>
                <ModalFooter>
                  <button
                    type="button"
                    class="btn btn-success"
                    onClick={this.paymentOpen}
                  >
                    OK
                  </button>
                  <button
                    type="button"
                    class="btn btn-success "
                    onClick={this.closeModalToggle}
                  >
                    Cancel
                  </button>
                </ModalFooter>
              </Modal>
              <Modal
                isOpen={modal2}
                toggle={this.modal2Toggle}
                style={{ width: '70%', marginBottom: '20%' }}
                contentClassName="custom-modal-style"
              >
                <ModalHeader toggle={this.modal2Toggle}>
                  <h5 class="info-modal-title">Select Payment Method</h5>
                </ModalHeader>
                <ModalBody>
                  <ul class="list-unstyled mb-0">
                    <li>
                      <b>
                        you need to pay $ {this.state.finalTotal} for add new
                        product.
                      </b>
                    </li>
                    <li>
                      <div class="vs-radio-con model-resize-div">
                        <button
                          class="btn btn-primary-green p-1 wallet-payment-btn"
                          type="button"
                          onClick={() => this.walletPayment()}
                        >
                          Pay From Wallet (<b>${this.state.walletAmount}</b>)
                        </button>
                      </div>
                    </li>

                    <li>
                      <div class="vs-radio-con model-resize-div">
                        <Paypal
                          totalPay={this.state.finalTotal}
                          onSuccess={this.onSuccess}
                          transactionError={this.transactionError}
                          transactionCanceled={this.transactionCanceled}
                        />
                      </div>
                    </li>
                    <li>
                      <fieldset>
                        <div class="vs-radio-con model-resize-div">
                          <StripeCheckout
                            stripeKey="pk_test_51GtSo6BPY6CGrhq8PbbsO8Cd70i2F9wD7u4qk4gwCugvjMakwViTZIfsUVwmnU6qfQacoJUCpaGFSnvognvlK3HI00CIAYrPMY"
                            token={this.onToken(
                              parseFloat(this.state.finalTotal),
                              'Product Payment'
                            )}
                            name="Garage Lane"
                            amount={fromDollarToCent(
                              parseFloat(this.state.finalTotal)
                            )}
                            currency={CURRENCY}
                          >
                            <button
                              className="btn btn-success btn-primary resize-credit-debit-btn float-right mr-1 btn-inline waves-effect waves-light pay-btn"
                              data-dismiss="modal"
                            >
                              Credit/Debit Card
                            </button>
                          </StripeCheckout>
                        </div>
                      </fieldset>
                    </li>
                  </ul>
                </ModalBody>
                <ModalFooter>
                  <button
                    type="button"
                    class="btn btn-success"
                    onClick={this.modal2Toggle}
                  >
                    OK
                  </button>
                  <button
                    type="button"
                    class="btn btn-success "
                    onClick={this.modal2Toggle}
                  >
                    Cancel
                  </button>
                </ModalFooter>
              </Modal>
              <Modal
                isOpen={this.state.modal3}
                toggle={this.closeModalToggle3}
                style={{ width: '70%', marginBottom: '20%' }}
                contentClassName="custom-modal-style"
              >
                <ModalHeader toggle={this.closeModalToggle3}>
                  <h5 class="info-modal-title">Enter No Of Copy</h5>
                </ModalHeader>
                <ModalBody>
                  <ul class="list-unstyled mb-0">
                    <li>
                      <b>
                      <div class="form-label-group position-relative"><input type="number" id="first-name-floating-icon" class="form-control" name="productName" placeholder="Enter No of copy" value={this.state.copyno} onChange={(e)=>this.setState({copyno:e.target.value})}/></div>
                       {/* <input value={this.state.copyno} onChange={(e)=>this.setState({copyno:e.target.value})} placeholder="Enter No of copy"></input> */}
                      </b>
                    </li>
                    {/* <li>
                      <div class="vs-radio-con model-resize-div">
                        <button
                          class="btn btn-primary-green p-1 wallet-payment-btn"
                          type="button"
                          onClick={() => this.walletPayment()}
                        >
                          Pay From Wallet (<b>${this.state.walletAmount}</b>)
                        </button>
                      </div>
                    </li>

                    <li>
                      <div class="vs-radio-con model-resize-div">
                        <Paypal
                          totalPay={this.state.finalTotal}
                          onSuccess={this.onSuccess}
                          transactionError={this.transactionError}
                          transactionCanceled={this.transactionCanceled}
                        />
                      </div>
                    </li>
                    <li>
                      <fieldset>
                        <div class="vs-radio-con model-resize-div">
                          <StripeCheckout
                            stripeKey="pk_test_51GtSo6BPY6CGrhq8PbbsO8Cd70i2F9wD7u4qk4gwCugvjMakwViTZIfsUVwmnU6qfQacoJUCpaGFSnvognvlK3HI00CIAYrPMY"
                            token={this.onToken(
                              parseFloat(this.state.finalTotal),
                              'Product Payment'
                            )}
                            name="Garage Lane"
                            amount={fromDollarToCent(
                              parseFloat(this.state.finalTotal)
                            )}
                            currency={CURRENCY}
                          >
                            <button
                              className="btn btn-success btn-primary resize-credit-debit-btn float-right mr-1 btn-inline waves-effect waves-light pay-btn"
                              data-dismiss="modal"
                            >
                              Credit/Debit Card
                            </button>
                          </StripeCheckout>
                        </div>
                      </fieldset>
                    </li> */}
                  </ul>
                </ModalBody>
                <ModalFooter>
                  <button
                    type="button"
                    class="btn btn-success"
                    onClick={()=>this.modalToggle(this.c_id,this.c_index)}
                  >
                    Submit
                  </button>
                  <button
                    type="button"
                    class="btn btn-success "
                    onClick={()=>this.closeModalToggle3()}
                  >
                    Cancel
                  </button>
                </ModalFooter>
              </Modal>
              <section id="add-row">
                <div class="row">
                  <div class="col-12">
                    <div class="card">
                      <div>
                        {/* <button variant="warning" onClick={() => this.exportAll(this.state.allProduct,"download")}>Export</button> */}

                        <ReactToPdf
                          targetRef={targetRef}
                          filename="download.pdf"
                        >
                          {({ toPdf }) => (
                            <button
                              onClick={toPdf}
                              ref={(pdfButton) => (this.pdfElement = pdfButton)}
                              style={{ display: 'none' }}
                            >
                              Generate pdf
                            </button>
                          )}
                        </ReactToPdf>

                        <div
                          style={{ background: 'white' }}
                          ref={targetRef}
                          id="pdfDiv"
                        ></div>
                        <div id="pdfTableDiv" style={{ display: 'none' }}>
                          <Table
                            responsive
                            hover
                            className="display border-product-list-table"
                          >
                            <thead>
                              <tr>
                                <th scope="col">No</th>
                                <th scope="col">Product Name</th>
                                <th scope="col">SKU</th>
                                <th scope="col">Stock</th>
                                <th scope="col">Price</th>
                              </tr>
                            </thead>
                            <tbody>
                              {this.state.allProduct.map((product, index) => (
                                <tr key={product.id.toString()}>
                                  <td>{index + 1}</td>
                                  <td className="prod-ellip">
                                    {product.product_name}
                                  </td>
                                  <td>{product.sku}</td>
                                  <td>{product.quantity}</td>
                                  <td className="price-nowrap">
                                    ${' '}
                                    {product.price
                                      ? product.price
                                      : product.color_size[0].price}
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </Table>
                        </div>
                      </div>

                      <ReactToPrint
                        trigger={() => (
                          <button
                            ref={(printButton) =>
                              (this.printButtonElement = printButton)
                            }
                            style={{ display: 'none' }}
                          >
                            Print this out!
                          </button>
                        )}
                        content={() => this.componentRef}
                        style={{ display: 'none' }}
                      />

                      <div id="printMe" style={{ display: 'none' }}>
                        <ComponentToPrint
                          ref={(el) => (this.componentRef = el)}
                          style={{ display: 'none' }}
                        />
                      </div>
                      <div class="card-content">
                        <div class="card-body">
                          <div class="product-list-header">
                            <div class="form-label-group position-relative has-icon-left">
                              <input
                                type="text"
                                id="first-name-floating-icon"
                                class="form-control"
                                name="searchProduct"
                                value={this.state.searchProduct}
                                onChange={this.handleSearchProduct}
                                placeholder="Search"
                              />
                              <div class="form-control-position">
                                <i class="fa fa-search"></i>
                              </div>
                            </div>{' '}
                            <select
                              class="form-control action-select"
                              style={{ fontSize: '16px' }}
                              value={this.state.sortTask}
                              onChange={(e) => this.sortHandleChange(e)}
                            >
                              <option>--Choose Sort Type--</option>
                              <option value="">All</option>
                              <option value="priceHighToLow">
                                Price:Highest to Lowest
                              </option>
                              <option value="priceLowToHigh">
                                Price:Lowest to Highest
                              </option>
                              <option value="sortNewToOld">
                                Sort by Newest to Oldest{' '}
                              </option>
                              <option value="sortOldToNew">
                                Sort by Oldest to Newest
                              </option>
                              <option value="alphabetic">
                                Sort Alphabetically
                              </option>
                              <option value="freeShipping">
                                Free Shipping
                              </option>
                              <option value="outOfStock">Out Of Stock</option>

                              <option value="visible">Visible</option>
                              <option value="notVisible">Not Visible</option>
                            </select>
                            {this.state.totalCount ? (
                              <>
                                <select
                                  class="form-control action-select"
                                  style={{ fontSize: '16px' }}
                                  value={this.state.actionTask}
                                  onChange={(e) => this.actionChange(e)}
                                >
                                  <option>--Choose an action--</option>
                                  {/* <option value="visible">Visible</option>
                                  <option value="notvisible">Not Visible</option> */}
                                  {/* <option value="freeshipping">Free Shipping</option> */}
                                  {/* <option value="outofstock">Out Of Stock</option> */}
                                  <option value="renewal">Manual Renewal</option>
                                  <option value="print">Print Selected</option>
                                  <option value="pdf">Save as PDF</option>
                                  <option value="exportAll">Export All</option>
                                  <option value="exportSelected">
                                    Export Selected
                                  </option>
                                </select>
                               {
                               this.state.lodingconfirm===false?
                                <button
                                  class="btn btn-outline-primary float-left btn-inline res-btn cnf-btn"
                                  onClick={()=>{this.setState({lodingconfirm:true});this.checkAction()}}
                                >
                                  Confirm
                                </button>:
                                <Spinner  style={{ width: '20px', height: '20px',margin:'10px 1px 10px 13px',flex:'0 0 20px' }}/>}
                                <i
                                  class="fa fa-trash product-trash-icon"
                                  onClick={this.multiProductDeleteHandler}
                                ></i>
                                <i
                                  class="fa fa-bars product-cus-list-icon"
                                  aria-hidden="true"
                                  onClick={() => this.firstListView()}
                                ></i>
                                <i
                                  class="fa fa-th-large product-cus-list-icon"
                                  aria-hidden="true"
                                  onClick={() => this.secondListView()}
                                ></i>
                              </>
                            ) : null}
                          </div>
                          {this.state.listView === '1' ? (
                            <>
                              <Table
                                responsive
                                hover
                                className="display border-product-list-table"
                                style={{ fontSize: '18px' }}
                              >
                                <thead>
                                  <tr>
                                    <th scope="col">
                                      <label for="checkbox1" class="checkbox">
                                        <input
                                          type="checkbox"
                                          checked={allProductCheck}
                                          onClick={() =>
                                            this.allProductSelection()
                                          }
                                          style={{
                                            height: '20px',
                                            width: '16px',
                                          }}
                                        />
                                      </label>
                                    </th>
                                    <th
                                      scope="col"
                                      style={{ fontSize: '18px' }}
                                    >
                                      Product Image
                                    </th>
                                    <th
                                      scope="col"
                                      style={{ fontSize: '18px' }}
                                    >
                                      Product Name
                                    </th>
                                    <th
                                      scope="col"
                                      style={{ fontSize: '18px' }}
                                    >
                                      SKU
                                    </th>
                                    <th
                                      scope="col"
                                      style={{ fontSize: '18px' }}
                                    >
                                      Stock
                                    </th>
                                    <th
                                      scope="col"
                                      style={{ fontSize: '18px' }}
                                    >
                                      Price
                                    </th>
                                    <th
                                      scope="col"
                                      style={{ fontSize: '18px' }}
                                    >
                                      Renew Date
                                    </th>
                                    <th style={{ fontSize: '18px' }}>
                                      Actions
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {this.state.totalCount ? (
                                    this.state.listdata.map(
                                      (product, index) => 

                                        {
                                          console.log(product,"kk123")
                                          return(<>
                                        <tr key={product.id.toString()}>
                                          <td>
                                            {' '}
                                            <label
                                              for="checkbox1"
                                              class="checkbox"
                                            >
                                              <input
                                                type="checkbox"
                                                checked={product.checked}
                                                onChange={() =>
                                                  this.productCheck(index)
                                                }
                                                style={{
                                                  height: '20px',
                                                  width: '15px',
                                                }}
                                              />
                                            </label>
                                          </td>
                                          <td>
                                            <img
                                              src={product.image[0]}
                                              alt=".."
                                              className="product-first-image"
                                            ></img>
                                          </td>
                                          <td className="prod-ellip">
                                            {product.product_name}
                                          </td>
                                          <td>{product.sku}</td>
                                          <td>{product.quantity}</td>
                                          <td className="price-nowrap">
                                            ${' '}
                                            {product.price
                                              ? product.price
                                              : product.color_size[0].price}
                                          </td>
                                          <td style={{ whiteSpace: 'nowrap' }}>
                                            {product.updatedAt.substring(0, 10)}
                                          </td>
                                          <td>
                                            {' '}
                                            <div style={{ display: 'flex' }}>
                                              {product.activeStatus === '1' ? (
                                                <button
                                                  className="newdlt_btn"
                                                  onClick={() =>
                                                    this.disableHandler(
                                                      product.id,
                                                      index
                                                    )
                                                  }
                                                >
                                                  <i class="fa fa-eye list-eye-icon"></i>
                                                </button>
                                              ) : (
                                                <button
                                                  className="newdlt_btn"
                                                  onClick={() =>
                                                    this.disableHandler(
                                                      product.id,
                                                      index
                                                    )
                                                  }
                                                >
                                                  <i class="fa fa-eye-slash list-eye-icon"></i>
                                                </button>
                                              )}

                                              <Link
                                                to={
                                                  '/editproduct/' + product.id
                                                }
                                              >
                                                <i
                                                  className="fa fa-pencil"
                                                  style={{
                                                    marginLeft: '10px',
                                                    marginRight: '10px',
                                                    marginTop: '7px',
                                                    color: 'green',
                                                  }}
                                                ></i>
                                              </Link>
                                              <button
                                                className="newdlt_btn"
                                                onClick={() =>
                                                  this.Deleteproducthendeler(
                                                    product.id
                                                  )
                                                }
                                              >
                                                <i
                                                  className="fa fa-trash list-of-trash"
                                                  style={{
                                                    marginRight: '6px',
                                                    marginTop: '7px',
                                                  }}
                                                ></i>
                                              </button>

                                              <li
                                                class="dropdown dropdown-user nav-item custom-drop"
                                                style={{ width: '20px' }}
                                              >
                                                <a
                                                  class="dropdown-toggle nav-link dropdown-user-link custom-drop-toggle"
                                                  href="#"
                                                  data-toggle="dropdown"
                                                >
                                                  <span>
                                                    <i
                                                      class="fa fa-ellipsis-h"
                                                      height="40"
                                                      width="40"
                                                    />
                                                  </span>
                                                </a>
                                                <div class="dropdown-menu dropdown-menu-right">
                                                  <Link
                                                    to={
                                                      '/viewproduct/' +
                                                      product.id
                                                    }
                                                    class="dropdown-item"
                                                  >
                                                    View
                                                  </Link>
                                                  {/* <Link to="/productlist" class="dropdown-item">
                                                  View Page Builder
                                              </Link> */}
                                                  <Link
                                                    to={
                                                      '/editproduct/' +
                                                      product.id
                                                    }
                                                    class="dropdown-item"
                                                  >
                                                    Edit
                                                  </Link>
                                                  <button
                                                    class="dropdown-item"
                                                    onClick={() =>
                                                      this.modelToggle3(
                                                        product.id,
                                                        index
                                                      )
                                                    }
                                                    style={{ width: '100%' }}
                                                  >
                                                    Copy
                                                  </button>
                                                  <Link
                                                    to={
                                                      '/orderlist/' + product.id
                                                    }
                                                    class="dropdown-item"
                                                  >
                                                    View Order
                                                  </Link>
                                                </div>
                                              </li>
                                            </div>
                                          </td>
                                        </tr></>)}
                                      
                                    )
                                  ) : (
                                    <tr>
                                      <td colSpan={6}>No Product Available.</td>
                                    </tr>
                                  )}
                                </tbody>
                              </Table>
                            </>
                          ) : this.state.listView === '2' ? (
                            <div class="row grid-view">
                              {this.state.totalCount ? (
                                this.state.gridData
                              ) : (
                                <tr>
                                  <td colSpan={6}>No Product Available.</td>
                                </tr>
                              )}
                            </div>
                          ) : null}
                          {this.state.totalCount ? (
                            <ReactPaginate
                              previousLabel={'prev'}
                              nextLabel={'next'}
                              breakLabel={'...'}
                              breakClassName={'break-me'}
                              pageCount={this.state.pageCount}
                              marginPagesDisplayed={2}
                              pageRangeDisplayed={5}
                              onPageChange={this.handlePageClick}
                              containerClassName={'pagination'}
                              subContainerClassName={'pages pagination'}
                              activeClassName={'active'}
                            />
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
        <div class="sidenav-overlay"></div>
        <div class="drag-target"></div>

        <Footer />
      </body>
    );
  }
}

export default AddProduct;
