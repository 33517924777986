  import React from "react";
import { BrowserRouter } from "react-router-dom";
//import Navigation from "./Navigation.js";
// import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
// import "./App.css";
// import Dashboard from "./Seller/Container/Dashboard/Dashbard";
// import AddProduct from "./Seller/Container/AddProduct/AddProduct";
// import Login from "./Seller/Container/Login/Login";
// import ForgotPassword from "./Seller/Container/ForgotPassword/ForgotPassword";
// import ResetPassword from "./Seller/Container/ResetPassword/ResetPassword";
// import Register from "./Seller/Container/Register/Register";
// import Demo from "./Seller/Container/Dashboard/Demo";
// import SetOTP from "./Seller/Container/SetOTP/SetOTP";
// import ProductList from "./Seller/Container/ProductList/ProductList";
// import EditProduct from "./Seller/Container/EditProduct/EditProduct";
// import SellerDashboard from "./Seller/Container/SellerDashboard/Dashboard";
// import EditProfile from "./Seller/Container/EditProfile/EditProfile";
// import SoldProduct from "./Seller/Container/SoldProduct/SoldProductList";
// import ViewedProduct from "./Seller/Container/ViewedProduct/ViewedProduct";
// import AddOffer from "./Seller/Container/AddOffer/AddOffer";
// import EditOffer from "./Seller/Container/EditOffer/EditOffer";
// import OfferList from "./Seller/Container/OfferList/OfferList";
// import ViewProduct from "./Seller/Container/ViewProduct/ViewProduct";
// import OrderList from "./Seller/Container/OrderList/OrderList";
// import ViewOrder from "./Seller/Container/ViewOrder/ViewOrder";
// import FollowList from "./Seller/Container/FollowUserList/FollowList";
// import Subscription from "./Seller/Container/Subscription/Subscription";
// import FollowerList from "./Seller/Container/FollowerList/FollowerList";
// import NotFound404 from "./Seller/404/404"
// import SellerForm from "./Seller/Container/SellerForum/SellerForum"
// import AddFAQ from "./Seller/Container/AddFAQ/AddFAQ"
// import Address from "./Seller/Container/TaxModel/Address"
// import PaymentMethod from "./Seller/Container/TaxModel/PaymentMethod"
// import TaxForm from "./Seller/Container/TaxModel/TaxForm"
// import Forum from "./Seller/Container/Forum/Forum"
// import AddBanner from "./Seller/Container/AddBanner/AddBanner"
// import ViewBanner from "./Seller/Container/AddBanner/ViewBanner"
// import PayPalBtn from "./Seller/Container/AddBanner/PaypalBtn"
// import Credit from "./Seller/Container/CreditDebit/Credit"
// import Done from './Seller/Container/AddBanner/Done'
// import BannerList from './Seller/Container/AddBanner/BannerList'
// import AddPayperClickBanner from "./Seller/Container/PayPerClick/AddBanner"
// import PayperClickBannerList from "./Seller/Container/PayPerClick/BannerList"

// const routes = [

//   // { path: "/credit", exact: true, component: Credit },

//   // { path: "/paypal", exact: true, component: PayPalBtn },
//   { path: "/dashboard", exact: true, component: SellerDashboard },
//   { path: "/login", exact: true, component: Login },
//   { path: "/editprofile", exact: true, component: EditProfile },
//   { path: "/userlist", exact: true, component: FollowList },
//   { path: "/followerlist", exact: true, component: FollowerList },

//   { path: "/addproduct", exact: true, component: AddProduct },
//   { path: "/productlist", exact: true, component: ProductList },
//   { path: "/editproduct/:id", exact: true, component: EditProduct },
//   { path: "/viewproduct/:id", exact: true, component: ViewProduct },

//   { path: "/addoffer", exact: true, component: AddOffer },
//   { path: "/editoffer/:id", exact: true, component: EditOffer },
//   { path: "/offerlist", exact: true, component: OfferList },

//   { path: "/soldproduct", exact: true, component: SoldProduct },
//   { path: "/viewedproduct", exact: true, component: ViewedProduct },
//   { path: "/subscription", exact: true, component: Subscription },

//   { path: "/orderlist", exact: true, component: OrderList },
//   { path: "/vieworder/:id", exact: true, component: ViewOrder },

//   { path: "/sellerforum", exact: true, component: Forum },
//   { path: "/addfaq", exact: true, component: AddFAQ },
//   { path: "/address", exact: true, component: Address },
//   { path: "/paymentmethod", exact: true, component: PaymentMethod },

//   { path: "/taxform", exact: true, component: TaxForm },

//   { path: "/forum", exact: true, component: Forum },

//   { path: "/addbanner", exact: true, component: AddBanner },
//   { path: "/viewbanner/:id", exact: true, component: ViewBanner },
//   { path: "/paymentdone", exact: true, component: Done },
//   { path: "/bannerlist", exact: true, component: BannerList },

//   { path: "/addpayperclickbanner", exact: true, component: AddPayperClickBanner },
//   { path: "/payperclickbannerlist", exact: true, component: PayperClickBannerList },

// ];
// import Header from "./Seller/Component/Header/Header";
// import Footer from "./Seller/Component/Footer/Footer";
// import Sidebar from "./Seller/Component/Sidebar/Sidebar";
// import AfterLogin from "./AfterLoginWrapper"
import BeforeLogin from './BeforeLoginWrapper'
//import BaseRoute from "./BaseRouter"
//var location = "";

//const excludeSidebarAndFooter = ["login", "register"]
class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      seller: "",
      location: ""
    };
    if (localStorage.getItem("sellerdata")) {
      this.state = {
        seller: JSON.parse(localStorage.getItem("sellerdata"))
      };
      console.log("Constructor");
    }
  }
  componentWillMount() {
    if (localStorage.getItem("sellerdata")) {
      this.setState({ seller: JSON.parse(localStorage.getItem("sellerdata")) });
    }
    console.log("Component Will Mount",this.props);
  }
  componentDidMount() {
    let location1 = window.location.href;
    let newLocation = location1.split("#");
    if (newLocation.length) {
  //    location = newLocation[1];
      console.log(newLocation[1]);
      this.setState({ location: newLocation[1] });
    }
    this.setState({ location: "" });
    console.log("Component Did Mount");
  }

  render() {
    
    return (
      <BrowserRouter basename="#">
        {/* <body
          class="vertical-layout vertical-menu-modern semi-dark-layout 2-columns  navbar-floating footer-static  "
          data-open="click"
          data-menu="vertical-menu-modern"
          data-col="2-columns"
          data-layout="semi-dark-layout"
        > */}
       
        <BeforeLogin/>
         {/* <AfterLogin /> */}
              
        {/* </body> */}
      </BrowserRouter>
    );
  }
}

export default App;
