import React from "react";
import Image from "./ss.png";

export default class donePayment extends React.Component {

  componentWillMount(){
   this.setState({a:""})

 }

  render() {
    return (
      <div class="done-div">
        <img src={Image} alt=".." className="done-image"></img>
        <h1>Thank You</h1>
        <h3 style={{ color: "green" ,margin: "25px"}}>Your Payment Done Successfully</h3>
        <button onClick={this.props.history.goBack} class="btn btn-primary login-btn" data-dismiss="modal">
          Back</button>
      </div>
    );
  }
}
// import React from "react";
// import Webcam from "react-webcam";
// export default class WebcamComponent extends React.Component {
//   render() {
//     return <Webcam />;
//   }
// }
