import React from 'react';
import axios from 'axios';
import 'react-image-lightbox/style.css';
import Avtar from '../images/avtar.jpg';
import { toast } from 'react-toastify';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
  Button,
} from 'reactstrap';
import { Link } from 'react-router-dom';
import Footer from '../../Component/Footer/Footer';
import global from '../../global';
import confirm from "reactstrap-confirm";
import moment from 'moment';
import './style.css';
let commentError = '';
const $ = require('jquery');
$.DataTable = require('datatables.net');
var submitDisable = false;
toast.configure();
// const CustomToast = () => {
//   return (
//     <div>
//       <i class="fa fa-check success-toast"></i>
//     Offer Updated Successfully
//     </div>
//   )
// }

class Question extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: this.props.match.params.id,
      activeTab: '1',
      topicId1:'',
      showLogOut: false,
      showLogOut2: false,
      topics: [],
      topics2: [],
      reply: [],
      messageto:'',
      comment: '',
      seller: {},
      updateComment: '',
      commentId: '',
      commentId1:'',
      serch:'',
    };
    commentError = '';
  }
  componentWillMount() {
    this.getAPICall();
  }
  getAPICall() {
    if (localStorage.getItem('sellerdata') !== '') {
      let seller = JSON.parse(localStorage.getItem('sellerdata'));
      this.setState({ seller: JSON.parse(localStorage.getItem('sellerdata')) });
      let urlencoded = new URLSearchParams();
      urlencoded.append('UserID', seller.id);
      axios
        .post(global.baseUrl + 'askQuestionsellerList', urlencoded, {
          headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        })
        .then((result) => {
          console.log(result.data, '22');
          if (result.data) {
            this.setState({
              topics: result.data.data,
              topics2: result.data.data,
              // reply: result.data.data.comment,
            });
          }
        });
    }
  }
  toggle = (tab) => {
    if (tab === '1') {
      this.setState({ activeTab: '1' });
    } else if (tab === '2') {
      this.setState({ activeTab: '2' });
    } else if (tab === '3') {
      this.setState({ activeTab: '3' });
    } else if (tab === '4') {
      this.setState({ activeTab: '4' });
    } else if (tab === '5') {
      this.setState({ activeTab: '5' });
    } else if (tab === '6') {
      this.setState({ activeTab: '6' });
    } else if (tab === '7') {
      this.setState({ activeTab: '7' });
    } else if (tab === '8') {
      this.setState({ activeTab: '8' });
    }
  };
  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };
  handleSubmit = (typeId) => {
    if (this.state.comment) {
      if (this.state.comment.trim() !== '') {
        var urlencoded = new URLSearchParams();
        // console.log(typeId, '52');
        // urlencoded.append('', this.state.seller.id);
        urlencoded.append('id', typeId);
        urlencoded.append('answer', this.state.comment.trim());
        

        axios
          .post(global.baseUrl + 'answerquestion', urlencoded, {
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
          })
          .then((result) => {
            console.log(result.data);
            if (result.data) {
              this.setState({
                comment: '',
              });
              //this.props.history.push("/sellerforum");
              this.getAPICall();
            }
          });
      }
    }
  };

  deleteHandler = async(commentId) => {
    // var r = await confirm({message:'Are you sure you want to delete the comment?'});
    this.setState({messageto:'Are you sure you want to delete the comment?',commentId1:commentId,showLogOut:true})
    // if (r) {
    //   let urlencoded = new URLSearchParams();
    //   urlencoded.append('UserID', this.state.seller.id);
    //   urlencoded.append('id', commentId);
    //   axios
    //     .post(global.baseUrl + 'forumCommentDelete', urlencoded, {
    //       headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    //     })
    //     .then((result) => {
    //       console.log(result.data, 'amount');
    //       // if (result.data.status) {
    //       //   comment: this.state.comment.filter(comment => comment.id !== commentId)
    //       // }
    //       this.getAPICall();
    //     });
    //   //this.dataTable();
    // }
  };

  deleteHandler1=()=>{
    let urlencoded = new URLSearchParams();
    urlencoded.append('UserID', this.state.seller.id);
    urlencoded.append('id', this.state.commentId1);
    axios
      .post(global.baseUrl + 'forumCommentDelete', urlencoded, {
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      })
      .then((result) => {
        console.log(result.data, 'amount');
        // if (result.data.status) {
        //   comment: this.state.comment.filter(comment => comment.id !== commentId)
        // }
        this.getAPICall();
      });
  }
  updateAPICall = () => {
    var urlencoded = new URLSearchParams();
    urlencoded.append('userId', this.state.seller.id);
    urlencoded.append('id', this.state.commentId);
    urlencoded.append('comment', this.state.updateComment);

    axios
      .post(global.baseUrl + 'forumCommentUpdate', urlencoded, {
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      })
      .then((result) => {
        console.log(result.data);
        if (result.data.status) {
          this.setState({
            commentId: '',
            updateComment: '',
          });
          this.getAPICall();
          // this.props.history.push("/");
        }
      });
  };
  EditIconHandler = (commentId, comment) => {
    this.setState({
      commentId: commentId,
      updateComment: comment,
    });
  };
  deleteTopicHandler =async (topicId, CategoryImage) => {
    // var r = await confirm({message:'Are you sure to delete the topic?'});
    this.setState({messageto:'Are you sure to delete the topic?',topicId1:topicId,showLogOut2:true})
    // if (r) {
    //   let urlencoded = new URLSearchParams();
    //   // urlencoded.append('topic', this.state.topics.topic)
    //   urlencoded.append('id', topicId);
    //   axios
    //     .post(global.baseUrl + 'adminTopicDelete', urlencoded, {
    //       headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    //     })
    //     .then((result) => {
    //       console.log(result.data);
    //       this.setState({
    //         categories: this.state.topics.filter(
    //           (topic) => topic.id !== topicId
    //         ),
    //       });
    //       // this.listAPI()
    //       window.location.reload();
    //       // this.deleteCommentHandler()
    //     });
    //   // this.dataTable();
    //   ///profile delete
    //   // this.deleteImage(CategoryImage);
    // }
  };

  setSerch=(e)=>{
    this.setState({serch:e.target.value})
    let filterdata=[]
    if(!e.target.value||e.target.value===''){
      filterdata=this.state.topics2;
    }else{
    filterdata=this.state.topics2.filter((item)=>
      item.productId.product_name.includes(e.target.value)
    )
    }
    // console.log(filterdata,"pdpdpdpdpddp")
    this.setState({
      topics: filterdata,
      // reply: result.data.data.comment,
    });
  }
  deleteTopicHandler2=()=>{
    let urlencoded = new URLSearchParams();
    // urlencoded.append('topic', this.state.topics.topic)
    urlencoded.append('id', this.state.topicId1);
    axios
      .post(global.baseUrl + 'adminTopicDelete', urlencoded, {
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      })
      .then((result) => {
        console.log(result.data);
        this.setState({
          categories: this.state.topics.filter(
            (topic) => topic.id !== this.state.topicId1
          ),
        });
        // this.listAPI()
        window.location.reload();
        // this.deleteCommentHandler()
      });
  }

  render() {
    return (
      <body
        class="vertical-layout vertical-menu-modern semi-dark-layout 2-columns  navbar-floating footer-static  "
        data-open="click"
        data-menu="vertical-menu-modern"
        data-col="2-columns"
        data-layout="semi-dark-layout"
      >
        {/* <Header />
        <Sidebar /> */}

        <div class="app-content content">
          <div class="content-overlay"></div>
          <div class="header-navbar-shadow"></div>
          <div class="content-wrapper" style={{ minHeight: '79vh' }}>
            <div class="content-header row">
              <div class="content-header-left col-md-9 col-12 mb-2">
                <div class="row breadcrumbs-top">
                  <div class="col-12">
                    <h2 class="content-header-title float-left mb-0">
                      CUSTOMER INQUIRY{' '}
                    </h2>
                    <Modal isOpen={this.state.showLogOut} fade="false"  toggle={() => this.setState({showLogOut: false})}>
<ModalHeader toggle={() => this.setState({showLogOut: false})}>Warning</ModalHeader>
    <ModalBody>   
        <p>{this.state.messageto}</p>
    </ModalBody>
<ModalFooter>

    <Button onClick={() => this.setState({showLogOut: false})}>Cancel</Button>
    <Button color="info" onClick={()=>{this.setState({showLogOut: false});this.deleteHandler1();}}>Ok</Button>
</ModalFooter>
</Modal>
<Modal isOpen={this.state.showLogOut2} fade="false"  toggle={() => this.setState({showLogOut2: false})}>
<ModalHeader toggle={() => this.setState({showLogOut2: false})}>Warning</ModalHeader>
    <ModalBody>   
        <p>{this.state.messageto}</p>                     
    </ModalBody>
<ModalFooter>

    <Button onClick={() => this.setState({showLogOut2: false})}>Cancel</Button>
    <Button color="info" onClick={()=>{this.setState({showLogOut2: false});this.deleteTopicHandler2();}}>Ok</Button>
</ModalFooter>
</Modal>
                    <div class="breadcrumb-wrapper col-12">
                      <ol class="breadcrumb">
                        <li class="breadcrumb-item">
                          <Link to="/">Home</Link>
                        </li>
                        <li class="breadcrumb-item active">Question</li>
                      </ol>
                    </div>
                  </div>
                  <div class="form-label-group position-relative has-icon-left serchquestion">
                      <input type="text" id="first-name-floating-icon" class="form-control" name="searchProduct" placeholder="Search" value={this.state.serch} onChange={(e)=>this.setSerch(e)}></input>
                      <div class="form-control-position"><i class="fa fa-search"></i></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="content-body">
              <section class="users-edit">
                <div>
                  {/* <div class="form-group">
                    <Link to="/forum/addForumtopic">
                      <Button
                        type="submit"
                        size="sm"
                        color=""
                        className="additem add-widths"
                      >
                        <i className="fas fa-plus-circle mr-1" />
                        Add Topic
                      </Button>
                    </Link>
                  </div> */}
                  <div class="bg-transparent border-0 shadow-none collapse-icon set accordion-icon-rotate">
                    <div class="col-xl-12 col-md-12 col-12">
                      <div
                        className="accordion search-content-info acc-cust"
                        style={{
                          backgroundColor: '#fff',
                          border: '1px solid #c8ced3',
                          borderradius: '0.25rem',
                        }}
                        id="accordionExample"
                      >
                        {/* {this.state.topics.map((topic, index) => ( */}
                        {this.state.topics.length ? (
                          this.state.topics.map((topic, index) => (
                            <div class="card collapse-margin search-content mt-0 ">
                              <div
                                class="card-header"
                                id={'heading' + index}
                                role="button"
                                data-toggle="collapse"
                                data-target={'#collapse' + index}
                                aria-expanded="false"
                                aria-controls={'collapse' + index}
                                style={{
                                  padding: '0.75rem 1.25rem',
                                  marginBottom: '0',
                                  backgroundColor: '#f0f3f5',
                                  borderBottom: '1px solid #c8ced3',
                                  marginBottom: '1rem',
                                }}
                              >
                                <div class="row">
                                  <span class="col-lg-9 col-md-8 col-12 lead collapse-title topic-header forum-width">
                                    {topic.question}
                                  </span>

                                  <div
                                    className="col-lg-3 col-md-4 col-12 total-color total-comment edit-space-forums"
                                    style={{
                                      paddingRight: '0',
                                      textAlign: 'left',
                                      marginRight: '0',
                                    }}
                                  >
                                   <div style={{ marginLeft: '70px' }}>
                                        {/* <i className="fa fa-trash text-danger mr-2" onClick={() => this.deleteTopicHandler(topic.id, topic.image)}
                                    // style={{ marginRight: '10px', lineHeight: "20px" }}
                                  ></i>

                                  <Link to={`/forum/editForumtopic/${topic.id}`} >
                                    <i className="fa fa-pencil mr-2 text-success"
                                      // style={{ marginRight: '10px' }}
                                  ></i></Link> */}
                                      </div>
                                    {/* <div>
                                    <i className="fa fa-trash text-danger mr-2" onClick={() => this.deleteTopicHandler(topic.id, topic.image)}
                                    // style={{ marginRight: '10px', lineHeight: "20px" }}
                                  ></i>

                                  <Link to={`/forum/editForumtopic/${topic.id}`} >
                                    <i className="fa fa-pencil mr-2 text-success"
                                      // style={{ marginRight: '10px' }}
                                  ></i></Link>
                                  </div> */}
                                    {/* <div>
                                      Total Comment : <b>{topic.totalCount}</b>
                                    </div> */}
                                  </div>
                                </div>
                              </div>
                              <div
                                id={'collapse' + index}
                                class="collapse"
                                aria-labelledby={'heading' + index}
                                data-parent="#accordionExample"
                              >
                                <div class="card-body col-md-12">
                                  <div className="col-12">
                                    <p
                                      style={{
                                        background: 'rgb(240, 243, 245)',
                                        padding: '5px 15px',
                                        border: '1px solid rgb(221, 221, 221)',
                                        fontWeight: 'bold',
                                      }}
                                    >
                                    {topic.additional_details}
                                    </p>
                                    <div className="div-4">
                                    <div className="comm-user">
                                    {topic.answer&&(<>
                                      <div>
                                          {this.state.seller ? (
                                            this.state.seller.profile_image ? (
                                              <img
                                                class="media-object rounded-circle comment-seller-icon"
                                                src={
                                                  this.state.seller
                                                    .profile_image
                                                }
                                                height="32"
                                                width="32"
                                                alt="..."
                                              />
                                            ) : (
                                              <img
                                                class="media-object rounded-circle comment-seller-icon"
                                                src={Avtar}
                                                height="32"
                                                width="32"
                                                alt="..."
                                              />
                                            )
                                          ) : (
                                            <img
                                              class="media-object rounded-circle comment-seller-icon"
                                              src={Avtar}
                                              height="32"
                                              width="32"
                                              alt="..."
                                            />
                                          )}
                                        </div>

                                              <div
                                                class="comment-ans chat-flexs"
                                                style={{
                                                  marginleft: '49px',
                                                  marginbottom: '20px',
                                                  background: '#ececec',
                                                  padding: '10px',
                                                  borderradius: '10px',
                                                  borderRadius: '14px',
                                                  display: 'flex',
                                                }}
                                              >
                                                <p className="mb-0 mr-2">
                                                  {topic.answer?topic.answer:''}
                                                </p>
                                              </div>
                                   </> )}
                                            </div>
                                    </div>
                                    <div class="row">
                                      <div class="col-12 comment-user">
                                        <div>
                                          {this.state.seller ? (
                                            this.state.seller.profile_image ? (
                                              <img
                                                class="media-object rounded-circle comment-seller-icon"
                                                src={
                                                  this.state.seller
                                                    .profile_image
                                                }
                                                height="32"
                                                width="32"
                                                alt="..."
                                              />
                                            ) : (
                                              <img
                                                class="media-object rounded-circle comment-seller-icon"
                                                src={Avtar}
                                                height="32"
                                                width="32"
                                                alt="..."
                                              />
                                            )
                                          ) : (
                                            <img
                                              class="media-object rounded-circle comment-seller-icon"
                                              src={Avtar}
                                              height="32"
                                              width="32"
                                              alt="..."
                                            />
                                          )}
                                        </div>

                                        <div className="col-10 comment-name-box">
                                          <input
                                            type="text"
                                            class="form-control"
                                            placeholder="Add Comment.."
                                            name="comment"
                                            value={this.state.comment}
                                            onChange={this.handleChange}
                                            onKeyPress={(event) => {
                                              if (event.key === 'Enter') {
                                                this.handleSubmit(topic.id);
                                              }
                                            }}
                                          />
                                        </div>
                                        <div className="col-2 comment-name-box">
                                          <button
                                            className="btn btn-primary-green cmt-btn w-100"
                                            type="button"
                                            onClick={() =>
                                              this.handleSubmit(topic.id)
                                            }
                                          >
                                            <span>Comment</span>
                                            <i class="fas fa-comment"></i>
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                    <br></br>

                                    <div class="row">
                                      <div class="col-12 comment-user">
                                        {this.state.updateComment ? (
                                          <>
                                            <div>
                                              {this.state.seller ? (
                                                this.state.seller
                                                  .profile_image ? (
                                                  <img
                                                    class="media-object rounded-circle comment-seller-icon"
                                                    src={
                                                      this.state.seller
                                                        .profile_image
                                                    }
                                                    height="32"
                                                    width="32"
                                                    alt="..."
                                                  />
                                                ) : (
                                                  <img
                                                    class="media-object rounded-circle comment-seller-icon"
                                                    src={Avtar}
                                                    height="32"
                                                    width="32"
                                                    alt="..."
                                                  />
                                                )
                                              ) : (
                                                <img
                                                  class="media-object rounded-circle comment-seller-icon"
                                                  src={Avtar}
                                                  height="32"
                                                  width="32"
                                                  alt="..."
                                                />
                                              )}
                                            </div>

                                            <div className="col-10 col-md-9 comment-name-box">
                                              <input
                                                type="text"
                                                class="form-control"
                                                placeholder="edit Comment.."
                                                name="updateComment"
                                                value={this.state.updateComment}
                                                onChange={this.handleChange}
                                                onKeyPress={(event) => {
                                                  if (event.key === 'Enter') {
                                                    this.updateAPICall();
                                                  }
                                                }}
                                              />
                                            </div>
                                            <div className="col-2 col-md-3 comment-name-box">
                                              <button
                                                className="btn btn-primary-green cmt-btn w-100"
                                                type="button"
                                                onClick={() =>
                                                  this.updateAPICall()
                                                }
                                              >
                                                Edit Comment
                                              </button>
                                            </div>
                                          </>
                                        ) : null}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))
                        ) : (
                          <h5>No Question Available.</h5>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>

        <div class="sidenav-overlay"></div>
        <div class="drag-target"></div>
        <Footer />
      </body>
    );
  }
}
export default Question;