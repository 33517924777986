import React from "react";
import axios from "axios";
import { Link } from "react-router-dom";
// import Sidebar from "../../Component/Sidebar/Sidebar";
// import Header from "../../Component/Header/Header";
import Footer from "../../Component/Footer/Footer";
import './Subscription.css'
import global from "../../global";
var seller = {};
class Subscription extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      product: 0,
      subscribed: 0,
      soldProduct: 0,
      totalView: 0,
      totalVisit: 0,
      totalOrder: 0,
      totalRevanue: 0
    };
  }
  componentWillMount() {
    if (localStorage.getItem("sellerdata")) {
      seller = JSON.parse(localStorage.getItem("sellerdata"));
      this.setState({ seller: JSON.parse(localStorage.getItem("sellerdata")) });
      this.dashboardAPI(seller.id);
    }
    console.log(this.state.seller);
  }
  dashboardAPI(Id) {
    var urlencoded = new URLSearchParams();
    urlencoded.append("userId", Id);
    axios
      .post(global.baseUrl + "sellerdashboard", urlencoded, {
        headers: { "Content-Type": "application/x-www-form-urlencoded" }
      })
      .then(result => {
        console.log(result.data);
        if (result.data.status) {
          this.setState({
            product: result.data.data.Product,
            subscribed: result.data.data.Subscribed,
            soldProduct: result.data.data.Soldproduct,
            totalView: result.data.data.Total_view,
            totalVisit: result.data.data.Total_visit,
            totalOrder: result.data.data.Total_order,
            totalRevanue: result.data.data.Total_revenue_generated
          });
        }
      });
  }
  render() {
    return (
      <body
        class="vertical-layout vertical-menu-modern semi-dark-layout 2-columns  navbar-floating footer-static  "
        data-open="click"
        data-menu="vertical-menu-modern"
        data-col="2-columns"
        data-layout="semi-dark-layout"
      >
        {/* <Header />
        <Sidebar /> */}
        <div class="app-content content">
          <div class="content-overlay"></div>
          <div class="header-navbar-shadow"></div>
          <div class="content-wrapper">
            <div class="content-header row">
              <div class="content-header-left col-md-9 col-12 mb-2">
                <div class="row breadcrumbs-top">
                  <div class="col-12">
                    <h2 class="content-header-title float-left mb-0">
                      Subscription
                    </h2>
                    <div class="breadcrumb-wrapper col-12">
                      <ol class="breadcrumb">
                        <li class="breadcrumb-item">
                          <Link to="/dashboard">Home</Link>
                        </li>
                        <li class="breadcrumb-item active">Subscription</li>
                      </ol>
                    </div>
                    {/* <div class="breadcrumb-wrapper col-12">
                      <ol class="breadcrumb">
                        <li class="breadcrumb-item">
                          <a href="index.html">Dashboard</a>
                        </li>
                         <li class="breadcrumb-item active">Dashboard</li> 
                      </ol>
                    </div> */}
                  </div>
                </div>
              </div>
              <div class="content-header-right text-md-right col-md-3 col-12 d-md-block d-none"></div>
            </div>
            <div class="content-body">
              <section id="statistics-card">
                <div class="row">
                  <div class="col-lg-4 col-md-6 col-sm-12">
                    <div class="card text-white bg-gradient-danger text-center sub-d">
                      <div class="card-content d-flex">
                        <div class="card-body">
                          <h4 class="card-title text-white mt-3">
                            Brand Minute
                          </h4>
                          <p class="card-text">Donut toffee candy brownie.</p>
                          <button class="btn btn-danger btn-darken-3">
                            Buy Now
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-4 col-md-6 col-sm-12">
                    <div class="card text-white bg-gradient-danger text-center sub-d">
                      <div class="card-content d-flex">
                        <div class="card-body">
                          <h4 class="card-title text-white mt-3">
                            Brand Minute
                          </h4>
                          <p class="card-text">Donut toffee candy brownie.</p>
                          <button class="btn btn-danger btn-darken-3">
                            Buy Now
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-4 col-md-6 col-sm-12">
                    <div class="card text-white bg-gradient-danger text-center sub-d">
                      <div class="card-content d-flex">
                        <div class="card-body">
                          <h4 class="card-title text-white mt-3">
                            Brand Minute
                          </h4>
                          <p class="card-text">Donut toffee candy brownie.</p>
                          <button class="btn btn-danger btn-darken-3">
                            Buy Now
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-4 col-md-6 col-sm-12">
                    <div class="card text-white bg-gradient-danger text-center sub-d">
                      <div class="card-content d-flex">
                        <div class="card-body">
                          <h4 class="card-title text-white mt-3">
                            Brand Minute
                          </h4>
                          <p class="card-text">Donut toffee candy brownie.</p>
                          <button class="btn btn-danger btn-darken-3">
                            Buy Now
                          </button>
                        </div>
                      </div>
                    </div>
                  </div><div class="col-lg-4 col-md-6 col-sm-12">
                    <div class="card text-white bg-gradient-danger text-center sub-d">
                      <div class="card-content d-flex">
                        <div class="card-body">
                          <h4 class="card-title text-white mt-3">
                            Brand Minute
                          </h4>
                          <p class="card-text">Donut toffee candy brownie.</p>
                          <button class="btn btn-danger btn-darken-3">
                            Buy Now
                          </button>
                        </div>
                      </div>
                    </div>
                  </div><div class="col-lg-4 col-md-6 col-sm-12">
                    <div class="card text-white bg-gradient-danger text-center sub-d">
                      <div class="card-content d-flex">
                        <div class="card-body">
                          <h4 class="card-title text-white mt-3">
                            Brand Minute
                          </h4>
                          <p class="card-text">Donut toffee candy brownie.</p>
                          <button class="btn btn-danger btn-darken-3">
                            Buy Now
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>

        <div class="sidenav-overlay"></div>
        <div class="drag-target"></div>

        <Footer />
      </body>
    );
  }
}
export default Subscription;
