import React from 'react'
import {Link} from 'react-router-dom'
class Footer extends React.Component{
    render(){
      return(
        <footer class="footer footer-static footer-light foot">
        <p class="clearfix blue-grey lighten-2 mb-0">
          <span class="float-md-left d-block d-md-inline-block mt-25">
            COPYRIGHT &copy; 2020
            <Link to="/dashboard"
              class="text-bold-800 grey darken-2"
              
            >
              Garage Lane,
              </Link>
            All rights Reserved
          </span>
          <button class="btn btn-primary btn-icon scroll-top" type="button">
            <i class="feather icon-arrow-up"></i>
          </button>
        </p>
      </footer>
      )  
    }
}
export default Footer