import React, { Profiler } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import 'react-toastify/dist/ReactToastify.css';
// import '../style.css'
// import '../../../assets/css/style.css';
// import '../../assets/colors/blue.css'
// import '../../../assets/css/bootstrap.min.css'

// import '../../../assets/css/font-awesome.min.css'
// import '../../../assets/css/themify-icons.css'
// import '../../../assets/css/elegant.css'
// // import '../../../assets/css/jquery.mmenu.css'
// // import '../../../assets/css/jquery-ui.min.css'
// import '../../../assets/css/venobox.css'
// import '../../../assets/css/slick.css'

// import '../../../assets/css/slick-theme.css'
// import '../../../assets/css/cssanimation.min.css'
// import '../../assets/css/animate.css'
// import '../../../assets/css/helper.css'
// import '../../../assets/css/responsive.css'
import global from '../../global';
import './sellerprofile.css';
import Button from 'reactstrap/lib/Button';
// import Footer from "../../Component/Footer/Footer";
// import Header from "../../Component/Header/Header";
var seller = {};

var Gender = '';
var email = '';
var mobileNumber = '';
var firstName = '';
var lastName = '';
var streetAddress = '';
var zipCode = '';
var name = '';
var profileImage = '';
var imageError = '';
var sendImage = '';

class SellerProfile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      seller: '',
      UserID: '',
      Gender: '',
      email: '',
      mobileNumber: '',
      firstName: '',
      lastName: '',
      streetAddress: '',
      zipCode: '',
      name: '',
      userId: '',
      profileImage: '',
      allsellerdata: [],
      Followers: [],
      name: '',
      userName: '',

      bio: '',
      gender: '',
      companyName: '',
      contactDetail: '',
      yearOfEstablish: '',
      StreetAddress: '',
      seller: JSON.parse(localStorage.getItem('sellerdata')),
      users: [],
    };
    imageError = '';
    sendImage = '';
    seller = {};
  }
  componentWillMount() {
    this.sellerDetailAPI();
    this.profileAPI();
  }
  sellerDetailAPI() {
    console.log('seller list');
    if (localStorage.getItem('sellerdata')) {
      this.setState({ seller: JSON.parse(localStorage.getItem('sellerdata')) });
      seller = JSON.parse(localStorage.getItem('sellerdata'));
    }

    let urlencoded = new URLSearchParams();
    urlencoded.append('sellerId', seller.id);
    axios
      .post(global.baseUrl + 'SellerWiseProduct', urlencoded, {
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      })
      .then((result) => {
        console.log(result.data, 'result seller wise');
        if (result.data) {
          this.setState({
            allsellerdata: result.data.data,
          });
        }
      });
  }

  AddFollowerAPICall = (followerID) => {
    console.log('follow');
    var urlencoded = new URLSearchParams();
    urlencoded.append('UserID', seller.id);
    urlencoded.append('followerId', followerID);

    console.log(seller.id, 'seller', followerID, 'follow');
    axios
      .post(global.baseUrl + 'addfollower', urlencoded, {
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      })
      .then((result) => {
        if (result.data) {
          console.log('resultsuccess', result.data);
          this.setState({
            Followers: result.data.data,
          });
        }
      });

    // window.location.reload()
  };

  profileAPI() {
    var urlencoded = new URLSearchParams();
    urlencoded.append('userId', seller.id);
    axios
      .post(global.baseUrl + 'selleridlist', urlencoded, {
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      })
      .then((result) => {
        console.log(result.data, 'user data');
        if (result.data.data[0]) {
          this.setState({
            userId: result.data.data[0].id,
            name: result.data.data[0].name,
            email: result.data.data[0].email,
            mobileNumber: result.data.data[0].phone_number,
            profileImage: result.data.data[0].profile_image,
          });
          if (result.data.data[0].company_name) {
            this.setState({
              userName: result.data.data[0].user_name,
              bio: result.data.data[0].bio,
              gender: result.data.data[0].gender,
              companyName: result.data.data[0].company_name,
              yearOfEstablish: result.data.data[0].year_of_establish,
              StreetAddress: result.data.data[0].street_address,
              contactDetail: result.data.data[0].company_contact,
            });
          }
          this.setState({
            users: result.data.data,
          });
        }
      });
  }

  onImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      var Extension = ['jpg', 'jpeg', 'png', 'gif', 'tiff'];
      var extArray = event.target.files[0].name.split('.');
      var ext = extArray[extArray.length - 1];
      var flag = 0;
      for (let i = 0; i < Extension.length; i++) {
        if (ext.toLowerCase() === Extension[i]) {
          flag = 1;
        }
      }
      if (flag) {
        this.setState({ imageError: '' });
        imageError = '';

        let reader = new FileReader();
        reader.onload = (e) => {
          this.setState({ profileImage: e.target.result });
        };
        reader.readAsDataURL(event.target.files[0]);
        sendImage = event.target.files[0];
        console.log('image', sendImage);
        this.uplodeimage();
      } else {
        this.setState({
          profileImage: '',
          imageError: 'Please select only image',
        });
        sendImage = '';
        imageError = 'Please select only image';
      }
      //   this.uplodeimage();
    }
  };
  uplodeimage = async () => {
    if (sendImage) {
      const apiUrl = global.baseUrl + 'sellerImageUpload';
      const formData = new FormData();
      formData.append('myFile', sendImage);
      const config = {
        headers: {
          'content-type': 'multipart/form-data',
        },
      };
      axios
        .post(apiUrl, formData, config)
        .then((response) => {
          console.log('image add', response.data);
          this.setState({ profileImage: response.data.path });
          this.registerApiCall();
        })
        .catch((error) => { });
    } else {
      this.registerApiCall();
    }
  };
  registerApiCall = () => {
    var urlencoded = new URLSearchParams();

    urlencoded.append('userId', this.state.userId);
    urlencoded.append('name', this.state.name);
    urlencoded.append('user_name', this.state.userName);
    urlencoded.append('email', this.state.email);
    urlencoded.append('phone_number', this.state.mobileNumber);
    urlencoded.append('bio', this.state.bio);
    urlencoded.append('gender', this.state.gender);
    urlencoded.append('company_name', this.state.companyName);
    urlencoded.append('street_address', this.state.StreetAddress);

    urlencoded.append('year_of_establish', this.state.yearOfEstablish);
    urlencoded.append('company_contact', this.state.contactDetail);
    urlencoded.append('profile_image', this.state.profileImage);

    axios
      .post(global.baseUrl + 'sellerupdateregister', urlencoded, {
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      })
      .then((result) => {
        // console.log(result.data);
        if (result.data.status) {
          seller.profile_image = result.data.data[0].profile_image;
          localStorage.setItem('sellerdata', JSON.stringify(seller));
          //localStorage.setItem('userdata', JSON.stringify(result.data))
          //   this.props.history.push("/dashboard");
          // this.setState({ profileImage: response.data.path });
          window.location.reload();
        }
      });
  };
  render() {
    console.log(this.state.allsellerdata, 'aaa');
    return (
      <body
        class="vertical-layout vertical-menu-modern semi-dark-layout 2-columns  navbar-floating footer-static  "
        data-open="click"
        data-menu="vertical-menu-modern"
        data-col="2-columns"
        data-layout="semi-dark-layout"
      >
        <div class="app-content content">
          <div class="content-overlay"></div>
          <div class="header-navbar-shadow"></div>
          <div class="content-wrapper">
            <div class="content-header row">
              <div className="card" style={{ width: '100%' }}>
                <div className="card-content">
                  <div className="card-body">
                    {/* {this.state.allsellerdata.length? */}
                    {this.state.users.map((temp, index) => (
                      <div class="seller_profile_page">
                        <div class="container">
                          <div className="selrof">
                            <Link to={'/dashboard'}>
                              {' '}
                              <i className="feather icon-arrow-left icon-right-arrow"></i>
                            </Link>
                            {temp ? temp.company_name : null}
                          </div>
                          <div class="row">
                            <div class="col-3 col-md-2">
                              <div class="seller-profile-three">
                                <i class="fa fa-pencil edit-pen "></i>
                                <input
                                  type="file"
                                  id={index}
                                  name="imageUpload"
                                  style={{}}
                                  onChange={(e) => this.onImageChange(e)}
                                />
                                <div class="pm-comment-avatar mb-2">
                                  {/* <i class="fa fa-pencil"></i> */}
                                  {console.log(temp, 'profgile')}

                                  <img
                                    src={
                                      temp.profile_image
                                        ? temp.profile_image
                                        : 'https://api.garagelane.com/upload/1633584535473.png'
                                    }
                                    alt=""
                                    style={{ marginBottom: '15px' }}
                                  />
                                  {/* <i class="fa fa-pencil"></i> */}
                                </div>
                              </div>
                              {/* <h6
                                style={{
                                  marginTop: '-20px',
                                  marginLeft: '50px',
                                  color: 'red',
                                  marginBottom: '20px',
                                }}
                              >
                                {this.state.imageError}
                              </h6> */}
                            </div>
                            {/* product section */}
                            <div
                              class="col-9 col-md-6 "
                              style={{ marginTop: '30px' }}
                            >
                              <div class="d-flex w-100 mb-3">
                                <p class="seller_p pr-4">
                                  <span
                                    class="font-weight-bold "
                                    style={{ marginLeft: '35px' }}
                                  >
                                    {temp ? temp.ProductCount : null}
                                  </span>{' '}
                                  <span
                                    class="pl=-2"
                                    style={{ display: 'block' }}
                                  >
                                    Products
                                  </span>
                                </p>

                                <p class="seller_p pr-4">
                                  <span
                                    class="font-weight-bold "
                                    style={{ marginLeft: '35px' }}
                                  >
                                    {temp ? temp.followers : null}
                                  </span>{' '}
                                  <span
                                    class="pl=-2"
                                    style={{ display: 'block' }}
                                  >
                                    Followers
                                  </span>{' '}
                                </p>

                                <p class="seller_p pr-4">
                                  <span
                                    class="font-weight-bold "
                                    style={{ marginLeft: '35px' }}
                                  >
                                    {temp ? temp.following : null}
                                  </span>{' '}
                                  <span
                                    class="pl=-2"
                                    style={{ display: 'block' }}
                                  >
                                    Following
                                  </span>{' '}
                                </p>
                              </div>
                            </div>
                            <div className="col-md-12">
                              {/* <Link to={"/sellerProfile"} class="btn add-to-cart crt follow__btn">Follow</Link> */}
                              <h6
                                style={{
                                  marginTop: '-20px',

                                  color: 'red',
                                  marginBottom: '20px',
                                }}
                              >
                                {this.state.imageError}
                              </h6>
                              <p class="f-18 company_name mb-0">
                                <i class="fa fa-building pr-2"></i>Company Info
                              </p>
                              <p>
                                {' '}
                                Lorem ipsum dolor sit amet, consectetur
                                adipisicing elit, sed do eiusmod tempor
                                incididunt ut labore et dolore magna aliqua. Ut
                                enim ad minim veniam, quis nostrud exercitation
                                ullamco laboris nisi ut aliquip ex ea commodo
                                consequat.{' '}
                              </p>
                              <p class="f-18 company_name">
                                <i class="fa fa-phone pr-2"></i>
                                {temp ? temp.phone_number : null}
                              </p>
                            </div>
                            <hr class="w-100"></hr>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>

                <div className="card-content">
                  <div className="card-body">
                    {/* : "No data available"} */}

                    <ul
                      class="nav nav-tabs mb-4"
                      style={{ marginTop: '-32px' }}
                    >
                      <li class="nav-item">
                        <a
                          class="nav-link active"
                          data-toggle="tab"
                          href="#home"
                          style={{ fontSize: '18px' }}
                        >
                          {/* Product */}Free Listing
                        </a>
                      </li>
                      <li class="nav-item">
                        <a
                          class="nav-link"
                          data-toggle="tab"
                          href="#menu1"
                          style={{ fontSize: '18px' }}
                        >
                          {/* Banner */}Retail
                        </a>
                      </li>
                      <li class="nav-item">
                        <a
                          class="nav-link"
                          data-toggle="tab"
                          href="#menu2"
                          style={{ fontSize: '18px' }}
                        >
                          Livestream
                        </a>
                      </li>
                    </ul>

                    <div class="tab-content">
                      <div class="tab-pane container active" id="home">
                        {console.log('list1::::::', this.state.allsellerdata)}

                        <div id="review_item">
                          <div class="container">
                            <div class="row">
                              <div
                                id="list-products"
                                class="w-100 sa-lists-pro"
                              >
                                <div class="product-single wide-style row">
                                  {this.state.allsellerdata.length
                                    ? this.state.allsellerdata.map(
                                      (temp, index) =>
                                        temp.Product.map(
                                          (ProductList, index) =>
                                            ProductList.sellerType ===
                                            'Freelisting' && (
                                              <div class="col-lg-3 col-md-4 col-6">
                                                <div class="pro_item pro_item2">
                                                  <Link
                                                    to={`/viewproduct/${ProductList.id}`}
                                                    data-toggle="modal"
                                                    data-target=".modal_cstm"
                                                  >
                                                    <img
                                                      src={
                                                        ProductList
                                                          ? ProductList
                                                            .image[0]
                                                          : 'https://www.thestatesman.com/wp-content/uploads/2020/04/1c5b1aa3386eeb2c21d633f04e2ddfbe.jpg'
                                                      }
                                                      onClick={() => { this.props.history.push(`/viewproduct/${ProductList.id}`) }}
                                                      style={{
                                                        width: '100%',
                                                        height: '200px',
                                                        objectFit: 'cover',
                                                        marginBottom: '15px',
                                                      }}
                                                    />
                                                  </Link>
                                                  <p class="mb-0">
                                                    {/* Samsung Electronics UN55MU6500 Curved 55-Inch 4K Ultra HD Smart LED TV (2019 Model) */}
                                                    {ProductList
                                                      ? ProductList.product_name
                                                      : null}
                                                  </p>
                                                </div>
                                              </div>
                                            )
                                        )
                                    )
                                    : 'No Product available'}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="tab-pane container fade" id="menu1">
                        <div id="review_item">
                          <div class="container">
                            <div class="row">
                              <div
                                id="list-products"
                                class="w-100 sa-lists-pro"
                              >
                                <div class="product-single wide-style row">
                                  {this.state.allsellerdata.length ? (
                                    this.state.allsellerdata.map(
                                      (temp, index) =>
                                        temp.Product.map(
                                          (Productlist, index) =>
                                            Productlist.sellerType ===
                                            'Retailer' && (
                                              <div class="col-lg-3 col-md-4 col-6">
                                                <div class="pro_item pro_item2">
                                                  <Link
                                                    to={`/viewproduct/${Productlist.id}`}
                                                    data-toggle="modal"
                                                    data-target=".modal_cstm"
                                                  >
                                                    <img
                                                      src={
                                                        Productlist
                                                          ? Productlist.image[0]
                                                          : 'https://www.thestatesman.com/wp-content/uploads/2020/04/1c5b1aa3386eeb2c21d633f04e2ddfbe.jpg'
                                                      }
                                                      onClick={() => { this.props.history.push(`/viewproduct/${Productlist.id}`) }}
                                                      style={{
                                                        width: '100%',
                                                        height: '200px',
                                                        objectFit: 'cover',
                                                        marginBottom: '15px',
                                                      }}

                                                    />
                                                  </Link>
                                                  <p class="mb-0">
                                                    {/* Samsung Electronics UN55MU6500 Curved 55-Inch 4K Ultra HD Smart LED TV (2019 Model) */}
                                                    {Productlist
                                                      ? Productlist.product_name
                                                      : null}
                                                  </p>
                                                </div>
                                              </div>
                                            )
                                        )
                                    )
                                  ) : (
                                    <div>No Banner available</div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="tab-pane container fade" id="menu2">
                        <div id="review_item">
                          <div class="container">
                            <div class="row">
                              <div
                                id="list-products"
                                class="w-100 sa-lists-pro"
                              >
                                <div class="product-single wide-style row">
                                  {this.state.allsellerdata.length > 0 ? (
                                    this.state.allsellerdata.map(
                                      (temp, index) =>
                                        temp.Livestream.map(
                                          (liveList, index) => (
                                            <div class="col-lg-3 col-md-4">
                                              <div class="pro_item pro_item2">
                                                <a
                                                  href={`https://garagelane.com/livestream/#/seller/${liveList.id}/${liveList.channelName}`}
                                                  // data-toggle="modal"
                                                  // data-target=".modal_cstm"
                                                >
                                                  <img
                                                    src={
                                                      liveList.productId
                                                        ? liveList.productId
                                                          .image?liveList.productId
                                                          .image[0]?liveList.productId
                                                          .image[0]:liveList.productId
                                                          .image:'https://www.thestatesman.com/wp-content/uploads/2020/04/1c5b1aa3386eeb2c21d633f04e2ddfbe.jpg'
                                                        : 'https://www.thestatesman.com/wp-content/uploads/2020/04/1c5b1aa3386eeb2c21d633f04e2ddfbe.jpg'
                                                    }
                                                    style={{
                                                      width: '100%',
                                                      height: '200px',
                                                      objectFit: 'cover',
                                                      marginBottom: '15px',
                                                    }}
                                                  />
                                                </a>
                                                <p class="mb-0">
                                                  {liveList.productId
                                                    ? liveList.productId
                                                      .product_name
                                                    : null}
                                                </p>
                                              </div>
                                            </div>
                                          )
                                        )
                                    )
                                  ) : (
                                    <div>No Livestream</div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </body>
    );
  }
}
export default SellerProfile;
