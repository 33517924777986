import React from "react";
import axios from "axios";
//import InputColor from "react-input-color";
import Select from "react-select";
import { Link } from "react-router-dom";
//import Sidebar from "../../Component/Sidebar/Sidebar";
//import Header from "../../Component/Header/Header";
import Footer from "../../Component/Footer/Footer";
import global from "../../global";
import "./style.css";
var questionError = "";
var answerError = "";
var submitDisable = false;
var topicError=""

class AddProduct extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      question: "",
      answer: "",
      questionError: "",
      answerError: "",
      topicError:"",
      seller:"",
      topics:[
        {
          label: "Ordering",
          value: "ordering"
        },
        {
          label: "Shipping & Delivery",
          value: "shipping_delivery"
        },
        {
          label: "Return & Exchanges",
          value: "return_exchanges"
        },
        {
          label: "Auto Delivery",
          value: "auto_delivery"
        },
        {
          label: "GiftCard",
          value: "giftcard"
        },
        {
          label: "Payment",
          value: "payment"
        },
        {
          label: "EasyPay",
          value: "easypay"
        },
      ]
    };
    questionError = "";
    answerError = "";
    topicError=""
    submitDisable=false
  }

  componentWillMount() {
    if (localStorage.getItem("sellerdata")) {
      this.setState({ seller: JSON.parse(localStorage.getItem("sellerdata")) });
    }
    ///this.listAPI();
  }

  dropChangeHandler = event => {
    this.setState({
      topic: event.value
    });
  };

  handleChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  addApiCall = () => {
    var urlencoded = new URLSearchParams();
    urlencoded.append("sellerId", this.state.seller.id);
    urlencoded.append("topic_type", this.state.topic);
    urlencoded.append("question", this.state.question);
    urlencoded.append("answer", this.state.answer);
    axios
      .post(global.baseUrl+"selleraddpoint", urlencoded, {
        headers: { "Content-Type": "application/x-www-form-urlencoded" }
      })
      .then(result => {
        console.log(result.data);
        if (result.data.status) {
          this.props.history.push("/sellerforum");
        }
      });
  };
  handleSubmit = e => {
    e.preventDefault();
    if (this.state.question === "") {
      this.setState({ quantityError: "Please enter question" });
      questionError = "Please enter question";
    } else if (this.state.question.trim() === "") {
      this.setState({ questionError: "Please enter valid question" });
      questionError = "Please enter valid question";
    } else {
      this.setState({ questionError: "" });
      questionError = "";
    }
    if (this.state.answer === "") {
      this.setState({ answerError: "Please enter answer" });
      answerError = "Please enter answer";
    } else if (this.state.answer.trim() === "") {
      this.setState({ answerError: "Please enter valid answer" });
      answerError = "Please enter valid answer";
    } else {
      this.setState({ answerError: "" });
      answerError = "";
    }
    if (this.state.topic === "") {
      this.setState({ topicError: "Please select type" });
      topicError = "Please select type";
    } else {
      this.setState({ topicError: "" });
      topicError = "";
    }
    if (
      this.state.question &&
      this.state.answer &&
      this.state.topic &&
      topicError==="" &&
      
      questionError === "" &&
      answerError === ""
    ) {
      //submitDisable = true;
      this.addApiCall();
    }
  };

  resetHandler = () => {
    this.setState({
      question: "",
      answer: "",
      topic: ""
    });
    questionError = "";
    answerError = "";
  };
  render() {
    return (
      <body
        class="vertical-layout vertical-menu-modern semi-dark-layout 2-columns  navbar-floating footer-static  "
        data-open="click"
        data-menu="vertical-menu-modern"
        data-col="2-columns"
        data-layout="semi-dark-layout"
      >
        {/* <Header />
        <Sidebar /> */}
        <div class="app-content content">
          <div class="content-overlay"></div>
          <div class="header-navbar-shadow"></div>
          <div class="content-wrapper">
            <div class="content-header row">
              <div class="content-header-left col-md-9 col-12 mb-2">
                <div class="row breadcrumbs-top">
                  <div class="col-12">
                    <h2 class="content-header-title float-left mb-0">
                      Add Topic
                    </h2>
                    <div class="breadcrumb-wrapper col-12">
                      <ol class="breadcrumb">
                        <li class="breadcrumb-item">
                          <Link to="/sellerforum">Forum</Link>
                        </li>
                        <li class="breadcrumb-item active">Add Topic</li>
                      </ol>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="content-body">
              <section id="dashboard-analytics">
                <form class="form pl-2 pr-2">
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <div class="row">
                    <div class="col-lg-8 col-12">
                      <div class="form-group">
                          <label>Topic Type</label>
                          <Select
                              value={this.state.topics.filter(
                                option => option.value === this.state.topic
                              )}
                              theme={theme => ({
                                ...theme,
                                borderRadius: "5px",
                                border: "0px solid",
                                colors: {
                                  ...theme.colors,
                                  text: "orangered",
                                  primary25: "#9cf59c",
                                  primary: "#47A547"
                                }
                              })}
                              options={this.state.topics}
                              name="brand"
                              onChange={val => this.dropChangeHandler(val)}
                            />
                          <p class="error-msg">{topicError} </p>
                        </div>

                        
                      </div>
                      <div class="col-lg-8 col-md-12">
                        <div class="form-group">
                          <label>Question</label>
                          <input
                            type="text"
                            class="form-control"
                            placeholder="Question"
                            name="question"
                            value={this.state.question}
                            onChange={this.handleChange}
                          />
                          <p class="error-msg">{questionError} </p>
                        </div>
                      </div>
                      <div class="col-lg-8 col-12">
                        <div class="form-group">
                          <label>Answer</label>
                          <textarea
                            type="text"
                            id="first-name-floating-icon"
                            class="form-control"
                            name="answer"
                            value={this.state.answer}
                            onChange={this.handleChange}
                            placeholder="Answer"
                          />
                          <p class="error-msg">{answerError} </p>
                        </div>
                      </div>
                      
                    </div>
                  </div>
                </form>
                <div class="col-12">
                  <div class="form-label-group position-relative has-icon-left">
                    <button
                      class="btn btn-primary-green p-1 add-size add-product add-faq"
                      type="button"
                      onClick={this.handleSubmit}
                      disabled={submitDisable}
                    >
                      Add Topic
                    </button>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>

        <div class="sidenav-overlay"></div>
        <div class="drag-target"></div>

        <Footer />
      </body>
    );
  }
}

export default AddProduct;
