import React from "react";
import axios from "axios";
import "react-image-lightbox/style.css";

import { Link } from "react-router-dom";
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  // Modal,
  // ModalHeader,
  // ModalBody,
  // ModalFooter
} from "reactstrap";

import classnames from "classnames";
import Footer from "../../Component/Footer/Footer";
import global from "../../global";
// import Paypal from "./PaypalBtn";
// import StripeCheckout from "react-stripe-checkout";
import "react-toastify/dist/ReactToastify.css";
import money from './money.jpg'
import moment from 'moment'

import { toast } from "react-toastify";

import LoadingOverlay from "react-loading-overlay";
import "./style.css";
toast.configure()
const CustomToast = () => {
  return (
    <div>
      <i class="fa fa-check success-toast"></i>
    Payout Successfully
    </div>
  )
}
const CustomToast2 = () => {
  return (
    <div>
      <i class="fa fa-check success-toast"></i>
    Sucessfuly Aproved!
    </div>
  )
}
// const CURRENCY = "USD";
// const fromDollarToCent = amount => parseInt(amount * 100);
var isActive = false;
var seller = {};
var amountError = "";
var modal = false;

class PaymentPayout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      seller: {},
      activeTab: "1",
      payoutType: "manual",
      budget: 0,
      paymentAmount: 0,
      automaticPayment: "1st of the month",
      list: [],
      total:0,
      totalRecived:0,
    };
    modal = false;
    amountError = "";
    isActive = false
  }

  componentWillMount() {
    if (localStorage.getItem("sellerdata")) {
      seller = JSON.parse(localStorage.getItem("sellerdata"));
      this.setState({ seller: JSON.parse(localStorage.getItem("sellerdata")) });
      this.getAmountAPI()
      let urlencoded1 = new URLSearchParams();
      urlencoded1.append("sellerId", seller.id);
      axios
        .post(global.baseUrl + "SellerAllProductList", urlencoded1, {
          headers: { "Content-Type": "application/x-www-form-urlencoded" }
        })
        .then(result => {
          if (result.data.status) {
            // let defaultOptions = this.state.defaultOptions
            // let autoRenewalOptions = this.state.autoRenewalOptions
            for (let i = 0; i < result.data.data.length; i++) {
              if (result.data.data[i].renewal === "manual") {
                // defaultOptions.push({
                //   id:result.data.data[i].id,
                //   label:result.data.data[i].product_name
                // })
              }
              else {
                if (result.data.data[i].renewal === "auto") {
                  // autoRenewalOptions.push({
                  //   id:result.data.data[i].id,
                  //   label:result.data.data[i].product_name
                  // })
                }
              }
            }
            // this.setState({defaultOptions,autoRenewalOptions})
          }
        });

      let urlencoded2 = new URLSearchParams();
      axios
        .post(global.baseUrl + "SellerProductCharge", urlencoded2, {
          headers: { "Content-Type": "application/x-www-form-urlencoded" }
        })
        .then(result => {
          console.log(result.data, "charge amount");
          if (result.data.status) {
            this.setState({
              productCharge: parseFloat(result.data.data.product_charge),
              imageCharge: parseFloat(result.data.data.image_charge)
            })
          }
        });
    }
    this.getcourentmonthprice()
  }

  getcourentmonthprice = () => {
    let urlencoded = new URLSearchParams();
    urlencoded.append("id", seller.id);
    axios
      .post(global.baseUrl + "totalwallatebalance", urlencoded, {
        headers: { "Content-Type": "application/x-www-form-urlencoded" }
      })
      .then(result => {
        console.log(result.data, "amount");
        if (result.data.data[0]) {
          if (result.data.currentmonth !== null) {
            this.setState({ paymentAmount: result.data.currentmonth });
            this.setState({ total: result.data.total });
            this.setState({ totalRecived: result.data.recivedTotal });
          } else {
            this.setState({ total:0 });
          }
          // this.setState({paymentAmount : parseInt(result.data.data[0].total) });

        }
      });

  }
  getAmountAPI = () => {
    let urlencoded = new URLSearchParams();
    urlencoded.append("sellerId", seller.id);
    axios
      .post(global.baseUrl + "SellerGetWalletAmount", urlencoded, {
        headers: { "Content-Type": "application/x-www-form-urlencoded" }
      })
      .then(result => {
        console.log(result.data, "amount");
        if (result.data.status) {
          if (result.data.amount > 0) {
            this.setState({ walletAmount: parseFloat(result.data.amount) });
          }
        }
      });

  }

  Approve = (id, status) => {

    var urlencoded = new URLSearchParams();
    urlencoded.append("id", id);
    urlencoded.append("ApprovedStatus", status);
    // if(banners[index].automatic_payment!==null&&banners[index].automatic_payment!=='null'){
    //   sellerId: req.body.sellerId,
    //   payment_type: req.body.payment_type,
    //   automatic_payment: req.body.automatic_payment,
    //   debit: req.body.payment_amount,
    // }else{
    axios.post(global.baseUrl + "paymentpayoutsellerapprove", urlencoded, {
      headers: { "Content-Type": 'application/x-www-form-urlencoded' }
    })
      .then((result) => {
        if (result.data.status) {
          // this.setState({ users: r })
          console.log('result', result.data)
          toast.success(<CustomToast2 />, { position: toast.POSITION.TOP_RIGHT })
          this.toggle('2')
        }
        else {
          console.log('result', result.data)
        }
      })
  }
  handleChange = (event) => {
    if (event.target.value > this.state.total.toFixed(2)) {
      toast.error("You can't request more then total balance");
    } else {
      this.setState({ budget: event.target.value });
    }
  };
  submitHandler = () => {
    if (this.state.payoutType === "manual") {
      let numberCheck = /^[0-9]\d*(\.\d+)?$/;
      if (this.state.budget === "") {
        this.setState({ amountError: "Please enter amount" });
        amountError = "Please enter amount";
      } else if (!numberCheck.test(this.state.budget) || this.state.budget < 1) {
        this.setState({ amountError: "Please enter valid budget" });
        amountError = "Please enter valid amount";
      } else {
        amountError = "";
        modal = true;
        this.setState({ amountError: "" });
        console.log("wofjf")
        this.AddPamentPayoutAPI();
      }
    } else {
      this.AddPamentPayoutAPI();

    }
  };
  modalToggle = () => {
    if (modal === false) {
      modal = true;
    } else {
      modal = false;
    }
    this.setState({ A: "" });
  };
  toggle = tab => {
    if (tab === "1") {
      this.setState({ activeTab: "1" });
    } else if (tab === "2") {
      this.setState({ activeTab: "2" });
      let urlencoded = new URLSearchParams();
      urlencoded.append("sellerId", this.state.seller.id);
      axios
        .post(global.baseUrl + "paymentPayoutWalletHistory", urlencoded, {
          headers: { "Content-Type": "application/x-www-form-urlencoded" }
        })
        .then(result => {
          console.log(result.data, "amount");
          if (result.data.status) {

            let amount = 0
            let list = result.data.data
            for (let i = result.data.data.length - 1; i >= 0; i--) {
              if (result.data.data[i].credit !== "0") {
                amount = parseFloat(amount) - parseFloat(result.data.data[i].credit)
                list[i].amount = amount
              }
              else {
                amount = parseFloat(amount) + parseFloat(result.data.data[i].debit)
                list[i].amount = amount
              }
            }
            this.setState({
              list: list,
              finalWalletAmount: result.data.amount
            });
          }
        });
    }
  };
  payoutTypeChange = (e) => {
    this.setState({ payoutType: e.target.value })
  }
  autoPayoutRadio = (e) => {
    this.setState({ automaticPayment: e.target.value })
  }
  AddPamentPayoutAPI() {
    if (this.state.payoutType === "manual") {
      let urlencoded = new URLSearchParams();
      urlencoded.append("sellerId", this.state.seller.id);
      urlencoded.append("payment_type", this.state.payoutType);
      urlencoded.append("payment_amount", this.state.budget);
      // urlencoded.append("automatic_payment", this.state.automaticPayment);

      axios
        .post(global.baseUrl + "addPaymentPayout", urlencoded, {
          headers: { "Content-Type": "application/x-www-form-urlencoded" }
        })
        .then(result => {
          console.log(result.data, "amount");
          if (result.data.status) {
            this.toggle("2")
            toast.success(<CustomToast />, { position: toast.POSITION.TOP_RIGHT })

            // if (result.data.amount > 0) {
            //   this.setState({ walletAmount: parseFloat(result.data.amount) });
            // }
          }
        });
    } else {
      console.log("4646445545454")
      let urlencoded = new URLSearchParams();
      urlencoded.append("sellerId", this.state.seller.id);
      urlencoded.append("payment_type", this.state.payoutType);
      // urlencoded.append("payment_amount", this.state.budget);
      urlencoded.append("automatic_payment", this.state.automaticPayment);

      axios
        .post(global.baseUrl + "addPaymentPayout", urlencoded, {
          headers: { "Content-Type": "application/x-www-form-urlencoded" }
        })
        .then(result => {
          console.log(result.data, "amount");
          if (result.data.status) {
            this.toggle("2")
            toast.success(<CustomToast />, { position: toast.POSITION.TOP_RIGHT })

            // if (result.data.amount > 0) {
            //   this.setState({ walletAmount: parseFloat(result.data.amount) });
            // }
          }
        });
    }
  }
  render() {
    return (
      <body
        class="vertical-layout vertical-menu-modern semi-dark-layout content-left-sidebar chat-application navbar-floating footer-static  "
        data-open="click"
        data-menu="vertical-menu-modern"
        data-col="content-left-sidebar"
        data-layout="semi-dark-layout"
      >
        {/* <Header />
        <Sidebar /> */}
        <LoadingOverlay
          active={isActive}
          spinner
          text="Please wait we are processing your payment"
        />
        <div class="app-content content payment">
          <div class="content-overlay"></div>
          <div class="header-navbar-shadow"></div>
          <div class="content-wrapper">
            <div class="content-header row">
              <div class="content-header-left col-md-9 col-12 mb-2">
                <div class="row breadcrumbs-top">
                  <div class="col-12">
                    <h2 class="content-header-title float-left mb-0">
                      Payment Payout
                    </h2>
                    <div class="breadcrumb-wrapper col-12">
                      <ol class="breadcrumb" >
                        <li class="breadcrumb-item">
                          <Link to="/dashboard">Home</Link>
                        </li>
                        <li class="breadcrumb-item active">Payment Payout</li>
                      </ol>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="content-body" style={{ paddingBottom: "0px" }}>
              <section id="add-row">
                <div class="row">
                  <div class="col-12">
                    <div class="card follow-box">
                      <div class="card-content ">
                        <div class="card col-12">
                          <Nav tabs className="add-payments-payout-nav">
                            <NavItem>
                              <NavLink
                                className={classnames({
                                  active: this.state.activeTab === "1"
                                })}
                                onClick={() => {
                                  this.toggle("1");
                                }}
                              >
                                <span class="d-sm-block" style={{ fontSize: "15px" }}>
                                  Add Payments Payout
                            </span>
                              </NavLink>
                            </NavItem>
                            <NavItem>
                              <NavLink
                                className={classnames({
                                  active: this.state.activeTab === "2"
                                })}
                                onClick={() => {
                                  this.toggle("2");
                                }}
                              >
                                <span class="d-sm-block" style={{ fontSize: "15px" }}>
                                  Payout Transaction History
                            </span>
                              </NavLink>
                            </NavItem>
                          </Nav>
                          <TabContent activeTab={this.state.activeTab}>
                            <TabPane tabId="1">
                              <div class="col-md-12 col-12 ">
                                <div style={{ padding: "2px" }}>
                                  {/* <div class="card" style={{ marginBottom: "0px" }}>
                                  <div class="card-header">
                                    <div class="card-title wallet-title"> */}
                                  <p>Total Balance for {new Date().toLocaleString('en-us', { month: 'short' })}: {this.state.paymentAmount === 'NaN' ? 0 : this.state.paymentAmount.toFixed(2)}</p>
                                  <p>Total Balance :{this.state.total === 'NaN' ? 0 : this.state.total.toFixed(2)}</p>
                                  <p>Total Recived :{this.state.totalRecived === 'NaN' ? 0 : this.state.totalRecived.toFixed(2)}</p>
                                  <p>Your sales covered your fees</p>
                                  <p>(Payment Method) ACH</p>


                                  {/* </div>
                                  </div>
                                </div> */}
                                </div>
                              </div>


                              <div class="col-md-12 col-12 ">
                                <div class="card" style={{ marginBottom: "0px" }}>
                                  <div class="xs-pl-0  card-header">
                                    <div class="card-title wallet-title">
                                      <input
                                        type="radio"
                                        style={{ height: "13px", width: "13px", margin: "5px" }}
                                        name="payoutType"
                                        value="manual"
                                        onChange={(e) => this.payoutTypeChange(e)}
                                        checked={this.state.payoutType === "manual"}
                                      ></input>
                                      <p>Manual Payout</p>
                                    </div>
                                  </div>
                                  <div class="xs-pl-0  card-body">
                                    <div class="card-title mb-1 pl" style={{ fontSize: "16px" }}>
                                      How much would you like to withdraw?
                                    </div>
                                    <section class="page-users-view" >

                                      <div class="row" >
                                        <div class="col-md-2">
                                          <p style={{ marginTop: "6px", fontSize: "13px" }} className="pl">
                                            Enter Amount
                                        <span class="required-span">*</span>
                                          </p>
                                        </div>

                                        <div class="col-md-4">
                                          <div class="form-label-group position-relative has-icon-left  xs-pl-25">
                                            <input
                                              type="text"
                                              id="first-name-floating-icon"
                                              class="form-control"
                                              name="budget"
                                              value={this.state.budget}
                                              onChange={this.handleChange}
                                              placeholder="Enter Amount"

                                            />
                                            <div class="form-control-position">
                                              <i class="fa fa-dollar"></i>
                                            </div>

                                            <p class="error-msg">{amountError}</p>
                                          </div>{" "}
                                        </div>

                                      </div>
                                    </section>

                                  </div>
                                </div>
                                <div class="card" style={{ paddingTop: "0px" }}>
                                  <div class="card-header xs-pl-0 ">
                                    <div class="card-title wallet-title">
                                      <input
                                        type="radio"
                                        style={{ height: "13px", width: "13px", margin: "5px" }}
                                        name="payoutType"
                                        value="automatic"
                                        onChange={(e) => this.payoutTypeChange(e)}
                                        checked={this.state.payoutType === "automatic"}
                                      ></input>
                                      <p>Automatic Renewal</p>
                                    </div>
                                  </div>
                                  <div class="card-body xs-pl-0 ">
                                    <div class="wallet-drop-area">
                                      <div class="">
                                        <form>
                                          <label class="radio">
                                            <input type="radio"
                                              name="automaticPayment"
                                              style={{ height: "13px", width: "13px", margin: "5px" }}

                                              value="1st of the month"
                                              onChange={(e) => this.autoPayoutRadio(e)}
                                              checked={this.state.automaticPayment === "1st of the month"} />  1st of the month
                                              </label><br></br><br></br>
                                          <label class="radio">
                                            <input type="radio"
                                              name="automaticPayment"
                                              style={{ height: "13px", width: "13px", margin: "5px" }}

                                              value="Every Two weeks"
                                              onChange={(e) => this.autoPayoutRadio(e)}
                                              checked={this.state.automaticPayment === "Every Two weeks"} />Every Two weeks,
                                          </label><br></br><br></br>
                                          <label class="radio">
                                            <input type="radio"
                                              name="automaticPayment"
                                              style={{ height: "13px", width: "13px", margin: "5px" }}

                                              value="Every Quarter"
                                              onChange={(e) => this.autoPayoutRadio(e)}
                                              checked={this.state.automaticPayment === "Every Quarter"} />Every Quarter
                                          </label>
                                        </form>
                                      </div>

                                      {/* <div class="wallet-move-btn">
                                        <button
                                          class="btn btn-outline-primary float-left btn-inline res-btn w-btn"
                                          onClick={() => this.addOptionHandler()}
                                        >
                                          Add
                                    </button>
                                        <button
                                          class="btn btn-outline-primary float-left btn-inline res-btn w-btn"
                                          onClick={() => this.addAllOptionHandler()}

                                        >
                                          Add All
                                    </button><button
                                          class="btn btn-outline-primary float-left btn-inline res-btn w-btn"
                                          onClick={() => this.removeOptionHandler()}

                                        >
                                          Remove
                                    </button><button
                                          class="btn btn-outline-primary float-left btn-inline res-btn w-btn"
                                          onClick={() => this.removeAllOptionHandler()}
                                        >
                                          Remove All
                                    </button>
                                      </div> */}
                                      {/* <div class="">
                                        <select class="form-control wallet-drop-down" size={5} onChange={(e) => this.autoOptionHandler(e)}>

                                          {this.state.autoRenewalOptions.map((data, index) => (
                                            <option value={data.id} key={index}>{data.label}</option>
                                          ))}
                                        </select>
                                      </div> */}
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="card" style={{ paddingTop: "0px" }}>
                                <div class="card-header">
                                  <div class="card-title wallet-title">
                                    <button
                                      class="btn btn-primary-green wallet-add-amount"
                                      type="button"
                                      onClick={() => this.submitHandler()}
                                    >
                                      Request Payout
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </TabPane>
                            <TabPane tabId="2">
                              <div class="col-md-12 col-12 ">
                                <div class="card">
                                  <div className="table-responsive">
                                    <table id="list" className="table">
                                      <thead>
                                        <tr>
                                          <th>Transaction History</th>
                                          {/* <th>Credit</th> */}
                                          <th>Debit</th>
                                          <th>Transaction Id</th>
                                          {/* <th>Action</th> */}
                                          {/* <th>Balance</th> */}
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {this.state.list.length
                                          ? this.state.list.map((list, index) => (
                                            <tr key={index}>
                                              <td class="font-weight-bold mb-0" style={{ minWidth: "260px" }}>
                                                <span class="avatar m-0 avatar-md trigger-wallet-div" style={{
                                                  background:
                                                    "url(" + money + ")"
                                                }}>
                                                  <img
                                                    class="media-object rounded-circle"
                                                    src={money}
                                                    height="42"
                                                    width="42"
                                                    alt="..."
                                                  />
                                                  <i></i>
                                                </span>



                                                Payout {" "}{list.productId ? list.productId.product_name : null}


                                                {/* <td class="truncate" style={{ fontSize: "13px" }}> */}
                                                {moment(list.updatedAt).format(
                                                  "DD-MM-YYYY,HH:mm"
                                                )}
                                                {/* </td> */}
                                              </td>

                                              {/* <td> */}
                                              {/* {list.debit === "0" && list.credit !== "0" ? */}
                                              {/* <td style={{ color: "green", paddingLeft: "10px" }}>
                                                    ${list.credit ? parseFloat(list.credit).toFixed(2) : null}
                                                  </td> */}
                                              {/* : null
                                                } */}
                                              {/* </td> */}


                                              {list.debit !== "0" && list.credit === "0" ?
                                                <td style={{ color: "green" }}> ${list.debit ? parseFloat(list.debit).toFixed(2) : 0}</td>
                                                :
                                                null
                                              }

                                              <td>{list.TransectionId && list.TransectionId !== '' ? list.TransectionId : 'pending'}</td>
                                              {/* {!list.TransectionId||list.TransectionId===''||list.ApprovedStatus==='3'||list.ApprovedStatus==='1'?
                                              <td><Link class="btn btn-primary mb-2 add-product-link" style={{pointerEvents:'none'}}>Recived</Link>{'   '}<Link class="btn btn-danger mb-2 " style={{pointerEvents:'none'}}>Not Recived</Link></td>
                                              :
                                              <td><Link class="btn btn-primary mb-2 add-product-link" onClick={()=>this.Approve(list.id,"1")}>Recived</Link>{'   '}<Link class="btn btn-danger mb-2 " onClick={()=>this.Approve(list.id,"3")}>Not Recived</Link></td>
                                              } */}



                                            </tr>
                                          ))
                                          : <td>Transaction not Available</td>


                                        }


                                      </tbody>


                                    </table>
                                  </div>
                                </div>
                              </div>
                            </TabPane>
                          </TabContent>

                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>

        <Footer />
        <div class="sidenav-overlay"></div>
        <div class="drag-target"></div>
      </body>

    );
  }
}
export default PaymentPayout;
