import React from "react";
import axios from "axios";
import Select from "react-select";
//import { Link } from "react-router-dom";

// import IntlTelInput from "react-intl-tel-input";
// import Sidebar from "../../Component/Sidebar/Sidebar";
// import Header from "../../Component/Header/Header";
import Footer from "../../Component/Footer/Footer";
import global from "../../global";

import "react-intl-tel-input/dist/main.css";
import "./style.css";

var submitDisable = false;
var firstAddressError = ""
var firstCityError = ""
var firstZipCodeError = ""
var secondAddressError = ""
var secondCityError = ""
var secondZipCodeError = ""
class Address extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      taxDescription: "",
      countries: [],
      infodata:{},
      firstStates: [],
      firstAddress: "",
      firstAddress2: "",
      firstCity: "",
      firstZipCode: "",
      firstStateId: "",
      firstCountryId: "",
      secondStates: [],
      secondAddress: "",
      secondAddress2: "",
      secondCity: "",
      secondZipCode: "",
      secondStateId: "",
      secondCountryId: "",
      sameAddress: false,

    };
    submitDisable = false;
    firstAddressError = ""
    firstCityError = ""
    firstZipCodeError = ""
    secondAddressError = ""
    secondCityError = ""
    secondZipCodeError = ""

  }

  componentWillMount() {
    if (localStorage.getItem("userdata")) {
      this.setState({ seller: JSON.parse(localStorage.getItem("userdata")),infodata: JSON.parse(localStorage.getItem("infodata")) },
        () => {
          this.getTaxAPI()

        });
    }
    axios.get(global.baseUrl + "GetTaxDescription", {
      headers: { "Content-Type": "application/x-www-form-urlencoded" }
    })
      .then(result => {
        if (result.data.status) {
          this.setState({ taxDescription: result.data.data.description })

        }
      });
  }
  getTaxAPI() {
    var urlencoded = new URLSearchParams();
    urlencoded.append("sellerId", this.state.seller.id);
    axios
      .post(global.baseUrl + "sellergettaxform", urlencoded, {
        headers: { "Content-Type": "application/x-www-form-urlencoded" }
      })
      .then(result => {
        console.log(result);
        if (result.data.status) {

          if (result.data.data.same_address === "1") {
            this.setState({ sameAddress: true })
          }
          if (result.data.data.first_address) {
            if (result.data.data.first_address.length) {
              let first_address = JSON.parse(result.data.data.first_address[0])
              this.setState({
                firstAddress: first_address.address,
                firstAddress2: first_address.address2,
                firstCity: first_address.city,
                firstZipCode: first_address.zip_code,
                firstCountryId: first_address.country_Id,
                firstStateId: first_address.state_Id,

              }, () => {
                this.firstStateList()
              })
            }
          }
          if (result.data.data.second_address) {
            if (result.data.data.second_address.length) {
              let second_address = JSON.parse(result.data.data.second_address[0])
              this.setState({
                secondAddress: second_address.address,
                secondAddress2: second_address.address2,
                secondCity: second_address.city,
                secondZipCode: second_address.zip_code,
                secondCountryId: second_address.country_Id,
                secondStateId: second_address.state_Id,
              }, () => {
                this.secondStateList()
              })
            }
          }
        }
        this.countryAPI()
      });
  }
  firstStateList = () => {
    var urlencoded = new URLSearchParams();
    urlencoded.append("countryId", this.state.firstCountryId);
    axios
      .post(global.baseUrl + "sellerstatelist", urlencoded, {
        headers: { "Content-Type": "application/x-www-form-urlencoded" }
      })
      .then(result => {
        console.log(result.data);
        if (result.data.status) {
          var option = [];
          option.push({
            label: "Select",
            value: ""
          });
          for (let i = 0; i < result.data.data.length; i++) {
            option.push({
              label: result.data.data[i].name,
              value: result.data.data[i].id
            });
          }
          this.setState({
            firstStates: option
          });
        }
      });
  }
  secondStateList = () => {
    var urlencoded = new URLSearchParams();
    urlencoded.append("countryId", this.state.secondStateId);
    axios
      .post(global.baseUrl + "sellerstatelist", urlencoded, {
        headers: { "Content-Type": "application/x-www-form-urlencoded" }
      })
      .then(result => {
        console.log(result.data);
        if (result.data.status) {
          var option = [];
          option.push({
            label: "Select",
            value: ""
          });
          for (let i = 0; i < result.data.data.length; i++) {
            option.push({
              label: result.data.data[i].name,
              value: result.data.data[i].id
            });
          }
          this.setState({
            secondStates: option
          });
        }
      });
  }
  countryAPI() {
    const apiUrl = global.baseUrl + "sellercontrylist";
    axios.get(apiUrl).then(
      result => {
        console.log("API Call Results:", result.data);

        if (result.data.status) {
          var option = [];
          option.push({
            label: "Select",
            value: ""
          });
          for (let i = 0; i < result.data.data.length; i++) {
            option.push({
              label: result.data.data[i].name,
              value: result.data.data[i].id
            });
          }
          this.setState({
            countries: option
          });
        }
      },
      error => {
        this.setState({ error });
      }
    );
  }
  firstCountryDropChangeHandler = event => {
    this.setState({
      firstCountryId: event.value,
      firstStateId: ""
    });

    var urlencoded = new URLSearchParams();
    urlencoded.append("countryId", event.value);
    axios
      .post(global.baseUrl + "sellerstatelist", urlencoded, {
        headers: { "Content-Type": "application/x-www-form-urlencoded" }
      })
      .then(result => {
        console.log(result.data);
        if (result.data.status) {
          var option = [];
          option.push({
            label: "Select",
            value: ""
          });
          for (let i = 0; i < result.data.data.length; i++) {
            option.push({
              label: result.data.data[i].name,
              value: result.data.data[i].id
            });
          }
          this.setState({
            firstStates: option
          });
        }
      });
  };
  firstStateDropChangeHandler = event => {
    this.setState({
      firstStateId: event.value
    });
  };
  secondCountryDropChangeHandler = event => {
    this.setState({
      secondCountryId: event.value,
      secondStateId: ""
    });

    var urlencoded = new URLSearchParams();
    urlencoded.append("countryId", event.value);
    axios
      .post(global.baseUrl + "sellerstatelist", urlencoded, {
        headers: { "Content-Type": "application/x-www-form-urlencoded" }
      })
      .then(result => {
        console.log(result.data);
        if (result.data.status) {
          var option = [];
          option.push({
            label: "Select",
            value: ""
          });
          for (let i = 0; i < result.data.data.length; i++) {
            option.push({
              label: result.data.data[i].name,
              value: result.data.data[i].id
            });
          }
          this.setState({
            secondStates: option
          });
        }
      });
  };
  secondStateDropChangeHandler = event => {
    this.setState({
      secondStateId: event.value
    });
  };
  handleChange = event => {
    this.setState({ [event.target.name]: event.target.value }, () => {
      this.checkSameHandler()
    });
  };
  handlePhoneChange = (countryData, phoneNumber) => {
    let validated = phoneNumber.replace(/(\s|-)/g, "");
    this.setState({
      phoneNumber: validated,
      countryCode: countryData.dialCode,
      countryAlias: countryData.iso2
    });
  };

  addApiCall = () => {
    var urlencoded = new URLSearchParams();
    let first_address = {
      address: `${this.state.firstAddress},${this.state.firstZipCode}`,
      address2: this.state.firstAddress2,
      city: this.state.firstCity,
      zip_code: this.state.firstZipCode,
      country_Id: this.state.firstCountryId,
      state_Id: this.state.firstStateId,
    }
    let second_address = {
      address: `${this.state.secondAddress},${this.state.secondZipCode}`,
      address2: this.state.secondAddress2,
      city: this.state.secondCity,
      zip_code: this.state.secondZipCode,
      country_Id: this.state.secondCountryId,
      state_Id: this.state.secondStateId,
    }
    urlencoded.append("sellerId", this.state.seller.id);
    urlencoded.append("first_address", JSON.stringify(first_address));
    urlencoded.append("second_address", JSON.stringify(second_address));
    if (this.state.sameAddress) {
      urlencoded.append("same_address", "1");
    }
    else {
      urlencoded.append("same_address", "0");
    }
    axios
      .post(global.baseUrl + "sellersecondtaxform", urlencoded, {
        headers: { "Content-Type": "application/x-www-form-urlencoded" }
      })
      .then(result => {
        if (result.data.status) {
          var urlencoded2 = new URLSearchParams();
          urlencoded2.append("userId", this.state.seller.id);
    urlencoded2.append("name",  `${this.state.infodata.first_name} ${this.state.infodata.middle_name} ${this.state.infodata.last_name}`);
    urlencoded2.append("phone_number", this.state.infodata.phone_number);
    urlencoded2.append("street_address", `${this.state.firstAddress},${this.state.firstZipCode}`);
    urlencoded2.append("zipCode", this.state.secondZipCode);

    axios
      .post(global.baseUrl+ "sellerupdateregisterpaytime", urlencoded2, {
        headers: { "Content-Type": "application/x-www-form-urlencoded" }
      })
      .then(result => {
        console.log(result.data);
        if (result.data.status) {
        }
      });
  
          this.props.history.push("/sellarpaymentmethod");
        }
      });
  };
  handleSubmit = e => {
    //this.props.history.push("/paymentmethod");
    e.preventDefault();
    this.setState({ isValid: false })
    if (this.state.firstAddress === "") {
      firstAddressError = "Please enter address";
    }
    else if (this.state.firstAddress.trim() === "") {
      firstAddressError = "Please enter valid address";
    }
    else {
      firstAddressError = "";
    }
    if (this.state.firstCity === "") {
      firstCityError = "Please enter city";
    } else if (
      !this.state.firstCity.match(/^[a-zA-Z-,]+(\s{0,1}[a-zA-Z-, ])*$/)
    ) {
      firstCityError = "Please enter only character";
    } else {
      firstCityError = "";
    }
    if (this.state.firstZipCode === "") {
      firstZipCodeError = "Please enter zipcode";
    }
    else {
      firstZipCodeError = ""
    }
    if (this.state.secondAddress === "") {
      secondAddressError = "Please enter address";
    }
    else if (this.state.secondAddress.trim() === "") {
      secondAddressError = "Please enter valid address";
    }
    else {
      secondAddressError = "";
    }
    if (this.state.secondCity === "") {
      secondCityError = "Please enter city";
    } else if (
      !this.state.secondCity.match(/^[a-zA-Z-,]+(\s{0,1}[a-zA-Z-, ])*$/)
    ) {
      secondCityError = "Please enter only character";
    } else {
      secondCityError = "";
    }
    if (this.state.secondZipCode === "") {
      secondZipCodeError = "Please enter zipcode";
    }
    else {
      secondZipCodeError = ""
    }
    if (
      this.state.firstAddress && this.state.firstCity && this.state.firstZipCode && this.state.secondAddress && this.state.secondCity && this.state.secondZipCode &&
      firstAddressError === "" && firstCityError === "" && firstZipCodeError === "" && secondAddressError === "" && secondCityError === "" && secondZipCodeError === "") {
      console.log('working')
      //submitDisable = true;
      this.addApiCall();
    }
  };
  checkSameHandler = () => {
    if (this.state.sameAddress) {
      this.setState({
        secondStates: this.state.firstStates,
        secondAddress: this.state.firstAddress,
        secondAddress2: this.state.firstAddress2,
        secondCity: this.state.firstCity,
        secondZipCode: this.state.firstZipCode,
        secondStateId: this.state.firstStateId,
        secondCountryId: this.state.firstCountryId,
      })
      secondAddressError = ""
      secondCityError = ""
      secondZipCodeError = ""
    }
  }
  handleSameAddressChange = () => {
    if (this.state.sameAddress) {
      this.setState({
        secondStates: [],
        secondAddress: "",
        secondAddress2: "",
        secondCity: "",
        secondZipCode: "",
        secondStateId: "",
        secondCountryId: "",
        sameAddress: false
      })
      secondAddressError = ""
      secondCityError = ""
      secondZipCodeError = ""

    }
    else {
      this.setState({
        secondStates: this.state.firstStates,
        secondAddress: this.state.firstAddress,
        secondAddress2: this.state.firstAddress2,
        secondCity: this.state.firstCity,
        secondZipCode: this.state.firstZipCode,
        secondStateId: this.state.firstStateId,
        secondCountryId: this.state.firstCountryId,
        sameAddress: true
      })
      secondAddressError = ""
      secondCityError = ""
      secondZipCodeError = ""

    }
  }
  resetHandler = () => {

  };
  backUrl = () => {
    this.props.history.push("/sellarinformation");
  };
  render() {
    return (
      <body
        class="vertical-layout vertical-menu-modern semi-dark-layout 2-columns  navbar-floating footer-static  "
        data-open="click"
        data-menu="vertical-menu-modern"
        data-col="2-columns"
        data-layout="semi-dark-layout"
      >
        {/* <Header />
        <Sidebar /> */}
        <div class="app-content content">
          <div class="content-overlay"></div>
          <div class="header-navbar-shadow"></div>
          <div class="content-wrapper">
            <div class="content-header row">
              <p className="info-detail"
                dangerouslySetInnerHTML={{
                  __html: this.state.taxDescription
                }}
              />


            </div>
            <div class="content-body">
              <section id="dashboard-analytics">
                <div className="row step-box">
                  <div>
                    <div className="round-color-number-box c-box1">
                      <p className="round-color-number-page c-sub-box1">1</p>
                    </div>
                    <div className="t-point">Seller & Business <br />Information</div>
                  </div>
                  <div>
                    <div className="round-color-number-box c-box2">
                      <p className="round-color-number-page">2</p>
                    </div>
                    <div className="t-point c-sub-box2" >Address</div>
                  </div>
                  <div>
                    <div className="round-number-box">
                      <p className="round-number-page">3</p>
                    </div>
                    <div class="p-text t-point">
                      Payment
                      <br /> Method
                    </div>
                  </div>
                  {this.state.seller.type === "Retailer" ? <>
                    <div>
                      <div className="round-number-box">
                        <p className="round-number-page">4</p>
                      </div>
                      <div class="p-text t-point">Tax Forms</div>
                    </div>
                  </> : null}
                  <div>
                    <div className="round-none-number-box">
                      <p className="round-number-page"> {this.state.seller.type === "Retailer" ? 5 : 4}</p>
                    </div>
                    <div class="p-text t-point">Done</div>
                  </div>
                </div>

                <div class="card">
                  <div class="card-header" style={{ display: "initial" }}>
                    <div>
                      <h4 style={{ width: "100%" }}>Billing Address</h4>
                    </div>
                    <div>
                    </div>
                  </div>
                  <div class="card-body">
                    <div>
                      <h5>MAIN ADDRESS</h5>
                    </div>
                    <div>
                      <p style={{ fontSize: "12px" }}>
                        Please enter your detail as they appears on your bank record.P.O.Box is not allowed.
                      </p>{" "}
                    </div>
                    <div class="row">
                      <div class="col-xl-2 col-md-3 add-field">Address<span class="required-span1">*</span></div>
                      <div class="col-md-6">
                        <input
                          type="text"
                          class="form-control"
                          name="firstAddress"
                          placeholder="Address"
                          value={this.state.firstAddress}
                          onChange={this.handleChange}
                        />
                        <p class="p-error">{firstAddressError} </p>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-xl-2 col-md-3 add-field">Address2</div>
                      <div class="col-md-6">
                        <input
                          type="text"
                          class="form-control"
                          name="firstAddress2"
                          placeholder="Address2"
                          value={this.state.firstAddress2}
                          onChange={this.handleChange}
                        />
                        <p> </p>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-xl-2 col-md-3 add-field">City<span class="required-span1">*</span></div>
                      <div class="col-md-6">
                        <input
                          type="text"
                          class="form-control"
                          name="firstCity"
                          placeholder="City"
                          value={this.state.firstCity}
                          onChange={this.handleChange}
                        />
                        <p class="p-error">{firstCityError} </p>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-xl-2 col-md-3 add-field">Zip Code<span class="required-span1">*</span></div>
                      <div class="col-md-6">
                        <input
                          type="text"
                          class="form-control"
                          name="firstZipCode"
                          placeholder="Zip Code"
                          value={this.state.firstZipCode}
                          onChange={this.handleChange}
                        />
                        <p class="p-error">{firstZipCodeError} </p>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-xl-2 col-md-3 add-field">Country</div>
                      <div class="col-md-6">
                        <Select
                          value={this.state.countries.filter(
                            option => option.value === this.state.firstCountryId
                          )}
                          theme={theme => ({
                            ...theme,
                            borderRadius: "5px",
                            border: "0px solid",
                            colors: {
                              ...theme.colors,
                              text: "orangered",
                              primary25: "#9cf59c",
                              primary: "#47A547"
                            }
                          })}
                          options={this.state.countries}
                          name="brand"
                          onChange={val => this.firstCountryDropChangeHandler(val)}
                        />
                        <p> </p>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-xl-2 col-md-3 add-field">State</div>
                      <div class="col-md-6">
                        <Select
                          value={this.state.firstStates.filter(
                            option => option.value === this.state.firstStateId
                          )}
                          theme={theme => ({
                            ...theme,
                            borderRadius: "5px",
                            border: "0px solid",
                            colors: {
                              ...theme.colors,
                              text: "orangered",
                              primary25: "#9cf59c",
                              primary: "#47A547"
                            }
                          })}
                          options={this.state.firstStates}
                          name="brand"
                          onChange={val => this.firstStateDropChangeHandler(val)}
                        />
                        <p> </p>
                      </div>
                    </div>

                    <div>
                      <h5>ADDRESS2</h5>
                    </div>
                    <div>
                      <p style={{ fontSize: "12px" }}>
                        Please enter your detail as they appears on your bank record.P.O.Box is not allowed.
                      </p>{" "}
                    </div>
                    <div class="form-group row">
                      <div class="col-12" style={{
                        display: "flex",
                        justifyContent: "flex-start"
                      }}>
                        <fieldset class="checkbox">
                          <div class="vs-checkbox-con vs-checkbox-primary">
                            <input type="checkbox" checked={this.state.sameAddress} name="term" onChange={() => this.handleSameAddressChange()} />
                            <span class="vs-checkbox">
                              <span class="vs-checkbox--check" >
                                <i class="vs-icon feather icon-check"></i>
                              </span>
                            </span>
                            <span class="">
                              {" "}
                              Same address above
                            </span>

                          </div>
                          <p class="error-msg">{ }</p>

                        </fieldset>

                      </div>
                    </div>

                    <div class="row">
                      <div class="col-xl-2 col-md-3 add-field">Address<span class="required-span1">*</span></div>
                      <div class="col-md-6">
                        <input
                          type="text"
                          class="form-control"
                          name="secondAddress"
                          placeholder="Address"
                          value={this.state.secondAddress}
                          onChange={this.handleChange}
                          readOnly={this.state.sameAddress ? true : false}
                        />
                        <p class="p-error">{secondAddressError} </p>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-xl-2 col-md-3 add-field">Address2</div>
                      <div class="col-md-6">
                        <input
                          type="text"
                          class="form-control"
                          name="secondAddress2"
                          placeholder="Address2"
                          value={this.state.secondAddress2}
                          onChange={this.handleChange}
                          readOnly={this.state.sameAddress ? true : false}

                        />
                        <p> </p>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-xl-2 col-md-3 add-field">City<span class="required-span1">*</span></div>
                      <div class="col-md-6">
                        <input
                          type="text"
                          class="form-control"
                          name="secondCity"
                          placeholder="city"
                          value={this.state.secondCity}
                          onChange={this.handleChange}
                          readOnly={this.state.sameAddress ? true : false}

                        />
                        <p class="p-error">{secondCityError} </p>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-xl-2 col-md-3 add-field">Zip Code<span class="required-span1">*</span></div>
                      <div class="col-md-6">
                        <input
                          type="text"
                          class="form-control"
                          name="secondZipCode"
                          placeholder="Zip Code"
                          value={this.state.secondZipCode}
                          onChange={this.handleChange}
                          readOnly={this.state.sameAddress ? true : false}

                        />
                        <p class="p-error">{secondZipCodeError} </p>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-xl-2 col-md-3 add-field">Country</div>
                      <div class="col-md-6">
                        <Select
                          value={this.state.countries.filter(
                            option => option.value === this.state.secondCountryId
                          )}
                          theme={theme => ({
                            ...theme,
                            borderRadius: "5px",
                            border: "0px solid",
                            colors: {
                              ...theme.colors,
                              text: "orangered",
                              primary25: "#9cf59c",
                              primary: "#47A547"
                            }
                          })}
                          options={this.state.countries}
                          name="brand"
                          onChange={val => this.secondCountryDropChangeHandler(val)}
                          readOnly={this.state.sameAddress ? true : false}

                        />
                        <p> </p>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-xl-2 col-md-3 add-field">State</div>
                      <div class="col-md-6">
                        <Select
                          value={this.state.secondStates.filter(
                            option => option.value === this.state.secondStateId
                          )}
                          theme={theme => ({
                            ...theme,
                            borderRadius: "5px",
                            border: "0px solid",
                            colors: {
                              ...theme.colors,
                              text: "orangered",
                              primary25: "#9cf59c",
                              primary: "#47A547"
                            }
                          })}
                          options={this.state.secondStates}
                          name="brand"
                          onChange={val => this.secondStateDropChangeHandler(val)}
                          readOnly={this.state.sameAddress ? true : false}

                        />
                        <p> </p>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-6 mt-1">
                        <button
                          type="reset"
                          class="btn btn-outline-primary float-left btn-inline res-btn"
                          onClick={this.backUrl}
                        >
                          Back
                        </button>
                      </div>
                      <div class="col-6 d-flex flex-sm-row justify-content-end mt-1">
                        <button
                          type="submit"
                          class="btn btn-primary glow mb-1 mb-sm-0 mr-0 mr-sm-1 login-btn"
                          onClick={this.handleSubmit}
                        >
                          Next
                        </button>
                      </div>
                    </div>

                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>

        <div class="sidenav-overlay"></div>
        <div class="drag-target"></div>

        <Footer />
      </body>
    );
  }
}

export default Address;
