import React from 'react'
import { Link } from 'react-router-dom'
import Avtar from "../images/avtar.jpg"
import axios from 'axios'
import global from '../../global'


var seller = {}
class Downlode extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
        }
    }
    render() {
        return (
            <body
                class="vertical-layout vertical-menu-modern semi-dark-layout 2-columns  navbar-floating footer-static  "
                data-open="click"
                data-menu="vertical-menu-modern"
                data-col="2-columns"
                data-layout="semi-dark-layout"
            >

                <div class="app-content content">
                    <div class="content-overlay"></div>
                    <div class="header-navbar-shadow"></div>
                    <div class="content-wrapper">
                        <div class="content-header-left col-md-9 col-12 mb-2">
                            <div class="row breadcrumbs-top">
                                <div class="col-12">
                                    <h2 class="content-header-title float-left mb-0">
                                        Downlode
                                    </h2>
                                    <div class="breadcrumb-wrapper col-12">
                                        <ol class="breadcrumb">
                                            <li class="breadcrumb-item">
                                                <Link to="/dashboard">Dashboard</Link>
                                            </li>
                                            {/* <li class="breadcrumb-item active">Chat With Admin</li> */}
                                        </ol>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="content-body">
                            <section id="dashboard-analytics">
                            <div style={{itemAlign:'center'}}><iframe height="600" width="1100" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d470029.1604841957!2d72.29955005258641!3d23.019996818380896!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x395e848aba5bd449%3A0x4fcedd11614f6516!2sAhmedabad%2C%20Gujarat!5e0!3m2!1sen!2sin!4v1641299931417!5m2!1sen!2sin"/></div>
                            </section>
                        </div>
                    </div>
                </div>

            </body>
        )
    }
}
export default Downlode;