import React from 'react';
import { Link } from 'react-router-dom';
import './style.css';
import Avtar from '../images/avtar.jpg';
import axios from 'axios';
import global from '../../global';
class Chat extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      seller: {
        id: '',
      },
      users: [],
      userlist: [],
      selectedUser: {},
      selectUser: false,
      message: '',
      userMessage: [],
      userchatlist: [],
      time: '',
    };
  }
  //   componentDidMount() {
  //     if (localStorage.getItem('sellerdata')) {
  //       this.setState(
  //         { seller: JSON.parse(localStorage.getItem('sellerdata')) },
  //         () => {
  //           this.listAPI();
  //         }
  //       );
  //     }
  //   }
  handleSearchChange = (e) => {
    let search = e.target.value;
    let users = this.state.userlist;
    this.setState({ search: e.target.value });
    if (search) {
      console.log(search, 'ssgdhjg');

      users = users.filter((order) => {
        console.log(order);
        if (order.first_name) {
          const first_name = order.first_name;
          console.log('Working');
          return (
            first_name &&
            first_name.toLowerCase().includes(search.toLowerCase())
          );
        } else if (order.name) {
          const name = order.name;
          console.log('Working');
          return name && name.toLowerCase().includes(search.toLowerCase());
        } else return users;
      });
      this.setState({ users: users });
      this.setState({ userchatlist: users });
    } else {
      this.setState({ users: this.state.userlist });
      this.listAPI();
    }
  };
  listAPI() {
    var urlencoded = new URLSearchParams();
    urlencoded.append('userId', this.state.seller.id);

    axios
      .post(global.baseUrl + 'sellerfollowuserlist', urlencoded, {
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      })
      .then((result) => {
        console.log(result.data, 'result');
        if (result.data.status) {
          this.setState(
            {
              users: result.data.data,
              userlist: result.data.data,
            },
            () => {
              console.log(this.state.users);
            }
          );
          result.data.data.forEach((element) => {
            if (this.props.match.params.id === element.id) {
              console.log('match');
              this.onChatChange(element);
            }
          });
          let data1 = [];
          this.state.userlist.map((item) => {
            var urlencoded = new URLSearchParams();
            urlencoded.append('userId', item.id);
            urlencoded.append('recieverId', this.state.seller.id);

            axios
              .post(global.baseUrl + 'SellerChatList', urlencoded, {
                headers: {
                  'Content-Type': 'application/x-www-form-urlencoded',
                },
              })
              .then((result) => {
                console.log(result.data, 'result');
                if (result.data.status) {
                  data1.push(item);
                }
              });
              
          });
          this.setState({ userchatlist: data1 }, () => {
            console.log(this.state.users, 'dddd');
            // this.forceUpdate();
          });
          console.log(this.state.userchatlist, 'ddcheck');
          //   this.forceUpdate();
          // this.setState({ userMessage: [] });
          //   this.setState({ userchatlist: data1 });
        }
      });
  }
  onChatChange = (user) => {
    console.log(user);
    this.setState({ selectedUser: user, selectUser: true, userMessage: [] });
    var urlencoded = new URLSearchParams();
    urlencoded.append('userId', user.id);
    urlencoded.append('recieverId', this.state.seller.id);

    axios
      .post(global.baseUrl + 'SellerChatList', urlencoded, {
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      })
      .then((result) => {
        console.log(result.data, 'result');
        if (result.data.status) {
          this.setState({ userMessage: result.data.data, message: '' });
        }
      });
  };

  createChatAPI() {
    console.log(this.state.selectedUser);
    var urlencoded = new URLSearchParams();
    urlencoded.append('senderId', this.state.seller.id);
    urlencoded.append('recieverId', this.state.selectedUser.id);
    urlencoded.append('message', this.state.message);

    axios
      .post(global.baseUrl + 'SellerChatCreate', urlencoded, {
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      })
      .then((result) => {
        console.log(result.data, 'result');
        if (result.data.status) {
          this.setState({ message: '' });
          let userMessage = this.state.userMessage;
          userMessage.push(result.data.data[0]);
          this.setState({ userMessage });
        }
      });
  }
  onSendChange = (e) => {
    this.setState({ message: e.target.value });
  };
  // OnChatClick=()=>{
  //     console.log("Manish")
  //     $(".auto-suggestion").click(function() {
  //         $('html, body').animate({
  //             scrollTop: $(".chat-box").offset().top
  //         }, 1500);
  //     });
  // }
  //   shouldshouldComponentUpdate(nextProps, nextState) {
  //     if (this.state.userchatlist[0]) {
  //       console.log('enr=ter');
  //       return true;
  //     }
  //     return false;
  //   }
  componentDidMount() {
    if (localStorage.getItem('sellerdata')) {
      this.setState(
        { seller: JSON.parse(localStorage.getItem('sellerdata')) },
        () => {
          this.listAPI();
        }
      );
    }
    setTimeout(this.render(), 10000);
    this.interval = setInterval(
      () => this.setState({ time: Date.now() }),
      1000
    );
  }

  //   componentDidMount() {
  //     this.interval = setInterval(() => this.setState({ time: Date.now() }), 1000);
  //   }
  componentWillUnmount() {
    clearInterval(this.interval);
  }
  render() {
    return (
      <body
        class="vertical-layout vertical-menu-modern semi-dark-layout 2-columns  navbar-floating footer-static  "
        data-open="click"
        data-menu="vertical-menu-modern"
        data-col="2-columns"
        data-layout="semi-dark-layout"
      >
        <div class="app-content content">
          <div class="content-overlay"></div>
          <div class="header-navbar-shadow"></div>
          <div class="content-wrapper">
            <div class="content-header-left col-md-12 col-12 mb-2">
              <div class="row breadcrumbs-top">
                <div class="col-12">
                  <h2 class="content-header-title float-left mb-0">Chat</h2>
                  <div className="row">
                    <div class="breadcrumb-wrapper col-9">
                      <ol class="breadcrumb">
                        <li class="breadcrumb-item">
                          <Link to="/dashboard">Dashboard</Link>
                        </li>
                        <li class="breadcrumb-item active">Chat With Buyer</li>
                      </ol>
                    </div>
                    <div className="col-md-3 text-right chat-bred">
                      <Link to="/chatadmin" className="Contact-Adminstrator">
                        Contact Adminstrator
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="content-body">
              <section id="dashboard-analytics">
                <div class="card">
                  <div class="card-body" style={{ padding: '0px' }}>
                    <div>
                      <div class="row">
                        <div
                          class="col-xl-4 col-lg-4 col-md-4"
                          className="chat-left-side"
                          style={{ width: '350px' }}
                        >
                          <div
                            class="chat-fixed-search follow-search "
                            style={{
                              height: '70px',
                              paddingTop: '11px',
                              paddingLeft: '10px',
                            }}
                          >
                            <div
                              class="d-flex align-items-center"
                              style={{ marginTop: '-7px' }}
                            >
                              <div class="sidebar-profile-toggle position-relative d-inline-flex">
                                <div class="avatar">
                                  {this.state.seller.profile_image ? (
                                    <img
                                    className="media-object rounded-circle"
                                      src={this.state.seller.profile_image}
                                      alt="user_avatar"
                                      height="40"
                                      width="40"
                                    />
                                  ) : (
                                    <img
                                    className="media-object rounded-circle"
                                      src={Avtar}
                                      alt="user_avatar"
                                      height="40"
                                      width="40"
                                    />
                                  )}
                                  <span class="avatar-status-online"></span>
                                </div>

                                <div class="bullet-success bullet-sm position-absolute"></div>
                              </div>
                              <fieldset class="form-group position-relative has-icon-left mx-1 my-0 w-100 chatusr-name">
                                <input
                                  type="text"
                                  class="form-control round search1 chat-search"
                                  id="chat-search"
                                  placeholder="Search to follow users"
                                  value={this.state.search}
                                  onChange={this.handleSearchChange}
                                />
                                <div class="form-control-position">
                                  <i class="feather icon-search"></i>
                                </div>
                              </fieldset>
                            </div>
                          </div>

                          <ul className="chat-user-list">
                            {this.state.userchatlist[0] ? (
                              this.state.userchatlist.map((user, index) => (
                                <li class="auto-suggestion d-flex align-items-center cursor-pointer">
                                  <p
                                    class="d-flex align-items-center justify-content-between py-50 w-100"
                                    onClick={() => this.onChatChange(user)}
                                    style={{ paddingLeft: '30px' }}
                                  >
                                    <div class="d-flex align-items-center">
                                      <div class="avatar mr-50">
                                        {user.profile_image &&
                                        user.profile_image !== '' ? (
                                          <img
                                            src={user.profile_image}
                                            alt="png"
                                            height="32"
                                          />
                                        ) : (
                                          <img
                                            src={Avtar}
                                            alt="png"
                                            height="32"
                                          />
                                        )}
                                      </div>

                                      <div class="search-data">
                                        <p class="search-data-title mb-0">
                                          {user.name ? (
                                            user.name
                                          ) : (
                                            <>
                                              {user.first_name} {user.last_name}
                                            </>
                                          )}
                                        </p>
                                        {/* <small class="text-muted">{user.email}</small> */}
                                      </div>
                                    </div>
                                  </p>
                                </li>
                              ))
                            ) : (
                              <p
                                class="search-data-title mb-0"
                                style={{ marginLeft: 'auto' }}
                              >
                                No user
                              </p>
                            )}
                          </ul>
                        </div>
                        <div class="col lg-8">
                          {this.state.selectUser ? (
                            <div>
                              <div class="d-flex align-items-center chat-selected-user">
                                <div style={{ paddingLeft: '27px' }}>
                                  <div class="avatar">
                                    {this.state.selectedUser.profile_image ? (
                                      <img
                                      className="media-object rounded-circle"
                                        src={this.state.seller.profile_image}
                                        alt="user_avatar"
                                        height="40"
                                        width="40"
                                      />
                                    ) : (
                                      <img
                                      className="media-object rounded-circle"
                                        src={Avtar}
                                        alt="user_avatar"
                                        height="40"
                                        width="40"
                                      />
                                    )}
                                    <span class="avatar-status-online"></span>
                                  </div>

                                  <div class="bullet-success bullet-sm position-absolute"></div>
                                </div>
                                <fieldset class="form-group position-relative has-icon-left mx-1 my-0 w-100 chatusr-name">
                                  {this.state.selectedUser.name !== ''
                                    ? this.state.selectedUser.name
                                    : null}
                                  {this.state.selectedUser.first_name !== ''
                                    ? this.state.selectedUser.first_name
                                    : null}
                                </fieldset>
                              </div>
                              <div style={{ height: '280px' }} class="chat-box">
                                {this.state.userMessage.length
                                  ? this.state.userMessage.map((data, index) =>
                                      data.senderId === this.state.seller.id ? (
                                        <p className="right-chat">
                                          {data.message}
                                        </p>
                                      ) : (
                                        <p className="left-chat">
                                          {data.message}
                                        </p>
                                      )
                                    )
                                  : 'No chat'}
                              </div>
                              <fieldset
                                class=" position-relative has-icon-left mx-1 my-0 w-100 chatusr-name"
                                style={{ display: 'flex' }}
                              >
                                <input
                                  type="text"
                                  class="form-control round search1 chat-search"
                                  id="chat-search"
                                  placeholder="Enter message"
                                  value={this.state.message}
                                  name="message"
                                  onChange={this.onSendChange}
                                  style={{ width: '90%' }}
                                  onKeyPress={(event) => {
                                    if (event.key === 'Enter') {
                                      this.createChatAPI();
                                    }
                                  }}
                                />

                                <i
                                  class="fa fa-paper-plane"
                                  aria-hidden="true"
                                  style={{
                                    padding: '10px',
                                    fontSize: '18px',
                                    cursor: 'pointer',
                                  }}
                                  onClick={() => this.createChatAPI()}
                                ></i>
                              </fieldset>
                            </div>
                          ) : (
                            <div className="no-chat-div">
                              <h4>No Chat</h4>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </body>
    );
  }
}
export default Chat;
