import React from 'react';
import axios from 'axios';
import Select from 'react-select';
import ReactPhoneInput from "react-phone-input-2";
import 'react-phone-input-2/lib/style.css'
import IntlTelInput from 'react-intl-tel-input';
import Footer from '../../Component/Footer/Footer';
import global from '../../global';
import 'react-intl-tel-input/dist/main.css';
import './style.css';
var firstNameError = '';
var lastNameError = '';
var middleNameError = '';
var dobError = '';
var socialError = '';
var documentError = '';
var emailError = '';
var phoneNumberError = '';
var companyNameError = '';
var businessNameError = '';
var businessTypeError = '';
var taxIdError = '';
var desiredNameError = '';
var checkError = '';
var sendImage = '';
var regExp = new RegExp(/^[(]{0,1}[0-9]{3}[)]{0,1}[-\s\.]{0,1}[0-9]{3}[-\s\.]{0,1}[0-9]{4}$/);
// var regExp = '/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im'
// var IssuidError = '';
class Address extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      firstName: '',
      lastName: '',
      // Issuid: '',
      middleName: '',
      dob: '',
      socialNumber: '',
      document: '',
      email: '',
      companyName: '',
      businessName: '',
      businessTypeError: '',
      taxId: '',
      desiredName: '',
      check: false,
      phoneNumber: '',
      existingDoc: '',
      sellerType: [],

      types: [
        {
          label: 'Select Type',
          value: '',
        },
        {
          label: 'Partnership',
          value: 'Partnership',
        },
        {
          label: 'Corporation',
          value: 'Corporation',
        },
        {
          label: 'Other',
          value: 'Other',
        },
      ],
    };
    sendImage = '';
    firstNameError = '';
    lastNameError = '';
    middleNameError = '';
    dobError = '';
    socialError = '';
    documentError = '';
    emailError = '';
    companyNameError = '';
    businessNameError = '';
    businessTypeError = '';
    taxIdError = '';
    phoneNumberError = '';
  }
  componentWillMount() {
    if (localStorage.getItem('sellerdata')) {
      this.setState(
        { seller: JSON.parse(localStorage.getItem('sellerdata')) },
        () => {
          this.getTaxAPI();
        }
      );
    }
    axios
      .get(global.baseUrl + 'GetTaxDescription', {
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      })
      .then((result) => {
        if (result.data.status) {
          this.setState({ taxDescription: result.data.data.description });
        }
      });
  }

  // profileReset() {
  //   this.setState({
  //     firstName: '',
  //     lastName: '',
  //     middleName: '',
  //     dob: '',
  //     countryAlias: '',
  //     countryCode: '',
  //     phoneNumber: '',
  //     email: '',
  //     companyName: '',
  //     businessName: '',
  //     businessType: '',
  //     taxId: '',
  //     desiredName: '',
  //     document: '',
  //     existingDoc: '',
  //     socialNumber: '',
  //   });
  //   this.props.history.push('/sellarin');
  //   this.forceUpdate();

  // }
  getTaxAPI() {
    var urlencoded = new URLSearchParams();
    urlencoded.append('sellerId', this.state.seller.id);
    axios
      .post(global.baseUrl + 'sellergettaxform', urlencoded, {
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      })
      .then((result) => {
        console.log(result.data);
        if (result.data.status) {
          this.setState({
            firstName: result.data.data.first_name,
            lastName: result.data.data.last_name,
            middleName: result.data.data.middle_name,
            dob: result.data.data.dob,
            countryAlias: result.data.data.country_alias,
            countryCode: result.data.data.country_code,
            phoneNumber: result.data.data.phone_number,
            email: result.data.data.email,
            companyName: result.data.data.company_name,
            businessName: result.data.data.business_name,
            businessType: result.data.data.business_type,
            taxId: result.data.data.tax_Id,
            desiredName: result.data.data.desired_name,
            document: result.data.data.document,
            existingDoc: result.data.data.document,
            socialNumber: result.data.data.social_number,
            // Issuid: result.data.data.issue_Id,
          });
          let existingDoc = result.data.data.document.split('/');
          existingDoc = existingDoc[existingDoc.length - 1];
          this.setState({
            existingDoc: existingDoc,
          });
        }
      });
  }
  bTypeDropChangeHandler = (event) => {
    this.setState({
      businessType: event.value,
    });

    var urlencoded = new URLSearchParams();
    urlencoded.append('countryId', event.value);
    axios
      .post(global.baseUrl + 'sellerstatelist', urlencoded, {
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      })
      .then((result) => {
        console.log(result.data);
        if (result.data.status) {
          var option = [];
          option.push({
            label: 'Select',
            value: '',
          });
          for (let i = 0; i < result.data.data.length; i++) {
            option.push({
              label: result.data.data[i].name,
              value: result.data.data[i].id,
            });
          }
          this.setState(
            {
              states: option,
            },
            () => {
              console.log(this.state.states, 'jdhfjg');
            }
          );
        }
      });
  };
  stateDropChangeHandler = (event) => {
    this.setState({
      stateId: event.value,
    });
  };
  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };
  handlePhoneChange = (countryData, phoneNumber) => {
    let validated = phoneNumber.replace(/(\s|-)/g, '');
    this.setState({
      phoneNumber: validated,
      countryCode: countryData.dialCode,
      countryAlias: countryData.iso2,
    });
  };
  onImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      this.setState({ imagError: '' });
      documentError = '';
      let reader = new FileReader();
      reader.onload = (e) => {
        this.setState({
          document: e.target.result,
          existingDoc: '',
        });
      };
      reader.readAsDataURL(event.target.files[0]);
      sendImage = event.target.files[0];
    } else {
      this.setState({
        document: '',
        documentError: '',
      });
      sendImage = '';
      documentError = '';
    }
  };
  addImage = () => {
    if (sendImage) {
      const apiUrl = global.baseUrl + 'sellerImageUpload';
      const formData = new FormData();
      formData.append('myFile', sendImage);
      const config = {
        headers: {
          'content-type': 'multipart/form-data',
        },
      };
      axios
        .post(apiUrl, formData, config)
        .then((response) => {
          console.log('image add', response.data.filename);
          this.setState({ document: response.data.path });
          this.addApiCall();
        })
        .catch((error) => { });
    } else {
      this.addApiCall();
    }
  };
  addApiCall = () => {
    var urlencoded = new URLSearchParams();
    urlencoded.append('sellerId', this.state.seller.id);
    urlencoded.append('first_name', this.state.firstName);
    urlencoded.append('last_name', this.state.lastName);
    urlencoded.append('middle_name', this.state.middleName);
    // urlencoded.append("dob", this.state.dob.replace(/(\d\d)\/(\d\d)\/(\d{4})/, "$3-$1-$2"));
    urlencoded.append('dob', this.state.dob);
    urlencoded.append('phone_number', this.state.phoneNumber);
    urlencoded.append('country_code', this.state.countryCode);
    urlencoded.append('country_alias', this.state.countryAlias);
    urlencoded.append('email', this.state.email);
    urlencoded.append('company_name', this.state.companyName);
    urlencoded.append('business_name', this.state.businessName);
    urlencoded.append('business_type', this.state.businessType);
    urlencoded.append('tax_Id', this.state.taxId);
    urlencoded.append('desired_name', this.state.desiredName);
    urlencoded.append('document', this.state.document);
    urlencoded.append('social_number', this.state.socialNumber);
    // urlencoded.append('issue_Id', this.state.Issuid);

    axios
      .post(global.baseUrl + 'selleraddfirsttaxform', urlencoded, {
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      })
      .then((result) => {
        console.log(result.data);
        if (result.data.status) {
          this.props.history.push('/address');
        }
      });
  };
  allCheckHandler = () => {
    if (this.state.check) {
      this.setState({ check: false });
    } else {
      this.setState({ check: true });
    }
  };
  handleSubmit = (e) => {
    //this.props.history.push("/paymentmethod");
    e.preventDefault();
    let numberCheck = /^[0-9]+$/;
    let nameCheck = /^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/;

    if (this.state.phoneNumber !== '') {
      console.log(this.state.phoneNumber.length);
      if (
        !numberCheck.test(this.state.phoneNumber) ||
        this.state.phoneNumber.length <= 8 ||
        this.state.phoneNumber.length >= 12
      ) {
        this.setState({ phoneNumberError: 'Please enter valid number' });
        phoneNumberError = 'Please enter valid number';
      } else {
        this.setState({ phoneNumberError: '' });
        phoneNumberError = '';
      }
    } else {
      this.setState({ phoneNumberError: '' });
      phoneNumberError = '';
    }
    if (this.state.email === '') {
      this.setState({ emailError: 'Please enter contact email' });
      emailError = 'Please enter contact email';
    } else if (
      /^\w+([.-]?\w+)*@\w+([-]?\w+)*(\.\w{2,3})+$/.test(this.state.email)
    ) {
      this.setState({ emailError: '' });
      emailError = '';
    } else {
      this.setState({ emailError: 'Please enter valid contact email' });
      emailError = 'Please enter valid contact email';
    }
    if (this.state.firstName === '') {
      this.setState({ firstNameError: 'Please enter name' });
      firstNameError = 'Please enter first name';
    } else if (
      !this.state.firstName.match(/^[a-zA-Z-,]+(\s{0,1}[a-zA-Z-, ])*$/)
    ) {
      this.setState({ firstNameError: 'Please enter only character' });
      firstNameError = 'Please enter only character';
    } else {
      this.setState({ firstNameError: '' });
      firstNameError = '';
    }
    // if (this.state.Issuid === '') {
    //   this.setState({ IssuidError: 'Please Enter Issuid' });
    //   IssuidError = 'Please Enter Issuid';
    // } else {
    //   this.setState({ IssuidError: '' });
    //   IssuidError = '';
    // }
    if (this.state.lastName === '') {
      this.setState({ lastNameError: 'Please enter name' });
      lastNameError = 'Please enter last name';
    } else if (
      !this.state.lastName.match(/^[a-zA-Z-,]+(\s{0,1}[a-zA-Z-, ])*$/)
    ) {
      this.setState({ lastNameError: 'Please enter only character' });
      lastNameError = 'Please enter only character';
    } else {
      this.setState({ lastNameError: '' });
      lastNameError = '';
    }
    if (this.state.middleName !== '') {
      if (!this.state.middleName.match(/^[a-zA-Z-,]+(\s{0,1}[a-zA-Z-, ])*$/)) {
        this.setState({ middleNameError: 'Please enter only character' });
        middleNameError = 'Please enter only character';
      } else {
        this.setState({ middleNameError: '' });
        middleNameError = '';
      }
    } else {
      this.setState({ middleNameError: '' });
      middleNameError = '';
    }
    if (this.state.dob === '') {
      this.setState({ dobError: 'Please select dob' });
      dobError = 'Please select dob';
    } else if (this.state.dob.length > 10) {
      this.setState({ dobError: 'Please select valid dob' });
      dobError = 'Please select valid dob';
    } else {
      this.setState({ dobError: '' });
      dobError = '';
    }
    if (this.state.socialNumber === '') {
      this.setState({ socialError: 'Please enter number' });
      socialError = 'Please enter number';
    } else if (!numberCheck.test(this.state.socialNumber)) {
      this.setState({ socialError: 'Please enter valid number' });
      socialError = 'Please enter valid number';
    } else {
      this.setState({ socialError: '' });
      socialError = '';
    }
    if (this.state.document === '') {
      this.setState({ documentError: 'Please Upload Government Issued Id' });
      documentError = 'Please Upload Government Issued Id';
    } else {
      this.setState({ documentError: '' });
      documentError = '';
    }
    if (
      this.state.firstName &&
      this.state.lastName &&
      this.state.email &&
      this.state.dob &&
      this.state.socialNumber &&
      this.state.document &&
      this.state.phoneNumber &&
      firstNameError === '' &&
      lastNameError === '' &&
      emailError === '' &&
      dobError === '' &&
      socialError === '' &&
      documentError === '' &&
      phoneNumberError === ''
      // !IssuidError &&
      // IssuidError === ''
    ) {
      // if (this.state.check === false) {
      //   checkError = "Please agree term & condition"
      // }
      // else {
      //   console.log('working')
      //   //submitDisable = true;
      //   this.addImage();

      // }
      this.addImage();
    }
  };
  resetHandler = () => {
    this.setState({
      question: '',
      answer: '',
      topic: '',
    });
  };
  render() {
    return (
      <body
        class="vertical-layout vertical-menu-modern semi-dark-layout 2-columns  navbar-floating footer-static  "
        data-open="click"
        data-menu="vertical-menu-modern"
        data-col="2-columns"
        data-layout="semi-dark-layout"
      >
        {/* <Header />
        <Sidebar /> */}
        <div class="app-content content">
          <div class="content-overlay"></div>
          <div class="header-navbar-shadow"></div>
          <div class="content-wrapper">
            <div class="content-header row">
              <p
                className="info-detail"
                dangerouslySetInnerHTML={{
                  __html: this.state.taxDescription,
                }}
              />
            </div>
            <div class="content-body">
              <section id="dashboard-analytics">
                <div className="row step-box">
                  <div>
                    <div className="round-color-number-box c-box1">
                      <p className="round-color-number-page c-sub-box1">1</p>
                    </div>
                    <div className="t-point">
                      Seller & Business <br />
                      Information
                    </div>
                  </div>
                  <div>
                    <div className="round-number-box c-box2">
                      <p className="round-number-page">2</p>
                    </div>
                    <div className="t-point c-sub-box2">Address</div>
                  </div>
                  <div>
                    <div className="round-number-box">
                      <p className="round-number-page">3</p>
                    </div>
                    <div class="p-text t-point">
                      Payment
                      <br /> Method
                    </div>
                  </div>
                  {this.state.seller.type === 'Retailer' ? (
                    <>
                      <div>
                        <div className="round-number-box">
                          <p className="round-number-page">4</p>
                        </div>
                        <div class="p-text t-point">Tax Forms</div>
                      </div>
                    </>
                  ) : null}
                  <div>
                    <div className="round-none-number-box">
                      <p className="round-number-page">
                        {' '}
                        {this.state.seller.type === 'Retailer' ? 5 : 4}
                      </p>
                    </div>
                    <div class="p-text t-point">Done</div>
                  </div>
                </div>

                <div class="card">
                  <div class="card-header" style={{ display: 'initial' }}>
                    <div>
                      <h4 style={{ width: '100%' }}>Personal Information</h4>
                    </div>
                    <div>
                      <p style={{ margin: '0px' }}>
                        Please enter your detail as they appears on your bank
                        record.
                      </p>{' '}
                    </div>
                  </div>
                  <div class="card-body">
                    <div className="b-info-header">
                      <h6>PERSONAL INFO.</h6>
                    </div>
                    <div class="row">
                      <div class="col-xl-2 col-md-3 add-field">
                        First Name<span class="required-span1">*</span>
                      </div>
                      <div class="col-md-6">
                        <input
                          type="text"
                          class="form-control"
                          name="firstName"
                          placeholder="First Name"
                          value={this.state.firstName}
                          onChange={this.handleChange}
                        />
                        <p class="p-error">{firstNameError} </p>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-xl-2 col-md-3 add-field">Middle Name</div>
                      <div class="col-md-6">
                        <input
                          type="text"
                          class="form-control"
                          name="middleName"
                          placeholder="Middle Name"
                          value={this.state.middleName}
                          onChange={this.handleChange}
                        />
                        <p class="p-error">{middleNameError} </p>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-xl-2 col-md-3 add-field">
                        Last Name<span class="required-span1">*</span>
                      </div>
                      <div class="col-md-6">
                        <input
                          type="text"
                          class="form-control"
                          name="lastName"
                          placeholder="Last Name"
                          value={this.state.lastName}
                          onChange={this.handleChange}
                        />
                        <p class="p-error">{lastNameError} </p>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-xl-2 col-md-3 add-field">
                        DOB<span class="required-span1">*</span>
                      </div>
                      <div class="col-md-6">
                        <input
                          type="text"
                          class="form-control"
                          placeholder="mm/dd/yyyy"
                          name="dob"
                          value={this.state.dob}
                          onChange={this.handleChange}
                        />
                        <p class="p-error">{dobError} </p>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-xl-2 col-md-3 add-field">
                        Social Security No.<span class="required-span1">*</span>
                      </div>
                      <div class="col-md-6">
                        <input
                          type="text"
                          class="form-control"
                          name="socialNumber"
                          placeholder="Social Security Number"
                          value={this.state.socialNumber}
                          onChange={this.handleChange}
                        />
                        <p class="p-error">{socialError} </p>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-xl-2 col-md-3 add-field">
                        Government Issued ID<span class="required-span1">*</span>
                      </div>
                      <div class="col-md-6">
                        <div className="add-filed">File Upload</div>
                        <input
                          type="file"
                          class="form-control"
                          onChange={this.onImageChange}
                        />
                        <p>{this.state.existingDoc}</p>

                        <p class="p-error">{documentError} </p>
                      </div>
                    </div>
                    {/* <div class="row">
                      <div class="col-xl-2 col-md-3 add-field">
                      Government Issued ID<span class="required-span1">*</span>
                      </div>
                      <div class="col-md-6">

                        <input
                          type="text"
                          class="form-control"
                          name="Issuid"
                          placeholder="Issue Id"
                          value={this.state.Issuid}
                          onChange={this.handleChange}
                        />
                        <p>{this.state.existingDoc}</p>

                        <p class="p-error">{IssuidError} </p>
                      </div>
                    </div> */}
                    <div class="row">
                      <div class="col-xl-2 col-md-3 add-field">
                        Contact Email<span class="required-span1">*</span>
                      </div>
                      <div class="col-md-6">
                        <input
                          type="text"
                          class="form-control"
                          name="email"
                          placeholder="Contact Email"
                          value={this.state.email}
                          onChange={this.handleChange}
                        />
                        <p class="p-error">{emailError} </p>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-xl-2 col-md-3 add-field">
                        Phone Number
                      </div>
                      <div class="col-md-6">

                        <ReactPhoneInput
                          onlyCountries={["us"]}
                          defaultCountry="us"
                          placeholder="(702) 123-4567"
                          disableAreaCodes
                          disableCountryCode
                          disableDropdown
                          // country={this.state.countryAlias}
                          // disableCountryCode={true}
                          value={this.state.phoneNumber}
                          country="us"
                          // countryCodeEditable={false}
                          onChange={phoneNumber => this.setState({ phoneNumber })}
                          // defaultvalue={this.state.phoneNumber}
                          isValid={(value, country) => {
                            if (value === '') {
                              return 'Phone no required';
                            } else if (country === '') {
                              return 'country code required';
                            } else if (regExp.test(value)===false){
                              return 'Phone no note valide.';
                            } else {
                              return true;
                            }
                          }}
                        />
                        {/* <IntlTelInput
                          onPhoneNumberChange={(
                            status,
                            value,
                            countryData,
                            number,
                            id
                          ) => this.handlePhoneChange(countryData, value)}
                          containerClassName="intl-tel-input"
                          inputClassName="form-control"
                          //preferredCountries={["in"]}
                          style={{ width: '100%' }}
                          defaultCountry={this.state.countryAlias}
                          value={this.state.phoneNumber}
                        /> */}

                        <p class="p-error">{phoneNumberError} </p>
                      </div>
                    </div>
                    {/* {this.state.seller.type == 'Retailer' ? (
                      <> */}
                    <div className="b-info-header">
                      <h6>BUSINESS INFO.</h6>
                    </div>

                    <div class="row">
                      <div class="col-xl-2 col-md-3 add-field">
                        Company Name
                          </div>
                      <div class="col-md-6">
                        <input
                          type="text"
                          class="form-control"
                          name="companyName"
                          placeholder="Company Name"
                          value={this.state.companyName}
                          onChange={this.handleChange}
                        />
                        <p class="p-error">{companyNameError} </p>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-xl-2 col-md-3 add-field">
                        Business Name
                          </div>
                      <div class="col-md-6">
                        <input
                          type="text"
                          class="form-control"
                          name="businessName"
                          placeholder="Business Name"
                          value={this.state.businessName}
                          onChange={this.handleChange}
                        />
                        <p class="p-error">{businessNameError} </p>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-xl-2 col-md-3 add-field">
                        Business Type
                          </div>
                      <div class="col-md-6">
                        <Select
                          value={this.state.types.filter(
                            (option) =>
                              option.value === this.state.businessType
                          )}
                          theme={(theme) => ({
                            ...theme,
                            borderRadius: '5px',
                            border: '0px solid',
                            colors: {
                              ...theme.colors,
                              text: 'orangered',
                              primary25: '#9cf59c',
                              primary: '#47A547',
                            },
                          })}
                          options={this.state.types}
                          name="brand"
                          onChange={(val) =>
                            this.bTypeDropChangeHandler(val)
                          }
                        />
                        <p> </p>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-xl-2 col-md-3 add-field">Tax ID</div>
                      <div class="col-md-6">
                        <input
                          type="text"
                          class="form-control"
                          name="taxId"
                          placeholder="Tax Id"
                          value={this.state.taxId}
                          onChange={this.handleChange}
                        />
                        <p> </p>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-xl-2 col-md-3 add-field">
                        Desired Name
                          </div>
                      <div class="col-md-6">
                        <input
                          type="text"
                          class="form-control"
                          name="desiredName"
                          placeholder="Desired Name"
                          value={this.state.desiredName}
                          onChange={this.handleChange}
                        />
                        <p> </p>
                      </div>
                    </div>
                    {/* </>
                    ) : null} */}

                    {/* <div class="form-group row">
                      <div class="col-12">
                        <fieldset class="checkbox">
                          <div class="vs-checkbox-con vs-checkbox-primary">
                            <input type="checkbox" checked={this.state.check} name="term" onChange={this.allCheckHandler} />
                            <span class="vs-checkbox">
                              <span class="vs-checkbox--check" >
                                <i class="vs-icon feather icon-check"></i>
                              </span>
                            </span>
                            <span class="">
                              {" "}
                                         Click here to indicate that you have read and agree to
                                         the terms presented in the Terms and Condition agreement.
                                        </span>

                          </div>
                          <p class="error-msg">{checkError}</p>

                        </fieldset>

                      </div>
                    </div> */}

                    <div class="col-lg-8 col-md-9 d-flex flex-sm-row flex-column justify-content-end mt-1">
                      <button
                        type="submit"
                        class="btn btn-primary glow mb-1 mb-sm-0 mr-0 mr-sm-1 login-btn"
                        onClick={this.handleSubmit}
                      >
                        Next
                      </button>
                      <button
                        type="reset"
                        class="btn btn-outline-primary float-left btn-inline res-btn"
                        onClick={this.profileReset}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>

        <div class="sidenav-overlay"></div>
        <div class="drag-target"></div>

        <Footer />
      </body>
    );
  }
}

export default Address;
