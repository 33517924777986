import React from 'react';
import axios from 'axios';
import 'react-image-lightbox/style.css';

import { Link } from 'react-router-dom';
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
} from 'reactstrap';

import classnames from 'classnames';
import Footer from '../../Component/Footer/Footer';
import global from '../../global';
import Paypal from './PaypalBtn';
import StripeCheckout from 'react-stripe-checkout';
import 'react-toastify/dist/ReactToastify.css';
import money from './money.jpg';
import five from './five.jpg';
import ten from './ten.jpg';
import twenty from './twenty.jpg';
import moment from 'moment';

import { toast } from 'react-toastify';

import LoadingOverlay from 'react-loading-overlay';
import './style.css';
const CURRENCY = 'USD';
const fromDollarToCent = (amount) => parseInt(amount * 100);
var isActive = false;
var seller = {};
var budgetError = '';
var modal = false;
const WarnToast = () => {
  return (
    <div style={{ display: 'flex' }}>
      <i class="fa fa-info info-toast"></i>
      You have insufficient amount in wallet.
    </div>
  );
};

const WarnToast2 = () => {
  return (
    <div style={{ display: 'flex' }}>
      <i class="fa fa-info info-toast"></i>
      Sucessfully add product in renewal.
    </div>
  );
};
class Wallet extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      seller: {},
      id: this.props.match.params.id,
      activeTab: '1',
      photoIndex: 0,
      isOpen: false,
      budget: '',
      walletAmount: 0,
      list: [],
      showLogOut: false,
      messageto:'',
      defaultOptions: [],
      autoRenewalOptions: [],
      addOption: '',
      removeOption: '',
      productCharge: 0,
      finalWalletAmount: 0,
    };
    modal = false;
    budgetError = '';
    isActive = false;
  }
  componentWillMount() {
    if (localStorage.getItem('sellerdata')) {
      seller = JSON.parse(localStorage.getItem('sellerdata'));
      this.setState({ seller: JSON.parse(localStorage.getItem('sellerdata')) });
      this.getAmountAPI();
      let urlencoded1 = new URLSearchParams();
      urlencoded1.append('sellerId', seller.id);
      axios
        .post(global.baseUrl + 'SellerAllProductList', urlencoded1, {
          headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        })
        .then((result) => {
          if (result.data.status) {
            let defaultOptions = this.state.defaultOptions;
            let autoRenewalOptions = this.state.autoRenewalOptions;
            for (let i = 0; i < result.data.data.length; i++) {
              if (result.data.data[i].renewal === 'manual') {
                defaultOptions.push({
                  id: result.data.data[i].id,
                  label: result.data.data[i].product_name,
                });
              } else {
                if (result.data.data[i].renewal === 'auto') {
                  autoRenewalOptions.push({
                    id: result.data.data[i].id,
                    label: result.data.data[i].product_name,
                  });
                }
              }
            }
            this.setState({ defaultOptions, autoRenewalOptions });
          }
        });

      let urlencoded2 = new URLSearchParams();
      axios
        .post(global.baseUrl + 'SellerProductCharge', urlencoded2, {
          headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        })
        .then((result) => {
          console.log(result.data, 'charge amount');
          if (result.data.status) {
            this.setState({
              productCharge: parseFloat(result.data.data.product_charge),
              imageCharge: parseFloat(result.data.data.image_charge),
            });
          }
        });
    }
  }
  getAmountAPI = () => {
    let urlencoded = new URLSearchParams();
    urlencoded.append('sellerId', seller.id);
    axios
      .post(global.baseUrl + 'SellerGetWalletAmount', urlencoded, {
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      })
      .then((result) => {
        console.log(result.data, 'amount');
        if (result.data.status) {
          if (result.data.amount > 0) {
            this.setState({ walletAmount: parseFloat(result.data.amount) });
          }
        }
      });
  };
  handleChange = (event) => {
    this.setState({ budget: event.target.value });
  };
  submitHandler = () => {
    let numberCheck = /^[0-9]\d*(\.\d+)?$/;
    if (this.state.budget === '') {
      this.setState({ budgetError: 'Please enter budget' });
      budgetError = 'Please enter budget';
    } else if (!numberCheck.test(this.state.budget)) {
      this.setState({ budgetError: 'Please enter valid budget' });
      budgetError = 'Please enter valid budget';
    } 
    // else if (
    //   parseFloat(this.state.budget) < 5 ||
    //   parseFloat(this.state.budget) > 50
    // ) {
    //   this.setState({ budgetError: 'Please enter valid budget' });
    //   budgetError = 'Please enter  budget grater then $5';
    // } 
    else {
      budgetError = '';
      modal = true;
      this.setState({ budgetError: '' });
    }
  };
  onToken = (amount, description) => (token) => {
    isActive = true;
    this.setState({ a: '' });
    let urlencoded = new URLSearchParams();
    urlencoded.append('email', token.email);
    urlencoded.append('token', JSON.stringify(token));
    urlencoded.append('description', description);
    urlencoded.append('source', token.id);
    urlencoded.append('amount', parseInt(amount * 100));
    urlencoded.append('currency', CURRENCY);
    urlencoded.append('cardName', token.card.name);
    urlencoded.append('sellerId', seller.id);
    urlencoded.append('payment_type', 'credit_debit');
    urlencoded.append('debit', this.state.budget);

    axios
      .post(global.baseUrl + 'SellerWalletStripePayment', urlencoded)
      .then((result) => {
        console.log(result);

        if (result.data.status) {
          isActive = false;
          this.props.history.push('/paymentdone');
        }
        //successPayment();
      })
      .catch();
  };
  onSuccess = (data) => {
    isActive = true;
    this.setState({ A: '' });
    var urlencoded = new URLSearchParams();
    urlencoded.append('sellerId', seller.id);
    urlencoded.append('payment_type', 'paypal');
    urlencoded.append('paymentId', data.paymentID);
    urlencoded.append('debit', this.state.budget);

    axios
      .post(global.baseUrl + 'SellerAddWallet', urlencoded, {
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      })
      .then((result) => {
        if (result.data.status) {
          isActive = false;
          this.setState({ A: '' });
          this.props.history.push('/paymentdone');
        }
      });
  };
  transactionCanceled = () => {
    console.log('transaction cancel');
  };
  transactionError = () => {
    console.log('transaction error');
  };
  modalToggle = () => {
    if (modal === false) {
      modal = true;
    } else {
      modal = false;
    }
    this.setState({ A: '' });
  };
  toggle = (tab) => {
    if (tab === '1') {
      this.setState({ activeTab: '1' });
    } else if (tab === '2') {
      this.setState({ activeTab: '2' });
      let urlencoded = new URLSearchParams();
      urlencoded.append('sellerId', this.state.seller.id);
      axios
        .post(global.baseUrl + 'SellerWalletHistory', urlencoded, {
          headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        })
        .then((result) => {
          console.log(result.data, 'amount');
          if (result.data.status) {
            let amount = 0;
            let list = result.data.data;
            for (let i = result.data.data.length - 1; i >= 0; i--) {
              if (result.data.data[i].credit !== '0') {
                amount =
                  parseFloat(amount) - parseFloat(result.data.data[i].credit);
                list[i].amount = amount;
              } else {
                amount =
                  parseFloat(amount) + parseFloat(result.data.data[i].debit);
                list[i].amount = amount;
              }
            }
            this.setState({
              list: list,
              finalWalletAmount: result.data.amount,
            });
          }
        });
    }
  };
  defaultOptionHandler = (e) => {
    this.setState({ addOption: e.target.value });
  };
  autoOptionHandler = (e) => {
    this.setState({ removeOption: e.target.value });
  };
  
  addOptionHandler = () => {
    let addOption = '';
    let nextOption = '';
    if (
      this.state.addOption !== '' &&
      this.state.productCharge <= this.state.walletAmount
    ) {
      this.setState({messageto:'Are you sure for auto renewal product?It will take $ ' +this.state.productCharge})
      this.setState({showLogOut: true})
      // this.addOptionHandler2()
      // let r = window.confirm(
      //   'Are you sure for auto renewal product?It will take $ ' +
      //     this.state.productCharge
      // );
      // if (r) {
      //   let defaultOptions = this.state.defaultOptions;
      //   for (let i = 0; i < defaultOptions.length; i++) {
      //     if (this.state.addOption === defaultOptions[i].id) {
      //       addOption = defaultOptions[i];
      //       let urlencoded = new URLSearchParams();
      //       urlencoded.append('sellerId', seller.id);
      //       urlencoded.append(
      //         'productId',
      //         JSON.stringify(defaultOptions[i].id)
      //       );
      //       urlencoded.append('credit', this.state.productCharge);

      //       axios
      //         .post(
      //           global.baseUrl + 'SellerMultiProductAutoRenewal',
      //           urlencoded,
      //           {
      //             headers: {
      //               'Content-Type': 'application/x-www-form-urlencoded',
      //             },
      //           }
      //         )
      //         .then((result) => {
      //           console.log(result.data, 'amount');
      //           if (result.data.status) {
      //             //defaultOptions.splice(i, 1)
      //             window.location.reload();
      //           }
      //         });
      //       if (i !== defaultOptions.length - 1) {
      //         nextOption = defaultOptions[i + 1].id;
      //       }
      //       defaultOptions.splice(i, 1);
      //     }
      //   }
      //   let autoRenewalOptions = this.state.autoRenewalOptions;
      //   autoRenewalOptions.push(addOption);
      //   console.log(nextOption);
      //   this.setState({ addOption: nextOption });
      //   this.setState({
      //     defaultOptions,
      //     autoRenewalOptions,
      //   });
      // }
    } else {
      console.log('not working');
      toast.warn(<WarnToast />, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  addOptionHandler2=()=>{
    let addOption = '';
    let nextOption = '';
    let defaultOptions = this.state.defaultOptions;
        for (let i = 0; i < defaultOptions.length; i++) {
          if (this.state.addOption === defaultOptions[i].id) {
            addOption = defaultOptions[i];
            let urlencoded = new URLSearchParams();
            urlencoded.append('sellerId', seller.id);
            urlencoded.append(
              'productId',
              JSON.stringify(defaultOptions[i].id)
            );
            urlencoded.append('credit', this.state.productCharge);

            axios
              .post(
                global.baseUrl + 'SellerMultiProductAutoRenewal',
                urlencoded,
                {
                  headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                  },
                }
              )
              .then((result) => {
                console.log(result.data, 'amount');
                if (result.data.status) {
                  //defaultOptions.splice(i, 1)
                  window.location.reload();
                }
              });
            if (i !== defaultOptions.length - 1) {
              nextOption = defaultOptions[i + 1].id;
            }
            defaultOptions.splice(i, 1);
          }
        }
        let autoRenewalOptions = this.state.autoRenewalOptions;
        autoRenewalOptions.push(addOption);
        console.log(nextOption);
        this.setState({ addOption: nextOption });
        this.setState({
          defaultOptions,
          autoRenewalOptions,
        });
      }
  
  removeOptionHandler = () => {
    let removeOption = '';
    let nextOption = '';
    if (this.state.removeOption !== '') {
      let autoRenewalOptions = this.state.autoRenewalOptions;
      for (let i = 0; i < autoRenewalOptions.length; i++) {
        if (this.state.removeOption === autoRenewalOptions[i].id) {
          removeOption = autoRenewalOptions[i];
          let urlencoded = new URLSearchParams();
          urlencoded.append('sellerId', seller.id);
          urlencoded.append('productId', autoRenewalOptions[i].id);
          urlencoded.append('renewal', 'manual');
          urlencoded.append('debit', this.state.productCharge);
          axios
            .post(global.baseUrl + 'SellerMultiProductRenewal', urlencoded, {
              headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            })
            .then((result) => {
              console.log(result.data, 'amount');
              if (result.data.status) {
                // autoRenewalOptions.splice(i, 1)
                window.location.reload();
              }
            });
          if (i !== autoRenewalOptions.length - 1) {
            nextOption = autoRenewalOptions[i + 1].id;
          }
          autoRenewalOptions.splice(i, 1);
        }
      }
      let defaultOptions = this.state.defaultOptions;
      defaultOptions.push(removeOption);
      this.setState({ removeOption: nextOption });
      this.setState({
        defaultOptions,
        autoRenewalOptions,
      });
    }
  };
  addAllOptionHandler = () => {
    let defaultOptions = this.state.defaultOptions;
    let autoRenewalOptions = this.state.autoRenewalOptions;
    let total = defaultOptions.length * this.state.productCharge;
    if (total <= this.state.walletAmount) {
      this.setState({messageto:'Are you sure for auto renewal product?' + parseFloat(total).toFixed(2)})
      this.setState({showLogOut: true})
      // let r = window.confirm('Are you sure for auto renewal product?' + total);
      // if (r) {
      //   let data = [];
      //   let urlencoded = new URLSearchParams();
      //   urlencoded.append('sellerId', seller.id);
      //   for (let i = 0; i < defaultOptions.length; i++) {
      //     data.push(defaultOptions[i].id);
      //     // urlencoded.append("productId", defaultOptions[i].id);
      //     autoRenewalOptions.push(defaultOptions[i]);
      //   }
      //   urlencoded.append('productId', JSON.stringify(data));
      //   urlencoded.append('renewal', 'auto');
      //   urlencoded.append('credit', total);
      //   axios
      //     .post(global.baseUrl + 'SellerMultiProductAutoRenewal', urlencoded, {
      //       headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      //     })
      //     .then((result) => {
      //       console.log(result.data, 'amount');
      //       window.location.reload();
      //     });

      //   this.setState({
      //     defaultOptions: [],
      //     autoRenewalOptions,
      //   });
      // }
    } else {
      toast.warn(<WarnToast />, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  addAllOptionHandler2=()=>{
    let defaultOptions = this.state.defaultOptions;
    let autoRenewalOptions = this.state.autoRenewalOptions;
    let total = defaultOptions.length * this.state.productCharge;
    let data = [];
        let urlencoded = new URLSearchParams();
        urlencoded.append('sellerId', seller.id);
        for (let i = 0; i < defaultOptions.length; i++) {
          data.push(defaultOptions[i].id);
          // urlencoded.append("productId", defaultOptions[i].id);
          autoRenewalOptions.push(defaultOptions[i]);
        }
        urlencoded.append('productId', JSON.stringify(data));
        urlencoded.append('renewal', 'auto');
        urlencoded.append('credit', this.state.productCharge);
        axios
          .post(global.baseUrl + 'SellerMultiProductAutoRenewal', urlencoded, {
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
          })
          .then((result) => {
            console.log(result.data, 'amount');
            toast.warn(<WarnToast2 />, {
              position: toast.POSITION.TOP_RIGHT,
            });
            window.location.reload();
          });

        this.setState({
          defaultOptions: [],
          autoRenewalOptions,
        });
  }
  removeAllOptionHandler = () => {
    let defaultOptions = this.state.defaultOptions;
    let autoRenewalOptions = this.state.autoRenewalOptions;
    let data = [];
    let urlencoded = new URLSearchParams();
    urlencoded.append('sellerId', seller.id);
    console.log(autoRenewalOptions, 'option');
    for (let i = 0; i < autoRenewalOptions.length; i++) {
      data.push(autoRenewalOptions[i].id);
      // urlencoded.append("productId", autoRenewalOptions[i].id);
      defaultOptions.push(autoRenewalOptions[i]);
    }
    let total = defaultOptions.length * this.state.productCharge;
    urlencoded.append('productId', JSON.stringify(data));
    urlencoded.append('renewal', 'manual');
    urlencoded.append('debit',this.state.productCharge);
    axios
      .post(global.baseUrl + 'SellerMultiProductAutoRenewal', urlencoded, {
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      })
      .then((result) => {
        console.log(result.data, 'amount');
        toast.warn(<WarnToast2 />, {
          position: toast.POSITION.TOP_RIGHT,
        });
        window.location.reload();
      });

    this.setState({
      defaultOptions,
      autoRenewalOptions: [],
    });
  };
  render() {
    return (
      <body
        class="vertical-layout vertical-menu-modern semi-dark-layout test "
        data-open="click"
        data-menu="vertical-menu-modern"
        data-col="content-left-sidebar"
        data-layout="semi-dark-layout"
      >
        {/* <Header />
        <Sidebar /> */}
        <LoadingOverlay
          active={isActive}
          spinner
          text="Please wait we are processing your payment"
        />
        <div class="app-content content">
          <div class="content-overlay"></div>
          <div class="header-navbar-shadow"></div>
          <div class="content-wrapper">
            <div class="content-header row">
              <div class="content-header-left col-md-9 col-12 mb-2">
                <div class="row breadcrumbs-top">
                  <div class="col-12">
                    <h2 class="content-header-title float-left mb-0">Wallet</h2>
                    <div class="breadcrumb-wrapper col-12">
                      <ol class="breadcrumb">
                        <li class="breadcrumb-item">
                          <Link to="/dashboard">Home</Link>
                        </li>
                        <li class="breadcrumb-item active">Wallet</li>
                      </ol>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="content-body" style={{ paddingBottom: '0px' }}>
              <section id="add-row">
                <div class="row">
                  <div class="col-12">
                  <Modal isOpen={this.state.showLogOut} fade="false"  toggle={() => this.setState({showLogOut: false})}>
<ModalHeader toggle={() => this.setState({showLogOut: false})}>Warning</ModalHeader>
    <ModalBody>   
        <p>{this.state.messageto}</p>                     
    </ModalBody>
<ModalFooter>

    <Button onClick={() => this.setState({showLogOut: false})}>Cancel</Button>
    <Button color="info" onClick={()=>{this.setState({showLogOut: false,r:true});this.state.messageto.includes("It will take")?this.addOptionHandler2():this.addAllOptionHandler2();}}>Ok</Button>
</ModalFooter>
</Modal>
                    
                    <div class="card follow-box">
                      <div class="card-content ">
                        <div class="card col-12">
                          <Nav tabs style={{ padding: '20px' }}>
                            <NavItem>
                              <NavLink
                                className={classnames({
                                  active: this.state.activeTab === '1',
                                })}
                                onClick={() => {
                                  this.toggle('1');
                                }}
                              >
                                <span class="d-sm-block">
                                  Add Amount In Wallet
                                </span>
                              </NavLink>
                            </NavItem>
                            <NavItem>
                              <NavLink
                                className={classnames({
                                  active: this.state.activeTab === '2',
                                })}
                                onClick={() => {
                                  this.toggle('2');
                                }}
                              >
                                <span class="d-sm-block">
                                  Transaction History
                                </span>
                              </NavLink>
                            </NavItem>
                          </Nav>
                          <TabContent activeTab={this.state.activeTab}>
                            <TabPane tabId="1">
                              <div class="col-md-12 col-12 ">
                                <div
                                  class="card"
                                  style={{ marginBottom: '0px' }}
                                >
                                  <div class="card-header card-wallet-pl">
                                    <div class="card-title wallet-title">
                                      <input
                                        type="radio"
                                        style={{
                                          height: '13px',
                                          width: '13px',
                                          margin: '5px',
                                        }}
                                      ></input>
                                      <p>MANUAL RENEWAL</p>
                                    </div>
                                  </div>
                                  <div class="card-body card-wallet-padding">
                                    <div
                                      class="card-title mb-1 pl"
                                      style={{ fontSize: '16px' }}
                                    >
                                      Add Amount In Wallet
                                    </div>
                                    <section class="page-users-view">
                                      <p className="pl">
                                        Your total amount in wallet is{' '}
                                        <b>
                                          $ {this.state.walletAmount.toFixed(2)}
                                        </b>
                                        .
                                      </p>
                                      <div
                                        className="amt-wallet-box"
                                        style={{
                                          display: 'flex',
                                          overflow: 'auto',
                                        }}
                                      >
                                        <div className="amt-box">
                                          {/* <div className="wallet-amount-box" style={{ paddingLeft: "43px" }}> */}
                                          <div
                                            style={{
                                              padding: '3px',
                                              textAlign: 'center',
                                            }}
                                          >
                                            {/* <h2>$5</h2> */}
                                            <img
                                              src={five}
                                              style={{ height: '80px' }}
                                              className="wallet-img ml-61"
                                            ></img>
                                          </div>
                                          <div className="wallet-radio-div">
                                            <input
                                              type="radio"
                                              name="budget"
                                              value="5"
                                              checked={
                                                this.state.budget === '5'
                                                  ? true
                                                  : false
                                              }
                                              onChange={this.handleChange}
                                              className="ml-58"
                                              style={{
                                                height: '15px',
                                                width: '15px',
                                              }}
                                            ></input>
                                          </div>
                                        </div>
                                        <div className="amt-box">
                                          <div
                                            style={{
                                              paddingLeft: '43px',
                                              padding: '3px',
                                              textAlign: 'center',
                                            }}
                                          >
                                            {/* <h2>$10</h2> */}
                                            <img
                                              src={ten}
                                              className="wallet-img"
                                            ></img>
                                          </div>
                                          <div className="wallet-radio-div">
                                            <input
                                              type="radio"
                                              name="budget"
                                              value="10"
                                              checked={
                                                this.state.budget === '10'
                                                  ? true
                                                  : false
                                              }
                                              onChange={this.handleChange}
                                              style={{
                                                height: '15px',
                                                width: '15px',
                                              }}
                                            ></input>
                                          </div>
                                        </div>
                                        <div className="amt-box">
                                          <div
                                            style={{
                                              padding: '3px',
                                              textAlign: 'center',
                                            }}
                                          >
                                            {/* <h2>$20</h2> */}
                                            <img
                                              src={twenty}
                                              style={{ height: '80px' }}
                                              className="wallet-img"
                                            ></img>
                                          </div>
                                          <div className="wallet-radio-div">
                                            <input
                                              type="radio"
                                              name="budget"
                                              value="20"
                                              checked={
                                                this.state.budget === '20'
                                                  ? true
                                                  : false
                                              }
                                              onChange={this.handleChange}
                                              style={{
                                                height: '15px',
                                                width: '15px',
                                              }}
                                            ></input>
                                          </div>
                                        </div>
                                      </div>
                                      <div class="row">
                                        <div class="col-lg-2 col-md-3 ">
                                          <p
                                            style={{
                                              marginTop: '6px',
                                              fontSize: '13px',
                                            }}
                                            className="pl xs-pl-0"
                                          >
                                            Enter Amount
                                            <span class="required-span">*</span>
                                          </p>
                                        </div>

                                        <div class="col-lg-4 col-6 ">
                                          <div class="form-label-group position-relative has-icon-left">
                                            <input
                                              type="text"
                                              id="first-name-floating-icon"
                                              class="form-control"
                                              name="budget"
                                              value={this.state.budget}
                                              onChange={this.handleChange}
                                              placeholder="Enter Amount"
                                            />
                                            <div class="form-control-position">
                                              <i class="fa fa-dollar"></i>
                                            </div>

                                            <p class="error-msg">
                                              {budgetError}
                                            </p>
                                          </div>{' '}
                                        </div>
                                        <button
                                          class="btn btn-primary-green wallet-add-amount"
                                          type="button"
                                          onClick={() => this.submitHandler()}
                                        >
                                          Add Amount
                                        </button>
                                      </div>
                                    </section>
                                    <Modal
                                      isOpen={modal}
                                      toggle={this.modalToggle}
                                      style={{ width: '70%' }}
                                      contentClassName="custom-modal-style"
                                    >
                                      <ModalHeader toggle={this.modalToggle}>
                                        <h5 class="info-modal-title">
                                          Select Payment Method
                                        </h5>
                                      </ModalHeader>
                                      <ModalBody>
                                        <ul class="list-unstyled mb-0">
                                          <li>
                                            <div class="vs-radio-con model-resize-div">
                                              <Paypal
                                                totalPay={this.state.budget}
                                                onSuccess={this.onSuccess}
                                                transactionError={
                                                  this.transactionError
                                                }
                                                transactionCanceled={
                                                  this.transactionCanceled
                                                }
                                              />
                                            </div>
                                          </li>
                                          <li>
                                            <fieldset>
                                              <div class="vs-radio-con model-resize-div">
                                                <StripeCheckout
                                                  stripeKey="pk_test_51GtSo6BPY6CGrhq8PbbsO8Cd70i2F9wD7u4qk4gwCugvjMakwViTZIfsUVwmnU6qfQacoJUCpaGFSnvognvlK3HI00CIAYrPMY"
                                                  token={this.onToken(
                                                    this.state.budget,
                                                    'Set Amount'
                                                  )}
                                                  name="Garage Lane"
                                                  amount={fromDollarToCent(
                                                    this.state.budget
                                                  )}
                                                  currency={CURRENCY}
                                                >
                                                  <button
                                                    className="btn btn-success btn-primary resize-credit-debit-btn float-right mr-1 btn-inline waves-effect waves-light pay-btn"
                                                    data-dismiss="modal"
                                                  >
                                                    Credit/Debit Card
                                                  </button>
                                                </StripeCheckout>
                                              </div>
                                            </fieldset>
                                          </li>
                                        </ul>
                                      </ModalBody>
                                      <ModalFooter>
                                        <button
                                          type="button"
                                          class="btn btn-success"
                                          onClick={this.modalToggle}
                                        >
                                          OK
                                        </button>
                                        <button
                                          type="button"
                                          class="btn btn-success "
                                          onClick={this.modalToggle}
                                        >
                                          Cancel
                                        </button>
                                      </ModalFooter>
                                    </Modal>
                                  </div>
                                </div>
                                <div class="card" style={{ paddingTop: '0px' }}>
                                  <div class="card-header card-wallet-pl ">
                                    <div class="card-title wallet-title ">
                                      <input
                                        type="radio"
                                        style={{
                                          height: '13px',
                                          width: '13px',
                                          margin: '5px',
                                        }}
                                      ></input>
                                      <p className="automatic-renewal">
                                        AUTOMATIC RENEWAL
                                      </p>
                                    </div>
                                  </div>
                                  <div class="card-body card-wallet-padding">
                                    <div class="wallet-drop-area">
                                      <div class="form-control select-text wallet-drop-down">
                                        {/* <select class="form-control wallet-drop-down" size={5} onChange={(e) => this.defaultOptionHandler(e)}>
                                          {this.state.defaultOptions.map((data, index) => (
                                            <option value={data.id} key={index}>{data.label}</option>
                                          ))}
                                        </select> */}
                                        {this.state.defaultOptions.map(
                                          (data, index) => (
                                            <div
                                              class="form-check"
                                              key={index}
                                              onChange={(e) =>
                                                this.defaultOptionHandler(e)
                                              }
                                            >
                                              <div className="input-product-div ">
                                                <input
                                                  class="form-check-input"
                                                  type="radio"
                                                  name="flexRadioDefault"
                                                  id="flexRadioDefault1"
                                                  value={data.id}
                                                />
                                                <label
                                                  class="form-check-label"
                                                  for="flexRadioDefault1"
                                                >
                                                  {data.label}
                                                </label>
                                              </div>
                                            </div>
                                          )
                                        )}
                                      </div>
                                      <div class="wallet-move-btn wallet-mar">
                                        <button
                                          class="btn btn-outline-primary float-left btn-inline res-btn w-btn"
                                          onClick={() =>
                                            this.addOptionHandler()
                                          }
                                        >
                                          Add
                                        </button>
                                        <button
                                          class="btn btn-outline-primary float-left btn-inline res-btn w-btn"
                                          onClick={() =>
                                            this.addAllOptionHandler()
                                          }
                                        >
                                          Add All
                                        </button>
                                        <button
                                          class="btn btn-outline-primary float-left btn-inline res-btn w-btn"
                                          onClick={() =>
                                            this.removeOptionHandler()
                                          }
                                        >
                                          Remove
                                        </button>
                                        <button
                                          class="btn btn-outline-primary float-left btn-inline res-btn w-btn"
                                          onClick={() =>
                                            this.removeAllOptionHandler()
                                          }
                                        >
                                          Remove All
                                        </button>
                                      </div>
                                      <div class="form-control select-text wallet-drop-down">
                                        {/* <select class="form-control wallet-drop-down" size={5} onChange={(e) => this.autoOptionHandler(e)}>

                                          {this.state.autoRenewalOptions.map((data, index) => (
                                            <option value={data.id} key={index}>{data.label}</option>
                                          ))}
                                        </select> */}
                                        {this.state.autoRenewalOptions.map(
                                          (data, index) => (
                                            <div
                                              class="form-check"
                                              key={index}
                                              onChange={(e) => {
                                                this.autoOptionHandler(e);
                                              }}
                                            >
                                              <div className="input-product-div ">
                                                <input
                                                  class="form-check-input"
                                                  type="radio"
                                                  name="flexRadioDefault"
                                                  id="flexRadioDefault1"
                                                  value={data.id}
                                                />
                                                <label
                                                  class="form-check-label"
                                                  for="flexRadioDefault1"
                                                >
                                                  {data.label}
                                                </label>
                                              </div>
                                            </div>
                                          )
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </TabPane>
                            <TabPane tabId="2">
                              <div class="col-md-12 col-12 ">
                                <div class="card">
                                  <div
                                    class="table-responsive"
                                    style={{ fontSize: '16px' }}
                                  >
                                    {/* {console.log(products)} */}
                                    <table id="list" className="table">
                                      <thead>
                                        <tr>
                                          <th> Transaction History</th>
                                          <th>Debit</th>
                                          <th>Credit</th>
                                          <th>Balance</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {this.state.list.length ? (
                                          this.state.list.map((list, index) => (
                                            <tr key={index}>
                                              <td
                                                class=""
                                                style={{ minWidth: '367px' }}
                                              >
                                                <span
                                                  class="avatar m-0 avatar-md trigger-wallet-div"
                                                  style={{
                                                    background:
                                                      'url(' + money + ')',
                                                  }}
                                                >
                                                  <i></i>
                                                </span>
                                                {list.message}{' '}
                                                {list.productId
                                                  ? list.productId.product_name
                                                  : null}
                                                {/* <td class="truncate" style={{ fontSize: "13px" }}> */}
                                                <div
                                                  style={{
                                                    marginLeft: '40px',
                                                    fontSize: '14px',
                                                  }}
                                                >
                                                  {moment(
                                                    list.createdAt
                                                  ).format('DD-MM-YYYY,HH:mm')}
                                                  {/* </td> */}
                                                </div>
                                              </td>

                                              <td style={{ color: 'green' }}>
                                                {' '}
                                                ${parseFloat(list.debit).toFixed(2)}
                                              </td>
                                              <td
                                                style={{
                                                  color: 'red',
                                                  paddingLeft: '10px',
                                                }}
                                              >
                                                {' '}
                                                ${parseFloat(list.credit).toFixed(2)}
                                              </td>
                                              {/* {list.debit !== "0" && list.credit === "0" ?
                                                <td style={{ color: "green" }}> ${list.debit}</td>
                                                :
                                                null
                                              }
                                              {list.debit === "0" && list.credit !== "0" ?
                                                <td style={{ color: "red", paddingLeft: "10px" }}> ${list.credit}</td>
                                              : null} */}

                                              {/* {list.debit !== "0" && list.credit === "0" ?
                                                <td style={{ color: "green" }}> ${list.debit}</td>
                                                :
                                                null
                                              } */}

                                              <td>${parseFloat(list.amount).toFixed(2)}</td>
                                            </tr>
                                          ))
                                        ) : (
                                          <td colSpan="4">
                                            Transaction not Available
                                          </td>
                                        )}
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </div>
                            </TabPane>
                          </TabContent>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>

        <Footer />
        <div class="sidenav-overlay"></div>
        <div class="drag-target"></div>
      </body>
    );
  }
}
export default Wallet;
