import React from "react";
import axios from "axios";
import "react-image-lightbox/style.css";

import { Link } from "react-router-dom";
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  // Modal,
  // ModalHeader,
  // ModalBody,
  // ModalFooter,
  // Label
} from "reactstrap";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
  Button,
} from 'reactstrap';
import Select from "react-select";
import classnames from "classnames";
import Footer from "../../Component/Footer/Footer";
import global from "../../global";
// import Paypal from "./PaypalBtn";
// import StripeCheckout from "react-stripe-checkout";
import "react-toastify/dist/ReactToastify.css";
// import money from './money.jpg'
// import moment from 'moment'

import { toast } from "react-toastify";

import LoadingOverlay from "react-loading-overlay";
import "../../../assets/css/style.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
toast.configure()
const CustomToast = () => {
  return (
    <div>
      <i class="fa fa-check success-toast"></i>
    Discount added successfully!
    </div>
  )
}
const CustomToastDelete = () => {
  return (
    <div>
      <i class="fa fa-check success-toast"></i>
    Discount deleted successfully!
    </div>
  )
}
// const CURRENCY = "USD";
// const fromDollarToCent = amount => parseInt(amount * 100);
var isActive = false;
var seller = {};
var discount_error = "";
var code_error = ""
var modal = false;
var submitDisable = false;
var discount_type_error = ""
var discount_usageerror = ""
var never_expiresError = ""
var minOrderToQualifyError = ""
var start_Dateerror = ""
var end_Dateerror = ""
var order_totalerror=""
var order_quantityerror=""
var discount_amounterror=""
class Discount extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      discount_name: "",
      code_name: "",
      showLogOut: false,
      id1:0,
      messageto:'',
      start_Date: "",
      end_Date: "",
      seller: {},
      activeTab: "1",
      unlimited_usage: "",
      never_expires: "",
      discount_usage: "",
      discount_amount:"",
      order_quantity:"",
      order_total:"",
      minOrderToQualify: "none",
      discount_types: [{
        label: "Select Condition",
        value: ""
      },
      {
        label: "Percentage off",
        value: "Percentage off"
      },
      {
        label: "Fixed Amount off",
        value: "Fixed Amount off"
      },
      {
        label: "Free standard shiping",
        value: "Free standard shiping"
      }
      ],
      list: {},
      summary: [],
      discount_type: ""

    };
    modal = false;
    discount_error = "";
    code_error = "";
    discount_usageerror = "";
    order_quantityerror="";
    order_totalerror="";
    discount_amounterror="";
    discount_type_error = "";
    never_expiresError = "";
    minOrderToQualifyError = "";
    start_Dateerror = "";
    end_Dateerror = "";

    isActive = false
  }
  handleFromDateChange = date => {
    this.setState({
      start_Date: date
    })
  };
  handleEndDateChange = date => {
    this.setState({
      end_Date: date
    });
  };
  conditionDropChangeHandler = event => {
    this.setState({
      discount_type: event.value
    });
  };

  unlimited_usageCheck = (e) => {
    if (this.state.unlimited_usage === "unlimited_usage")
      this.setState({ unlimited_usage: "" })
    else
      this.setState({ unlimited_usage: e.target.value })
  }
  never_expiresCheck = (e) => {
    if (this.state.never_expires === "never_expires")
      this.setState({ never_expires: "" })
    else
      this.setState({ never_expires: e.target.value })
  }


  componentWillMount() {
    if (localStorage.getItem("sellerdata")) {
      this.setState({ seller: JSON.parse(localStorage.getItem("sellerdata")) });
    }
    let urlencoded = new URLSearchParams();
      urlencoded.append("sellerId", this.state.seller.id);
      axios
        .post(global.baseUrl + "sellerDiscountList", urlencoded, {
          headers: { "Content-Type": "application/x-www-form-urlencoded" }
        })
        .then(result => {
          console.log(result.data, "amount");
          if (result.data.status) {
            this.setState({
              summary: result.data.data,
            });
            this.toggle("2")
          }
        });
  }
  // getAmountAPI = () => {
  //   let urlencoded = new URLSearchParams();
  //   urlencoded.append("sellerId", seller.id);
  //   axios
  //     .post(global.baseUrl + "SellerGetWalletAmount", urlencoded, {
  //       headers: { "Content-Type": "application/x-www-form-urlencoded" }
  //     })
  //     .then(result => {
  //       console.log(result.data, "amount");
  //       if (result.data.status) {
  //         if (result.data.amount > 0) {
  //           this.setState({ walletAmount: parseFloat(result.data.amount) });
  //         }
  //       }
  //     });

  // }
  autoDiscountRadio = (e) => {
    this.setState({ minOrderToQualify: e.target.value })
  }
  handleChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };
  submitHandler = () => {
    console.log("enterdone");
    if (this.state.discount_name === "") {
      // this.setState({ discount_name: "Please enter discount name" });
      discount_error = "Please enter discount name";
    } else if (this.state.discount_name.trim() === "") {
      // this.setState({ discount_error: "Please enter valid discount name" });
      discount_error = "Please enter valid discount name";
    } else {
      // this.setState({ discount_error: "" });
      discount_error = "";
    }
    if (this.state.code_name === "") {
      // this.setState({ code_name: "Please enter code name" });
      code_error = "Please enter code name";
    } else if (this.state.code_name.trim() === "") {
      // this.setState({ code_error: "Please enter valid code name" });
      code_error = "Please enter valid discount name";
    } else {
      // this.setState({ code_error: "" });
      code_error = "";
    }
    if (this.state.start_Date === ""&&this.state.never_expires === "") {
      // this.setState({ start_Date: "Please enter start Date" });
      start_Dateerror = "Please enter start Date";
    }
    // } else if (this.state.start_Date.trim() === "") {
    //   // this.setState({ start_Dateerror: "Please enter valid start date" });
    //   start_Dateerror = "Please enter valid start date";
    // } 
    else {
      // this.setState({ start_Dateerror: "" });
      start_Dateerror = "";
    }

    if (this.state.end_Date === ""&&this.state.never_expires === "") {
      // this.setState({ start_Date: "Please enter end Date" });
      end_Dateerror = "Please enter end Date";
    }
    //  else if (this.state.end_Date.trim() === "") {
    //   // this.setState({ end_Dateerror: "Please enter valid end date" });
    //   end_Dateerror = "Please enter valid end date";
    // }
     else {
      // this.setState({ end_Dateerror: "" });
      end_Dateerror = "";
    }



    if (this.state.discount_type === "") {
      // this.setState({ discount_type: "Please enter discount type" });
      discount_type_error = "Please enter discount type ";
    } else if (this.state.discount_type.trim() === "") {
      // this.setState({ discount_type_error: "Please enter discount type" });
      discount_type_error = "Please enter discount type";
    } else {
      // this.setState({ discount_type_error: "" });
      discount_type_error = "";
    }

    if (this.state.discount_usage === ""&&this.state.unlimited_usage==="") {
      // this.setState({ discount_usage: "Please enter discount usage" });
      discount_usageerror = "Please enter discount usage ";
    } else if (this.state.discount_usage.trim() === ""&&this.state.unlimited_usage==="") {
      // this.setState({ discount_usageerror: "Please enter discount usage" });
      discount_usageerror = "Please enter discount usage";
    } else {
      //this.setState({ discount_usageerror: "" });
      discount_usageerror = "";
    }
    if (this.state.discount_amount ==="") {
      // this.setState({ discount_amounterror: "Please enter discount amount" });
      if(this.state.discount_type==="Free standard shiping"){
        discount_amounterror = "";
      }else{
        discount_amounterror = "Please enter discount amount ";
      }
    } else if (this.state.discount_amount.trim() === "") {
      // this.setState({ discount_amounterror: "Please enter discount usage" });
      discount_amounterror = "Please enter discount amount";
    } else {
      // this.setState({ discount_amounterror: "" });
      discount_amounterror = "";
    }
    if (this.state.order_quantity ==="") {
      // this.setState({ discount_amounterror: "Please enter discount amount" });
      if(this.state.minOrderToQualify !== "quantity"){
        order_quantityerror = "";
      }else{
        order_quantityerror = "Please enter order quantity";
      }
    } else if (this.state.order_quantity.trim() === "") {
      // this.setState({ discount_amounterror: "Please enter discount usage" });
      order_quantityerror = "Please enter order quantity";
    } else {
      // this.setState({ discount_amounterror: "" });
      order_quantityerror = "";
    }
    if (this.state.order_total ==="") {
      // this.setState({ discount_amounterror: "Please enter discount amount" });
      if(this.state.minOrderToQualify !== "Order total"){
        order_totalerror = "";
      }else{
        order_totalerror = "Please enter order total";
      }
    } else if (this.state.order_total.trim() === "") {
      // this.setState({ discount_amounterror: "Please enter discount usage" });
      order_totalerror = "Please enter order total";
    } else {
      // this.setState({ discount_amounterror: "" });
      order_totalerror = "";
    }
    if (this.state.minOrderToQualify === "") {
      // this.setState({ minOrderToQualifyError: "Please enter discount usage" });
      minOrderToQualifyError = "Please enter discount usage ";
    } else if (this.state.minOrderToQualify.trim() === "") {
      // this.setState({ minOrderToQualifyError: "Please enter discount usage" });
      minOrderToQualifyError = "Please enter discount usage";
    } else {
      // this.setState({ minOrderToQualifyError: "" });
      minOrderToQualifyError = "";
    }
    if (
      // this.state.discount_name &&
      // this.state.code_name &&
      // this.state.start_Date &&
      // this.state.end_Date &&
      // this.state.never_expires &&
      // this.state.discount_type &&
      // this.state.discount_usage &&
      // this.state.minOrderToQualify &&

      discount_error === "" &&
      code_error === "" &&
      start_Dateerror === "" &&
      end_Dateerror === "" &&

      discount_type_error === "" &&
      discount_usageerror === "" &&
      minOrderToQualifyError === ""&&
      order_quantityerror===""&&
      order_totalerror===""
    ) {
      submitDisable = true;
      this.AddDiscountAPI();
      console.log("ifenter")
    }else{
      this.forceUpdate();
    }
    //  else {
    //     discount_error = "";
    //     modal = true;
    //     this.setState({ discount_error: "" });
    //     console.log("wofjf")
    //     this.AddPamentPayoutAPI();
    //   }
    //  else {
    //   this.AddPamentPayoutAPI();

    // }
  };
  modalToggle = () => {
    if (modal === false) {
      modal = true;
    } else {
      modal = false;
    }
    this.setState({ A: "" });
  };
  Deleteproducthendeler=(id)=>{
    this.setState({messageto:'Are you sure to  delete  Discount?',id1:id,showLogOut2:true})
  }
  
  Deleteproducthendeler2=()=>{
    // this.setState({messageto:'Are you sure to  delete  product?',id1:id,showLogOut2:true})
    let urlencoded = new URLSearchParams();
    urlencoded.append("id",this.state.id1);


    axios
      .post(global.baseUrl+ "sellerDiscountdelete", urlencoded, {
        headers: { "Content-Type": "application/x-www-form-urlencoded" }
      })
      .then(result => {
        console.log(result.data, "amount");
        if (result.data.status==1) {
          this.toggle("2")
          toast.success(<CustomToastDelete />, { position: toast.POSITION.TOP_RIGHT })

          // if (result.data.amount > 0) {
          //   this.setState({ walletAmount: parseFloat(result.data.amount) });
          // }
        }
      });
  }
  toggle = tab => {
    if (tab === "1") {

      this.setState({
        activeTab: "1",
        discount_name: "",
        discount_name:"",
        code_name:"",
        start_Date:"",
        end_Date:"",
        never_expires:"",
        discount_type:"",
        discount_usage:"",
        discount_amount:"",
        order_quantity:"",
        order_total:"",
        unlimited_usage:"",
        minOrderToQualify:""
      });
    } else if (tab === "2") {
      this.setState({ activeTab: "2" });
      let urlencoded = new URLSearchParams();
      urlencoded.append("sellerId", this.state.seller.id);
      axios
        .post(global.baseUrl + "sellerDiscountList", urlencoded, {
          headers: { "Content-Type": "application/x-www-form-urlencoded" }
        })
        .then(result => {
          console.log(result.data, "amount");
          if (result.data.status) {
            this.setState({
              summary: result.data.data,
            });
            // this.toggle("2")
          }
        });
    }
  };
  AddDiscountAPI() {
    // if (this.state.payoutType == "manual") {
    let urlencoded = new URLSearchParams();
    urlencoded.append("sellerId", this.state.seller.id);
    urlencoded.append("discountName", this.state.discount_name);
    urlencoded.append("codeName", this.state.code_name);
    urlencoded.append("startDate", this.state.start_Date);
    urlencoded.append("endDate", this.state.end_Date);
    urlencoded.append("neverExpires", this.state.never_expires);
    urlencoded.append("discountType", this.state.discount_type);
    urlencoded.append("noOfdiscountUsage", this.state.discount_usage);
    urlencoded.append("discountamount", this.state.discount_amount);
    urlencoded.append("orderquantity", this.state.order_quantity);
    urlencoded.append("ordertotal", this.state.order_total);
    urlencoded.append("unlimitedUsage", this.state.unlimited_usage);
    urlencoded.append("minOrderToQualify", this.state.minOrderToQualify);



    axios
      .post(global.baseUrl + "sellerDiscount", urlencoded, {
        headers: { "Content-Type": "application/x-www-form-urlencoded" }
      })
      .then(result => {
        console.log(result.data, "amount");
        if (result.data.status) {
          this.toggle("2")
          toast.success(<CustomToast />, { position: toast.POSITION.TOP_RIGHT })

          // if (result.data.amount > 0) {
          //   this.setState({ walletAmount: parseFloat(result.data.amount) });
          // }
        }
      });
    // } 
    // else {
    //   console.log("4646445545454")
    //   let urlencoded = new URLSearchParams();
    //   urlencoded.append("sellerId", this.state.seller.id);
    //   urlencoded.append("payment_type", this.state.payoutType);
    //   // urlencoded.append("payment_amount", this.state.budget);
    //   urlencoded.append("automatic_payment", this.state.automaticPayment);

    //   axios
    //     .post(global.baseUrl + "addPaymentPayout", urlencoded, {
    //       headers: { "Content-Type": "application/x-www-form-urlencoded" }
    //     })
    //     .then(result => {
    //       console.log(result.data, "amount");
    //       if (result.data.status) {
    //         this.toggle("2")
    //         toast.success(<CustomToast />, { position: toast.POSITION.TOP_RIGHT })

    //         // if (result.data.amount > 0) {
    //         //   this.setState({ walletAmount: parseFloat(result.data.amount) });
    //         // }
    //       }
    //     });
    // }
  }
  render() {
    return (
      <body
        class="vertical-layout vertical-menu-modern semi-dark-layout content-left-sidebar chat-application navbar-floating footer-static  "
        data-open="click"
        data-menu="vertical-menu-modern"
        data-col="content-left-sidebar"
        data-layout="semi-dark-layout"
      >
        {/* <Header />
        <Sidebar /> */}
        <LoadingOverlay
          active={isActive}
          spinner
          text="Please wait we are processing your payment"
        />
        <div class="app-content content payment">
          <div class="content-overlay"></div>
          <div class="header-navbar-shadow"></div>
          <div class="content-wrapper">
            <div class="content-header row">
              <div class="content-header-left col-md-9 col-12 mb-2">
                <div class="row breadcrumbs-top">
                  <div class="col-12">
                    <h2 class="content-header-title float-left mb-0">
                      Discount
                    </h2>
                    <div class="breadcrumb-wrapper col-12">
                      <ol class="breadcrumb" >
                        <li class="breadcrumb-item">
                          <Link to="/dashboard">Home</Link>
                        </li>
                        <li class="breadcrumb-item active">Discount</li>
                      </ol>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="content-body" style={{ paddingBottom: "0px" }}>
              <section id="add-row">
                <div class="row">
                  <div class="col-12">
                    <div class="card follow-box">
                      <div class="card-content ">
                        <div class="card col-12">
                          <Nav tabs style={{ padding: "20px" }}>
                            <NavItem>
                              <NavLink
                                className={classnames({
                                  active: this.state.activeTab === "1"
                                })}
                                onClick={() => {
                                  this.toggle("1");
                                }}
                              >
                                <span class="d-sm-block">
                                  Add Discount
                            </span>
                              </NavLink>
                            </NavItem>
                            <NavItem>
                              <NavLink
                                className={classnames({
                                  active: this.state.activeTab === "2"
                                })}
                                onClick={() => {
                                  this.toggle("2");
                                }}
                              >
                                <span class="d-sm-block">
                                  Summary
                            </span>
                              </NavLink>
                            </NavItem>
                          </Nav>
                          <TabContent activeTab={this.state.activeTab}>
                            <TabPane tabId="1">
                              <div class="col-md-12 col-12 ">
                                <div style={{ padding: "2px" }}>

                                </div>
                              </div>


                              <div class="col-md-12 ">
                                <div className="row react-input-100" >

                                  <div className="col-lg-2 col-md-6 form-group sr-left">
                                    <label className="b-label">
                                      Discount Name
                                            <span class="required-span">*</span>
                                    </label>
                                  </div>
                                  <div className="col-lg-4 col-md-6 form-group sr-left">
                                    <input
                                      type="text"
                                      id="first-name-floating-icon"
                                      class="form-control"
                                      name="discount_name"
                                      value={this.state.discount_name}
                                      onChange={this.handleChange}
                                      placeholder="Enter discount"
                                    />

                                    <p class="error-msg2">{discount_error}</p>

                                  </div>
                                  <div className="col-lg-2 col-md-6 form-group sr-left">
                                    <label className="b-label">
                                      code Name
                                            <span class="required-span">*</span>
                                    </label>
                                  </div>
                                  <div className="col-lg-4 col-md-6 form-group sr-left">
                                    <input
                                      type="text"
                                      id="code_name"
                                      class="form-control"
                                      name="code_name"
                                      value={this.state.code_name}
                                      onChange={this.handleChange}
                                      placeholder="Enter code"
                                    />

                                    <p class="error-msg2">{code_error}</p>


                                  </div>
                                  <div className="col-lg-2 col-md-6 form-group sr-left">
                                    <label className="b-label" >
                                      Date From
                                            <span class="required-span">*</span>
                                    </label>
                                  </div>
                                  <div className="col-lg-4 col-md-6 form-group sr-left">
                                    <DatePicker
                                      selected={this.state.start_Date}
                                      onChange={this.handleFromDateChange}
                                      showMonthDropdown
                                      showYearDropdown
                                      dropdownMode="select"
                                      style={{ width: "100%" }}
                                      className="form-control"

                                    />
                                  </div>
                                  <div className="col-lg-2 col-md-6 form-group sr-left">
                                    <label className="b-label" style={{ marginRight: "80px" }}>
                                      To
                                            <span class="required-span">*</span>
                                    </label>

                                  </div>
                                  <div className="col-lg-4 col-md-6 form-group sr-left">
                                    <DatePicker
                                      selected={this.state.end_Date}
                                      onChange={this.handleEndDateChange}
                                      showMonthDropdown
                                      showYearDropdown
                                      dropdownMode="select"
                                      className="form-control"

                                      style={{ width: "100% " }}

                                    />
                                  </div>
                                  <div className="col-lg-6 col-md-12 form-group sr-left">
                                    <label className="b-label">
                                      <input type="checkbox"
                                        name="never_expires"
                                        style={{ height: "13px", width: "13px", margin: "5px" }}
                                        // className="form-control"
                                        value="never_expires"
                                        onChange={(e) => this.never_expiresCheck(e)}
                                        checked={this.state.never_expires === "never_expires"} />Never expires
                                              </label>

                                  </div>
                                  <div className="col-lg-2 col-md-6 form-group sr-left">
                                    <label className="b-label">
                                      Discount Type
                                            <span class="required-span">*</span>
                                    </label>
                                  </div>
                                  <div className="col-lg-4 col-md-6 form-group sr-left">
                                    <Select
                                      value={this.state.discount_types.filter(
                                        option =>
                                          option.value ===
                                          this.state.discount_type
                                      )}
                                      theme={theme => ({
                                        ...theme,
                                        borderRadius: "4px",
                                        border: "0px solid",
                                        colors: {
                                          ...theme.colors,
                                          text: "orangered",
                                          // primary25: "#9cf59c",
                                          primary: "#47A547"
                                        }
                                      })}
                                      options={this.state.discount_types}
                                      name="discount_type"
                                      style={{width:"100%"}}
                                      onChange={val =>
                                        this.conditionDropChangeHandler(
                                          val
                                        )
                                      }
                                    />
                                    <p class="error-msg2">
                                      {discount_type_error}
                                    </p>


                                  </div>
                                </div>

                                <div className="row">
                                  {
                                    this.state.discount_type==="Free standard shiping"?'':<>
                                      <div className="col-lg-2 col-md-6 form-group sr-left">
                                        <label className="b-label">
                                          Discount Amount
                                              <span class="required-span">*</span>
                                        </label>

                                      </div>
                                      <div className="col-lg-4 col-md-6 form-group sr-left">
                                        <input
                                          type="text"
                                          id="first-name-floating-icon"
                                          class="form-control"
                                          name="discount_amount"
                                          value={this.state.discount_amount}
                                          onChange={this.handleChange}
                                          placeholder="Enter discount amount"
                                        />

                                        <p class="error-msg2">{discount_amounterror}</p>
                                      </div>
                                  </>}
                                  <div className="col-lg-2 col-md-6 form-group sr-left">
                                    <label className="b-label">
                                      No of Discount Usage
                                            <span class="required-span">*</span>
                                    </label>

                                  </div>
                                  <div className="col-lg-4 col-md-6 form-group sr-left">
                                    <input
                                      type="text"
                                      id="first-name-floating-icon"
                                      class="form-control"
                                      name="discount_usage"
                                      value={this.state.discount_usage}
                                      onChange={this.handleChange}
                                      placeholder="Enter discount usage"
                                    />
                                    <p class="error-msg2">{discount_usageerror}</p>
    


                                  </div>
                                  <div className="col-lg-6 col-md-6 form-group sr-left">
                                    <label className="b-label">
                                      <input type="checkbox"
                                        name="unlimited_usage"
                                        style={{ height: "13px", width: "13px", margin: "5px" }}

                                        value="unlimited_usage"
                                        onChange={(e) => this.unlimited_usageCheck(e)}
                                        checked={this.state.unlimited_usage === "unlimited_usage"} />Unlimited Usage
                                              </label>


                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-lg-6 col-md-6 form-group sr-left dis-block">
                                    <label className="b-label">
                                      Minimum order to qualify
                                            {/* <span class="required-span">*</span> */}
                                      <p>When should i set a minimum?</p>
                                    </label>
                                    <label className="radio " >
                                      <input type="radio"
                                        name="minOrderToQualify"
                                        style={{ height: "13px", width: "13px", margin: "2px" }}

                                        value="None"
                                        onChange={(e) => this.autoDiscountRadio(e)}
                                        checked={this.state.minOrderToQualify === "None"} /> None


                                  <input type="radio"
                                        name="minOrderToQualify"
                                        style={{ height: "13px", width: "13px", margin: "5px" }}

                                        value="quantity"
                                        onChange={(e) => this.autoDiscountRadio(e)}
                                        checked={this.state.minOrderToQualify === "quantity"} />Quantity

                                  <input type="radio"
                                        name="minOrderToQualify"
                                        style={{ height: "13px", width: "13px", margin: "5px" }}

                                        value="Order total"
                                        onChange={(e) => this.autoDiscountRadio(e)}
                                        checked={this.state.minOrderToQualify === "Order total"} />Order total
                                  </label>

                                  </div>
                              {this.state.minOrderToQualify === "quantity"?<>
                                  <div className="col-lg-2 col-md-6 form-group sr-left">
                                    <label className="b-label">
                                      Order Quantity
                                          <span class="required-span">*</span>
                                    </label>

                                  </div>
                                  <div className="col-lg-4 col-md-6 form-group sr-left">
                                    <input
                                      type="text"
                                      id="first-name-floating-icon"
                                      class="form-control"
                                      name="order_quantity"
                                      value={this.state.order_quantity}
                                      onChange={this.handleChange}
                                      placeholder="Enter order quantity"
                                    />

                                    <p class="error-msg2">{order_quantityerror}</p>
                                  </div>
                              </>:this.state.minOrderToQualify === "Order total"?<>
                                <div className="col-lg-2 col-md-6 form-group sr-left">
                                  <label className="b-label">
                                    Order Total
                                        <span class="required-span">*</span>
                                  </label>

                                </div>
                                <div className="col-lg-4 col-md-6 form-group sr-left">
                                  <input
                                    type="text"
                                    id="first-name-floating-icon"
                                    class="form-control"
                                    name="order_total"
                                    value={this.state.order_total}
                                    onChange={this.handleChange}
                                    placeholder="Enter Order total"
                                  />

                                  <p class="error-msg2">{order_totalerror}</p>
                                </div>
                              </>:''}
                                  {/* <div className="col-lg-6 col-md-6 form-group sr-left"> */}
                                    {/* <label className="radio" >
                                      <input type="radio"
                                        name="minOrderToQualify"
                                        style={{ height: "13px", width: "13px", margin: "2px" }}

                                        value="None"
                                        onChange={(e) => this.autoDiscountRadio(e)}
                                        checked={this.state.minOrderToQualify === "None"} /> None


                                  <input type="radio"
                                        name="minOrderToQualify"
                                        style={{ height: "13px", width: "13px", margin: "5px" }}

                                        value="quantity"
                                        onChange={(e) => this.autoDiscountRadio(e)}
                                        checked={this.state.minOrderToQualify === "quantity"} />Quantity

                                  <input type="radio"
                                        name="minOrderToQualify"
                                        style={{ height: "13px", width: "13px", margin: "5px" }}

                                        value="Order total"
                                        onChange={(e) => this.autoDiscountRadio(e)}
                                        checked={this.state.minOrderToQualify === "Order total"} />Order total
                                  </label> */}

                                  {/* </div> */}

                                </div>
                              </div>








                              <div class="card" style={{ paddingTop: "0px" }}>
                                <div class="card-header row">
                                  <div class="card-title wallet-title">
                                    <button
                                      class="btn btn-primary-green wallet-add-amount"
                                      type="button"
                                      onClick={() => this.submitHandler()}
                                    >
                                      Discount
                                    </button>
                                  </div>
                                </div>
                              </div>

                            </TabPane>
                            <TabPane tabId="2">
                            <Modal isOpen={this.state.showLogOut2} fade="false"  toggle={() => this.setState({showLogOut2: false})}>
<ModalHeader toggle={() => this.setState({showLogOut2: false})}>Warning</ModalHeader>
    <ModalBody>   
        <p>{this.state.messageto}</p>                     
    </ModalBody>
<ModalFooter>

    <Button onClick={() => this.setState({showLogOut2: false})}>Cancel</Button>
    <Button color="info" onClick={()=>{this.setState({showLogOut2: false,r:true});this.Deleteproducthendeler2();}}>Ok</Button>
</ModalFooter>
</Modal>
                              <div class="col-md-12 col-12 ">
                                <div class="card">
                                  <div class="row">
                                    <div class="">
                                      {/* <div class="card-title mb-11">
                                       Summary
                                    </div> */}
                                    </div>
                                    <div className="table-responsive">
                                      <table id="list" className="table">
                                      <thead>
                                        <tr>
                                          <th style={{minWidth:"230px"}}>Date</th>
                                          <th style={{minWidth:"180px"}}>Display Name</th>
                                          <th style={{minWidth:"145px"}}>Code Name</th>
                                          <th style={{minWidth:"153px"}}>Discount Type</th>
                                          <th style={{minWidth:"190px"}}>Min. Order to qualify</th>
                                          <th style={{minWidth:"185px"}}>No of customer use</th>
                                          <th style={{minWidth:"185px"}}>Action</th>
                                        </tr>
                                        
                                        </thead>
                                        <tbody>
                                        {this.state.summary.length
                                      ? this.state.summary.map((list, index) => (
                                        <tr key={index}>
                                            <td >{list.createdAt}</td>
                                            <td>{list.discountName}</td>
                                            <td>{list.codeName}</td>
                                            <td>{list.discountType}</td>

                                            <td>{list.minOrderToQualify}</td>
                                            <td>{list.noOfdiscountUsage}</td>
                                            <td><Link
                                                to={
                                                  '/editdiscount/' + list.id
                                                }
                                              >
                                                <i
                                                  className="fa fa-pencil"
                                                  style={{
                                                    marginLeft: '10px',
                                                    marginRight: '10px',
                                                    marginTop: '7px',
                                                    color: 'green',
                                                  }}
                                                ></i>
                                              </Link>
                                              <button
                                                className="newdlt_btn"
                                                onClick={() =>
                                                  this.Deleteproducthendeler(list.id)
                                                }
                                              >
                                                <i
                                                  className="fa fa-trash list-of-trash"
                                                  style={{
                                                    marginRight: '6px',
                                                    marginTop: '7px',
                                                  }}
                                                ></i>
                                              </button></td>




                                        </tr>
                                      ))
                                      : <td>No summary available</td>}

                                          </tbody>
                                      </table>
                                    </div>
                                   
                                  </div>
                                  
                                </div>
                              </div>
                            </TabPane>
                          </TabContent>

                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div >

        <Footer />
        <div class="sidenav-overlay"></div>
        <div class="drag-target"></div>
      </body >

    );
  }
}
export default Discount;
