import React, { Component } from "react";
import axios from "axios";
import global from "../../global";
import {
    Button,
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    Col,
    FormGroup,
    FormText,
    Input,
    Label,
    Row
} from "reactstrap";
import "./style.css";
import { Link } from "react-router-dom";
import { AddContainer } from "./AddForm.style";
import CKEditor from "react-ckeditor-component";
var questionerror = "";
var descriptionError = "";
var submitDisable = false;
var topicError = "";
var description = ""

class EditForum extends React.Component {
    constructor(props) {
        super(props);

        this.toggle = this.toggle.bind(this);
        this.toggleFade = this.toggleFade.bind(this);
        this.state = {
            collapse: true,
            fadeIn: true,
            timeout: 300,
            topic: "",
            topics:[],
            description: "",
            descriptionError: "",
        };
        questionerror = "";
        descriptionError = "";
        submitDisable = false;
    }
    handleChange = event => {
        this.setState({ [event.target.name]: event.target.value });
    };
    onDescriptionChange = evt => {
        description = evt.editor.getData();
      };
    
    toggle() {
        this.setState({ collapse: !this.state.collapse });
    }
    toggleFade() {
        this.setState(prevState => {
            return { fadeIn: !prevState };
        });
    }
    dropChangeHandler = event => {
        this.setState({
            topic: event.value
        });
    };
 
    submitHandler = () => {
        if (this.state.topic === "") {
            this.setState({ topicError: "Please enter topic" });
            topicError = "Please enter topic";
        }
        else if (this.state.topic.trim() === "") {
            this.setState({ topicError: "Please enter valid topic" });
            topicError = "Please enter valid topic";
        }
        else {
            this.setState({ topicError: "" });
            topicError = "";
        }
        if (this.state.description === "") {
            this.setState({ descriptionError: "Please enter description" });
            descriptionError = "Please enter description";
        }
        
        else if (this.state.topic.trim() === "") {
            this.setState({ descriptionError: "Please enter valid description" });
            descriptionError = "Please enter valid description";
        }
        else {
            this.setState({ descriptionError: "" });
            descriptionError = "";
        }
        if (
            this.state.topic &&
            topicError === "" &&
            descriptionError === ""
        ) {
            //console.log("working");
            submitDisable = true;
            this.EditFAQ();
        }
    };

    resetHandler = () => {
        this.setState({
            topic: "",
            description: ""
        });
        topicError = "";
    };


    componentWillMount() {
        var urlencoded = new URLSearchParams();
        urlencoded.append("id", this.props.match.params.id);
        axios
            .post(global.baseUrl + "getAdminFAQ/", urlencoded, {
                headers: { "Content-Type": "application/x-www-form-urlencoded" }
            })
            .then(result => {
                if (result.data) {
                    console.log("result faq", result.data.data.Description);
                    this.setState({
                        id: result.data.data.id,
                        topic: result.data.data.topic,
                        description: result.data.data.Description[0]
                    });
                }
            });
    }

    EditFAQ() {
        var urlencoded = new URLSearchParams();
        urlencoded.append("id", this.state.id);
        urlencoded.append("topic", this.state.topic);
        urlencoded.append("Description", description);

        axios
            .post(global.baseUrl + "UpdateAdminFAQ", urlencoded, {
                headers: { "Content-Type": "application/x-www-form-urlencoded" }
            })
            .then(result => {
                if (result.data) {
                    this.props.history.push("/sellerforum");
                    //window.location.reload()
                    console.log(result.data);
                }
            });
    }







    render() {
        return (
            <body
                class="vertical-layout vertical-menu-modern semi-dark-layout 2-columns  navbar-floating footer-static  "
                data-open="click"
                data-menu="vertical-menu-modern"
                data-col="2-columns"
                data-layout="semi-dark-layout"
            >
                <div class="app-content content">
                    <div class="content-overlay"></div>
                    <div class="header-navbar-shadow"></div>
                    <div class="content-wrapper" style={{ minHeight: "70vh" }}>
                        <div class="content-header row">
                            <div class="content-header-left col-md-9 col-12 mb-2">
                                <div class="row breadcrumbs-top">
                                    <div class="col-12">
                                        <h2 class="content-header-title float-left mb-0">GARAGE LANE FORUM </h2>
                                        <div class="breadcrumb-wrapper col-12">
                                            <ol class="breadcrumb">
                                                <li class="breadcrumb-item">
                                                    <Link to="/">Home</Link>
                                                </li>
                                                {/* <li class="breadcrumb-item active">Forum</li> */}
                                                <li class="breadcrumb-item"><Link to="/forum">Forum</Link></li>
                                                <li class="breadcrumb-item active">Edi Forum</li>
                                            </ol>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <AddContainer>
                            <div className="animated fadeIn">
                                <Row>
                                    <Col xs="12" md="11">
                                        <Card>
                                            <CardHeader>
                                                <strong>Edit Forum</strong>
                                            </CardHeader>
                                            <CardBody>
                                                <FormGroup row>
                                                    <Col md="3">
                                                        <Label htmlFor="text-input">Topic Detail </Label>
                                                    </Col>
                                                    <Col xs="12" md="9">
                                                        <Input
                                                            type="text"
                                                            name="topic"
                                                            row={5}
                                                            placeholder="Enter the Topic"
                                                            value={this.state.topic}
                                                            onChange={this.handleChange}
                                                        />
                                                        <FormText>
                                                            <p className="login-error">{topicError}</p>
                                                        </FormText>
                                                    </Col>
                                                </FormGroup>
                                                <FormGroup row>
                                                    <Col md="3">
                                                        <Label htmlFor="text-input"> Topic Description</Label>
                                                    </Col>
                                                    <Col xs="12" md="9">
                                                        {/* <Input
                                                        type="textarea"
                                                        name="description"
                                                        row={5}
                                                        placeholder="Enter the Description"
                                                        value={this.state.description}
                                                        onChange={this.handleChange}
                                                    /> */}
                                                        <CKEditor
                                                            activeClass="p10"
                                                            content={this.state.description}
                                                            events={{
                                                                change: this.onDescriptionChange
                                                            }}
                                                        />
                                                        <FormText>
                                                            <p className="login-error">{descriptionError}</p>
                                                        </FormText>
                                                    </Col>
                                                </FormGroup>
                                                {/* <FormGroup row>
                        <Col md="3">
                        <Label htmlFor="textarea-input">Answer</Label>
                        </Col>
                        <Col xs="12" md="9">
                        <Input
                            type="textarea"
                            name="answer"
                            rows="4"
                            placeholder="Answer..."
                            value={this.state.answer}
                            onChange={this.handleChange}
                        />
                        <FormText className="help-block">
                            <p className="login-error">{answererror}</p>
                        </FormText>
                        </Col>
                    </FormGroup> */}
                                            </CardBody>
                                            <CardFooter>
                                                <Button
                                                    type="submit"
                                                    size="sm"
                                                    color=""
                                                    className="additem2"
                                                    disabled={submitDisable}
                                                    onClick={this.submitHandler}
                                                >
                                                    {" "}
                                                    Submit
                                                </Button>
                                                <Button size="sm" color="" className="additem3" onClick={this.resetHandler}>
                                                    Reset
                                                </Button>
                                            </CardFooter>
                                        </Card>
                                    </Col>
                                </Row>
                            </div>
                        </AddContainer>
                    </div>
                </div>
            </body>
        );
    }
}

export default EditForum;