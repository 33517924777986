import React from "react";
import axios from "axios";
import "react-image-lightbox/style.css";

import { Link } from "react-router-dom";
// import { Table } from 'reactstrap';

import Footer from "../../Component/Footer/Footer";
import global from "../../global";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";
import { data } from "jquery";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { element } from "prop-types";

const $ = require("jquery");
$.DataTable = require("datatables.net");

// import { toast } from "react-toastify";

// toast.configure()
// const CustomToast = () => {
//     return (
//         <div>
//             <i class="fa fa-check success-toast"></i>
//     Payout Successfully
//         </div>
//     )
// }
// const CURRENCY = "USD";
// const fromDollarToCent = amount => parseInt(amount * 100);
var seller = {};

class AllTransactionHistory extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      seller: {},
      activeTab: "1",
      payoutType: "manual",
      startDate: "",
      endDate: "",
      budget: 0,
      paymentAmount: 0,
      automaticPayment: "1st of the month",
      list: [],
      Bannerprice: [],
      TranjectionList: [],
      FilteredTranjectionList: [],
      ProductBannerprice: [],
      ProductPayment: [],
      WalletPayment: [],
      paymentPayout: [],
      csvdata: [["date", "description", "debit", "credit"]],
    };
    // modal = false;
    // amountError = "";
    // isActive = false
  }
  componentDidMount() {
    if (localStorage.getItem("sellerdata")) {
      seller = JSON.parse(localStorage.getItem("sellerdata"));
      this.setState({ seller: JSON.parse(localStorage.getItem("sellerdata")) });

      //   this.AllTransactionHistoryAPI()
      let urlencoded = new URLSearchParams();
      urlencoded.append("sellerId", seller.id);
      axios
        .post(global.baseUrl + "allTransactionHistory", urlencoded, {
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
        })
        .then(async (result) => {
          console.log(result.data, "amount");
          if (result.data.status) {
            // let amount = 0
            // let list = result.data.data

            await this.setState({
              Bannerprice: result.data.bannerprice,
              ProductBannerprice: result.data.sponsoredadvertiseprice,
              ProductPayment: result.data.ProductPayment,
              WalletPayment: result.data.WalletPayment.filter(
                (word) => word.credit !== "0" || word.debit !== "0"
              ),
              paymentPayout: result.data.paymentPayout,
            });
            let taranjection = [];
            await this.state.ProductPayment.map((list, index) => {
              let data = {};
              data.createdAt = moment(list.createdAt).format("DD-MM-YYYY");
              data.name = list.productId ? list.productId.product_name : "";
              data.credit =
                list.credit && list.credit !== null ? list.credit : 0;
              data.debit = list.debit && list.debit !== null ? list.credit : 0;
              taranjection.push(data);
            });
            await this.state.WalletPayment.map((list, index) => {
              let data = {};
              data.createdAt = moment(list.createdAt).format("DD-MM-YYYY");
              data.name =
                list.message === "Pay for Product" ||
                list.message === "Payment of Product "
                  ? `Payment of product ${" "}${
                      list.productId ? list.productId.product_name : ""
                    }`
                  : `Debited in wallet`;
              data.credit =
                list.credit && list.credit !== null ? list.credit : 0;
              data.debit = list.debit && list.credit !== null ? list.debit : 0;
              taranjection.push(data);
            });
            await this.state.paymentPayout.map((list, index) => {
              let data = {};
              if (list.ApprovedStatus === "1") {
                data.createdAt = moment(list.createdAt).format("DD-MM-YYYY");
                data.name = list.payment_type ? list.payment_type : null;
                data.credit =
                  list.credit && list.credit !== null ? list.credit : 0;
                // data.debit =
                //   list.debit !== '0' && list.credit === '0' ? (
                //     <h6 style={{ color: 'green' }}> ${list.debit}</h6>
                //   ) : (
                //     0
                //   );
                data.debit = list.debit && list.debit !== null ? list.debit : 0;
                taranjection.push(data);
              }
            });
            await this.state.Bannerprice.map((list, index) => {
              let data = {};
              data.createdAt = moment(list.createdAt).format("DD-MM-YYYY");
              data.name = `Sponser Banner Payment`;
              data.credit = 0;
              data.debit = list.price;
              taranjection.push(data);
            });
            await this.state.ProductBannerprice.map((list, index) => {
              let data = {};
              data.createdAt = moment(list.createdAt).format("DD-MM-YYYY");
              data.name = `Sponser Product Payment`;
              data.credit = 0;
              data.debit = list.price;
              taranjection.push(data);
            });
            await this.setState({ TranjectionList: taranjection });
            // let newcsv=[...this.state.csvdata]
            // taranjection.map((element,index)=>{
            //   newcsv=[...newcsv,[]];
            //   this.setState({csvdata:newcsv})
            // });
            await this.state.TranjectionList.sort(function (a, b) {
              a = a.createdAt.toString().split("-");
              b = b.createdAt.toString().split("-");
              return b[2] - a[2] || b[1] - a[1] || b[0] - a[0];
            });
            this.dataTable();
          }
        });
    }
  }

  handleFromDateChange = (date) => {
    console.log(date, "date");
    this.setState({
      startDate: date,
    });
  };

  handleEndDateChange = (date) => {
    console.log(date, "date");
    console.log(this.state.startDate, "this.state.startDate");

    this.setState({
      endDate: date,
    });
    if (
      this.state.startDate &&
      this.state.startDate !== "" &&
      date &&
      date !== ""
    ) {
      let datanew = this.state.TranjectionList.filter((element) => {
        if (
          this.state.startDate &&
          this.state.startDate !== "" &&
          date &&
          date !== ""
        ) {
          const a = new Date(element.createdAt);
          console.log(date, "aaa", a);

          console.log(
            a > new Date(this.state.startDate) && a < new Date(date),
            "hh"
          );
          return a > new Date(this.state.startDate) && a < new Date(date);
        } else {
          {
            /* return true */
          }
        }
      });

      console.log(datanew, "datanew");
      this.setState({ FilteredTranjectionList: datanew });
      console.log(this.state.startDate, "h", this.state.endDate);
    } else {
      // this.newlist();
      alert("Error");
    }
  };

  dataTable() {
    $(document).ready(function () {
      $("#list").DataTable();
    });
  }
  render() {
    return (
      <body
        class="vertical-layout vertical-menu-modern semi-dark-layout content-left-sidebar chat-application navbar-floating footer-static  "
        data-open="click"
        data-menu="vertical-menu-modern"
        data-col="content-left-sidebar"
        data-layout="semi-dark-layout"
      >
        {/* <LoadingOverlay
                    active={isActive}
                    spinner
                    text="Please wait we are processing your payment"
                /> */}
        <div class="app-content content payment">
          <div class="content-overlay"></div>
          <div class="header-navbar-shadow"></div>
          <div class="content-wrapper">
            <div class="content-header row">
              <div class="content-header-left col-md-9 col-12 mb-2">
                <div class="row breadcrumbs-top">
                  <div class="col-12">
                    <h2 class="content-header-title float-left mb-0">
                      All Transaction
                    </h2>
                    <div class="breadcrumb-wrapper col-12">
                      <ol class="breadcrumb">
                        <li class="breadcrumb-item">
                          <Link to="/dashboard">Home</Link>
                        </li>
                        <li class="breadcrumb-item active">All Transaction</li>
                      </ol>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-12 col-12 ">
              <div class="card">
                <div class="card-body">
                  <div>
                    <div className="over-date">
                      <div>
                        Date
                        <span
                          className="order-span font16"
                          style={{ marginRight: "5px" }}
                        >
                          From
                        </span>
                        <DatePicker
                          selected={this.state.startDate}
                          onChange={(e) => this.handleFromDateChange(e)}
                          showMonthDropdown
                          showYearDropdown
                          dropdownMode="select"
                          style={{ width: "90px" }}
                        />
                        <span
                          className="order-span font16"
                          style={{ marginRight: "5px" }}
                        >
                          To
                        </span>
                        <DatePicker
                          selected={this.state.endDate}
                          onChange={(e) => this.handleEndDateChange(e)}
                          showMonthDropdown
                          showYearDropdown
                          dropdownMode="select"
                          style={{ width: "90px !important" }}
                        />
                      </div>
                    </div>
                  </div>
                  {/* <Table>
                                        <div class="row"> */}

                  <div class="table-responsive">
                    {/* {console.log(products)} */}
                    <table className="table" id="list">
                      <thead>
                        <tr>
                          <th style={{ minWidth: "200px" }}>Date</th>
                          <th style={{ minWidth: "385px" }}>Description</th>
                          <th style={{ minWidth: "80px" }}>Debit</th>
                          <th style={{ minWidth: "80px" }}>Credit</th>
                          {/* <th style={{ minWidth: '80px' }}>
                            Ending Daily Balance
                          </th> */}
                        </tr>
                      </thead>
                      {console.log(
                        this.state.TranjectionList,
                        "detatebale", this.state.FilteredTranjectionList
                      )}
                      <tbody>
                        <>  

                          {
                            !this.state.FilteredTranjectionList  ? this.state.FilteredTranjectionList.map((element, index) => {
                            return (
                              <tr key={element.id}>
                                <td style={{ minWidth: "192px" }}>
                                  {element.createdAt}
                                </td>

                                <td
                                  class="history-ellips"
                                  style={{ minWidth: "385px" }}
                                >
                                  {element.name}
                                </td>
                                <td style={{ minWidth: "300px" }}>
                                  {element.credit && element.credit !== ""
                                    ? parseFloat(element.credit).toFixed(2)
                                    : 0.0}
                                </td>
                                <td style={{ minWidth: "300px" }}>
                                  {/* {console.log(
                                      parseFloat(element.debit),
                                      "ddddrrrrrttttt"
                                    )} */}
                                  {element.debit && element.debit
                                    ? parseFloat(element.debit).toFixed(2)
                                    : 0.0}
                                </td>
                              </tr>
                            );
                          }) : 
                          this.state.TranjectionList.map((element, index) => {
                            return (
                              <tr key={element.id}>
                                <td style={{ minWidth: "192px" }}>
                                  {element.createdAt}
                                </td>

                                <td
                                  class="history-ellips"
                                  style={{ minWidth: "385px" }}
                                >
                                  {element.name}
                                </td>
                                <td style={{ minWidth: "300px" }}>
                                  {element.credit && element.credit !== ""
                                    ? parseFloat(element.credit).toFixed(2)
                                    : 0.0}
                                </td>
                                <td style={{ minWidth: "300px" }}>
                                  {/* {console.log(
                                      parseFloat(element.debit),
                                      "ddddrrrrrttttt"
                                    )} */}
                                  {element.debit && element.debit
                                    ? parseFloat(element.debit).toFixed(2)
                                    : 0.0}
                                </td>
                              </tr>
                            );
                          })
                          }




                         
                        </>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div>
          <Footer />
        </div>
      </body>
    );
  }
}
export default AllTransactionHistory;
