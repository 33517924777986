import React from "react";
import axios from 'axios'
import {Link} from 'react-router-dom'
import global from '../../global'
import sideImage from "../../../app-assets/images/pages/reset-password.png"
//var OTPError = "";
var passwordError = "";
var cPasswordError = "";
var submitDisable=false

class ResetPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      OTP: "",
      password: "",
      cPassword: "",
      passwordError: "",
      OTPError: "",
      cPasswordError: ""
    };
    //OTPError = "";
    passwordError = "";
    cPasswordError = "";
    submitDisable=false
  }
  handleChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };
  ApiCall = () => {
    var urlencoded = new URLSearchParams();
    urlencoded.append("id", this.props.match.params.id);
    urlencoded.append("password", this.state.password);
    axios
      .post(global.baseUrl + "sellersetnewpassword", urlencoded, {
        headers: { "Content-Type": "application/x-www-form-urlencoded" }
      })
      .then(result => {
        console.log(result.data);
        if (result.data.status) {
          //localStorage.setItem('userdata', JSON.stringify(result.data))
          submitDisable=true
          this.props.history.push("/login");
        } else {
          cPasswordError = "Invalid data";
          this.setState({
            cPasswordError: "Invalid data"
          });
        }
      });
  };
  handleSubmit = e => {
    e.preventDefault();
    // if (this.state.OTP === "") {
    //   this.setState({ OTPError: "Please enter OTP" });
    //   OTPError = "Please enter OTP";
    // } else {
    //   this.setState({ OTPError: "" });
    //   OTPError = "";
    // }
    if (this.state.password === "") {
      this.setState({ passwordError: "Please enter password " });
      passwordError = "Please enter password ";
    } else {
      this.setState({ passwordError: "" });
      passwordError = "";
    }
    if (this.state.cPassword === "") {
      this.setState({ cPasswordError: "Please enter confirm password " });
      cPasswordError = "Please enter confirm password ";
    } else {
      if (this.state.password !== this.state.cPassword) {
        this.setState({
          cPasswordError: "Password & confirm password are different"
        });
        cPasswordError = "Password & confirm password are different";
      } else {
        this.setState({ cPasswordError: "" });
        cPasswordError = "";
      }
    }
    if (
      this.state.password &&
      this.state.cPassword &&
      passwordError === "" &&
      cPasswordError === ""
    ) {
      this.ApiCall();
    }
  };
  render() {
    return (
      <body
        class="vertical-layout vertical-menu-modern semi-dark-layout 1-column  navbar-floating footer-static bg-full-screen-image  blank-page blank-page"
        data-open="click"
        data-menu="vertical-menu-modern"
        data-col="1-column"
        data-layout="semi-dark-layout"
      >
        <div class="app-content content">
          <div class="content-overlay"></div>
          <div class="header-navbar-shadow"></div>
          <div class="content-wrapper">
            <div class="content-header row"></div>
            <div class="content-body">
              <section class="row flexbox-container">
                <div class="col-xl-7 col-10 d-flex justify-content-center">
                  <div class="card bg-authentication rounded-0 mb-0 w-100">
                    <div class="row m-0">
                      <div class="col-lg-6 d-lg-block d-none text-center align-self-center p-0">
                        <img
                          src={sideImage}
                          alt="branding logo"
                        />
                      </div>
                      <div class="col-lg-6 col-12 p-0">
                        <div class="card rounded-0 mb-0 px-2">
                          <div class="card-header pb-1">
                            <div class="card-title">
                              <h4 class="mb-0">Reset Password</h4>
                            </div>
                          </div>
                          <p class="px-2">Please enter your new password.</p>
                          <div class="card-content">
                            <div class="card-body pt-1">
                              <form>
                                {/* <fieldset class="form-label-group">
                                  <input
                                    type="text"
                                    class="form-control"
                                    id="user-OTP"
                                    placeholder="OTP"
                                    name="OTP"
                                    value={this.state.OTP}
                                    onChange={this.handleChange}
                                  />
                                  <label for="user-OTP">OTP</label>
                                  <p class="error-msg">{OTPError}</p>
                                </fieldset> */}

                                <fieldset class="form-label-group">
                                  <input
                                    type="password"
                                    class="form-control"
                                    id="user-password"
                                    placeholder="Password"
                                    name="password"
                                    value={this.state.password}
                                    onChange={this.handleChange}
                                  />
                                  <label for="user-password">Password</label>
                                  <p class="error-msg">{passwordError}</p>
                                </fieldset>

                                <fieldset class="form-label-group">
                                  <input
                                    type="password"
                                    class="form-control"
                                    id="user-confirm-password"
                                    placeholder="Confirm Password"
                                    name="cPassword"
                                    value={this.state.cPassword}
                                    onChange={this.handleChange}
                                  />
                                  <label for="user-confirm-password">
                                    Confirm Password
                                  </label>
                                  <p class="error-msg">{cPasswordError}</p>
                                </fieldset>
                                <div class="row pt-2">
                                  <div class="col-12 col-md-6 mb-1">
                                    <Link
                                      to="/login"
                                      class="btn btn-outline-primary btn-block px-0 res-btn"
                                    >
                                      Go Back to Login
                                    </Link>
                                  </div>
                                  <div class="col-12 col-md-6 mb-1">
                                    <button
                                      type="submit"
                                      onClick={this.handleSubmit}
                                      disabled={submitDisable}
                                      class="btn btn-primary btn-block px-0 login-btn"
                                    >
                                      Reset
                                    </button>
                                  </div>
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </body>
    );
  }
}
export default ResetPassword