import React, { Component } from "react";
import axios from "axios";
import global from "../../global";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  FormGroup,
  FormText,
  Input,
  Label,
  Row
} from "reactstrap";
import "./style.css";
import { Link } from "react-router-dom";
import { AddContainer } from "./AddForm.style";
import CKEditor from "react-ckeditor-component";
var questionerror = "";
var descriptionError = "";
var submitDisable = false;
var topicError = "";
var seller={};
class AddForm extends React.Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.toggleFade = this.toggleFade.bind(this);
    this.state = {
      collapse: true,
      fadeIn: true,
      timeout: 300,
      // question: "",
      // answer: "",
      // descriptionError: "",
      // answererror: "",
      topic: "",
      description:""
    };
    questionerror = "";
    descriptionError = "";
    seller={};
    submitDisable = false;
  }
  handleChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };
  onHelpChange = evt => {
    this.setState({description : evt.editor.getData()});
    //console.log("onChange fired with event info: ", evt.editor.getData());
  };
  toggle() {
    this.setState({ collapse: !this.state.collapse });
  }
  toggleFade() {
    this.setState(prevState => {
      return { fadeIn: !prevState };
    });
  }
  componentWillMount() {
    if (localStorage.getItem("sellerdata")) {
      seller = JSON.parse(localStorage.getItem("sellerdata"));
      this.setState({ seller: JSON.parse(localStorage.getItem("sellerdata")) });
      //this.dashboardAPI(seller.id);
    }
  }
  dropChangeHandler = event => {
    this.setState({
      topic: event.value
    });
  };
  addFAQ() {
    var urlencoded = new URLSearchParams();
    console.log("description===",this.state.description);
    urlencoded.append("id", seller.id);
    urlencoded.append("topic", this.state.topic);
    urlencoded.append("Description", this.state.description);

    axios
      .post(global.baseUrl+"AddForumTopicSeller", urlencoded, {
        headers: { "Content-Type": "application/x-www-form-urlencoded" }
      })
      .then(result => {
        if (result.data.status) {
          console.log(result.data);
          this.props.history.push("/forum");
          //window.location.reload()
        }
      });
  }
  submitHandler = () => {
    // if (this.state.question === "") {
    //   this.setState({ questionerror: "Please enter question" });
    //   questionerror = "Please enter question";
    // } else if (this.state.question.trim() === "") {
    //   this.setState({ questionerror: "Please enter valid question" });
    //   questionerror = "Please enter valid question";
    // } else {
    //   this.setState({ questionerror: "" });
    //   questionerror = "";
    // }
    if (this.state.topic === "") {
      this.setState({ topicError: "Please enter topic" });
      topicError = "Please enter topic";
    }
    else if (this.state.topic.trim() === "") {
      this.setState({ topicError: "Please enter valid topic" });
      topicError = "Please enter valid topic";
    }
     else {
      this.setState({ topicError: "" });
      topicError = "";
    }
    if (this.state.description === "") {
      this.setState({ descriptionError: "Please enter description" });
      descriptionError = "Please enter description";
    }
    else if (this.state.topic.trim() === "") {
      this.setState({ descriptionError: "Please enter valid description" });
      descriptionError = "Please enter valid description";
    }
     else {
      this.setState({ descriptionError: "" });
      descriptionError = "";
    }
    if (
      this.state.topic &&
      topicError === "" &&
      descriptionError===""
    ) {
      //console.log("working");
      submitDisable = true;
      this.addFAQ();
    }
  };

  resetHandler = () => {
    this.setState({
      topic: "",
      description:""
    });
    topicError = "";
  };
  render() {
    return (
        <body
            class="vertical-layout vertical-menu-modern semi-dark-layout 2-columns  navbar-floating footer-static  "
            data-open="click"
            data-menu="vertical-menu-modern"
            data-col="2-columns"
            data-layout="semi-dark-layout"
        >
            <div class="app-content content">
                <div class="content-overlay"></div>
                <div class="header-navbar-shadow"></div>
                <div class="content-wrapper" style={{ minHeight: "70vh" }}>
                    <div class="content-header row">
                        <div class="content-header-left col-md-9 col-12 mb-2">
                            <div class="row breadcrumbs-top">
                                <div class="col-12">
                                    <h2 class="content-header-title float-left mb-0">GARAGE LANE FORUM </h2>
                                    <div class="breadcrumb-wrapper col-12">
                                        <ol class="breadcrumb">
                                            <li class="breadcrumb-item">
                                                <Link to="/">Home</Link>
                                            </li>
                                            {/* <li class="breadcrumb-item active">Forum</li> */}
                                            <li class="breadcrumb-item"><Link to="/forum">Forum</Link></li>
                                            <li class="breadcrumb-item active">AddForum</li>
                                        </ol>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <AddContainer>
                        <div className="animated fadeIn">
                            <Row>
                                <Col xs="12" md="11">
                                    <Card>
                                        <CardHeader>
                                            <strong>Add Topic</strong>
                                        </CardHeader>
                                        <CardBody>
                                            <FormGroup row>
                                                <Col md="3">
                                                    <Label htmlFor="text-input">Topic Detail </Label>
                                                </Col>
                                                <Col xs="12" md="9">
                                                    <Input
                                                        type="text"
                                                        name="topic"
                                                        row={5}
                                                        placeholder="Enter the Topic"
                                                        value={this.state.topic}
                                                        onChange={this.handleChange}
                                                    />
                                                    <FormText>
                                                        <p className="login-error">{topicError}</p>
                                                    </FormText>
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Col md="3">
                                                    <Label htmlFor="text-input"> Topic Description</Label>
                                                </Col>
                                                <Col xs="12" md="9">
                                                    {/* <Input
                                                        type="textarea"
                                                        name="description"
                                                        row={5}
                                                        placeholder="Enter the Description"
                                                        value={this.state.description}
                                                        onChange={this.handleChange}
                                                    /> */}
                                                    <CKEditor
                                                      activeClass="p10"
                                                      content={this.state.description}
                                                      events={{
                                                        change: this.onHelpChange
                                                      }}
                                                    />
                                                    <FormText>
                                                        <p className="login-error">{descriptionError}</p>
                                                    </FormText>
                                                </Col>
                                            </FormGroup>
                                            {/* <FormGroup row>
                        <Col md="3">
                        <Label htmlFor="textarea-input">Answer</Label>
                        </Col>
                        <Col xs="12" md="9">
                        <Input
                            type="textarea"
                            name="answer"
                            rows="4"
                            placeholder="Answer..."
                            value={this.state.answer}
                            onChange={this.handleChange}
                        />
                        <FormText className="help-block">
                            <p className="login-error">{answererror}</p>
                        </FormText>
                        </Col>
                    </FormGroup> */}
                                        </CardBody>
                                        <CardFooter>
                                            <Button
                                                type="submit"
                                                size="sm"
                                                color=""
                                                className="additem2"
                                                disabled={submitDisable}
                                                onClick={this.submitHandler}
                                            >
                                                {" "}
                                                Submit
                                            </Button>
                                            <Button size="sm" color=""  className="additem3" onClick={this.resetHandler}>
                                                Reset
                                            </Button>
                                        </CardFooter>
                                    </Card>
                                </Col>
                            </Row>
                        </div>
                    </AddContainer>
                </div>
            </div>
        </body>
    );
  }
}

export default AddForm;