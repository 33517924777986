import React from "react";
import axios from "axios";
import "react-image-lightbox/style.css";

import { Link } from "react-router-dom";
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";

import classnames from "classnames";
import Footer from "../../Component/Footer/Footer";
import global from "../../global";
import Paypal from "./PaypalBtn";
import StripeCheckout from "react-stripe-checkout";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import "./style.css";
const CURRENCY = "USD";
const fromDollarToCent = amount => parseInt(amount * 100);
var isActive = false;
const $ = require("jquery");
$.DataTable = require("datatables.net");
var images = [];
var seller = {};
var budgetError = "";
var feedbackError = "";
var isModelOpen=false
toast.configure();
const CustomToast = () => {
  return (
    <div>
      <i class="fa fa-check success-toast"></i>
      Your banner is appear now to user.
    </div>
  );
};
class BannerList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: this.props.match.params.id,
      product: {},
      colorSize: [],
      reviews: [],
      overviewBanner: [],
      activeTab: "1",
      photoIndex: 0,
      isOpen: false,
      banners: [],
      approvedBanners: [],
      newBanners: [],
      budget: "",
      modal: "modal",
      feedback: ""
    };
    feedbackError = "";
    isModelOpen=true
  }
  componentWillMount() {
    if (localStorage.getItem("sellerdata")) {
      seller = JSON.parse(localStorage.getItem("sellerdata"));
      this.setState({ seller: JSON.parse(localStorage.getItem("sellerdata")) });
      //this.dashboardAPI(seller.id);
    }

    let urlencoded1 = new URLSearchParams();
    urlencoded1.append("sellerId", seller.id);

    axios
      .post(global.baseUrl + "SellerGetPayPerClickList", urlencoded1, {
        headers: { "Content-Type": "application/x-www-form-urlencoded" }
      })
      .then(result => {
        console.log(result.data);
        if (result.data.status) {
          this.setState({ overviewBanner: result.data.data });
        }

        this.dataTable();
      });


    let urlencoded = new URLSearchParams();
    urlencoded.append("sellerId", seller.id);

    axios
      .post(global.baseUrl + "SellerPayPerClickBannerList", urlencoded, {
        headers: { "Content-Type": "application/x-www-form-urlencoded" }
      })
      .then(result => {
        console.log(result.data);
        if (result.data.status) {
          this.setState({ banners: result.data.data });
        }
        // this.dataTable();
      });
  }
  dataTable() {
    $(document).ready(function() {
      $("#list").DataTable();
    });
  }
  toggle = tab => {
    if (tab === "1") {
      this.setState({ activeTab: "1" });
      
    }
    else if (tab === "2") {
      this.setState({ activeTab: "2" });
      
    }
     else if (tab === "3") {
      this.setState({ activeTab: "3" });
      let urlencoded1 = new URLSearchParams();
      urlencoded1.append("sellerId", seller.id);

      axios
        .post(
          global.baseUrl + "SellerPayPerClickApprovedBannerList",
          urlencoded1,
          {
            headers: { "Content-Type": "application/x-www-form-urlencoded" }
          }
        )
        .then(result => {
          console.log(result.data);
          if (result.data.status) {
            this.setState({ approvedBanners: result.data.data }, () => {
              let banners = this.state.approvedBanners;
              for (let i = 0; i < banners.length; i++) {
                if (banners[i].liveStatus === "1") {
                  banners[i].liveStatus = "1";
                  banners[i].checked = true;
                } else {
                  banners[i].liveStatus = "";
                }
              }
              this.setState({ approvedBanners: banners });
            });
          }
          // this.dataTable();
        });
    } else if (tab === "4") {
      this.setState({ activeTab: "4" });
      this.setState({ budgetError: "" });
      budgetError = "";
      let urlencoded = new URLSearchParams();
      urlencoded.append("sellerId", seller.id);

      axios
        .post(global.baseUrl + "SellerGetPayPerClickBudget", urlencoded, {
          headers: { "Content-Type": "application/x-www-form-urlencoded" }
        })
        .then(result => {
          console.log(result.data);
          if (result.data.status) {
            let budget =
              parseFloat(result.data.data.budget) -
              parseFloat(result.data.data.spend);
            this.setState({ budget: budget },()=>{
              this.checkBudgetHandler()
            });
          }
        });
    } else if (tab === "4") {
      
      this.setState({ activeTab: "4" });
    } else {
      feedbackError = "";
      this.setState({ activeTab: "5" });
    }
  };
  onBannerChecked = index => {
    let banners = this.state.approvedBanners;
    if (banners[index].checked) {
      banners[index].checked = false;
      banners[index].liveStatus = "";
    } else {
      banners[index].checked = true;
      banners[index].liveStatus = "1";
    }
    this.setState({ approvedBanners: banners });
  };
  updateBannerAPICall() {
    let urlencoded = new URLSearchParams();
    urlencoded.append("sellerId", seller.id);
    for (let i = 0; i < this.state.newBanners.length; i++) {
      urlencoded.append("banners", JSON.stringify(this.state.newBanners[i]));
    }

    axios
      .post(global.baseUrl + "SellerUpdatePayPerClickBanner", urlencoded, {
        headers: { "Content-Type": "application/x-www-form-urlencoded" }
      })
      .then(result => {
        toast.success(<CustomToast />, { position: toast.POSITION.TOP_RIGHT });

        console.log(result.data);
      });
  }
  submitHandler = () => {
    let banners = this.state.approvedBanners;
    let newBanners = [];
    for (let i = 0; i < banners.length; i++) {
      newBanners.push({
        id: banners[i].id,
        liveStatus: banners[i].liveStatus
      });
    }
    this.setState({ newBanners: newBanners }, () => {
      this.updateBannerAPICall();
    });

    // var uploadUrl = 'http://localhost:5000/SellerPayPerClickBannerList';
    // let data = {
    //     banners: this.state.approvedBanners,
    // }

    // fetch(uploadUrl, {
    //     method: 'POST',
    //     headers: {
    //         'Accept': 'application/json',
    //         'Content-Type': 'application/json',
    //     },
    //     body: JSON.stringify(data)
    // }).then((response) => response.json())
    //     .then((responseJson) => {
    //         console.log("Add Product Response for user : ", responseJson);
    //         if (responseJson.status == 1) {
    //         }
    //         else {
    //         }
    //     })
  };
  handleChange = event => {
    this.setState({ budget: event.target.value });
  };
  handleBudgetChange = event => {
    let numberCheck = /^[0-9]\d*(\.\d+)?$/;
    isModelOpen=true
    this.setState({a:""})
    if (event.target.value === "") {
      this.setState({ budgetError: "Please enter budget" });
      budgetError = "Please enter budget";
    } else if (!numberCheck.test(event.target.value)) {
      this.setState({ budgetError: "Please enter validbudget" });
      budgetError = "Please enter valid budget";
    } else if (
      parseFloat(event.target.value) < 1 ||
      parseFloat(event.target.value) > 50
    ) {
      this.setState({ budgetError: "Please enter validbudget" });
      budgetError = "Please enter valid budget";
    } else {
      //   modal="modal"
      // this.setState({modal:"modal"})
      budgetError = "";
      isModelOpen=false
      this.setState({ budgetError: "" });
      
    }
    this.setState({
      budget: event.target.value
    });
    console.log("model",isModelOpen)
  };
  handleFeedbackChange = event => {
    this.setState({ feedback: event.target.value });
  };
  checkBudgetHandler = () => {
    // modal=""
    // this.setState({modal:""})
    let numberCheck = /^[0-9]\d*(\.\d+)?$/;

    if (this.state.budget === "") {
      this.setState({ budgetError: "Please enter budget" });
      budgetError = "Please enter budget";
    } else if (!numberCheck.test(this.state.budget)) {
      this.setState({ budgetError: "Please enter validbudget" });
      budgetError = "Please enter valid budget";
    } else if (
      parseFloat(this.state.budget) < 1 ||
      parseFloat(this.state.budget) > 50
    ) {
      this.setState({ budgetError: "Please enter validbudget" });
      budgetError = "Please enter valid budget";
    } else {
      //   modal="modal"
      // this.setState({modal:"modal"})
      isModelOpen=false
      budgetError = "";
      this.setState({ budgetError: "" });
    }
  };

  onToken = (amount, description) => token => {
    isActive = true;
    this.setState({ a: "" });
    let urlencoded = new URLSearchParams();
    console.log(token, "token");
    urlencoded.append("email", token.email);
    urlencoded.append("token", JSON.stringify(token));
    urlencoded.append("description", description);
    urlencoded.append("source", token.id);
    urlencoded.append("amount", parseInt(amount * 100));
    urlencoded.append("currency", CURRENCY);
    urlencoded.append("cardName", token.card.name);
    urlencoded.append("sellerId", seller.id);
    urlencoded.append("payment_type", "credit_debit");
    axios
      .post(global.baseUrl + "SellerPayPerClickStripePayment", urlencoded)
      .then(result => {
        console.log(result);
        //this.props.history.push("/paymentdone");

        if (result.data.status) {
          //isActive = false;
          this.setState({ a: "" });
          this.addBudgetAPICall();
        }
        //successPayment();
      })
      .catch();
  };
  onSuccess = data => {
    var urlencoded = new URLSearchParams();
    urlencoded.append("sellerId", seller.id);
    urlencoded.append("payment_type", "paypal");
    urlencoded.append("paymentID", data.paymentID);

    axios
      .post(global.baseUrl + "SellerPayPerClickPaypal", urlencoded, {
        headers: { "Content-Type": "application/x-www-form-urlencoded" }
      })
      .then(result => {
        this.closeHandler()
        this.addBudgetAPICall();
      });
  };
  closeHandler = () => {
    document.getElementById("success").style.display = "none";
    for (let i = 0;i < document.getElementsByClassName("modal-backdrop").length;i++) {
      document.getElementsByClassName("modal-backdrop")[i].style.zIndex = "-1";
      document.getElementsByClassName("modal-backdrop")[i].style.width = "0px";
    }
    document.getElementsByClassName("modal-open")[0].style.padding = "0px";
    document.getElementsByTagName("body")[0].classList.remove("modal-open");

    this.props.history.push("/paymentdone");
    //  setTimeout(function() { document.getElementsByClassName('modal').display="none" }, 4000);
  };
  transactionCanceled = () => {
    console.log("transaction cancel");
  };
  transactionError = () => {
    console.log("transaction error");
  };
  addBudgetAPICall() {
    let urlencoded = new URLSearchParams();
    urlencoded.append("sellerId", seller.id);
    urlencoded.append("budget", this.state.budget);

    axios
      .post(global.baseUrl + "SellerUpdatePayPerClickBudget", urlencoded, {
        headers: { "Content-Type": "application/x-www-form-urlencoded" }
      })
      .then(result => {
        console.log(result.data);
        if (result.data.status) {
          this.setState({ banners: result.data.data });
        }
        // this.dataTable();
        this.props.history.push("/paymentdone");
      });
  }
  feedbackAPIcall = () => {
    if (this.state.feedback === "") {
      this.setState({ feedbackError: "enter feedback" });
      feedbackError = "Please enter feedback";
    } else if (this.state.feedback.trim() === "") {
      this.setState({ feedbackError: "enter feedback" });
      feedbackError = "Please enter valid feedback";
    } else {
      this.setState({ feedbackError: "" });
      feedbackError = "";
      let urlencoded = new URLSearchParams();
      urlencoded.append("sellerId", seller.id);
      urlencoded.append("feedback", this.state.feedback);

      axios
        .post(global.baseUrl + "SellerPayPerClickFeedback", urlencoded, {
          headers: { "Content-Type": "application/x-www-form-urlencoded" }
        })
        .then(result => {
          console.log(result.data);
          if (result.data.status) {
            this.setState({ activeTab: "1" });
          }
          // this.dataTable();
        });
    }
  };
  render() {
    const { photoIndex, isOpen } = this.state;
    return (
      <body
        class="vertical-layout vertical-menu-modern semi-dark-layout 2-columns  navbar-floating footer-static  "
        data-open="click"
        data-menu="vertical-menu-modern"
        data-col="2-columns"
        data-layout="semi-dark-layout"
      >
        {/* <Header />
        <Sidebar /> */}

        <div class="app-content content" style={{ minHeight: "91vh" }}>
          <div class="content-overlay"></div>
          <div class="header-navbar-shadow"></div>
          <div class="content-wrapper">
            <div class="content-header row">
              <div class="content-header-left col-md-9 col-12 mb-2">
                <div class="row breadcrumbs-top">
                  <div class="col-12">
                    <h2 class="content-header-title float-left mb-0">
                      Pay Per Click
                    </h2>
                    <div class="breadcrumb-wrapper col-12">
                      <ol class="breadcrumb">
                        <li class="breadcrumb-item">
                          <Link to="/dashboard">Dashboard</Link>
                        </li>
                        <li class="breadcrumb-item active">Pay Per Click</li>
                      </ol>
                    </div>
                  </div>
                </div>
              </div>
              <div class="content-header-right text-md-right col-md-3 col-12 d-md-block ">  {/* d-none */}
                <div class="form-group breadcrum-right">
                  <Link
                    to="/addpayperclickbanner"
                    id="addRow"
                    class="btn btn-primary mb-2 add-product-link"
                  >
                    <i class="feather icon-plus"></i>Add Image
                  </Link>
                </div>
              </div>
            </div>
            <div class="content-body">
              <section class="users-edit">
                <div class="card">
                  <div class="card-content">
                    <div class="card-body">
                      <Nav tabs className="sr-tab">
                      <NavItem>
                          <NavLink
                            className={classnames({
                              active: this.state.activeTab === "1"
                            })}
                            onClick={() => {
                              this.toggle("1");
                            }}
                          >
                            <span class="d-sm-block">
                              Advertise Listing
                            </span>
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className={classnames({
                              active: this.state.activeTab === "4"
                            })}
                            onClick={() => {
                              this.toggle("4");
                            }}
                          >
                            <span class="d-sm-block">Manage Budget</span>
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className={classnames({
                              active: this.state.activeTab === "3"
                            })}
                            onClick={() => {
                              this.toggle("3");
                            }}
                          >
                            <span class="d-sm-block">
                              Approved Banners
                            </span>
                          </NavLink>
                        </NavItem>
                       
                        <NavItem>
                          <NavLink
                            className={classnames({
                              active: this.state.activeTab === "2"
                            })}
                            onClick={() => {
                              this.toggle("2");
                            }}
                          >
                            <span class="d-sm-block">Banner List</span>
                          </NavLink>
                        </NavItem>
                      {/* <NavItem>
                          <NavLink
                            className={classnames({
                              active: this.state.activeTab === "4"
                            })}
                            onClick={() => {
                              this.toggle("4");
                            }}
                          >
                            <span class="d-sm-block">Manage Budget</span>
                          </NavLink>
                        </NavItem> */}
                        <NavItem>
                          <NavLink
                            className={classnames({
                              active: this.state.activeTab === "5"
                            })}
                            onClick={() => {
                              this.toggle("5");
                            }}
                          >
                            <span class="d-sm-block">Feedback</span>
                          </NavLink>
                        </NavItem>
                      </Nav>

                      <TabContent activeTab={this.state.activeTab}>
                      <TabPane tabId="1">
                          <div class="col-md-12 col-12 ">
                            <div class="card">
                              {/* <div class="card-header">
                                <div class="card-title mb-2">Banner Budget</div>
                              </div> */}
                              <div class="">
                                <section class="page-users-view">
                                  <div class="row">
                                    <div class="table-responsive">
                                      {/* <MDBDataTable
                              striped
                              bordered
                              small
                              data={this.state.tableData}
                            /> */}

                                      <table id="list">
                                        <thead>
                                          <tr>
                                            <th style={{width:"50px"}}>Id</th>
                                            <th style={{width:"50px"}}>Image</th>
                                            <th style={{width:"50px"}}>Product</th>
                                            <th style={{width:"50px"}}>Ad Views</th>
                                            <th style={{width:"50px"}}>Clicks</th>
                                            <th style={{width:"50px"}}>Orders</th>
                                            <th style={{width:"50px"}}>Revenue</th>
                                            <th style={{width:"50px"}}>Budget spent</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {this.state.overviewBanner.map(
                                            (banner, index) => (
                                              <tr>
                                                <td style={{ width: "200px" }}>
                                                  {index + 1}
                                                </td>
                                                <td style={{ width: "200px" }}>
                                                  <img
                                                    src={banner.image}
                                                    class="banner-list-img"
                                                    alt=".."
                                                    style={{
                                                      marginTop: "10px",
                                                      marginBottom: "10px"
                                                    }}
                                                  />
                                                </td>
                                                <td>
                                                  {banner.productId
                                                    ? banner.productId
                                                        .product_name
                                                    : null}
                                                </td>
                                                <td>0</td>
                                                <td>0</td>
                                                <td>0</td>
                                                <td>0</td>
                                                <td>0</td>
                                              </tr>
                                            )
                                          )}
                                        </tbody>
                                      </table>
                                    </div>
                                  </div>
                                </section>
                              </div>
                            </div>
                          </div>
                        </TabPane>
                        
                        <TabPane tabId="2">
                          <div class="col-md-12 col-12 ">
                            <div class="card">
                              <div class="card-header cr-padding">
                                <div class="card-title mb-2">Banner List</div>
                              </div>
                              <div class="">
                                <section class="page-users-view">
                                  <div class="row">
                                    {this.state.banners.map((banner, index) => (
                                      <div class="col-md-3">
                                        <div
                                          class="card"
                                          style={{ backgroundColor: "#f2f2f2"}}
                                        >
                                          <div class="card-content">
                                            <img
                                              class="card-img-top img-fluid p-img"
                                              src={banner.image}
                                              alt="banner"
                                            />
                                            <div class="" >
                                              <h5
                                                class="card-text mb-2"
                                                style={{ textAlign: "center" }}
                                              >
                                                {banner.productId
                                                  ? banner.productId
                                                      .product_name
                                                  : null}
                                              </h5>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    ))}
                                  </div>
                                </section>
                              </div>
                            </div>
                          </div>
                        </TabPane>
                        <TabPane tabId="3">
                          <div class="col-md-12 col-12 ">
                            <div class="card">
                              <div class="card-header cr-padding">
                                <div class="card-title mb-2 col-md-10 p-0">
                                  Approved Banners
                                </div>
                              </div>
                              <div class="">
                                <section class="page-users-view">
                                  <div class="row">
                                    {this.state.approvedBanners.map(
                                      (banner, index) => (
                                        <div class="col-12 col-md-4 col-lg-3  banner-check-div">
                                          <div
                                            class="card"
                                            style={{
                                              backgroundColor: "#f2f2f2"
                                            }}
                                          >
                                            <div class="card-content">
                                              <img
                                                class="card-img-top img-fluid p-img"
                                                src={banner.image}
                                                alt="banner"
                                              />
                                              <div class="">
                                                <h5
                                                  class="card-text"
                                                  style={{
                                                    textAlign: "center"
                                                  }}
                                                >
                                                  {banner.productId
                                                    ? banner.productId
                                                        .product_name
                                                    : null}
                                                </h5>
                                              </div>
                                            </div>
                                          </div>
                                          <label
                                            for="checkbox1"
                                            class="checkbox"
                                          >
                                            <input
                                              type="checkbox"
                                              checked={banner.checked}
                                              onChange={() =>
                                                this.onBannerChecked(index)
                                              }
                                              class=" banner-check-in"
                                              value="false"
                                            />
                                          </label>
                                        </div>
                                      )
                                    )}
                                  </div>

                                  <div class="col-12">
                                    {this.state.approvedBanners.length ? (
                                      <button
                                        type="submit"
                                        class="btn btn-primary glow mb-1 mb-sm-0 mr-0 mr-sm-1 login-btn"
                                        onClick={this.submitHandler}
                                      >
                                        Submit
                                      </button>
                                    ) : null}
                                  </div>
                                </section>
                              </div>
                            </div>
                          </div>
                        </TabPane>
                        <TabPane tabId="4">
                          <div class="col-md-12 col-12 ">
                            <div class="card">
                              <div class="card-header cr-padding">
                                <div class="card-title mb-2">Manage Budget</div>
                              </div>
                              <div style={{ height: "35vh" }}>
                                <section class="page-users-view">
                                  <p>
                                    The minimum is $1.00. a day and maximum is
                                    $50.00.
                                  </p>

                                  <div class="row">
                                    <div class="col-md-2">
                                      <p style={{marginTop:"6px"}}>Budget<span class="required-span">*</span></p>
                                    </div>

                                    <div class="col-md-4 ">
                                     <div class="row">
                                      <div class="col-9">
                                      <div class="input-group ">
                                      <div class="input-group-prepend">
                                        <span
                                          class="input-group-text"
                                          id="basic-addon1"
                                        >
                                          $
                                        </span>
                                      </div>
                                      <input
                                        type="text"
                                        class="form-control budget-textbox"
                                        placeholder="Budget"
                                        name="budget"
                                        value={this.state.budget}
                                        aria-label="Username"
                                        aria-describedby="basic-addon1"
                                        onChange={this.handleBudgetChange}
                                      />
                                      </div>
                                      </div>
                                     <div class="col-3" style={{padding:"0px",paddingTop:"5px"}}>
                                     <span>
                                        a day</span>
                                    
                                     </div>
                                     
                                    </div>  
                                      <p class="error-msg">{budgetError} </p>
                                    </div>
                                    <div class="col-md-2">
                                      <button
                                        type="submit"
                                        data-toggle="modal"
                                        data-target="#success"
                                        disabled={isModelOpen}
                                        class="xs-mt-20 btn btn-primary glow mb-1 mb-sm-0 mr-0 mr-sm-1 login-btn s-btn"
                                      >
                                        Set Budget
                                      </button>
                                    </div>
                                  </div>
                                </section>
                                <div class="modal-success mr-1 mb-1 d-inline-block">
                                  <div
                                    class="modal fade text-left"
                                    id="success"
                                    tabindex="-1"
                                    role="dialog"
                                    aria-labelledby="myModalLabel110"
                                    aria-hidden="true"
                                  >
                                    <div
                                      class="modal-dialog modal-dialog-centered modal-dialog-scrollable"
                                      role="document"
                                    >
                                        <div class="modal-content vertical-modal">
                                          <div class="modal-header bg-success white">
                                            <h5
                                              class="modal-title"
                                              id="myModalLabel110"
                                            >
                                              Select Payment Method
                                            </h5>
                                            <button
                                              type="button"
                                              class="close"
                                              data-dismiss="modal"
                                              aria-label="Close"
                                            >
                                              <span aria-hidden="true">
                                                &times;
                                              </span>
                                            </button>
                                          </div>
                                          <div
                                            class="modal-body"
                                            style={{ paddingBottom: "0px" }}
                                          >
                                            <ul class="list-unstyled mb-0">
                                              <li>
                                                <div class="vs-radio-con model-resize-div">
                                                  <Paypal
                                                    totalPay={parseFloat(
                                                      this.state.budget
                                                    )}
                                                    onSuccess={this.onSuccess}
                                                    transactionError={
                                                      this.transactionError
                                                    }
                                                    transactionCanceled={
                                                      this.transactionCanceled
                                                    }
                                                  />
                                                  {/* <span class="vs-radio">
                                          <span class="vs-radio--border"></span>
                                          <span class="vs-radio--circle"></span>
                                        </span>
                                        Credit Debit */}
                                                </div>
                                              </li>
                                              <li>
                                                <fieldset>
                                                  <div class="vs-radio-con model-resize-div">
                                                    <StripeCheckout
                                                      stripeKey="pk_test_51GtSo6BPY6CGrhq8PbbsO8Cd70i2F9wD7u4qk4gwCugvjMakwViTZIfsUVwmnU6qfQacoJUCpaGFSnvognvlK3HI00CIAYrPMY"
                                                      token={this.onToken(
                                                        this.state.budget,
                                                        "Banner Payment"
                                                      )}
                                                      name="Garage Lane"
                                                      amount={fromDollarToCent(
                                                        this.state.budget
                                                      )}
                                                      currency={CURRENCY}
                                                    >
                                                      <button
                                                        className="btn btn-success resize-credit-debit-btn btn-primary float-right mr-1 btn-inline waves-effect waves-light pay-btn"
                                                        data-dismiss="modal"
                                                      >
                                                        Credit/Debit Card
                                                      </button>
                                                    </StripeCheckout>
                                                    {/* <span class="vs-radio">
                                          <span class="vs-radio--border"></span>
                                          <span class="vs-radio--circle"></span>
                                        </span>
                                        Credit Debit */}
                                                  </div>
                                                </fieldset>
                                              </li>
                                              {/* <li>
                                    
                                    <fieldset>
                                      <div class="vs-radio-con">
                                        <input
                                          type="radio"
                                          value="paypal"
                                          onChange={this.handleChange}
                                          checked={
                                            this.state.paymentMethod ===
                                            "paypal"
                                              ? true
                                              : false
                                          }
                                        />
                                        <span class="vs-radio">
                                          <span class="vs-radio--border"></span>
                                          <span class="vs-radio--circle"></span>
                                        </span>
                                        PayPal
                                      </div>
                                    </fieldset>
                                  </li>
                                  <li>
                                    <fieldset>
                                      <div class="vs-radio-con">
                                        <input
                                          type="radio"
                                          value="venmo"
                                          onChange={this.handleChange}
                                          checked={
                                            this.state.paymentMethod === "venmo"
                                              ? true
                                              : false
                                          }
                                        />
                                        <span class="vs-radio">
                                          <span class="vs-radio--border"></span>
                                          <span class="vs-radio--circle"></span>
                                        </span>
                                        Venmo
                                      </div>
                                    </fieldset>
                                  </li> */}
                                            </ul>
                                            <p class="error-msg"> </p>
                                          </div>
                                          <div
                                            class="modal-footer"
                                            style={{ paddingTop: "0px" }}
                                          >
                                            <button
                                              type="button"
                                              class="btn btn-success"
                                              data-dismiss="modal"
                                              onClick={() =>
                                                this.payPopUpOpen()
                                              }
                                            >
                                              OK
                                            </button>
                                            <button
                                              type="button"
                                              class="btn btn-success "
                                              data-dismiss="modal"
                                              aria-label="Close"
                                            >
                                              Cancel
                                            </button>
                                          </div>
                                        </div>
                                       
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </TabPane>
                        <TabPane tabId="5">
                          <div class="col-md-12 col-12 ">
                            <div class="card">
                              {/* <div class="card-header">
                                <div class="card-title mb-2">Feedback</div>
                              </div> */}
                              <div class="" style={{ height: "35vh" }}>
                                <section class="page-users-view">
                                  <div class="row">
                                    <div class="col-md-2">
                                      <p>Feedback</p>
                                    </div>
                                    <div class="col-md-6">
                                      <textarea
                                        type="text"
                                        row={4}
                                        class="form-control"
                                        placeholder="Feedback"
                                        name="feedback"
                                        value={this.state.feedback}
                                        onChange={this.handleFeedbackChange}
                                      />
                                      <p class="error-msg">{feedbackError} </p>
                                    </div>
                                  </div>
                                  <div>
                                  <div class="row mt-1">
                                    <div class="col-md-2 offset-md-2">
                                      <button
                                        type="submit"
                                        class="btn btn-primary glow mb-1 mb-sm-0 mr-0 mr-sm-1 login-btn s-btn"
                                        onClick={this.feedbackAPIcall}
                                      >
                                        Feedback
                                      </button>
                                    </div>
                                  </div>
                                  </div>
                                </section>
                              </div>
                            </div>
                          </div>
                        </TabPane>
                      </TabContent>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>

        <div class="sidenav-overlay"></div>
        <div class="drag-target"></div>
        <Footer />
      </body>
    );
  }
}
export default BannerList;
