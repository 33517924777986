import React, { useRef, useEffect, useState, useCallback } from "react";
import axios from "axios";
import "react-image-lightbox/style.css";
import Avtar from "../images/avtar.jpg";
import { toast } from 'react-toastify'
import { Link } from "react-router-dom";
import Footer from "../../Component/Footer/Footer";
import global from "../../global";
import './livestream.css'
import moment from "moment";
import $ from 'jquery'
import Hls from 'hls.js';
import ReactHlsPlayer from 'react-hls-player';
// const videoRef = useRef(null);
import ReactDatePicker from "react-datepicker";
import { useForm, Controller } from "react-hook-form";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
// import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
// var emailError = ""
toast.configure()
const CustomToast = () => {
  return (
    <div>
      <i class="fa fa-check success-toast"></i>
     Livestream created sucessfuly.
    </div>
  )
}
const LiveStream = () => {
  const [livestream, setlivestream] = useState([]);
  const [seller, setseller] = useState("")
  const [product, setProduct] = useState("")
  const [category, setCatgeory] = useState("")
  const [productname, setProductName] = useState("")

  const [Products, setAllProduct] = useState([])
  const [items, setItems] = React.useState([]);
  const [itemsCategory, setItemsCategory] = React.useState([]);

  const [isSending, setIsSending] = useState(false)
  const [sellerdata, setSellerData] = useState({})
  const [name, setName] = useState(JSON.parse(window.localStorage.getItem("sellerdata")));
  const videoRef = useRef();
  const { control } = useForm({});
  const [livestreamlist, setlivestreamlist] = useState([]);
  const [startDate, setStartDate] = useState(new Date());

  let effects = useEffect(() => {
    if (window.localStorage.getItem("sellerdata")) {
      setSellerData(JSON.parse(window.localStorage.getItem("sellerdata")));
    }

    let hls;
    let urlencoded = new URLSearchParams();
    urlencoded.append('sellerId', name.id)

    axios
      .post(global.baseUrl + "LiveStreamList", urlencoded, {
        headers: { "Content-Type": "application/x-www-form-urlencoded" }
      })
      .then(result => {

        setlivestreamlist(result.data.data)
      })
    async function getCategory() {
      axios
        .get(global.baseUrl + "supercategories", urlencoded, {
          headers: { "Content-Type": "application/x-www-form-urlencoded" }
        })
        .then(result => {
          console.log(result.data.data, "cat::::::::::")
          setItemsCategory(
            result.data.data.map(({ name, id }) => ({ label: name, value: id })));
        })
      // getCharacters(id)
    }
    getCharacters()
    getCategory();
  }, [videoRef]);


  const getCharacters = (id) => {
    console.log(id, "id")
    let urlencoded = new URLSearchParams();
    urlencoded.append('UserID', name.id)
    urlencoded.append('type', "category")
    urlencoded.append('super_categoryId', id)
    urlencoded.append('categoryId', "")
    urlencoded.append('sub_categoryId', "")


    axios
      .post(global.baseUrl + "specificproductlistseller", urlencoded, {
        headers: { "Content-Type": "application/x-www-form-urlencoded" }
      })
      .then(result => {
        // console.log(result.data.data, "proooooooooooooooooooo ")
        setItems(result.data.data.map(({ product_name, id }) => ({ label: product_name, value: id })));
      })

  }

  const sendRequest = (event) => {
    console.log(typeof product, "fgfgf")
    event.preventDefault();
    let hls;
    let urlencoded = new URLSearchParams();
    urlencoded.append('sellerId', name.id)
    urlencoded.append('productId', product)
    urlencoded.append('live_stream_date', startDate)
    urlencoded.append('superCategoryId', category)


    axios
      .post(global.baseUrl + "creatertctoken", urlencoded, {
        headers: { "Content-Type": "application/x-www-form-urlencoded" }
      })
      .then(result => {
        console.log(result.data.data, "resss")

        setlivestream(result.data.data)
        toast.success(<CustomToast />, { position: toast.POSITION.TOP_RIGHT })
        // window.location.reload()
        // const src = ``;

        // if (videoRef.current) {
        //   const video = videoRef.current;

        //   if (video.canPlayType("application/vnd.apple.mpegurl")) {
        //     // Some browers (safari and ie edge) support HLS natively
        //     video.src = src;
        //   } else if (Hls.isSupported()) {
        //     // This will run in all other modern browsers
        //     hls = new Hls();
        //     hls.loadSource(src);
        //     hls.attachMedia(video);

        //   } else {
        //     console.error("This is a legacy browser that doesn't support MSE");
        //   }
        // }
        // return () => {
        //   if (hls) {
        //     hls.destroy();
        //   }
        // };
      })
    // don't send again while we are sending
    if (isSending) return
    // update state
    setIsSending(true)
    // send the actual request
    //await API.sendRequest()
    // once the request is sent, update state again
    setIsSending(false)
  }



  const handleAddrTypeChange = (e) => {
    setProduct(e.target.value)
  }
  const handleCategoryTypeChange = (e) => {
    console.log(e.target.value, "eeee")
    setCatgeory(e.target.value)
    getCharacters(e.target.value)
  }
  return (
    <body
      class="vertical-layout vertical-menu-modern semi-dark-layout 2-columns  navbar-floating footer-static  "
      data-open="click"
      data-menu="vertical-menu-modern"
      data-col="2-columns"
      data-layout="semi-dark-layout"
    >
      {/* <Header />
        <Sidebar /> */}

      <div class="app-content content">
        <div class="content-overlay"></div>
        <div class="header-navbar-shadow"></div>
        <div class="content-wrapper" style={{ minHeight: "79vh" }}>
          <div class="content-header row">
            <div class="content-header-left col-md-9 col-12 mb-2">
              <div class="row breadcrumbs-top">
                <div class="col-12">
                  <h2 class="content-header-title float-left mb-0">LiveStream</h2>
                  <div class="breadcrumb-wrapper col-12">
                    <ol class="breadcrumb">
                      <li class="breadcrumb-item">
                        <Link to="/">Home</Link>
                      </li>
                      <li class="breadcrumb-item active">LiveStream</li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>

          </div>
          <div class="content-body">
            <section class="users-edit">
              <div>
                <div class="bg-transparent border-0 shadow-none collapse-icon set accordion-icon-rotate">
                  <div class="card">
                    <div class="card-body">
                      <div class="row ">
                        <div class="col-12 base-product-d">

                          <div class="row form-label-group ">
                            <div class="col-md-3">
                              <label>
                                Category
                                {/* <span class="required-span">*</span> */}
                              </label>
                              <div class="form-label-group position-relative ">

                                <select className="form-control" onChange={e => handleCategoryTypeChange(e)}>
                                  <option key={""} value={""}>Please select a category</option>
                                  {itemsCategory.map(({ label, value }) => (

                                    <option key={value} value={value}>
                                      {label}
                                    </option>
                                  ))}

                                </select>



                              </div>

                            </div>
                            <div class="col-md-3">
                              <label>
                                Products
                                {/* <span class="required-span">*</span> */}
                              </label>
                              <div class="form-label-group position-relative ">

                                <select className="form-control" onChange={e => handleAddrTypeChange(e)}>
                                  <option key={""} value={""}>Please select a product</option>
                                  {items.map(({ label, value }) => (

                                    <option key={value} value={value}>
                                      {label}
                                    </option>
                                  ))}

                                </select>



                              </div>

                            </div>
                            <div class="col-md-3">
                              <label>
                                Date
                                {/* <span class="required-span">*</span> */}
                              </label>

                              <div class="form-label-group position-relative react-datepicker-w100">
                                <DatePicker className="form-control w-100" selected={startDate} showTimeSelect timeIntervals={15}
                                  onChange={date => setStartDate(date)} />

                              </div>
                            </div>
                            <div class="col-md-3">
                              <div class="form-label-group position-relative no-label-space">
                                <button className="btn btn-primary add-product-link" onClick={sendRequest}>Submit</button>
                              </div>

                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="">
                        <div className="">
                          <div class="table-responsive" style={{ fontSize: "16px" }}>

                            <table className="table" id="list">
                              <thead>
                                <tr>
                                  <th style={{ fontSize: "16px" }}>Super Category</th>
                                  <th style={{ fontSize: "16px" }}>Product Name</th>
                                  <th style={{ fontSize: "16px" }}>Stream Key</th>
                                  <th style={{ fontSize: "16px" }}>Date</th>

                                </tr>
                              </thead>
                              <tbody>
                                {livestreamlist.slice(0).reverse().map((live, index) => (
                                  <tr key={index}>
                                    <td style={{ marginTop: "60px", whiteSpace: "nowrap" }}> {live ? live.superCategoryId ? live.superCategoryId.name : null : null}</td>

                                    <td className="prod-name-ellips" ><a href={`https://garagelane.com/livestream/#/seller/${live.id}/${live.channelName}`}> {live ? live.productId ? live.productId.product_name : null : null}</a></td>
                                    <td style={{ marginTop: "60px", whiteSpace: "nowrap" }}>{live ? live.RtcToken : null}</td>
                                    <td style={{ marginTop: "60px", whiteSpace: "nowrap" }}>{live ? live.live_stream_date : null}</td>
                                  </tr>
                                ))}

                              </tbody>
                            </table>
                          </div>
                          {/* <div >
                            <div className="alert alert-success" style={{ fontWeight: "900" }}>To do a live stream please install OBS application and put stream key!</div>
                          </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>

      <div class="sidenav-overlay"></div>
      <div class="drag-target"></div>

      <Footer />
    </body>
  );

  // }
}
export default LiveStream;
