import React from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import moment from "moment";
// import Sidebar from "../../Component/Sidebar/Sidebar";
//import Header from "../../Component/Header/Header";
import Footer from "../../Component/Footer/Footer";
import global from "../../global";
import {toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import "./Product.css";
var sellerData = {};

const $ = require("jquery");
$.DataTable = require("datatables.net");
var products=[]

const CustomToast=()=>{
  return(
  <div>
    <i class="fa fa-check success-toast"></i>
    Offer Deleted Successfully
  </div>
  )
}
class OfferProduct extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      seller: "",
      products: []
    };
  }

  componentWillMount() {
    if (localStorage.getItem("sellerdata")) {
      this.setState({ seller: JSON.parse(localStorage.getItem("sellerdata")) });
      sellerData = JSON.parse(localStorage.getItem("sellerdata"));
    }
    this.listAPI();
  }

  // dataTable() {
  //   $(document).ready(function() {
  //     $("#list").DataTable();
  //   });
  //   console.log("working");
  // }
  dataTable() {
    $(document).ready(function() {
      $("#list").DataTable();
    });
  }
  listAPI() {
    console.log(this.state.seller);
    var urlencoded = new URLSearchParams();
    console.log(sellerData.id);
    urlencoded.append("userId", sellerData.id);

    axios
      .post(global.baseUrl + "sellerofferuserlist", urlencoded, {
        headers: { "Content-Type": "application/x-www-form-urlencoded" }
      })
      .then(result => {
        console.log(result.data);
        if (result.data.status) {
            products = result.data.data;
          this.setState({ products: result.data.data });
          //localStorage.setItem('userdata', JSON.stringify(result.data))
          //this.props.history.push("/setotp/" + result.data.data.id);

          //this.dataTable()
          console.log("mount",products)
          this.dataTable()
        }
      });
  }
  deleteHandler = (productID) => {
    var r = window.confirm("Are you sure to delete the record?");
    if (r) {
      var urlencoded = new URLSearchParams();
      console.log(sellerData.id);
      urlencoded.append("id", productID);
      urlencoded.append("userId", this.state.seller.id);

      axios
        .post(global.baseUrl + "sellerofferdelete", urlencoded, {
          headers: { "Content-Type": "application/x-www-form-urlencoded" }
        })
        .then(result => {
          console.log(result.data);
          toast.success(<CustomToast/>,{position:toast.POSITION.TOP_RIGHT})

        });
        this.setState({
          products: this.state.products.filter(
            product => product.id !== productID
          )
        },()=>{
          console.log(this.state.products)
        });
        this.dataTable()
    }
  };
  render() {

    return (
      <body
        class="vertical-layout vertical-menu-modern semi-dark-layout 2-columns  navbar-floating footer-static  "
        data-open="click"
        data-menu="vertical-menu-modern"
        data-col="2-columns"
        data-layout="semi-dark-layout"
      >
        {/* <Header />
        <Sidebar /> */}
        <div class="app-content content" style={{minHeight: "91vh"}}>
          <div class="content-overlay"></div>
          <div class="header-navbar-shadow"></div>
          <div class="content-wrapper">
            <div class="content-header row">
              <div class="content-header-left col-md-9 col-12 mb-2">
                <div class="row breadcrumbs-top">
                  <div class="col-12">
                    <h2 class="content-header-title float-left mb-0">
                      Offers
                    </h2>
                    <div class="breadcrumb-wrapper col-12">
                      <ol class="breadcrumb">
                        <li class="breadcrumb-item">
                          <Link to="/dashboard">Home</Link>
                        </li>
                        <li class="breadcrumb-item active">Offers</li>
                      </ol>
                    </div>
                  </div>
                </div>
              </div>
              <div class="content-header-right text-md-right col-md-3 col-12 d-md-block">
                <div class="form-group breadcrum-right xs-text-right">
                  <Link
                    to="/addoffer"
                    id="addRow"
                    class="btn btn-primary mb-2 add-product-link"
                  >
                    <i class="feather icon-plus"></i>Add Offer
                  </Link>
                </div>
              </div>
            </div>
            <div class="content-body">
              <section id="add-row">
                <div class="row">
                  <div class="col-12">
                    <div class="card">
                      {/* <div class="card-header">
                                    <h4 class="card-title">Add rows</h4>
                                </div> */}
                      <div class="card-content">
                        <div class="card-body">
                          {/* <button id="addRow" class="btn btn-primary mb-2"><i class="feather icon-plus"></i>&nbsp; Add new row</button> */}
                          <div class="table-responsive" style={{fontSize:"16px"}}>
                            {console.log(products)}
                            <table  id="list">
                              <thead>
                                <tr>
                                  <th style={{fontSize:"16px"}}>Id</th>
                                  <th style={{fontSize:"16px"}}>Percentage</th>
                                  <th style={{fontSize:"16px"}}>Start Date</th>
                                  <th style={{fontSize:"16px"}}>End Date</th>
                                  <th style={{fontSize:"16px"}}>MinQty</th>
                                  <th style={{fontSize:"16px"}}>MaxQty</th>
                                  <th style={{fontSize:"16px"}}>Offer Type</th>
                                  <th style={{fontSize:"16px"}}>Offer On</th>
                                  <th style={{fontSize:"16px"}}>Action</th>
                                </tr>
                              </thead>
                              <tbody>
                                {/* <tr>
                              <td>Id</td>
                                  <td>Percentage</td>
                                  <td>Start Date</td>
                                  <td>End Date</td>
                                  <td>MinQty</td>
                                  <td>MaxQty</td>
                                  <td>Offer Type</td>
                                  <td>Offer On</td>
                                  <td>Action</td>
                                  </tr> */}
                                {this.state.products.map(
                                      (product, index) => (
                                        <tr key={index}>
                                          
                                          <td>{index + 1}</td>
                                          <td>{product.percentage}</td>
                                          <td>
                                            {moment(product.start_date).format(
                                              "DD/MM/YYYY"
                                            )}
                                          </td>
                                          <td>
                                            {moment(product.end_date).format(
                                              "DD/MM/YYYY"
                                            )}
                                          </td>
                                          <td>{product.minimum_quantity}</td>
                                          <td>{product.maximum_quantity}</td>
                                          <td>{product.offer_type}</td>

                                           <td>
                                            {product.productId
                                              ? product.productId.product_name
                                              : product.brandId
                                              ? product.brandId.name
                                              : product.categoryId
                                              ? product.categoryId.name
                                              : null}
                                          </td>
                                          <td>
                                            <Link
                                              to={`/editoffer/${product.id}`}
                                            >
                                              <i
                                                className="fa fa-pencil"
                                                style={{
                                                  marginLeft: "10px",
                                                  marginRight: "10px",
                                                  color: "green"
                                                }}
                                              ></i>
                                            </Link>
                                            <i
                                              className="fa fa-trash"
                                              onClick={() =>
                                                this.deleteHandler(product.id)
                                              }
                                              style={{ marginRight: "20px" }}
                                            ></i>
                                          </td>
                                        </tr>
                                      )
                                    )}
                              </tbody>
                               {/* <tfoot>
                                <tr>
                                  <th>Id</th>
                                  <th>Percentage</th>
                                  <th>Start Date</th>
                                  <th>End Date</th>

                                  <th>MinQty</th>
                                  <th>MaxQty</th>
                                  <th>Offer Type</th>
                                  <th>Offer On</th>
                                  <th>Action</th>
                                </tr>
                              </tfoot>  */}
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
        <div class="sidenav-overlay"></div>
        <div class="drag-target"></div>

        <Footer />
      </body>
    );
  }
}

export default OfferProduct;
