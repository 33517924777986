import React from "react";
import axios from "axios";
import InputColor from "react-input-color";
import "react-image-lightbox/style.css";
import CKEditor from "react-ckeditor-component";
import { Link } from "react-router-dom";
import convert from "image-file-resize";
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";

import classnames from "classnames";
import Footer from "../../Component/Footer/Footer";
import global from "../../global";
import { toast } from "react-toastify";
import Select from "react-select";

import Paypal from "./PaypalBtn";
import StripeCheckout from "react-stripe-checkout";
import img from "./upload.jpg";
import AddImg from "./plus.jpg";

import LoadingOverlay from "react-loading-overlay";
import "react-toastify/dist/ReactToastify.css";
import "./style.css";
import { data } from "jquery";
import EditCropSelectedImage from "./EditCropselectImage";
const CURRENCY = "USD";

const fromDollarToCent = (amount) => parseInt(amount * 100);

var images = [];
var seller = {};

var nameError = "";
var skuError = "";
var brandError = "";
var conditionError = "";
var quantityError = "";
var renewalOptionError = "";
var imageError = "";

var priceError = "";
var msrpError = "";
var discountError = "";
var discountTypeError = "";

var budgetError = "";
var isModelOpen = false;
var description = "";
var fitGuide = "";
var glossary = "";
var categoryError = "";
var descriptionError = "";
var submitDisable = false;
var variationError = "";
var sendVideo = "";
var selsTaxError = "";
var taxAmountError = "";
var charge = {};
var modal = false;
var modal2 = false;
var isActive = false;
var variationTypeError = "";
var colorSizeError = "";
var firstCategoryError = "";
var secondCategoryError = "";
var thirdCategoryError = "";

toast.configure();
const CustomToast = () => {
  return (
    <div>
      <i class="fa fa-check success-toast"></i>
      Product Updated Successfully.
    </div>
  );
};
const InfoToast = () => {
  return (
    <div style={{ display: "flex" }}>
      <i class="fa fa-info info-toast"></i>
      Adding more then 5 image are applicable respect to $ {
        charge.image_charge
      }{" "}
      charges per image.
    </div>
  );
};
const WarnToast = () => {
  return (
    <div style={{ display: "flex" }}>
      <i class="fa fa-info info-toast"></i>
      You have insufficient amount in wallet.
    </div>
  );
};
class AddProduct extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      priceOn: "0",

      walletAmount: 0,
      wallerPaymentId: "",
      colorSize: [
        {
          color: "#000000",
          size: "",
          qty: "",
          price: "",
        },
      ],
      id: this.props.match.params.id,
      defaultProductLength: 0,
      paypalPaymentId: "",
      transactionId: "",
      paymentType: "",
      activeTab: "1",
      file: [null],
      imageArray: [
        {
          productImage: {},
          sendImage: "",
        },
      ],
      productName: "",
      sku: "",
      brand: "",
      brands: [],
      descriptions: [""],
      conditions: [
        {
          label: "Select Condition",
          value: "",
        },
        {
          label: "New",
          value: "new",
        },
        {
          label: "Used",
          value: "used",
        },
        {
          label: "Refurbish",
          value: "refurbish",
        },
      ],
      condition: "",
      quantity: "",
      productVideo: "",
      renewalOption: "",
      variation: [
        {
          name: "",
          value: "",
          qty: "",
          price: "",
        },
      ],
      firstCategory: "",
      secondCategory: "",
      thirdCategory: "",
      totalImage: 0,
      shippingPrice: "0",

      price: "",
      msrp: "",
      discountType: "",
      discountTypes: [
        {
          label: "Select Type",
          value: "",
        },
        {
          label: "$",
          value: "$",
        },
        {
          label: "%",
          value: "%",
        },
      ],
      discount: "",
      tags: "",
      taxAmount: "",
      countries: [],
      country: "",
      productInfo: "",
      superCategories: [],
      categories: [],
      subCategories: [],

      bargain: "",
      finalTotal: 0,
      seller: "",
      shippings: [
        {
          label: "Select ",
          value: "",
        },
        {
          label: "Same cost to all buyer ",
          value: "1",
        },
        // {
        //   label: "Different cost to all buyer ",
        //   value: "2"
        // },
        {
          label: "Free Shipping",
          value: "3",
        },
      ],
      shippingId: "",
      video: "",
      variationType: "",
      ProcessTiming: [
        {
          label: "Select ",
          value: "",
        },
        {
          label: "2-3 Days",
          value: "1",
        },
        {
          label: "1 Week",
          value: "2",
        },
      ],
      packingType: [
        {
          label: "Select ",
          value: "",
        },
        {
          label: "Envelop",
          value: "1",
        },
        {
          label: "Irregular",
          value: "2",
        },
      ],
      shippingcarrier: "",
      mailclass: "",
      mailclasses: [],
      ShippingCarriers: [],
      lengthError: "",
      HeightError: "",
      widthError: "",
      ItemWeight_LB_Error: "",
      ItemWeight_OZ_Error: "",
    };
    images = [];
    seller = {};
    nameError = "";
    skuError = "";
    brandError = "";
    conditionError = "";
    quantityError = "";
    renewalOptionError = "";
    imageError = "";
    priceError = "";
    msrpError = "";
    discountError = "";
    discountTypeError = "";
    budgetError = "";
    isModelOpen = false;
    description = "";
    fitGuide = "";
    glossary = "";
    categoryError = "";
    descriptionError = "";
    submitDisable = false;
    variationError = "";
    sendVideo = "";
    selsTaxError = "";
    taxAmountError = "";
    charge = {};
    modal = false;
    modal2 = false;
    isActive = false;
    variationTypeError = "";
    colorSizeError = "";
    firstCategoryError = "";
    secondCategoryError = "";
    thirdCategoryError = "";
  }
  componentWillMount() {
    if (localStorage.getItem("sellerdata")) {
      seller = JSON.parse(localStorage.getItem("sellerdata"));
      this.setState({ seller: JSON.parse(localStorage.getItem("sellerdata")) });
      //this.dashboardAPI(seller.id);
      let urlencoded = new URLSearchParams();
      urlencoded.append("sellerId", seller.id);
      axios
        .post(global.baseUrl + "SellerGetWalletAmount", urlencoded, {
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
        })
        .then((result) => {
          console.log(result.data, "amount");
          if (result.data.status) {
            if (result.data.amount > 0) {
              this.setState({ walletAmount: parseFloat(result.data.amount) });
            }
          }
        });
    }
    this.listAPI();
    let urlencoded = new URLSearchParams();
    urlencoded.append("id", this.state.id);
    axios
      .post(global.baseUrl + "sellerproductIdlist", urlencoded, {
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
      })
      .then((result) => {
        console.log(result.data, "product");
        if (result.data.status) {
          this.setState({
            id: result.data.data.id,
            sellerId: result.data.sellerId,
            productName: result.data.data.product_name,
            sku: result.data.data.sku,
            condition: result.data.data.condition,
            quantity: result.data.data.quantity,
            renewalOption: result.data.data.renewal,
            price: result.data.data.price,
            msrp: result.data.data.msrp,
            discount: result.data.data.discount,
            discountType: result.data.data.discount_type,
            country: result.data.data.tax_country,
            taxAmount: result.data.data.tax_amount,
            descriptions: result.data.data.product_description,
            colorSizeArray: result.data.data.color_size,
            variationType: result.data.data.variation_type,
            priceOn: "0",
            shippingPrice: result.data.data.shipping_cost,
            tag: result.data.data.tags,
            shippingcarrier: result.data.data.shippingCarrier,
            mailClass: result.data.data.mailclass,
            ZipCode: result.data.data.zipCode,
            ItemLocation: result.data.data.zipCode,
            ItemWeight_LB: result.data.data.itemWeight_LB,
            ItemWeight_OZ: result.data.data.itemWeight_OZ,
            Length: result.data.data.length,
            Width: result.data.data.width,
            Height: result.data.data.height,
            PickUpCost: result.data.data.pickUpCost,
            PickUpAdditional: result.data.data.pickUpAdditional,
            shippingId:
              result.data.data.shipping_cost &&
              result.data.data.shipping_cost !== "0" &&
              result.data.data.shipping_cost !== ""
                ? "1"
                : "3",
            brand: result.data.data.process_time,
          });
          if (result.data.data.price.includes("-")) {
            this.setState({ priceOn: "1" });
          }
          let imageArray = [];
          for (let i = 0; i < result.data.data.image.length; i++) {
            imageArray.push({
              productImage: result.data.data.image[i],
              sendImage: result.data.data.image[i],
            });
          }
          this.setState({
            imageArray: imageArray,
            defaultProductLength: result.data.data.image.length,
          });
          // if (result.data.data.brand) {
          //   this.setState({ brand: result.data.data.brand.id });
          // }
          if (result.data.data.super_categoryId) {
            this.setState({
              firstCategory: result.data.data.super_categoryId.id,
            });
            const apiUrl2 =
              global.baseUrl +
              "SellerDropCategoryList/" +
              result.data.data.super_categoryId.id;
            axios
              .get(apiUrl2)

              .then(
                (result) => {
                  var option = [];
                  for (let i = 0; i < result.data.data.length; i++) {
                    option.push({
                      label: result.data.data[i].name,
                      value: result.data.data[i].id,
                    });
                  }
                  this.setState({
                    categories: option,
                  });
                },
                (error) => {
                  this.setState({ error });
                }
              );
          }
          if (result.data.data.categoryId) {
            this.setState({ secondCategory: result.data.data.categoryId.id });
            const apiUrl =
              global.baseUrl +
              "SellerSubCategoryList/" +
              result.data.data.categoryId.id;
            axios.get(apiUrl).then(
              (result) => {
                var option = [];
                for (let i = 0; i < result.data.data.length; i++) {
                  option.push({
                    label: result.data.data[i].name,
                    value: result.data.data[i].id,
                  });
                }
                this.setState({
                  subCategories: option,
                });
              },
              (error) => {
                this.setState({ error });
              }
            );
          }
          if (result.data.data.sub_categoryId) {
            this.setState({
              thirdCategory: result.data.data.sub_categoryId.id,
            });
          }

          if (result.data.data.variation_type === "normal") {
            let variationArray = result.data.data.color_size;
            for (let i = 0; i < variationArray.length; i++) {
              variationArray[i] = JSON.parse(variationArray[i]);
            }
            this.setState({ variation: variationArray });
          }
          if (result.data.data.variation_type === "color") {
            let colorSize = result.data.data.color_size;
            for (let i = 0; i < colorSize.length; i++) {
              colorSize[i] = JSON.parse(colorSize[i]);
            }
            this.setState({ colorSize: colorSize }, () => {
              console.log(this.state.colorSize, "djfkg");
            });
          }

          if (result.data.data.shippingCarrier) {
            this.setState({
              shippingcarrier: result.data.data.shippingCarrier.id,
            });
            const apiUrl =
              global.baseUrl +
              "MailClassList/" +
              result.data.data.shippingCarrier.id;
            axios.get(apiUrl).then(
              (result) => {
                var option = [];
                for (let i = 0; i < result.data.data.length; i++) {
                  option.push({
                    label: result.data.data[i].class_name,
                    value: result.data.data[i].id,
                  });
                }
                this.setState({
                  mailclasses: option,
                });
              },
              (error) => {
                this.setState({ error });
              }
            );
          }
          if (result.data.data.mailClass) {
            this.setState({
              mailclass: result.data.data.mailClass.id,
            });
          }
        }
      });
  }
  listAPI() {
    var urlencoded = new URLSearchParams();

    axios
      .post(global.baseUrl + "SellerAddProductFieldList", urlencoded, {
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
      })
      .then((result) => {
        console.log(result.data);
        if (result.data.status) {
          var option = [];
          option.push({
            label: "Select Category",
            value: "",
          });
          if (result.data.data.superCategory.length) {
            for (let i = 0; i < result.data.data.superCategory.length; i++) {
              option.push({
                label: result.data.data.superCategory[i].name,
                value: result.data.data.superCategory[i].id,
              });
            }
            this.setState({
              superCategories: option,
            });
          }

          let optionShippingCarrier = [];
          optionShippingCarrier.push({
            label: "Select shiiping carrier",
            value: "",
          });
          for (let i = 0; i < result.data.data.shippingCarrier.length; i++) {
            optionShippingCarrier.push({
              label: result.data.data.shippingCarrier[i].carrier_type,
              value: result.data.data.shippingCarrier[i].id,
            });
          }
          this.setState({
            ShippingCarriers: optionShippingCarrier,
          });

          let optionCountry = [];
          optionCountry.push({
            label: "Select Country",
            value: "",
          });
          for (let i = 0; i < result.data.data.country.length; i++) {
            optionCountry.push({
              label: result.data.data.country[i].name,
              value: result.data.data.country[i].id,
            });
          }
          this.setState({
            countries: optionCountry,
          });
          if (result.data.data.charge.length) {
            charge = result.data.data.charge[0];
          }
          if (result.data.data.info.length) {
            this.setState({
              productInfo: result.data.data.info[0].product_info,
            });
          }
        }
      });
  }
  toggle = (tab) => {
    if (tab === "1") {
      this.setState({ activeTab: "1" });
    } else if (tab === "2") {
      this.setState({ activeTab: "2" });
    } else {
      this.setState({ activeTab: "3" });
    }
  };
  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };
  conditionDropChangeHandler = (event) => {
    this.setState({
      condition: event.value,
    });
  };
  variationTypeDropChangeHandler = (event) => {
    if (event.target.value === "color") {
      variationError = "";
      variationTypeError = "";

      this.setState({
        variation: [
          {
            name: "",
            value: "",
            qty: "",
            price: "",
          },
        ],
      });
    } else {
      variationTypeError = "";

      if (event.target.value === "normal") {
        colorSizeError = "";
        this.setState({
          colorSize: [
            {
              color: "#000000",
              size: "",
              qty: "",
              price: "",
            },
          ],
        });
      }
    }
    this.setState({
      variationType: event.target.value,
    });
  };
  countryDropChangeHandler = (event) => {
    this.setState({
      country: event.value,
    });
  };
  brandDropChangeHandler = (event) => {
    this.setState({
      brand: event.value,
    });
    console.log(this.state.category);
  };
  shippingChangeHandler = (event) => {
    this.setState({
      shippingId: event.value,
    });
  };
  typeDropChangeHandler = (event) => {
    this.setState({
      discountType: event.value,
    });
  };
  firstCategoryDropChangeHandler = (event) => {
    this.setState({
      secondCategory: "",
      thirdCategory: "",
      firstCategory: event.value,
    });
    const apiUrl = global.baseUrl + "SellerDropCategoryList/" + event.value;
    axios
      .get(apiUrl)

      .then(
        (result) => {
          var option = [];
          for (let i = 0; i < result.data.data.length; i++) {
            option.push({
              label: result.data.data[i].name,
              value: result.data.data[i].id,
            });
          }
          this.setState({
            categories: option,
          });
        },
        (error) => {
          this.setState({ error });
        }
      );
  };
  secondCategoryDropChangeHandler = (event) => {
    this.setState({
      secondCategory: event.value,
      thirdCategory: "",
    });
    const apiUrl = global.baseUrl + "SellerSubCategoryList/" + event.value;
    axios.get(apiUrl).then(
      (result) => {
        var option = [];
        for (let i = 0; i < result.data.data.length; i++) {
          option.push({
            label: result.data.data[i].name,
            value: result.data.data[i].id,
          });
        }
        this.setState({
          subCategories: option,
        });
      },
      (error) => {
        this.setState({ error });
      }
    );
  };
  thirdCategoryDropChangeHandler = (event) => {
    this.setState({
      thirdCategory: event.value,
    });
  };

  resizeFile = (file) =>
    new Promise((resolve) => {
      convert({
        file: file,
        width: 250,
        height: 150,
        type: "jpeg",
      })
        .then((resp) => {
          resolve(resp);
        })
        .catch((error) => {
          // Error
        });
    });

  onImageChange = (event, index) => {
    console.log(index, "index");
    let productImage = this.state.productImage;
    if (event.target.files && event.target.files[0]) {
      var Extension = ["jpg", "jpeg", "png", "gif", "tiff"];
      var extArray = event.target.files[0].name.split(".");
      var ext = extArray[extArray.length - 1];
      var flag = 0;
      for (let i = 0; i < Extension.length; i++) {
        if (ext.toLowerCase() === Extension[i]) {
          flag = 1;
        }
      }

      if (flag) {
        let imageArray = this.state.imageArray;
        this.setState({ imagError: "" });
        imageError = "";
        let filedata = event.target.files[0];
        let reader = new FileReader();
        reader.onload = (e) => {
          var image = new Image();
          // let flag = 1;
          image.src = e.target.result;
          image.onload = () => {
            var height = image.height;
            var width = image.width;
            console.log(height, width);
            if (width > 151 && height > 250) {
              // this.setState({
              //   imagError: 'Please select image(250*151px)',
              // });
              // imageError = 'Please select image(250*151px)';
              // // fileName = '';
              // imageArray= imageArray;
              // this.setState({
              //   imageArray:this.state.imageArray,
              // });
              // sendImage[index] = '';
              this.resizeFile(filedata).then((data) => {
                filedata = data;
                imageArray[index].productImage = e.target.result;
                console.log(imageArray, index);
                this.setState(
                  {
                    imageArray: imageArray,
                  },
                  () => {
                    console.log(imageArray);
                  }
                );
                this.setState({ a: "" });
                // reader.readAsDataURL(event.target.files[0]);
                imageArray[index].sendImage = filedata;
              });
            } else if (width < 151 && height < 250) {
              // this.setState({
              //   imagError: 'Please select image(250*151px)',
              // });
              // imageError = 'Please select image(250*151px)';
              // // fileName = '';
              // imageArray= imageArray;
              // this.setState({
              //   imageArray:this.state.imageArray,
              // });
              // sendImage[index] = '';
              this.resizeFile(filedata).then((data) => {
                filedata = data;
                imageArray[index].productImage = e.target.result;
                console.log(productImage, index);
                this.setState(
                  {
                    productImage: productImage,
                  },
                  () => {
                    console.log(productImage);
                  }
                );
                imageArray[index].sendImage = filedata;
              });
            } else {
              imageArray[index].productImage = e.target.result;
              console.log(imageArray, index);
              this.setState(
                {
                  imageArray: imageArray,
                },
                () => {
                  console.log(imageArray);
                }
              );
              this.setState({ a: "" });
              // reader.readAsDataURL(event.target.files[0]);
              imageArray[index].sendImage = filedata;
            }
          };
        };
        reader.readAsDataURL(event.target.files[0]);

        // let imageArray = this.state.imageArray;
        // this.setState({ imagError: "" });
        // imageError = "";
        // let reader = new FileReader();
        // reader.onload = e => {
        //   imageArray[index].productImage = e.target.result;
        //   this.setState({
        //     imageArray: imageArray
        //   });
        // };
        // reader.readAsDataURL(event.target.files[0]);
        // imageArray[index].sendImage = event.target.files[0];
        // this.setState({
        //   imageArray: imageArray
        // });
        // this.setState({ a: "" });
      } else {
        let imageArray = this.state.imageArray;
        imageArray[index].productImage = "";
        imageArray[index].sendImage = "";
        this.setState({
          imageArray: imageArray,
          imagError: "Please select only image",
          fileName: "",
        });
        imageError = "Please select only image";
      }
    } else {
    }
  };
  onDescriptionChange = (evt, index) => {
    let description = this.state.descriptions;

    description[index] = evt.editor.getData();
    modal = "";
    this.setState({ descriptions: description });
  };
  addNewImage = () => {
    let imageArray = this.state.imageArray;
    let flag = 1;
    for (let i = 0; i < imageArray.length; i++) {
      if (imageArray[i].productImage === "") {
        flag = 0;
        
      }
    }
    
    imageArray.push({
      productImage: "",
      sendImage: "",
    });
    this.setState({
      imageArray: imageArray,
    });
    if (flag) {
      if (
        this.state.defaultProductLength <= this.state.imageArray.length &&
        this.state.imageArray.length >= 5
      ) {
        toast.warn(<InfoToast />, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 8000,
        });
      }
      // imageArray.push({
      //   productImage: "",
      //   sendImage: "",
      // });
      // this.setState({
      //   imageArray: imageArray,
      // });
    }
  };
  addVideo = () => {
    if (sendVideo) {
      console.log("image added");
      const apiUrl = global.baseUrl + "sellerImageUpload";

      const formData = new FormData();
      formData.append("myFile", sendVideo);
      const config = {
        headers: {
          "content-type": "multipart/form-data",
        },
      };
      axios
        .post(apiUrl, formData, config)
        .then((response) => {
          this.setState({ video: response.data.path });
          this.addImage();
        })
        .catch((error) => {});
    } else {
      this.addImage();
    }
  };
  onVideoChange = (event) => {
    console.log("working", event);
    if (event.target.files && event.target.files[0]) {
      let reader = new FileReader();
      reader.onload = (e) => {
        console.log("working", e.target.result);
      };
      reader.readAsDataURL(event.target.files[0]);
      this.setState({ productVideo: event.target.files[0].name });
      //console.log(event.target.files[0])
      sendVideo = event.target.files[0];
    } else {
      this.setState({ productVideo: "" });
      sendVideo = "";
    }
  };
  removeImage = (index) => {
    console.log(index, "index");
    let imageArray = this.state.imageArray;

    if (this.state.imageArray.length < 6) {
      // imageArray[index].productImage = "";
      // imageArray[index].sendImage = "";
      let newImageArr = this.state.imageArray.filter((arr,nindex) => {
        console.log(arr , "ooo1")
       

        return nindex!==index
      })
      // .map((row) => {
      //   console.log(row , "ooo")
      //   return row
      //   // this.setState({ imageArray: row });
        
      // })
      // imageArray[index]=''
      this.setState({ imageArray: newImageArr });
      // this.setState({ imageArray: imageArray });
    } else {
      imageArray.splice(index, 1);
      this.setState({ imageArray: imageArray });
    }
  };
  handlePriceChange(event, index) {
    let variation = this.state.variation;
    variation[index].price = event.target.value;

    this.setState({ variation: variation });
  }
  firstSubmitHandler = (e) => {
    // this.state.imageArray[i].productImage ===
    // this.state.imageArray[i].sendImage
    console.log(
      this.state.imageArray[0].productImage,
      "kk12",
      this.state.imageArray
    );
    if (this.state.productName === "") {
      this.setState({ nameError: "Please enter product name" });
      nameError = "Please enter product name";
    } else if (this.state.productName.trim() === "") {
      this.setState({ nameError: "Please enter valid product name" });
      nameError = "Please enter valid product name";
    } else {
      this.setState({ nameError: "" });
      nameError = "";
    }
    let spaceValidation = /^\S*$/;
    if (this.state.sku === "") {
      this.setState({ skuError: "Please enter sku" });
      skuError = "Please enter sku";
    } else if (!spaceValidation.test(this.state.sku)) {
      this.setState({ skuError: "Please enter valid sku" });
      skuError = "Please enter valid sku";
    } else {
      this.setState({ skuError: "" });
      skuError = "";
    }
    // if (this.state.brand === "") {
    //   this.setState({ brandError: "Please select brand" });
    //   brandError = "Please select brans";
    // } else {
    //   this.setState({ brandError: "" });
    //   brandError = "";
    // }
    if (this.state.condition === "") {
      this.setState({ conditionError: "Please select condition" });
      conditionError = "Please select condition";
    } else {
      this.setState({ conditionError: "" });
      conditionError = "";
    }

    // let numberCheck = /^[0-9]+$/;
    // if (this.state.quantity === "") {
    //   this.setState({ quantityError: "Please enter quantity " });
    //   quantityError = "Please enter quantity ";
    // } else if (
    //   !numberCheck.test(this.state.quantity) ||
    //   parseFloat(this.state.quantity) < 1
    // ) {
    //   this.setState({ quantityError: "Please enter valid quantity " });
    //   quantityError = "Please enter valid quantity ";
    // } else {
    //   this.setState({ quantityError: "" });
    //   quantityError = "";
    // }
    if (this.state.firstCategory === "") {
      this.setState({ firstCategoryError: "Please select first category " });
      firstCategoryError = "Please select first category ";
    } else {
      this.setState({ firstCategoryError: "" });
      firstCategoryError = "";
    }
    // if (this.state.secondCategory === "") {
    //   this.setState({ secondCategoryError: "Please select second category " });
    //   secondCategoryError = "Please select second category ";
    // } else {
    //   this.setState({ secondCategoryError: "" });
    //   secondCategoryError = "";
    // }
    // if (this.state.thirdCategory === "") {
    //   this.setState({ thirdCategoryError: "Please select third category " });
    //   thirdCategoryError = "Please select third category ";
    // } else {
    //   this.setState({ thirdCategoryError: "" });
    //   thirdCategoryError = "";
    // }
    // if (this.state.renewalOption === "") {
    //   this.setState({ renewalOptionError: "Please select renewal option" });
    //   renewalOptionError = "Please select r enewal option";
    // } else {
    //   this.setState({ renewalOptionError: "" });
    //   renewalOptionError = "";
    // }

    let flag = 1;
    let totalImage = 0;
    for (let i = 0; i < this.state.imageArray.length; i++) {
      if (this.state.imageArray[i].productImage !== "") {
        flag = 0;
        totalImage = totalImage + 1;
      }
    }
    this.setState({ totalImage: totalImage });
    // if (flag) {
    //   this.setState({ imageError: "Please select image" });
    //   imageError = "Please select image";
    // } else {
    //   this.setState({ imageError: "" });
    //   imageError = "";
    // }

    if (
      this.state.productName &&
      this.state.sku &&
      this.state.condition &&
      this.state.firstCategory &&
      firstCategoryError === "" &&
      colorSizeError === "" &&
      nameError === "" &&
      skuError === "" &&
      conditionError === "" &&
      imageError === ""
    ) {
      console.log("working");
      this.setState({ activeTab: "2" });
      // submitDisable = true;
      //     this.addVideo();
    }
  };
  secondSubmitHandler = (e) => {
    console.log(this.state.priceOn);
    if (this.state.priceOn === "1") {
      if (this.state.variationType === "normal") {
        variationTypeError = "";
        let vFlag = 1;
        let quantity = 0;
        for (let i = 0; i < this.state.variation.length; i++) {
          if (
            this.state.variation[i].name === "" ||
            this.state.variation[i].value === "" ||
            this.state.variation[i].qty === "" ||
            this.state.variation[i].price === ""
          ) {
            vFlag = 0;
            break;
          }
        }
        if (vFlag) {
          for (let i = 0; i < this.state.variation.length; i++) {
            quantity = quantity + parseFloat(this.state.variation[i].qty);
          }
          this.setState({ quantity: quantity });
          variationError = "";
        } else {
          this.setState({ variationError: "" });
          variationError = "Please fill  name,value, quantity and price ";
        }
      } else if (this.state.variationType === "color") {
        variationTypeError = "";
        let vFlag = 1;
        let quantity = 0;
        for (let i = 0; i < this.state.colorSize.length; i++) {
          if (
            this.state.colorSize[i].size === "" ||
            this.state.colorSize[i].qty === "" ||
            this.state.colorSize[i].price === ""
          ) {
            vFlag = 0;
            break;
          }
        }
        if (vFlag) {
          for (let i = 0; i < this.state.colorSize.length; i++) {
            quantity = quantity + parseFloat(this.state.colorSize[i].qty);
          }
          this.setState({ quantity: quantity });
          colorSizeError = "";
        } else {
          this.setState({ variationError: "" });
          colorSizeError = "Please fill  color,size ,quantity and price";
        }
      } else {
        this.setState({ variationTypeError: "Please select variation type" });
        variationTypeError = "Please select variation type";
      }
    } else {
      if (this.state.variationType === "normal") {
        variationTypeError = "";
        let vFlag = 1;
        let quantity = 0;
        for (let i = 0; i < this.state.variation.length; i++) {
          if (
            this.state.variation[i].name === "" ||
            this.state.variation[i].value === "" ||
            this.state.variation[i].qty === ""
          ) {
            vFlag = 0;
            break;
          }
        }
        if (vFlag) {
          for (let i = 0; i < this.state.variation.length; i++) {
            quantity = quantity + parseFloat(this.state.variation[i].qty);
          }
          this.setState({ quantity: quantity });
          variationError = "";
        } else {
          this.setState({ variationError: "" });
          variationError = "Please fill  name,value and quantity ";
        }
      } else if (this.state.variationType === "color") {
        variationTypeError = "";
        let vFlag = 1;
        let quantity = 0;
        for (let i = 0; i < this.state.colorSize.length; i++) {
          if (
            this.state.colorSize[i].size === "" ||
            this.state.colorSize[i].qty === ""
          ) {
            vFlag = 0;
            break;
          }
        }
        if (vFlag) {
          for (let i = 0; i < this.state.colorSize.length; i++) {
            quantity = quantity + parseFloat(this.state.colorSize[i].qty);
          }
          this.setState({ quantity: quantity });
          colorSizeError = "";
        } else {
          this.setState({ variationError: "" });
          colorSizeError = "Please fill  color,size and quantity ";
        }
      } else {
        this.setState({ variationTypeError: "Please select variation type" });
        variationTypeError = "Please select variation type";
      }
    }
    let pointNumberCheck = /^[0-9]\d*(\.\d+)?$/;
    modal = false;
    this.setState({ a: "" });
    if (this.state.priceOn === "0") {
      if (this.state.price === "") {
        this.setState({ priceError: "Please enter price " });
        priceError = "Please enter price ";
      }
      //  else if (
      //   !pointNumberCheck.test(this.state.price) ||
      //   parseFloat(this.state.price) <= 0
      // ) {
      //   this.setState({ priceError: "Please enter valid price " });
      //   priceError = "Please enter valid price ";
      // }
      else {
        this.setState({ priceError: "" });
        priceError = "";
      }
      if (this.state.msrp !== "") {
        if (
          !pointNumberCheck.test(this.state.msrp) ||
          parseFloat(this.state.msrp) <= 0
        ) {
          this.setState({ msrpError: "Please enter valid price " });
          msrpError = "Please enter valid MSRP ";
        } else {
          this.setState({ msrpError: "" });
          msrpError = "";
        }
      }
    }
    let newFlag = 1;
    for (let i = 0; i < this.state.descriptions.length; i++) {
      if (this.state.descriptions[i] !== "") {
        if (this.state.descriptions[i].trim() !== "") {
          newFlag = 0;
        }
      }
    }
    if (newFlag) {
      this.setState({ descriptionError: "Please enter product description " });
      descriptionError = "Please enter  description ";
    } else {
      this.setState({ descriptionError: "" });
      descriptionError = "";
    }
    // if (fitGuide === "") {
    //   this.setState({ fitGuideError: "Please enter fit Guide " });
    //   fitGuideError = "Please enter fit guide ";
    // } else if (this.state.fitGuide.trim() === "") {
    //   this.setState({ fitGuideError: "Please enter valid fit Guide " });
    //   fitGuideError = "Please enter valid fit guide ";
    // } else {
    //   this.setState({ fitGuideError: "" });
    //   fitGuideError = "";
    // }
    // if (glossary === "") {
    //   this.setState({ glossaryError: "Please enter glossary " });
    //   glossaryError = "Please enter glossary ";
    // } else if (this.state.glossary.trim() === "") {
    //   this.setState({ glossaryError: "Please enter glossary " });
    //   glossaryError = "Please enter glossary ";
    // } else {
    //   this.setState({ glossaryError: "" });
    //   glossaryError = "";
    // }
    if (
      (this.state.discount && isNaN(this.state.discount)) ||
      (this.state.discount && parseInt(this.state.discount) < 0) ||
      (this.state.discount && parseInt(this.state.discount) > 100)
    ) {
      this.setState({ discountError: "Please enter valid discount" });
      discountError = "Please enter valid discount";
    } else {
      this.setState({ discountError: "" });
      discountError = "";
    }
    if (
      (this.state.taxAmount && isNaN(this.state.taxAmount)) ||
      (this.state.taxAmount && parseInt(this.state.taxAmount) < 0) ||
      (this.state.taxAmount && parseInt(this.state.taxAmount) > 100)
    ) {
      this.setState({ taxAmountError: "Please enter valid tax amount" });
      taxAmountError = "Please enter valid tax amount";
    } else {
      this.setState({ taxAmountError: "" });
      taxAmountError = "";
    }
    if (this.state.discount && this.state.discountType === "") {
      this.setState({ discountTypeError: "Please select discount type" });
      discountTypeError = "Please select discount type";
    } else {
      this.setState({ discountTypeError: "" });
      discountTypeError = "";
    }
    if (
      this.state.variationType &&
      variationError === "" &&
      variationTypeError === "" &&
      priceError === "" &&
      msrpError === "" &&
      discountError === "" &&
      discountTypeError === "" &&
      descriptionError === "" &&
      taxAmountError === "" &&
      colorSizeError === ""
    ) {
      console.log("working");
      this.setState({ activeTab: "3" });
    }
  };
  appendDescription = () => {
    description = this.state.descriptions;
    description.push("");
    this.setState({ descriptions: description });
  };
  modalToggle = () => {
    if (
      this.state.defaultProductLength < this.state.totalImage &&
      this.state.totalImage > 5
    ) {
      if (modal === false) {
        modal = true;
      } else {
        modal = false;
      }
    } else {
      this.uploadImage();
    }

    this.setState({ A: "" });
  };
  modal2Toggle = () => {
    if (modal2 === false) {
      modal2 = true;
    } else {
      modal2 = false;
    }
    this.setState({ A: "" });
  };
  paymentOpen = () => {
    modal = false;
    modal2 = true;
    let total = 0;

    if (
      this.state.defaultProductLength <= this.state.totalImage &&
      this.state.totalImage >= 5 &&
      this.state.defaultProductLength <= 5
    ) {
      total = (this.state.totalImage - 5) * parseFloat(charge.image_charge);
    } else if (
      this.state.defaultProductLength <= this.state.totalImage &&
      this.state.totalImage >= 5 &&
      this.state.defaultProductLength >= 5
    ) {
      total =
        (this.state.totalImage - parseInt(this.state.defaultProductLength)) *
        parseFloat(charge.image_charge);
    } else {
      this.uploadImage();
    }
    this.setState({ finalTotal: total });
  };
  async uploadImage() {
    let newProductImage = [];
    const apiUrl = global.baseUrl + "sellerImageUpload";
    let i = 0;
    for (i = 0; i < this.state.imageArray.length; i++) {
      if (
        this.state.imageArray[i].productImage ===
        this.state.imageArray[i].sendImage
      ) {
        newProductImage.push(this.state.imageArray[i].sendImage);
      } else if (
        this.state.imageArray[i].productImage !==
          this.state.imageArray[i].sendImage &&
        this.state.imageArray[i].sendImage !== ""
      ) {
        const formData = new FormData();
        formData.append("myFile", this.state.imageArray[i].productImage);
        const config = {
          headers: {
            "content-type": "multipart/form-data",
          },
        };
        await axios
          .post(apiUrl, formData, config)
          .then((response) => {
            console.log(response.data);
            newProductImage.push(response.data.path);
          })
          .catch((error) => {});
      }
    }
    if (i === this.state.imageArray.length) {
      this.setState({ productImage: newProductImage }, () => {
        this.productApiCall();
      });
    }
  }
  productApiCall = () => {
    if (parseInt(this.state.ItemWeight_LB) > 40) {
      this.setState({
        ItemWeight_LB_Error: "More then 40 weight not allow",
      });
    }
    if (parseInt(this.state.ItemWeight_OZ) > 40) {
      this.setState({
        ItemWeight_OZ_Error: "More then 40 weight not allow",
      });
    }
    if (parseInt(this.state.Length) > 30) {
      this.setState({
        lengthError: "More then 30 Length not allow",
      });
    }
    if (parseInt(this.state.Width) > 30) {
      this.setState({
        widthError: "More then 30 Width not allow",
      });
    }
    if (parseInt(this.state.Height) > 30) {
      this.setState({
        HeightError: "More then 30 Height not allow",
      });
    }
    if (
      parseInt(this.state.ItemWeight_LB) > 40 ||
      parseInt(this.state.ItemWeight_OZ) > 40 ||
      parseInt(this.state.Length) > 30 ||
      parseInt(this.state.Width) > 30 ||
      parseInt(this.state.Height) > 30
    ) {
    } else {
      const urlencoded = new FormData();

      urlencoded.append("sellerId", this.state.seller.id);
      urlencoded.append("id", this.state.id);

      urlencoded.append("product_name", this.state.productName);
      urlencoded.append("sku", this.state.sku);
      urlencoded.append("variation_type", this.state.variationType);
      urlencoded.append("condition", this.state.condition);
      urlencoded.append("quantity", this.state.quantity);

      let temp = [];
      if (this.state.variationType === "normal") {
        for (let i = 0; i < this.state.variation.length; i++) {
          // urlencoded.append("color_size", JSON.stringify(this.state.variation[i]));
          // temp.push(this.state.variation[i].price)
          temp.push(this.state.variation[i].price);
          if (this.state.variation[i].price == "") {
            this.state.variation[i].price = this.state.price;
          }
          urlencoded.append(
            "color_size",
            JSON.stringify(this.state.variation[i])
          );
        }
      } else {
        for (let i = 0; i < this.state.colorSize.length; i++) {
          temp.push(parseFloat(this.state.colorSize[i].price));
          if (
            this.state.colorSize[i].price == "" ||
            (this.state.price != "" && parseFloat(this.state.price))
          ) {
            this.state.colorSize[i].price = parseFloat(this.state.price);
          }
          urlencoded.append(
            "color_size",
            JSON.stringify(this.state.colorSize[i])
          );
        }
      }

      for (let i = 0; i < this.state.productImage.length; i++) {
        if (this.state.productImage[i] !== "") {
          urlencoded.append("image", this.state.productImage[i]);
        } else {
        }
      }

      for (let i = 0; i < this.state.imageArray.length; i++) {
        if (
          this.state.imageArray[i].productImage !==
            this.state.imageArray[i].sendImage &&
          this.state.imageArray[i].sendImage == ""
        ) {
          urlencoded.append("image", this.state.imageArray[i].productImage);
        }
      }
      // for (let i = 0; i < this.state.productImage.length; i++) {
      //   console.log(this.state.imageArray[i].productImage, "abc");
      //   if (this.state.productImage[i] !== "") {
      //     urlencoded.append("image", this.state.productImage[i]);
      //   }else{

      //   }
      // }
      // for (let i = 0; i < this.state.imageArray.length; i++) {
      //   console.log(this.state.imageArray[i].productImage, "abc")

      //   if((this.state.imageArray[i].productImage !== this.state.imageArray[i].sendImage) && this.state.imageArray[i].sendImage !== "") {
      //     // const urlencoded = new FormData();
      //     urlencoded.append("image", this.state.imageArray[i].productImage);
      //   }
      // }
      if (this.state.price !== "") {
        console.log("if");
        urlencoded.append("price", this.state.price);
      } else {
        console.log("else");
        if (temp.length > 1) {
          const nums = temp.sort((x, y) => x - y);
          let min_val = nums[0];
          let max_val = nums[nums.length - 1];
          console.log("min:", min_val, "max", max_val);
          urlencoded.append("price", min_val + "-" + max_val);
        } else {
          urlencoded.append("price", this.state.price);
        }
      }
      urlencoded.append("super_categoryId", this.state.firstCategory);
      urlencoded.append("categoryId", this.state.secondCategory);
      urlencoded.append("sub_categoryId", this.state.thirdCategory);
      urlencoded.append("msrp", this.state.msrp);
      urlencoded.append("discount_type", this.state.discountType);
      urlencoded.append("discount", this.state.discount);
      for (let i = 0; i < this.state.descriptions.length; i++) {
        if (this.state.descriptions[i] !== "") {
          urlencoded.append("product_description", this.state.descriptions[i]);
        }
      }
      urlencoded.append("tags", this.state.tag);
      // urlencoded.append("renewal", this.state.renewalOption);

      urlencoded.append("tax_country", this.state.country);
      urlencoded.append("tax_amount", this.state.taxAmount);
      // urlencoded.append("payment_type", this.state.paymentType);
      urlencoded.append("paymentID", this.state.paypalPaymentId);
      urlencoded.append("transactionId", this.state.transactionId);
      urlencoded.append("price_on_off", this.state.priceOn);

      urlencoded.append("walletId", this.state.wallerPaymentId);
      urlencoded.append("shippingCarrier", this.state.shippingcarrier);
      urlencoded.append("shipping_cost", this.state.shippingPrice);
      urlencoded.append("mailClass", this.state.mailclass);
      urlencoded.append("zipCode", this.state.ZipCode);
      urlencoded.append("itemLocation", this.state.ZipCode);
      urlencoded.append("itemWeight_LB", this.state.ItemWeight_LB);
      urlencoded.append("itemWeight_OZ", this.state.ItemWeight_OZ);
      urlencoded.append("packingType", this.state.PackingType);
      urlencoded.append("length", this.state.Length);
      urlencoded.append("width", this.state.Width);
      urlencoded.append("height", this.state.Height);
      urlencoded.append("pickUpCost", this.state.PickUpCost);
      urlencoded.append("pickUpAdditional", this.state.PickUpAdditional);
      urlencoded.append("process_time", this.state.brand);

      //urlencoded.append("bargain", this.state.bargain);
      axios
        .post(global.baseUrl + "sellerproductupdate", urlencoded, {
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
        })
        .then((result) => {
          console.log(result.data);
          if (result.data.status === 1) {
            toast.success(<CustomToast />, {
              position: toast.POSITION.TOP_RIGHT,
            });
            this.setState({
              lengthError: "",
              HeightError: "",
              widthError: "",
              ItemWeight_LB_Error: "",
              ItemWeight_OZ_Error: "",
            });
            isActive = false;
            this.setState({ a: "" });
            this.props.history.push("/productpaymentdone");
          } else if (result.data.status === 2) {
            toast.success(<CustomToast />, {
              position: toast.POSITION.TOP_RIGHT,
            });
            isActive = false;
            this.setState({ a: "" });
            this.props.history.push("/productlist");
          }
        });
    }
  };
  variationCheckHandler = () => {
    if (this.state.variationType === "color") {
      let colorSize = this.state.colorSize;
      for (let i = 1; i < colorSize.length; i++) {
        if (colorSize[i].size === "" && colorSize[i].qty === "") {
          colorSize.splice(i, 1);
        }
      }
      this.setState({ colorSize: colorSize }, () => {
        this.secondSubmitHandler();
      });
    } else if (this.state.variationType === "normal") {
      let variation = this.state.variation;
      for (let i = 1; i < this.state.variation.length; i++) {
        console.log("working", variation[i].name, variation[i].value);
        if (
          variation[i].name === "" &&
          variation[i].value === "" &&
          variation[i].qty === ""
        ) {
          console.log("working");
          variation.splice(i, 1);
        }
      }
      this.setState({ variation: variation }, () => {
        this.secondSubmitHandler();
      });
    } else {
      this.secondSubmitHandler();
    }
  };
  onSuccess = (data) => {
    modal2 = false;
    isActive = true;
    this.setState({
      paypalPaymentId: data.paymentID,
      paymentType: "paypal",
    });
    this.uploadImage();
  };
  transactionCanceled = () => {
    console.log("transaction cancel");
  };
  transactionError = () => {
    console.log("transaction error");
  };
  onToken = (amount, description) => (token) => {
    modal2 = false;
    isActive = true;
    this.setState({ a: "" });
    let urlencoded = new URLSearchParams();
    urlencoded.append("email", token.email);
    urlencoded.append("source", token.id);
    urlencoded.append("token", JSON.stringify(token));
    urlencoded.append("description", description);
    urlencoded.append("amount", parseInt(parseFloat(amount) * 100));
    urlencoded.append("currency", CURRENCY);
    urlencoded.append("cardName", token.card.name);

    axios
      .post(global.baseUrl + "SellerProductStripePayment", urlencoded)
      .then((result) => {
        console.log(result.data);
        if (result.data.status) {
          this.setState({
            transactionId: result.data.data.balance_transaction,
            paymentType: "credit_debit",
          });
          this.uploadImage();
        }
        //successPayment();
      })
      .catch();
  };
  handleNameChange(event, index) {
    let variation = this.state.variation;
    variation[index].name = event.target.value;
    this.setState({ variation: variation });
  }
  handleValueChange(event, index) {
    let variation = this.state.variation;
    variation[index].value = event.target.value;
    this.setState({ variation: variation });
  }
  handleQtyChange(event, index) {
    let variation = this.state.variation;
    variation[index].qty = event.target.value;
    this.setState({ variation: variation });
  }

  checkQty = (index) => {
    let numberCheck = /^[0-9]+$/;

    let variation = this.state.variation;

    if (
      numberCheck.test(variation[index].qty) &&
      parseFloat(variation[index].qty) >= 1
    ) {
      variation[index].qty = parseFloat(variation[index].qty);
    } else {
      variation[index].qty = "";
    }
    this.setState({ variation: variation });
  };
  checkCustomPrice = (index) => {
    let pointNumberCheck = /^[0-9]\d*(\.\d+)?$/;
    let variation = this.state.variation;
    if (
      pointNumberCheck.test(variation[index].price) &&
      parseFloat(variation[index].price) >= 1
    ) {
      variation[index].price = parseFloat(variation[index].price);
    } else {
      variation[index].price = "";
    }
    let flag = 0;
    for (let i = 0; i < variation.length; i++) {
      if (variation[i].price !== "") {
        flag = 1;
        break;
      }
    }
    if (flag) {
      priceError = "";
      msrpError = "";
      this.setState({
        priceOn: "1",
        price: "",
        msrp: "",
      });
    }
    this.setState({ variation: variation });
  };
  checkName = (index) => {
    let variation = this.state.variation;
    if (variation[index].name) {
      if (variation[index].name.trim() === "") {
        variation[index].name = "";
      }
    }
    this.setState({ variation: variation });
  };
  checkValue = (index) => {
    let variation = this.state.variation;
    if (variation[index].value) {
      if (variation[index].value.trim() === "") {
        variation[index].value = "";
      }
    }
    this.setState({ variation: variation });
  };
  appendVariation() {
    let variation = this.state.variation;
    let flag = 1;
    for (let i = 0; i < variation.length; i++) {
      if (
        variation[i].name === "" ||
        variation[i].value === "" ||
        variation[i].qty === ""
      ) {
        flag = 0;
        break;
      }
    }
    if (flag) {
      variation.push({
        name: "",
        value: "",
        qty: "",
        price: "",
      });
      variationError = "";
    } else {
      variationError =
        "Please fill  name,value and qty then it will allow to add new variation";
    }
    this.setState({
      variation: variation,
    });
  }
  handleColorChange(event, index) {
    let colorSize = this.state.colorSize;
    colorSize[index].color = event.hex;
    this.setState({ colorSize: colorSize });
  }
  handleSizeChange(event, index) {
    let colorSize = this.state.colorSize;
    colorSize[index].size = event.target.value;
    this.setState({ colorSize: colorSize });
  }

  handleCSPriceChange(event, index) {
    let colorSize = this.state.colorSize;
    colorSize[index].price = event.target.value;
    this.setState({ colorSize: colorSize });
  }
  checkCSCustomPrice = (index) => {
    let pointNumberCheck = /^[0-9]\d*(\.\d+)?$/;
    let colorSize = this.state.colorSize;
    if (
      pointNumberCheck.test(colorSize[index].price) &&
      parseFloat(colorSize[index].price) >= 1
    ) {
      colorSize[index].price = parseFloat(colorSize[index].price);
    } else {
      colorSize[index].price = "";
    }
    let flag = 0;
    for (let i = 0; i < colorSize.length; i++) {
      if (colorSize[i].price !== "") {
        flag = 1;
        break;
      }
    }
    if (flag) {
      priceError = "";
      msrpError = "";
      this.setState({
        priceOn: "1",
        price: "",
        msrp: "",
      });
    }
    this.setState({ colorSize: colorSize });
  };
  handleCSQtyChange(event, index) {
    let colorSize = this.state.colorSize;
    colorSize[index].qty = event.target.value;
    this.setState({ colorSize: colorSize });
  }
  checkCSQty = (index) => {
    let numberCheck = /^[0-9]+$/;
    let colorSize = this.state.colorSize;
    if (
      numberCheck.test(colorSize[index].qty) &&
      parseFloat(colorSize[index].qty) >= 1
    ) {
      colorSize[index].qty = parseFloat(colorSize[index].qty);
    } else {
      colorSize[index].qty = "";
    }
    this.setState({ colorSize: colorSize });
  };
  checkSize = (index) => {
    let colorSize = this.state.colorSize;
    if (colorSize[index].size) {
      if (colorSize[index].size.trim() === "") {
        colorSize[index].size = "";
      }
    }
    this.setState({ colorSize: colorSize });
  };
  appendColorSize() {
    let colorSize = this.state.colorSize;
    let flag = 1;
    for (let i = 0; i < colorSize.length; i++) {
      if (
        colorSize[i].color === "" ||
        colorSize[i].size === "" ||
        colorSize[i].qty === ""
      ) {
        flag = 0;
        break;
      }
    }
    if (flag) {
      colorSize.push({
        color: "#000000",
        size: "",
        qty: "",
        price: "",
      });
      colorSizeError = "";
    } else {
      colorSizeError =
        "Please fill  color,size and quantity then it will allow to add new variation";
    }
    this.setState({
      colorSize: colorSize,
    });
  }
  walletPayment = () => {
    if (
      parseFloat(this.state.finalTotal) <=
        parseFloat(this.state.walletAmount) &&
      parseFloat(this.state.walletAmount) > 0
    ) {
      modal2 = false;
      isActive = true;
      this.setState({ a: "" });

      let urlencoded = new URLSearchParams();

      urlencoded.append("sellerId", this.state.seller.id);
      urlencoded.append("credit", this.state.finalTotal);
      axios
        .post(global.baseUrl + "SellerCreditWallet", urlencoded)
        .then((result) => {
          console.log(result.data);
          if (result.data.status) {
            this.setState({
              paymentType: "wallet",
              wallerPaymentId: result.data.data.id,
            });
            this.uploadImage();
          }
          //successPayment();
        })
        .catch();
    } else {
      toast.error(<WarnToast />, {
        position: toast.POSITION.TOP_RIGHT,
      });
      console.log("working not");
    }
  };
  priceOnOffHandler = (data) => {
    console.log(data, "dddddddddqqqqqqqqqq");
    // if (this.state.price === "" && data == "1") {
    //   priceError = ""
    //   this.setState({ priceOn: "1" })
    // }
    // else if (data == "0") {
    //   let flag = 1
    //   let colorSize = this.state.colorSize
    //   for (let i = 0; i < colorSize.length; i++) {
    //     if (colorSize[i].price !== "") {
    //       flag = 0
    //       break
    //     }
    //   }
    //   console.log(flag)
    //   if (flag) {
    //     console.log(flag,"ppppp");
    //     variationError = ""
    //     this.setState({ priceOn: "0" })
    //   }
    //   let variation = this.state.variation
    //   for (let i = 0; i < variation.length; i++) {
    //     if (variation[i].price !== "") {
    //       flag = 0
    //       break
    //     }
    //   }
    //   // console.log(flag,"ppppp")
    //   if (flag) {
    //     variationError = ""
    //     console.log(flag,"ppppp");
    //     this.setState({ priceOn: "0" })
    //   }
    // }
    if (data == "1") {
      priceError = "";
      this.setState({ priceOn: "1" });
    } else if (data == "0") {
      // let flag = 1
      // let colorSize = this.state.colorSize
      // for (let i = 0; i < colorSize.length; i++) {
      //   if (colorSize[i].price !== "") {
      //     flag = 0
      //     break
      //   }
      // }
      // console.log(flag)
      // if (flag) {
      //   console.log(flag,"ppppp");
      //   variationError = ""
      //   this.setState({ priceOn: "0" })
      // }
      // let variation = this.state.variation
      // for (let i = 0; i < variation.length; i++) {
      //   if (variation[i].price !== "") {
      //     flag = 0
      //     break
      //   }
      // }
      // // console.log(flag,"ppppp")
      // if (flag) {
      //   variationError = ""
      //   console.log(flag,"ppppp");
      //   this.setState({ priceOn: "0" })
      // }
      this.setState({ priceOn: "0" });
    }
  };
  ShippingCarrierDropChangeHandler = (event) => {
    console.log("shipping");
    this.setState({
      mailclass: "",
      shippingcarrier: event.value,
    });
    const apiUrl = global.baseUrl + "MailClassList/" + event.value;
    axios
      .get(apiUrl)

      .then(
        (result) => {
          var option = [];
          for (let i = 0; i < result.data.data.length; i++) {
            console.log(result.data.data, "shipp");
            option.push({
              label: result.data.data[i].class_name,
              value: result.data.data[i].id,
            });
          }
          this.setState({
            mailclasses: option,
          });
        },
        (error) => {
          this.setState({ error });
        }
      );
  };
  MailClassDropChangeHandler = (event) => {
    this.setState({
      mailclass: event.value,
    });
  };

  render() {
    return (
      <body
        class="vertical-layout vertical-menu-modern semi-dark-layout 2-columns  navbar-floating footer-static  "
        data-open="click"
        data-menu="vertical-menu-modern"
        data-col="2-columns"
        data-layout="semi-dark-layout"
      >
        {/* <Header />
        <Sidebar /> */}
        <LoadingOverlay
          active={isActive}
          spinner
          text="Please wait we are processing your payment"
        />
        <div class="app-content content">
          <div class="content-overlay"></div>
          <div class="header-navbar-shadow"></div>

          <div class="content-wrapper">
            <div class="content-header row">
              <div class="content-header-left col-md-9 col-12 mb-2">
                <div class="row breadcrumbs-top">
                  <div class="col-12">
                    <h2 class="content-header-title float-left mb-0">
                      <Link to={"/productlist"} className="back-arrow  mr-2">
                        <i className="feather icon-arrow-left"></i>
                      </Link>
                      Edit Product
                    </h2>
                    <div class="breadcrumb-wrapper col-12">
                      <ol class="breadcrumb">
                        <li class="breadcrumb-item">
                          <Link to="/productlist">Products</Link>
                        </li>
                        <li class="breadcrumb-item active">Edit Product</li>
                      </ol>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="content-body">
              <section class="users-edit">
                <div class="card">
                  <div class="card-content">
                    <div class="card-body">
                      <Nav tabs>
                        <NavItem>
                          <NavLink
                            className={classnames({
                              active: this.state.activeTab === "1",
                            })}
                          >
                            <span class="d-none d-sm-block">Information</span>
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className={classnames({
                              active: this.state.activeTab === "2",
                            })}
                          >
                            <span class="d-none d-sm-block">Pricing</span>
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className={classnames({
                              active: this.state.activeTab === "3",
                            })}
                            // onClick={() => {
                            //   this.toggle("3");
                            // }}
                          >
                            <span class="d-none d-sm-block">Shipping</span>
                          </NavLink>
                        </NavItem>
                      </Nav>

                      <TabContent activeTab={this.state.activeTab}>
                        <Modal isOpen={modal} toggle={this.modalToggle}>
                          <ModalHeader toggle={this.modalToggle}>
                            <h5 class="info-modal-title">Cost of images</h5>
                          </ModalHeader>
                          <ModalBody>
                            <h6>
                              If you are not set price based on variation then
                              it will take default price.And if you add more
                              then 5 image it will take extra cost as per
                              policy.
                            </h6>
                            <table class="table table-bordered">
                              <thead>
                                <tr>
                                  <th>No</th>
                                  <th>Type</th>
                                  <th>Cost</th>
                                </tr>
                              </thead>
                              <tbody>
                                {this.state.totalImage > 5 ? (
                                  <tr>
                                    <td>2</td>
                                    <td>Image</td>
                                    <td>
                                      ${" "}
                                      {(this.state.totalImage -
                                        this.state.defaultProductLength) *
                                        parseFloat(charge.image_charge)}
                                    </td>
                                  </tr>
                                ) : null}
                                {this.state.totalImage > 5 ? (
                                  <tr>
                                    <td colSpan={2}>Total Cost</td>
                                    <td>
                                      ${" "}
                                      {(this.state.totalImage -
                                        this.state.defaultProductLength) *
                                        parseFloat(charge.image_charge)}
                                    </td>
                                  </tr>
                                ) : (
                                  <tr>
                                    <td colSpan={2}>Total Cost</td>
                                    <td>
                                      ${parseFloat(charge.product_charge)}
                                    </td>
                                  </tr>
                                )}
                              </tbody>
                            </table>
                          </ModalBody>
                          <ModalFooter>
                            <button
                              type="button"
                              class="btn btn-success"
                              onClick={this.paymentOpen}
                            >
                              OK
                            </button>
                            <button
                              type="button"
                              class="btn btn-success "
                              onClick={this.modalToggle}
                            >
                              Cancel
                            </button>
                          </ModalFooter>
                        </Modal>
                        <Modal
                          isOpen={modal2}
                          toggle={this.modal2Toggle}
                          style={{ width: "70%" }}
                          contentClassName="custom-modal-style"
                        >
                          <ModalHeader toggle={this.modal2Toggle}>
                            <h5 class="info-modal-title">
                              Select Payment Method
                            </h5>
                          </ModalHeader>
                          <ModalBody>
                            <ul class="list-unstyled mb-0">
                              <li>
                                <b>
                                  you need to pay $ {this.state.finalTotal} for
                                  add new product.
                                </b>
                              </li>
                              <li>
                                <div class="vs-radio-con model-resize-div">
                                  <button
                                    class="btn btn-primary-green p-1 wallet-payment-btn"
                                    type="button"
                                    onClick={() => this.walletPayment()}
                                  >
                                    Pay From Wallet (
                                    <b>${this.state.walletAmount}</b>)
                                  </button>
                                </div>
                              </li>

                              <li>
                                <div class="vs-radio-con model-resize-div">
                                  <Paypal
                                    totalPay={this.state.finalTotal}
                                    onSuccess={this.onSuccess}
                                    transactionError={this.transactionError}
                                    transactionCanceled={
                                      this.transactionCanceled
                                    }
                                  />
                                </div>
                              </li>
                              <li>
                                <fieldset>
                                  <div class="vs-radio-con model-resize-div">
                                    <StripeCheckout
                                      stripeKey="pk_test_51GtSo6BPY6CGrhq8PbbsO8Cd70i2F9wD7u4qk4gwCugvjMakwViTZIfsUVwmnU6qfQacoJUCpaGFSnvognvlK3HI00CIAYrPMY"
                                      token={this.onToken(
                                        this.state.finalTotal,
                                        "Product Payment"
                                      )}
                                      name="Garage Lane"
                                      amount={fromDollarToCent(
                                        this.state.finalTotal
                                      )}
                                      currency={CURRENCY}
                                    >
                                      <button
                                        className="btn btn-success btn-primary resize-credit-debit-btn float-right mr-1 btn-inline waves-effect waves-light pay-btn"
                                        data-dismiss="modal"
                                      >
                                        Credit/Debit Card
                                      </button>
                                    </StripeCheckout>
                                  </div>
                                </fieldset>
                              </li>
                            </ul>
                          </ModalBody>
                          <ModalFooter>
                            <button
                              type="button"
                              class="btn btn-success"
                              onClick={this.modal2Toggle}
                            >
                              OK
                            </button>
                            <button
                              type="button"
                              class="btn btn-success "
                              onClick={this.modal2Toggle}
                            >
                              Cancel
                            </button>
                          </ModalFooter>
                        </Modal>

                        <TabPane tabId="1">
                          <div class="col-md-12 col-12 ">
                            <div class="card">
                              <div class="card-header cr-padding">
                                <div class="card-title mb-2">
                                  Product Information
                                </div>
                              </div>
                              <div class="">
                                <section class="page-users-view">
                                  <div class="row">
                                    <div class="col-md-7">
                                      <div class="row ">
                                        <div class="col-12 col-lg-12 base-product-d">
                                          <label className="b-label">
                                            Product Name
                                            <span class="required-span">*</span>
                                          </label>

                                          <div class="form-label-group position-relative has-icon-left">
                                            <input
                                              type="text"
                                              id="first-name-floating-icon1"
                                              class="form-control"
                                              name="productName"
                                              value={this.state.productName}
                                              onChange={this.handleChange}
                                              placeholder="Product Name"
                                            />
                                            <div class="form-control-position">
                                              <i class="feather icon-package"></i>
                                            </div>
                                            <p class="error-msg">{nameError}</p>
                                          </div>
                                        </div>
                                      </div>
                                      <div class="row ">
                                        <div class="col-12 col-lg-12 base-product-d">
                                          <label className="b-label">
                                            sku
                                            <span class="required-span">*</span>
                                          </label>
                                          <div class="form-label-group position-relative has-icon-left">
                                            <input
                                              type="text"
                                              id="first-name-floating-icon"
                                              class="form-control"
                                              name="sku"
                                              value={this.state.sku}
                                              onChange={this.handleChange}
                                              placeholder="sku"
                                              maxLength={20}
                                            />
                                            <div class="form-control-position">
                                              <i class="feather icon-package"></i>
                                            </div>
                                            <p class="error-msg">{skuError}</p>
                                          </div>
                                        </div>
                                      </div>
                                      <div class="row">
                                        {/* <div class="col-12 col-lg-6 base-product-d">
                                            <label className="b-label">
                                              Brands
                                              <span class="required-span">
                                                *
                                              </span>
                                            </label>
                                            <div class="form-label-group position-relative has-icon-left">
                                              <Select
                                                value={this.state.brands.filter(
                                                  option =>
                                                    option.value ===
                                                    this.state.brand
                                                )}
                                                theme={theme => ({
                                                  ...theme,
                                                  borderRadius: "5px",
                                                  border: "0px solid",
                                                  colors: {
                                                    ...theme.colors,
                                                    text: "orangered",
                                                    primary25: "#9cf59c",
                                                    primary: "#47A547"
                                                  }
                                                })}
                                                options={this.state.brands}
                                                name="brand"
                                                onChange={val =>
                                                  this.brandDropChangeHandler(
                                                    val
                                                  )
                                                }
                                              />
                                              <p class="error-msg">
                                                {brandError}
                                              </p>
                                            </div>
                                          </div> */}
                                        <div class="col-12 col-lg-6 base-product-d">
                                          <label className="b-label">
                                            Condition
                                            <span class="required-span">*</span>
                                          </label>
                                          <div class="form-label-group position-relative has-icon-left">
                                            <Select
                                              value={this.state.conditions.filter(
                                                (option) =>
                                                  option.value ===
                                                  this.state.condition
                                              )}
                                              theme={(theme) => ({
                                                ...theme,
                                                borderRadius: "5px",
                                                border: "0px solid",
                                                colors: {
                                                  ...theme.colors,
                                                  text: "orangered",
                                                  primary25: "#9cf59c",
                                                  primary: "#47A547",
                                                },
                                              })}
                                              options={this.state.conditions}
                                              name="condition"
                                              onChange={(val) =>
                                                this.conditionDropChangeHandler(
                                                  val
                                                )
                                              }
                                            />
                                            <p class="error-msg">
                                              {conditionError}
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                      <div class="row">
                                        <div class="col-12">
                                          <div
                                            class="card-header"
                                            style={{
                                              padding: "0px",
                                              paddingTop: "10px",
                                            }}
                                          >
                                            <div class="card-title mt-1 mb-1">
                                              Category
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div class="row">
                                        <div class="col-12 col-lg-6 base-product-d">
                                          <label className="b-label">
                                            Main Category
                                            <span class="required-span">*</span>
                                          </label>
                                          <div class="form-label-group position-relative has-icon-left">
                                            <Select
                                              value={this.state.superCategories.filter(
                                                (option) =>
                                                  option.value ===
                                                  this.state.firstCategory
                                              )}
                                              theme={(theme) => ({
                                                ...theme,
                                                borderRadius: "5px",
                                                border: "0px solid",
                                                colors: {
                                                  ...theme.colors,
                                                  text: "orangered",
                                                  primary25: "#9cf59c",
                                                  primary: "#47A547",
                                                },
                                              })}
                                              options={
                                                this.state.superCategories
                                              }
                                              name="firstCategory"
                                              onChange={(val) =>
                                                this.firstCategoryDropChangeHandler(
                                                  val
                                                )
                                              }
                                            />
                                            <p class="error-msg">
                                              {firstCategoryError}
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                      <div class="row">
                                        <div class="col-12 col-lg-6 base-product-d">
                                          <label className="b-label">
                                            Sub Category1
                                          </label>
                                          <div class="form-label-group position-relative has-icon-left">
                                            <Select
                                              value={this.state.categories.filter(
                                                (option) =>
                                                  option.value ===
                                                  this.state.secondCategory
                                              )}
                                              theme={(theme) => ({
                                                ...theme,
                                                borderRadius: "5px",
                                                border: "0px solid",
                                                colors: {
                                                  ...theme.colors,
                                                  text: "orangered",
                                                  primary25: "#9cf59c",
                                                  primary: "#47A547",
                                                },
                                              })}
                                              options={this.state.categories}
                                              name="condition"
                                              onChange={(val) =>
                                                this.secondCategoryDropChangeHandler(
                                                  val
                                                )
                                              }
                                            />
                                            <p class="error-msg">
                                              {secondCategoryError}
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                      <div class="row">
                                        <div class="col-12 col-lg-6 base-product-d">
                                          <label className="b-label">
                                            Sub Category2
                                          </label>
                                          <div class="form-label-group position-relative has-icon-left">
                                            <Select
                                              value={this.state.subCategories.filter(
                                                (option) =>
                                                  option.value ===
                                                  this.state.thirdCategory
                                              )}
                                              theme={(theme) => ({
                                                ...theme,
                                                borderRadius: "5px",
                                                border: "0px solid",
                                                colors: {
                                                  ...theme.colors,
                                                  text: "orangered",
                                                  primary25: "#9cf59c",
                                                  primary: "#47A547",
                                                },
                                              })}
                                              options={this.state.subCategories}
                                              name="brand"
                                              onChange={(val) =>
                                                this.thirdCategoryDropChangeHandler(
                                                  val
                                                )
                                              }
                                            />
                                            <p class="error-msg">
                                              {thirdCategoryError}
                                            </p>

                                            {/* <p class="error-msg">{nameError}</p> */}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div class="col-md-5">
                                      <div class="row-lists mb-2">
                                        {this.state.imageArray.map(
                                          (image, index) =>
                                           { 
                                            {/* console.log(image , "change") */}
                                            return(
                                              <>
                                              {image.productImage ? (
                                              <div class="image-small-view">
                                                <div class="image-inp-div">
                                                  <label
                                                    class="cursor-pointer trigger-div"
                                                    for={index}
                                                    style={{
                                                      background:
                                                        "url(" +
                                                        `${
                                                          image.productImage
                                                            ? image.productImage
                                                            : "upload"
                                                        }` +
                                                        ")",
                                                    }}
                                                  ></label>
                                                  <input
                                                    type="file"
                                                    id={index}
                                                    name="imageUpload"
                                                    onChange={(e) =>
                                                      this.onImageChange(
                                                        e,
                                                        index
                                                      )
                                                    }
                                                    hidden
                                                  />
                                                  <i
                                                    class="fa fa-trash del-img-icon"
                                                    onClick={() => 
                                                      this.removeImage(index)
                                                    }
                                                  ></i>
                                                 
                                                </div>
                                              </div>
                                            ) : (
                                              <EditCropSelectedImage
                                                parentCallback={(data) => {
                                                  let productImage = data.base64Image
                                                  this.setState({
                                                    imageArray: [
                                                     ...this.state.imageArray,
                                                     {
                                                      productImage: productImage,
                                                      sendImage:''
                                                     }
                                                    ],
                                                  });
                                                }}
                                              />
                                            )}
                                              </>
                                            )}
                                            
                                        )}

                                        <div class="image-small-view">
                                          <button
                                            class="trigger-div-btn"
                                            onClick={() => this.addNewImage()}
                                          >
                                            <i class="fa fa-plus" /> Add more
                                            image
                                          </button>
                                        </div>
                                        <div class="col-12">
                                          <p class="error-msg">{imageError}</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <div class="col-11 d-flex flex-sm-row flex-column justify-content-end">
                                    <button
                                      class="btn btn-primary-green p-1 "
                                      type="button"
                                      onClick={() => this.firstSubmitHandler()}
                                    >
                                      Next
                                    </button>
                                  </div>
                                </section>
                              </div>
                            </div>
                          </div>
                        </TabPane>
                        <TabPane tabId="2">
                          <div class="col-md-12 col-12 ">
                            <div class="card">
                              <div class="card-header p-header-0">
                                <div class="card-title mb-2">
                                  Product Pricing
                                  {this.state.priceOn === "1" ? (
                                    <>
                                      <i
                                        class="fa fa-toggle-on price-tog"
                                        aria-hidden="true"
                                        onClick={() =>
                                          this.priceOnOffHandler("0")
                                        }
                                      ></i>{" "}
                                      on
                                    </>
                                  ) : (
                                    <>
                                      <i
                                        class="fa fa-toggle-off price-tog"
                                        aria-hidden="true"
                                        onClick={() =>
                                          this.priceOnOffHandler("1")
                                        }
                                      ></i>
                                      off
                                    </>
                                  )}
                                </div>
                              </div>
                              <div class="">
                                <section class="page-users-view">
                                  <div class="row">
                                    <div class="col-lg-8 col-md-8 col-12">
                                      {this.state.priceOn === "0" ? (
                                        <div class="row ">
                                          <div class="col-12 col-lg-6 base-product-d">
                                            <label className="b-label">
                                              Default Price
                                              <span class="required-span">
                                                *
                                              </span>
                                            </label>

                                            <div class="form-label-group position-relative has-icon-left">
                                              <input
                                                type="text"
                                                id="first-name-floating-icon"
                                                class="form-control"
                                                name="price"
                                                value={this.state.price}
                                                onChange={this.handleChange}
                                                placeholder="Default Price"
                                              />
                                              <div class="form-control-position">
                                                <i class="feather icon-package"></i>
                                              </div>
                                              <p class="error-msg">
                                                {priceError}
                                              </p>
                                            </div>
                                          </div>
                                          <div class="col-12 col-lg-6 base-product-d">
                                            <label className="b-label">
                                              MSRP
                                            </label>
                                            <div class="form-label-group position-relative has-icon-left">
                                              <input
                                                type="text"
                                                id="first-name-floating-icon"
                                                class="form-control"
                                                name="msrp"
                                                value={this.state.msrp}
                                                onChange={this.handleChange}
                                                placeholder="MSRP"
                                              />
                                              <div class="form-control-position">
                                                <i class="feather icon-package"></i>
                                              </div>
                                              <p class="error-msg">
                                                {msrpError}
                                              </p>
                                            </div>
                                          </div>
                                        </div>
                                      ) : null}
                                      <div class="row">
                                        <div
                                          class="card-title mt-1 mb-1"
                                          style={{ paddingLeft: "16px" }}
                                        >
                                          Variation
                                        </div>
                                      </div>
                                      <div class="row">
                                        <div class="col-xl-4 col-md-3 add-field">
                                          <label className="b-label mb-1">
                                            Variation Type
                                            <span class="required-span">*</span>
                                          </label>
                                        </div>
                                        <div
                                          class="col-md-6 "
                                          style={{ marginTop: "9px" }}
                                        >
                                          <ul class="list-unstyled mb-0">
                                            <li class="d-inline-block mr-2">
                                              <fieldset>
                                                <div class="vs-radio-con">
                                                  <input
                                                    type="radio"
                                                    name="variationType"
                                                    value="color"
                                                    onChange={
                                                      this
                                                        .variationTypeDropChangeHandler
                                                    }
                                                    checked={
                                                      this.state
                                                        .variationType ===
                                                      "color"
                                                        ? true
                                                        : false
                                                    }
                                                  />
                                                  <span class="vs-radio">
                                                    <span class="vs-radio--border"></span>
                                                    <span class="vs-radio--circle"></span>
                                                  </span>
                                                  Color Size
                                                </div>
                                              </fieldset>
                                            </li>
                                            <li class="d-inline-block mr-2">
                                              <fieldset>
                                                <div class="vs-radio-con">
                                                  <input
                                                    type="radio"
                                                    name="variationType"
                                                    value="normal"
                                                    onChange={
                                                      this
                                                        .variationTypeDropChangeHandler
                                                    }
                                                    checked={
                                                      this.state
                                                        .variationType ===
                                                      "normal"
                                                        ? true
                                                        : false
                                                    }
                                                  />
                                                  <span class="vs-radio">
                                                    <span class="vs-radio--border"></span>
                                                    <span class="vs-radio--circle"></span>
                                                  </span>
                                                  Normal
                                                </div>
                                              </fieldset>
                                            </li>
                                          </ul>
                                          <p class="p-error">
                                            {" "}
                                            {variationTypeError}
                                          </p>
                                        </div>
                                      </div>
                                      {this.state.variationType === "normal" ? (
                                        <>
                                          {this.state.variation.map(
                                            (data, index) => (
                                              <div class="row">
                                                <div class="col-lg-3 col-6">
                                                  <div class="form-group">
                                                    <label for="first-name-floating-icon">
                                                      Name
                                                      <span class="error-msg">
                                                        *
                                                      </span>
                                                    </label>
                                                    <input
                                                      type="text"
                                                      class="form-control"
                                                      placeholder="Name"
                                                      value={data.name}
                                                      onChange={(e) => {
                                                        this.handleNameChange(
                                                          e,
                                                          index
                                                        );
                                                      }}
                                                      onBlur={(e) => {
                                                        this.checkName(index);
                                                      }}
                                                      maxLength={30}
                                                    />
                                                  </div>
                                                </div>
                                                <div class="col-lg-3 col-6">
                                                  <div class="form-group">
                                                    <label for="first-name-floating-icon">
                                                      Value
                                                      <span class="error-msg">
                                                        *
                                                      </span>
                                                    </label>
                                                    <input
                                                      type="text"
                                                      class="form-control"
                                                      placeholder="value"
                                                      value={data.value}
                                                      onChange={(e) => {
                                                        this.handleValueChange(
                                                          e,
                                                          index
                                                        );
                                                      }}
                                                      onBlur={(e) => {
                                                        this.checkValue(index);
                                                      }}
                                                      maxLength={25}
                                                    />
                                                  </div>
                                                </div>
                                                <div class="ccol-lg-3 col-6">
                                                  <div class="form-group">
                                                    <label for="first-name-floating-icon">
                                                      Quantity
                                                      <span class="error-msg">
                                                        *
                                                      </span>
                                                    </label>
                                                    <input
                                                      type="text"
                                                      class="form-control"
                                                      placeholder="Quantity"
                                                      value={data.qty}
                                                      onChange={(e) => {
                                                        this.handleQtyChange(
                                                          e,
                                                          index
                                                        );
                                                      }}
                                                      onBlur={(e) => {
                                                        this.checkQty(index);
                                                      }}
                                                      maxLength={10}
                                                    />
                                                  </div>
                                                </div>
                                                <div class="col-lg-3 col-6">
                                                  {this.state.priceOn ==
                                                    "1" && (
                                                    <div class="form-group">
                                                      <label for="first-name-floating-icon">
                                                        Price
                                                      </label>
                                                      <input
                                                        type="text"
                                                        class="form-control"
                                                        placeholder="Price"
                                                        value={data.price}
                                                        onChange={(e) => {
                                                          this.handlePriceChange(
                                                            e,
                                                            index
                                                          );
                                                        }}
                                                        onBlur={(e) => {
                                                          this.checkCustomPrice(
                                                            index
                                                          );
                                                        }}
                                                        maxLength={10}
                                                      />
                                                    </div>
                                                  )}
                                                </div>
                                              </div>
                                            )
                                          )}
                                          <p class="error-msg">
                                            {variationError}
                                          </p>
                                          <div>
                                            <button
                                              class="btn btn-primary-green p-1 add-size mb-2"
                                              type="button"
                                              onClick={() =>
                                                this.appendVariation()
                                              }
                                            >
                                              <i class="fa fa-plus" /> Variation
                                            </button>
                                          </div>
                                        </>
                                      ) : this.state.variationType ===
                                        "color" ? (
                                        <>
                                          {this.state.colorSize.map(
                                            (data, index) => (
                                              <div class="row">
                                                <div class="col-lg-3 col-6 d-flex">
                                                  <label for="first-name-floating-icon mr-8px">
                                                    Color
                                                    <span class="error-msg">
                                                      *
                                                    </span>
                                                  </label>

                                                  <div class="form-group">
                                                    <InputColor
                                                      initialValue={data.color}
                                                      placement="right"
                                                      value={
                                                        this.state.colorSize[
                                                          index
                                                        ].color
                                                      }
                                                      onChange={(e) =>
                                                        this.handleColorChange(
                                                          e,
                                                          index
                                                        )
                                                      }
                                                    />
                                                  </div>
                                                </div>
                                                <div class="col-lg-3 col-6">
                                                  <div class="form-group">
                                                    <label for="first-name-floating-icon">
                                                      Size
                                                      <span class="error-msg">
                                                        *
                                                      </span>
                                                    </label>
                                                    <input
                                                      type="text"
                                                      class="form-control"
                                                      placeholder="size"
                                                      value={data.size}
                                                      onChange={(e) => {
                                                        this.handleSizeChange(
                                                          e,
                                                          index
                                                        );
                                                      }}
                                                      onBlur={(e) => {
                                                        this.checkSize(index);
                                                      }}
                                                      maxLength={20}
                                                    />
                                                  </div>
                                                </div>
                                                <div class="col-lg-3 col-6">
                                                  <div class="form-group">
                                                    <label for="first-name-floating-icon">
                                                      Quantity
                                                      <span class="error-msg">
                                                        *
                                                      </span>
                                                    </label>
                                                    <input
                                                      type="text"
                                                      class="form-control"
                                                      placeholder="Quantity"
                                                      value={data.qty}
                                                      onChange={(e) => {
                                                        this.handleCSQtyChange(
                                                          e,
                                                          index
                                                        );
                                                      }}
                                                      onBlur={(e) => {
                                                        this.checkCSQty(index);
                                                      }}
                                                      maxLength={10}
                                                    />
                                                  </div>
                                                </div>
                                                <div class="col-lg-3 col-6">
                                                  {this.state.priceOn ==
                                                    "1" && (
                                                    <div class="form-group">
                                                      <label for="first-name-floating-icon">
                                                        Price
                                                      </label>
                                                      <input
                                                        type="text"
                                                        class="form-control"
                                                        placeholder="Price"
                                                        value={data.price}
                                                        onChange={(e) => {
                                                          this.handleCSPriceChange(
                                                            e,
                                                            index
                                                          );
                                                        }}
                                                        onBlur={(e) => {
                                                          this.checkCSCustomPrice(
                                                            index
                                                          );
                                                        }}
                                                        maxLength={10}
                                                      />
                                                    </div>
                                                  )}
                                                </div>
                                              </div>
                                            )
                                          )}
                                          <p class="error-msg">
                                            {colorSizeError}
                                          </p>

                                          <div>
                                            <button
                                              class="btn btn-primary-green p-1 add-size mb-2"
                                              type="button"
                                              onClick={() =>
                                                this.appendColorSize()
                                              }
                                            >
                                              <i class="fa fa-plus" /> Variation
                                            </button>
                                          </div>
                                        </>
                                      ) : null}

                                      <div class="row">
                                        <div class="col-12 col-lg-6 base-product-d">
                                          <label className="b-label">
                                            Discount Type
                                          </label>
                                          <div class="form-label-group position-relative has-icon-left">
                                            <Select
                                              value={this.state.discountTypes.filter(
                                                (option) =>
                                                  option.value ===
                                                  this.state.discountType
                                              )}
                                              theme={(theme) => ({
                                                ...theme,
                                                borderRadius: "5px",
                                                border: "0px solid",
                                                colors: {
                                                  ...theme.colors,
                                                  text: "orangered",
                                                  primary25: "#9cf59c",
                                                  primary: "#47A547",
                                                },
                                              })}
                                              options={this.state.discountTypes}
                                              name="brand"
                                              onChange={(val) =>
                                                this.typeDropChangeHandler(val)
                                              }
                                            />
                                            <p class="error-msg">
                                              {discountTypeError}
                                            </p>
                                          </div>
                                        </div>
                                        <div class="col-12 col-lg-6 base-product-d">
                                          <label className="b-label">
                                            Discount
                                          </label>
                                          <div class="form-label-group ">
                                            <input
                                              type="text"
                                              class="form-control"
                                              placeholder="Discount"
                                              name="discount"
                                              value={this.state.discount}
                                              onChange={this.handleChange}
                                              aria-label="Amount"
                                            />
                                            {/* <p class="error-msg">{nameError}</p> */}
                                          </div>
                                        </div>
                                      </div>
                                      <div class="row ">
                                        <div class="col-12 col-lg-6 base-product-d">
                                          <label className="b-label">
                                            Sales Tax
                                          </label>{" "}
                                          (Country)
                                          <div class="form-label-group ">
                                            <Select
                                              value={this.state.countries.filter(
                                                (option) =>
                                                  option.value ===
                                                  this.state.country
                                              )}
                                              theme={(theme) => ({
                                                ...theme,
                                                borderRadius: "5px",
                                                border: "0px solid",
                                                colors: {
                                                  ...theme.colors,
                                                  text: "orangered",
                                                  primary25: "#9cf59c",
                                                  primary: "#47A547",
                                                },
                                              })}
                                              options={this.state.countries}
                                              name="brand"
                                              onChange={(val) =>
                                                this.countryDropChangeHandler(
                                                  val
                                                )
                                              }
                                            />
                                            {/* <p class="error-msg">{nameError}</p> */}
                                          </div>
                                        </div>
                                        <div class="col-12 col-lg-6 base-product-d">
                                          <label className="b-label">
                                            Sales Tax
                                          </label>
                                          (Amount)
                                          <div class="form-label-group position-relative has-icon-right">
                                            <input
                                              type="text"
                                              class="form-control"
                                              placeholder="taxAmount"
                                              name="taxAmount"
                                              value={this.state.taxAmount}
                                              onChange={this.handleChange}
                                              aria-label="Amount"
                                            />
                                            <div class="form-control-position">
                                              %
                                            </div>
                                            <p class="error-msg">
                                              {taxAmountError}
                                            </p>
                                          </div>
                                        </div>
                                      </div>

                                      <div class="row">
                                        <div class="col-12  base-product-d">
                                          <label className="b-label">
                                            Tags
                                            {/* <span class="required-span">*</span> */}
                                          </label>
                                          <div class="form-label-group">
                                            <textarea
                                              type="text"
                                              class="form-control"
                                              placeholder="Tags"
                                              name="tag"
                                              value={this.state.tag}
                                              onChange={this.handleChange}
                                              aria-label="Amount"
                                            />
                                            {/* <div class="form-control-position">
                                              <i class="feather icon-package"></i>
                                            </div> */}
                                            {/* <p class="error-msg">{nameError}</p> */}
                                          </div>
                                        </div>
                                      </div>

                                      <div class="row">
                                        {this.state.descriptions.map(
                                          (description, index) => (
                                            <div class="col-12 ">
                                              <label className="b-label">
                                                Description
                                                {index === 0 ? (
                                                  <span class="required-span">
                                                    *
                                                  </span>
                                                ) : null}
                                              </label>
                                              <CKEditor
                                                activeClass="p10"
                                                content={
                                                  this.state.descriptions[index]
                                                }
                                                events={{
                                                  change: (e) =>
                                                    this.onDescriptionChange(
                                                      e,
                                                      index
                                                    ),
                                                }}
                                              />
                                            </div>
                                          )
                                        )}
                                        <div class="col-12">
                                          <p class="error-msg">
                                            {descriptionError}
                                          </p>
                                          <button
                                            class="btn btn-primary-green p-1 res-width-centers add-size res-btn-mb"
                                            type="button"
                                            onClick={() =>
                                              this.appendDescription()
                                            }
                                            style={{ marginTop: "17px" }}
                                          >
                                            <i class="fa fa-plus" /> Description
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="col-lg-11 col-12 d-flex flex-sm-row flex-column justify-content-end">
                                    <button
                                      class="btn btn-primary-green p-1 res-margins-sa res-btn-mb "
                                      type="button"
                                      onClick={() =>
                                        this.setState({ activeTab: "1" })
                                      }
                                    >
                                      Previous
                                    </button>
                                    <button
                                      class="btn btn-primary-green res-btn-mb p-1 "
                                      type="button"
                                      onClick={() =>
                                        this.variationCheckHandler()
                                      }
                                    >
                                      Next
                                    </button>
                                  </div>
                                </section>
                              </div>
                            </div>
                          </div>
                        </TabPane>
                        <TabPane tabId="3">
                          <div class="col-md-12 col-12 ">
                            <div class="card">
                              <div class="card-header p-header-0">
                                <div class="card-title mb-2">
                                  Product Shipping
                                </div>
                              </div>
                              <div class="">
                                <section class="page-users-view">
                                  <div class="row">
                                    <div class="col-12">
                                      <div class="row ">
                                        <div class="col-12 col-lg-4 base-product-d">
                                          <label className="b-label">
                                            Shipping
                                            <span class="required-span">*</span>
                                          </label>
                                          <div class="form-label-group position-relative has-icon-left">
                                            <Select
                                              value={this.state.shippings.filter(
                                                (option) =>
                                                  option.value ===
                                                  this.state.shippingId
                                              )}
                                              theme={(theme) => ({
                                                ...theme,
                                                borderRadius: "5px",
                                                border: "0px solid",
                                                colors: {
                                                  ...theme.colors,
                                                  text: "orangered",
                                                  primary25: "#9cf59c",
                                                  primary: "#47A547",
                                                },
                                              })}
                                              options={this.state.shippings}
                                              name="brand"
                                              onChange={(val) =>
                                                this.shippingChangeHandler(val)
                                              }
                                            />
                                            {/* <p class="error-msg">{nameError}</p> */}
                                          </div>
                                        </div>

                                        {this.state.shippingId === "1" ? (
                                          <div class="col-12 col-lg-4 base-product-d">
                                            <label className="b-label">
                                              Shipping Price
                                              <span class="required-span">
                                                *
                                              </span>
                                            </label>

                                            <div class="form-label-group position-relative has-icon-left">
                                              <input
                                                type="text"
                                                id="first-name-floating-icon"
                                                class="form-control"
                                                name="shippingPrice"
                                                value={this.state.shippingPrice}
                                                onChange={this.handleChange}
                                                placeholder="Shipping Price"
                                              />
                                              <div class="form-control-position">
                                                <i class="fa fa-usd"></i>
                                              </div>
                                              {/* <p class="error-msg">{nameError}</p> */}
                                            </div>
                                          </div>
                                        ) : this.state.shippingId == "2" ? (
                                          <>
                                            <div class="col-12 col-lg-4 base-product-d">
                                              <label className="b-label">
                                                Shipping Within City
                                                <span class="required-span">
                                                  *
                                                </span>
                                              </label>

                                              <div class="form-label-group position-relative has-icon-left">
                                                <input
                                                  type="text"
                                                  id="first-name-floating-icon"
                                                  class="form-control"
                                                  name="shippingPrice"
                                                  value={
                                                    this.state.shippingPrice
                                                  }
                                                  onChange={this.handleChange}
                                                  placeholder="Shipping Price Within City"
                                                />
                                                <div class="form-control-position">
                                                  <i class="fa fa-usd"></i>
                                                </div>
                                                {/* <p class="error-msg">{nameError}</p> */}
                                              </div>
                                            </div>
                                            <div class="col-12 col-lg-4 base-product-d">
                                              <label className="b-label">
                                                Shipping Price Out Of City
                                                <span class="required-span">
                                                  *
                                                </span>
                                              </label>

                                              <div class="form-label-group position-relative has-icon-left">
                                                <input
                                                  type="text"
                                                  id="first-name-floating-icon"
                                                  class="form-control"
                                                  name="shippingPrice"
                                                  value={
                                                    this.state.shippingPrice
                                                  }
                                                  onChange={this.handleChange}
                                                  placeholder="Shipping Price Out Of City"
                                                />
                                                <div class="form-control-position">
                                                  <i class="fa fa-usd"></i>
                                                </div>
                                                {/* <p class="error-msg">{nameError}</p> */}
                                              </div>
                                            </div>
                                          </>
                                        ) : null}
                                      </div>
                                      <div class="row">
                                        <div class="col-12 col-lg-4 base-product-d">
                                          <label className="b-label">
                                            Processing time
                                            <span class="required-span">*</span>
                                          </label>
                                          <div class="form-label-group position-relative has-icon-left">
                                            <div class="">
                                              <Select
                                                value={this.state.ProcessTiming.filter(
                                                  (option) =>
                                                    option.value ===
                                                    this.state.brand
                                                )}
                                                theme={(theme) => ({
                                                  ...theme,
                                                  borderRadius: "5px",
                                                  border: "0px solid",
                                                  colors: {
                                                    ...theme.colors,
                                                    text: "orangered",
                                                    primary25: "#9cf59c",
                                                    primary: "#47A547",
                                                  },
                                                })}
                                                options={
                                                  this.state.ProcessTiming
                                                }
                                                name="brand"
                                                onChange={(val) =>
                                                  this.brandDropChangeHandler(
                                                    val
                                                  )
                                                }
                                              />
                                              <p class="error-msg">{} </p>
                                            </div>
                                            {/* <p class="error-msg">{nameError}</p> */}
                                          </div>
                                        </div>
                                        <div class="col-12 col-lg-4 base-product-d">
                                          <label className="b-label">
                                            Zip Code
                                            <span class="required-span">*</span>
                                          </label>
                                          <div
                                            class="form-label-group"
                                            style={{ height: "42px" }}
                                          >
                                            <input
                                              type="text"
                                              class="form-control"
                                              placeholder="Zip Code"
                                              name="ZipCode"
                                              value={this.state.ZipCode}
                                              onChange={this.handleChange}
                                              aria-label="Amount"
                                            />
                                            {/* <p class="error-msg">{nameError}</p> */}
                                          </div>
                                        </div>
                                      </div>
                                      {/* <label className="b-label">
                                        Offer Local PickUp And Each Additional
                                        </label>
                                      <div class="row">
                                        <div class="col-12 col-lg-4 base-product-d">
                                          <div class="form-label-group position-relative has-icon-left">
                                            <input
                                              type="text"
                                              id="first-name-floating-icon"
                                              class="form-control"
                                              name="PickUpCost"
                                              value={this.state.PickUpCost}
                                              onChange={this.handleChange}
                                              placeholder="Cost"
                                            />
                                            <div class="form-control-position">
                                              <i class="fa fa-usd"></i>
                                            </div>
                                          
                                          </div>
                                        </div>

                                        <div class="col-12 col-lg-4 base-product-d">
                                          <div class="form-label-group position-relative has-icon-left">
                                            <input
                                              type="text"
                                              id="first-name-floating-icon"
                                              class="form-control"
                                              name="PickUpAdditional"
                                              value={this.state.PickUpAdditional}
                                              onChange={this.handleChange}
                                              placeholder="Each Additional"
                                            />
                                            <div class="form-control-position">
                                              <i class="fa fa-usd"></i>
                                            </div>
                                            
                                          </div>
                                        </div>
                                      </div>
                                       */}
                                      {/* <div class="row ">
                                        <div class="col-12 col-lg-8 base-product-d">
                                          <label className="b-label">
                                            Item Location
                                              <span class="required-span">
                                              *
                                              </span>
                                          </label>
                                          <div class="form-label-group position-relative has-icon-left">
                                            <textarea
                                              type="text"
                                              class="form-control"
                                              placeholder="Shipping Location"
                                              name="ItemLocation"
                                              value={this.state.ItemLocation}
                                              onChange={this.handleChange}
                                              aria-label="Amount"
                                              row={4}
                                            />
                                            <div class="form-control-position">
                                              <i class="feather icon-package"></i>
                                            </div>
                                            
                                          </div>
                                        </div>
                                      </div> */}
                                      <div class="row">
                                        <div class="col-12 col-lg-4 base-product-d">
                                          <label className="b-label">
                                            Shipping Carrier
                                            <span class="required-span">*</span>
                                          </label>
                                          <div class="form-label-group position-relative has-icon-left">
                                            <Select
                                              value={this.state.ShippingCarriers.filter(
                                                (option) =>
                                                  option.value ===
                                                  this.state.shippingcarrier
                                              )}
                                              theme={(theme) => ({
                                                ...theme,
                                                borderRadius: "5px",
                                                border: "0px solid",
                                                colors: {
                                                  ...theme.colors,
                                                  text: "orangered",
                                                  primary25: "#9cf59c",
                                                  primary: "#47A547",
                                                },
                                              })}
                                              options={
                                                this.state.ShippingCarriers
                                              }
                                              name="shippingCarrier"
                                              onChange={(val) =>
                                                this.ShippingCarrierDropChangeHandler(
                                                  val
                                                )
                                              }
                                            />

                                            <p class="error-msg">{nameError}</p>
                                          </div>
                                        </div>
                                        <div class="col-12 col-lg-4 base-product-d">
                                          <label className="b-label">
                                            Mail Class
                                            <span class="required-span">*</span>
                                          </label>
                                          <div class="form-label-group position-relative has-icon-left">
                                            <Select
                                              value={this.state.mailclasses.filter(
                                                (option) =>
                                                  option.value ===
                                                  this.state.mailclass
                                              )}
                                              theme={(theme) => ({
                                                ...theme,
                                                borderRadius: "5px",
                                                border: "0px solid",
                                                colors: {
                                                  ...theme.colors,
                                                  text: "orangered",
                                                  primary25: "#9cf59c",
                                                  primary: "#47A547",
                                                },
                                              })}
                                              options={this.state.mailclasses}
                                              name="condition"
                                              onChange={(val) =>
                                                this.MailClassDropChangeHandler(
                                                  val
                                                )
                                              }
                                            />
                                            {/* <p class="error-msg">{nameError}</p> */}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </section>
                              </div>
                              <div class="card-header p-header-0">
                                <div class="card-title mb-2">
                                  Weight And Dimensions
                                </div>
                              </div>
                              <div class="">
                                <section class="page-users-view">
                                  <div class="row">
                                    <div class="col-12">
                                      <div class="row ">
                                        <div class="col-12 col-lg-4 base-product-d">
                                          <label className="b-label">
                                            Item Weight
                                            {/* <span class="required-span">
                                              *
                                              </span> */}
                                          </label>

                                          <div class="form-label-group position-relative has-icon-right">
                                            <input
                                              type="text"
                                              id="first-name-floating-icon"
                                              class="form-control"
                                              name="ItemWeight_LB"
                                              value={this.state.ItemWeight_LB}
                                              onChange={this.handleChange}
                                              placeholder="Weight"
                                            />
                                            <div class="form-control-position">
                                              lb
                                            </div>
                                            <p class="error-msg">
                                              {this.state.ItemWeight_LB_Error}
                                            </p>
                                          </div>
                                        </div>
                                        <div class="col-12 col-lg-4 base-product-d">
                                          <label className="b-label">
                                            Item Weight
                                            {/* <span class="required-span">
                                              *
                                              </span> */}
                                          </label>

                                          <div class="form-label-group position-relative has-icon-right">
                                            <input
                                              type="text"
                                              id="first-name-floating-icon"
                                              class="form-control"
                                              name="ItemWeight_OZ"
                                              value={this.state.ItemWeight_OZ}
                                              onChange={this.handleChange}
                                              placeholder="Weight"
                                            />
                                            <div class="form-control-position">
                                              oz
                                            </div>
                                            <p class="error-msg">
                                              {this.state.ItemWeight_OZ_Error}
                                            </p>
                                          </div>
                                        </div>
                                        <div class="col-12 col-lg-4 base-product-d">
                                          <label className="b-label">
                                            Packing Type
                                            <span class="required-span">*</span>
                                          </label>
                                          <div class="form-label-group position-relative has-icon-left">
                                            <Select
                                              value={this.state.packingType.filter(
                                                (option) =>
                                                  option.value ===
                                                  this.state.brand
                                              )}
                                              theme={(theme) => ({
                                                ...theme,
                                                borderRadius: "5px",
                                                border: "0px solid",
                                                colors: {
                                                  ...theme.colors,
                                                  text: "orangered",
                                                  primary25: "#9cf59c",
                                                  primary: "#47A547",
                                                },
                                              })}
                                              options={this.state.packingType}
                                              name="brand"
                                              onChange={(val) =>
                                                this.brandDropChangeHandler(val)
                                              }
                                            />
                                            {/* <p class="error-msg">{nameError}</p> */}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="row ">
                                    <div class="col-12 col-lg-4 base-product-d">
                                      <label className="b-label">
                                        Length
                                        <span class="required-span">*</span>
                                      </label>
                                      (inches)
                                      <div class="form-label-group">
                                        <input
                                          type="text"
                                          class="form-control"
                                          placeholder="Length"
                                          name="Length"
                                          value={this.state.Length}
                                          onChange={this.handleChange}
                                          aria-label="Amount"
                                        />
                                        <p class="error-msg">
                                          {this.state.lengthError}
                                        </p>
                                      </div>
                                    </div>
                                    <div class="col-12 col-lg-4 base-product-d">
                                      <label className="b-label">
                                        Width
                                        <span class="required-span">*</span>
                                        (Inches)
                                      </label>
                                      <div class="form-label-group ">
                                        <input
                                          type="text"
                                          class="form-control"
                                          placeholder="Width"
                                          name="Width"
                                          value={this.state.Width}
                                          onChange={this.handleChange}
                                          aria-label="Amount"
                                        />

                                        <p class="error-msg">
                                          {this.state.widthError}
                                        </p>
                                      </div>
                                    </div>
                                    <div class="col-12 col-lg-4 base-product-d">
                                      <label className="b-label">
                                        Height
                                        <span class="required-span">*</span>
                                        (Inches)
                                      </label>
                                      <div class="form-label-group ">
                                        <input
                                          type="text"
                                          class="form-control"
                                          placeholder="Height"
                                          name="Height"
                                          value={this.state.Height}
                                          onChange={this.handleChange}
                                          aria-label="Amount"
                                        />
                                        <p class="error-msg">
                                          {this.state.HeightError}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </section>
                              </div>
                              <div class="col-lg-12 col-12 d-flex flex-sm-row flex-column justify-content-end">
                                <button
                                  class="btn btn-primary-green res-margins-sa res-btn-mb p-1 "
                                  type="button"
                                  onClick={() =>
                                    this.setState({ activeTab: "2" })
                                  }
                                  style={{ marginRight: "13px" }}
                                >
                                  Previous
                                </button>
                                <button
                                  class="btn btn-primary-green p-1 "
                                  type="button"
                                  onClick={() => this.modalToggle()}
                                >
                                  Submit
                                </button>
                              </div>
                            </div>
                          </div>
                        </TabPane>
                      </TabContent>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>

        <div class="sidenav-overlay"></div>
        <div class="drag-target"></div>
        <Footer />
      </body>
    );
  }
}
export default AddProduct;
