import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import './App.css';
// import AddOldProduct from "./Seller/Container/AddProduct/AddProduct";
import ProductList from './Seller/Container/Product/ProductList/ProductList';
// import EditOldProduct from "./Seller/Container/EditProduct/EditProduct";
import SellerDashboard from './Seller/Container/SellerDashboard/Dashboard';
import EditProfile from './Seller/Container/EditProfile/EditProfile';
import SoldProduct from './Seller/Container/SoldProduct/SoldProductList';
import ViewedProduct from './Seller/Container/ViewedProduct/ViewedProduct';
import AddOffer from './Seller/Container/AddOffer/AddOffer';
import EditOffer from './Seller/Container/EditOffer/EditOffer';
import OfferList from './Seller/Container/OfferList/OfferList';
import ViewProduct from './Seller/Container/ViewProduct/ViewProduct';
import OrderList from './Seller/Container/OrderList/OrderList';
import ViewOrder from './Seller/Container/ViewOrder/ViewOrder';
import ViewLable from './Seller/Container/ViewOrder/ViewLable';
import FollowList from './Seller/Container/FollowUserList/FollowList';
import Subscription from './Seller/Container/Subscription/Subscription';
import FollowerList from './Seller/Container/FollowerList/FollowerList';
import NotFound404 from './Seller/404/404';
import SellerForm from './Seller/Container/SellerForum/SellerForum';
import Downlode from './Seller/Container/OrderList/downlode';
import AddFAQ from './Seller/Container/AddFAQ/AddFAQ';

import BusinessInformation from './Seller/Container/TaxModel/Information';
import Address from './Seller/Container/TaxModel/Address';
import PaymentMethod from './Seller/Container/TaxModel/PaymentMethod';
import TaxForm from './Seller/Container/TaxModel/TaxForm';
import Forum from './Seller/Container/Forum/Forum';
import AddForum from './Seller/Container/Forum/AddFoum';
import EditForum from './Seller/Container/Forum/EditForum';

import AddBanner from './Seller/Container/AddBanner/AddBanner';
import EditBanner from './Seller/Container/AddBanner/EditBanner';
import AddBannerRetailer from './Seller/Container/AddBanner/AddBannerRetailer';
import EditBannerRetailer from './Seller/Container/AddBanner/EditBannerRetailer';
import EditCategoryBanner from './Seller/Container/AddBanner/EditCategoryBanner';
import AddCategoryBannerRetailer from './Seller/Container/AddBanner/AddCategoryBanner';
import AddBannerProduct from './Seller/Container/AddBanner/AddBannerProduct';
import AddPromote from './Seller/Container/AddBanner/Addpromoteadd';
import EditPromote from './Seller/Container/AddBanner/editrowbanner';
import EditBannerProduct from './Seller/Container/AddBanner/EditBannerProduct';
import Questionansewerlist from './Seller/Container/QuestionAnswer/Questionanswer';
import Treackorder from './Seller/Container/ViewOrder/Treckingpage';
import ViewBanner from './Seller/Container/AddBanner/ViewBanner';
import BannerList from './Seller/Container/AddBanner/BannerList';
import AddPayperClickBanner from './Seller/Container/PayPerClick/AddBanner';
import PayperClickBannerList from './Seller/Container/PayPerClick/BannerList';
import AddVideo from './Seller/Container/VideoAd/AddVideo';
import AdsList from './Seller/Container/VideoAd/AdsList';
import ViewAds from './Seller/Container/VideoAd/ViewAds';
import BaseRouter from './BaseRouter';
import AbdCartList from './Seller/Container/AbandonedCart/List';
import AddCoupon from './Seller/Container/AddCoupon/AddCoupon';
// import Shpping from "./Seller/Container/AddProduct/Shipping"
import AddProduct from './Seller/Container/Product/AddProduct';
import EditProduct from './Seller/Container/Product/EditProduct';
import SellerWallet from './Seller/Container/SellerWallet/Wallet';
import ContactUs from './Seller/Container/Setting/ContactUs';
import AboutUs from './Seller/Container/Setting/AboutUs';
import TermCondition from './Seller/Container/Setting/TermAndCondition';
import DataPolicy from './Seller/Container/Setting/Policy';
import TransactionHistory from './Seller/Container/TransactionHistory/History';
import PaymentPayout from './Seller/Container/PaymentPayout/PaymentPayout';
import ContactList from './Seller/Container/ContactList/ContactList';
import Discount from './Seller/Container/Discount/Discount';
import Reviews from './Seller/Container/Review/Review';

import Chat from './Seller/Container/Chat/index';
import ContectBuyer from './Seller/Container/Chat/ContectBuyer';

import ChatAdmin from './Seller/Container/Chat/ChatAdmin';
import SalesReport from './Seller/Container/SalesReport/SalesReport';
import LiveStream from './Seller/Container/LiveStream/LiveStream';

import AddProductFreelisting from './Seller/Container/Product/AddProductFreelisting';
import EditProductFreelisting from './Seller/Container/Product/EditProductFreelisting';

import ProductFreelistingList from './Seller/Container/Product/ProductList/ProductFreelistingList';

import AllTransactionHistory from './Seller/Container/AllTransactionHistory/AllTransactionHistory';
import SellerProfile from './Seller/Container/SellerProfile/SellerProfile';

import ReturnExchange from './Seller/Container/ReturnExchange/ReturnExchangeList';
import ViewedProductList from './Seller/Container/viewproductlist/viewlist';
import Editdiscount from './Seller/Container/Discount/Editdiscount';
import BrodcastStreem from './Seller/Container/LiveStream/brodcaststream.js';

const routes = [
  // { path: "/credit", exact: true, component: Credit },

  // { path: "/paypal", exact: true, component: PayPalBtn },
  { path: '/brodcaststreem', exact: true, component: BrodcastStreem },
  { path: '/dashboard', exact: true, component: SellerDashboard },
  { path: '/editprofile', exact: true, component: EditProfile },
  { path: '/userlist', exact: true, component: FollowList },
  { path: '/viewedproduct', exact: true, component: ViewedProductList },
  { path: '/followerlist', exact: true, component: FollowerList }, 
  { path: '/download', exact: true, component: Downlode }, 
  // { path: "/addoldproduct", exact: true, component: AddOldProduct },

  { path: '/addproduct', exact: true, component: AddProduct },
  { path: '/productlist', exact: true, component: ProductList },
  // { path: "/editoldproduct/:id", exact: true, component: EditOldProduct },
  { path: '/editproduct/:id', exact: true, component: EditProduct },
  { path: '/editdiscount/:id', exact: true, component: Editdiscount },

  { path: '/viewproduct/:id', exact: true, component: ViewProduct },

  { path: '/addoffer', exact: true, component: AddOffer },
  { path: '/editoffer/:id', exact: true, component: EditOffer },
  { path: '/offerlist', exact: true, component: OfferList },
  { path: '/questionlist', exact: true, component: Questionansewerlist },
  { path: '/soldproduct', exact: true, component: SoldProduct },
  { path: '/viewedproduct', exact: true, component: ViewedProduct },
  { path: '/subscription', exact: true, component: Subscription },

  { path: '/orderlist', exact: true, component: OrderList },
  { path: '/orderlist/:id', exact: true, component: OrderList },
  { path: '/ordertreak/:id', exact: true, component: Treackorder },

  { path: '/vieworder/:id', exact: true, component: ViewOrder },
  { path: '/viewlabel/', exact: true, component: ViewLable },

  { path: '/sellerforum', exact: true, component: Forum },
  { path: '/addfaq', exact: true, component: AddFAQ },

  { path: '/information', exact: true, component: BusinessInformation },
  { path: '/address', exact: true, component: Address },
  { path: '/paymentmethod', exact: true, component: PaymentMethod },

  { path: '/taxform', exact: true, component: TaxForm },

  { path: '/forum', exact: true, component: Forum },
  { path: '/forum/addForumtopic', exact: true, component: AddForum },
  { path: '/forum/editForumtopic/:id', exact: true, component: EditForum },

  { path: '/addbanner', exact: true, component: AddBanner },
  { path: '/addpromote', exact: true, component: AddPromote },
  { path: '/editpromote/:id', exact: true, component: EditPromote },
  
  { path: '/editbanner/:id', exact: true, component: EditBanner },
  { path: '/addbannerretailer', exact: true, component: AddBannerRetailer },
  { path: '/editbannerretailer/:id', exact: true, component: EditBannerRetailer },
  { path: '/editcategorybanner/:id', exact: true, component: EditCategoryBanner },
  
  {
    path: '/addcategorybannerretailer',
    exact: true,
    component: AddCategoryBannerRetailer,
  },
  { path: '/addbannerproduct', exact: true, component: AddBannerProduct },

  { path: '/editbannerproduct/:id', exact: true, component: EditBannerProduct },

  { path: '/viewbanner/:id', exact: true, component: ViewBanner },
  { path: '/bannerlist', exact: true, component: BannerList },

  { path: '/paymentpayout', exact: true, component: PaymentPayout },

  {
    path: '/allTransactionhistory',
    exact: true,
    component: AllTransactionHistory,
  },

  // { path: "/addproduct", exact: true, component: AddProduct },
  { path: '/contactList', exact: true, component: ContactList },
  // { path: "/editoldproduct/:id", exact: true, component: EditOldProduct },
  // { path: "/editproduct/:id", exact: true, component: EditProduct },
  { path: '/discount', exact: true, component: Discount },

  {
    path: '/addpayperclickbanner',
    exact: true,
    component: AddPayperClickBanner,
  },
  {
    path: '/payperclickbannerlist',
    exact: true,
    component: PayperClickBannerList,
  },
  {
    path: '/addvideo',
    exact: true,
    component: AddVideo,
  },
  {
    path: '/adslist',
    exact: true,
    component: AdsList,
  },
  {
    path: '/viewads/:id',
    exact: true,
    component: ViewAds,
  },
  {
    path: '/adbcartlist',
    exact: true,
    component: AbdCartList,
  },
  {
    path: '/addcoupon',
    exact: true,
    component: AddCoupon,
  },
  {
    path: '/shipping',
    exact: true,
    component: AddCoupon,
  },
  {
    path: '/sellerwallet',
    exact: true,
    component: SellerWallet,
  },
  {
    path: '/contact-us',
    exact: true,
    component: ContactUs,
  },
  {
    path: '/about-us',
    exact: true,
    component: AboutUs,
  },
  {
    path: '/term-and-condition',
    exact: true,
    component: TermCondition,
  },
  {
    path: '/data-policy',
    exact: true,
    component: DataPolicy,
  },
  {
    path: '/transaction-history',
    exact: true,
    component: TransactionHistory,
  },
  {
    path: '/paymentpayout',
    exact: true,
    component: PaymentPayout,
  },
  {
    path: '/chat',
    exact: true,
    component: Chat,
  },
  {
    path: '/contectbuyer/:id/:p_id',
    exact: true,
    component: ContectBuyer,
  },
  {
    path: '/contectbuyer/:id',
    exact: true,
    component: ContectBuyer,
  },
  {
    path: '/chatadmin',
    exact: true,
    component: ChatAdmin,
  },
  {
    path: '/buyer-chat/:id',
    exact: true,
    component: Chat,
  },
  {
    path: '/reviews',
    exact: true,
    component: Reviews,
  },
  {
    path: '/salesReport',
    exact: true,
    component: SalesReport,
  },
  {
    path: '/liveStream',
    exact: true,
    component: LiveStream,
  },
  {
    path: '/addProductFreelisting',
    exact: true,
    component: AddProductFreelisting,
  },
  {
    path: '/editProductFreelisting/:id',
    exact: true,
    component: EditProductFreelisting,
  },
  {
    path: '/productFreelistinglist',
    exact: true,
    component: ProductFreelistingList,
  },
  {
    path: '/sellerProfile',
    exact: true,
    component: SellerProfile,
  },

  {
    path: '/returnexchange',
    exact: true,
    component: ReturnExchange,
  },
];

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      seller: '',
    };
    if (localStorage.getItem('sellerdata')) {
      this.state = {
        seller: JSON.parse(localStorage.getItem('sellerdata')),
      };
    }
    console.log('Render', this.props);
  }
  componentWillMount() {
    if (localStorage.getItem('sellerdata')) {
      this.setState({ seller: JSON.parse(localStorage.getItem('sellerdata')) });
    }
    console.log(localStorage.getItem('sellerdata'));
    console.log('Render', this.props);
  }
  render() {
    return (
      <BaseRouter>
        <Switch>
          <Route path="/dashboard" component={SellerDashboard} exact={true} />
          <Route path="/" component={SellerDashboard} exact={true} />

          {this.state.seller ? (
            routes.map((route, idx) => {
              return route.component ? (
                <Route
                  key={idx}
                  path={route.path}
                  exact={route.exact}
                  render={(props) => <route.component {...props} />}
                />
              ) : null;
            })
          ) : (
            <Redirect from="/" to="/login" />
          )}
          {/* <Redirect from="/" to="/404" /> */}
        </Switch>
      </BaseRouter>
    );
  }
}
export default App;
