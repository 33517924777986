import React from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import moment from "moment";
//import { MDBDataTable } from "mdbreact";
import Footer from "../../Component/Footer/Footer";
import global from "../../global";
import "./style.css";
const $ = require("jquery");
$.DataTable = require("datatables.net");
var sellerData = {};
class AddProduct extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      seller: "",
      banners: []
    };
  }

  componentWillMount() {
    if (localStorage.getItem("sellerdata")) {
      this.setState({ seller: JSON.parse(localStorage.getItem("sellerdata")) });
      sellerData = JSON.parse(localStorage.getItem("sellerdata"));
    }
    this.listAPI();
  }
  listAPI() {
    var urlencoded = new URLSearchParams();
    urlencoded.append("sellerId", sellerData.id);

    axios
      .post(global.baseUrl + "SellerVideoAdsList", urlencoded, {
        headers: { "Content-Type": "application/x-www-form-urlencoded" }
      })
      .then(result => {
        console.log(result.data);
        if (result.data.status) {
          this.setState({
            // tableData: {
            //   rows: result.data.data
            // },
            banners: result.data.data
          });
          this.dataTable();
        }
      });
  }
  dataTable() {
    $(document).ready(function () {
      $("#list").DataTable();
    });
  }
  render() {
    return (
      <body
        class="vertical-layout vertical-menu-modern semi-dark-layout 2-columns  navbar-floating footer-static  "
        data-open="click"
        data-menu="vertical-menu-modern"
        data-col="2-columns"
        data-layout="semi-dark-layout"
      >
        {/* <Header />
        <Sidebar /> */}
        <div class="app-content content">
          <div class="content-overlay"></div>
          <div class="header-navbar-shadow"></div>
          <div class="content-wrapper">
            <div class="content-header row">
              <div class="content-header-left col-md-9 col-12 mb-2">
                <div class="row breadcrumbs-top">
                  <div class="col-12">
                    <h2 class="content-header-title float-left mb-0">
                      Live stream Advertise
                    </h2>
                    <div class="breadcrumb-wrapper col-12">
                      <ol class="breadcrumb">
                        <li class="breadcrumb-item">
                          <Link to="/dashboard">Home</Link>
                        </li>
                        <li class="breadcrumb-item active">Live stream Advertise</li>
                      </ol>
                    </div>
                  </div>
                </div>
              </div>
              <div class="content-header-right text-md-right col-md-3 col-12 d-md-block xs-text-right">
                <div class="form-group breadcrum-right">
                  <Link
                    to="/addvideo"
                    id="addRow"
                    class="btn btn-primary mb-2 add-product-link"
                  >
                    <i class="feather icon-plus"></i>Add Live stream Ads
                  </Link>
                </div>
              </div>
            </div>
            <div class="content-body">
              <section id="add-row">
                <div class="row">
                  <div class="col-12">
                    <div class="card">
                      <div class="card-content">
                        <div class="card-body">
                          {/* <button id="addRow" class="btn btn-primary mb-2"><i class="feather icon-plus"></i>&nbsp; Add new row</button> */}
                          <div class="table-responsive">
                            <table class="table add-rows" id="list">
                              <thead>
                                <tr>
                                  <th>Id</th>
                                  <th>Ads Type</th>
                                  <th>Budget</th>
                                  <th>Start Date</th>
                                  <th>End Date</th>
                                  <th>Type</th>
                                  <th>Category</th>
                                  <th>Status</th>
                                  <th>Action</th>
                                </tr>
                              </thead>
                              <tbody>
                                {this.state.banners.map((banner, index) => (
                                  <tr>
                                    <td style={{ width: "100px" }}>
                                      {index + 1}
                                    </td>
                                    <td style={{ width: "200px" }}>
                                      {banner.ads_type}
                                    </td>
                                    <td style={{ width: "150px" }}>
                                      {banner.budget}
                                    </td>
                                    <td style={{ width: "200px" }}>
                                      {moment(banner.start_date).format(
                                        "DD-MM-YYYY"
                                      )}{" "}
                                    </td>

                                    <td style={{ width: "200px" }}>
                                      {moment(banner.end_date).format(
                                        "DD-MM-YYYY"
                                      )}
                                    </td>
                                    <td>{banner.date_type}</td>
                                    <td className="white-space">
                                      {banner.page_category
                                        ? banner.page_category.name
                                        : null}
                                    </td>
                                    <td>
                                      {banner.paymentStatus === "1" ? (
                                        <b>Paid</b>
                                      ) : null}
                                    </td>
                                    <td style={{ width: "200px" }}>
                                      <Link
                                        to={"/viewads/" + banner.id}
                                        class="btn btn-outline-primary float-left btn-inline res-btn b-pay-btn"
                                      >
                                        Payment
                                      </Link>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
        <div class="sidenav-overlay"></div>
        <div class="drag-target"></div>

        <Footer />
      </body>
    );
  }
}

export default AddProduct;
