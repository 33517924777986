import React from 'react';
import axios from 'axios';
import 'react-image-lightbox/style.css';
import { saveAs } from "file-saver";
import { Link } from 'react-router-dom';

import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
  Button,
} from 'reactstrap';
import Footer from '../../Component/Footer/Footer';
import moment from 'moment';
import global from '../../global';
import { Spinner } from "reactstrap"
import './Product.css';
import ReactToPrint from 'react-to-print';
//var images = [];
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
var order = [];
var orderDetail = '';
var summarydata = [];
let sellerid=""
const CustomToast = () => {
  return (
    <div>
      <i class="fa fa-check success-toast"></i>
      Successfully Cancel Item
    </div>
  );
};
const CustomToast2 = () => {
  return (
    <div>
      <i class="fa fa-check success-toast"></i>
      Cancel Resone Required.
    </div>
  );
};

const CustomToast3 = () => {
  return (
    <div>
      <i class="fa fa-check success-toast"></i>
      Successfully report user.
    </div>
  );
};
const CustomToast4 = () => {
  return (
    <div>
      <i class="fa fa-check success-toast"></i>
      Report description Required.
    </div>
  );
};


class ComponentToPrint extends React.Component {
  render() {
    return (
      <>
        <div id="printdata" >

        {order.map((data, index) => (data.productId.sellerId===sellerid&&
                  <>
                       <table style={{border: "1px solid #000", width: "100%",marginBottom: "20px"}}>

                            <tr>
                              <td colSpan={2}>
                                <img
                              src={
                                data.productId ? data.productId.image[0] : null
                              }
                              alt="..."
                              height="150"
                              width="200"
                              class="product-view-image"
                            />
                              </td>
                            </tr>
                            <tr>
                              <th>
                                Product
                              </th>
                              <td class="product-detail-box1">
                                {data.productId
                                  ? data.productId.product_name
                                  : null}
                              </td>
                            </tr>
                            <tr>
                              <th>
                                Quantity
                              </th>
                              <td class="product-detail-box1">
                                {data.quantity}
                              </td>
                            </tr>
                            <tr>
                              <th>
                                Size
                              </th>
                              <td class="product-detail-box1">{data.size}</td>
                            </tr>
                            <tr>
                              <th>
                                Order Date
                              </th>
                              <td class="product-detail-box1">
                                {moment(data.createdAt).format('DD/MM/YYYY')}
                              </td>
                            </tr>

                            {/* <tr>
                              <td class="font-weight-bold product-detail-box1">
                                Order note
                              </td>
                              <td class="product-detail-box1">
                                {data.Order_add_edit_notes}
                              </td>
                            </tr> */}
                            <tr>
                              <th>
                                Return Treacking Number
                              </th>
                              <td>
                                {data.return_treking_id ? data.return_treking_id : ''}
                              </td>
                            </tr>
                            <tr>
                              <th>
                               Treacking Number
                              </th>
                              <td class="product-detail-box1">
                                {data.treking_id ? data.treking_id : ''}
                              </td>
                            </tr>
                          </table>
                     
                  </>
                ))}


           
                <>
                 
                <table style={{border: "1px solid #000", width: "100%"}}>

                      <tr>
                        <td colSpan={2}>
                          <h4>Other Detail</h4>
                        </td>
                      </tr>
                      <tr>
                        <th>
                          Buyer Name{' '}
                        </th>
                        <td>{order.length ?order[0].userId&& order[0].userId.name : null}</td>
                      </tr>
                      <tr>
                        <th>
                          Shipping Address
                        </th>
                        <td>No address found </td>
                      </tr>
                      <tr>
                        <th>
                          Transaction Id
                        </th>
                        <td>{order.length?order[0].treking_id?order[0].treking_id:'':''}</td>
                      </tr>
                      <tr>
                        <th>
                          Payment
                        </th>
                        <td>paypal</td>
                      </tr>
                      <tr>
                        <th>
                          Coupon Id
                        </th>
                        <td>{order.length ? order[0].couponId : null}</td>
                      </tr>
                    </table>
                  

                 
                   
                 
                </>
        </div>
        <div class="col-md-4">
          <div class="card">
            <div class="card-header">
              <h4 class="card-title">Order Tracking</h4>
            </div>
            <div class="card-content">
              <div class="card-body">
                <div class="form-label-group">
                  <textarea
                    type="text"
                    class="form-control"
                    placeholder="Enter tracking Id"
                    name="orderDetail"
                    value={orderDetail}
                    onChange={()=>this.handleOrderDetailChange()}
                    aria-label="Amount"
                    // onBlur={this.addOrderTrackingDetail}
                  />
                  <ul style={{ marginTop: '5px' }}>
                    <p className="tracking-line">
                      {summarydata.map((data, index) => (
                        <li key={index} style={{ margin: '10px' }}>
                          <p className="tracking-date">
                            {moment(data.date).format('DD/MM/YYYY,h:mm a ')}
                            <br />
                          </p>
                          <strong> {data.value}</strong>
                        </li>
                      ))}
                    </p>
                  
                  </ul>
                  <Link
                      // to={`/ordertreak/${}`}
                      id="addRow"
                      class="btn btn-primary mb-2 add-product-link"
                    >
                      Track
                    </Link>
                  {/* <div class="form-control-position">
                        <i class="feather icon-package"></i>
                      </div> */}
                  {/* <p class="error-msg">{nameError}</p> */}
                </div>
                {/* <ul >
                <div className={this.state.newOrderStatus === "packed" || this.state.orderStatus === "dispatched" || this.state.orderStatus === "delivered" ? "active-track-box" : "order-track-box"}>
                  <div class="timeline-icon bg-primary orderlist-icon">
                    <i class="feather icon-plus font-medium-2"></i>
                  </div>
                  <div>
                    <div class="timeline-info">
                      <p class="font-weight-bold" style={{ margin: "0px" }}>
                        Ordered
                    </p>
                    </div>
                    <small class="">
                      {moment(
                        this.state.order.createdAt
                      ).format("DD/MM/YYYY")}
                    </small>
                  </div>
                </div>

                <div className={this.state.newOrderStatus === "dispatched" || this.state.orderStatus === "delivered" ? "active-track-box" : "order-track-box"}>
                  <div class="timeline-icon bg-warning orderlist-icon">
                    <i class="feather icon-alert-circle font-medium-2"></i>

                  </div>
                  <div>
                    <div class="timeline-info">
                      <p class="font-weight-bold" style={{ margin: "0px" }}>Packed</p>
                    </div>
                    <small class=""> {this.state.packedDate ? moment(
                      this.state.packedDate
                    ).format("DD/MM/YYYY,h:mm a ") : null}</small>

                  </div>
                </div>

                <div className={this.state.newOrderStatus === "delivered" ? "active-track-box" : "order-track-box"}>
                  <div class="timeline-icon bg-warning orderlist-icon">
                    <i class="feather icon-alert-circle font-medium-2"></i>

                  </div>
                  <div>
                    <div class="timeline-info">
                      <p class="font-weight-bold">Dispatched</p>
                    </div>
                    <small class=""> {this.state.dispatchedDate ? moment(
                      this.state.dispatchedDate
                    ).format("DD/MM/YYYY,h:mm a ") : null}</small>

                  </div>
                </div>

                <div className="none-order-track-box">
                  <div class="timeline-icon bg-success orderlist-icon">
                    <i class="feather icon-check font-medium-2"></i>

                  </div>
                  <div>
                    <div class="timeline-info">
                      <p class="font-weight-bold" style={{ margin: "0px" }}>Delivered</p>
                    </div>
                    <small class=""> {this.state.deliveryDate ? moment(
                      this.state.deliveryDate
                    ).format("DD/MM/YYYY,h:mm a ") : null}</small>

                  </div>
                </div>
              </ul>
             */}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

class ViewProduct extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: this.props.match.params.id,
      order: [],
      colorSize: [],
      reviews: [],
      activeTab: '1',
      photoIndex: 0,
      isOpen: false,
      loding:false,
      order_track: {},
      orderStatus: '',
      newOrderStatus: '',
      orderDate: '',
      packedDate: '',
      dispatchedDate: '',
      deliveryDate: '',
      orderId: '',
      a: '',
      reportdescription:'',
      modal: false,
      modal2: false,
      orderDetail: '',
      summary: [],
      product_id:'',
      cancelReason:'',
    };
    this.toggle = this.toggle.bind(this);
    this.toggle2 = this.toggle.bind(this);
  }
  componentDidMount() {
    if (localStorage.getItem('sellerdata')) {
      this.setState({ seller: JSON.parse(localStorage.getItem('sellerdata')) });
      //let sellerData = JSON.parse(localStorage.getItem("sellerdata"));
      sellerid=JSON.parse(localStorage.getItem('sellerdata')).id;
      var urlencoded = new URLSearchParams();
      urlencoded.append('order_Id', this.state.id);
      // urlencoded.append("sellerId", sellerData.id);
      axios
        .post(global.baseUrl + 'sellerorderidlist', urlencoded, {
          headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        })
        .then((result) => {
          console.log(result.data);
          if (result.data.status) {
            order = result.data.data;
            this.setState({ order: result.data.data }, () => {
              this.OrderAPICall();
            });
          }
        });
    }
  }
  invoicegenerate=()=>{
    var urlencoded = new URLSearchParams();
    console.log(this.state.order,"ffffffffffffffffff")
    // urlencoded.append('product_id',this.state.product_id);
    urlencoded.append('User_id', this.state.order[0].userId.id);
    urlencoded.append('order_Id', this.state.id.slice(3,this.state.id.length));

    axios
      .post(global.baseUrl + 'invoice2', urlencoded, {
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      })
      .then((result) => {
        console.log(result.data);
        let url=result.data.data[0].Url;
        var message = {'type':'1', 'data' : [  { "pdfLink" : url } ]};
        window.postMessage(message)
        
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute(
          'download',
          `FileName.pdf`,
        );
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
          // window.location.reload();
        // window.location.reload();
      });
  }
   myFunction=()=> {
    document.getElementById("myDropdown").classList.toggle("show");
  }
  Reportuser=()=>{
    if(this.state.reportdescription&&this.state.reportdescription!==''){
      var urlencoded = new URLSearchParams();
      console.log(this.state.order,"ffffffffffffffffff")
      // urlencoded.append('product_id',this.state.product_id);
      urlencoded.append("sellerId", this.state.seller.id);
      urlencoded.append('description',this.state.reportdescription);
      urlencoded.append('userId', this.state.order[0].userId.id);
  
      axios
        .post(global.baseUrl + 'reportbuyer', urlencoded, {
          headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        })
        .then((result) => {
          console.log(result.data);
          this.setState({
            reportdescription:'',
          })
          toast.success(<CustomToast3 />, {
            position: toast.POSITION.TOP_RIGHT,
          });
            
            // window.location.reload();
          // window.location.reload();
        });
    // }
       
      }else{
        toast.success(<CustomToast4 />, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
      this.setState({
        modal2: !this.state.modal2
      });
  }

  downlodelable=(url)=>{
    var message = {'type':'1', 'data' : [  { "pdfLink" : url } ]};
    window.postMessage(message)
    // new JsFileDownloader({ 
    //   url: url
    // })
    // saveAs(
    //   url,
    //   "example.pdf"
    // );
    // const link = document.createElement('a');
    // link.href = url;
    // link.setAttribute(
    //   'download',
    //   `FileName.pdf`,
    // );
    // document.body.appendChild(link);
    // link.click();
    // link.parentNode.removeChild(link);
    localStorage.setItem('label',url);
    this.props.history.push("/viewlabel");
  }
  OrderAPICall = () => {
    var urlencoded = new URLSearchParams();
    urlencoded.append('order_Id', this.state.id);

    axios
      .post(global.baseUrl + 'sellerordertrack', urlencoded, {
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      })
      .then((result) => {
        console.log(result.data, 'order list');
        if (result.data.status) {
          if (result.data.data.order_summary) {
            if (result.data.data.order_summary) {
              let summary = [];
              for (let i = 0; i < result.data.data.order_summary.length; i++) {
                summary.push(JSON.parse(result.data.data.order_summary[i]));
                summarydata.push(JSON.parse(result.data.data.order_summary[i]));
              }
              this.setState({ summary });
              // summarydata.push()
              console.log('fjgg', summary);
            }
          } else {
            console.log('not working');
          }
          // this.setState({
          //   orderStatus: result.data.data.order_status,
          //   newOrderStatus: result.data.data.order_status,

          //   orderDate: result.data.data.order_date,
          //   a: result.data.data.order_status,
          // });

          // if (result.data.data.order_status === "packed") {
          //   this.setState({
          //     orderDate: result.data.data.order_date,
          //     packedDate: result.data.data.packed_date,

          //   });
          // } else if (result.data.data.order_status === "dispatched") {
          //   this.setState({
          //     orderDate: result.data.data.order_date,
          //     packedDate: result.data.data.packed_date,
          //     dispatchedDate: result.data.data.dispatched_date,

          //   });
          // } else if (result.data.data.order_status === "delivered") {
          //   this.setState({
          //     orderDate: result.data.data.order_date,
          //     packedDate: result.data.data.packed_date,
          //     dispatchedDate: result.data.data.dispatched_date,
          //     deliveryDate: result.data.data.delivered_date
          //   }, () => console.log(this.state.packedDate, "djhfjdhkdjh"));
          // }
        }
      });
  };
  OrderTrackAPICall = () => {
    if (this.state.orderStatus !== this.state.a) {
      if (this.state.orderStatus === 'packed') {
        this.setState(
          {
            packedDate: new Date(),
            dispatchedDate: '',
            deliveryDate: '',
          },
          () => this.updateTrackAPICall()
        );
      } else if (this.state.orderStatus === 'dispatched') {
        if (this.state.packedDate === '') {
          this.setState(
            {
              packedDate: new Date(),
              dispatchedDate: new Date(),
              deliveryDate: '',
            },
            () => this.updateTrackAPICall()
          );
        } else {
          this.setState(
            {
              dispatchedDate: new Date(),
              deliveryDate: '',
            },
            () => this.updateTrackAPICall()
          );
        }
      } else if (this.state.orderStatus === 'delivered') {
        if (this.state.packedDate === '') {
          this.setState(
            {
              packedDate: new Date(),
              dispatchedDate: new Date(),
              deliveryDate: new Date(),
            },
            () => this.updateTrackAPICall()
          );
        } else if (this.state.dispatchedDate === '') {
          this.setState(
            {
              dispatchedDate: new Date(),
              deliveryDate: '',
            },
            () => this.updateTrackAPICall()
          );
        } else {
          this.setState(
            {
              deliveryDate: new Date(),
            },
            () => this.updateTrackAPICall()
          );
        }
      }
    }
  };
  updateTrackAPICall() {
    this.setState({ a: '' });
    console.log(this.state.order, 'djfdjk');
    var urlencoded = new URLSearchParams();
    urlencoded.append('order_Id', this.state.order.id);

    urlencoded.append('order_status', this.state.orderStatus);
    urlencoded.append('order_date', this.state.orderDate);
    urlencoded.append('packed_date', this.state.packedDate);
    urlencoded.append('dispatched_date', this.state.dispatchedDate);
    urlencoded.append('delivered_date', this.state.deliveryDate);

    axios
      .post(global.baseUrl + 'sellerordertrackupdate', urlencoded, {
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      })
      .then((result) => {
        console.log(result.data);
        this.setState({ newOrderStatus: this.state.orderStatus });
        this.OrderAPICall();
      });
  }
  toggle = (tab) => {
    if (tab === '1') {
      this.setState({ activeTab: '1' });
    } else {
      this.setState({ activeTab: '2' });
    }
  };
  slideOpen = (image) => {
    // images = image;
    this.setState({ isOpen: true });
  };

  handleChange = (event) => {
    this.setState(
      {
        orderStatus: event.target.value,
      },
      () => console.log('dkfjjdhfkj', this.state.orderStatus)
    );
  };
  handleOrderDetailChange = (e) => {
    localStorage.setItem('treackdata',JSON.stringify({user_ID:this.state.order[0].userId.id,orderId:this.state.id}))
    this.setState({ orderDetail: e.target.value });
    orderDetail = e.target.value;
    this.props.history.push(`/ordertreak/${e.target.value}`);
    // location.his
  };
  addOrderTrackingDetail = () => {
    console.log('Working');
    if (this.state.orderDetail !== '') {
      let summary = this.state.summary;
      summary.unshift({
        value: this.state.orderDetail,
        date: new Date(),
      });
      var urlencoded = new URLSearchParams();
      urlencoded.append('order_Id', this.state.id);
      for (let i = 0; i < summary.length; i++) {
        urlencoded.append('order_summary', JSON.stringify(summary[i]));
      }

      axios
        .post(global.baseUrl + 'sellerAddOrderSummary', urlencoded, {
          headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        })
        .then((result) => {
          console.log(result.data);
          if (result.data.status) {
            let summary = [];
            for (let i = 0; i < result.data.data.order_summary.length; i++) {
              summary.push(JSON.parse(result.data.data.order_summary[i]));
            }
            this.setState({ summary });
            // this.setState({ summary: result.data.data.or })
          }
        });
    }
  };


  sellercancell=()=>{
    if(this.state.cancelReason&&this.state.cancelReason!==''){
    var urlencoded = new URLSearchParams();
    urlencoded.append('product_id',this.state.product_id);
    urlencoded.append('cancelReason',this.state.cancelReason);
    // urlencoded.append('userId', this.state.seller.id);

    axios
      .post(global.baseUrl + 'sellercancelorder', urlencoded, {
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      })
      .then((result) => {
        console.log(result.data);
        var urlencoded2 = new URLSearchParams();
        urlencoded2.append('id',this.state.product_id);
        urlencoded2.append('refundStatus','1');

    axios
      .post(global.baseUrl + 'approveRefund', urlencoded2, {
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      })
      .then((result) => {
        if (result.data) {
          this.setState({
            product_id:'',
            cancelReason:'',
          })
          toast.success(<CustomToast />, {
            position: toast.POSITION.TOP_RIGHT,
          });
          
          // window.location.reload();
        } else {
          console.log('result', result.data);
        }
        // window.location.reload();
      });
  // }
      });
    }else{
      toast.success(<CustomToast2 />, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
    this.setState({
      modal: !this.state.modal
    });
  }

  downlodeall=()=>{
    if(window.innerWidth <= 767){
    let data=`<div>${document.getElementById('printdata').innerHTML}</div>`
     var urlencoded = new URLSearchParams();
       // urlencoded.append('id', id);
       urlencoded.append('body', data);
       axios
         .post(global.baseUrl+ 'pdfgenratelink', urlencoded, {
           headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
         })
         .then((result) => {
          console.log(result.data.data,"ddddddddlink")
          // this.setState({loading:false});
          var message = {'type':'1', 'data' : [  { "pdfLink" : result.data.data } ]};
         //  window.webkit.messageHandlers.derp.postMessage(message);
         // <iframe src={result.data.data} />
         // window.open(result.data.data,"_self")
         window.postMessage(message)
         this.setState({loading:false});
         // this.props.history.push({
         //   pathname: '/download',
         //   // search: '?query=abc',
         //   state: { downlodelink: result.data.data}
         // })
         // this.setState({downlodelink:result.data.data})
         // return <iframe src={this.state.downlodelink} />
         this.setState({loding:false});
         });
        }else{
          this.setState({loding:false});
        }
   }
  render() {
    return (
      <body
        class="vertical-layout vertical-menu-modern semi-dark-layout 2-columns  navbar-floating footer-static  "
        data-open="click"
        data-menu="vertical-menu-modern"
        data-col="2-columns"
        data-layout="semi-dark-layout"
      >
        {/* <Header />
        <Sidebar /> */}

        <div class="app-content content">
          <div class="content-overlay"></div>
          <div class="header-navbar-shadow"></div>
          <div class="content-wrapper">
            <div class="content-header">
              <div class="content-header-left col-md-12 col-12 mb-2">
                <div class="row breadcrumbs-top">
                  <div class="col-12">
                    <div class="row">
                      <div class="col-9">
                        <Link
                          class="content-header-title float-left mb-0"
                          to="/orderlist"
                          style={{ display: 'block', fontSize: '1.74rem' }}
                        >
                          <i class="fas fa-long-arrow-alt-left"></i> View Order
                        </Link>
                        <div class="breadcrumb-wrapper">
                          <ol class="breadcrumb">
                            <li class="breadcrumb-item">
                              <Link to="/orderlist">Orders</Link>
                            </li>
                            <li
                              class="breadcrumb-item 
                            active"
                            >
                              Order Detail
                            </li>
                          </ol>
                        </div>
                      </div>

                      {/* <div class="content-header-right text-md-right col-md-3 col-12 d-md-block d-none">
                        <div
                          class="form-group breadcrum-right"
                          style={{ marginBottom: "0px" }}
                        >
                          <Link
                            to="/addproduct"
                            id="addRow"
                            data-toggle="modal"
                            data-target="#success"
                            class="btn btn-primary mb-2 add-product-link"
                          >
                            Order Status
                          </Link>
                        </div>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>





              <Modal isOpen={this.state.modal} toggle={this.toggle} className={this.props.className}>
          <ModalHeader toggle={this.toggle}>Cancel Resone</ModalHeader>
          <ModalBody>
          <textarea type="text" class="form-control" placeholder="Enter Cancel Resone" name="orderDetail" value={this.state.cancelReason} onChange={(e)=>{this.setState({cancelReason:e.target.value})}}></textarea>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={()=>this.sellercancell()}>Submit</Button>{' '}
            <Button color="secondary" onClick={()=>{this.setState({
      modal: !this.state.modal
    })}}>Cancel</Button>
          </ModalFooter>
        </Modal>



        <Modal isOpen={this.state.modal2} toggle2={this.toggle2} className={this.props.className}>
          <ModalHeader toggle2={this.toggle2}>Report Description</ModalHeader>
          <ModalBody>
          <textarea type="text" class="form-control" placeholder="Enter report description" name="orderDetail" value={this.state.reportdescription} onChange={(e)=>{this.setState({reportdescription:e.target.value})}}></textarea>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={()=>this.Reportuser()}>Submit</Button>{' '}
            <Button color="secondary" onClick={()=>{this.setState({
      modal2: !this.state.modal2
    })}}>Cancel</Button>
          </ModalFooter>
        </Modal>







              <section class="users-edit">
                <div class="modal-success mr-1 mb-1 d-inline-block">
                  <div
                    class="modal fade text-left"
                    id="success"
                    tabindex="-1"
                    role="dialog"
                    aria-labelledby="myModalLabel110"
                    aria-hidden="true"
                  >
                    <div
                      class="modal-dialog modal-dialog-centered modal-dialog-scrollable"
                      role="document"
                    >
                      <div class="modal-content">
                        <div class="modal-header bg-success white">
                          <h5 class="modal-title" id="myModalLabel110">
                            Order Status
                          </h5>
                          <button
                            type="button"
                            class="close"
                            data-dismiss="modal"
                            aria-label="Close"
                          >
                            <span aria-hidden="true">&times;</span>
                          </button>
                        </div>
                        <div class="modal-body">
                          <h4>Change order Status</h4>
                          <fieldset class="form-group">
                            <select
                              class="form-control"
                              id="basicSelect"
                              placeholder="Select seller type "
                              name="orderStatus"
                              value={this.state.orderStatus}
                              onChange={(e) => this.handleChange(e)}
                            >
                              <option value="" className="opt">
                                Select
                              </option>

                              {this.state.orderStatus === 'ordered' ? (
                                <>
                                  <option
                                    value="ordered"
                                    disabled
                                    className="opt"
                                  >
                                    Ordered
                                  </option>
                                  <option value="packed" className="opt">
                                    Packed
                                  </option>
                                  <option value="dispatched" className="opt">
                                    Dispatched
                                  </option>
                                  <option value="delivered" className="opt">
                                    Delivered
                                  </option>
                                </>
                              ) : null}

                              {this.state.orderStatus === 'packed' ? (
                                <>
                                  <option
                                    value="ordered"
                                    disabled
                                    className="opt"
                                  >
                                    Ordered
                                  </option>
                                  <option
                                    value="packed"
                                    className="opt"
                                    disable
                                  >
                                    Packed
                                  </option>
                                  <option value="dispatched" className="opt">
                                    Dispatched
                                  </option>
                                  <option value="delivered" className="opt">
                                    Delivered
                                  </option>
                                </>
                              ) : null}
                              {this.state.orderStatus === 'dispatched' ? (
                                <>
                                  <option
                                    value="ordered"
                                    disabled
                                    className="opt"
                                  >
                                    Ordered
                                  </option>
                                  <option value="packed" className="opt">
                                    Packed
                                  </option>
                                  <option
                                    value="dispatched"
                                    className="opt"
                                    disabled
                                  >
                                    Dispatched
                                  </option>
                                  <option value="delivered" className="opt">
                                    Delivered
                                  </option>
                                </>
                              ) : null}
                              {this.state.orderStatus === 'delivered' ? (
                                <>
                                  <option
                                    value="ordered"
                                    disabled
                                    className="opt"
                                  >
                                    Ordered
                                  </option>
                                  <option value="packed" className="opt">
                                    Packed
                                  </option>
                                  <option value="dispatched" className="opt">
                                    Dispatched
                                  </option>
                                  <option
                                    value="delivered"
                                    className="opt"
                                    disable
                                  >
                                    Delivered
                                  </option>
                                </>
                              ) : null}
                            </select>
                          </fieldset>
                        </div>
                        <div class="modal-footer">
                          <button
                            type="button"
                            class="btn btn-success"
                            data-dismiss="modal"
                            onClick={() => this.OrderTrackAPICall()}
                          >
                            OK
                          </button>
                          <button
                            type="button"
                            class="btn btn-success "
                            data-dismiss="modal"
                            aria-label="Close"
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div>
                  <div class="content-body">
                    <section class="page-users-view">
                      <div class="row order-tracking-row">
                        <div class="col-md-8">
                          <div class="card order-box">
                            <div class="card-header">
                              <div class="card-title">
                                Order Id:{this.state.id}
                              </div>
                              <div>
                              <button
                                      style={{
                                        backgroundColor: 'transparent',
                                        border: 'none',
                                      }}
                                      // onClick={()=>this.downlodeall()}
                                    >
                                      {' '}
                                      {this.state.loding===false?
                                      <i
                                        className="fa fa-print order-print"
                                        // onClick={()=>{this.setState({loding:true});this.downlodeall()}}
                                        onClick={()=>this.invoicegenerate()}
                                        style={{ cursor: 'pointer' }}
                                      ></i>:<Spinner/>}
                                    </button>
                                {/* <ReactToPrint
                                  trigger={() => (
                                    <button
                                      style={{
                                        backgroundColor: 'transparent',
                                        border: 'none',
                                      }}
                                      // onClick={()=>this.downlodeall()}
                                    >
                                      {' '}
                                      {this.state.loding===false?
                                      <i
                                        className="fa fa-print order-print"
                                        // onClick={()=>{this.setState({loding:true});this.downlodeall()}}
                                        onClick={()=>this.invoicegenerate()}
                                        style={{ cursor: 'pointer' }}
                                      ></i>:<Spinner/>}
                                    </button>
                                  )}
                                  content={() => this.componentRef}
                                  style={{ display: 'none' }}
                                /> */}
                                <div id="printMe" style={{ display: 'none' }}>
                                  <ComponentToPrint
                                    ref={(el) => (this.componentRef = el)}
                                    style={{ display: 'none' }}
                                  />
                                </div>
                              </div>
                            </div>
                            <div class="card-body">
                              <div class="row">
                                {console.log(this.state.order,"dddddddddd",this.state.seller?.type)}
                                {this.state.order.map((data, index) => (data.productId.sellerType.toLowerCase()==this.state.seller.type.toLowerCase()&&data.productId.sellerId==this.state.seller.id&&
                                  <>
                                    <div className="col-md-12">
                                      <div className="row br-order">
                                        <div class="col-md-4 col-12 ">
                                          <img
                                            src={
                                              data.productId
                                                ? data.productId.image[0]
                                                : null
                                            }
                                            alt="..."
                                            class="product-view-image"
                                          />
                                        </div>
                                        <div class="col-md-8 col-12 ">
                                          <table>
                                            <tr>
                                              <td class="font-weight-bold product-detail-box1">
                                                Product
                                              </td>
                                              <td class="product-detail-box1">
                                                {data.productId
                                                  ? data.productId.product_name
                                                  : null}
                                              </td>
                                            </tr>
                                            <tr>
                                              <td class="font-weight-bold product-detail-box1">
                                                Quantity
                                              </td>
                                              <td class="product-detail-box1">
                                                {data.quantity}
                                              </td>
                                            </tr>
                                            <tr>
                                              <td class="font-weight-bold product-detail-box1">
                                                Size
                                              </td>
                                              <td class="product-detail-box1">
                                                {data.size}
                                              </td>
                                            </tr>
                                            <tr>
                                              <td class="font-weight-bold product-detail-box1">
                                                Order Date
                                              </td>
                                              <td class="product-detail-box1">
                                                {moment(data.createdAt).format(
                                                  'DD/MM/YYYY'
                                                )}
                                              </td>
                                            </tr>

                                            {/* <tr>
                                              <td class="font-weight-bold product-detail-box1">
                                                Order note
                                              </td>
                                              <td class="product-detail-box1">
                                                {data.Order_add_edit_notes}
                                              </td>
                                            </tr> */}
                                            <tr>
                                              <td class="font-weight-bold product-detail-box1">
                                                Label

                                              </td>
                                              <td class="product-detail-box1">
                                                {data.lable_url ? <i
                                        className="fa fa-print downlode"
                                        onClick={()=>{this.downlodelable(data.lable_url)}}
                                        style={{ cursor: 'pointer',fontSize:"25px"}}
                                      ></i> : ''}
                                              </td>
                                            </tr>
                                            {data.returnApprovedStatus !=
                                          '8'&&
                                            <tr>
                                              <td class="font-weight-bold product-detail-box1">
                                                Return Status
                                              </td>
                                              <td class="product-detail-box1">
                                                { data.returnApprovedStatus ==
                                            '2' ?(
                                            'Cancel'
                                          ): data.returnApprovedStatus ==
                                          '8'? 'Pending':data.returnApprovedStatus ==
                                          '1'? (
                                            'Approve'
                                          ):data.returnApprovedStatus ==
                                          '0'&&'Pending'}
                                              </td>
                                            </tr>
                                            }
                                            <tr>
                                              <td class="font-weight-bold product-detail-box1">
                                                Return Tracking Number
                                              </td>
                                              <td class="product-detail-box1">
                                                {data.return_treking_id ? data.return_treking_id : ''}
                                              </td>
                                            </tr>
                                            <tr>
                                              <td class="font-weight-bold product-detail-box1">
                                               Tracking Number
                                              </td>
                                              <td class="product-detail-box1">
                                                {data.treking_id?data.treking_id:''}
                                              </td>
                                            </tr>
                                          </table>
                                          {data.cancelApprovedStatus=='8'?
                                          <button className="btn btn-danger" style={{marginLeft:"30%",marginBottom:"13px"}} onClick={() =>{
                                                  // this.setState({userid:order.id,status1:'2'});
                                                  // this.CancelApprovHandler1(order.id,'4')
                                                  this.setState({
                                                    modal: !this.state.modal,
                                                    product_id:data.id
                                                  });

                                                }}>Cancel Order</button>:data.cancelApprovedStatus=='3'?<p style={{color:"red",fontWeight:"bold"}}>Order Canceled</p>:data.cancelApprovedStatus=='4'?<p style={{color:"red",fontWeight:"bold"}}>Cancele Request Decline</p>:<p style={{color:"red",fontWeight:"bold"}}>Buyer Request For Cancellation</p>}
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                ))}
                              </div>
                            </div>
                          </div>
                          <div class="card">
                            <div class="card-header">
                              <div class="card-title">Other Detail</div>
                            </div>
                            <div class="card-body">
                              <div class="row">
                                <>
                                  <div class="col-md-6 col-12 ">
                                    <table>
                                      <tr>
                                        <td class="font-weight-bold product-detail-box">
                                          Buyer Name{' '}
                                        </td>
                                        <td>
                                          {this.state.order.length
                                            ? this.state.order[0].userId&&this.state.order[0].userId.name
                                            : null}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td class="font-weight-bold product-detail-box">
                                          Shipping Address
                                        </td>
                                        <td>No address found </td>
                                      </tr>
                                      <tr>
                                        <td class="font-weight-bold product-detail-box">
                                          Transaction Id
                                        </td>
                                        <td>9521753</td>
                                      </tr>
                                      <tr>
                                        <td class="font-weight-bold product-detail-box">
                                          Payment
                                        </td>
                                        <td>paypal</td>
                                      </tr>
                                    </table>
                                  </div>

                                  <div class="col-md-6 col-12 ">
                                    <table>
                                      <tr>
                                        <td class="font-weight-bold product-detail-box">
                                          Coupon Id
                                        </td>
                                        <td>
                                          {this.state.order.length
                                            ? this.state.order[0].couponId
                                            : null}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td class="font-weight-bold product-detail-box">
                                          Order Total
                                        </td>
                                        <td> {this.state.order.length
                                            ? this.state.order[0].bag_total
                                            : null}</td>
                                      </tr>
                                      {/* <tr>
                                        <td></td> */}
                                    
                                      {/* </tr> */}
                                      {/* <tr>
                                        <td class="font-weight-bold product-detail-box">
                                          treacking Id
                                        </td>
                                        <td>364001</td>
                                      </tr> */}
                                    </table>
                                    <button className="btn btn-danger buttonposition"  onClick={() =>{
                                                  // this.setState({userid:order.id,status1:'2'});
                                                  // this.CancelApprovHandler1(order.id,'4')
                                                  this.setState({
                                                    modal2: !this.state.modal2,
                                                    // user_id:data.id
                                                  });

                                                }}>Report Buyer</button>
                                  </div>
                                </>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                         {this.state.seller?.type=="Retailer"&&<> <div class="card">
                            <div class="card-header">
                              <h4 class="card-title">Order Tracking</h4>
                            </div>
                            <div class="card-content">
                              <div class="card-body">
                                <div class="form-label-group">
                                <select name="cars" id="cars" class="form-control"
                                    placeholder="Enter Tracking Id"
                                    // name="orderDetail"
                                    onChange={this.handleOrderDetailChange}
                                    value={this.state.orderDetail}
                                    >
                                      <option value="">Plese Select Product</option>
                                     {this.state.order.map((data, index) => (data.productId.sellerId===this.state.seller.id&&data.treking_id&&data.treking_id!==''&&data.productId.sellerType==this.state.seller.type&&
                                       <option value={data.treking_id}>{data.productId
                                        ? data.productId.product_name
                                        : null}</option>
                                     ))}
   {/* <option value="volvo">Volvo</option>
  <option value="saab">Saab</option>
   <option value="mercedes">Mercedes</option>
   <option value="audi">Audi</option> */}
</select>
                                  {/* <textarea
                                    type="text"
                                    class="form-control"
                                    placeholder="Enter Tracking Id"
                                    name="orderDetail"
                                    value={this.state.orderDetail}
                                    onChange={this.handleOrderDetailChange}
                                    aria-label="Amount"
                                    onBlur={this.addOrderTrackingDetail}
                                  /> */}
                                  {/* <ul style={{ marginTop: '5px' }}>
                                    <p className="tracking-line">
                                      {this.state.summary.map((data, index) => (
                                        <li
                                          key={index}
                                          style={{ margin: '10px' }}
                                        >
                                          <p className="tracking-date">
                                            {moment(data.date).format(
                                              'DD/MM/YYYY,h:mm a '
                                            )}
                                            <br />
                                          </p>
                                          <strong> {data.value}</strong>
                                        </li>
                                      ))}
                                    </p>
                                  </ul> */}
                                  <ul style={{ marginTop: '5px' }}></ul>
                                  {/* <Link
                      to={`/ordertreak/${this.state.orderDetail}`}
                      id="addRow"
                      class="btn btn-primary mb-2 add-product-link"
                    >
                      Track
                    </Link> */}
                                  {/* <div class="form-control-position">
                                              <i class="feather icon-package"></i>
                                            </div> */}
                                  {/* <p class="error-msg">{nameError}</p> */}
                                </div>
                                {/* <ul >
                                      <div className={this.state.newOrderStatus === "packed" || this.state.orderStatus === "dispatched" || this.state.orderStatus === "delivered" ? "active-track-box" : "order-track-box"}>
                                        <div class="timeline-icon bg-primary orderlist-icon">
                                          <i class="feather icon-plus font-medium-2"></i>
                                        </div>
                                        <div>
                                          <div class="timeline-info">
                                            <p class="font-weight-bold" style={{ margin: "0px" }}>
                                              Ordered
                                          </p>
                                          </div>
                                          <small class="">
                                            {moment(
                                              this.state.order.createdAt
                                            ).format("DD/MM/YYYY")}
                                          </small>
                                        </div>
                                      </div>

                                      <div className={this.state.newOrderStatus === "dispatched" || this.state.orderStatus === "delivered" ? "active-track-box" : "order-track-box"}>
                                        <div class="timeline-icon bg-warning orderlist-icon">
                                          <i class="feather icon-alert-circle font-medium-2"></i>

                                        </div>
                                        <div>
                                          <div class="timeline-info">
                                            <p class="font-weight-bold" style={{ margin: "0px" }}>Packed</p>
                                          </div>
                                          <small class=""> {this.state.packedDate ? moment(
                                            this.state.packedDate
                                          ).format("DD/MM/YYYY,h:mm a ") : null}</small>

                                        </div>
                                      </div>

                                      <div className={this.state.newOrderStatus === "delivered" ? "active-track-box" : "order-track-box"}>
                                        <div class="timeline-icon bg-warning orderlist-icon">
                                          <i class="feather icon-alert-circle font-medium-2"></i>

                                        </div>
                                        <div>
                                          <div class="timeline-info">
                                            <p class="font-weight-bold">Dispatched</p>
                                          </div>
                                          <small class=""> {this.state.dispatchedDate ? moment(
                                            this.state.dispatchedDate
                                          ).format("DD/MM/YYYY,h:mm a ") : null}</small>

                                        </div>
                                      </div>

                                      <div className="none-order-track-box">
                                        <div class="timeline-icon bg-success orderlist-icon">
                                          <i class="feather icon-check font-medium-2"></i>

                                        </div>
                                        <div>
                                          <div class="timeline-info">
                                            <p class="font-weight-bold" style={{ margin: "0px" }}>Delivered</p>
                                          </div>
                                          <small class=""> {this.state.deliveryDate ? moment(
                                            this.state.deliveryDate
                                          ).format("DD/MM/YYYY,h:mm a ") : null}</small>

                                        </div>
                                      </div>
                                    </ul>
                                   */}
                              </div>
                            </div>
                          </div>
                          <div class="card">
                            <div class="card-header">
                              <h4 class="card-title">Return Order Tracking</h4>
                            </div>
                            <div class="card-content">
                              <div class="card-body">
                                <div class="form-label-group">
                                <select name="cars" id="cars" class="form-control"
                                    placeholder="Enter Tracking Id"
                                    // name="orderDetail"
                                    onChange={this.handleOrderDetailChange}
                                    value={this.state.orderDetail}
                                    >
                                      <option value="">Plese Select Product</option>
                                     {this.state.order.map((data, index) => (data.productId.sellerId===this.state.seller.id&&data.return_treking_id&&data.return_treking_id!==''&&
                                       <option value={data.return_treking_id}>{data.productId
                                        ? data.productId.product_name
                                        : null}</option>
                                     ))}
   {/* <option value="volvo">Volvo</option>
  <option value="saab">Saab</option>
   <option value="mercedes">Mercedes</option>
   <option value="audi">Audi</option> */}
</select>
                                  <ul style={{ marginTop: '5px' }}></ul>
                                </div>
                              </div>
                            </div>
                          </div></>}
                          <div class="card">
                            <div class="card-header">
                              <h4 class="card-title">Order Settings</h4>
                            </div>
                            <div class="card-content">
                              <div class="card-body">
                                <div class="form-label-group">
                                <div class="dropdown">
  <button onClick={()=>this.myFunction()} class="btn btn-primary add-product-link">Order Settings</button>
  <div id="myDropdown" class="dropdown-content">
    <a onClick={()=>this.invoicegenerate()}>Purchase shipping label</a>
    <a  onClick={() =>{this.setState({modal2: !this.state.modal2})}}>Report Buyer</a>
    {/* <Link to={`/contectbuyer/${this.state.order[0]?.userId.id}`}>Contact Buyer</Link> */}
  </div>
</div>
                                  {/* <select name="cars" id="cars" class="form-control"
                                    placeholder="Enter Tracking Id"
                                    name="orderDetail"
                                  // onChange={this.handleOrderDetailChange}
                                  // value={this.state.orderDetail}
                                  >
                                    <option value="">Plese Select</option>
                                    <option value="volvo">Purchase shipping lable</option>
                                    <option value="volvo">Report Buyer</option>
                                    <option value="saab"><Link to={`/contectbuyer/${this.state.order[0]?.userId.id}`}>Contact Buyer</Link></option>
                                  </select> */}
                                  <ul style={{ marginTop: '5px' }}></ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>

        {/* <div class="app-content content">
          <div class="content-overlay"></div>
          <div class="header-navbar-shadow"></div>
          <div class="content-wrapper">
            <div class="content-header row"></div>
            <div class="content-body">
              <section class="page-users-view">
                <div class="row">
                  <div class="col-12">
                    <div class="card">
                      <div class="card-header">
                        <div class="card-title">
                          {this.state.product.product_name}
                        </div>
                      </div>
                      <div class="card-body">
                        <div class="row">
                          {console.log(this.state.product)}
                          {this.state.product.image
                            ? this.state.product.image.map((product, index) => (
                                <img
                                  key={index}
                                  src={product}
                                  alt="..."
                                  class="product-view-image"
                                />
                              ))
                            : null}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-12 col-12 ">
                    <div class="card">
                      <div class="card-header">
                        <div class="card-title mb-2">Product Detail</div>
                      </div>
                      <div class="card-body">
                        <div class="row">
                          <div class="col-md-6 col-12 ">
                            <table>
                              <tr>
                                <td class="font-weight-bold product-detail-box">
                                  Product Name{" "}
                                </td>
                                <td>{this.state.product.product_name}</td>
                              </tr>
                              <tr>
                                <td class="font-weight-bold product-detail-box">
                                  Price
                                </td>
                                <td>{this.state.product.price}</td>
                              </tr>
                              <tr>
                                <td class="font-weight-bold product-detail-box">
                                  Product Category
                                </td>
                                <td>
                                  {this.state.product.category
                                    ? this.state.product.category.name
                                    : null}
                                </td>
                              </tr>
                              <tr>
                                <td class="font-weight-bold product-detail-box">
                                  Brand
                                </td>
                                <td>
                                  {this.state.product.brand
                                    ? this.state.product.brand.name
                                    : null}
                                </td>
                              </tr>
                              <tr>
                                <td class="font-weight-bold product-detail-box">
                                  Fit Guide
                                </td>
                                <td>{this.state.product.fit_guide}</td>
                              </tr>
                              <tr>
                                <td class="font-weight-bold product-detail-box">
                                  Glossary
                                </td>
                                <td>{this.state.product.glossary}</td>
                              </tr>
                              <tr>
                                <td class="font-weight-bold product-detail-box">
                                  Description
                                </td>
                                <td>
                                  {this.state.product.product_description}
                                </td>
                              </tr>
                            </table>
                          </div>
                          <div class="col-md-6 col-12 ">
                            <iframe
                              width="350"
                              height="200"
                              src={this.state.product.video}
                              frameborder="0"
                              title=" "
                              // allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                              allowfullscreen
                            ></iframe>
                             <video width="400" controls>
                          <source
                            src={this.state.product.video}
                            type="video/mp4"
                          />
                          Your browser does not support HTML video.
                        </video> 
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="card">
                      <div class="card-header border-bottom mx-2 px-0">
                        <h6 class="border-bottom py-1 mb-0 font-medium-2">
                          <i class="feather icon-lock mr-50 "></i>Stock
                          Management
                        </h6>
                      </div>
                      <div class="card-body px-75">
                        <div class="table-responsive users-view-permission">
                          <table class="table table-borderless">
                            <thead>
                              <tr style={{ textAlign: "center" }}>
                                <th className="stock-td">No</th>
                                <th className="stock-td">Color</th>
                                <th className="stock-td">Size</th>
                                <th className="stock-td">Stock</th>
                                <th className="stock-td">Status</th>
                                <th></th>
                              </tr>
                            </thead>
                            <tbody>
                              {console.log(this.state.colorSize)}
                              {this.state.colorSize
                                ? this.state.colorSize.map((colorSize, index) =>
                                    colorSize.size_qty.map(
                                      (size_qty, index1) => (
                                        <tr style={{ textAlign: "center" }}>
                                          <td className="stock-td">
                                            {index1 + index}
                                          </td>
                                          <td className="color-box-d stock-td">
                                            <div
                                              className="color-text-d"
                                              style={{
                                                backgroundColor: colorSize.color
                                              }}
                                            ></div>
                                          </td>
                                          <td className="stock-td">
                                            {size_qty.size}
                                          </td>
                                          <td className="stock-td">
                                            <input
                                              type="number"
                                              class="form-control"
                                              placeholder="Quantity"
                                              value={size_qty.quantity}
                                              onChange={e =>
                                                this.handleQtyChange(
                                                  e,
                                                  index,
                                                  index1
                                                )
                                              }
                                              aria-label="Amount"
                                              style={{ width: "180px" }}
                                            />
                                          </td>
                                          <td className="stock-td">
                                            {size_qty.quantity === "0"
                                              ? "Out of stock"
                                              : "Available"}
                                          </td>
                                          <td></td>
                                        </tr>
                                      )
                                    )
                                  )
                                : null}
                            </tbody>
                            <button
                              class="btn btn-primary-green p-1 add-size add-product"
                              type="button"
                              onClick={this.productApiCall}
                              // disabled={submitDisable}
                            >
                              Update Stock
                            </button>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-md-12 col-12 ">
                  <div class="card">
                    <div class="card-header">
                      <div class="card-title mb-2">Reviews</div>
                    </div>
                    <div class="card-body">
                      {this.state.reviews
                        ? this.state.reviews.map((review, index) => (
                            <div class="row">
                              <div class="col-md-3 col-12 ">
                                <img
                                  key={index}
                                  src={review.file_path}
                                  alt="..."
                                  class="product-view-image"
                                />
                              </div>

                              <div class="col-md-4 col-12 ">
                                <table>
                                  <tr>
                                    <td class="font-weight-bold product-detail-box">
                                      User Name
                                    </td>
                                    <td>
                                      {review.userId
                                        ? review.userId.name
                                        : null}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td class="font-weight-bold product-detail-box">
                                      Rating
                                    </td>
                                    <td>{review.rating}</td>
                                  </tr>
                                  <tr>
                                    <td class="font-weight-bold product-detail-box">
                                      Fit as expect
                                    </td>
                                    <td>{review.size}</td>
                                  </tr>
                                  <tr>
                                    <td class="font-weight-bold product-detail-box">
                                      Value for money
                                    </td>
                                    <td>{review.value}</td>
                                  </tr>
                                </table>
                              </div>
                              <div class="col-md-4 col-12 ">
                                <table>
                                  <tr>
                                    <td class="font-weight-bold product-detail-box">
                                      Review Headline
                                    </td>
                                    <td>{review.review_headline}</td>
                                  </tr>
                                  <tr>
                                    <td class="font-weight-bold product-detail-box">
                                      Easy to use
                                    </td>
                                    <td>{review.use}</td>
                                  </tr>
                                  <tr>
                                    <td class="font-weight-bold product-detail-box">
                                      Description
                                    </td>
                                    <td>{review.review_description}</td>
                                  </tr>
                                </table>
                              </div>
                            </div>
                          ))
                        : null}
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div> */}
        <div class="sidenav-overlay"></div>
        <div class="drag-target"></div>
        <Footer />
      </body>
    );
  }
}
export default ViewProduct;
