import React from 'react';
import axios from 'axios';
// import InputColor from 'react-input-color';
import 'react-image-lightbox/style.css';
// import CKEditor from 'react-ckeditor-component';
import { Link } from 'react-router-dom';
import $ from 'jquery';
import OwlCarousel from 'react-owl-carousel';

// import {
//   TabContent,
//   TabPane,
//   Nav,
//   NavItem,
//   NavLink,
//   Button,
//   Modal,
//   ModalHeader,
//   ModalBody,
//   ModalFooter,
// } from 'reactstrap';

// import classnames from 'classnames';
import Footer from '../../Component/Footer/Footer';
import global from '../../global';
import { toast } from 'react-toastify';
import Select from 'react-select';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

import img from './upload.jpg';
// import AddImg from './plus.jpg';

import 'react-toastify/dist/ReactToastify.css';
import './style.css';

var images = [];
var seller = {};

var nameError = '';
var Quantityerror='';
var descriptionError = '';
// var CityError = '';
// var ZipCodeError = '';
var imageError = '';

var priceError = '';
var msrpError = '';
var discountError = '';
var discountTypeError = '';
var uploadimages = [];
var upload = [];
var isModelOpen = false;
var description = '';
var descriptionError = '';

var submitDisable = false;
var variationError = '';
var sendVideo = '';
var taxAmountError = '';
var charge = {};
var modal = false;
var modal2 = false;
var isActive = false;
var sendImage = ['', '', '', '', ''];
var variationTypeError = '';
var colorSizeError = '';
var firstCategoryError = '';
var secondCategoryError = '';
var thirdCategoryError = '';
var sellerType = '';
var newProductImage = [];

toast.configure();
const CustomToast = () => {
  return (
    <div>
      <i class="fa fa-check success-toast"></i>
      Product Created Successfully.
    </div>
  );
};

class EditProductFreelisting extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      productName: '',
      Descriptions: [''],
      firstCategory: '',
      secondCategory: '',
      thirdCategory: '',
      Price: '',
      superCategories: [],
      categories: [],
      subCategories: [],
      file: [null],
      seller: '',
      ZipCode: '',
      submit:false,
      bigImage: '',
      conditions: [
        {
          label: 'Select Condition',
          value: '',
        },
        {
          label: 'New',
          value: 'new',
        },
        {
          label: 'Used',
          value: 'used',
        },
        {
          label: 'Refurbish',
          value: 'refurbish',
        },
      ],
      uploadimages: [],
      condition: '',
      productImage: ['', '', '', '', ''],
      // City: '',
      PickUpOnly: '',
      AvailableForShipping: '',
      quantity: '',
      Size: '',
      Color: '',
      responsive: {
        0: {
          items: 2,
        },
        376: {
          items: 2,
        },
        768: {
          items: 4,
        },
        992: {
          items: 4,
        },
        1200: {
          items: 5,
        },
      },
    };
    images = [];
    seller = {};
    nameError = '';
    Quantityerror='';
    // ZipCodeError = '';
    imageError = '';
    priceError = '';
    description = '';
    descriptionError = '';
    // CityError = '';
    submitDisable = false;
    sendImage = ['', '', '', '', ''];
    firstCategoryError = '';
    secondCategoryError = '';
    thirdCategoryError = '';
  }
  //#region  methods
  componentDidMount() {
    if (localStorage.getItem('sellerdata')) {
      seller = JSON.parse(localStorage.getItem('sellerdata'));
      this.setState({ seller: JSON.parse(localStorage.getItem('sellerdata')) });
    }
    this.listAPI();
    this.GetProductIdList();
  }

  listAPI() {
    var urlencoded = new URLSearchParams();

    axios
      .post(global.baseUrl + 'SellerAddProductFieldList', urlencoded, {
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      })
      .then((result) => {
        console.log(result.data);
        if (result.data.status) {
          var option = [];
          option.push({
            label: 'Select First Category',
            value: '',
          });
          if (result.data.data.superCategory.length) {
            let temp = result.data.data.superCategory.length++;
            console.log(temp, 'temp');
            for (let i = 0; i < temp; i++) {
              option.push({
                label: result.data.data.superCategory[i].name,
                value: result.data.data.superCategory[i].id,
              });
            }
            // console.log(option, "dfdsf")
          }
          option.sort((a, b) => a.label.localeCompare(b.label));
          this.setState({
            superCategories: option,
          });
        }
      });
  }
  productApiCall = () => {
    console.log('Free add product');
    console.log(newProductImage, 'donedone product2');
    var urlencoded = new URLSearchParams();

    // newProductImage.push(element);
    // var urlencoded = new URLSearchParams();
    urlencoded.append('id', this.props.match.params.id);
    urlencoded.append('sellerId', this.state.seller.id);
    urlencoded.append('product_name', this.state.productName);
    urlencoded.append('super_categoryId', this.state.firstCategory);
    urlencoded.append('categoryId', this.state.secondCategory);
    urlencoded.append('sub_categoryId', this.state.thirdCategory);
    urlencoded.append('quantity', this.state.quantity);
    urlencoded.append('condition', this.state.condition);
    // urlencoded.append("size", this.state.Size);
    // urlencoded.append("color", this.state.Color);
    // urlencoded.append('city', this.state.City);
    // urlencoded.append('zipCode', this.state.ZipCode);
    urlencoded.append('price', this.state.Price);
    urlencoded.append(
      'available_for_shipping',
      this.state.AvailableForShipping
    );
    console.log(JSON.stringify(this.state.productImage), 'checkimage2');
    urlencoded.append('pick_up_only', this.state.PickUpOnly);
    console.log(JSON.stringify(newProductImage), 'checkimage');
    urlencoded.append('image', JSON.stringify(newProductImage));

    // for (let i = 0; i < this.state.Descriptions.length; i++) {
    //     if (this.state.Descriptions[i] !== "") {
    urlencoded.append('product_description', this.state.Descriptions);
    //     }
    // }
    // if (this.state.productImage.length > 0) {
    //     for (let i = 0; i < this.state.productImage.length; i++) {
    //         if (this.state.productImage[i] !== "") {
    //             urlencoded.append("image", this.state.productImage);
    //         }
    //     }
    // } else {
    //     urlencoded.append("image", []);

    // }

    axios
      .post(global.baseUrl + 'sellerProductFreelistingUpdate', urlencoded, {
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      })
      .then((result) => {
        console.log(result.data);
        if (result.data) {
          // toast.success(<CustomToast />, {
          //     position: toast.POSITION.TOP_RIGHT
          // })

          $('#pills-profile').removeClass('active show');
          $('#pills-contact').addClass('active show');
          $('#pills-contact-tab').addClass('active');
          $('#pills-profile-tab').removeClass('active ');
          this.setState({submit:false})
          // this.props.history.push("/productFreelistinglist");
        }
      });
  };

  setbigimage = (image) => {
    this.setState({ bigImage: image });
    this.forceUpdate();
  };
  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };
  firstCategoryDropChangeHandler = (event) => {
    this.setState({
      secondCategory: '',
      thirdCategory: '',
      firstCategory: event.value,
    });
    const apiUrl = global.baseUrl + 'SellerDropCategoryList/' + event.value;
    axios
      .get(apiUrl)

      .then(
        (result) => {
          var option = [];
          for (let i = 0; i < result.data.data.length; i++) {
            option.push({
              label: result.data.data[i].name,
              value: result.data.data[i].id,
            });
          }
          option.sort((a, b) => a.label.localeCompare(b.label));
          this.setState({
            categories: option,
          });
        },
        (error) => {
          this.setState({ error });
        }
      );
  };
  secondCategoryDropChangeHandler = (event) => {
    this.setState({
      secondCategory: event.value,
      thirdCategory: '',
    });
    const apiUrl = global.baseUrl + 'SellerSubCategoryList/' + event.value;
    axios.get(apiUrl).then(
      (result) => {
        var option = [];
        for (let i = 0; i < result.data.data.length; i++) {
          option.push({
            label: result.data.data[i].name,
            value: result.data.data[i].id,
          });
        }
        option.sort((a, b) => a.label.localeCompare(b.label));
        this.setState({
          subCategories: option,
        });
      },
      (error) => {
        this.setState({ error });
      }
    );
  };
  thirdCategoryDropChangeHandler = (event) => {
    this.setState({
      thirdCategory: event.value,
    });
  };
  conditionDropChangeHandler = (event) => {
    this.setState({
      condition: event.value,
    });
  };
  PickUpOnlyCheck = (e) => {
    if (this.state.PickUpOnly === 'PickUpOnly')
      this.setState({ PickUpOnly: '' });
    else this.setState({ PickUpOnly: e.target.value });
  };

  AvailableForShippingCheck = (e) => {
    console.log('availbale', e);
    if (this.state.AvailableForShipping === 'AvailableForShipping')
      this.setState({ AvailableForShipping: '' });
    else this.setState({ AvailableForShipping: e.target.value });
  };
  onImageChange = async (event, index) => {
    console.log(index, 'on image change event');
    let productImage = this.state.productImage;
    console.log(productImage, 'testimage');
    if (event.target.files && event.target.files[0]) {
      var Extension = ['jpg', 'jpeg', 'png', 'gif', 'tiff'];
      var extArray = event.target.files[0].name.split('.');
      var ext = extArray[extArray.length - 1];
      var flag = 0;
      for (let i = 0; i < Extension.length; i++) {
        if (ext.toLowerCase() === Extension[i]) {
          flag = 1;
        }
      }
      if (flag) {
        this.setState({ imagError: '' });
        imageError = '';
        let productImage = this.state.productImage;

        let reader = new FileReader();
        reader.onload = async (e) => {
          productImage[index] = e.target.result;
          console.log(productImage, index);
          await this.setState(
            {
              productImage: productImage,
            },
            () => {
              console.log(productImage, 'check=');
            }
          );
          this.forceUpdate();
          //console.log(this.state.productImage,"check done");
        };

        reader.readAsDataURL(event.target.files[0]);
        sendImage[index] = event.target.files[0];
        //     // sendImage[index] = event.target.result;
        //     console.log(sendImage,"check done");
        //    await this.setState(
        //         {
        //             productImage: sendImage
        //         })

        //     console.log(this.state.productImage,"check done3");
        // this.forceUpdate();
      } else {
        productImage[index] = '';
        sendImage[index] = '';
        this.setState({
          productImage: productImage,
          imagError: 'Please select only image',
          fileName: '',
        });
        imageError = 'Please select only image';
      }
    } else {
    }
  };
  removeImage = async (index, image) => {
    console.log(this.state.productImage, 'faistimagecount');
    let productImage = this.state.productImage;

    if (this.state.productImage.length < 6) {
      if (typeof sendImage[index] === 'string') {
        var urlencoded = new URLSearchParams();

        urlencoded.append('image', sendImage[index]);
        axios
          .post(global.baseUrl + 'imagedelete', urlencoded, {
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
          })
          .then((result) => {
            console.log(result, 'image delete');
          });
        sendImage[index] = '';
      } else {
        sendImage[index] = '';
      }
      productImage[index] = '';
      // sendImage[index] = "";
      // newProductImage.splice(index, 1);
      newProductImage[index] = '';
      await this.setState({ productImage: productImage });
      console.log(this.state.bigImage, '=big');
      console.log(image, '=small');
      console.log(this.state.bigImage === image, 'condition check');
      (await this.state.bigImage) === image && this.setState({ bigImage: '' });
    } else {
      productImage.splice(index, 1);
      sendImage.splice(index, 1);

      await this.setState({
        productImage: productImage,
      });
      console.log(this.state.bigImage === image, 'condition check');
      (await this.state.bigImage) === image && this.setState({ bigImage: '' });
    }
    this.forceUpdate();
    console.log(sendImage, 'lastimagecountsend');
    console.log(this.state.productImage, 'lastimagecount');
  };
  handleColorChange(event, index) {
    console.log(event.hex);
    var tempColor = this.state.Color;

    tempColor = event.hex;
    this.setState({
      Color: tempColor,
    });
    console.log(this.state.Color);
  }
  handleSubmit = (e) => {
    this.setState({submit:true});
    e.preventDefault();
    console.log('working');
    if (this.state.productName === '') {
      this.setState({ nameError: 'Please enter product name' });
      nameError = 'Please enter product name';
    } else if (this.state.productName.trim() === '') {
      this.setState({ nameError: 'Please enter valid product name' });
      nameError = 'Please enter valid product name';
    } else {
      this.setState({ nameError: '' });
      nameError = '';
    }
    if(this.state.quantity==''){
      this.setState({ Quantityerror: '' });
      Quantityerror='Please add quantity'
    }else {
      this.setState({ Quantityerror: '' });
      Quantityerror=''
    }
    if (this.state.Color === '') {
      this.setState({ colorSizeError: 'Please choose the color' });
      colorSizeError = 'Please choose the color';
    } else {
      this.setState({ colorSizeError: '' });
      colorSizeError = '';
    }
    if (this.state.firstCategory === '') {
      this.setState({ firstCategoryError: 'Please select first category ' });
      firstCategoryError = 'Please select first category ';
    } else {
      this.setState({ firstCategoryError: '' });
      firstCategoryError = '';
    }
    // if (this.state.secondCategory === "") {
    //     this.setState({ secondCategoryError: "Please select second category " });
    //     secondCategoryError = "Please select second category ";
    // } else {
    //     this.setState({ secondCategoryError: "" });
    //     secondCategoryError = "";
    // }
    if (this.state.secondCategory === '') {
      this.setState({ secondCategoryError: 'Please select second category ' });
      secondCategoryError = 'Please select second category ';
    } else {
      this.setState({ secondCategoryError: '' });
      secondCategoryError = '';
    }
    // if (this.state.City === '') {
    //   this.setState({ CityError: 'Please enter the city ' });
    //   CityError = 'Please enter the city ';
    // } else {
    //   this.setState({ CityError: '' });
    //   CityError = '';
    // }
    // if (this.state.ZipCode === '') {
    //   this.setState({ ZipCodeError: 'Please enter the zipcode ' });
    //   ZipCodeError = 'Please enter the zipcode ';
    // } else {
    //   this.setState({ ZipCodeError: '' });
    //   ZipCodeError = '';
    // }
    let pointNumberCheck = /^[0-9]\d*(\.\d+)?$/;
    if (this.state.Price === '') {
      this.setState({ priceError: 'Please enter price ' });
      priceError = 'Please enter price ';
    } else if (
      !pointNumberCheck.test(this.state.Price) ||
      parseFloat(this.state.Price) <= 0
    ) {
      this.setState({ priceError: 'Please enter valid price ' });
      priceError = 'Please enter valid price ';
    } else {
      this.setState({ priceError: '' });
      priceError = '';
    }
    let newFlag = 1;
    for (let i = 0; i < this.state.Descriptions.length; i++) {
      if (this.state.Descriptions[i] !== '') {
        if (this.state.Descriptions[i].trim() !== '') {
          newFlag = 0;
        }
      }
    }
    if (newFlag) {
      this.setState({ descriptionError: 'Please enter product description ' });
      descriptionError = 'Please enter  description ';
    } else {
      this.setState({ descriptionError: '' });
      descriptionError = '';
    }
    // console.log(firstCategoryError,)
    if (
      this.state.productName &&
      this.state.Descriptions &&
      this.state.firstCategory &&
      // this.state.City &&
      // this.state.ZipCode &&
      this.state.Price &&
      this.state.quantity!=''&&
      // this.state.secondCategory &&
      firstCategoryError === '' &&
      // colorSizeError === "" &&
      nameError === '' &&
      priceError === '' &&
      descriptionError === ''
    ) {
      console.log('working');
      submitDisable = true;
      this.uploadImage();
    }
  };
  ResetHandler = (e) => {
    e.preventDefault();
    this.setState({
      productName: '',
      Descriptions: '',
      firstCategory: '',
      // City: '',
      // ZipCode: '',
      Price: '',
      secondCategory: '',
      thirdCategory: '',
      Color: '',
      Size: '',
      AvailableForShipping: '',
      PickUpOnly: '',
      condition: '',
      quantity: '',
    });
  };
  async uploadImage() {
    // let newProductImage = [];
    console.log(sendImage, 'firstuplodimagecountsend');
    console.log(this.state.productImage, 'firstuplodimagecount');
    const apiUrl = global.baseUrl + 'sellerImageUpload';
    let i = 0;
    for (i = 0; i < sendImage.length; i++) {
      if (sendImage[i] !== '') {
        // console.log(sendImage[i].includes("http://3.141.68.71:5000"),"includes")
        console.log(typeof sendImage[i], 'includes65');
        if (typeof sendImage[i] === 'string') {
          newProductImage[i] = sendImage[i];
          // newProductImage.push(sendImage[i]);
          newProductImage = [...new Set(newProductImage)];
        } else {
          const formData = new FormData();
          formData.append('myFile', sendImage[i]);
          const config = {
            headers: {
              'content-type': 'multipart/form-data',
            },
          };
          await axios
            .post(apiUrl, formData, config)
            .then((response) => {
              console.log(response.data, 'd');
              newProductImage[i] = response.data.path;
              // newProductImage.push(response.data.path);
              newProductImage = [...new Set(newProductImage)];
              console.log(i, this.state.productImage.length);
              console.log(newProductImage, 'donedone product');
            })
            .catch((error) => {});
        }
        // const formData = new FormData();
        // formData.append("myFile", sendImage[i]);
        // const config = {
        //     headers: {
        //         "content-type": "multipart/form-data"
        //     }
        // };
        // await axios
        //     .post(apiUrl, formData, config)
        //     .then(response => {
        //         console.log(response.data,"d");
        //         newProductImage.push(response.data.path);
        //         console.log(i, this.state.productImage.length);
        //         console.log(newProductImage,"donedone product");
        //     })
        //     .catch(error => { });
      }
    }
    if (i === sendImage.length) {
      console.log('Working', i, sendImage.length);
      this.setState({ productImage: newProductImage }, () => {
        this.setState({submit:false})
        this.productApiCall();
      });
    }
  }
  Next1() {
    $(document).ready(function () {
      $('#next1').click(function () {
        $('#npstep1').hide();
        $('#npstep2').css('display', 'flex');
        $('#pills-home').removeClass('show');
        $('#pills-home').removeClass('active');
        $('#pills-profile').addClass('show');
        $('#pills-profile').addClass('active');
      });
    });
  }
  Next2() {
    $(document).ready(function () {
      $('#next2').click(function () {
        $('#npstep2').hide();
        $('#npstep3').css('display', 'flex');
        $('#pills-profile').removeClass('show');
        $('#pills-profile').removeClass('active');
        $('#pills-contact').addClass('show');
        $('#pills-contact').addClass('active');
      });
    });
  }
  Previous2() {
    $(document).ready(function () {
      $('#pre2').click(function () {
        $('#npstep1').css('display', 'flex');
        $('#npstep2').hide();
        $('#pills-home').addClass('show');
        $('#pills-home').addClass('active');
        $('#pills-profile').removeClass('show');
        $('#pills-profile').removeClass('active');
      });
    });
  }
  Previous3() {
    $(document).ready(function () {
      $('#pre3').click(function () {
        $('#npstep2').css('display', 'flex');
        $('#npstep3').hide();
        $('#pills-profile').addClass('show');
        $('#pills-profile').addClass('active');
        $('#pills-contact').removeClass('show');
        $('#pills-contact').removeClass('active');
      });
    });
  }
  GetProductIdList = () => {
    var urlencoded = new URLSearchParams();
    urlencoded.append('id', this.props.match.params.id);
    // urlencoded.append("sortType", this.state.sortType);

    axios
      .post(global.baseUrl + 'sellerproductIdlist', urlencoded, {
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      })
      .then(async (result) => {
        // console.log("Working", result.data)

        if (result.data.data) {
          if (result.data.data.super_categoryId) {
            let data = { value: result.data.data.super_categoryId.id };
            this.firstCategoryDropChangeHandler(data);
          }
          if (result.data.data.categoryId) {
            let data = { value: result.data.data.categoryId.id };
            this.secondCategoryDropChangeHandler(data);
          }
          this.setState({
            productName: result.data.data.product_name,
            firstCategory: result.data.data.super_categoryId.id,
            secondCategory: result.data.data.categoryId
              ? result.data.data.categoryId.id
              : '',
            thirdCategory: result.data.data.sub_categoryId
              ? result.data.data.sub_categoryId.id
              : '',
            quantity: result.data.data.quantity,
            condition: result.data.data.condition,
            // City: result.data.data.city,
            // ZipCode: result.data.data.zipCode,
            Price: result.data.data.price,
            AvailableForShipping: result.data.data.available_for_shipping,
            PickUpOnly: result.data.data.pick_up_only,
            Descriptions: result.data.data.product_description[0],
            // newProductImage:JSON.parse(result.data.data.image),
            // Descriptions:result.data.data,
            // Descriptions:result.data.data,
            // allProduct: result.data.data,
            // postData: [],
            // gridData: []
          });
          let productImage = this.state.productImage;
          await result.data.data.image.map((element, index) => {
            productImage[index] = element;
            sendImage[index] = element;
            console.log(sendImage, 'checksendindex');
            this.setState({ productImage: productImage });
          });
          // this.setState({productImage:newProductImage});
          console.log('Working', result.data);
          console.log(this.state.productImage, 'testprogductname');
          this.forceUpdate();
        }
      });
  };

  //#endregion
  render() {
    return (
      <body
        class="vertical-layout vertical-menu-modern semi-dark-layout 2-columns  navbar-floating footer-static  "
        data-open="click"
        data-menu="vertical-menu-modern"
        data-col="2-columns"
        data-layout="semi-dark-layout"
      >
        <div class="app-content content">
          <div class="content-overlay"></div>
          <div class="header-navbar-shadow"></div>

          <div class="content-wrapper">
            <div class="content-header row">
              <div class="content-header-left col-md-9 col-12 mb-2">
                <div class="row breadcrumbs-top">
                  <div class="col-12">
                    <h2 class="content-header-title float-left mb-0">
                      <Link
                        to={'/productFreelistinglist'}
                        className="back-arrow  mr-2"
                      >
                        {/* <i className="feather icon-chevron-left"></i> */}
                        <i class="feather icon-arrow-left"></i>
                      </Link>
                      Edit Product
                    </h2>
                    <div class="breadcrumb-wrapper col-12">
                      <ol class="breadcrumb">
                        <li class="breadcrumb-item">
                          <Link to="/productFreelistinglist">Products</Link>
                        </li>
                        <li class="breadcrumb-item active">Edit Product</li>
                      </ol>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="content-body">
              <div className="card">
                <div class="card-body">
                  <div class="card-content">
                    <div class="tab-content" id="pills-tabContent">
                      <div
                        class="tab-pane fade show active"
                        id="pills-home"
                        role="tabpanel"
                        aria-labelledby="pills-home-tab"
                      >
                        <div class="card-body">
                          <div class="row">
                            <div class="col-md-12 text-center mb-2">
                              <h3 class="">Images</h3>
                            </div>
                            <div class="col-md-12 sa-cameras mb-2">
                              {this.state.bigImage !== '' ? (
                                <img
                                  style={{ height: '180px', width: '180px' }}
                                  src={this.state.bigImage}
                                />
                              ) : (
                                <button
                                  type="button"
                                  class="btn sa-btns"
                                  style={{ position: 'relative' }}
                                >
                                  {/* <i
                                    class="fa fa-camera sa-icons"
                                    aria-hidden="true"
                                  ></i> */}
                                  <img
                                    src="https://api.garagelane.com/upload/1630673075738.png 
                                                                    "
                                  ></img>
                                  {/* <span class="sa-textp1">Take a Photo</span> */}
                                  <div>
                                    <input
                                      type="image"
                                      id="takePhoto"
                                      name="imageUpload"
                                      className="take-photo-file"
                                      // onChange={e =>
                                      //     this.onImageChange(e)
                                      // }
                                    />
                                    {/* <img className="take-photo-file" src={this.state.productImage[0]}/> */}
                                  </div>
                                </button>
                              )}
                            </div>
                          </div>
                          <div class="take-a-photo-main-body">
                            <OwlCarousel
                              className={'owl-theme'}
                              loop={false}
                              margin={10}
                              nav={true}
                              dots={false}
                              autoplay={true}
                              autoplayTimeout={8000}
                              // items={1}
                              responsive={this.state.responsive}
                            >
                              {/* <button className="btn btn-primary">alert</button> */}

                              {this.state.productImage.map((image, index) =>
                                image ? (
                                  <div className="item sr-rel">
                                    <div
                                      class=" image-small-view"
                                      onClick={(e) => {
                                        this.setbigimage(image);
                                      }}
                                    >
                                      <div class="image-inp-div">
                                        <label
                                          class={`cursor-pointer trigger-div disable-box`}
                                          for={index}
                                          style={{
                                            background: 'url(' + image + ')',
                                          }}
                                        ></label>
                                        <input
                                          type="file"
                                          id={index}
                                          name="imageUpload"
                                          onChange={(e) =>
                                            this.onImageChange(e, index)
                                          }
                                          hidden
                                        />
                                        {/* <i
                                          class="fa fa-trash del-img-icon"
                                          onClick={() => {
                                            this.removeImage(index, image);
                                          }}
                                        ></i> */}
                                      </div>
                                    </div>
                                    <i
                                      class="fa fa-trash del-img-icon sr-del-icon"
                                      onClick={() => {
                                        this.removeImage(index, image);
                                      }}
                                    ></i>
                                  </div>
                                ) : (
                                  <div class="image-small-view">
                                    <label
                                      class="cursor-pointer trigger-div"
                                      for={index}
                                      style={{
                                        background: 'url(' + img + ')',
                                      }}
                                    ></label>

                                    <input
                                      type="file"
                                      id={index}
                                      name="imageUpload"
                                      onChange={(e) =>
                                        this.onImageChange(e, index)
                                      }
                                      hidden
                                    />
                                  </div>
                                )
                              )}
                            </OwlCarousel>
                          </div>
                        </div>
                      </div>
                      {/* <!-- step 2 --> */}
                      <div
                        class="tab-pane fade"
                        id="pills-profile"
                        role="tabpanel"
                        aria-labelledby="pills-profile-tab"
                      >
                        {' '}
                        <div class="card-body">
                          <div class="row">
                            <div class="col-md-12 mb-2">
                              <label
                                for="exampleFormControlInput1"
                                class="form-label sa-labels"
                              >
                                Title
                              </label>
                              <input
                                type="text"
                                class="form-control"
                                id="exampleFormControlInput1"
                                autoComplete="off"
                                name="productName"
                                value={this.state.productName}
                                onChange={this.handleChange}
                                placeholder="Product Name"
                              />
                              <p class="error-msg">{nameError}</p>
                            </div>

                            <div class="col-md-12 mb-2">
                              <label
                                for="exampleFormControlTextarea1"
                                class="form-label sa-labels"
                              >
                                Description
                              </label>
                              <textarea
                                class="form-control"
                                id="exampleFormControlTextarea1"
                                name="Descriptions"
                                value={this.state.Descriptions}
                                onChange={this.handleChange}
                                aria-label="Amount"
                              ></textarea>

                              <p class="error-msg">{descriptionError}</p>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-md-4 mb-2">
                              <label
                                for="exampleFormControldrop1"
                                class="form-label"
                              >
                                {' '}
                                Main Category
                              </label>
                              <Select
                                value={this.state.superCategories.filter(
                                  (option) =>
                                    option.value === this.state.firstCategory
                                )}
                                theme={(theme) => ({
                                  ...theme,
                                  borderRadius: '5px',
                                  border: '0px solid',
                                  colors: {
                                    ...theme.colors,
                                    text: 'orangered',
                                    primary25: '#9cf59c',
                                    primary: '#47A547',
                                  },
                                })}
                                options={this.state.superCategories}
                                name="firstCategory"
                                onChange={(val) =>
                                  this.firstCategoryDropChangeHandler(val)
                                }
                              />
                              <p class="error-msg">{firstCategoryError}</p>
                            </div>
                            <div class="col-md-4 mb-2">
                              <label
                                for="exampleFormControldrop2"
                                class="form-label"
                              >
                                Category
                              </label>
                              <Select
                                value={this.state.categories.filter(
                                  (option) =>
                                    option.value === this.state.secondCategory
                                )}
                                theme={(theme) => ({
                                  ...theme,
                                  borderRadius: '5px',
                                  border: '0px solid',
                                  colors: {
                                    ...theme.colors,
                                    text: 'orangered',
                                    primary25: '#9cf59c',
                                    primary: '#47A547',
                                  },
                                })}
                                options={this.state.categories}
                                name="condition"
                                onChange={(val) =>
                                  this.secondCategoryDropChangeHandler(val)
                                }
                              />
                              {/* <p class="error-msg">
                                                            {secondCategoryError}
                                                        </p> */}
                            </div>
                            <div class="col-md-4 mb-2">
                              <label
                                for="exampleFormControldrop3"
                                class="form-label"
                              >
                                Brand
                              </label>
                              <Select
                                value={this.state.subCategories.filter(
                                  (option) =>
                                    option.value === this.state.thirdCategory
                                )}
                                theme={(theme) => ({
                                  ...theme,
                                  borderRadius: '5px',
                                  border: '0px solid',
                                  colors: {
                                    ...theme.colors,
                                    text: 'orangered',
                                    primary25: '#9cf59c',
                                    primary: '#47A547',
                                  },
                                })}
                                options={this.state.subCategories}
                                onChange={(val) =>
                                  this.thirdCategoryDropChangeHandler(val)
                                }
                              />
                            </div>
                            <div class="col-md-6 mb-2">
                              <label
                                for="exampleFormControlInput2"
                                class="form-label"
                              >
                                Quantity
                              </label>
                              <input
                                type="number"
                                class="form-control"
                                id="exampleFormControlInput2"
                                name="quantity"
                                value={this.state.quantity}
                                onChange={this.handleChange}
                              />
                              <p class="error-msg">{Quantityerror}</p>
                            </div>
                            
                            <div class="col-md-6 mb-2">
                              <label
                                for="exampleFormControldrop5"
                                class="form-label"
                              >
                                Condition
                              </label>
                              <Select
                                value={this.state.conditions.filter(
                                  (option) =>
                                    option.value === this.state.condition
                                )}
                                theme={(theme) => ({
                                  ...theme,
                                  borderRadius: '5px',
                                  border: '0px solid',
                                  colors: {
                                    ...theme.colors,
                                    text: 'orangered',
                                    primary25: '#9cf59c',
                                    primary: '#47A547',
                                  },
                                })}
                                options={this.state.conditions}
                                name="condition"
                                onChange={(val) =>
                                  this.conditionDropChangeHandler(val)
                                }
                              />
                            </div>
                            {/* <div class="col-md-4 mb-2">
                                                        <label for="exampleFormControlInput3" class="form-label">Size</label>
                                                        <input
                                                            type="number"
                                                            class="form-control"
                                                            id="exampleFormControlInput3"
                                                            name="Size"
                                                            value={this.state.Size}
                                                            onChange={this.handleChange}
                                                        />
                                                    </div> */}
                          </div>
                          {/* <div class="row">
                            <div class="col-md-2 mb-2">
                                                        <label for="exampleFormControldrop6" class="form-label">Color</label>
                                                        <InputColor
                                                            initialValue="#000000"
                                                            // placement="right"
                                                            value={
                                                                this.state.Color
                                                            }
                                                            onChange={e =>
                                                                this.handleColorChange(e)
                                                            }
                                                            style={{ width: "100%" }}
                                                        />
                                                        <p class="error-msg">
                                                            {colorSizeError}
                                                        </p>
                                                    </div>
                            <div class="col-md-6 sa-citys mb-2">
                              <label
                                for="exampleFormControlInput4"
                                class="form-label"
                              >
                                City
                              </label>
                              <input
                                type="text"
                                class="form-control sa-size-input"
                                id="exampleFormControlInput4"
                                name="City"
                                value={this.state.City}
                                onChange={this.handleChange}
                                placeholder="Enter City Name"
                              />
                              <p class="error-msg">{CityError}</p>
                            </div>
                            <div class="col-md-6 sa-zips mb-2 mr-0">
                              <label
                                for="exampleFormControlInput5"
                                class="form-label sa-zip-text"
                              >
                                Zip Code
                              </label>
                              <input
                                type="text"
                                class="form-control"
                                id="exampleFormControlInput5"
                                name="ZipCode"
                                value={this.state.ZipCode}
                                onChange={this.handleChange}
                                aria-label="Amount"
                              />
                              <p class="error-msg">{ZipCodeError}</p>
                            </div>
                          </div> */}
                          <div class="row">
                            <div class="col-md-6 mb-2">
                              <label
                                for="exampleFormControlInput5"
                                class="form-label"
                              >
                                Price
                              </label>
                              <input
                                type="number"
                                class="form-control"
                                id="exampleFormControlInput5"
                                name="Price"
                                autoComplete="off"
                                value={this.state.Price}
                                onChange={this.handleChange}
                                placeholder="Default Price"
                              />
                              <p class="error-msg">{priceError}</p>
                            </div>
                            <div class="col-md-6 sa-chechboxs mb-2">
                              <div>
                                <input
                                  type="checkbox"
                                  name="AvailableForShipping"
                                  style={{
                                    height: '13px',
                                    width: '13px',
                                    margin: '5px',
                                  }}
                                  value="AvailableForShipping"
                                  onChange={(e) =>
                                    this.AvailableForShippingCheck(e)
                                  }
                                  checked={
                                    this.state.AvailableForShipping ===
                                    'AvailableForShipping'
                                  }
                                />
                                <label class="sa-txt-check">
                                  Available for shipping
                                </label>
                              </div>
                              <div>
                                <input
                                  type="checkbox"
                                  name="PickUpOnly"
                                  style={{
                                    height: '13px',
                                    width: '13px',
                                    margin: '5px',
                                  }}
                                  value="PickUpOnly"
                                  onChange={(e) => this.PickUpOnlyCheck(e)}
                                  checked={
                                    this.state.PickUpOnly === 'PickUpOnly'
                                  }
                                />
                                <label class="sa-txt-check">Pick up only</label>
                              </div>
                            </div>
                          </div>
                          <div>
                            <button
                              class="btn btn-primary-green mr-2 p-1 "
                              type="button"
                              onClick={this.handleSubmit}
                              disabled={this.state.submit}
                            >
                              Submit
                            </button>

                            {/* <a class="btn btn-primary-green mr-2 p-1 " id="pills-contact-tab"
                                                        data-toggle="pill"
                                                        href="#pills-contact"
                                                        role="tab"
                                                        aria-controls="pills-contact" aria-selected="false"
                                                        onClick={this.handleSubmit}>Submit </a>
                                                 
                                                  */}
                            <button
                              class="btn btn-primary-green p-1 "
                              type="button"
                              onClick={this.ResetHandler}
                            >
                              Reset
                            </button>
                          </div>
                        </div>
                      </div>

                      <div
                        class="tab-pane fade "
                        id="pills-contact"
                        role="tabpanel"
                        aria-labelledby="pills-contact-tab"
                      >
                        <div class="mt-3 mb-3  text-center">
                          <p style={{ fontSize: '50px' }}>
                            <i className="fa fa-check"></i>
                          </p>
                          <p>Your product has been created Successfully!</p>
                          <button className="btn btn-primary-green  p-1 ">
                            {' '}
                            <Link
                              to={'/productFreelistinglist'}
                              style={{ color: '#ffffff' }}
                            >
                              Go to Product page
                            </Link>
                          </button>
                        </div>
                      </div>
                    </div>
                    {/* <ul class="nav nav-tabs mb-3 d-flex justify-content-center" id="pills-tab" role="tablist">
                                            <li class="nav-item">	<a class="nav-link active" id="pills-home-tab" data-toggle="pill" href="#pills-home" role="tab" aria-controls="pills-home" aria-selected="true">STEP 1<i class="fa fa-camera sa-icons-list" aria-hidden="true"></i></a>
                                            </li>
                                            <li class="nav-item">	<a class="nav-link" id="pills-profile-tab" data-toggle="pill" href="#pills-profile" role="tab" aria-controls="pills-profile" aria-selected="false">STEP 2<i class="fa fa-list-alt sa-icons-list" aria-hidden="true"></i></a>
                                            </li>
                                            <li class="nav-item">	<a class="nav-link" id="pills-contact-tab" href="javascript:void(0)" >STEP 3<i class="fa fa-check-circle-o sa-icons-list" aria-hidden="true"></i></a>
                                            </li>
                                        </ul> */}
                    <div className="row pre-next-row" id="npstep1">
                      <div className="col-6"></div>
                      <div className="col-6 text-right">
                        <button
                          id="next1"
                          className="btn btn-primary add-product-link"
                          onClick={this.Next1()}
                        >
                          Next
                        </button>
                      </div>
                    </div>

                    <div className="row pre-next-row" id="npstep2">
                      <div className="col-6">
                        <button
                          id="pre2"
                          className="btn btn-primary add-product-link"
                          onClick={this.Previous2()}
                        >
                          Previous
                        </button>
                      </div>
                      {/* <div className="col-6 text-right">
                        <button
                          id="next2"
                          className="btn btn-primary add-product-link"
                          onClick={this.Next2()}
                        >
                          Next
                        </button>
                      </div> */}
                    </div>

                    <div className="row pre-next-row" id="npstep3">
                      <div className="col-6">
                        <button
                          id="pre3"
                          className="btn btn-primary add-product-link"
                          onClick={this.Previous3()}
                        >
                          Previous
                        </button>
                      </div>
                      <div className="col-6 text-right"></div>
                    </div>
                    {/* <!-- end second --> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="sidenav-overlay"></div>
        <div class="drag-target"></div>
        <Footer />
      </body>
    );
  }
}
export default EditProductFreelisting;
