import { Box, Button, Modal } from "@material-ui/core";

import React, { useState } from "react";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import Url from "../../global";
import upload from "./upload.jpg";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  // border: "2px solid #000",
  boxShadow: 24,
  py: 4,
};

function CropSelectedImage({ parentCallback}) {
  const [src, setSrc] = useState(null);
  const [crop, setCrop] = useState({ aspect: 16 / 9 });
  const [image, setImage] = useState("");
  const [output, setOutput] = useState(null);
  const [productImage, setProductImage] = useState([]);

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const selectImage = (file) => {
    setSrc(URL.createObjectURL(file));
    setProductImage(URL.createObjectURL(file));
    setImage(file);
  };

  function sendimagedata(output) {
    return output;
  }

  const cropImageNow = () => {
    const canvas = document.createElement("canvas");
    const image = document.getElementById("source");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext("2d");

    const pixelRatio = window.devicePixelRatio;
    canvas.width = crop.width * pixelRatio;
    canvas.height = crop.height * pixelRatio;
    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = "high";
    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    // Converting to base64
    const base64Image = canvas.toDataURL("image/jpeg");

    // const byteNumbers = new Array(base64Image.length);

    // for (let i = 0; i < base64Image.length; i++) {
    //   byteNumbers[i] = base64Image.charCodeAt(i);

    // }
    // const byteArray = new Uint8Array(byteNumbers);

    // const blob = new Blob([byteArray], {type: "image/jpeg"});

    // const blobUrl = URL.createObjectURL(blob);
    // console.log(blobUrl, "kk12")

    setOutput(base64Image);
    parentCallback({base64Image});
    handleClose(true);
  };

  return (
    <>
      <div className="App">
        <center>
          <label
            class="cursor-pointer trigger-div"
            style={{
              width: "100px",
              height: "100px",
              background: "url(" + `${output ? output : upload}` + ")",
            }}
          >
            <input
              type="file"
              accept="image/*"
              onChange={(e) => {
                selectImage(e.target.files[0]);
                handleOpen();
              }}
              hidden
            />
          </label>
          <br />
          <br />
          <div>
            
            <Modal
              open={open}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box style={style}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {src && (
                    <div>
                      <ReactCrop
                        src={src}
                        onImageLoaded={setImage}
                        crop={crop}
                        onChange={setCrop}
                      >
                        <img
                          id="source"
                          src={src}
                          width="300px"
                          height="300px"
                          alt=""
                        />
                      </ReactCrop>
                      <br />
                      <Box sx={{ display: "flex", justifyContent: "center" }}>
                        <Button
                          color="secondary"
                          variant="contained"
                          onClick={() => cropImageNow()}
                        >
                          Crop
                        </Button>
                      </Box>
                      <br />
                      <br />
                    </div>
                  )}
                </Box>
              </Box>
            </Modal>
          </div>
        </center>
      </div>
    </>
  );
}

export default CropSelectedImage;
