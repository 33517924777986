import React from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import moment from "moment";
import LoadingOverlay from "react-loading-overlay";
//import scriptLoader from 'react-async-script-loader';
//import PropTypes from 'prop-types';
// import Sidebar from "../../Component/Sidebar/Sidebar";
// import Header from "../../Component/Header/Header";
import Footer from "../../Component/Footer/Footer";
import global from "../../global";
import Paypal from "./PaypalBtn";
import StripeCheckout from "react-stripe-checkout";

import "./style.css";

const CURRENCY = "USD";

const fromDollarToCent = amount => parseInt(amount * 100);

var seller = "";
var payDisable = true;
var isActive = false;
var isBanner = false;
var bannerMsg = "";
var isOpen = false;
var redirect = false;
class ViewBanner extends React.Component {
  constructor(props) {
    super(props);
    // window.React = React;
    // window.ReactDOM = ReactDOM;
    // this.state = {
    //   showButton: false,
    //   env: 'sandbox', // Or 'sandbox'
    //   client: {
    //     sandbox:    'AfhXynr5d0EW6OygnhXrEz1sAJvOYJynrFiy3gT0vpeuqrZTepqm0Q5jvOWOIHT5dN2ESSs9xwUdELme', // sandbox client ID
    //     production: 'xxxxxxxxx' // production client ID
    //   },
    //   commit: true, // Show a 'Pay Now' button
    // };
    this.state = {
      id: this.props.match.params.id,
      banner: {},
      bannerTotal: 0,
      paymentMethod: "credit_debit",
      seller: "",
      redirect: false
    };
    bannerMsg = "";
    payDisable = true;
    isActive = false;
    isBanner = false;
    isOpen = false;
    redirect = false;
  }
  componentWillMount() {
    if (localStorage.getItem("sellerdata")) {
      seller = JSON.parse(localStorage.getItem("sellerdata"));
      this.setState({ seller: JSON.parse(localStorage.getItem("sellerdata")) });
      //this.dashboardAPI(seller.id);
    }
    var urlencoded = new URLSearchParams();
    urlencoded.append("sellerId", seller.id);
    urlencoded.append("id", this.state.id);

    axios
      .post(global.baseUrl + "SellerGetVideoAds", urlencoded, {
        headers: { "Content-Type": "application/x-www-form-urlencoded" }
      })
      .then(result => {
        console.log(result.data);
        if (result.data.status) {
          isBanner = true;

          this.setState({ banner: result.data.data });

          if (
            result.data.data.ApprovedStatus === "1" &&
            result.data.data.paymentStatus !== "1"
          ) {
            payDisable = false;
            this.setState({ a: "" });
          }
        } else {
          bannerMsg = "No banner available";
          this.setState({ a: "" });
        }
      });
  }
  handleChange = event => {
    this.setState({ paymentMethod: event.target.value });
    console.log(event.target.value);
  };
  onSuccess = data => {
    //isActive=true
    this.setState({ a: "" });
    var urlencoded = new URLSearchParams();
    urlencoded.append("sellerId", seller.id);
    urlencoded.append("videoId", this.state.id);
    urlencoded.append("payment_type", "paypal");
    urlencoded.append("paymentID", data.paymentID);

    axios
      .post(global.baseUrl + "SellerVideoAdsPaypal", urlencoded, {
        headers: { "Content-Type": "application/x-www-form-urlencoded" }
      })
      .then(result => {
        isOpen = true;
        this.id = setTimeout(() => this.setState({ redirect: true }), 1000);
        redirect = true;
        this.setState({ a: "" });

        this.closeHandler();
        // setTimeout(this.donePayment, 2000);
        //  this.id = setTimeout(() => this.setState({ redirect: true }), 1000)
        // window.location.reload()
      });
  };
  componentWillUnmount() {
    // clearTimeout(this.id)
  }
  donePayment = () => {
    clearTimeout(this.id);
  };
  transactionCanceled = () => {
    console.log("transaction cancel");
  };
  transactionError = () => {
    console.log("transaction error");
  };
  onToken = (amount, description, bannerId) => token => {
    isActive = true;
    this.setState({ a: "" });
    let urlencoded = new URLSearchParams();
    console.log(token, "token");
    urlencoded.append("email", token.email);
    urlencoded.append("token", JSON.stringify(token));
    urlencoded.append("description", description);
    urlencoded.append("source", token.id);
    urlencoded.append("amount", parseInt(amount * 100));
    urlencoded.append("currency", CURRENCY);
    urlencoded.append("cardName", token.card.name);
    urlencoded.append("sellerId", seller.id);
    urlencoded.append("videoId", bannerId);
    urlencoded.append("payment_type", "credit_debit");
    axios
      .post(global.baseUrl + "SellerVideoAdsStripePayment", urlencoded)
      .then(result => {
        console.log(result);

        if (result.data.status) {
          isActive = false;
          this.setState({ a: "" });
          this.props.history.push("/paymentdone");
        }
        //successPayment();
      })
      .catch();
  };
  closeHandler = () => {
    document.getElementById("success").style.display = "none";
    for (
      let i = 0;
      i < document.getElementsByClassName("modal-backdrop").length;
      i++
    ) {
      document.getElementsByClassName("modal-backdrop")[i].style.zIndex = "-1";
      document.getElementsByClassName("modal-backdrop")[i].style.width = "0px";
    }
    document.getElementsByClassName("modal-open")[0].style.padding = "0px";
    document.getElementsByTagName("body")[0].classList.remove("modal-open");

    this.props.history.push("/paymentdone");
    //  setTimeout(function() { document.getElementsByClassName('modal').display="none" }, 4000);
  };
  render() {
    return (
      <body
        class="vertical-layout vertical-menu-modern semi-dark-layout 2-columns  navbar-floating footer-static  "
        data-open="click"
        data-menu="vertical-menu-modern"
        data-col="2-columns"
        data-layout="semi-dark-layout"
      >
        <LoadingOverlay
          active={isActive}
          data-dismiss={isActive ? "modal" : ""}
          spinner
          text="Please wait we are processing your payment"
        ></LoadingOverlay>
        {isBanner ? (
          <div class="app-content content">
            <div class="content-overlay"></div>
            <div class="header-navbar-shadow"></div>
            <div class="content-wrapper">
              <div class="content-header-left col-md-9 col-12 mb-2">
                <div class="row breadcrumbs-top">
                  <div class="col-12">
                    <h2 class="content-header-title float-left mb-0">
                      View Ads
                    </h2>
                    <div class="breadcrumb-wrapper col-12">
                      <ol class="breadcrumb">
                        <li class="breadcrumb-item">
                          <Link to="/adslist">Live stream Ads</Link>
                        </li>
                        <li class="breadcrumb-item active">View Ads</li>
                      </ol>
                    </div>
                  </div>
                </div>
              </div>
              <div class="content-body">
                <section id="dashboard-analytics">
                  <div class="card">
                    <div class="card-body">
                      <div
                        class="tab-pane active"
                        id="account"
                        aria-labelledby="account-tab"
                        role="tabpanel"
                      >
                        <div class="col-lg-11 col-12 pb-2">
                          <label className="b-label">Banner Detail </label>
                          <p>
                            {" "}
                            (Your Live stream ads will not run until they are reviewed
                            by GaragaLane.We review most ads within 3 business
                            days)
                          </p>
                        </div>

                        <div class="row mb-1">
                          <div class="col-lg-11 col-12">
                            <div className="table-overflow">
                            <table class="table table-bordered">
                              <thead>
                                <tr>
                                  <th>Duration</th>
                                  <th>Ads Type</th>
                                  <th>Type</th>
                                  <th>Category</th>
                                  <th>Budget</th>
                                  <th>Status</th>
                                  <th>Reason</th>
                                  <th>View</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td style={{whiteSpace: "nowrap"}}>
                                    {moment(
                                      this.state.banner.start_date
                                    ).format("DD-MM-YYYY")}{" "}
                                    to{" "}
                                    {moment(this.state.banner.end_date).format(
                                      "DD-MM-YYYY"
                                    )}
                                  </td>
                                  <td>{this.state.banner.ads_type}</td>
                                  <td>{this.state.banner.date_type}</td>
                                  <td>
                                    {this.state.banner.page_category
                                      ? this.state.banner.page_category.name
                                      : null}
                                  </td>
                                  <td>${this.state.banner.budget}</td>
                                  <td>
                                    {this.state.banner.ApprovedStatus ===
                                    "1" ? (
                                      <b>Approved</b>
                                    ) : this.state.banner.ApprovedStatus ===
                                      "0" ? (
                                      <b>Denied</b>
                                    ) : (
                                      <b>Pending</b>
                                    )}
                                  </td>
                                  <td>{this.state.banner.reason}</td>
                                  <td>
                                    <button
                                      data-toggle="modal"
                                      data-target="#success1"
                                      class="btn btn-primary float-right mr-1 btn-inline waves-effect waves-light login-btn "
                                      style={{ padding: "6px" }}
                                    >
                                      View
                                    </button>
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                    colSpan="4"
                                    style={{ textAlign: "right" }}
                                  >
                                    <span style={{ fontWeight: "bold" }}>
                                      Total
                                    </span>
                                  </td>
                                  <td colSpan={4}>
                                    <b>${this.state.banner.budget}</b>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                            </div>
                          </div>
                        </div>
                        {/*  Pay model*/}
                        <div class="modal-success mr-1 mb-1 d-inline-block ">
                          <div
                            class="modal fade text-left "
                            id="success"
                            tabindex="-1"
                            role="dialog"
                            aria-labelledby="myModalLabel110"
                            aria-hidden="true"
                          >
                            <div
                              class="modal-dialog modal-dialog-centered modal-dialog-scrollable"
                              role="document"
                            >
                              <div class="modal-content vertical-modal">
                                <div class="modal-header bg-success white">
                                  <h5 class="modal-title" id="myModalLabel110">
                                    Select Payment Method
                                  </h5>
                                  <button
                                    type="button"
                                    class="close"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                  >
                                    <span aria-hidden="true">&times;</span>
                                  </button>
                                </div>
                                <div
                                  class="modal-body"
                                  style={{ paddingBottom: "0px" }}
                                >
                                  <ul class="list-unstyled mb-0">
                                    <li>
                                      <div class="vs-radio-con model-resize-div">
                                        <Paypal
                                          totalPay={this.state.banner.budget}
                                          onSuccess={this.onSuccess}
                                          transactionError={
                                            this.transactionError
                                          }
                                          transactionCanceled={
                                            this.transactionCanceled
                                          }
                                          data-dismiss="modal"
                                        />

                                        {/* <span class="vs-radio">
                                          <span class="vs-radio--border"></span>
                                          <span class="vs-radio--circle"></span>
                                        </span>
                                        Credit Debit */}
                                      </div>
                                    </li>
                                    <li>
                                      <fieldset>
                                        <div class="vs-radio-con model-resize-div">
                                          <StripeCheckout
                                            stripeKey="pk_test_51GtSo6BPY6CGrhq8PbbsO8Cd70i2F9wD7u4qk4gwCugvjMakwViTZIfsUVwmnU6qfQacoJUCpaGFSnvognvlK3HI00CIAYrPMY"
                                            token={this.onToken(
                                              this.state.banner.budget,
                                              "Video ads Payment",
                                              this.state.id
                                            )}
                                            name="Garage Lane"
                                            amount={fromDollarToCent(
                                              this.state.bannerTotal
                                            )}
                                            currency={CURRENCY}
                                          >
                                            <button
                                              className="btn btn-success btn-primary resize-credit-debit-btn float-right mr-1 btn-inline waves-effect waves-light pay-btn"
                                              data-dismiss="modal"
                                            >
                                              Credit/Debit Card
                                            </button>
                                          </StripeCheckout>
                                          {/* <span class="vs-radio">
                                          <span class="vs-radio--border"></span>
                                          <span class="vs-radio--circle"></span>
                                        </span>
                                        Credit Debit */}
                                        </div>
                                      </fieldset>
                                    </li>
                                    {/* <li>
                                    
                                    <fieldset>
                                      <div class="vs-radio-con">
                                        <input
                                          type="radio"
                                          value="paypal"
                                          onChange={this.handleChange}
                                          checked={
                                            this.state.paymentMethod ===
                                            "paypal"
                                              ? true
                                              : false
                                          }
                                        />
                                        <span class="vs-radio">
                                          <span class="vs-radio--border"></span>
                                          <span class="vs-radio--circle"></span>
                                        </span>
                                        PayPal
                                      </div>
                                    </fieldset>
                                  </li>
                                  <li>
                                    <fieldset>
                                      <div class="vs-radio-con">
                                        <input
                                          type="radio"
                                          value="venmo"
                                          onChange={this.handleChange}
                                          checked={
                                            this.state.paymentMethod === "venmo"
                                              ? true
                                              : false
                                          }
                                        />
                                        <span class="vs-radio">
                                          <span class="vs-radio--border"></span>
                                          <span class="vs-radio--circle"></span>
                                        </span>
                                        Venmo
                                      </div>
                                    </fieldset>
                                  </li> */}
                                  </ul>
                                  <p class="error-msg"> </p>
                                </div>
                                <div
                                  class="modal-footer modal-resize-footer"
                                  style={{ paddingTop: "0px" }}
                                >
                                  <button
                                    type="button"
                                    class="btn btn-success"
                                    data-dismiss="modal"
                                  >
                                    OK
                                  </button>
                                  <button
                                    type="button"
                                    class="btn btn-success "
                                    data-dismiss="modal"
                                    aria-label="Close"
                                  >
                                    Cancel
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        {/* view model */}
                        <div class="modal-success1 mr-1 mb-1 d-inline-block ">
                          <div
                            class="modal fade text-left "
                            id="success1"
                            tabindex="-1"
                            role="dialog"
                            aria-labelledby="myModalLabel110"
                            aria-hidden="true"
                          >
                            <div
                              class="modal-dialog modal-dialog-centered modal-dialog-scrollable"
                              role="document"
                            >
                              <div class="modal-content vertical-view-model">
                                <div class="modal-header bg-success white">
                                  <h5 class="modal-title" id="myModalLabel110">
                                  Live stream Ads
                                  </h5>
                                  <button
                                    type="button"
                                    class="close"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                  >
                                    <span aria-hidden="true">&times;</span>
                                  </button>
                                </div>
                                <div
                                  class="modal-body view-model-body"
                                  style={{ paddingBottom: "0px" }}
                                >
                                  {this.state.banner.video_file.lastIndexOf(
                                    ".mp4"
                                  ) > 1 ? (
                                    <iframe
                                      width="350"
                                      height="200"
                                      src={this.state.banner.video_file}
                                      frameborder="0"
                                      title=" "
                                      // allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                      allowfullscreen
                                    ></iframe>
                                  ) : (
                                    <img
                                      width="350"
                                      height="200"
                                      src={this.state.banner.video_file}
                                    />
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="row">
                          <div class="col-lg-11 col-md-12 col-12 d-flex flex-sm-row flex-column justify-content-end mt-1">
                            <button
                              type="button"
                              data-toggle="modal"
                              data-target="#success"
                              disabled={payDisable}
                              class="btn btn-primary float-right right-payment btn-inline waves-effect waves-light login-btn "
                            >
                              Payment
                            </button>
                            <button
                              type="reset"
                              onClick={this.resetHandler}
                              class="btn btn-outline-primary float-left btn-inline res-btn"
                            >
                              Reset
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        ) : (
          <h2>{bannerMsg}</h2>
        )}
        <div class="sidenav-overlay"></div>
        <div class="drag-target"></div>
        <Footer />
      </body>
    );
  }
}
export default ViewBanner;
//export default scriptLoader('https://www.paypalobjects.com/api/checkout.js')(PaypalButton);
