import React from 'react';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import global from '../../global';
import { toast } from 'react-toastify';
import '../../../app-assets/vendors/css/vendors.min.css';
//import '../../../app-assets/vendors/css/vendors-rtl.min.css'

import '../../../app-assets/vendors/css/charts/apexcharts.css';
import '../../../app-assets/vendors/css/extensions/tether-theme-arrows.css';
import '../../../app-assets/vendors/css/extensions/tether.min.css';
import '../../../app-assets/vendors/css/extensions/shepherd-theme-default.css';

import '../../../app-assets/css/bootstrap.css';
import '../../../app-assets/css/bootstrap-extended.css';
import '../../../app-assets/css/colors.css';
import '../../../app-assets/css/components.css';
//sssimport "../../../app-assets/css/components.min.css";

import '../../../app-assets/css/themes/dark-layout.css';
import '../../../app-assets/css/themes/semi-dark-layout.css';

import '../../../app-assets/css/core/menu/menu-types/vertical-menu.css';
import '../../../app-assets/css/core/colors/palette-gradient.css';
import '../../../app-assets/css/pages/dashboard-analytics.css';
import '../../../app-assets/css/pages/card-analytics.css';
import '../../../app-assets/css/plugins/tour/tour.css';
import '../../../assets/css/style.css';
import $ from 'jquery';
import axios from 'axios';
//import "../../../app-assets/css/core/menu/menu-types/horizontal-menu.css";
//import "../../../app-assets/css/core/menu/menu-types/horizontal-menu.min.css"
//import "../../../app-assets/css/core/menu/menu-types/vertical-menu.css";
//import "../../../app-assets/css/core/menu/menu-types/vertical-overlay-menu.css";
//import "../../../app-assets/css/core/menu/menu-types/vertical-menu.min.css"
//import "../../../app-assets/css/core/menu/menu-types/vertical-overlay-menu.min.css"
//import "../../../app-assets/vendors/css/vendors.min.css";
toast.configure();
const CustomToast = () => {
  return (
    <div>
      <i class="fa fa-check success-toast"></i>
      You are not allowed to redirect to home page.
    </div>
  );
};
class Sidebar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tooltipOpen: false,
      seller: '',
      dashcount: 0,
    };
  }
  componentWillMount() {
    if (localStorage.getItem('sellerdata')) {
      let seller = JSON.parse(localStorage.getItem('sellerdata'));
      this.setState({ seller: seller.type });
      this.dashboardAPI(seller.id);
      console.log(seller);
    }
  }

  dashboardAPI(Id) {
    var urlencoded = new URLSearchParams();
    urlencoded.append('userId', Id);
    axios
      .post(global.baseUrl + 'sellerdashboard', urlencoded, {
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      })
      .then((result) => {
        // console.log("countorder==",result.data.data.OrderCount[0]._id.count);
        if (result.data > 0) {
          this.setState({
            dashcount: result.data.data.OrderCount[0]._id.count,
          });
          console.log(this.state, 'aaaaa');
        }
      });
  }
  // componentDidUpdate(){
  //   this.setState({a:""})
  // }
  //#region side bar collapse
  sideBarHideCollapse() {
    $(document).ready(function () {
      $('#mobilemenuhidebtn').click(function () {
        $('.main-menu').removeClass('showw');
      });
    });
  }
  ReturnExchangeSideBarClose() {
    $(document).ready(function () {
      $('#returnexchange-modal').click(function () {
        $('.main-menu').removeClass('showw');
      });
    });
  }
  sideBarClose() {
    $(document).ready(function () {
      $('.order-modal').click(function () {
        $('.main-menu').removeClass('showw');
      });
    });
  }
  dashboardSideBarClose() {
    $(document).ready(function () {
      $('.dashboard-modal').click(function () {
        $('.main-menu').removeClass('showw');
      });
    });
  }
  ProductsideBarClose() {
    $(document).ready(function () {
      $('.product-modal').click(function () {
        $('.main-menu').removeClass('showw');
      });
    });
  }
  OfferSideBarClose() {
    $(document).ready(function () {
      $('.offers-modal').click(function () {
        $('.main-menu').removeClass('showw');
      });
    });
  }
  ForumSideBarClose() {
    $(document).ready(function () {
      $('.forum-modal').click(function () {
        $('.main-menu').removeClass('showw');
      });
    });
  }
  LiveStreamSideBarClose() {
    $(document).ready(function () {
      $('.livestream-modal').click(function () {
        $('.main-menu').removeClass('showw');
      });
    });
  }
  ChatSideBarClose() {
    $(document).ready(function () {
      $('.chat-modal').click(function () {
        $('.main-menu').removeClass('showw');
      });
    });
  }
  WalletSideBarClose() {
    $(document).ready(function () {
      $('.wallet-modal').click(function () {
        $('.main-menu').removeClass('showw');
      });
    });
  }
  SaleReportSideBarClose() {
    $(document).ready(function () {
      $('.salesreport-modal').click(function () {
        $('.main-menu').removeClass('showw');
      });
    });
  }
  ReviewSideBarClose() {
    $(document).ready(function () {
      $('.review-modal').click(function () {
        $('.main-menu').removeClass('showw');
      });
    });
  }
  CustomerSideBarClose() {
    $(document).ready(function () {
      $('.customer-modal').click(function () {
        $('.main-menu').removeClass('showw');
      });
    });
  }
  ContactSideBarClose() {
    $(document).ready(function () {
      $('.contact-modal').click(function () {
        $('.main-menu').removeClass('showw');
      });
    });
  }
  AboutSideBarClose() {
    $(document).ready(function () {
      $('.about-modal').click(function () {
        $('.main-menu').removeClass('showw');
      });
    });
  }
  TermSideBarClose() {
    $(document).ready(function () {
      $('.termCondition-modal').click(function () {
        $('.main-menu').removeClass('showw');
      });
    });
  }
  PrivacySideBarClose() {
    $(document).ready(function () {
      $('.privacy-modal').click(function () {
        $('.main-menu').removeClass('showw');
      });
    });
  }
  PaymentPayoutSideBarClose() {
    $(document).ready(function () {
      $('.paymentPayout-modal').click(function () {
        $('.main-menu').removeClass('showw');
      });
    });
  }
  PaymentSettingSideBarClose() {
    $(document).ready(function () {
      $('.paymentSetting-modal').click(function () {
        $('.main-menu').removeClass('showw');
      });
    });
  }
  TransactionSideBarClose() {
    $(document).ready(function () {
      $('.transactionHistory-modal').click(function () {
        $('.main-menu').removeClass('showw');
      });
    });
  }
  DiscountSideBarClose() {
    $(document).ready(function () {
      $('.discount-modal').click(function () {
        $('.main-menu').removeClass('showw');
      });
    });
  }
  SponsorSideBarClose() {
    $(document).ready(function () {
      $('.sponsor-modal').click(function () {
        $('.main-menu').removeClass('showw');
      });
    });
  }
  PayPerClickSideBarClose() {
    $(document).ready(function () {
      $('.payPerClick-modal').click(function () {
        $('.main-menu').removeClass('showw');
      });
    });
  }
  VideoSideBarClose() {
    $(document).ready(function () {
      $('.video-modal').click(function () {
        $('.main-menu').removeClass('showw');
      });
    });
  }
  VideoSideBarCloseNew() {
    $('#fortestid').click(function () {
      toast.success(<CustomToast />, {
        position: toast.POSITION.TOP_RIGHT,
      });
    });
  }

  //#endregion
  render() {
    return (
      <body
        class="vertical-layout vertical-menu-modern semi-dark-layout 2-columns  navbar-floating footer-static  "
        data-open="click"
        data-menu="vertical-menu-modern"
        data-col="2-columns"
        data-layout="semi-dark-layout"
      >
        {/* <ul class="main-search-list-defaultlist d-none">
          <li class="d-flex align-items-center">
            <a class="pb-25" href="#">
              <h6 class="text-primary mb-0">Files</h6>
            </a>
          </li>
          <li class="auto-suggestion d-flex align-items-center cursor-pointer">
            <a
              class="d-flex align-items-center justify-content-between w-100"
              href="#"
            >
              <div class="d-flex">
                <div class="mr-50">
                  <img
                    src="app-assets/images/icons/xls.png"
                    alt="png"
                    height="32"
                  />
                </div>
                <div class="search-data">
                  <p class="search-data-title mb-0">Two new item submitted</p>
                  <small class="text-muted">Marketing Manager</small>
                </div>
              </div>
              <small class="search-data-size mr-50 text-muted">
                &apos;17kb
              </small>
            </a>
          </li>
          <li class="auto-suggestion d-flex align-items-center cursor-pointer">
            <a
              class="d-flex align-items-center justify-content-between w-100"
              href="#"
            >
              <div class="d-flex">
                <div class="mr-50">
                  <img
                    src="app-assets/images/icons/jpg.png"
                    alt="png"
                    height="32"
                  />
                </div>
                <div class="search-data">
                  <p class="search-data-title mb-0">52 JPG file Generated</p>
                  <small class="text-muted">FontEnd Developer</small>
                </div>
              </div>
              <small class="search-data-size mr-50 text-muted">
                &apos;11kb
              </small>
            </a>
          </li>
          <li class="auto-suggestion d-flex align-items-center cursor-pointer">
            <a
              class="d-flex align-items-center justify-content-between w-100"
              href="#"
            >
              <div class="d-flex">
                <div class="mr-50">
                  <img
                    src="app-assets/images/icons/pdf.png"
                    alt="png"
                    height="32"
                  />
                </div>
                <div class="search-data">
                  <p class="search-data-title mb-0">25 PDF File Uploaded</p>
                  <small class="text-muted">Digital Marketing Manager</small>
                </div>
              </div>
              <small class="search-data-size mr-50 text-muted">
                &apos;150kb
              </small>
            </a>
          </li>
          <li class="auto-suggestion d-flex align-items-center cursor-pointer">
            <a
              class="d-flex align-items-center justify-content-between w-100"
              href="#"
            >
              <div class="d-flex">
                <div class="mr-50">
                  <img
                    src="app-assets/images/icons/doc.png"
                    alt="png"
                    height="32"
                  />
                </div>
                <div class="search-data">
                  <p class="search-data-title mb-0">Anna_Strong.doc</p>
                  <small class="text-muted">Web Designer</small>
                </div>
              </div>
              <small class="search-data-size mr-50 text-muted">
                &apos;256kb
              </small>
            </a>
          </li>
          <li class="d-flex align-items-center">
            <a class="pb-25" href="#">
              <h6 class="text-primary mb-0">Members</h6>
            </a>
          </li>
          <li class="auto-suggestion d-flex align-items-center cursor-pointer">
            <a
              class="d-flex align-items-center justify-content-between py-50 w-100"
              href="#"
            >
              <div class="d-flex align-items-center">
                <div class="avatar mr-50">
                  <img
                    src="app-assets/images/portrait/small/avatar-s-8.jpg"
                    alt="png"
                    height="32"
                  />
                </div>
                <div class="search-data">
                  <p class="search-data-title mb-0">John Doe</p>
                  <small class="text-muted">UI designer</small>
                </div>
              </div>
            </a>
          </li>
          <li class="auto-suggestion d-flex align-items-center cursor-pointer">
            <a
              class="d-flex align-items-center justify-content-between py-50 w-100"
              href="#"
            >
              <div class="d-flex align-items-center">
                <div class="avatar mr-50">
                  <img
                    src="app-assets/images/portrait/small/avatar-s-1.jpg"
                    alt="png"
                    height="32"
                  />
                </div>
                <div class="search-data">
                  <p class="search-data-title mb-0">Michal Clark</p>
                  <small class="text-muted">FontEnd Developer</small>
                </div>
              </div>
            </a>
          </li>
          <li class="auto-suggestion d-flex align-items-center cursor-pointer">
            <a
              class="d-flex align-items-center justify-content-between py-50 w-100"
              href="#"
            >
              <div class="d-flex align-items-center">
                <div class="avatar mr-50">
                  <img
                    src="app-assets/images/portrait/small/avatar-s-14.jpg"
                    alt="png"
                    height="32"
                  />
                </div>
                <div class="search-data">
                  <p class="search-data-title mb-0">Milena Gibson</p>
                  <small class="text-muted">Digital Marketing Manager</small>
                </div>
              </div>
            </a>
          </li>
          <li class="auto-suggestion d-flex align-items-center cursor-pointer">
            <a
              class="d-flex align-items-center justify-content-between py-50 w-100"
              href="#"
            >
              <div class="d-flex align-items-center">
                <div class="avatar mr-50">
                  <img
                    src="app-assets/images/portrait/small/avatar-s-6.jpg"
                    alt="png"
                    height="32"
                  />
                </div>
                <div class="search-data">
                  <p class="search-data-title mb-0">Anna Strong</p>
                  <small class="text-muted">Web Designer</small>
                </div>
              </div>
            </a>
          </li>
        </ul>
        <ul class="main-search-list-defaultlist-other-list d-none">
          <li class="auto-suggestion d-flex align-items-center justify-content-between cursor-pointer">
            <a class="d-flex align-items-center justify-content-between w-100 py-50">
              <div class="d-flex justify-content-start">
                <span class="mr-75 feather icon-alert-circle"></span>
                <span>No results found.</span>
              </div>
            </a>
          </li>
        </ul> */}
        <div
          class="main-menu menu-fixed menu-dark menu-accordion menu-shadow"
          style={{ background: '#47a547' }}
          data-scroll-to-active="true"
        >
          <div class="navbar-header">
            <ul class="nav navbar-nav flex-row">
              <li
                class="nav-item mr-auto d-flex align-items-center"
                style={{
                  flex: '1',
                  justifyContent: 'center',
                }}
              >
                <Link to="/dashboard" class="navbar-brand">
                  <div
                    class="brand-logo "
                    style={{
                      width: '100px',
                      height: '64px',
                      objectFit: 'contain',
                    }}
                  ></div>
                </Link>
              </li>
              <li class="nav-item nav-toggle">
                <a
                  id="mobilemenuhidebtn"
                  class="nav-link modern-nav-toggle pr-0"
                  data-toggle="collapse"
                  onClick={this.sideBarHideCollapse()}
                >
                  <i class="feather icon-x d-block d-xl-none font-medium-4 primary toggle-icon"></i>
                  <i
                    class="toggle-icon feather icon-disc font-medium-4 d-none d-xl-block collapse-toggle-icon primary"
                    data-ticon="icon-disc"
                  ></i>
                </a>
              </li>
            </ul>
          </div>
          <div class="main-menu-content" style={{ background: ' #47a547' }}>
            <ul
              class="navigation navigation-main"
              id=""
              style={{ background: ' #47a547', color: '#fff' }}
              data-menu="menu-navigation"
            >
              {this.state.seller === 'Retailer' ? (
                <>
                  <li className="dashboard-modal" style={{ color: '#fff' }}>
                    <Link
                      to="/dashboard"
                      onClick={this.dashboardSideBarClose()}
                    >
                      <i
                        class="feather icon-home"
                        style={{ color: '#fff' }}
                      ></i>
                      <span
                        class="menu-title"
                        data-i18n="Dashboard"
                        style={{ color: '#fff' }}
                      >
                        Dashboard
                      </span>
                      <span
                        class="badge badge badge-warning-custm badge-pill float-right mr-2"
                        style={{ color: '#000000', background: '#fff' }}
                      >
                        {this.state.dashcount}
                      </span>
                    </Link>
                  </li>
                  <li>
                    {/* <a href="http://work-demo.xyz/garage-lan/login" target="_blank"> */}
                    {/* <a href="http://3.141.68.71/#/sellerProfile" target="_blank"> */}
                    <Link to={'/sellerProfile'}>
                      <i class="feather icon-home"></i>
                      <span class="menu-item" data-i18n="Analytics">
                        My Store
                      </span>
                    </Link>
                    {/* </a> */}
                  </li>
                  <li className="order-modal">
                    <a href="https://garagelane.com/" className="home-show">
                      <i class="feather icon-shopping-bag"></i>
                      <span class="menu-item" data-i18n="Analytics">
                        {/* Buyer Panel */}
                        Home
                      </span>
                    </a>
                    {/* <Link
                      to={{ pathname: '/dashboard', hash: '1' }}
                      className="mobile-hide"
                      onClick={this.sideBarClose()}
                    >
                      <i class="feather icon-shopping-bag"></i>
                      <span class="menu-item" data-i18n="Analytics">
                        
                        Home
                      </span>
                    </Link> */}
                  </li>
                  <li className="order-modal">
                    <Link to="/orderlist" onClick={this.sideBarClose()}>
                      <i class="feather icon-shopping-bag"></i>
                      <span class="menu-item" data-i18n="Analytics">
                        Orders
                      </span>
                    </Link>
                  </li>
                  <li className="product-modal">
                    <Link
                      to="/productlist"
                      onClick={this.ProductsideBarClose()}
                    >
                      <i class="feather icon-shopping-bag"></i>
                      <span class="menu-item" data-i18n="Analytics">
                        Products
                      </span>
                    </Link>
                  </li>
                  <li className="offers-modal">
                    <Link to="/offerlist" onClick={this.OfferSideBarClose()}>
                      <i class="feather icon-shopping-bag"></i>
                      <span class="menu-item" data-i18n="Analytics">
                        Offers
                      </span>
                    </Link>
                  </li>
                  <li className="forum-modal">
                    <Link to="/sellerforum" onClick={this.ForumSideBarClose()}>
                      <i class="fa fa-edit"></i>
                      <span class="menu-item" data-i18n="Analytics">
                        Forum
                      </span>
                    </Link>
                  </li>
                  <li className="livestream-modal">
                    <Link
                      to="/liveStream"
                      onClick={this.LiveStreamSideBarClose()}
                    >
                      <i class="fas fa-camera"></i>
                      <span class="menu-item" data-i18n="Analytics">
                        Live Stream
                      </span>
                    </Link>
                  </li>
                  <li class="wallet-modal">
                    <Link
                      to="/sellerwallet"
                      onClick={this.WalletSideBarClose()}
                    >
                      <i class="fa fa-money"></i>
                      <span class="menu-item" data-i18n="Analytics">
                        Wallet
                      </span>
                    </Link>
                  </li>
                  {/* <li class="">
                <a href="">
                  <i class="feather icon-users"></i>
                  <span class="menu-item" data-i18n="Analytics">
                    Customers
                  </span>
                </a>
              </li> */}
                  <li class="chat-modal">
                    <Link to="/chat" onClick={this.ChatSideBarClose()}>
                      <i class="fa fa-line-chart"></i>
                      <span class="menu-item" data-i18n="Analytics">
                        Chat With Buyer
                      </span>
                    </Link>
                  </li>
                  <li class="chat-modal">
                    <Link to="/questionlist" onClick={this.ChatSideBarClose()}>
                      <i class="fa fa-line-chart"></i>
                      <span class="menu-item" data-i18n="Analytics">
                        Question
                      </span>
                    </Link>
                  </li>
                  <li className="salesreport-modal">
                    <Link
                      to="/salesReport"
                      onClick={this.SaleReportSideBarClose()}
                    >
                      <i class="feather icon-circle"></i>
                      <span class="menu-item" data-i18n="Shop">
                        Sales Report
                      </span>
                    </Link>
                  </li>
                  <li className="returnexchange-modal">
                    <Link
                      to="/returnexchange"
                      onClick={this.ReturnExchangeSideBarClose()}
                    >
                      <i class="feather icon-circle"></i>
                      <span class="menu-item" data-i18n="Shop">
                        Return Exchange
                      </span>
                    </Link>
                  </li>
                  {/* <li>
                  <Link to="/contactList">
                    <i class="feather icon-shopping-bag"></i>
                    <span class="menu-item" data-i18n="Analytics">
                      Contacts
                  </span>
                  </Link>
                </li> */}
                  {/* <li>
                  <Link to="/reviewlist">
                    <i class="feather icon-shopping-bag"></i>
                    <span class="menu-item" data-i18n="Analytics">
                      Reviews
                  </span>
                  </Link>
                </li> */}
                  <li class="navigation-header navigation-header-custm">
                    <span></span>
                  </li>
                  <li class=" nav-item">
                    <a>
                      <i class="feather icon-tv"></i>
                      <span class="menu-title" data-i18n="Ecommerce">
                        Promote{' '}
                      </span>
                    </a>
                    <ul class="menu-content">
                      <li className="discount-modal">
                        <Link
                          to="/discount"
                          onClick={this.DiscountSideBarClose()}
                        >
                          <i class="feather icon-shopping-bag"></i>
                          <span class="menu-item" data-i18n="Analytics">
                            Discount
                          </span>
                        </Link>
                      </li>
                      {/* <li class=" nav-item">
                  <a href="">
                    <i class="feather icon-tag"></i>
                    <span class="menu-title" data-i18n="Email">
                      Discounts
                  </span>
                  </a>
                </li> */}
                      <li className="discount-modal">
                        <Link
                          to="/bannerlist"
                          onClick={this.SponsorSideBarClose()}
                        >
                          <i class="feather icon-circle"></i>
                          <span class="menu-item" data-i18n="Shop">
                            Sponsor Advertise
                          </span>
                        </Link>
                      </li>
                      {/* <li className="payPerClick-modal">
                        <Link to="/payperclickbannerlist" onClick={this.PayPerClickSideBarClose()}>
                          <i class="feather icon-circle"></i>
                          <span class="menu-item" data-i18n="Details" style={{ fontSize: "15px" }}>
                            PayPerClick Manager
                          </span>
                        </Link>
                      </li> */}
                      <li className="video-modal">
                        <Link to="/adslist" onClick={this.VideoSideBarClose()}>
                          <i class="feather icon-circle"></i>
                          <span class="menu-item" data-i18n="Shop">
                            Live Stream Advertise
                          </span>
                        </Link>
                      </li>
                    </ul>
                  </li>

                  <li className="review-modal">
                    <Link to="/reviews" onClick={this.ReviewSideBarClose()}>
                      <i class="feather icon-star"></i>
                      <span class="menu-title" data-i18n="Chat">
                        Reviews
                      </span>
                    </Link>
                  </li>

                  <li class=" nav-item">
                    <a>
                      <i class="fa fa-money"></i>
                      <span class="menu-title" data-i18n="Ecommerce">
                        Finances{' '}
                      </span>
                    </a>
                    <ul class="menu-content">
                      <li className="paymentPayout-modal">
                        <Link
                          to="/paymentpayout"
                          onClick={this.PaymentPayoutSideBarClose()}
                        >
                          <i class="feather icon-circle"></i>
                          <span class="menu-item" data-i18n="Shop">
                            Payout & Payout History
                          </span>
                        </Link>
                      </li>
                      {this.state.seller === 'Retailer' ? (
                        <li className="paymentSetting-modal">
                          <Link
                            to="/information"
                            onClick={this.PaymentSettingSideBarClose()}
                          >
                            <i class="fa fa-edit"></i>
                            <span class="menu-item" data-i18n="Analytics">
                              Seller & Payment Info
                            </span>
                          </Link>
                        </li>
                      ) : null}
                      <li className="transactionHistory-modal">
                        <Link
                          to="/allTransactionhistory"
                          onClick={this.TransactionSideBarClose()}
                        >
                          <i class="feather icon-circle"></i>
                          <span class="menu-item" data-i18n="Shop">
                            Transaction History
                          </span>
                        </Link>
                      </li>
                    </ul>
                  </li>

                  <li class="navigation-header navigation-header-custm">
                    <span></span>
                  </li>
                  <li class=" nav-item">
                    <a>
                      <i class="feather icon-settings"></i>
                      <span class="menu-title" data-i18n="Ecommerce">
                        Settings{' '}
                      </span>
                    </a>
                    <ul class="menu-content">
                      <li className="contact-modal">
                        <Link
                          to="/contact-us"
                          onClick={this.ContactSideBarClose()}
                        >
                          <i class="feather icon-circle"></i>
                          <span class="menu-item" data-i18n="Shop">
                            Contact us
                          </span>
                        </Link>
                      </li>
                      <li className="about-modal">
                        <Link to="/about-us" onClick={this.AboutSideBarClose()}>
                          <i class="feather icon-circle"></i>
                          <span class="menu-item" data-i18n="Details">
                            About us
                          </span>
                        </Link>
                      </li>
                      <li className="termCondition-modal">
                        <Link
                          to="/term-and-condition"
                          onClick={this.TermSideBarClose()}
                        >
                          <i class="feather icon-circle"></i>
                          <span class="menu-item" data-i18n="Wish List">
                            Terms & condition
                          </span>
                        </Link>
                      </li>
                      <li className="privacy-modal">
                        <Link
                          to="/data-policy"
                          onClick={this.PrivacySideBarClose()}
                        >
                          <i class="feather icon-circle"></i>
                          <span class="menu-item" data-i18n="Checkout">
                            Privacy Policy
                          </span>
                        </Link>
                      </li>
                    </ul>
                  </li>
                </>
              ) : (
                <>
                  <li className="dashboard-modal">
                    <Link
                      to="/dashboard"
                      onClick={this.dashboardSideBarClose()}
                    >
                      <i class="feather icon-home"></i>
                      <span class="menu-title" data-i18n="Dashboard">
                        Dashboard
                      </span>
                      <span class="badge badge badge-warning-custm badge-pill float-right mr-2">
                        {this.state.dashcount}
                      </span>
                    </Link>
                  </li>
                  <li>
                    {/* <a href="http://3.141.68.71/#/sellerProfile" target="_blank">
                      <i class="feather icon-home"></i>
                      <span class="menu-item" data-i18n="Analytics">
                        My Store
                      </span>
                    </a> */}
                    <Link to={'/sellerProfile'}>
                      <i class="feather icon-home"></i>
                      <span class="menu-item" data-i18n="Analytics">
                        My Store
                      </span>
                    </Link>
                  </li>
                  <li className="order-modal">
                    {/* <a href="http://3.140.250.189/">
                      <i class="feather icon-shopping-bag"></i>
                      <span class="menu-item" data-i18n="Analytics">
                        
                        Home
                      </span>
                    </a> */}
                    <a href="https://garagelane.com/" className="home-show">
                      <i class="feather icon-shopping-bag"></i>
                      <span class="menu-item" data-i18n="Analytics">
                        {/* Buyer Panel */}
                        Home
                      </span>
                    </a>
                    {/* <Link
                      to={{ pathname: '/dashboard', hash: '1' }}
                      className="mobile-hide"
                      onClick={this.sideBarClose()}
                    >
                      <i class="feather icon-shopping-bag"></i>
                      <span class="menu-item" data-i18n="Analytics">
                        
                        Home1
                      </span>
                    </Link> */}
                  </li>
                  <li className="order-modal">
                    <Link to="/orderlist" onClick={this.sideBarClose()}>
                      <i class="feather icon-shopping-bag"></i>
                      <span class="menu-item" data-i18n="Analytics">
                        Orders
                      </span>
                    </Link>
                  </li>
                  <li className="product-modal">
                    <Link
                      to="/productFreelistinglist"
                      onClick={this.ProductsideBarClose()}
                    >
                      <i class="feather icon-shopping-bag"></i>
                      <span class="menu-item" data-i18n="Analytics">
                        Products
                      </span>
                    </Link>
                  </li>
                  <li className="offers-modal">
                    <Link to="/offerlist" onClick={this.OfferSideBarClose()}>
                      <i class="feather icon-shopping-bag"></i>
                      <span class="menu-item" data-i18n="Analytics">
                        Offers
                      </span>
                    </Link>
                  </li>
                  {/* <li class="customer-modal">
                    <Link
                      to="/contactList"
                      onClick={this.CustomerSideBarClose()}
                    >
                      <i class="feather icon-users"></i>
                      <span class="menu-item" data-i18n="Analytics">
                        Customers
                      </span>
                    </Link>
                  </li> */}
                  <li class=" nav-item">
                    <a>
                      <i class="fa fa-money"></i>
                      <span class="menu-title" data-i18n="Ecommerce">
                        Finances{' '}
                      </span>
                    </a>
                    <ul class="menu-content">
                      <li className="paymentPayout-modal">
                        <Link
                          to="/paymentpayout"
                          onClick={this.PaymentPayoutSideBarClose()}
                        >
                          <i class="feather icon-circle"></i>
                          <span class="menu-item" data-i18n="Shop">
                            Payout & Payout History
                          </span>
                        </Link>
                      </li>
                      <li className="paymentSetting-modal">
                        <Link
                          to="/information"
                          onClick={this.PaymentSettingSideBarClose()}
                        >
                          <i class="fa fa-edit"></i>
                          <span class="menu-item" data-i18n="Analytics">
                            Seller & Payment Info
                          </span>
                        </Link>
                      </li>
                      <li className="transactionHistory-modal">
                        <Link
                          to="/allTransactionhistory"
                          onClick={this.TransactionSideBarClose()}
                        >
                          <i class="feather icon-circle"></i>
                          <span class="menu-item" data-i18n="Shop">
                            Transaction History
                          </span>
                        </Link>
                      </li>
                    </ul>
                  </li>

                  <li class=" nav-item">
                    <a>
                      <i class="feather icon-tv"></i>
                      <span class="menu-title" data-i18n="Ecommerce">
                        Promote{' '}
                      </span>
                    </a>
                    <ul class="menu-content">
                      <li className="discount-modal">
                        <Link
                          to="/discount"
                          onClick={this.DiscountSideBarClose()}
                        >
                          <i class="feather icon-shopping-bag"></i>
                          <span class="menu-item" data-i18n="Analytics">
                            Discount
                          </span>
                        </Link>
                      </li>
                      {/* <li class=" nav-item">
                  <a href="">
                    <i class="feather icon-tag"></i>
                    <span class="menu-title" data-i18n="Email">
                      Discounts
                  </span>
                  </a>
                </li> */}
                      <li className="sponsor-modal">
                        <Link
                          to="/bannerlist"
                          onClick={this.SponsorSideBarClose()}
                        >
                          <i class="feather icon-circle"></i>
                          <span class="menu-item" data-i18n="Shop">
                            Sponsor Advertise
                          </span>
                        </Link>
                      </li>
                      {/* <li className="payPerClick-modal">
                        <Link to="/payperclickbannerlist" onClick={this.PayPerClickSideBarClose()}>
                          <i class="feather icon-circle"></i>
                          <span class="menu-item" data-i18n="Details" style={{ fontSize: "15px" }}>
                            PayPerClick Manager
                          </span>
                        </Link>
                      </li> */}
                    </ul>
                  </li>
                  <li className="review-modal">
                    <Link to="/reviews" onClick={this.ReviewSideBarClose()}>
                      <i class="feather icon-star"></i>
                      <span class="menu-title" data-i18n="Chat">
                        Reviews
                      </span>
                    </Link>
                  </li>
                  <li className="forum-modal">
                    <Link to="/sellerforum" onClick={this.ForumSideBarClose()}>
                      <i class="fa fa-edit"></i>
                      <span class="menu-item" data-i18n="Analytics">
                        Forum
                      </span>
                    </Link>
                  </li>
                  <li className="livestream-modal">
                    <Link
                      to="/liveStream"
                      onClick={this.LiveStreamSideBarClose()}
                    >
                      <i class="fas fa-camera"></i>
                      <span class="menu-item" data-i18n="Analytics">
                        Live Stream
                      </span>
                    </Link>
                  </li>
                  <li className="salesreport-modal">
                    <Link
                      to="/salesReport"
                      onClick={this.SaleReportSideBarClose()}
                    >
                      <i class="feather icon-circle"></i>
                      <span class="menu-item" data-i18n="Shop">
                        Sales Report
                      </span>
                    </Link>
                  </li>
                  <li class="navigation-header navigation-header-custm">
                    <span></span>
                  </li>
                  <li class=" nav-item">
                    <a>
                      <i class="feather icon-settings"></i>
                      <span class="menu-title" data-i18n="Ecommerce">
                        Settings{' '}
                      </span>
                    </a>
                    <ul class="menu-content">
                      <li className="contact-modal">
                        <Link
                          to="/contact-us"
                          onClick={this.ContactSideBarClose()}
                        >
                          <i class="feather icon-circle"></i>
                          <span class="menu-item" data-i18n="Shop">
                            Contact us
                          </span>
                        </Link>
                      </li>
                      <li className="about-modal">
                        <Link to="/about-us" onClick={this.AboutSideBarClose()}>
                          <i class="feather icon-circle"></i>
                          <span class="menu-item" data-i18n="Details">
                            About us
                          </span>
                        </Link>
                      </li>
                      <li className="termCondition-modal">
                        <Link
                          to="/term-and-condition"
                          onClick={this.TermSideBarClose()}
                        >
                          <i class="feather icon-circle"></i>
                          <span class="menu-item" data-i18n="Wish List">
                            Terms & condition
                          </span>
                        </Link>
                      </li>
                      <li className="privacy-modal">
                        <Link
                          to="/data-policy"
                          onClick={this.PrivacySideBarClose()}
                        >
                          <i class="feather icon-circle"></i>
                          <span class="menu-item" data-i18n="Checkout">
                            Privacy Policy
                          </span>
                        </Link>
                      </li>
                    </ul>
                  </li>
                </>
              )}
            </ul>
          </div>
        </div>
      </body>
    );
  }
}
export default withRouter(Sidebar);

// <div class="main-menu-content">
//       <ul
//         class="navigation navigation-main"
//         id="main-menu-navigation"
//         data-menu="menu-navigation"
//       >
//         <li class="nav-item active">
//           <a href="index.html">
//             <i class="feather icon-home"></i>
//             <span class="menu-title" data-i18n="Dashboard">
//               Dashboard
//             </span>
//             <span class="badge badge badge-warning-custm badge-pill float-right mr-2">
//               2
//             </span>
//           </a>
//         </li>
//         <li>
//           <a href="">
//             <i class="feather icon-shopping-bag"></i>
//             <span class="menu-item" data-i18n="Analytics">
//               Order
//             </span>
//           </a>
//         </li>
//         <li>
//           <a href="">
//             <i class="feather icon-package"></i>
//             <span class="menu-item" data-i18n="eCommerce">
//               Products
//             </span>
//           </a>
//           <ul class="menu-content">
//             <li>
//               <a href="add-product.html">
//                 <i class="feather icon-circle"></i>
//                 <span class="menu-item" >
//                   Add product
//                 </span>
//               </a>
//             </li>
//             <li>
//               <a href="">
//                 <i class="feather icon-circle"></i>
//                 <span class="menu-item" data-i18n="Shop">
//                   Best selling product
//                 </span>
//               </a>
//             </li>
//             <li>
//               <a href="">
//                 <i class="feather icon-circle"></i>
//                 <span class="menu-item" data-i18n="Shop">
//                   Low inventory
//                 </span>
//               </a>
//             </li>
//             <li>
//               <a href="">
//                 <i class="feather icon-circle"></i>
//                 <span class="menu-item" data-i18n="Shop">
//                   All products
//                 </span>
//               </a>
//             </li>
//           </ul>
//         </li>
//         <li class="">
//           <a href="">
//             <i class="feather icon-users"></i>
//             <span class="menu-item" data-i18n="Analytics">
//               Customers
//             </span>
//           </a>
//         </li>
//         <li class="">
//           <a href="">
//             <i class="fa fa-line-chart"></i>
//             <span class="menu-item" data-i18n="Analytics">
//               Analytics
//             </span>
//           </a>
//         </li>
//         <li class="navigation-header navigation-header-custm">
//           <span></span>
//         </li>
//         <li class=" nav-item">
//           <a href="">
//             <i class="feather icon-tv"></i>
//             <span class="menu-title" data-i18n="Email">
//               Advertisments
//             </span>
//           </a>
//         </li>
//         <li class=" nav-item">
//           <a href="">
//             <i class="feather icon-tag"></i>
//             <span class="menu-title" data-i18n="Email">
//               Discounts
//             </span>
//           </a>
//         </li>
//         <li class=" nav-item">
//           <a href="">
//             <i class="feather icon-star"></i>
//             <span class="menu-title" data-i18n="Chat">
//               Reviews
//             </span>
//           </a>
//         </li>
//         <li class=" nav-item">
//           <a href="">
//             <i class="fa fa-money"></i>
//             <span class="menu-title" data-i18n="Todo">
//               Payout
//             </span>
//           </a>
//         </li>
//         <li class="navigation-header navigation-header-custm">
//           <span></span>
//         </li>
//         <li class=" nav-item">
//           <a href="#">
//             <i class="feather icon-settings"></i>
//             <span class="menu-title" data-i18n="Ecommerce">
//               Settings{" "}
//             </span>
//           </a>
//           <ul class="menu-content">
//             <li>
//               <a href="">
//                 <i class="feather icon-circle"></i>
//                 <span class="menu-item" data-i18n="Shop">
//                   Contact us
//                 </span>
//               </a>
//             </li>
//             <li>
//               <a href="">
//                 <i class="feather icon-circle"></i>
//                 <span class="menu-item" data-i18n="Details">
//                   About us
//                 </span>
//               </a>
//             </li>
//             <li>
//               <a href="">
//                 <i class="feather icon-circle"></i>
//                 <span class="menu-item" data-i18n="Wish List">
//                   Terms & condition
//                 </span>
//               </a>
//             </li>
//             <li>
//               <a href="">
//                 <i class="feather icon-circle"></i>
//                 <span class="menu-item" data-i18n="Checkout">
//                   Privacy Policy
//                 </span>
//               </a>
//             </li>
//           </ul>
//         </li>
//       </ul>
//     </div>
