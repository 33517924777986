import React from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import moment from 'moment';
//import { MDBDataTable } from "mdbreact";
// import Sidebar from "../../Component/Sidebar/Sidebar";
// import Header from "../../Component/Header/Header";
import Footer from '../../Component/Footer/Footer';
import global from '../../global';
import ReactPaginate from 'react-paginate';
import { CardHeader } from 'reactstrap';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Nav, NavItem, NavLink } from 'reactstrap';
import classnames from 'classnames';
import './style.css';
import Button from 'reactstrap/lib/Button';
import { toast } from 'react-toastify';
const $ = require('jquery');
$.DataTable = require('datatables.net');
var sellerData = {};

toast.configure();
const CustomToast = () => {
  return (
    <div>
      <i class="fa fa-check success-toast"></i>
      Banner Deleted Successfully
    </div>
  );
};
class AddProduct extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      seller: '',
      bannersf: [],
      banners: [],
      model: false,
      reason: '',
      tab1: 'active',
      tab2: '',
      productbannersf: [],
      productbanners: [],
      selectpage: '1',
      activeTab: '1',
      offset: 1,
      perPage: 10,
      currentPage: 1,
      totalCount: 0,
      pageCount: 0,
      offset1: 1,
      currentPage1: 1,
      totalCount1: 0,
      pageCount1: 0,
      count: 1,
    };
    this.toggle = this.toggle.bind(this);
  }

  componentWillMount() {
    if (localStorage.getItem('sellerdata')) {
      this.setState({ seller: JSON.parse(localStorage.getItem('sellerdata')) });
      sellerData = JSON.parse(localStorage.getItem('sellerdata'));
    }
    this.listAPI();
    // this.setState({ tab1: 'active' });
  }
  pagination1() {
    console.log(this.state.totalCount1, 'countcheck123check');
    console.log(this.state.offset1, 'offsetcheckbanner');
    const slice = this.state.bannersf.slice(
      this.state.offset1 - 1,
      this.state.offset1 - 1 + this.state.perPage
    );

    // For displaying Data
    // this.setState({ listdata: slice });
    this.setState({ banners: slice });
    this.dataTable();
  }
  pagination() {
    console.log(this.state.offset, 'offsetcheck');
    const slice = this.state.productbannersf.slice(
      this.state.offset - 1,
      this.state.offset - 1 + this.state.perPage
    );

    // For displaying Data
    // this.setState({ listdata: slice });
    this.setState({ productbanners: slice });
  }
  handlePageClick = (e) => {
    const selectedPage = e.selected;
    console.log(selectedPage, 'selected page');
    const offset = selectedPage * this.state.perPage;

    this.setState(
      {
        currentPage: selectedPage + 1,
        offset: offset + 1,
      },
      () => {
        // this.pagination();
        this.listAPI();
      }
    );
  };
  handlePageClick1 = (e) => {
    const selectedPage = e.selected;
    console.log(selectedPage, 'selected page234');
    const offset = selectedPage * this.state.perPage;

    this.setState(
      {
        currentPage1: selectedPage + 1,
        offset1: offset + 1,
      },
      () => {
        // this.pagination();
        this.listAPI();
      }
    );
  };

  bannerdelete = (id) => {
    var urlencoded = new URLSearchParams();
    urlencoded.append('id', id);

    axios
      .post(global.baseUrl + 'deletebanner', urlencoded, {
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      })
      .then((result) => {
        this.listAPI();
        toast.success(<CustomToast />, {
          position: toast.POSITION.TOP_RIGHT,
        });
        // window.location.reload()
      });
  };

  bannerproductdelete = (id) => {
    var urlencoded = new URLSearchParams();
    urlencoded.append('id', id);

    axios
      .post(global.baseUrl + 'deletebannerproduct', urlencoded, {
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      })
      .then((result) => {
        this.listAPI();
        toast.success(<CustomToast />, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  listAPI() {
    var urlencoded = new URLSearchParams();
    urlencoded.append('sellerId', sellerData.id);

    axios
      .post(global.baseUrl + 'SellerBannerList', urlencoded, {
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      })
      .then((result) => {
        console.log(result.data);
        if (result.data.status) {
          if (this.state.seller.type !== 'Retailer') {
            this.setState({
              // tableData: {
              //   rows: result.data.data
              // },
              bannersf: result.data.data.filter(function (element) {
                if (element.type !== 'Retailer') {
                  return true; // skip
                }
                return false;
              }),
              banners: result.data.data.filter(function (element) {
                if (element.type !== 'Retailer') {
                  return true; // skip
                }
                return false;
              }),
            });
            console.log(this.state.bannersf, 'banner1');
            this.setState({
              // totalCount1: result.data.data.length,
              // pageCount1: Math.ceil(result.data.data.length / this.state.perPage),
              totalCount1: result.data.data.filter(function (element) {
                if (element.type !== 'Retailer') {
                  return true; // skip
                }
                return false;
              }).length,
              pageCount1: Math.ceil(
                result.data.data.filter(function (element) {
                  if (element.type !== 'Retailer') {
                    return true; // skip
                  }
                  return false;
                }).length / this.state.perPage
              ),
            });
            this.pagination1();
          } else {
            console.log('finalyenter');
            this.setState({
              // tableData: {
              //   rows: result.data.data
              // },
              banners: result.data.data,
            });
            this.dataTable();
            // console.log(this.state.banners,"banner1")
            // this.setState({
            //   // totalCount1: result.data.data.length,
            //   // pageCount1: Math.ceil(result.data.data.length / this.state.perPage),
            //   totalCount1:result.data.data.length,
            //   pageCount1: (result.data.data.length / this.state.perPage),
            // });
            this.dataTable();
          }
          // this.pagination1();
          // this.dataTable();
        }
      });

    axios
      .post(global.baseUrl + 'SellerProductBannerList', urlencoded, {
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      })
      .then((result) => {
        console.log(result.data);
        if (result.data.status) {
          this.setState({
            // tableData: {
            //   rows: result.data.data
            // },
            productbannersf: result.data.data,
            productbanners: result.data.data,
          });
          this.setState({
            totalCount: result.data.data.length,
            pageCount: Math.ceil(result.data.data.length / this.state.perPage),
          });
          this.pagination();
          this.dataTable2();
        }
      });
  }

  dataTable2() {
    $(document).ready(function () {
      $('#list').DataTable();
    });
  }
  dataTable() {
    $(document).ready(function () {
      $('#list_banner').DataTable();
    });
  }

  toggle() {
    this.setState({
      modal: !this.state.modal,
    });
  }
  resonshow(reson) {
    this.setState({
      reason: reson,
    });
  }
  tabselect1 = () => {
    // alert('ddddd');
    this.setState({
      tab1: 'active',
      tab2: 'unactive',
      selectpage: '1',
      activeTab: '1',
    });
    this.dataTable2();
    // this.forceUpdate();
  };
  tabselect2 = () => {
    // alert('ddddd2');
    this.setState({
      tab2: 'active',
      tab1: 'unactive',
      selectpage: '2',
      activeTab: '2',
    });
    this.dataTable();
    // this.forceUpdate();
  };
  render() {
    return (
      <body
        class="vertical-layout vertical-menu-modern semi-dark-layout 2-columns  navbar-floating footer-static  "
        data-open="click"
        data-menu="vertical-menu-modern"
        data-col="2-columns"
        data-layout="semi-dark-layout"
      >
        {/* <Header />
        <Sidebar />  */}
        <div class="app-content content" style={{ minHeight: '91vh' }}>
          <div class="content-overlay"></div>
          <div class="header-navbar-shadow"></div>
          <div class="content-wrapper">
            <div class="content-header row ">
              <div class="content-header-left col-md-7 col-12 mb-2">
                <div class="row breadcrumbs-top">
                  <div class="col-12">
                    <h2 class="content-header-title float-left mb-0">
                      Sponsor Advertise
                    </h2>
                    <div class="breadcrumb-wrapper col-12">
                      <ol class="breadcrumb">
                        <li class="breadcrumb-item">
                          <Link to="/dashboard">Home</Link>
                        </li>
                        <li class="breadcrumb-item active">
                          Sponsor Advertise
                        </li>
                      </ol>
                    </div>
                  </div>
                </div>
              </div>
              <div class="content-header-right text-md-right col-md-5 col-12 ">
                {' '}
                {/* d-none */}
                <div class="form-group breadcrum-right sr-breadcrum-right">
                  {this.state.seller.type === 'Retailer' ? (
                    <>
                      {' '}
                      <Link
                        to="/addbannerretailer"
                        id="addRow"
                        class="btn btn-primary  add-product-link mr-1"
                        style={{ whiteSpace: 'nowrap' }}
                      >
                        <i class="feather icon-plus"></i>Add Banner
                      </Link>
                      <Link
                        to="/addcategorybannerretailer"
                        id="addRow"
                        class="btn btn-primary add-product-link"
                        style={{ whiteSpace: 'nowrap' }}
                      >
                        <i class="feather icon-plus"></i>Add Category Banner
                      </Link>
                    </>
                  ) : (
                    <>
                      <Link
                        to="/addbanner"
                        id="addRow"
                        class="btn btn-primary  add-product-link mr-1 promotebanner"
                        style={{ whiteSpace: 'nowrap' }}
                      >
                        <i class="feather icon-plus"></i>Add Banner
                      </Link>
                      <Link
                        to="/addpromote"
                        id="addRow"
                        class="btn btn-primary  add-product-link mr-1 promotebanner"
                        style={{ whiteSpace: 'nowrap' }}
                      >
                        <i class="feather icon-plus"></i>Add Promote Banner
                      </Link>
                      <Link
                        to="/addbannerproduct"
                        id="addRow"
                        class="btn btn-primary add-product-link promotebanner"
                        style={{ whiteSpace: 'nowrap' }}
                      >
                        <i class="feather icon-plus"></i>Add Product
                      </Link>
                    </>
                  )}
                </div>
              </div>
            </div>
            <div class="content-body">
              <section id="add-row">
                <div class="row">
                  <div class="col-12">
                    <div class="card">
                      {this.state.seller.type === 'Retailer' ? (
                        <div class="card-content">
                          {/* <div class="tab">
                        <button
                          class={`tablinks ${this.state.tab1}`}
                          onClick={this.tabselect1}
                        >
                          Sponsor Banner List
                        </button>
                        <button
                          class={`tablinks ${this.state.tab2}`}
                          onClick={this.tabselect2}
                        >
                          Sponsor Product List
                        </button>
                      </div> */}
                          <div class="card-body">
                            {/* <button id="addRow" class="btn btn-primary mb-2"><i class="feather icon-plus"></i>&nbsp; Add new row</button> */}
                            <div class="table-responsive">
                              <table
                                class="table add-rows banertabale"
                                id="list"
                              >
                                <thead>
                                  <tr>
                                    <th>Id</th>
                                    <th>Start Date</th>
                                    <th>End Date</th>
                                    <th>Image</th>
                                    <th>Status</th>
                                    <th>Payment</th>
                                    <th>Action</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {this.state.banners
                                    .filter(function (element) {
                                      if (element.type !== 'Retailer') {
                                        return false; // skip
                                      }
                                      return true;
                                    })
                                    .map((banner, index) => (
                                      <>
                                        {banner.type === 'Retailer' ? (
                                          <>
                                            <Modal
                                              isOpen={this.state.modal}
                                              toggle={() => this.toggle()}
                                              style={{ marginTop: '265px' }}
                                            >
                                              <ModalHeader
                                                toggle={() => this.toggle()}
                                              >
                                                Denied Reason
                                              </ModalHeader>
                                              <ModalBody>{`${this.state.reason}`}</ModalBody>
                                              <ModalFooter>
                                                <Button
                                                  color="secondary"
                                                  onClick={() => this.toggle()}
                                                >
                                                  Cancel
                                                </Button>
                                              </ModalFooter>
                                            </Modal>
                                            <tr>
                                              <td>{index + 1}</td>
                                              <td>
                                                {moment(
                                                  banner.start_date
                                                ).format('DD-MM-YYYY')}{' '}
                                              </td>
                                              <td>
                                              {banner.end_date?moment(
                                                    banner.end_date
                                                  ).format('DD-MM-YYYY'):moment(
                                                    banner.end_milisecond
                                                  ).format('DD-MM-YYYY')}
                                              </td>
                                              <td>
                                                <img
                                                  src={
                                                    banner.banner_image_app
                                                      ? banner.banner_image_app
                                                      : null
                                                  }
                                                  class="banner-list-img"
                                                  alt=".."
                                                  style={{
                                                    marginTop: '10px',
                                                    marginBottom: '10px',
                                                  }}
                                                />
                                              </td>
                                              <td>
                                                {banner.ApprovedStatus ===
                                                '1' ? (
                                                  <b>Approved</b>
                                                ) : banner.ApprovedStatus ===
                                                  '0' ? (
                                                  <Button
                                                    onClick={() => {
                                                      this.toggle();
                                                      this.resonshow(
                                                        banner.reason
                                                      );
                                                    }}
                                                  >
                                                    <b>Denied</b>
                                                  </Button>
                                                ) : (
                                                  <b>Pending</b>
                                                )}
                                              </td>
                                              <td>
                                                <Link
                                                  to={
                                                    '/viewbanner/' + banner.id
                                                  }
                                                  class="btn btn-outline-primary float-left btn-inline res-btn b-pay-btn"
                                                >
                                                  Payment
                                                </Link>
                                              </td>
                                              <td style={{ width: '100px' }}>
                                                {banner.paymentstatus ===
                                                '0' ? (
                                                  <>
                                                    {' '}
                                                    <Link
                                                      className="btn-banners"
                                                      // to={banner.productId&&banner.productId!==''?
                                                      //   '/editbannerretailer/' +
                                                      //   banner.id
                                                      //   :'/editcategorybanner/' +
                                                      //   banner.id
                                                      // }
                                                      onClick={() =>
                                                        this.bannerdelete(
                                                          banner.id
                                                        )
                                                      }
                                                    >
                                                      {' '}
                                                      <i class="far fa-trash-alt"></i>
                                                    </Link>
                                                    <Link
                                                      className="btn-banners"
                                                      style={{
                                                        color: 'green',
                                                      }}
                                                      to={
                                                        banner.productId &&
                                                        banner.productId !== ''
                                                          ? '/editbannerretailer/' +
                                                            banner.id
                                                          : '/editcategorybanner/' +
                                                            banner.id
                                                      }
                                                    >
                                                      {' '}
                                                      <i
                                                        class="fa fa-pencil"

                                                        // onClick={() =>
                                                        //     {this.openediteproduct(item.id)}
                                                        // }
                                                      ></i>
                                                    </Link>
                                                  </>
                                                ) : (
                                                  <>
                                                    {' '}
                                                    <Link
                                                      className="btn-banners"
                                                      style={{
                                                        pointerEvents: 'none',
                                                      }}
                                                      to={
                                                        '/editbanner/' +
                                                        banner.id
                                                      }
                                                    >
                                                      {' '}
                                                      <i class="far fa-trash-alt"></i>
                                                    </Link>
                                                    <Link
                                                      className="btn-banners"
                                                      style={{
                                                        pointerEvents: 'none',
                                                        color: 'green',
                                                      }}
                                                      to={
                                                        '/editbanner/' +
                                                        banner.id
                                                      }
                                                    >
                                                      {' '}
                                                      <i
                                                        class="fa fa-pencil"

                                                        // onClick={() =>
                                                        //     {this.openediteproduct(item.id)}
                                                        // }
                                                      ></i>
                                                    </Link>
                                                  </>
                                                )}
                                              </td>
                                            </tr>
                                          </>
                                        ) : null}
                                      </>
                                    ))}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div class="card-content">
                          {/* <div class="tab">
                          <button
                            class={`tablinks ${this.state.tab1}`}
                            onClick={this.tabselect1}
                          >
                            Sponsor Banner List
                          </button>
                          <button
                            class={`tablinks ${this.state.tab2}`}
                            onClick={this.tabselect2}
                          >
                            Sponsor Product List
                          </button>
                        </div> */}

                          <Nav tabs style={{ padding: '20px' }}>
                            <NavItem>
                              <NavLink
                                className={classnames({
                                  active: this.state.activeTab === '1',
                                })}
                                onClick={this.tabselect1}
                              >
                                <span class="d-sm-block">
                                  Sponsor Banner List
                                </span>
                              </NavLink>
                            </NavItem>
                            <NavItem>
                              <NavLink
                                className={classnames({
                                  active: this.state.activeTab === '2',
                                })}
                                onClick={this.tabselect2}
                              >
                                <span class="d-sm-block">
                                  Sponsor Product List
                                </span>
                              </NavLink>
                            </NavItem>
                          </Nav>
                          {this.state.selectpage === '1' ? (
                            <div class="card-body hidedt">
                              {/* <button id="addRow" class="btn btn-primary mb-2"><i class="feather icon-plus"></i>&nbsp; Add new row</button> */}
                              <div class="table-responsive">
                                <table
                                  class="table add-rows banertabale banner-table-action"
                                  id="list_banner"
                                >
                                  <thead>
                                    <tr>
                                      <th>Id</th>
                                      <th>Start Date</th>
                                      <th>End Date</th>
                                      <th>Image</th>
                                      <th>Status</th>
                                      {/* <th>Action</th> */}
                                      <th>Payment</th>
                                      <th>Action</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {this.state.banners
                                      .filter(function (element) {
                                        if (element.type === 'Retailer') {
                                          return false;
                                        }
                                        return true;
                                      })
                                      .map((banner, index) => (
                                        <>
                                          {banner.type === 'freelisting' ? (
                                            <>
                                              <Modal
                                                isOpen={this.state.modal}
                                                toggle={() => this.toggle()}
                                                style={{ marginTop: '265px' }}
                                              >
                                                <ModalHeader
                                                  toggle={() => this.toggle()}
                                                >
                                                  Denied Reason
                                                </ModalHeader>
                                                <ModalBody>{`${this.state.reason}`}</ModalBody>
                                                <ModalFooter>
                                                  <Button
                                                    color="secondary"
                                                    onClick={() =>
                                                      this.toggle()
                                                    }
                                                  >
                                                    Cancel
                                                  </Button>
                                                </ModalFooter>
                                              </Modal>
                                              <tr>
                                                <td style={{ width: '200px' }}>
                                                  {index + 1}
                                                </td>
                                                <td style={{ width: '200px' }}>
                                                  {moment(
                                                    banner.start_date
                                                  ).format('DD-MM-YYYY')}{' '}
                                                </td>
                                                <td style={{ width: '200px' }}>
                                                  {banner.end_date?moment(
                                                    banner.end_date
                                                  ).format('DD-MM-YYYY'):moment(
                                                    banner.end_milisecond
                                                  ).format('DD-MM-YYYY')}
                                                </td>
                                                <td style={{ width: '200px' }}>
                                                  {banner.video &&
                                                  banner.video !== '' ? (
                                                    <a href={banner.video}>
                                                      <button
                                                        style={{
                                                          backgroundColor:
                                                            '#47a547',
                                                          border: 'none',
                                                          borderRadius: '5px',
                                                          color: 'white',
                                                        }}
                                                      >
                                                        view
                                                      </button>
                                                    </a>
                                                  ) : (
                                                    <img
                                                      src={
                                                        banner.banner_image_app
                                                          ? banner.banner_image_app
                                                          : null
                                                      }
                                                      class="banner-list-img"
                                                      alt=".."
                                                      style={{
                                                        marginTop: '10px',
                                                        marginBottom: '10px',
                                                      }}
                                                    />
                                                  )}
                                                </td>
                                                <td>
                                                  {banner.ApprovedStatus ===
                                                  '1' ? (
                                                    <b>Approved</b>
                                                  ) : banner.ApprovedStatus ===
                                                    '0' ? (
                                                    <Button
                                                      onClick={() => {
                                                        this.toggle();
                                                        this.resonshow(
                                                          banner.reason
                                                        );
                                                      }}
                                                    >
                                                      <b>Denied</b>
                                                    </Button>
                                                  ) : (
                                                    <b>Pending</b>
                                                  )}
                                                </td>

                                                {/* <td style={{ width: '100px' }}>
                                                  {banner.paymentstatus ===
                                                  '0' ? (
                                                    <>
                                                      {' '}
                                                      <Link
                                                        className="btn-banners"
                                                        to={
                                                          '/editbanner/' +
                                                          banner.id
                                                        }
                                                      >
                                                        {' '}
                                                        <i class="far fa-trash-alt"></i>
                                                      </Link>
                                                      <Link
                                                        className="btn-banners"
                                                        style={{
                                                          color: 'green',
                                                        }}
                                                        to={
                                                          '/editbanner/' +
                                                          banner.id
                                                        }
                                                      >
                                                        {' '}
                                                        <i
                                                          class="fa fa-pencil"

                                                          // onClick={() =>
                                                          //     {this.openediteproduct(item.id)}
                                                          // }
                                                        ></i>
                                                      </Link>
                                                    </>
                                                  ) : (
                                                    <>
                                                      {' '}
                                                      <Link
                                                        className="btn-banners"
                                                        style={{
                                                          pointerEvents: 'none',
                                                        }}
                                                        to={
                                                          '/editbanner/' +
                                                          banner.id
                                                        }
                                                      >
                                                        {' '}
                                                        <i class="far fa-trash-alt"></i>
                                                      </Link>
                                                      <Link
                                                        className="btn-banners"
                                                        style={{
                                                          pointerEvents: 'none',
                                                          color: 'green',
                                                        }}
                                                        to={
                                                          '/editbanner/' +
                                                          banner.id
                                                        }
                                                      >
                                                        {' '}
                                                        <i
                                                          class="fa fa-pencil"

                                                          // onClick={() =>
                                                          //     {this.openediteproduct(item.id)}
                                                          // }
                                                        ></i>
                                                      </Link>
                                                    </>
                                                  )}
                                                </td> */}

                                                <td>
                                                  <Link
                                                    to={
                                                      '/viewbanner/' + banner.id
                                                    }
                                                    class="btn btn-outline-primary paymentbtnnew"
                                                  >
                                                    Payment
                                                  </Link>
                                                </td>

                                                <td style={{ width: '100px' }}>
                                                  {banner.paymentstatus ===
                                                  '0' ? (
                                                    <>
                                                      {' '}
                                                      <Link
                                                        className="btn-banners"
                                                        // to={
                                                        //   '/editbanner/' +
                                                        //   banner.id
                                                        // }
                                                        onClick={() =>
                                                          this.bannerdelete(
                                                            banner.id
                                                          )
                                                        }
                                                      >
                                                        {' '}
                                                        <i class="far fa-trash-alt"></i>
                                                      </Link>
                                                      <Link
                                                        className="btn-banners"
                                                        style={{
                                                          color: 'green',
                                                        }}
                                                        to={banner.end_milisecond?'/editpromote/' +
                                                        banner.id:
                                                          '/editbanner/' +
                                                          banner.id
                                                        }
                                                      >
                                                        {' '}
                                                        <i
                                                          class="fa fa-pencil"

                                                          // onClick={() =>
                                                          //     {this.openediteproduct(item.id)}
                                                          // }
                                                        ></i>
                                                      </Link>
                                                    </>
                                                  ) : (
                                                    <>
                                                      {' '}
                                                      <Link
                                                        className="btn-banners"
                                                        style={{
                                                          pointerEvents: 'none',
                                                        }}
                                                        to={
                                                          '/editbanner/' +
                                                          banner.id
                                                        }
                                                      >
                                                        {' '}
                                                        <i class="far fa-trash-alt"></i>
                                                      </Link>
                                                      <Link
                                                        className="btn-banners"
                                                        style={{
                                                          pointerEvents: 'none',
                                                          color: 'green',
                                                        }}
                                                        to={
                                                          '/editbanner/' +
                                                          banner.id
                                                        }
                                                      >
                                                        {' '}
                                                        <i
                                                          class="fa fa-pencil"

                                                          // onClick={() =>
                                                          //     {this.openediteproduct(item.id)}
                                                          // }
                                                        ></i>
                                                      </Link>
                                                    </>
                                                  )}
                                                </td>
                                              </tr>
                                            </>
                                          ) : null}
                                        </>
                                      ))}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          ) : (
                            <div class="card-body hidedt">
                              {/* <button id="addRow" class="btn btn-primary mb-2"><i class="feather icon-plus"></i>&nbsp; Add new row</button> */}
                              <div class="table-responsive">
                                <table
                                  class="table add-rows banertabale"
                                  id="list"
                                >
                                  <thead>
                                    <tr>
                                      <th>Id</th>
                                      <th>Start Date</th>
                                      <th>End Date</th>
                                      <th>Image</th>
                                      <th>Status</th>
                                      {/* <th>Action</th> */}
                                      <th>Payment</th>
                                      <th>Action</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {this.state.productbanners.map(
                                      (banner, index) => (
                                        <>
                                          <Modal
                                            isOpen={this.state.modal}
                                            toggle={() => this.toggle()}
                                            style={{ marginTop: '265px' }}
                                          >
                                            <ModalHeader
                                              toggle={() => this.toggle()}
                                            >
                                              Denied Reason
                                            </ModalHeader>
                                            <ModalBody>{`${this.state.reason}`}</ModalBody>
                                            <ModalFooter>
                                              <Button
                                                color="secondary"
                                                onClick={() => this.toggle()}
                                              >
                                                Cancel
                                              </Button>
                                            </ModalFooter>
                                          </Modal>
                                          <tr>
                                            <td style={{ width: '200px' }}>
                                              {index + 1}
                                            </td>
                                            <td style={{ width: '200px' }}>
                                              {moment(banner.start_date).format(
                                                'DD-MM-YYYY'
                                              )}{' '}
                                            </td>
                                            <td style={{ width: '200px' }}>
                                            {banner.end_date?moment(
                                                    banner.end_date
                                                  ).format('DD-MM-YYYY'):moment(
                                                    banner.end_milisecond
                                                  ).format('DD-MM-YYYY')}
                                            </td>
                                            <td style={{ width: '200px' }}>
                                              {banner.Link !== '' ? (
                                                <a href={banner.Link}>
                                                  <button
                                                    style={{
                                                      backgroundColor:
                                                        '#47a547',
                                                      border: 'none',
                                                      borderRadius: '5px',
                                                      color: 'white',
                                                    }}
                                                  >
                                                    view
                                                  </button>
                                                </a>
                                              ) : (
                                                <img
                                                  src={
                                                    banner.productId
                                                      ? banner.productId
                                                          .image[0]
                                                      : banner.image
                                                      ? banner.image
                                                      : null
                                                  }
                                                  class="banner-list-img"
                                                  alt=".."
                                                  style={{
                                                    marginTop: '10px',
                                                    marginBottom: '10px',
                                                  }}
                                                />
                                              )}
                                            </td>
                                            <td>
                                              {banner.ApprovedStatus === '1' ? (
                                                <b>Approved</b>
                                              ) : banner.ApprovedStatus ===
                                                '0' ? (
                                                <Button
                                                  onClick={() => {
                                                    this.toggle();
                                                    this.resonshow(
                                                      banner.reason
                                                    );
                                                  }}
                                                >
                                                  <b>Denied</b>
                                                </Button>
                                              ) : (
                                                <b>Pending</b>
                                              )}
                                            </td>
                                            {/* <td style={{ width: '100px' }}>
                                              {banner.paymentstatus === '0' ? (
                                                <>
                                                  {' '}
                                                  <Link
                                                    className="btn-banners"
                                                    to={
                                                      '/editbannerproduct/' +
                                                      banner.id
                                                    }
                                                  >
                                                    {' '}
                                                    <i class="far fa-trash-alt"></i>
                                                  </Link>
                                                  <Link
                                                    className="btn-banners"
                                                    style={{ color: 'green' }}
                                                    to={
                                                      '/editbannerproduct/' +
                                                      banner.id
                                                    }
                                                  >
                                                    {' '}
                                                    <i
                                                      class="fa fa-pencil"

                                                      // onClick={() =>
                                                      //     {this.openediteproduct(item.id)}
                                                      // }
                                                    ></i>
                                                  </Link>
                                                </>
                                              ) : (
                                                <>
                                                  {' '}
                                                  <Link
                                                    className="btn-banners"
                                                    style={{
                                                      pointerEvents: 'none',
                                                    }}
                                                    to={
                                                      '/editbannerproduct/' +
                                                      banner.id
                                                    }
                                                  >
                                                    {' '}
                                                    <i class="far fa-trash-alt"></i>
                                                  </Link>
                                                  <Link
                                                    className="btn-banners"
                                                    style={{
                                                      pointerEvents: 'none',
                                                      color: 'green',
                                                    }}
                                                    to={
                                                      '/editbannerproduct/' +
                                                      banner.id
                                                    }
                                                  >
                                                    {' '}
                                                    <i
                                                      class="fa fa-pencil"

                                                      // onClick={() =>
                                                      //     {this.openediteproduct(item.id)}
                                                      // }
                                                    ></i>
                                                  </Link>
                                                </>
                                              )}
                                            </td> */}

                                            <td style={{ width: '100px' }}>
                                              <Link
                                                to={'/viewbanner/' + banner.id}
                                                class="btn btn-outline-primary float-left btn-inline res-btn b-pay-btn"
                                              >
                                                Payment
                                              </Link>
                                            </td>
                                            <td style={{ width: '100px' }}>
                                              {banner.paymentstatus === '0' ? (
                                                <>
                                                  {' '}
                                                  <Link
                                                    className="btn-banners"
                                                    // to={
                                                    //   '/editbannerproduct/' +
                                                    //   banner.id
                                                    // }
                                                    onClick={() =>
                                                      this.bannerproductdelete(
                                                        banner.id
                                                      )
                                                    }
                                                  >
                                                    {' '}
                                                    <i class="far fa-trash-alt"></i>
                                                  </Link>
                                                  <Link
                                                    className="btn-banners"
                                                    style={{ color: 'green' }}
                                                    to={
                                                      '/editbannerproduct/' +
                                                      banner.id
                                                    }
                                                  >
                                                    {' '}
                                                    <i
                                                      class="fa fa-pencil"

                                                      // onClick={() =>
                                                      //     {this.openediteproduct(item.id)}
                                                      // }
                                                    ></i>
                                                  </Link>
                                                </>
                                              ) : (
                                                <>
                                                  {' '}
                                                  <Link
                                                    className="btn-banners"
                                                    style={{
                                                      pointerEvents: 'none',
                                                    }}
                                                    to={
                                                      '/editbannerproduct/' +
                                                      banner.id
                                                    }
                                                  >
                                                    {' '}
                                                    <i class="far fa-trash-alt"></i>
                                                  </Link>
                                                  <Link
                                                    className="btn-banners"
                                                    style={{
                                                      pointerEvents: 'none',
                                                      color: 'green',
                                                    }}
                                                    to={
                                                      '/editbannerproduct/' +
                                                      banner.id
                                                    }
                                                  >
                                                    {' '}
                                                    <i
                                                      class="fa fa-pencil"

                                                      // onClick={() =>
                                                      //     {this.openediteproduct(item.id)}
                                                      // }
                                                    ></i>
                                                  </Link>
                                                </>
                                              )}
                                            </td>
                                          </tr>
                                        </>
                                      )
                                    )}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          )}

                          {this.state.selectpage === '1' &&
                          this.state.totalCount1 ? (
                            <ReactPaginate
                              previousLabel={'prev'}
                              nextLabel={'next'}
                              breakLabel={'...'}
                              activePage={this.state.currentPage1}
                              breakClassName={'break-me'}
                              pageCount={this.state.pageCount1}
                              marginPagesDisplayed={2}
                              pageRangeDisplayed={5}
                              onPageChange={this.handlePageClick1}
                              containerClassName={'pagination'}
                              subContainerClassName={'pages pagination'}
                              activeClassName={'active'}
                            />
                          ) : this.state.selectpage === '2' &&
                            this.state.totalCount ? (
                            <ReactPaginate
                              previousLabel={'prev'}
                              nextLabel={'next'}
                              breakLabel={'...'}
                              breakClassName={'break-me'}
                              activePage={this.state.currentPage}
                              pageCount={this.state.pageCount}
                              marginPagesDisplayed={2}
                              pageRangeDisplayed={5}
                              onPageChange={this.handlePageClick}
                              containerClassName={'pagination'}
                              subContainerClassName={'pages pagination'}
                              activeClassName={'active'}
                            />
                          ) : null}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
        <div class="sidenav-overlay"></div>
        <div class="drag-target"></div>

        <Footer />
      </body>
    );
  }
}

export default AddProduct;
