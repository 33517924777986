import Styled, { css } from 'styled-components'
var BREAKPOINTS = {
    DISPLAY: 1750,
    DESKTOP: 1450, // x_large  
    LAPTOP: '992px', // large
    TABLET: '767px', // medium
    PHABLET: '576px',
    MOBILE: '575px',// small
    SMOBILE: '350px' // x_small
}
export const responsive = Object.keys(BREAKPOINTS).reduce((acc, label) => {
    acc[label] = (...args) => css`
    @media (max-width: ${BREAKPOINTS[label]}) {
      ${css(...args)}
    }
  `;
  
    return acc;
}, {});

export const AddContainer = Styled.div`
    .login-error{
        margin:0px;
        color:red;
        font-size:12px
    }
    .file-input{
        padding:6px 12px;
        border: 1px solid #e4e7ea;
        border-radius: 0.25rem;
        color: #5c6873;
    }
    .add-img{
        width:170px;
        height:170px;
        margin:5px;
    }
    .btn{
        margin:5px 7px;
        color:white;
        font-weight:bold
    }
    .form-control:focus {
        background-color: #fff;
        border-color: #2684ff;
        box-shadow: 0 0 0 1px #2684ff;
    }
    .file-input:focus {
        background-color: #fff;
        border-color: #2684ff;
        box-shadow: 0 0 0 1px #2684ff;
    }
    button.btn.btn-primary.btn-sm:focus  {
        border-color: #2684ff;
        box-shadow: 0 0 0 1px #2684ff;
    }
`