import React from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import Footer from "../../Component/Footer/Footer";
import global from "../../global";
import './style.css'

class History extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            seller: {},
            list: [],
        };
    }
    toggle = tab => {
        if (tab === "1") {
            this.setState({ activeTab: "1" });
        } else if (tab === "2") {
            this.setState({ activeTab: "2" });
            let urlencoded = new URLSearchParams();
            urlencoded.append("sellerId", this.state.seller.id);
            axios
                .post(global.baseUrl + "SellerWalletHistory", urlencoded, {
                    headers: { "Content-Type": "application/x-www-form-urlencoded" }
                })
                .then(result => {
                    console.log(result.data, "amount");
                    if (result.data.status) {

                        let amount = 0
                        let list = result.data.data
                        for (let i = result.data.data.length - 1; i >= 0; i--) {
                            if (result.data.data[i].credit !== "0") {
                                amount = parseFloat(amount) - parseFloat(result.data.data[i].credit)
                                list[i].amount = amount
                            }
                            else {
                                amount = parseFloat(amount) + parseFloat(result.data.data[i].debit)
                                list[i].amount = amount
                            }
                        }
                        this.setState({
                            list: list,
                            finalWalletAmount: result.data.amount
                        });
                    }
                });
        }
    };
    render() {
        return (
            <body
                class="vertical-layout vertical-menu-modern semi-dark-layout content-left-sidebar chat-application navbar-floating footer-static  "
                data-open="click"
                data-menu="vertical-menu-modern"
                data-col="content-left-sidebar"
                data-layout="semi-dark-layout"
            >

                <div class="app-content content">
                    <div class="content-overlay"></div>
                    <div class="header-navbar-shadow"></div>
                    <div class="content-wrapper">
                        <div class="content-header row">
                            <div class="content-header-left col-md-9 col-12 mb-2">
                                <div class="row breadcrumbs-top">
                                    <div class="col-12">
                                        <h2 class="content-header-title float-left mb-0">
                                            History
                                        </h2>
                                        <div class="breadcrumb-wrapper col-12">
                                            <ol class="breadcrumb" >
                                                <li class="breadcrumb-item">
                                                    <Link to="/dashboard">Home</Link>
                                                </li>
                                                <li class="breadcrumb-item active">Transaction History</li>
                                            </ol>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="content-body" style={{ paddingBottom: "0px" }}>
                            <section id="add-row">
                                <div class="row">
                                    <div class="col-12">
                                        <div class="card follow-box">
                                            <div class="card-content ">
                                                <div class="card col-12">
                                                    <div class="col-md-12 col-12 ">
                                                        <div class="card">
                                                            <h4 className="th-header">Transaction History</h4>
                                                            <div class="row" style={{ textAlign: "center" }}>
                                                                <div class="col-2">
                                                                    <h5> Date</h5>
                                                                </div>
                                                                <div class="col-3">
                                                                    <h5>Description</h5>
                                                                </div>
                                                                <div class="col-2">
                                                                    <h5>Credit</h5>
                                                                </div>
                                                                <div class="col-2">
                                                                    <h5 style={{ paddingLeft: "0px" }}>Debit</h5>
                                                                </div>
                                                                <div class="col-2" style={{ paddingLeft: "0px" }}>
                                                                    <h5>Balance</h5>
                                                                </div>
                                                            </div>

                                                            <div class="card-body" >
                                                                <div className="row wallet-list" style={{ textAlign: "center" }}>
                                                                    <div class="col-2">
                                                                        <p class="truncate" style={{ fontSize: "13px" }}>
                                                                            10/9/2020
                                                                                </p>
                                                                    </div>
                                                                    <div class="col-3">

                                                                        <h6 class="font-weight-bold mb-0">
                                                                            Payout fee</h6>

                                                                    </div>
                                                                    <div class="col-2">


                                                                    </div>
                                                                    <div class="col-2">
                                                                        $30

                                                                    </div>
                                                                    <div class="col-2">
                                                                        <h6 >$0</h6>


                                                                    </div>

                                                                </div>
                                                                <div className="row wallet-list" style={{ textAlign: "center" }}>
                                                                    <div class="col-2">
                                                                        <p class="truncate" style={{ fontSize: "13px" }}>
                                                                            10/9/2020
                                                                                </p>
                                                                    </div>
                                                                    <div class="col-3">

                                                                        <h6 class="font-weight-bold mb-0">
                                                                            Payout fee</h6>

                                                                    </div>
                                                                    <div class="col-2">

                                                                    $10
                                                                    </div>
                                                                    <div class="col-2">
                                                                        

                                                                    </div>
                                                                    <div class="col-2">
                                                                        <h6 >$20</h6>


                                                                    </div>

                                                                </div>
                                                                {/* {this.state.list.length
                                      ? this.state.list.map((list, index) => (
                                        <div className="row wallet-list">
                                          <div class="col-6" style={{ display: "flex" }}>
                                            <div class="pr-1">
                                              {/* <span class="avatar m-0 avatar-md trigger-wallet-div" style={{
                                                background:
                                                  "url(" + money + ")"
                                              }}>
                                                
                                                
                                              </span> 
                                            </div>
                                            <div class="user-chat-info">
                                              <div class="contact-info">
                                                <h6 class="font-weight-bold mb-0">
                                                  {list.message} {" "}{list.productId ? list.productId.product_name : null}                                        </h6>

                                                <p class="truncate" style={{ fontSize: "13px" }}>
                                                  {moment(list.createdAt).format(
                                                    "DD-MM-YYYY,HH:mm"
                                                  )}
                                                </p>
                                              </div>
                                            </div>
                                          </div>
                                          <div class="col-2">
                                            {list.debit === "0" && list.credit !== "0" ?
                                              <h6 style={{ color: "red" ,paddingLeft:"10px"}}> {list.credit}</h6>
                                            :null}

                                          </div>
                                          <div class="col-2">
                                            {list.debit !== "0" && list.credit === "0" ?
                                              <h6 style={{ color: "green" }}> {list.debit}</h6>
                                              :
                                            null
                                            }

                                          </div>
                                          <div class="col-2">
                                              <h6 >{list.amount}</h6>
                                            

                                          </div>
  
                                        </div>
                                      ))
                                      : <p>Transaction not Available</p>} */}

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                </div>

                <Footer />
                <div class="sidenav-overlay"></div>
                <div class="drag-target"></div>
            </body>

        );
    }
}
export default History;
