import React from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
//import moment from "moment";
// import Sidebar from "../../Component/Sidebar/Sidebar";
//import Header from "../../Component/Header/Header";
import Footer from '../../Component/Footer/Footer';
import global from '../../global';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import './Product.css';
var sellerData = {};

const $ = require('jquery');
$.DataTable = require('datatables.net');
var users = [];

const CustomToast = () => {
  return (
    <div>
      <i class="fa fa-check success-toast"></i>
      Offer Deleted Successfully
    </div>
  );
};
class ContactList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      seller: '',
      users: [],
    };
  }

  componentWillMount() {
    if (localStorage.getItem('sellerdata')) {
      this.setState({ seller: JSON.parse(localStorage.getItem('sellerdata')) });
      sellerData = JSON.parse(localStorage.getItem('sellerdata'));
    }
    this.listAPI();
  }

  // dataTable() {
  //   $(document).ready(function() {
  //     $("#list").DataTable();
  //   });
  //   console.log("working");
  // }
  dataTable() {
    $(document).ready(function () {
      $('#list').DataTable();
    });
  }
  listAPI() {
    console.log(this.state.seller);
    var urlencoded = new URLSearchParams();
    console.log(sellerData.id);
    urlencoded.append('sellerId', sellerData.id);

    axios
      .post(global.baseUrl + 'contactList', urlencoded, {
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      })
      .then((result) => {
        console.log(result.data);
        if (result.data.status) {
          users = result.data.data;
          this.setState({ users: result.data.data });
          //localStorage.setItem('userdata', JSON.stringify(result.data))
          //this.props.history.push("/setotp/" + result.data.data.id);

          //this.dataTable()
          console.log('mount', users);
          this.dataTable();
        }
      });
  }
  deleteHandler = (productID) => {
    var r = window.confirm('Are you sure to delete the record?');
    if (r) {
      var urlencoded = new URLSearchParams();
      console.log(sellerData.id);
      urlencoded.append('id', productID);
      urlencoded.append('userId', this.state.seller.id);

      axios
        .post(global.baseUrl + 'sellerofferdelete', urlencoded, {
          headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        })
        .then((result) => {
          console.log(result.data);
          toast.success(<CustomToast />, {
            position: toast.POSITION.TOP_RIGHT,
          });
        });
      this.setState(
        {
          products: this.state.products.filter(
            (product) => product.id !== productID
          ),
        },
        () => {
          console.log(this.state.products);
          this.dataTable();
        }
      );
    }
  };
  render() {
    return (
      <body
        class="vertical-layout vertical-menu-modern semi-dark-layout 2-columns  navbar-floating footer-static  "
        data-open="click"
        data-menu="vertical-menu-modern"
        data-col="2-columns"
        data-layout="semi-dark-layout"
      >
        {/* <Header />
        <Sidebar /> */}
        <div class="app-content content">
          <div class="content-overlay"></div>
          <div class="header-navbar-shadow"></div>
          <div class="content-wrapper">
            <div class="content-header row">
              <div class="content-header-left col-md-9 col-12 mb-2">
                <div class="row breadcrumbs-top">
                  <div class="col-12">
                    <h2 class="content-header-title float-left mb-0">
                      Contact List:All Contact
                    </h2>
                    <div class="breadcrumb-wrapper col-12">
                      <ol class="breadcrumb">
                        <li class="breadcrumb-item">
                          <Link to="/dashboard">Home</Link>
                        </li>
                        <li class="breadcrumb-item active">Contact</li>
                      </ol>
                    </div>
                  </div>
                </div>
              </div>
              <div class="content-header-right text-md-right col-md-3 col-12 d-md-block d-none">
                <div class="form-group breadcrum-right">
                  {/* <Link
                    to="/addoffer"
                    id="addRow"
                    class="btn btn-primary mb-2 add-product-link"
                  >
                    <i class="feather icon-plus"></i>Add contact
                  </Link> */}
                </div>
              </div>
            </div>
            <div class="content-body">
              <section id="add-row">
                <div class="row">
                  <div class="col-12">
                    <div class="card">
                      {/* <div class="card-header">
                                    <h4 class="card-title">Add rows</h4>
                                </div> */}
                      <div class="card-content">
                        <div class="card-body">
                          {/* <button id="addRow" class="btn btn-primary mb-2"><i class="feather icon-plus"></i>&nbsp; Add new row</button> */}
                          <div class="table-responsive">
                            {console.log(users)}
                            <table className="w-100" id="list">
                              <thead>
                                <tr>
                                  <th style={{ minWidth: '50px' }}>Id</th>
                                  <th style={{ minWidth: '200px' }}>Name</th>
                                  <th style={{ minWidth: '200px' }}>Address</th>
                                  <th style={{ minWidth: '160px' }}>
                                    #of purchase
                                  </th>
                                  <th style={{ minWidth: '150px' }}>
                                    Total Spent
                                  </th>
                                  <th style={{ minWidth: '150px' }}>
                                    Last Activity
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {/* <tr>
                              <td>Id</td>
                                  <td>Percentage</td>
                                  <td>Start Date</td>
                                  <td>End Date</td>
                                  <td>MinQty</td>
                                  <td>MaxQty</td>
                                  <td>Offer Type</td>
                                  <td>Offer On</td>
                                  <td>Action</td>
                                  </tr> */}
                                {this.state.users[0] ? (
                                  this.state.users.map((user, index) => (
                                    <tr key={index}>
                                      <td>{index + 1}</td>
                                      <td>
                                        {user.userId ? (
                                          <Link
                                            to={'/buyer-chat/' + user.userId.id}
                                          >
                                            {user.userId.name}
                                          </Link>
                                        ) : null}
                                      </td>
                                      <td>
                                        {user.userId
                                          ? user.userId.street_address
                                          : null}
                                      </td>
                                      <td>{user.quantity}</td>
                                      <td>{user.grand_total}</td>
                                      <td>
                                        {user.userId
                                          ? user.userId.createdAt
                                          : null}
                                      </td>

                                      {/* {/* <td>
                                            {product.productId
                                              ? product.productId.product_name
                                              : product.brandId
                                              ? product.brandId.name
                                              : product.categoryId
                                              ? product.categoryId.name
                                              : null}
                                          </td>
                                          <td>
                                            <Link
                                              to={`/editoffer/${product.id}`}
                                            >
                                              <i
                                                className="fa fa-pencil"
                                                style={{
                                                  marginLeft: "10px",
                                                  marginRight: "10px",
                                                  color: "green"
                                                }}
                                              ></i>
                                            </Link>
                                            <i
                                              className="fa fa-trash"
                                              onClick={() =>
                                                this.deleteHandler(product.id)
                                              }
                                              style={{ marginRight: "20px" }}
                                            ></i>
                                          </td> */}
                                    </tr>
                                  ))
                                ) : (
                                  <tr>
                                    <td colspan="6" class="text-center p-4">
                                      No Contact
                                    </td>
                                  </tr>
                                )}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
        <div class="sidenav-overlay"></div>
        <div class="drag-target"></div>

        <Footer />
      </body>
    );
  }
}

export default ContactList;
