import React from 'react';
import axios from 'axios';
//import Select from "react-select";
import { Link } from 'react-router-dom';
import Footer from '../../Component/Footer/Footer';
import global from '../../global';
//import Avtar from "./avtar.jpg";
import moment from 'moment';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import $ from 'jquery';
import Select from 'react-select';

import './style.css';
var seller = {};
var startDateError = '';
var endDateError = '';
var typeError = '';
var pageError = '';
var submitDisable = false;
var imageError = '';
var imageError2 = '';
var imageError3 = '';
var bannerLocationError = '';
var sendImage = '';
var sendImage2 = '';
var sendImage3 = '';
var sendVideo = '';
toast.configure();
const CustomToast = () => {
  return (
    <div>
      <i className="fa fa-check success-toast"></i>
      Sponsor Banner Created Successfully
    </div>
  );
};
class EditBanner extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: this.props.match.params.id,
      pages: [''],
      edate: false,
      sdate: false,
      vide: false,
      selectedPage: [],
      rates: [''],
      bannerPageName: [],
      bannerPrice: [],
      bannerImage: '',
      bannerImage2: '',
      bannerImage3: '',
      bannerpricecharge: '',
      totalAmount: '',
      fileName: '',
      bannerPages: [],
      autoProductOptions: [],
      s: [],
      days: '',
      percentage: '',
      startDate: '',
      endDate: '',
      minQty: '',
      maxQty: '',
      bannerLocation: '',
      desiredLocation: '',
      offerType: '',
      productId: '',
      categoryId: '',
      brandId: '',
      products: [],
      categories: [],
      brands: [],
      banners: [],
      addOption: '',
      productlist: [],
      removeOption: '',
      addradio: 'Big',
      productLink: 0,
      value2: '',
      nextOptions: [],
      val: '',
      link: '',
      Video: '',
      BannerLocations: [
        {
          label: 'Select Banner Location',
          value: '',
        },
        {
          label: 'Banner Picture 1st Page',
          value: 'Banner_Picture_1st_Page',
        },
        {
          label: 'Banner Picture 1st Page',
          value: 'Banner_Picture_1st_Page',
        },
        {
          label: 'Banner Picture 1st Page',
          value: 'Banner_Picture_1st_Page',
        },
        {
          label: 'Banner Picture 1st Page',
          value: 'Banner_Picture_1st_Page',
        },
        {
          label: 'Banner Picture 1st Page',
          value: 'Banner_Picture_1st_Page',
        },
        {
          label: 'Banner Picture 2nd Page',
          value: 'Banner_Picture_2nd_Page',
        },
        {
          label: 'Banner Picture 2nd Page',
          value: 'Banner_Picture_2nd_Page',
        },
        {
          label: 'Banner Picture 2nd Page',
          value: 'Banner_Picture_2nd_Page',
        },
        {
          label: 'Banner Picture 2nd Page',
          value: 'Banner_Picture_2nd_Page',
        },
        {
          label: 'Banner Picture 2nd Page',
          value: 'Banner_Picture_2nd_Page',
        },
        {
          label: 'Banner Video 1st Page',
          value: 'Banner_Video_1st_Page',
        },
        {
          label: 'Banner Bottom Page 1st Page',
          value: 'Banner_Bottom_1st_Page',
        },
        {
          label: 'Banner Bottom Page 1st Page',
          value: 'Banner_Bottom_1st_Page',
        },
        {
          label: 'Banner Bottom Page 1st Page',
          value: 'Banner_Bottom_1st_Page',
        },
        {
          label: 'Banner Bottom Page 1st Page',
          value: 'Banner_Bottom_1st_Page',
        },
        {
          label: 'Banner Bottom Page 1st Page',
          value: 'Banner_Bottom_1st_Page',
        },
      ],
      DesiredLocations: [
        {
          label: 'Select Banner Location',
          value: '',
        },
        {
          label: 'Top',
          value: 'Top',
        },
        {
          label: 'Bottom',
          value: 'Bottom',
        },
      ],
    };
    startDateError = '';
    endDateError = '';
    submitDisable = false;
    pageError = '';
    bannerLocationError = '';
    imageError = '';
    imageError2 = '';
    imageError3 = '';
    sendVideo = '';
  }
  componentWillMount() {
    if (localStorage.getItem('sellerdata')) {
      seller = JSON.parse(localStorage.getItem('sellerdata'));
      this.setState({ seller: JSON.parse(localStorage.getItem('sellerdata')) });

      axios
        .get(
          global.baseUrl + 'getaboutdetail',
          {},
          {
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
          }
        )
        .then((result) => {
          console.log(result.data.data[0], 'about');
          if (result.data.data[0]) {
            // if (result.data.data[0]) {
            this.setState({
              bannerpricecharge: result.data.data[0].sponcer_banner_price,
            });
            // this.setState({ a: "" });
            // }
          }
        });
      var urlencoded2 = new URLSearchParams();
      console.log(this.state.id, '===id');
      urlencoded2.append('id', this.state.id);
      axios
        .post(global.baseUrl + 'getbannerbyid', urlencoded2, {
          headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        })
        .then((result) => {
          console.log(result.data);
          if (result.data.data[0]) {
            //   this.setState({ bannerPages: result.data.data });
            this.setState({ sdate: true });
            this.setState({ edate: true });
            this.setState({ vide: true });
            this.setState({ startDate: result.data.data[0].start_date });
            this.setState({ endDate: result.data.data[0].end_date });
            this.setState({
              bannerImage: result.data.data[0].banner_image_app,
            });
            this.setState({
              bannerImage2: result.data.data[0].banner_image_web,
            });
            this.setState({
              bannerLocation: result.data.data[0].banner_location
                ? result.data.data[0].banner_location
                : '',
            });
            this.setState({ Video: result.data.data[0].video });
            //localStorage.setItem('userdata', JSON.stringify(result.data))
            // this.props.history.push("/offerlist");
            //           startDate
            // endDate
            // bannerImage
            // bannerImage2
            // bannerLocation
            // Video
          }
        });

      //this.dashboardAPI(seller.id);
    }

    var urlencoded = new URLSearchParams();

    axios
      .post(global.baseUrl + 'sellerbannerpagelist', urlencoded, {
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      })
      .then((result) => {
        console.log(result.data);
        if (result.data.status) {
          this.setState({ bannerPages: result.data.data });
          //localStorage.setItem('userdata', JSON.stringify(result.data))
          // this.props.history.push("/offerlist");
        }
      });
    this.ProductListAPICall();
  }
  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value }, () => {
      this.setDays();
    });
  };
  OnChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };
  setDays = () => {
    let days = moment(this.state.endDate).diff(
      moment(this.state.startDate),
      'days'
    );
    this.setState({ days: days });
    let bannerPrice = this.state.bannerPrice;

    let total = 0;
    for (let j = 0; j < bannerPrice.length; j++) {
      total = total + parseInt(bannerPrice[j]);
    }
    total = total * (days + 1);
    this.setState({ total: total });
  };
  addPageHandler = () => {
    let pages = this.state.pages;
    pages.push('');
    this.setState({ pages: pages });
    let rates = this.state.rates;
    rates.push('');
    this.setState({ rates: rates });
  };
  handleBannerChange = (event, index) => {
    console.log(this.state.selectedPage, 'index');
    let flag = 1;
    let selectedPage = this.state.selectedPage;
    for (let i = 0; i < selectedPage.length; i++) {
      if (selectedPage[i] === event.target.value) {
        flag = 0;
        console.log('working');
      }
    }
    console.log(flag);
    let bannerPageName = this.state.bannerPageName;
    let bannerPrice = this.state.bannerPrice;

    if (flag === 1) {
      console.log('Working', flag);
      selectedPage[index] = event.target.value;
      bannerPageName = this.state.bannerPageName;
      bannerPrice = this.state.bannerPrice;
      for (let i = 0; i < this.state.bannerPages.length; i++) {
        if (event.target.value === this.state.bannerPages[i].id) {
          bannerPageName[index] = this.state.bannerPages[i].page;
          bannerPrice[index] = this.state.bannerPages[i].price;
        }
      }

      this.setState({
        selectedPage: selectedPage,
        bannerPageName: bannerPageName,
        bannerPrice: bannerPrice,
      });
    } else {
      selectedPage[index] = '';
      bannerPrice[index] = 0;
      this.setState({
        selectedPage: selectedPage,
      });
    }
    if (event.target.value === '') {
      bannerPrice[index] = 0;
    }
    console.log(bannerPrice);
    let total = 0;
    for (let j = 0; j < bannerPrice.length; j++) {
      total = total + parseInt(bannerPrice[j]);
    }
    total = total * (this.state.days + 1);
    this.setState({ total: total });
  };
  onImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      var Extension = ['jpg', 'jpeg', 'png', 'gif', 'tiff', 'mp4'];
      var extArray = event.target.files[0].name.split('.');
      var ext = extArray[extArray.length - 1];
      var flag = 0;
      for (let i = 0; i < Extension.length; i++) {
        if (ext.toLowerCase() === Extension[i]) {
          flag = 1;
        }
      }
      if (flag) {
        this.setState({ imagError: '' });
        imageError = '';

        let reader = new FileReader();
        reader.onload = (e) => {
          this.setState({
            bannerImage: e.target.result,
          });
        };
        this.setState({
          fileName: event.target.files[0].name,
        });
        reader.readAsDataURL(event.target.files[0]);
        sendImage = event.target.files[0];
        console.log('image', sendImage);
      } else {
        this.setState({
          // bannerImage: '',
          imagError: 'Please select only image',
          fileName: '',
        });
        sendImage = '';
        imageError = 'Please select only image';
      }
    } else {
      sendImage = '';
    }
  };
  APICall = () => {
    console.log('API');
    var urlencoded = new URLSearchParams();
    urlencoded.append('id', this.state.id);
    urlencoded.append('sellerId', seller.id);
    urlencoded.append('start_date', this.state.startDate);
    urlencoded.append('end_date', this.state.endDate);
    // this.state.fileName===
    urlencoded.append('banner_image_app', this.state.bannerImage);
    urlencoded.append('banner_image_web', this.state.bannerImage2);
    urlencoded.append('banner_location', this.state.bannerLocation);
    if (this.state.Video) {
      urlencoded.append('video', this.state.Video);
    } else {
      urlencoded.append('video', '');
    }
    let finaltotal = '';
    // if(this.state.edate===true){
    //   this.setState(new Date(this.state.endDate))
    // }
    // if(this.state.sdate===true){
    //   this.setState(new Date(this.state.startDate))
    // }
    let time_difference =
      new Date(this.state.endDate).getTime() -
      new Date(this.state.startDate).getTime();
    let days_difference = time_difference / (1000 * 60 * 60 * 24);
    finaltotal =
      parseInt(days_difference) * parseInt(this.state.bannerpricecharge);
    urlencoded.append('price', finaltotal);
    axios
      .post(global.baseUrl + 'newEditBanner', urlencoded, {
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      })
      .then((result) => {
        console.log(result.data, 'product submit');
        if (result.data) {
          this.setState({ banners: result.data.data });
          this.props.history.push('/bannerlist');
        }
      });
  };

  AddBannerAPI() {
    let days = moment(this.state.endDate).diff(
      moment(this.state.startDate),
      'days'
    );
    for (let i = 0; i < this.state.selectedPage.length; i++) {
      if (this.state.selectedPage[i] !== '') {
        let urlencoded = new URLSearchParams();
        urlencoded.append('pageId', this.state.selectedPage[i]);
        urlencoded.append('bannerId', this.state.bannerId);
        urlencoded.append('sellerId', this.state.seller.id);
        urlencoded.append('days', parseInt(days) + 1);
        urlencoded.append('price', '0');

        axios
          .post(global.baseUrl + 'selleraddbanner', urlencoded, {
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
          })
          .then((result) => {
            console.log(result.data);
            if (result.data.status) {
              //this.AddBannerAPI()
              //localStorage.setItem('userdata', JSON.stringify(result.data))
              ///this.props.history.push("/offerlist");
            }
          });
      }
      console.log(i, this.state.selectedPage.length);
      if (i === this.state.selectedPage.length - 1) {
        toast.success(<CustomToast />, { position: toast.POSITION.TOP_RIGHT });

        this.props.history.push('/bannerlist');
      }
    }
  }
  addImage = () => {
    console.log('add image ');
    // if (this.state.bannerImage === '') {
    if (sendImage) {
      const apiUrl = global.baseUrl + 'sellerImageUpload';
      const formData = new FormData();
      formData.append('myFile', sendImage);
      const config = {
        headers: {
          'content-type': 'multipart/form-data',
        },
      };
      axios
        .post(apiUrl, formData, config)
        .then((response) => {
          console.log('image add', response.data.filename);
          this.setState({ bannerImage: response.data.path });
          this.addImage2();
        })
        .catch((error) => {});
    }
    else {
      this.addImage2();
    }
  };
  handleSubmit = (e) => {
    console.log('submit haNDLER');
    let numberCheck = /^[0-9]\d*(\.\d+)?$/;

    ///^[0-9]+$/;
    if (this.state.startDate === '') {
      this.setState({ startDateError: 'Please select start date' });
      startDateError = 'Please select start date';
    }
    // else if (this.state.startDate.length > 10) {
    //   this.setState({ startDateError: "Please select valid start date" });
    //   startDateError = "Please select valid start date";
    // }
    else {
      this.setState({ startDateError: '' });
      startDateError = '';
    }
    if (this.state.endDate === '') {
      this.setState({ endDateError: 'Please select end date' });
      endDateError = 'Please select end date';
    }
    // else if (this.state.endDate.length > 10) {
    //   this.setState({ endDateError: "Please select valid end date" });
    //   endDateError = "Please select valid end date";
    // }
    else {
      // if (Date.parse(this.state.endDate) < Date.parse(this.state.startDate)) {
      //   this.setState({ endDateError: "End date less then start date" });
      //   endDateError = "End date less then start date";
      // } else {
      this.setState({ endDateError: '' });
      endDateError = '';
      // }
    }
    if (this.state.bannerImage === '') {
      this.setState({ imageError: 'Please select file' });
      imageError = 'Please select file';
    } else {
      this.setState({ imageError: '' });
      imageError = '';
    }

    if (this.state.bannerImage2 === '') {
      this.setState({ imageError2: 'Please select file' });
      imageError2 = 'Please select file';
    } else {
      this.setState({ imageError2: '' });
      imageError2 = '';
    }

    // if (this.state.bannerLocation === "") {
    //   this.setState({ bannerLocationError: "Please select banner location" });
    //   bannerLocationError = "Please select banner location";
    // } else {
    //   this.setState({ bannerLocationError: "" });
    //   bannerLocationError = "";
    // }

    if (this.state.selectedPage.length < 1) {
      this.setState({ pageError: 'Please select page' });
      pageError = 'Please select desired page';
    } else if (this.state.selectedPage.length > 0) {
      let flag = 0;
      for (let i = 0; i < this.state.selectedPage.length; i++) {
        console.log(this.state.selectedPage[i], 'djkgfhg');
        if (this.state.selectedPage[i]) {
          flag = 1;
        }
      }
      if (flag === 0) {
        this.setState({ pageError: 'Please selects page' });
        pageError = 'Please selects desired page';
      } else {
        this.setState({ pageError: '' });
        pageError = '';
      }
    } else {
      this.setState({ pageError: '' });
      pageError = '';
    }
    if (
      this.state.startDate &&
      this.state.endDate &&
      this.state.bannerImage &&
      this.state.bannerImage2 &&
      // this.state.bannerImage3&&
      // this.state.bannerLocation &&
      // pageError === "" &&
      startDateError === '' &&
      endDateError === '' &&
      imageError === '' &&
      imageError2 === ''
      // imageError3===""
      // &&
      // bannerLocationError === ""
    ) {
      submitDisable = true;

      this.addImage();
    } else {
      console.log('logggggg');
      this.addVideo();
    }
  };

  resetHandler = () => {
    this.setState({
      percentage: '',
      startDate: '',
      endDate: '',
      minQty: '',
      maxQty: '',
      offerType: '',
      productId: '',
      categoryId: '',
      brandId: '',
      products: [],
      categories: [],
      brands: [],
    });
    startDateError = '';
    endDateError = '';
    typeError = '';
    pageError = '';
    imageError = '';
    submitDisable = false;
  };
  handleFromDateChange = (date) => {
    this.setState({
      startDate: date,
      sdate: false,
    });
  };
  handleEndDateChange = (date) => {
    this.setState({
      endDate: date,
      edate: false,
    });
  };
  RemoveDropdownItem = (event) => {
    console.log('remoe');
    setTimeout(function () {
      $('option[value="banner_first"]:selected').attr('hidden', 'true');
      $('option[value="banner_two"]:selected').attr('hidden', 'true');
      $('option[value="banner_third"]:selected').attr('hidden', 'true');
      $('option[value="banner_four"]:selected').attr('hidden', 'true');
    }, 10);
  };
  onImageChange = (event) => {
    console.log('image1');
    if (event.target.files && event.target.files[0]) {
      var Extension = ['jpg', 'jpeg', 'png', 'gif', 'tiff', 'mp4'];
      var extArray = event.target.files[0].name.split('.');
      var ext = extArray[extArray.length - 1];
      var flag = 0;
      for (let i = 0; i < Extension.length; i++) {
        if (ext.toLowerCase() === Extension[i]) {
          flag = 1;
        }
      }
      if (flag) {
        this.setState({ imagError: '' });
        imageError = '';

        let fileName = event.target.files[0].name;

        let reader = new FileReader();

        reader.onload = (e) => {
          var image = new Image();
          let flag = 1;
          image.src = e.target.result;
          image.onload = () => {
            var height = image.height;
            var width = image.width;
            // console.log(height, width);
            if (width > 1280 && height > 590) {
              this.setState({
                imagError: 'Please select image(1280*590px)',
              });
              imageError = 'Please select image(1280*590px)';
              fileName = '';
              this.setState({
                // bannerImage: '',
                fileName: '',
              });
            } else if (width < 1280 && height < 590) {
              this.setState({
                imagError: 'Please select image(1280*590px)',
              });
              imageError = 'Please select image(1280*590px)';
              fileName = '';
              this.setState({
                // bannerImage: '',
                fileName: '',
              });
            } else {
              this.setState({
                bannerImage: e.target.result,
                fileName: fileName,
              });
            }
          };
        };

        reader.readAsDataURL(event.target.files[0]);
        sendImage = event.target.files[0];
        console.log('image', sendImage);
      } else {
        this.setState({
          // bannerImage: '',
          imagError: 'Please select only image',
          fileName: '',
        });
        sendImage = '';
        imageError = 'Please select only image';
      }
    } else {
      sendImage = '';
    }
  };
  onImageChange2 = (event) => {
    console.log('image2');
    if (event.target.files && event.target.files[0]) {
      var Extension = ['jpg', 'jpeg', 'png', 'gif', 'tiff', 'mp4'];
      var extArray = event.target.files[0].name.split('.');
      var ext = extArray[extArray.length - 1];
      var flag = 0;
      for (let i = 0; i < Extension.length; i++) {
        if (ext.toLowerCase() === Extension[i]) {
          flag = 1;
        }
      }
      if (flag) {
        this.setState({ imagError2: '' });
        imageError2 = '';

        let fileName2 = event.target.files[0].name;

        let reader = new FileReader();

        reader.onload = (e) => {
          var image2 = new Image();
          let flag = 1;
          image2.src = e.target.result;
          image2.onload = () => {
            var height = image2.height;
            var width = image2.width;
            console.log(height, width);
            if (width > 1500 && height > 400) {
              this.setState({
                imagError2: 'Please select image(1500*400px)',
              });
              imageError2 = 'Please select image(1500*400px)';
              fileName2 = '';
              this.setState({
                // bannerImage2: '',
                fileName2: '',
              });
            } else if (width < 1500 && height < 400) {
              this.setState({
                imagError2: 'Please select image(1500*400px)',
              });
              imageError2 = 'Please select image(1500*400px)';
              fileName2 = '';
              this.setState({
                // bannerImage2: '',
                fileName2: '',
              });
            } else {
              this.setState({
                bannerImage2: e.target.result,
                fileName2: fileName2,
              });
            }
          };
        };

        reader.readAsDataURL(event.target.files[0]);
        sendImage2 = event.target.files[0];
        console.log('image', sendImage2);
      } else {
        this.setState({
          // bannerImage2: '',
          imagError2: 'Please select only image',
          fileName2: '',
        });
        // sendImage2 = '';
        imageError2 = 'Please select only image';
      }
    } else {
      sendImage2 = '';
    }
  };
  addImage2 = () => {
    console.log('addimgae2');
    // if (this.state.bannerImage2 === '') {
    if (sendImage2) {
      const apiUrl = global.baseUrl + 'sellerImageUpload';
      const formData = new FormData();
      formData.append('myFile', sendImage2);
      const config = {
        headers: {
          'content-type': 'multipart/form-data',
        },
      };
      axios
        .post(apiUrl, formData, config)
        .then((response) => {
          console.log('image add', response.data.path);
          this.setState({ bannerImage2: response.data.path });
          this.APICall();
        })
        .catch((error) => {});
    }
    else {
      this.APICall();
    }
  };
  BannerDropChangeHandler = async (event) => {
    this.setState({
      bannerLocation: event.target.value,
      // BannerLocations: this.state.BannerLocations.filter(item => item.value !== this.state.val)
    });
    let newValue = this.state.BannerLocations.filter(
      (item) => item.value === event.target.value
    );
    console.log(newValue);
    newValue[0].isDisable = true;
  };
  DesiredLocationDropChangeHandler = async (event) => {
    this.setState({
      desiredLocation: event.target.value,
    });
  };
  defaultOptionHandler = (e) => {
    this.setState({ addOption: e.target.value });
  };
  autoOptionHandler = (e) => {
    console.log('remove', e.target.value);
    this.setState({ removeOption: e.target.value });
  };
  ProductListAPICall = () => {
    console.log('Product API');
    let urlencoded = new URLSearchParams();
    urlencoded.append('sellerId', seller.id);

    axios
      .post(global.baseUrl + 'sellerProductList', urlencoded, {
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      })
      .then((result) => {
        console.log(result.data, 'product');
        if (result.data) {
          let productlist = this.state.productlist;
          let addOption = this.state.nextOptions;
          for (let i = 0; i < result.data.data.length; i++) {
            if (this.state.productlist) {
              productlist.push({
                id: result.data.data[i].id,
                label: result.data.data[i].product_name,
              });
            } else {
              this.state.nextOptions.push({
                id: result.data.data[i].id,
                label: result.data.data[i].product_name,
              });
            }
          }
          this.setState({
            productlist,
            addOption,
          });
        }
      });
  };
  addOptionHandler = () => {
    let addOption = '';
    let nextOption = '';
    if (this.state.addOption !== '') {
      let r = window.confirm('Are you sure for product?');
      if (r) {
        let productlist = this.state.productlist;

        for (let i = 0; i < productlist.length; i++) {
          if (this.state.addOption === productlist[i].id) {
            console.log(addOption, 'addd');

            addOption = productlist[i];
            let urlencoded = new URLSearchParams();
            urlencoded.append('sellerId', seller.id);
            urlencoded.append('productId', productlist[i].id);

            axios
              .post(global.baseUrl + 'multiProductAdd', urlencoded, {
                headers: {
                  'Content-Type': 'application/x-www-form-urlencoded',
                },
              })
              .then((result) => {
                console.log(result.data, 'amount');
                if (result.data.status) {
                  //defaultOptions.splice(i, 1)
                }
              });
            if (i !== productlist.length - 1) {
              nextOption = productlist[i + 1].id;
            }
            console.log(nextOption, 'll');
            productlist.splice(i, 1);
          }
        }

        let productOption = this.state.productlist;
        this.state.nextOptions.push(addOption);

        this.setState({ addOption: this.state.nextOptions });
        this.setState({
          productlist,
        });
      }
    } else {
      console.log('not working');
      // toast.warn(<WarnToast />, {
      //   position: toast.POSITION.TOP_RIGHT
      // });
    }
  };
  removeOptionHandler = () => {
    console.log('remove');
    let removeOption = '';
    let nextOption = '';
    console.log('remove1');
    alert(this.state.removeOption);
    if (this.state.removeOption != '') {
      // console.log("remove2",this.state.nextOptions)
      let productOptions = this.state.nextOptions;
      console.log(productOptions, 'product');
      productOptions.map((item) => {
        console.log(item, 'loop');
        if (item.id == this.state.removeOption) {
          let check = productOptions.findIndex(
            (val) => val.id == this.state.removeOption
          );
          console.log('check satyam :::', check);
          productOptions.splice(check, 1);
          this.setState({ nextOptions: productOptions });
        }
      });
      // for (let i = 0; i < productOptions.length; i++) {
      //  console.log(productOptions,"remove option")
      //   if (this.state.removeOption == productOptions[i].id) {
      //     removeOption = productOptions[i]
      //     let urlencoded = new URLSearchParams();
      //     urlencoded.append("sellerId", seller.id);
      //     urlencoded.append("productId", productOptions[i].id);
      //     // urlencoded.append("sponsoredproduct");
      //     axios
      //       .post(global.baseUrl + "MultiProductRemove", urlencoded, {
      //         headers: { "Content-Type": "application/x-www-form-urlencoded" }
      //       })
      //       .then(result => {
      //         console.log(result.data, "remove item");
      //         if (result.data) {
      //           // productOptions.splice(i, 1)

      //         }
      //       });
      //     if (i !== (productOptions.length - 1)) {
      //       nextOption = productOptions[i + 1].id

      //     }
      //     productOptions.splice(i, 1)
      //     console.log(productOptions, "option")
      //   } else {
      //     console.log("else")
      //   }
      // }
      // let temp = this.state.nextOptions
      // console.log(temp, "nextoptions....")
      // temp.push(removeOption)

      // this.setState({ removeOption: nextOption })
      // this.setState({
      //   temp
      // })
    }
  };
  addAllOptionHandler = () => {
    let defaultOptions = this.state.defaultOptions;
    let autoRenewalOptions = this.state.autoRenewalOptions;
    // let total = defaultOptions.length * this.state.productCharge
    // if (total <= this.state.productCharge) {
    let r = window.confirm('Are you sure for product?');
    if (r) {
      let urlencoded = new URLSearchParams();
      urlencoded.append('sellerId', seller.id);
      for (let i = 0; i < this.state.productlist.length; i++) {
        urlencoded.append('productId', this.state.productlist[i].id);
        this.state.nextOptions.push(this.state.productlist[i]);
      }
      // urlencoded.append("renewal", "auto");
      axios
        .post(global.baseUrl + 'multiProductAdd', urlencoded, {
          headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        })
        .then((result) => {
          console.log(result.data, 'add all');
        });

      this.setState({
        productlist: [],
      });
    }
    // }
    // else {
    // toast.warn(<WarnToast />, {
    //   position: toast.POSITION.TOP_RIGHT
    // });
    // }
  };
  removeAllOptionHandler = () => {
    console.log('remove all');
    let defaultOptions = this.state.productlist;
    let addOption = this.state.addOption;

    let urlencoded = new URLSearchParams();
    urlencoded.append('sellerId', seller.id);
    for (let i = 0; i < addOption.length; i++) {
      urlencoded.append('productId', addOption[i].id);
      defaultOptions.push(addOption[i]);
    }
    // urlencoded.append("renewal", "manual");
    axios
      .post(global.baseUrl + 'MultiProductRemove', urlencoded, {
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      })
      .then((result) => {
        console.log(result.data, 'remove');
      });

    this.setState({
      defaultOptions,
      nextOptions: [],
    });
  };
  onVideoChange = (event) => {
    console.log('working', event);
    if (event.target.files && event.target.files[0]) {
      let reader = new FileReader();
      reader.onload = (e) => {
        // console.log("working", e.target.result);
      };
      reader.readAsDataURL(event.target.files[0]);
      this.setState({ Video: event.target.files[0].name });
      console.log('video', event.target.files[0].name);
      //console.log(event.target.files[0])
      sendVideo = event.target.files[0];
      this.setState({ vide: false });
    } else {
      this.setState({ Video: '' });
      sendVideo = '';
    }
  };
  addVideo = () => {
    // if (this.state.Video === '') {
    if (sendVideo) {
      console.log('image added');
      const apiUrl = global.baseUrl + 'sellerImageUpload';

      const formData = new FormData();
      formData.append('myFile', sendVideo);
      const config = {
        headers: {
          'content-type': 'multipart/form-data',
        },
      };
      axios
        .post(apiUrl, formData, config)
        .then((response) => {
          console.log(response.data, 'result ');
          this.setState({ Video: response.data.path });

          this.APICall();
        })
        .catch((error) => {});
    } else {
      this.APICall();
    }
    // } else {
    //   this.APICall();
    // }
  };
  render() {
    return (
      <body
        className="vertical-layout vertical-menu-modern semi-dark-layout 2-columns  navbar-floating footer-static  "
        data-open="click"
        data-menu="vertical-menu-modern"
        data-col="2-columns"
        data-layout="semi-dark-layout"
      >
        {/* <Header />
        <Sidebar /> */}
        <div className="app-content content">
          <div className="content-overlay"></div>
          <div className="header-navbar-shadow"></div>
          <div className="content-wrapper">
            <div className="content-header-left col-md-9 col-12 mb-2">
              <div className="row breadcrumbs-top">
                <div className="col-12">
                  <h2 className="content-header-title float-left mb-0">
                    <Link to={'/bannerlist'} className="back-arrow  mr-2">
                      <i className="feather icon-arrow-left"></i>
                    </Link>
                    Add Banner
                  </h2>
                  <div className="breadcrumb-wrapper col-12">
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <Link to="/offerlist">Banners</Link>
                      </li>
                      <li className="breadcrumb-item active">Add Banner</li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>
            <div className="content-body">
              <section id="dashboard-analytics">
                <div className="card">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="banners-title">
                          <h2>Banner</h2>
                        </div>
                      </div>
                      <div className="col-md-6 mb-2">
                        <div className="res-form-group">
                          <select
                            className="form-control disable-option"
                            id="selecter"
                            value={this.state.bannerLocation}
                            onChange={(e) => this.BannerDropChangeHandler(e)}
                          >
                            {this.state.BannerLocations.map(
                              (element, index) => (
                                <option
                                  value={element.value}
                                  disabled={element.isDisable}
                                >
                                  {element.label}
                                </option>
                              )
                            )}
                          </select>
                        </div>
                      </div>

                      <div className="col-md-4 text-align-start">
                        <button className="btn btn-primary add-product-link">
                          Add
                        </button>
                      </div>
                      <p
                        className="error-msg"
                        style={{ paddingLeft: '155px', marginTop: '-14px' }}
                      >
                        {bannerLocationError}
                      </p>
                    </div>
                    <div className="row mb-1">
                      <div className="col-12 col-lg-2">
                        <label className="b-label">
                          Start Date<span className="required-span1">*</span>
                        </label>
                      </div>
                      <div className="col-12 col-lg-4">
                        <div className="form-group sr-left  ma-left-minus">
                          {this.state.sdate === true ? (
                            <DatePicker
                              // selected={this.state.startDate}
                              value={new Date(
                                this.state.startDate
                              ).toLocaleDateString()}
                              onChange={this.handleFromDateChange}
                              showMonthDropdown
                              showYearDropdown
                              dropdownMode="select"
                              style={{ width: '100%' }}
                              className="form-control"
                              placeholderText="08/14/2021"
                            />
                          ) : (
                            <DatePicker
                              selected={this.state.startDate}
                              onChange={this.handleFromDateChange}
                              showMonthDropdown
                              showYearDropdown
                              dropdownMode="select"
                              style={{ width: '100%' }}
                              className="form-control"
                              placeholderText="08/14/2021"
                            />
                          )}

                          <p className="error-msg">{startDateError} </p>
                        </div>
                      </div>
                      <div className="col-12 col-lg-2">
                        <label className="b-label">
                          End Date<span className="required-span1">*</span>
                        </label>
                      </div>
                      <div className="col-12 col-lg-4">
                        <div className="form-group sr-left  ma-left-minus">
                          {console.log('date====', this.state.endDate)}
                          {this.state.edate === true ? (
                            <DatePicker
                              // selected={this.state.endDate}
                              // selected={moment(this.state.endDate, 'MM-DD-YYYY')}
                              // selected="10/15/2021"
                              // selected={this.state.endDate}
                              onChange={this.handleEndDateChange}
                              showMonthDropdown
                              showYearDropdown
                              value={new Date(
                                this.state.endDate
                              ).toLocaleDateString()}
                              dropdownMode="select"
                              className="form-control"
                              placeholderText="08/15/2021"
                              style={{ width: '100% ' }}
                            />
                          ) : (
                            <DatePicker
                              // selected={this.state.endDate}
                              // selected={moment(this.state.endDate, 'MM-DD-YYYY')}
                              // selected="10/15/2021"
                              selected={this.state.endDate}
                              onChange={this.handleEndDateChange}
                              showMonthDropdown
                              showYearDropdown
                              // value={this.state.endDate}
                              dropdownMode="select"
                              className="form-control"
                              placeholderText="08/15/2021"
                              style={{ width: '100% ' }}
                            />
                          )}
                          <p className="error-msg">{endDateError} </p>
                        </div>
                      </div>
                    </div>
                    {this.state.bannerLocation == 'Banner_Video_1st_Page' ? (
                      <>
                        <div className="row">
                          <div className="col-12 col-xl-2 col-md-4 max-single">
                            <label className="b-label">
                              Single file Upload
                              <span className="required-span1">*</span>
                            </label>
                          </div>
                          <div className="product-upload-btn pb-lg-2 col-12 col-md-5 col-xl-7">
                            <label
                              className="btn btn-sm btn-primary-green ml-50 mb-50 mb-sm-0 cursor-pointer up-btn"
                              for="account-upload1"
                            >
                              {/* Upload Single file */}
                              Upload Video
                            </label>
                            <input
                              id="account-upload1"
                              type="file"
                              accept="video/*"
                              onChange={(e) => this.onVideoChange(e)}
                              name="videoUpload"
                              hidden
                            />

                            {/* <p className="error-msg" style={{ paddingLeft: "10px" }}>
                            {imageError}
                          </p> */}
                          </div>
                          <div className="col-3 " style={{ marginTop: '5px' }}>
                            {this.state.vide ? (
                              <a href={this.state.Video}>
                                <button
                                  style={{
                                    backgroundColor: '#47a547',
                                    border: 'none',
                                    borderRadius: '5px',
                                    color: 'white',
                                  }}
                                >
                                  view
                                </button>
                              </a>
                            ) : (
                              <p>{this.state.Video}</p>
                            )}
                          </div>
                        </div>
                        <div className="row">
                          <div
                            className="col-md-12 mt-1 "
                            style={{ overflow: 'auto' }}
                          >
                            <p
                              style={{
                                fontSize: '17px',
                                color: '#000',
                                fontWeight: '600',
                              }}
                            >{`Amount $ ${this.state.bannerpricecharge} per day`}</p>
                            <p
                              style={{
                                fontSize: '17px',
                                color: '#000',
                                fontWeight: '600',
                                whiteSpace: 'nowrap',
                              }}
                            >
                              We will write the guideline in description `{'>>'}
                              ` Check garagelane guideline
                            </p>
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="row">
                          <div className="col-12 col-xl-2 col-md-4 max-single">
                            <label className="b-label">
                              Single file Upload
                              <span className="required-span1">*</span>
                            </label>
                          </div>
                          <div className="product-upload-btn pb-lg-2 col-12 col-md-5 col-xl-7">
                            <label
                              className="btn btn-sm btn-primary-green ml-50 mb-50 mb-sm-0 cursor-pointer up-btn"
                              for="account-upload"
                            >
                              {/* Upload Single file */}
                              Upload Single file (1280*590px)
                            </label>
                            <input
                              type="file"
                              id="account-upload"
                              accept="image/*"
                              name="imageUpload"
                              onChange={this.onImageChange}
                              multiple
                              hidden
                            />

                            <p
                              className="error-msg"
                              style={{ paddingLeft: '10px' }}
                            >
                              {imageError}
                            </p>
                          </div>
                          <div className="col-3 " style={{ marginTop: '5px' }}>
                            {this.state.fileName === undefined ||
                            this.state.fileName === '' ? (
                              // <p>{this.state.bannerImage.substr('http://3.141.68.71:5000/upload/'.length,this.state.bannerImage.length)}</p>
                              <img
                                style={{ height: '65px', width: '100px' }}
                                src={this.state.bannerImage}
                              ></img>
                            ) : (
                              <p>{this.state.fileName}</p>
                            )}
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-12 col-xl-2 col-md-4 max-single">
                            <label
                              className="b-label"
                              style={{ whiteSpace: 'nowrap' }}
                            >
                              Single file Upload2
                              <span className="required-span1">*</span>
                            </label>
                          </div>
                          <div className="product-upload-btn pb-2 col-12 col-md-5 col-xl-7">
                            <label
                              className="btn btn-sm btn-primary-green ml-50 mb-50 mb-sm-0 cursor-pointer up-btn"
                              for="account-upload2"
                            >
                              Upload Single file (1500*400px)
                            </label>
                            <input
                              type="file"
                              id="account-upload2"
                              accept="image/*,video/*"
                              name="imageUpload2"
                              onChange={this.onImageChange2}
                              multiple
                              hidden
                            />
                            <p
                              className="error-msg"
                              style={{ paddingLeft: '10px' }}
                            >
                              {imageError2}
                            </p>
                          </div>
                          <div className="col-3 " style={{ marginTop: '5px' }}>
                            {console.log(this.state.fileName2, 'filename2')}
                            {this.state.fileName2 === undefined ||
                            this.state.fileName2 === '' ? (
                              // <p>{this.state.bannerImage2.substr('http://3.141.68.71:5000/upload/'.length,this.state.bannerImage2.length)}</p>
                              <img
                                style={{ height: '65px', width: '100px' }}
                                src={this.state.bannerImage2}
                              ></img>
                            ) : (
                              <p>{this.state.fileName2}</p>
                            )}
                            {/* <p>{this.state.fileName2}</p> */}
                          </div>
                        </div>
                        <div className="row">
                          <div
                            className="col-md-12 mt-1 "
                            style={{ overflow: 'auto' }}
                          >
                            <p
                              style={{
                                fontSize: '17px',
                                color: '#000',
                                fontWeight: '600',
                              }}
                            >{`Amount $ ${this.state.bannerpricecharge} per day`}</p>
                            <p
                              style={{
                                fontSize: '17px',
                                color: '#000',
                                fontWeight: '600',
                                whiteSpace: 'nowrap',
                              }}
                            >
                              We will write the guideline in description `{'>>'}
                              ` Check garagelane guideline
                            </p>
                          </div>
                        </div>
                      </>
                    )}

                    <div className="row">
                      <div className="col-md-12" style={{ textAlign: 'end' }}>
                        <button
                          className="btn btn-primary add-product-link mt-2"
                          type="submit"
                          // disabled={submitDisable}
                          // className="btn btn-primary glow mb-1 mb-sm-0 mr-0 mr-sm-1 login-btn"
                          onClick={this.handleSubmit}
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>

        <div className="sidenav-overlay"></div>
        <div className="drag-target"></div>
        <Footer />
      </body>
    );
  }
}
export default EditBanner;
