import React from 'react';
import axios from 'axios';
import Select from 'react-select';
// import { Link } from "react-router-dom";
// import Sidebar from "../../Component/Sidebar/Sidebar";
// import Header from "../../Component/Header/Header";
import Footer from '../../Component/Footer/Footer';
import global from '../../global';
import './style.css';
//var submitDisable = false;
var paymentMethodError = '';
var accountNameError = '';
var bankNameError = '';
var routingCodeError = '';
var accountNumberError = '';
var accountTypeError = '';
var sendImage = '';
var checkError = '';
var paypalidError = '';
class PaymentMethod extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      accountName: '',
      bankName: '',
      routingCode: '',
      accountNumber: '',
      accountType: '',
      paypalid: '',
      paymentMethod: '',
      seller: '',
      check: false,
      newAccountNumber: '',
      paymentMethods: [
        {
          label: 'Direct Deposit',
          value: 'direct_deposit',
        },
        {
          label: 'Wire Transfer',
          value: 'wire_transfer',
        },
        {
          label: 'PayPal',
          value: 'paypal',
        },
      ],
      document: '',
      existingDoc: '',
      creditCards: [
        {
          cardHolder: '',
          cardNumber: '',
          expYear: '',
          expMonth: '',
          cvv: '',
          email: '',
          address: '',
        },
      ],
    };
    //  submitDisable = false;
    paypalidError = '';
    paymentMethodError = '';
    accountNameError = '';
    bankNameError = '';
    routingCodeError = '';
    accountNumberError = '';
    accountTypeError = '';
    sendImage = '';
  }

  componentWillMount() {
    if (localStorage.getItem('sellerdata')) {
      this.setState({ seller: JSON.parse(localStorage.getItem('sellerdata')) });
      let seller = JSON.parse(localStorage.getItem('sellerdata'));
      var urlencoded = new URLSearchParams();
      urlencoded.append('sellerId', seller.id);
      axios
        .post(global.baseUrl + 'sellergettaxform', urlencoded, {
          headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        })
        .then((result) => {
          console.log(result.data);
          if (result.data.status) {
            if (result.data.data.payment_method) {
              this.setState({
                paymentMethod: result.data.data.payment_method,
                accountName: result.data.data.account_name,
                bankName: result.data.data.bank_name,
                routingCode: result.data.data.routing_code,
                accountNumber: result.data.data.account_number,
                accountType: result.data.data.account_type,
                creditCard: result.data.data.credit_card,
                document: result.data.data.voided_check,
                paypalid:result.data.data.paypal_address,
              });
              console.log(result.data.data.credit_card.length, 'length');
              if (result.data.data.credit_card.length) {
                let card = [];
                for (let i = 0; i < result.data.data.credit_card.length; i++) {
                  card.push(JSON.parse(result.data.data.credit_card[i]));
                }
                console.log(card, 'card');
                this.setState({ creditCards: card });
              }
              if (result.data.data.account_number.length > 4) {
                let length = result.data.data.account_number.length;
                let newNumber = result.data.data.account_number.substring(
                  length,
                  8
                );
                console.log('new number', newNumber);
                this.setState({
                  newAccountNumber: '********' + newNumber,
                });
              }
            }
          }
        });
    }
    axios
      .get(global.baseUrl + 'GetTaxDescription', {
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      })
      .then((result) => {
        if (result.data.status) {
          this.setState({ taxDescription: result.data.data.description });
        }
      });
    ///this.listAPI();
  }
  onImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      let reader = new FileReader();
      reader.onload = (e) => {
        this.setState({
          document: e.target.result,
          existingDoc: '',
        });
      };
      reader.readAsDataURL(event.target.files[0]);
      sendImage = event.target.files[0];
    } else {
      this.setState({
        document: '',
      });
      sendImage = '';
    }
  };
  dropChangeHandler = (event) => {
    this.setState({
      paymentMethod: event.value,
    });
  };
  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };
  handleAccountChange = (event) => {
    this.setState({
      newAccountNumber: event.target.value,
      accountNumber: event.target.value,
    });
  };
  addImage = () => {
    if (sendImage) {
      const apiUrl = global.baseUrl + 'sellerImageUpload';
      const formData = new FormData();
      formData.append('myFile', sendImage);
      const config = {
        headers: {
          'content-type': 'multipart/form-data',
        },
      };
      axios
        .post(apiUrl, formData, config)
        .then((response) => {
          console.log('image add', response.data.filename);
          this.setState({ document: response.data.path });
          this.addApiCall();
        })
        .catch((error) => {});
    } else {
      this.addApiCall();
    }
  };
  addApiCall = () => {
    var urlencoded = new URLSearchParams();
    urlencoded.append('sellerId', this.state.seller.id);
    urlencoded.append('payment_method', this.state.paymentMethod);
    urlencoded.append('account_name', this.state.accountName);
    urlencoded.append('bank_name', this.state.bankName);
    urlencoded.append('routing_code', this.state.routingCode);
    urlencoded.append('account_number', this.state.accountNumber);
    urlencoded.append('account_type', this.state.accountType);
    urlencoded.append('voided_check', this.state.document);
    urlencoded.append('paypal_address', this.state.paypalid);
    for (let i = 0; i < this.state.creditCards.length; i++) {
      urlencoded.append(
        'credit_card',
        JSON.stringify(this.state.creditCards[i])
      );
    }

    axios
      .post(global.baseUrl + 'sellerThirdTaxForm', urlencoded, {
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      })
      .then((result) => {
        console.log(result.data);
        if (result.data.status) {
          if (this.state.seller.type === 'Retailer') {
            this.props.history.push('/taxform');
          } else {
            // this.props.history.push("/information");
            this.props.history.push('/taxform');
          }
        }
      });
  };
  allCheckHandler = () => {
    if (this.state.check) {
      this.setState({ check: false });
    } else {
      this.setState({ check: true });
    }
  };
  handleSubmit = (e) => {
    e.preventDefault();
    let numberCheck = /^[0-9]+$/;
    let nameCheck = /^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/;
    if (this.state.paymentMethod === '') {
      this.setState({ paymentMethodError: 'Please select payment method' });
      paymentMethodError = 'Please select payment method';
    } else {
      this.setState({ paymentMethodError: '' });
      paymentMethodError = '';
    }
    if (
      this.state.accountType === '' &&
      this.state.paymentMethod !== 'paypal'
    ) {
      this.setState({ accountTypeError: 'Please select account type' });
      accountTypeError = 'Please select account type';
    } else {
      this.setState({ accountTypeError: '' });
      accountTypeError = '';
    }
    if (
      this.state.accountName === '' &&
      this.state.paymentMethod !== 'paypal'
    ) {
      this.setState({ accountNameError: 'Please enter account name' });
      accountNameError = 'Please enter account name';
    } else if (
      !nameCheck.test(this.state.accountName) &&
      this.state.paymentMethod !== 'paypal'
    ) {
      this.setState({ accountNameError: 'Please enter valid account name' });
      accountNameError = 'Please enter valid account name';
    } else {
      this.setState({ accountNameError: '' });
      accountNameError = '';
    }
    if (this.state.bankName === '' && this.state.paymentMethod !== 'paypal') {
      this.setState({ bankNameError: 'Please enter bank name' });
      bankNameError = 'Please enter bank name';
    } else if (
      !nameCheck.test(this.state.bankName) &&
      this.state.paymentMethod !== 'paypal'
    ) {
      this.setState({ bankNameError: 'Please enter valid bank name' });
      bankNameError = 'Please enter valid bank name';
    } else {
      this.setState({ bankNameError: '' });
      bankNameError = '';
    }
    if (
      this.state.routingCode === '' &&
      this.state.paymentMethod !== 'paypal'
    ) {
      this.setState({ routingCodeError: 'Please enter routing code' });
      routingCodeError = 'Please enter routing code';
    } else if (
      !numberCheck.test(this.state.routingCode)&&this.state.paymentMethod !== 'paypal' ||
      (this.state.routingCode.length !== 9 &&
        this.state.paymentMethod !== 'paypal')
    ) {
      this.setState({ routingCodeError: 'Please enter valid routing code' });
      routingCodeError = 'Please enter valid routing code';
    } else {
      this.setState({ routingCodeError: '' });
      routingCodeError = '';
    }
    if (
      this.state.accountNumber === '' &&
      this.state.paymentMethod !== 'paypal'
    ) {
      this.setState({ accountNumberError: 'Please enter account number' });
      accountNumberError = 'Please enter account number';
    } else if (
      !numberCheck.test(this.state.accountNumber)&&this.state.paymentMethod !== 'paypal' ||
      this.state.accountNumber.length <= 8 &&this.state.paymentMethod !== 'paypal'||
      (this.state.accountNumber.length >= 13 &&
        this.state.paymentMethod !== 'paypal')
    ) {
      this.setState({
        accountNumberError: 'Please enter valid account number',
      });
      accountNumberError = 'Please enter valid account number';
    } else {
      this.setState({ accountNumberError: '' });
      accountNumberError = '';
    }
    if (this.state.paymentMethod === 'paypal' && (this.state.paypalid === ''||this.state.paypalid===undefined)) {
      this.setState({ paypalidError: 'Please enterpaypal id' });
      paypalidError = 'Please enterpaypal id';
    } else {
      this.setState({ paypalidError: '' });
      paypalidError = '';
    }
    console.log(this.state.paypalid,"method==")
    if (this.state.paymentMethod === 'paypal') {
      if (
        this.state.paymentMethod &&
        this.state.paypalid &&
        paymentMethodError === '' &&
        paypalidError === ''
      ) {
        //submitDisable = true;
        if (this.state.check === false) {
          checkError = 'Please agree term & condition';
          this.forceUpdate();
        } else {
          this.addImage();
          console.log('working');
        }
      } else {
        this.forceUpdate();
      }
    } else {
      if (
        this.state.paymentMethod &&
        this.state.accountName &&
        this.state.bankName &&
        this.state.routingCode &&
        this.state.accountNumber &&
        this.state.accountType &&
        paymentMethodError === '' &&
        accountNameError === '' &&
        bankNameError === '' &&
        routingCodeError === '' &&
        accountNumberError === '' &&
        accountTypeError === ''
      ) {
        //submitDisable = true;
        if (this.state.check === false) {
          checkError = 'Please agree term & condition';
          this.forceUpdate();
        } else {
          this.addImage();
          console.log('working');
        }
      } else {
        this.forceUpdate();
      }
    }
  };
  resetHandler = () => {
    this.setState({
      question: '',
      answer: '',
      topic: '',
    });
  };
  backUrl = () => {
    this.props.history.push('/address');
  };
  nextUrl = () => {
    this.props.history.push('/taxform');
  };
  handleNameChange = (e, index) => {
    let card = this.state.creditCards;
    card[index].cardHolder = e.target.value;
    this.setState({ creditCards: card });
  };
  handleNumberChange = (e, index) => {
    let card = this.state.creditCards;
    card[index].cardNumber = e.target.value;
    this.setState({ creditCards: card });
  };
  handleMonthChange = (e, index) => {
    let card = this.state.creditCards;
    card[index].expMonth = e.target.value;
    this.setState({ creditCards: card });
  };
  handleYearChange = (e, index) => {
    let card = this.state.creditCards;
    card[index].expYear = e.target.value;
    this.setState({ creditCards: card });
  };
  handleCVVChange = (e, index) => {
    let card = this.state.creditCards;
    card[index].cvv = e.target.value;
    this.setState({ creditCards: card });
  };
  handleEmailChange = (e, index) => {
    let card = this.state.creditCards;
    card[index].email = e.target.value;
    this.setState({ creditCards: card });
  };
  addCreditCardHandler = () => {
    let card = this.state.creditCards;
    card.push({
      cardHolder: '',
      cardNumber: '',
      expYear: '',
      expMonth: '',
      cvv: '',
      email: '',
      address: '',
    });
    this.setState({ creditCards: card });
  };
  handleAddressChange = (value, index) => {
    let card = this.state.creditCards;
    card[index].address = value;
    this.setState({ creditCards: card });
  };
  deleteCreditCardHandler = (index) => {
    let card = this.state.creditCards;
    card.splice(index, 1);
    this.setState({ creditCards: card });
  };
  render() {
    return (
      <body
        class="vertical-layout vertical-menu-modern semi-dark-layout 2-columns  navbar-floating footer-static  "
        data-open="click"
        data-menu="vertical-menu-modern"
        data-col="2-columns"
        data-layout="semi-dark-layout"
      >
        {/* <Header />
        <Sidebar /> */}
        <div class="app-content content">
          <div class="content-overlay"></div>
          <div class="header-navbar-shadow"></div>
          <div class="content-wrapper">
            <div class="content-header row">
              <p
                className="info-detail"
                dangerouslySetInnerHTML={{
                  __html: this.state.taxDescription,
                }}
              />
            </div>
            <div class="content-body">
              <section id="dashboard-analytics">
                <div className="row step-box">
                  <div>
                    <div className="round-color-number-box c-box1">
                      <p className="round-color-number-page c-sub-box1">1</p>
                    </div>
                    <div className="t-point">
                      Seller & Business <br />
                      Information
                    </div>
                  </div>
                  <div>
                    <div className="round-color-number-box c-box2">
                      <p className="round-color-number-page">2</p>
                    </div>
                    <div className="t-point c-sub-box2">Address</div>
                  </div>
                  <div>
                    <div className="round-color-number-box c-box3">
                      <p className="round-color-number-page">3</p>
                    </div>
                    <div class="p-text t-point">
                      Payment
                      <br /> Method
                    </div>
                  </div>
                  {this.state.seller.type === 'Retailer' ? (
                    <>
                      <div>
                        <div className="round-number-box">
                          <p className="round-number-page">4</p>
                        </div>
                        <div class="p-text t-point">Tax Forms</div>
                      </div>
                    </>
                  ) : null}
                  <div>
                    <div className="round-none-number-box">
                      <p className="round-number-page">
                        {' '}
                        {this.state.seller.type === 'Retailer' ? 5 : 4}
                      </p>
                    </div>
                    <div class="p-text t-point">Done</div>
                  </div>
                </div>

                <div class="card">
                  <div class="card-body">
                    <div class="row">
                      <div class="col-xl-3 col-md-3 add-field">
                        Payment Method{' '}
                      </div>
                      <div class="col-md-6">
                        <Select
                          value={this.state.paymentMethods.filter(
                            (option) =>
                              option.value === this.state.paymentMethod
                          )}
                          theme={(theme) => ({
                            ...theme,
                            borderRadius: '5px',
                            border: '0px solid',
                            colors: {
                              ...theme.colors,
                              text: 'orangered',
                              primary25: '#9cf59c',
                              primary: '#47A547',
                            },
                          })}
                          options={this.state.paymentMethods}
                          name="brand"
                          onChange={(val) => this.dropChangeHandler(val)}
                        />
                        <p class="p-error">{paymentMethodError} </p>
                      </div>
                    </div>
                    {
            this.state.paymentMethod !== 'paypal' ?<>
                    <div class="row" >
                      <div class="col-xl-3 col-md-3 add-field">
                        Name of Account<span class="required-span1">*</span>
                      </div>
                      <div class="col-md-6">
                        <input
                          type="text"
                          class="form-control"
                          name="accountName"
                          placeholder="Name of Account"
                          value={this.state.accountName}
                          onChange={this.handleChange}
                        />
                        <p class="p-error"> {accountNameError}</p>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-xl-3 col-md-3 add-field">
                        Bank Name <span class="required-span1">*</span>
                      </div>
                      <div class="col-md-6">
                        <input
                          type="text"
                          class="form-control"
                          name="bankName"
                          placeholder="Bank Name"
                          value={this.state.bankName}
                          onChange={this.handleChange}
                        />
                        <p class="p-error"> {bankNameError}</p>
                      </div>
                    </div>
                    {/* <div class="row">
                      <div class="col-xl-3 col-md-3 add-field">
                        Paypal Address<span class="required-span1">*</span>
                      </div>
                      <div class="col-md-6">
                        <input
                          type="text"
                          class="form-control"
                          name="paypalid"
                          placeholder="Paypal Address"
                          value={this.state.paypalid}
                          onChange={this.handleChange}
                        />
                        <p class="p-error"> {paypalidError}</p>
                      </div>
                    </div>
                     */}
                    {/* <div class="row">
                      <div class="col-xl-3 col-md-3 add-field">
                        Bank Name <span class="required-span1">*</span>
                      </div>
                      <div class="col-md-6">
                        <input
                          type="text"
                          class="form-control"
                          name="bankName"
                          placeholder="Bank Name"
                          value={this.state.bankName}
                          onChange={this.handleChange}
                        />
                        <p class="p-error"> {bankNameError}</p>
                      </div>
                    </div>
                     */}
                    <div class="row">
                      <div class="col-xl-3 col-md-3 add-field">
                        Routing Code<span class="required-span1">*</span>
                      </div>
                      <div class="col-md-6">
                        <input
                          type="text"
                          class="form-control"
                          name="routingCode"
                          placeholder="Routing Code"
                          value={this.state.routingCode}
                          onChange={this.handleChange}
                          maxLength={20}
                        />
                        <p class="p-error"> {routingCodeError}</p>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-xl-3 col-md-3 add-field">
                        Account Number<span class="required-span1">*</span>
                      </div>
                      <div class="col-md-6">
                        <input
                          type="text"
                          class="form-control"
                          name="newAccountNumber"
                          placeholder="Account Number"
                          value={this.state.newAccountNumber}
                          onChange={this.handleAccountChange}
                          maxLength={20}
                        />
                        <p class="p-error"> {accountNumberError}</p>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-xl-3 col-md-3 add-field">
                        Account Type<span class="required-span1">*</span>
                      </div>
                      <div class="col-md-6 " style={{ marginTop: '9px' }}>
                        <ul class="list-unstyled mb-0">
                          <li class="d-inline-block mr-2">
                            <fieldset>
                              <div class="vs-radio-con">
                                <input
                                  type="radio"
                                  name="accountType"
                                  value="checking"
                                  onChange={this.handleChange}
                                  checked={
                                    this.state.accountType === 'checking'
                                      ? true
                                      : false
                                  }
                                />
                                <span class="vs-radio">
                                  <span class="vs-radio--border"></span>
                                  <span class="vs-radio--circle"></span>
                                </span>
                                Checking
                              </div>
                            </fieldset>
                          </li>
                          <li class="d-inline-block mr-2">
                            <fieldset>
                              <div class="vs-radio-con">
                                <input
                                  type="radio"
                                  name="accountType"
                                  value="saving"
                                  onChange={this.handleChange}
                                  checked={
                                    this.state.accountType === 'saving'
                                      ? true
                                      : false
                                  }
                                />
                                <span class="vs-radio">
                                  <span class="vs-radio--border"></span>
                                  <span class="vs-radio--circle"></span>
                                </span>
                                Saving
                              </div>
                            </fieldset>
                          </li>
                        </ul>
                        <p class="p-error"> {accountTypeError}</p>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-xl-3 col-md-3 add-field">
                        Voided check
                      </div>
                      <div class="col-md-6">
                        <div className="add-filed">File Upload</div>
                        <input
                          type="file"
                          class="form-control"
                          onChange={(e) => this.onImageChange(e)}
                        />
                        <p>{this.state.existingDoc} </p>
                      </div>
                    </div>
                   </> :<div class="row">
                      <div class="col-xl-3 col-md-3 add-field">
                        Paypal Address<span class="required-span1">*</span>
                      </div>
                      <div class="col-md-6">
                        <input
                          type="text"
                          class="form-control"
                          name="paypalid"
                          placeholder="Paypal Address"
                          value={this.state.paypalid}
                          onChange={this.handleChange}
                        />
                        <p class="p-error"> {paypalidError}</p>
                      </div>
                    </div>
                    
                  }
                    <div className="b-info-header">
                      <h5>Credit Card Detail</h5>
                    </div>

                    {this.state.creditCards.map((card, index) => (
                      <div key={index}>
                        <div className="row">
                          <div className="col-12">
                            <div className="b-info-header">
                              <h6>
                                Credit Card {index + 1}
                                {index >= 1 ? (
                                  <i
                                    className="fa fa-times-circle credit-close-icon"
                                    onClick={() =>
                                      this.deleteCreditCardHandler()
                                    }
                                  ></i>
                                ) : null}
                              </h6>
                            </div>
                          </div>
                        </div>
                        <div class="row credit-bold">
                          <div class="col-xl-3 col-md-3 ">
                            <label>
                              {/* <div class="controls"> */}
                              Cardholder Name
                              {/* <span class="required-span">*</span> */}
                            </label>
                          </div>
                          <div class="col-md-6">
                            <input
                              type="text"
                              class="form-control"
                              placeholder="Card Holder Name"
                              value={card.cardHolder}
                              onChange={(e) => this.handleNameChange(e, index)}
                            />
                            <p class="error-msg">{}</p>
                          </div>
                        </div>

                        <div class="row credit-bold">
                          <div class="col-xl-3 col-md-3">
                            <label>Card Number</label>
                          </div>
                          <div class="col-md-6">
                            <input
                              type="number"
                              class="form-control"
                              placeholder="Card Number"
                              value={card.cardNumber}
                              onChange={(e) =>
                                this.handleNumberChange(e, index)
                              }
                              maxLength="12"
                            />
                            <p class="error-msg">{} </p>
                          </div>
                        </div>

                        {/* </div> */}

                        <div class="row credit-bold">
                          <div class="col-12 col-sm-3">
                            <div class="form-group">
                              <label>Expire Month</label>
                              <select
                                class="form-control"
                                value={card.expMonth}
                                onChange={(e) =>
                                  this.handleMonthChange(e, index)
                                }
                              >
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                                <option value="4">4</option>
                                <option value="5">5</option>
                                <option value="6">6</option>
                                <option value="7">7</option>
                                <option value="8">8</option>
                                <option value="9">9</option>
                                <option value="10">10</option>
                                <option value="11">11</option>
                                <option value="12">12</option>
                              </select>
                              <p class="error-msg">{} </p>
                            </div>
                          </div>
                          <div class="col-12 col-sm-3">
                            <div class="form-group">
                              <label>Expire Year</label>
                              <select
                                class="form-control"
                                value={card.expYear}
                                onChange={(e) =>
                                  this.handleYearChange(e, index)
                                }
                              >
                                <option value="2020">2020</option>
                                <option value="2021">2021</option>
                                <option value="2022">2022</option>
                                <option value="2023">2023</option>
                                <option value="2024">2024</option>
                                <option value="2025">2025</option>
                                <option value="2026">2026</option>
                                <option value="2027">2027</option>
                                <option value="2028">2028</option>
                                <option value="2029">2029</option>
                                <option value="2030">2030</option>
                                <option value="2031">2031</option>
                                <option value="2032">2032</option>
                                <option value="2033">2033</option>
                                <option value="2034">2034</option>
                                <option value="2035">2035</option>
                                <option value="2036">2036</option>
                                <option value="2037">2037</option>
                                <option value="2038">2038</option>
                                <option value="2039">2039</option>
                                <option value="2040">2040</option>
                                <option value="2041">2041</option>
                                <option value="2042">2042</option>
                                <option value="2043">2043</option>
                              </select>
                            </div>
                          </div>
                          <div class="col-12 col-sm-3">
                            <div class="form-group">
                              <label style={{ marginRight: '10px' }}>CVV</label>
                              <input
                                type="number"
                                class="form-control"
                                placeholder="CVV"
                                value={card.cvv}
                                onChange={(e) => this.handleCVVChange(e, index)}
                                maxLength="3"
                              />
                              <p class="error-msg">{} </p>
                            </div>
                          </div>
                        </div>
                        <div class="row credit-bold">
                          <div class="col-xl-3 col-md-3">
                            {/* <div class="controls"> */}
                            <label>Client Email</label>
                          </div>
                          <div class="col-md-6">
                            <input
                              type="text"
                              class="form-control"
                              placeholder="Client Email"
                              value={card.email}
                              onChange={(e) => this.handleEmailChange(e, index)}
                            />
                            <p class="error-msg">{}</p>
                          </div>
                        </div>

                        <div class="row">
                          <div class="col-md-12 credit-bold">Account Type</div>
                          <div
                            class="col-lg-6 col-md-12"
                            style={{ marginTop: '9px' }}
                          >
                            <ul class="list-unstyled mb-0">
                              <li class="d-inline-block mr-2">
                                <fieldset className="mb-1">
                                  <div class="vs-radio-con">
                                    <input
                                      type="radio"
                                      name="accountType"
                                      value="1"
                                      onChange={() =>
                                        this.handleAddressChange('1', index)
                                      }
                                      checked={
                                        card.address === '1' ? true : false
                                      }
                                    />
                                    <span class="vs-radio">
                                      <span class="vs-radio--border"></span>
                                      <span class="vs-radio--circle"></span>
                                    </span>
                                    Same As Main Address
                                  </div>
                                </fieldset>
                              </li>
                              <li class="d-inline-block mr-2">
                                <fieldset className="mb-1">
                                  <div class="vs-radio-con">
                                    <input
                                      type="radio"
                                      value="1"
                                      onChange={() =>
                                        this.handleAddressChange('2', index)
                                      }
                                      checked={
                                        card.address === '2' ? true : false
                                      }
                                    />
                                    <span class="vs-radio">
                                      <span class="vs-radio--border"></span>
                                      <span class="vs-radio--circle"></span>
                                    </span>
                                    Same As Address2
                                  </div>
                                </fieldset>
                              </li>
                            </ul>
                            <p class="p-error"> {accountTypeError}</p>
                          </div>
                        </div>
                      </div>
                    ))}

                    <div class="row">
                      <div class="col-12 mb-1">
                        <button
                          type="reset"
                          class="btn btn-primary glow  login-btn add-card-btn"
                          onClick={this.addCreditCardHandler}
                        >
                          <i className="fa fa-plus"></i> Add
                        </button>
                      </div>
                    </div>

                    <div class="form-group row">
                      <div class="col-12">
                        <fieldset class="checkbox">
                          <div class="vs-checkbox-con vs-checkbox-primary">
                            <input
                              type="checkbox"
                              checked={this.state.check}
                              name="term"
                              onChange={this.allCheckHandler}
                            />
                            <span class="vs-checkbox">
                              <span class="vs-checkbox--check">
                                <i class="vs-icon feather icon-check"></i>
                              </span>
                            </span>
                            <span class="">
                              {' '}
                              Click here to indicate that you have read and
                              agree to the terms presented in the Terms and
                              Condition agreement.
                            </span>
                          </div>
                          <p class="error-msg">{checkError}</p>
                        </fieldset>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-6 mt-1">
                        <button
                          type="reset"
                          class="btn btn-outline-primary float-left btn-inline res-btn"
                          onClick={this.backUrl}
                        >
                          Back
                        </button>
                      </div>
                      <div class="col-6 d-flex flex-sm-row justify-content-end mt-1">
                        <button
                          type="submit"
                          class="btn btn-primary glow mb-1 mb-sm-0 mr-0 mr-sm-1 login-btn"
                          onClick={(e) => this.handleSubmit(e)}
                        >
                          Next
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>

        <div class="sidenav-overlay"></div>
        <div class="drag-target"></div>

        <Footer />
      </body>
    );
  }
}

export default PaymentMethod;
