import React from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
// import moment from 'moment';
// import Sidebar from "../../Component/Sidebar/Sidebar";
//import Header from "../../Component/Header/Header";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
  Button,
} from 'reactstrap';
import Footer from '../../Component/Footer/Footer';
import global from '../../global';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactPaginate from 'react-paginate';
var sellerData = {};

const $ = require('jquery');
$.DataTable = require('datatables.net');
var products = [];
var orderList = [];
const CustomToast = () => {
  return (
    <div>
      <i class="fa fa-check success-toast"></i>
      Return Successfully
    </div>
  );
};
var r=false;
class ReturnExchangeList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      seller: '',
      orders: [],
      showLogOut: false,
      messageto:'',
      offset: 1,
      perPage: 10,
      currentPage: 1,
      totalCount: 0,
      approvedReturns: [],
      listdata: [],
      userid:'',
      status1:'',
      action:'',
      r:false,
    };
    orderList = [];
  }

  componentWillMount() {
    if (localStorage.getItem('sellerdata')) {
      this.setState({ seller: JSON.parse(localStorage.getItem('sellerdata')) });
      sellerData = JSON.parse(localStorage.getItem('sellerdata'));
    }
    this.getOrderAPI();
  }

  dataTable() {
    $(document).ready(function () {
      $('#list').DataTable();
    });
  }

  getOrderAPI() {
    var urlencoded = new URLSearchParams();
    urlencoded.append('UserID', sellerData.id);
    axios
      .post(global.baseUrl + 'returnexchangelist', urlencoded, {
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      })
      .then((result) => {
        console.log(result.data.data, 'order list');

        if (result.data.data) {
          let temporder = [];
          orderList = result.data.data;
          this.setState({
            orders: result.data.data,
            sortOrder: result.data.data,
            orderSummary: temporder,
            postData: [],
          });
          this.setState({
            totalCount: result.data.data.length,
            pageCount: Math.ceil(result.data.data.length / this.state.perPage),
          });
          this.pagination();
        }
      });
  }

  handlePageClick = (e) => {
    const selectedPage = e.selected;
    console.log(e.selected, 'selectedpage');
    console.log(selectedPage, 'selected page');
    const offset = selectedPage * this.state.perPage;

    this.setState(
      {
        currentPage: selectedPage + 1,
        offset: offset + 1,
      },
      () => {
        this.pagination();
      }
    );
  };

  pagination() {
    console.log(this.state.offset, 'offsetcheck');
    const slice = this.state.orders.slice(
      this.state.offset - 1,
      this.state.offset - 1 + this.state.perPage
    );

    // For displaying Data
    this.setState({ listdata: slice });
  }
  deleteHandler = (productID) => {
    var r = window.confirm('Are you sure to delete the record?');
    if (r) {
      var urlencoded = new URLSearchParams();
      console.log(sellerData.id);
      urlencoded.append('id', productID);
      urlencoded.append('userId', this.state.seller.id);

      axios
        .post(global.baseUrl + 'sellerofferdelete', urlencoded, {
          headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        })
        .then((result) => {
          console.log(result.data);
          toast.success(<CustomToast />, {
            position: toast.POSITION.TOP_RIGHT,
          });
          window.location.reload();
        });
      this.setState(
        {
          products: this.state.products.filter(
            (product) => product.id !== productID
          ),
        },
        () => {
          console.log(this.state.products);
        }
      );
      this.dataTable();
    }
  };

  ReturnApprovHandler1 = (user,status2) => {
    
      if (status2 === '2') {
        // var r = window.confirm('Are you sure you want to Cancel this request?');
        this.setState({ messageto: 'Are you sure you want to Cancel this request?' })
        this.setState({ showLogOut: true,action:'return' })
        // this.setState({userid:UserID,status1:status})
      } else {
        this.setState({ messageto: 'Are you sure you want to Approve this request?' })
        this.setState({ showLogOut: true,action:'return' })
        // this.setState({userid:UserID,status1:status})
        // var r = window.confirm('Are you sure you want to Approve this request?');
      }
      this.setState({status1:status2,userid:user})
  }
  ReturnApprovHandler = () => {
  //   if(this.state.r==false){
  //   if (this.state.status1 === '2') {
  //     // var r = window.confirm('Are you sure you want to Cancel this request?');
  //     this.setState({ messageto: 'Are you sure you want to Cancel this request?' })
  //     this.setState({ showLogOut: true,action:'return' })
  //     // this.setState({userid:UserID,status1:status})
  //   } else {
  //     this.setState({ messageto: 'Are you sure you want to Approve this request?' })
  //     this.setState({ showLogOut: true,action:'return' })
  //     // this.setState({userid:UserID,status1:status})
  //     // var r = window.confirm('Are you sure you want to Approve this request?');
  //   }
  // }
    // if (this.state.r==true) {
      var urlencoded = new URLSearchParams();
      urlencoded.append('id', this.state.userid);
      urlencoded.append('returnApprovedStatus', this.state.status1);

      axios
        .post(global.baseUrl + 'approveReturnOrder', urlencoded, {
          headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        })
        .then((result) => {
          if (result.data) {
            // this.setState({ orders: result.data.data })
            console.log('result', result.data);
            // this.bannersList()
          } else {
            console.log('result', result.data);
          }
          window.location.reload();
        });
    // }
  };


  RefundApprovHandler1 = (user,status2) => {
    
    if (status2 === '2') {
      // var r = window.confirm('Are you sure you want to Cancel this request?');
      this.setState({ messageto: 'Are you sure you want to Cancel this request?' })
      this.setState({ showLogOut: true,action:'refund' })
      // this.setState({userid:UserID,status1:status})
    } else {
      this.setState({ messageto: 'Are you sure you want to Approve this request?' })
      this.setState({ showLogOut: true,action:'refund' })
      // this.setState({userid:UserID,status1:status})
      // var r = window.confirm('Are you sure you want to Approve this request?');
    }
    this.setState({status1:status2,userid:user})
}
RefundApprovHandler = () => {
//   if(this.state.r==false){
//   if (this.state.status1 === '2') {
//     // var r = window.confirm('Are you sure you want to Cancel this request?');
//     this.setState({ messageto: 'Are you sure you want to Cancel this request?' })
//     this.setState({ showLogOut: true,action:'return' })
//     // this.setState({userid:UserID,status1:status})
//   } else {
//     this.setState({ messageto: 'Are you sure you want to Approve this request?' })
//     this.setState({ showLogOut: true,action:'return' })
//     // this.setState({userid:UserID,status1:status})
//     // var r = window.confirm('Are you sure you want to Approve this request?');
//   }
// }
  // if (this.state.r==true) {
    var urlencoded = new URLSearchParams();
    urlencoded.append('id', this.state.userid);
    urlencoded.append('refundStatus', this.state.status1);

    axios
      .post(global.baseUrl + 'approveRefund', urlencoded, {
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      })
      .then((result) => {
        if (result.data) {
          // this.setState({ orders: result.data.data })
          console.log('result', result.data);
          // this.bannersList()
        } else {
          console.log('result', result.data);
        }
        window.location.reload();
      });
  // }
};


  ExchangeApprovHandler1=(user,status2)=>{
    if (status2 === '4') {
      // var r = window.confirm('Are you sure you want to Cancel this request?');
      this.setState({ messageto: 'Are you sure you want to Cancel this request?' })
      this.setState({ showLogOut: true,action:'exchange' })
      // this.setState({userid:UserID,status1:status})
    } else {
      this.setState({ messageto: 'Are you sure you want to Approve this request?' })
      this.setState({ showLogOut: true,action:'exchange' })
      // this.setState({userid:UserID,status1:status})
      // var r = window.confirm('Are you sure you want to Approve this request?');
    }
    this.setState({status1:status2,userid:user})
  }
  ExchangeApprovHandler = () => {
  //   if(this.state.r==false){
  //   if (this.state.status1 === '3') {
  //     // var r = window.confirm('Are you sure you want to Cancel this request?');
  //     this.setState({ messageto: 'Are you sure you want to Cancel this request?' })
  //     this.setState({ showLogOut: true,action:'exchange' })
  //     // this.setState({userid:UserID,status1:status})
  //   } else {
  //     this.setState({ messageto: 'Are you sure you want to Approve this request?' })
  //     this.setState({ showLogOut: true,action:'exchange' })
  //     // this.setState({userid:UserID,status1:status})
  //     // var r = window.confirm('Are you sure you want to Approve this request?');
  //   }
  // }
    // if (status === '3') {
    //   var r = window.confirm('Are you sure you want to Cancel this request?');
    // } else {
    //   var r = window.confirm('Are you sure you want to Approve this request?');
    // }
    // if (this.state.r==true) {
      var urlencoded = new URLSearchParams();
      urlencoded.append('id',this.state.userid);
      urlencoded.append('exchangeApprovedStatus', this.state.status1);

      axios
        .post(global.baseUrl + 'exchangeReturnOrder', urlencoded, {
          headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        })
        .then((result) => {
          if (result.data) {
            console.log('result', result.data);
          } else {
            console.log('result', result.data);
          }
          window.location.reload();
        });
    // }
  };
  CancelApprovHandler1=(user,status2)=>{
      if (status2 === '4') {
        // var r = window.confirm('Are you sure you want to Cancel this request?');
        this.setState({ messageto: 'Are you sure you want to Cancel this request?' })
        this.setState({ showLogOut: true,action:'cancel' })
        // this.setState({userid:UserID,status1:status})
      } else {
        this.setState({ messageto: 'Are you sure you want to Approve this request?' })
        this.setState({ showLogOut: true,action:'cancel' })
        // this.setState({userid:UserID,status1:status})
        // var r = window.confirm('Are you sure you want to Approve this request?');
      }

      this.setState({status1:status2,userid:user})
    }
 
  CancelApprovHandler = () => {

    console.log("ener1111");
  //   if(this.state.r===false){
  //   if (this.state.status1 === '3') {
  //     // var r = window.confirm('Are you sure you want to Cancel this request?');
  //     this.setState({ messageto: 'Are you sure you want to Cancel this request?' })
  //     this.setState({ showLogOut: true,action:'cancel' })
  //     // this.setState({userid:UserID,status1:status})
  //   } else {
  //     this.setState({ messageto: 'Are you sure you want to Approve this request?' })
  //     this.setState({ showLogOut: true,action:'cancel' })
  //     // this.setState({userid:UserID,status1:status})
  //     // var r = window.confirm('Are you sure you want to Approve this request?');
  //   }
  // }
    // console.log(status);
    // if (status === '4') {
    //   var r = window.confirm('Are you sure you want to Cancel this request?');
    // } else {
    //   var r = window.confirm('Are you sure you want to Approve this request?');
    // }
    // if (this.state.r===true) {
      console.log("ener222222");
      var urlencoded = new URLSearchParams();
      urlencoded.append('id', this.state.userid);
      urlencoded.append('cancelApprovedStatus', this.state.status1);

      axios
        .post(global.baseUrl + 'cancelReturnOrder', urlencoded, {
          headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        })
        .then((result) => {
          if (result.data) {
            console.log('result', result.data);
          } else {
            console.log('result', result.data);
          }
          window.location.reload();
        });
    // }
  };
  render() {
    return (
      <body
        class="vertical-layout vertical-menu-modern semi-dark-layout 2-columns  navbar-floating footer-static  "
        data-open="click"
        data-menu="vertical-menu-modern"
        data-col="2-columns"
        data-layout="semi-dark-layout"
      >
        {/* <Header />
        <Sidebar /> */}
        <Modal isOpen={this.state.showLogOut} fade="false" toggle={() => this.setState({ showLogOut: false })}>
          <ModalHeader toggle={() => this.setState({ showLogOut: false })}>Warning</ModalHeader>
          <ModalBody>
            <p>{this.state.messageto}</p>
          </ModalBody>
          <ModalFooter>

            <Button onClick={() => this.setState({ showLogOut: false })}>Cancel</Button>
            <Button color="info" onClick={() => { this.setState({ showLogOut: false, r: true });if(this.state.action==='return'){this.ReturnApprovHandler()}else if(this.state.action==='refund'){this.RefundApprovHandler();}else if(this.state.action==='cancel'){this.CancelApprovHandler();}else if(this.state.action==='exchange'){this.ExchangeApprovHandler();} }}>Ok</Button>
          </ModalFooter>
        </Modal>
        <div class="app-content content" style={{ minHeight: '91vh' }}>
          <div class="content-overlay"></div>
          <div class="header-navbar-shadow"></div>
          <div class="content-wrapper">
            <div class="content-header row">
              <div class="content-header-left col-md-12 col-12 mb-2">
                <div class="row breadcrumbs-top">
                  <div class="col-12">
                    <h2 class="content-header-title float-left mb-0">
                      Return Exchange List
                    </h2>
                    <div class="breadcrumb-wrapper col-12">
                      <ol class="breadcrumb">
                        <li class="breadcrumb-item">
                          <Link to="/dashboard">Home</Link>
                        </li>
                        <li class="breadcrumb-item active">
                          Return Exchange List
                        </li>
                      </ol>
                    </div>
                  </div>
                </div>
              </div>

              {/* <div class="content-header-right text-md-right col-md-3 col-12 d-md-block">
                                <div class="form-group breadcrum-right xs-text-right">
                                    <Link
                                        to="/addoffer"
                                        id="addRow"
                                        class="btn btn-primary mb-2 add-product-link"
                                    >
                                        <i class="feather icon-plus"></i>Return Exchange List
                                    </Link>
                                </div>
                            </div> */}
            </div>
            <div class="content-body">
              <section id="add-row">
                <div class="row">
                  <div class="col-12">
                    <div class="card">
                      {/* <div class="card-header">
                                    <h4 class="card-title">Add rows</h4>
                                </div> */}
                      <div class="card-content">
                        <div class="card-body">
                          {/* <button id="addRow" class="btn btn-primary mb-2"><i class="feather icon-plus"></i>&nbsp; Add new row</button> */}
                          <div
                            class="table-responsive"
                            style={{ fontSize: '16px' }}
                          >
                            <table id="list" class="table">
                              <thead>
                                <tr>
                                  <th style={{ fontSize: '16px' }}>Id</th>
                                  <th style={{ fontSize: '16px' }}>Order ID</th>
                                  <th style={{ fontSize: '16px' }}>
                                    Product ID
                                  </th>
                                  <th style={{ fontSize: '16px' }}>
                                    Return Reason
                                  </th>
                                  <th style={{ fontSize: '16px' }}>
                                    Refund Action
                                   </th>
                                  <th style={{ fontSize: '16px' }}>
                                    Return Action
                                  </th>
                                  <th style={{ fontSize: '16px' }}>
                                    Exchange Action
                                  </th>
                                  <th style={{ fontSize: '16px' }}>
                                    Cancel Action
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {this.state.listdata[0]?this.state.listdata.map((order, index) => (
                                  <>
                                    <div class="modal-success1 mr-1 mb-1 d-inline-block ">
                                      <div
                                        class="modal fade text-left "
                                        id="success1"
                                        tabindex="-1"
                                        role="dialog"
                                        aria-labelledby="myModalLabel110"
                                        aria-hidden="true"
                                      >
                                        <div
                                          class="modal-dialog modal-dialog-centered modal-dialog-scrollable"
                                          role="document"
                                        >
                                          <div class="modal-content vertical-view-model">
                                            <div class="modal-header bg-success white">
                                              <h5
                                                class="modal-title"
                                                id="myModalLabel110"
                                              >
                                                Return Resone
                                              </h5>
                                              <button
                                                type="button"
                                                class="close"
                                                data-dismiss="modal"
                                                aria-label="Close"
                                              >
                                                <span aria-hidden="true">
                                                  &times;
                                                </span>
                                              </button>
                                            </div>
                                            <div
                                              class="modal-body view-model-body"
                                              style={{
                                                paddingBottom: '0px',
                                                height: '150px',
                                              }}
                                            >
                                              <p>
                                                <h6
                                                  style={{
                                                    float: 'left',
                                                    fontWeight: 'bold',
                                                    // marginLeft: '0px',
                                                  }}
                                                >
                                                  Return Resone:
                                                </h6>

                                                {order.condition_title}
                                              </p>
                                              <p>
                                                <h6
                                                  style={{
                                                    float: 'left',
                                                    fontWeight: 'bold',
                                                    // marginLeft: '0px',
                                                  }}
                                                >
                                                  Product Condition:
                                                </h6>
                                                {order.name}
                                              </p>
                                              <p>
                                                <h6
                                                  style={{
                                                    float: 'left',
                                                    fontWeight: 'bold',
                                                    // marginLeft: '0px',
                                                  }}
                                                >
                                                  User Comment:
                                                </h6>
                                                {order.return_comment}
                                              </p>
                                              {/* <img
                                  width="350"
                                  height="200"
                                  src={
                                    this.state.bannerPages.banner_image_app
                                      ? this.state.bannerPages.banner_image_app
                                      : this.state.bannerPages.banner_image_web
                                      ? this.state.bannerPages.banner_image_web
                                      : this.state.bannerproduct.image
                                      ? this.state.bannerproduct.image
                                      : this.state.bannerproduct.Link
                                  }
                                  alt=".."
                                /> */}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>

                                    <tr key={index}>
                                      <td className="white-space">
                                        {index + this.state.offset}
                                      </td>
                                      <td className="white-space">
                                       <Link to={`/vieworder/${order.order_Id}`}> {order.order_Id}</Link>
                                      </td>
                                      <td className="white-space">
                                        {order.productId.id}
                                      </td>
                                      <td className="white-space">
                                        {order.condition_title ? (
                                          <button
                                            data-toggle="modal"
                                            data-target="#success1"
                                            class="btn btn-primary float-right mr-1 btn-inline waves-effect waves-light login-btn "
                                            style={{ padding: '6px' }}
                                          >
                                            View
                                          </button>
                                        ) : order.returnReason?(
                                          <td>{order.returnReason}</td>
                                        ):''}
                                      </td>
                                      <td
                                        className="white-space"
                                        style={{
                                          textAlign: 'center',
                                        }}
                                      >
                                        {(order.returnApprovedStatus==='1'|| order.cancelApprovedStatus === '3')?order.refundStatus? (
                                          order.refundStatus === '8' ? (
                                            <>
                                            <button className="btn btn-success" onClick={() =>{
                                                  // this.setState({userid:order.id,status1:'1'});
                                                  this.RefundApprovHandler1(order.id,'1')
                                                }}>Approve</button>{' '}
                                                <button className="btn btn-danger" onClick={() =>{
                                                  // this.setState({userid:order.id,status1:'2'});
                                                  this.RefundApprovHandler1(order.id,'2')
                                                }}>Cancel</button>
                                              {/* <i
                                                className="fa fa-check check-icon"
                                                onClick={() =>{
                                                  // this.setState({userid:order.id,status1:'1'});
                                                  this.RefundApprovHandler1(order.id,'1')
                                                }}
                                                style={{ color: '#47a547' }}
                                              ></i>
                                              <i
                                                className="fa fa-times uncheck-icon"
                                                onClick={() =>{
                                                  // this.setState({userid:order.id,status1:'2'});
                                                  this.RefundApprovHandler1(order.id,'2')
                                                }
                                                }
                                                style={{ color: '#ef4835' }}
                                              ></i> */}
                                            </>
                                          ) : order.refundStatus ===
                                            '2' ? (
                                            'Cancel'
                                          ) : (
                                            'Approve'
                                          )
                                        ) : (
                                          ''
                                        ):''}
                                      </td>
                                      <td
                                        className="white-space"
                                        style={{
                                          textAlign: 'center',
                                        }}
                                      >
                                        {order.returnApprovedStatus !== '8' ? (
                                          order.returnApprovedStatus === '0' ? (
                                            <>
                                            <button className="btn btn-success" onClick={() =>{
                                                  // this.setState({userid:order.id,status1:'1'});
                                                  this.ReturnApprovHandler1(order.id,'1')
                                                }}>Approve</button>{' '}
                                                <button className="btn btn-danger" onClick={() =>{
                                                  // this.setState({userid:order.id,status1:'2'});
                                                  this.ReturnApprovHandler1(order.id,'2')
                                                }}>Cancel</button>
                                              {/* <i
                                                className="fa fa-check check-icon"
                                                onClick={() =>{
                                                  // this.setState({userid:order.id,status1:'1'});
                                                  this.ReturnApprovHandler1(order.id,'1')
                                                }}
                                                style={{ color: '#47a547' }}
                                              ></i>
                                              <i
                                                className="fa fa-times uncheck-icon"
                                                onClick={() =>{
                                                  // this.setState({userid:order.id,status1:'2'});
                                                  this.ReturnApprovHandler1(order.id,'2')
                                                }
                                                }
                                                style={{ color: '#ef4835' }}
                                              ></i> */}
                                            </>
                                          ) : order.returnApprovedStatus ===
                                            '2' ? (
                                            'Cancel'
                                          ) : (
                                            'Approve'
                                          )
                                        ) : (
                                          ''
                                        )}
                                      </td>
                                      <td
                                        className="white-space"
                                        style={{
                                          textAlign: 'center',
                                        }}
                                      >
                                        {order.exchangeApprovedStatus!=="8"? (
                                          order.exchangeApprovedStatus ==='0' ? (
                                            <>
                                             <button className="btn btn-success" onClick={() =>{
                                                  // this.setState({userid:order.id,status1:'1'});
                                                  this.ExchangeApprovHandler1(order.id,'2')
                                                }}>Approve</button>{' '}
                                                <button className="btn btn-danger" onClick={() =>{
                                                  // this.setState({userid:order.id,status1:'2'});
                                                  this.ExchangeApprovHandler1(order.id,'4')
                                                }}>Cancel</button>
                                              {/* <i
                                                className="fa fa-check check-icon"
                                                onClick={() =>{
                                                  // this.setState({userid:order.id,status1:'2'});
                                                  this.ExchangeApprovHandler1(order.id,'2')}
                                                }
                                                style={{ color: '#47a547' }}
                                              ></i>
                                              <i
                                                className="fa fa-times uncheck-icon"
                                                onClick={() =>
                                                  {
                                                    // this.setState({userid:order.id,status1:'4'});
                                                    this.ExchangeApprovHandler1(order.id,'4')}
                                                }
                                                style={{ color: '#ef4835' }}
                                              ></i> */}
                                            </>
                                          ) : order.exchangeApprovedStatus ===
                                            '4' ? (
                                            'Cancel'
                                          ) : (
                                            'Approve'
                                          )
                                        ) : (
                                          ''
                                        )}
                                      </td>
                                      <td
                                        className="white-space"
                                        style={{
                                          textAlign: 'center',
                                        }}
                                      >
                                        {order.returnReason !== '' &&order.cancelApprovedStatus !== '8'? (
                                          order.cancelApprovedStatus === '0' ? (
                                            <>
                                            <button className="btn btn-success" onClick={() =>{
                                                  // this.setState({userid:order.id,status1:'1'});
                                                  this.CancelApprovHandler1(order.id,'3')
                                                }}>Approve</button>{' '}
                                                <button className="btn btn-danger" onClick={() =>{
                                                  // this.setState({userid:order.id,status1:'2'});
                                                  this.CancelApprovHandler1(order.id,'4')
                                                }}>Cancel</button>
                                              {/* <i
                                                className="fa fa-check check-icon"
                                                onClick={() =>{
                                                  // this.setState({userid:order.id,status1:'3'});
                                                  this.CancelApprovHandler1(order.id,'3')}
                                                  
                                                }
                                                style={{ color: '#47a547' }}
                                              ></i>
                                              <i
                                                className="fa fa-times uncheck-icon"
                                                onClick={() =>{
                                                  // this.setState({userid:order.id,status1:'4'});
                                                  this.CancelApprovHandler1(order.id,'4')}
                                                }
                                                style={{ color: '#ef4835' }}
                                              ></i> */}
                                            </>
                                          ) : order.cancelApprovedStatus ===
                                            '4' ? (
                                            'Cancel'
                                          ) : (
                                            'Approve'
                                          )
                                        ) : (
                                          ''
                                        )}
                                      </td>
                                    </tr>
                                  </>
                                )):
                                <tr style={{textAlign:'center'}}><td colSpan="12">No Data Available</td></tr>}

                              </tbody>
                            </table>
                            {this.state.totalCount ? (
                              <ReactPaginate
                                previousLabel={'prev'}
                                nextLabel={'next'}
                                breakLabel={'...'}
                                breakClassName={'break-me'}
                                pageCount={this.state.pageCount}
                                marginPagesDisplayed={2}
                                pageRangeDisplayed={5}
                                onPageChange={this.handlePageClick}
                                containerClassName={'pagination'}
                                subContainerClassName={'pages pagination'}
                                activeClassName={'active'}
                              />
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
        <div class="sidenav-overlay"></div>
        <div class="drag-target"></div>

        <Footer />
      </body>
    );
  }
}

export default ReturnExchangeList;
