import React from "react";
import axios from "axios";
import StarRatings from "react-star-ratings";
//import Slider from "react-slick";

import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";

import { Link } from "react-router-dom";
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import classnames from "classnames";
// import Sidebar from "../../Component/Sidebar/Sidebar";
// import Header from "../../Component/Header/Header";
import Footer from "../../Component/Footer/Footer";
import global from "../../global";
import "./Product.css";
const $ = require("jquery");
$.DataTable = require("datatables.net");

var images = [];
toast.configure();
const CustomToast = () => {
  return (
    <div>
      <i class="fa fa-check success-toast"></i>
      Product Stock Updated Successfully
    </div>
  );
};
class ViewProduct extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: this.props.match.params.id,
      product: {},
      colorSize: [],
      reviews: [],
      activeTab: "1",
      photoIndex: 0,
      isOpen: false
    };
  }
  componentWillMount() {
    this.listAPI();

    var urlencoded = new URLSearchParams();
    urlencoded.append("productId", this.state.id);

    axios
      .post(global.baseUrl + "sellerproductratereviewlist", urlencoded, {
        headers: { "Content-Type": "application/x-www-form-urlencoded" }
      })
      .then(result => {
        console.log(result.data, "rating");
        if (result.data.status) {
          this.setState({ reviews: result.data.data });
        }
        this.dataTable()
      });
  }
  listAPI() {
    var urlencoded = new URLSearchParams();
    urlencoded.append("id", this.state.id);

    axios
      .post(global.baseUrl + "sellerproductIdlist", urlencoded, {
        headers: { "Content-Type": "application/x-www-form-urlencoded" }
      })
      .then(result => {
        console.log(result.data);
        if (result.data.status) {
          this.setState({ product: result.data.data });
          var colorSizeArray = result.data.data.color_size?result.data.data.color_size:0;
          for (let i = 0; i < colorSizeArray.length; i++) {
            colorSizeArray[i] = JSON.parse(colorSizeArray[i]);
          }
          this.setState({
            colorSize: colorSizeArray,
            sellerId: result.data.data.sellerId
          });
        }
      });
  }

  handleQtyChange(event, index) {
    console.log(event.target.value);
    let numberCheck = /^[0-9]\d*(\.\d+)?$/;

    var colorSize = this.state.colorSize;
    if (!numberCheck.test(event.target.value)) {
      event.target.value = "";
      colorSize[index].qty = event.target.value;
    } else {
      colorSize[index].qty = event.target.value;
    }
    this.setState({ colorSize: colorSize });
    console.log(this.state.colorSize);
  }
  productApiCall = () => {
    let quantity = 0;
    console.log("working", this.state.colorSize);
    var urlencoded = new URLSearchParams();
    for (let i = 0; i < this.state.colorSize.length; i++) {
      quantity = quantity + parseInt(this.state.colorSize[i].qty);
      urlencoded.append("color_size", JSON.stringify(this.state.colorSize[i]));
    }
    urlencoded.append("id", this.state.id);
    urlencoded.append("quantity", quantity);

    urlencoded.append("sellerId", this.state.sellerId);
    axios
      .post(global.baseUrl + "sellerstockupdate", urlencoded, {
        headers: { "Content-Type": "application/x-www-form-urlencoded" }
      })
      .then(result => {
        console.log(result.data);
        if (result.data.status) {
          toast.success(<CustomToast />, {
            position: toast.POSITION.TOP_RIGHT
          });

          this.props.history.push("/viewproduct/" + this.state.id);
        }
      });
  };
  toggle = tab => {
    if (tab === "1") {
      this.setState({ activeTab: "1" });
      this.listAPI();
    } else if (tab === "2") {
      this.setState({ activeTab: "2" });
    } else {
      this.setState({ activeTab: "3" })
    }
  };
  slideOpen = image => {
    images = image;
    this.setState({ isOpen: true });
  };
  dataTable() {
    $(document).ready(function() {
      $("#list").DataTable();
    });
  }
  render() {
    const { photoIndex, isOpen } = this.state;
    return (
      <body
        class="vertical-layout vertical-menu-modern semi-dark-layout 2-columns  navbar-floating footer-static  "
        data-open="click"
        data-menu="vertical-menu-modern"
        data-col="2-columns"
        data-layout="semi-dark-layout"
      >
        {/* <Header />
        <Sidebar /> */}

        <div class="app-content content">
          <div class="content-overlay"></div>
          <div class="header-navbar-shadow"></div>
          <div class="content-wrapper">
            <div class="content-header row">
              <div class="content-header-left col-md-9 col-12 mb-2">
                <div class="row breadcrumbs-top">
                  <div class="col-12">
                    <h2 class="content-header-title float-left mb-0">
                      View Product
                    </h2>
                    <div class="breadcrumb-wrapper col-12">
                      <ol class="breadcrumb">
                        <li class="breadcrumb-item">
                          <Link to="/productlist">Products</Link>
                        </li>
                        <li class="breadcrumb-item active">
                          Product Detail & Review
                        </li>
                      </ol>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="content-body">
              <section class="users-edit">
                <div class="card">
                  <div class="card-content">
                    <div class="card-body">
                      <Nav tabs>
                        <NavItem>
                          <NavLink
                            className={classnames({
                              active: this.state.activeTab === "1"
                            })}
                            onClick={() => {
                              this.toggle("1");
                            }}
                          >
                            <span class="d-none d-sm-block">
                              Product Detail
                            </span>
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className={classnames({
                              active: this.state.activeTab === "2"
                            })}
                            onClick={() => {
                              this.toggle("2");
                            }}
                          >
                            <span class="d-none d-sm-block">Rate & Review</span>
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className={classnames({
                              active: this.state.activeTab === "3"
                            })}
                            onClick={() => {
                              this.toggle("3");
                            }}
                          >
                            <span class="d-none d-sm-block">Total Rate & Review</span>
                          </NavLink>
                        </NavItem>
                      </Nav>

                      <TabContent activeTab={this.state.activeTab}>
                        <TabPane tabId="1">
                          {isOpen && (
                            <Lightbox
                              mainSrc={images[photoIndex]}
                              nextSrc={images[(photoIndex + 1) % images.length]}
                              prevSrc={
                                images[
                                (photoIndex + images.length - 1) %
                                images.length
                                ]
                              }
                              onCloseRequest={() =>
                                this.setState({ isOpen: false })
                              }
                              onMovePrevRequest={() =>
                                this.setState({
                                  photoIndex:
                                    (photoIndex + images.length - 1) %
                                    images.length
                                })
                              }
                              onMoveNextRequest={() =>
                                this.setState({
                                  photoIndex: (photoIndex + 1) % images.length
                                })
                              }
                            />
                          )}

                          <div
                            class="tab-pane active"
                            id="account"
                            aria-labelledby="account-tab"
                            role="tabpanel"
                          >
                            <div>
                              <div class="card-header">
                                <div class="card-title mb-2">
                                  Product Detail
                                </div>
                              </div>

                              <div class="content-body">
                                <section class="page-users-view">
                                  <div class="row">
                                    <div class="col-12">
                                      <div
                                        class="card"
                                        style={{ background: "#eaeae469" }}
                                      >
                                        <div class="card-header">
                                          <div class="card-title">
                                            {this.state.product.product_name}
                                          </div>
                                        </div>
                                        <div class="card-body">
                                          <div class="row">
                                            {this.state.product.image
                                              ? this.state.product.image.map(
                                                (product, index) => (
                                                  <img
                                                    key={index}
                                                    src={product}
                                                    alt="..."
                                                    class="product-view-image"
                                                  />
                                                )
                                              )
                                              : null}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div class="col-md-12 col-12 ">
                                      <div
                                        class="card"
                                        style={{ backgroundColor: "#eaeae469" }}
                                      >
                                        <div class="card-header">
                                          <div class="card-title mb-2">
                                            Product Detail
                                          </div>
                                        </div>
                                        <div class="card-body">
                                          <div class="row">
                                            <div class="col-md-6 col-12 ">
                                              <table>
                                                <tr>
                                                  <td class="td-top-text font-weight-bold product-detail-box">
                                                    Product Name{" "}
                                                  </td>
                                                  <td>
                                                    {
                                                      this.state.product
                                                        .product_name
                                                    }
                                                  </td>
                                                </tr>
                                                <tr>
                                                  <td class="font-weight-bold product-detail-box">
                                                    SKU{" "}
                                                  </td>
                                                  <td>
                                                    {this.state.product.sku}
                                                  </td>
                                                </tr>

                                                <tr>
                                                  <td class="font-weight-bold product-detail-box">
                                                    Price
                                                  </td>
                                                  <td>
                                                    {this.state.product.price}
                                                  </td>
                                                </tr>
                                                <tr>
                                                  <td class="font-weight-bold product-detail-box">
                                                    MSRP
                                                  </td>
                                                  <td>
                                                    {this.state.product.msrp}
                                                  </td>
                                                </tr>
                                                {this.state.product.discount ? (
                                                  <tr>
                                                    <td class="font-weight-bold product-detail-box">
                                                      Discount
                                                    </td>
                                                    <td>
                                                      {
                                                        this.state.product
                                                          .discount
                                                      }
                                                      {
                                                        this.state.product
                                                          .discount_type
                                                      }
                                                    </td>
                                                  </tr>
                                                ) : null}

                                                <tr>
                                                  <td class="font-weight-bold product-detail-box">
                                                    First Category
                                                  </td>
                                                  <td>
                                                    {this.state.product.super_categoryId
                                                      ? this.state.product
                                                        .super_categoryId.name
                                                      : null}
                                                  </td>
                                                </tr>
                                                {this.state.product
                                                  .categoryId ? (
                                                    <tr>
                                                      <td class="font-weight-bold product-detail-box">
                                                        Second Category
                                                    </td>
                                                      <td>
                                                        {
                                                          this.state.product
                                                            .categoryId.name
                                                        }
                                                      </td>
                                                    </tr>
                                                  ) : null}
                                                {this.state.product
                                                  .sub_categoryId ? (
                                                    <tr>
                                                      <td class="font-weight-bold product-detail-box">
                                                        Third Category
                                                    </td>
                                                      <td>
                                                        {
                                                          this.state.product
                                                            .sub_categoryId.name
                                                        }
                                                      </td>
                                                    </tr>
                                                  ) : null}

                                                <tr>
                                                  <td class="td-top-text font-weight-bold product-detail-box">
                                                    Description
                                                  </td>
                                                  <td>
                                                    <ol>
                                                      {" "}
                                                      {this.state.product
                                                        .product_description &&
                                                        this.state.product
                                                          .product_description
                                                          .length
                                                        ? this.state.product.product_description.map(
                                                          (desc, index) => (
                                                            <li>
                                                              <p
                                                                dangerouslySetInnerHTML={{
                                                                  __html: desc
                                                                }}
                                                              />
                                                            </li>
                                                          )
                                                        )
                                                        : null}
                                                    </ol>
                                                  </td>
                                                </tr>
                                              </table>
                                            </div>
                                            <div class="col-md-6 col-12 ">
                                              <iframe
                                                width="350"
                                                height="200"
                                                src={this.state.product.video}
                                                frameborder="0"
                                                title=" "
                                                // allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                                allowfullscreen
                                              ></iframe>
                                              {/* <video width="400" controls>
                          <source
                            src={this.state.product.video}
                            type="video/mp4"
                          />
                          Your browser does not support HTML video.
                        </video> */}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div class="col-12">
                                      <div
                                        class="card"
                                        style={{ backgroundColor: "#eaeae469" }}
                                      >
                                        <div class="card-header border-bottom mx-2 px-0">
                                          <h6 class="border-bottom py-1 mb-0 font-medium-2">
                                            <i class="feather icon-lock mr-50 "></i>
                                            Stock Management
                                          </h6>
                                        </div>
                                        <div class="card-body px-75">
                                          <div class="table-responsive users-view-permission">
                                            {this.state.product
                                              .variation_type === "normal" ? (
                                                <table class="table table-borderless">
                                                  <thead>
                                                    <tr
                                                      style={{
                                                        textAlign: "center"
                                                      }}
                                                    >
                                                      <th className="stock-td">
                                                        No
                                                    </th>

                                                      <th className="stock-td">
                                                        Name
                                                    </th>
                                                      <th className="stock-td">
                                                        Value
                                                    </th>
                                                      <th className="stock-td">
                                                        Cus Price
                                                    </th>
                                                      <th className="stock-td">
                                                        Stock
                                                    </th>
                                                      <th className="stock-td">
                                                        Status
                                                    </th>
                                                      <th></th>
                                                    </tr>
                                                  </thead>
                                                  <tbody>
                                                    {this.state.colorSize
                                                      ? this.state.colorSize.map(
                                                        (colorSize, index) => (
                                                          <tr
                                                            style={{
                                                              textAlign:
                                                                "center"
                                                            }}
                                                          >
                                                            <td className="stock-td">
                                                              {index + 1}
                                                            </td>
                                                            <td>
                                                              {/* <div
                                                                  className="color-text-d"
                                                                  style={{
                                                                    backgroundColor:
                                                                      colorSize.color
                                                                  }}
                                                                ></div> */}
                                                              {colorSize.name}
                                                            </td>

                                                            <td>
                                                              {/* <div
                                                                  className="color-text-d"
                                                                  style={{
                                                                    backgroundColor:
                                                                      colorSize.color
                                                                  }}
                                                                ></div> */}
                                                              {colorSize.value}
                                                            </td>
                                                            <td>
                                                              {colorSize.price
                                                                ? colorSize.price
                                                                : null}
                                                            </td>
                                                            <td className="stock-td">
                                                              <input
                                                                type="text"
                                                                class="form-control"
                                                                placeholder="Quantity"
                                                                value={
                                                                  colorSize.qty
                                                                }
                                                                onChange={e =>
                                                                  this.handleQtyChange(
                                                                    e,
                                                                    index
                                                                  )
                                                                }
                                                                aria-label="Amount"
                                                                style={{
                                                                  width: "180px"
                                                                }}
                                                                maxLength={7}
                                                              />
                                                            </td>
                                                            <td className="stock-td">
                                                              {parseInt(
                                                                colorSize.qty
                                                              ) <= 0
                                                                ? "Out of stock"
                                                                : "Available"}
                                                            </td>
                                                            <td></td>
                                                          </tr>
                                                        )
                                                      )
                                                      : null}
                                                  </tbody>
                                                </table>
                                              ) : (
                                                <table class="table table-borderless">
                                                  <thead>
                                                    <tr
                                                      style={{
                                                        textAlign: "center"
                                                      }}
                                                    >
                                                      <th className="stock-td">
                                                        No
                                                    </th>

                                                      <th className="stock-td">
                                                        Color
                                                    </th>
                                                      <th className="stock-td">
                                                        Size
                                                    </th>
                                                      <th className="stock-td">
                                                        Cus Price
                                                    </th>
                                                      <th className="stock-td">
                                                        Stock
                                                    </th>
                                                      <th className="stock-td">
                                                        Status
                                                    </th>
                                                      <th></th>
                                                    </tr>
                                                  </thead>
                                                  <tbody>
                                                    {this.state.colorSize
                                                      ? this.state.colorSize.map(
                                                        (colorSize, index) => (
                                                          <tr
                                                            style={{
                                                              textAlign:
                                                                "center"
                                                            }}
                                                          >
                                                            <td className="stock-td">
                                                              {index + 1}
                                                            </td>
                                                            <td className="stock-td">
                                                              <div
                                                                className="color-text-d"
                                                                style={{
                                                                  backgroundColor:
                                                                    colorSize.color,
                                                                  marginLeft: "42px"
                                                                }}
                                                              ></div>
                                                            </td>

                                                            <td>
                                                              {/* <div
                                                              className="color-text-d"
                                                              style={{
                                                                backgroundColor:
                                                                  colorSize.color
                                                              }}
                                                            ></div> */}
                                                              {colorSize.size}
                                                            </td>
                                                            <td>
                                                              {colorSize.price
                                                                ? colorSize.price
                                                                : null}
                                                            </td>
                                                            <td className="stock-td">
                                                              <input
                                                                type="text"
                                                                class="form-control"
                                                                placeholder="Quantity"
                                                                value={
                                                                  colorSize.qty
                                                                }
                                                                onChange={e =>
                                                                  this.handleQtyChange(
                                                                    e,
                                                                    index
                                                                  )
                                                                }
                                                                aria-label="Amount"
                                                                style={{
                                                                  width: "180px"
                                                                }}
                                                                maxLength={7}
                                                              />
                                                            </td>
                                                            <td className="stock-td">
                                                              {parseInt(
                                                                colorSize.qty
                                                              ) <= 0
                                                                ? "Out of stock"
                                                                : "Available"}
                                                            </td>
                                                            <td></td>
                                                          </tr>
                                                        )
                                                      )
                                                      : null}
                                                  </tbody>
                                                </table>
                                              )}
                                        <button
                                              class="btn btn-primary-green p-1 add-size add-product"
                                              type="button"
                                              onClick={this.productApiCall}
                                            // disabled={submitDisable}
                                            >
                                              Update Stock
                                            </button>
                                      <Link to={"/productlist"}><button
                                              class="btn btn-primary-green p-1 add-size add-product"
                                              type="button"
                                            
                                            >
                                           
                                           Go Back
                                            </button></Link> 
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </section>
                              </div>
                            </div>
                          </div>
                        </TabPane>
                        <TabPane tabId="2">
                          <div class="col-md-12 col-12 ">
                            <div class="card">
                              <div class="card-header">
                                <div class="card-title mb-2">Average Reviews</div>
                              </div>
                              <div class="card-body">
                                {this.state.reviews
                                  ? this.state.reviews.map((review, index) => (
                                    <div
                                      class="row"
                                      style={{
                                        backgroundColor: "#eaeae469",
                                        padding: "20px",
                                        marginBottom: "20px",
                                        borderRadius: "0.5rem"
                                      }}
                                    >
                                      <div class="col-md-3 col-12 " style={{ padding: "0px" }}>
                                        {review.type === "photo" ? (
                                          review.file_path.length ? (
                                            <div>
                                              <img
                                                key={index}
                                                src={review.file_path[0]}
                                                alt="..."
                                                class="product-view-image"
                                                onClick={() =>
                                                  this.slideOpen(
                                                    review.file_path
                                                  )
                                                }
                                                style={{ cursor: "grab" }}
                                              />
                                            </div>
                                          ) : null
                                        ) : null}
                                        {review.type === "video" ? (
                                          <iframe
                                            width="250"
                                            height="150"
                                            src={this.state.product.video}
                                            frameborder="0"
                                            title=" "
                                            // allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                            allowfullscreen
                                          ></iframe>
                                        ) : null}
                                      </div>

                                      <div class="col-md-5 col-12 " style={{ padding: "0px" }}>
                                        <table>
                                          <tr>
                                            <td class="font-weight-bold product-detail-box" style={{ width: "58%" }}>
                                              Communication To Seller
                                              </td>
                                            <td>
                                              <StarRatings
                                                rating={parseFloat(
                                                  review.communicationToSeller
                                                )}
                                                starDimension="15px"
                                                starSpacing="3px"
                                              />
                                              <span className="r-box">({parseFloat(
                                                review.communicationToSeller
                                              ) * 5})</span>
                                              {/* {review.rating} */}
                                            </td>
                                          </tr>
                                          <tr>
                                            <td class="font-weight-bold product-detail-box">
                                              Product As Described
                                              </td>
                                            <td>
                                              <StarRatings
                                                rating={parseFloat(
                                                  review.productAsDescribed
                                                )}
                                                starDimension="15px"
                                                starSpacing="3px"
                                              />
                                              <span className="r-box">({parseFloat(
                                                review.productAsDescribed
                                              ) * 5})</span>
                                              {/* {review.size} */}
                                            </td>
                                          </tr>
                                          <tr>
                                            <td class="font-weight-bold product-detail-box">
                                              Shipping Delivery
                                              </td>
                                            <td>
                                              <StarRatings
                                                rating={parseFloat(
                                                  review.shippingDelivery
                                                )}
                                                starDimension="15px"
                                                starSpacing="3px"
                                              />
                                              <span className="r-box">({parseFloat(
                                                review.shippingDelivery
                                              ) * 5})</span>
                                              {/* {review.value} */}
                                            </td>
                                          </tr>
                                          <tr>
                                            <td class="font-weight-bold product-detail-box">
                                              Shipping Charges
                                              </td>
                                            <td>
                                              <StarRatings
                                                rating={parseFloat(
                                                  review.shippingCharges
                                                )}
                                                starDimension="15px"
                                                starSpacing="3px"
                                              />
                                              <span className="r-box">({parseFloat(
                                                review.shippingCharges
                                              ) * 5})</span>
                                            </td>
                                          </tr>
                                        </table>
                                      </div>
                                      <div class="col-md-4 col-12 ">
                                        <table>
                                          <tr>
                                            <td class="font-weight-bold product-detail-box">
                                              User Name
                                              </td>
                                            <td>
                                              {review.userId
                                                ? review.userId.name
                                                : null}
                                            </td>
                                          </tr>
                                          <tr>
                                            <td class="font-weight-bold product-detail-box">
                                              Review Headline
                                              </td>
                                            <td>{review.review_headline}</td>
                                          </tr>

                                          <tr>
                                            <td class="td-top-text font-weight-bold product-detail-box">
                                              Description
                                              </td>
                                            <td>
                                              {review.review_description}
                                            </td>
                                          </tr>
                                        </table>
                                      </div>
                                    </div>
                                  ))
                                  : null}
                              </div>
                            </div>
                          </div>
                        </TabPane>
                        <TabPane tabId="3">
                          <div class="col-md-12 col-12 ">
                            <div class="card" >
                              <div class="card-header">
                                <div class="card-title mb-2">Total Reviews</div>
                              </div>
                              <div class="card-body" style={{paddingLeft:"0px",paddingRight:"0px"}}>
                                <div className="overflow-tables">
                                <table id="list">
                                  <thead>
                                    <tr>
                                      <th>Id</th>
                                      <th>User Name</th>
                                      <th>Communication To Seller</th>
                                      <th>Product As Described</th>
                                      <th>Shipping Delivery</th>
                                      <th>Shipping Charges</th>
                                      <th>Review Headline</th>
                                      <th>Description</th>

                                    </tr>
                                  </thead>
                                  <tbody>
                                    {this.state.reviews.map((review, index) => (

                                      <tr key={index}>
                                        <td>{index+1}</td>
                                        <td>
                                          {review.userId
                                            ? review.userId.name
                                            : null}
                                        </td>
                                        <td>
                                          <StarRatings
                                            rating={parseFloat(
                                              review.communicationToSeller
                                            )}
                                            starDimension="15px"
                                            starSpacing="3px"
                                          />
                                          <span className="r-box">({parseFloat(
                                            review.communicationToSeller
                                          ) * 5})</span>
                                          {/* {review.rating} */}
                                        </td>
                                        <td>
                                          <StarRatings
                                            rating={parseFloat(
                                              review.productAsDescribed
                                            )}
                                            starDimension="15px"
                                            starSpacing="3px"
                                          />
                                          <span className="r-box">({parseFloat(
                                            review.productAsDescribed
                                          ) * 5})</span>
                                          {/* {review.size} */}
                                        </td>
                                        <td>
                                          <StarRatings
                                            rating={parseFloat(
                                              review.shippingDelivery
                                            )}
                                            starDimension="15px"
                                            starSpacing="3px"
                                          />
                                          <span className="r-box">({parseFloat(
                                            review.shippingDelivery
                                          ) * 5})</span>
                                          {/* {review.value} */}
                                        </td>
                                        <td>
                                          <StarRatings
                                            rating={parseFloat(
                                              review.shippingCharges
                                            )}
                                            starDimension="15px"
                                            starSpacing="3px"
                                          />
                                          <span className="r-box">({parseFloat(
                                            review.shippingCharges
                                          ) * 5})</span>
                                        </td>
                                       
                                        <td>{review.review_headline}</td>
                                        <td className="td-top-text">
                                          {review.review_description}
                                        </td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </TabPane>

                      </TabContent>

                      {/* <ul class="nav nav-tabs mb-3" role="tablist">
                        <li class="nav-item">
                          <a
                            class="nav-link d-flex align-items-center active"
                            id="account-tab"
                            data-toggle="tab"
                            href="#account"
                            aria-controls="account"
                            role="tab"
                            aria-selected="true"
                          >
                            <i class="feather icon-user mr-25"></i>
                            <span class="d-none d-sm-block">Account</span>
                          </a>
                        </li>
                        <li class="nav-item">
                          <a
                            class="nav-link d-flex align-items-center"
                            id="information-tab"
                            data-toggle="tab"
                            href="#information"
                            aria-controls="information"
                            role="tab"
                            aria-selected="false"
                          >
                            <i class="feather icon-info mr-25"></i>
                            <span class="d-none d-sm-block">Information</span>
                          </a>
                        </li>
                        <li class="nav-item">
                          <a
                            class="nav-link d-flex align-items-center"
                            id="social-tab"
                            data-toggle="tab"
                            href="#social"
                            aria-controls="social"
                            role="tab"
                            aria-selected="false"
                          >
                            <i class="feather icon-share-2 mr-25"></i>
                            <span class="d-none d-sm-block">Social</span>
                          </a>
                        </li>
                      </ul> */}
                      {/* <div class="tab-content">
                        <div
                          class="tab-pane active"
                          id="account"
                          aria-labelledby="account-tab"
                          role="tabpanel"
                        >
                          <div>
                            <div class="content-body">
                              <section class="page-users-view">
                                <div class="row">
                                  <div class="col-12">
                                    <div
                                      class="card"
                                      style={{ background: "#eaeae469" }}
                                    >
                                      <div class="card-header">
                                        <div class="card-title">
                                          {this.state.product.product_name}
                                        </div>
                                      </div>
                                      <div class="card-body">
                                        <div class="row">
                                          {console.log(this.state.product)}
                                          {this.state.product.image
                                            ? this.state.product.image.map(
                                                (product, index) => (
                                                  <img
                                                    key={index}
                                                    src={product}
                                                    alt="..."
                                                    class="product-view-image"
                                                  />
                                                )
                                              )
                                            : null}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="col-md-12 col-12 ">
                                    <div
                                      class="card"
                                      style={{ backgroundColor: "#eaeae469" }}
                                    >
                                      <div class="card-header">
                                        <div class="card-title mb-2">
                                          Product Detail
                                        </div>
                                      </div>
                                      <div class="card-body">
                                        <div class="row">
                                          <div class="col-md-6 col-12 ">
                                            <table>
                                              <tr>
                                                <td class="font-weight-bold product-detail-box">
                                                  Product Name{" "}
                                                </td>
                                                <td>
                                                  {
                                                    this.state.product
                                                      .product_name
                                                  }
                                                </td>
                                              </tr>
                                              <tr>
                                                <td class="font-weight-bold product-detail-box">
                                                  Price
                                                </td>
                                                <td>
                                                  {this.state.product.price}
                                                </td>
                                              </tr>
                                              <tr>
                                                <td class="font-weight-bold product-detail-box">
                                                  Product Category
                                                </td>
                                                <td>
                                                  {this.state.product.category
                                                    ? this.state.product
                                                        .category.name
                                                    : null}
                                                </td>
                                              </tr>
                                              <tr>
                                                <td class="font-weight-bold product-detail-box">
                                                  Brand
                                                </td>
                                                <td>
                                                  {this.state.product.brand
                                                    ? this.state.product.brand
                                                        .name
                                                    : null}
                                                </td>
                                              </tr>
                                              <tr>
                                                <td class="font-weight-bold product-detail-box">
                                                  Fit Guide
                                                </td>
                                                <td>
                                                  {this.state.product.fit_guide}
                                                </td>
                                              </tr>
                                              <tr>
                                                <td class="font-weight-bold product-detail-box">
                                                  Glossary
                                                </td>
                                                <td>
                                                  {this.state.product.glossary}
                                                </td>
                                              </tr>
                                              <tr>
                                                <td class="font-weight-bold product-detail-box">
                                                  Description
                                                </td>
                                                <td>
                                                  {
                                                    this.state.product
                                                      .product_description
                                                  }
                                                </td>
                                              </tr>
                                            </table>
                                          </div>
                                          <div class="col-md-6 col-12 ">
                                            <iframe
                                              width="350"
                                              height="200"
                                              src={this.state.product.video}
                                              frameborder="0"
                                              title=" "
                                              // allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                              allowfullscreen
                                            ></iframe>
                                    
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="col-12">
                                    <div
                                      class="card"
                                      style={{ backgroundColor: "#eaeae469" }}
                                    >
                                      <div class="card-header border-bottom mx-2 px-0">
                                        <h6 class="border-bottom py-1 mb-0 font-medium-2">
                                          <i class="feather icon-lock mr-50 "></i>
                                          Stock Management
                                        </h6>
                                      </div>
                                      <div class="card-body px-75">
                                        <div class="table-responsive users-view-permission">
                                          <table class="table table-borderless">
                                            <thead>
                                              <tr
                                                style={{ textAlign: "center" }}
                                              >
                                                <th className="stock-td">No</th>
                                                <th className="stock-td">
                                                  Color
                                                </th>
                                                <th className="stock-td">
                                                  Size
                                                </th>
                                                <th className="stock-td">
                                                  Stock
                                                </th>
                                                <th className="stock-td">
                                                  Status
                                                </th>
                                                <th></th>
                                              </tr>
                                            </thead>
                                            <tbody>
                                              {console.log(
                                                this.state.colorSize
                                              )}
                                              {this.state.colorSize
                                                ? this.state.colorSize.map(
                                                    (colorSize, index) =>
                                                      colorSize.size_qty.map(
                                                        (size_qty, index1) => (
                                                          <tr
                                                            style={{
                                                              textAlign:
                                                                "center"
                                                            }}
                                                          >
                                                            <td className="stock-td">
                                                              {index1 + index}
                                                            </td>
                                                            <td className="color-box-d stock-td">
                                                              <div
                                                                className="color-text-d"
                                                                style={{
                                                                  backgroundColor:
                                                                    colorSize.color
                                                                }}
                                                              ></div>
                                                            </td>
                                                            <td className="stock-td">
                                                              {size_qty.size}
                                                            </td>
                                                            <td className="stock-td">
                                                              <input
                                                                type="number"
                                                                class="form-control"
                                                                placeholder="Quantity"
                                                                value={
                                                                  size_qty.quantity
                                                                }
                                                                onChange={e =>
                                                                  this.handleQtyChange(
                                                                    e,
                                                                    index,
                                                                    index1
                                                                  )
                                                                }
                                                                aria-label="Amount"
                                                                style={{
                                                                  width: "180px"
                                                                }}
                                                              />
                                                            </td>
                                                            <td className="stock-td">
                                                              {size_qty.quantity ===
                                                              "0"
                                                                ? "Out of stock"
                                                                : "Available"}
                                                            </td>
                                                            <td></td>
                                                          </tr>
                                                        )
                                                      )
                                                  )
                                                : null}
                                            </tbody>
                                            <button
                                              class="btn btn-primary-green p-1 add-size add-product"
                                              type="button"
                                              onClick={this.productApiCall}
                                              // disabled={submitDisable}
                                            >
                                              Update Stock
                                            </button>
                                          </table>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </section>
                            </div>
                          </div>
                        </div>
                        <div
                          class="tab-pane"
                          id="information"
                          aria-labelledby="information-tab"
                          role="tabpanel"
                        >
                          <div class="col-md-12 col-12 ">
                            <div class="card">
                              <div class="card-header">
                                <div class="card-title mb-2">Reviews</div>
                              </div>
                              <div class="card-body">
                                {this.state.reviews
                                  ? this.state.reviews.map((review, index) => (
                                      <div class="row">
                                        <div class="col-md-3 col-12 ">
                                          <img
                                            key={index}
                                            src={review.file_path}
                                            alt="..."
                                            class="product-view-image"
                                          />
                                        </div>

                                        <div class="col-md-4 col-12 ">
                                          <table>
                                            <tr>
                                              <td class="font-weight-bold product-detail-box">
                                                User Name
                                              </td>
                                              <td>
                                                {review.userId
                                                  ? review.userId.name
                                                  : null}
                                              </td>
                                            </tr>
                                            <tr>
                                              <td class="font-weight-bold product-detail-box">
                                                Rating
                                              </td>
                                              <td>
                                                <StarRatings
                                                  rating={2.403}
                                                  starDimension="20px"
                                                  starSpacing="5px"
                                                />
                                                {review.rating}
                                              </td>
                                            </tr>
                                            <tr>
                                              <td class="font-weight-bold product-detail-box">
                                                Fit as expect
                                              </td>
                                              <td>{review.size}</td>
                                            </tr>
                                            <tr>
                                              <td class="font-weight-bold product-detail-box">
                                                Value for money
                                              </td>
                                              <td>{review.value}</td>
                                            </tr>
                                          </table>
                                        </div>
                                        <div class="col-md-4 col-12 ">
                                          <table>
                                            <tr>
                                              <td class="font-weight-bold product-detail-box">
                                                Review Headline
                                              </td>
                                              <td>{review.review_headline}</td>
                                            </tr>
                                            <tr>
                                              <td class="font-weight-bold product-detail-box">
                                                Easy to use
                                              </td>
                                              <td>{review.use}</td>
                                            </tr>
                                            <tr>
                                              <td class="font-weight-bold product-detail-box">
                                                Description
                                              </td>
                                              <td>
                                                {review.review_description}
                                              </td>
                                            </tr>
                                          </table>
                                        </div>
                                      </div>
                                    ))
                                  : null}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          class="tab-pane"
                          id="social"
                          aria-labelledby="social-tab"
                          role="tabpanel"
                        >
                          <form novalidate>
                            <div class="row">
                              <div class="col-12 col-sm-6">
                                <fieldset>
                                  <label>Twitter</label>
                                  <div class="input-group mb-75">
                                    <div class="input-group-prepend">
                                      <span
                                        class="input-group-text feather icon-twitter"
                                        id="basic-addon3"
                                      ></span>
                                    </div>
                                    <input
                                      type="text"
                                      class="form-control"
                                      value="https://www.twitter.com/adoptionism744"
                                      placeholder="https://www.twitter.com/"
                                      aria-describedby="basic-addon3"
                                    />
                                  </div>

                                  <label>Facebook</label>
                                  <div class="input-group mb-75">
                                    <div class="input-group-prepend">
                                      <span
                                        class="input-group-text feather icon-facebook"
                                        id="basic-addon4"
                                      ></span>
                                    </div>
                                    <input
                                      type="text"
                                      class="form-control"
                                      value="https://www.facebook.com/adoptionism664"
                                      placeholder="https://www.facebook.com/"
                                      aria-describedby="basic-addon4"
                                    />
                                  </div>
                                  <label>Instagram</label>
                                  <div class="input-group mb-75">
                                    <div class="input-group-prepend">
                                      <span
                                        class="input-group-text feather icon-instagram"
                                        id="basic-addon5"
                                      ></span>
                                    </div>
                                    <input
                                      type="text"
                                      class="form-control"
                                      value="https://www.instagram.com/adopt-ionism744"
                                      placeholder="https://www.instagram.com/"
                                      aria-describedby="basic-addon5"
                                    />
                                  </div>
                                </fieldset>
                              </div>
                              <div class="col-12 col-sm-6">
                                <label>Github</label>
                                <div class="input-group mb-75">
                                  <div class="input-group-prepend">
                                    <span
                                      class="input-group-text feather icon-github"
                                      id="basic-addon9"
                                    ></span>
                                  </div>
                                  <input
                                    type="text"
                                    class="form-control"
                                    value="https://www.github.com/madop818"
                                    placeholder="https://www.github.com/"
                                    aria-describedby="basic-addon9"
                                  />
                                </div>
                                <label>Codepen</label>
                                <div class="input-group mb-75">
                                  <div class="input-group-prepend">
                                    <span
                                      class="input-group-text feather icon-codepen"
                                      id="basic-addon12"
                                    ></span>
                                  </div>
                                  <input
                                    type="text"
                                    class="form-control"
                                    value="https://www.codepen.com/adoptism243"
                                    placeholder="https://www.codepen.com/"
                                    aria-describedby="basic-addon12"
                                  />
                                </div>
                                <label>Slack</label>
                                <div class="input-group mb-75">
                                  <div class="input-group-prepend">
                                    <span
                                      class="input-group-text feather icon-slack"
                                      id="basic-addon11"
                                    ></span>
                                  </div>
                                  <input
                                    type="text"
                                    class="form-control"
                                    value="@adoptionism744"
                                    placeholder="https://www.slack.com/"
                                    aria-describedby="basic-addon11"
                                  />
                                </div>
                              </div>
                              <div class="col-12 d-flex flex-sm-row flex-column justify-content-end mt-1">
                                <button
                                  type="submit"
                                  class="btn btn-primary glow mb-1 mb-sm-0 mr-0 mr-sm-1"
                                >
                                  Save Changes
                                </button>
                                <button
                                  type="reset"
                                  class="btn btn-outline-warning"
                                >
                                  Reset
                                </button>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div> */}
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>

        {/* <div class="app-content content">
          <div class="content-overlay"></div>
          <div class="header-navbar-shadow"></div>
          <div class="content-wrapper">
            <div class="content-header row"></div>
            <div class="content-body">
              <section class="page-users-view">
                <div class="row">
                  <div class="col-12">
                    <div class="card">
                      <div class="card-header">
                        <div class="card-title">
                          {this.state.product.product_name}
                        </div>
                      </div>
                      <div class="card-body">
                        <div class="row">
                          {console.log(this.state.product)}
                          {this.state.product.image
                            ? this.state.product.image.map((product, index) => (
                                <img
                                  key={index}
                                  src={product}
                                  alt="..."
                                  class="product-view-image"
                                />
                              ))
                            : null}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-12 col-12 ">
                    <div class="card">
                      <div class="card-header">
                        <div class="card-title mb-2">Product Detail</div>
                      </div>
                      <div class="card-body">
                        <div class="row">
                          <div class="col-md-6 col-12 ">
                            <table>
                              <tr>
                                <td class="font-weight-bold product-detail-box">
                                  Product Name{" "}
                                </td>
                                <td>{this.state.product.product_name}</td>
                              </tr>
                              <tr>
                                <td class="font-weight-bold product-detail-box">
                                  Price
                                </td>
                                <td>{this.state.product.price}</td>
                              </tr>
                              <tr>
                                <td class="font-weight-bold product-detail-box">
                                  Product Category
                                </td>
                                <td>
                                  {this.state.product.category
                                    ? this.state.product.category.name
                                    : null}
                                </td>
                              </tr>
                              <tr>
                                <td class="font-weight-bold product-detail-box">
                                  Brand
                                </td>
                                <td>
                                  {this.state.product.brand
                                    ? this.state.product.brand.name
                                    : null}
                                </td>
                              </tr>
                              <tr>
                                <td class="font-weight-bold product-detail-box">
                                  Fit Guide
                                </td>
                                <td>{this.state.product.fit_guide}</td>
                              </tr>
                              <tr>
                                <td class="font-weight-bold product-detail-box">
                                  Glossary
                                </td>
                                <td>{this.state.product.glossary}</td>
                              </tr>
                              <tr>
                                <td class="font-weight-bold product-detail-box">
                                  Description
                                </td>
                                <td>
                                  {this.state.product.product_description}
                                </td>
                              </tr>
                            </table>
                          </div>
                          <div class="col-md-6 col-12 ">
                            <iframe
                              width="350"
                              height="200"
                              src={this.state.product.video}
                              frameborder="0"
                              title=" "
                              // allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                              allowfullscreen
                            ></iframe>
                             <video width="400" controls>
                          <source
                            src={this.state.product.video}
                            type="video/mp4"
                          />
                          Your browser does not support HTML video.
                        </video> 
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="card">
                      <div class="card-header border-bottom mx-2 px-0">
                        <h6 class="border-bottom py-1 mb-0 font-medium-2">
                          <i class="feather icon-lock mr-50 "></i>Stock
                          Management
                        </h6>
                      </div>
                      <div class="card-body px-75">
                        <div class="table-responsive users-view-permission">
                          <table class="table table-borderless">
                            <thead>
                              <tr style={{ textAlign: "center" }}>
                                <th className="stock-td">No</th>
                                <th className="stock-td">Color</th>
                                <th className="stock-td">Size</th>
                                <th className="stock-td">Stock</th>
                                <th className="stock-td">Status</th>
                                <th></th>
                              </tr>
                            </thead>
                            <tbody>
                              {console.log(this.state.colorSize)}
                              {this.state.colorSize
                                ? this.state.colorSize.map((colorSize, index) =>
                                    colorSize.size_qty.map(
                                      (size_qty, index1) => (
                                        <tr style={{ textAlign: "center" }}>
                                          <td className="stock-td">
                                            {index1 + index}
                                          </td>
                                          <td className="color-box-d stock-td">
                                            <div
                                              className="color-text-d"
                                              style={{
                                                backgroundColor: colorSize.color
                                              }}
                                            ></div>
                                          </td>
                                          <td className="stock-td">
                                            {size_qty.size}
                                          </td>
                                          <td className="stock-td">
                                            <input
                                              type="number"
                                              class="form-control"
                                              placeholder="Quantity"
                                              value={size_qty.quantity}
                                              onChange={e =>
                                                this.handleQtyChange(
                                                  e,
                                                  index,
                                                  index1
                                                )
                                              }
                                              aria-label="Amount"
                                              style={{ width: "180px" }}
                                            />
                                          </td>
                                          <td className="stock-td">
                                            {size_qty.quantity === "0"
                                              ? "Out of stock"
                                              : "Available"}
                                          </td>
                                          <td></td>
                                        </tr>
                                      )
                                    )
                                  )
                                : null}
                            </tbody>
                            <button
                              class="btn btn-primary-green p-1 add-size add-product"
                              type="button"
                              onClick={this.productApiCall}
                              // disabled={submitDisable}
                            >
                              Update Stock
                            </button>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-md-12 col-12 ">
                  <div class="card">
                    <div class="card-header">
                      <div class="card-title mb-2">Reviews</div>
                    </div>
                    <div class="card-body">
                      {this.state.reviews
                        ? this.state.reviews.map((review, index) => (
                            <div class="row">
                              <div class="col-md-3 col-12 ">
                                <img
                                  key={index}
                                  src={review.file_path}
                                  alt="..."
                                  class="product-view-image"
                                />
                              </div>

                              <div class="col-md-4 col-12 ">
                                <table>
                                  <tr>
                                    <td class="font-weight-bold product-detail-box">
                                      User Name
                                    </td>
                                    <td>
                                      {review.userId
                                        ? review.userId.name
                                        : null}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td class="font-weight-bold product-detail-box">
                                      Rating
                                    </td>
                                    <td>{review.rating}</td>
                                  </tr>
                                  <tr>
                                    <td class="font-weight-bold product-detail-box">
                                      Fit as expect
                                    </td>
                                    <td>{review.size}</td>
                                  </tr>
                                  <tr>
                                    <td class="font-weight-bold product-detail-box">
                                      Value for money
                                    </td>
                                    <td>{review.value}</td>
                                  </tr>
                                </table>
                              </div>
                              <div class="col-md-4 col-12 ">
                                <table>
                                  <tr>
                                    <td class="font-weight-bold product-detail-box">
                                      Review Headline
                                    </td>
                                    <td>{review.review_headline}</td>
                                  </tr>
                                  <tr>
                                    <td class="font-weight-bold product-detail-box">
                                      Easy to use
                                    </td>
                                    <td>{review.use}</td>
                                  </tr>
                                  <tr>
                                    <td class="font-weight-bold product-detail-box">
                                      Description
                                    </td>
                                    <td>{review.review_description}</td>
                                  </tr>
                                </table>
                              </div>
                            </div>
                          ))
                        : null}
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div> */}
        <div class="sidenav-overlay"></div>
        <div class="drag-target"></div>
        <Footer />
      </body>
    );
  }
}
export default ViewProduct;
