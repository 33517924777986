import React from 'react';
import axios from 'axios';
import 'react-image-lightbox/style.css';
import { saveAs } from "file-saver";
import { Link } from 'react-router-dom';

import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
  Button,
} from 'reactstrap';
import Footer from '../../Component/Footer/Footer';
import moment from 'moment';
import global from '../../global';
import { Spinner } from "reactstrap"
import './Product.css';
import ReactToPrint from 'react-to-print';
//var images = [];
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
var order = [];
var orderDetail = '';
var summarydata = [];
let sellerid=""
const CustomToast = () => {
  return (
    <div>
      <i class="fa fa-check success-toast"></i>
      Successfully Cancel Item
    </div>
  );
};

class ViewProduct extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    //   id: this.props.match.params.id,
      url:''
    };
  }
  componentDidMount() {

     this.setState({url:localStorage.getItem('label')})
    }
 


 

  render() {
    return (
      <body
        class="vertical-layout vertical-menu-modern semi-dark-layout 2-columns  navbar-floating footer-static  "
        data-open="click"
        data-menu="vertical-menu-modern"
        data-col="2-columns"
        data-layout="semi-dark-layout"
      >
        {/* <Header />
        <Sidebar /> */}

        <div class="app-content content">
          <div class="content-overlay"></div>
          <div class="header-navbar-shadow"></div>
          <div class="content-wrapper">
            <div class="content-header row">
              <div class="content-header-left col-md-12 col-12 mb-2">
                <div class="row breadcrumbs-top">
                  <div class="col-12">
                    <div class="row">
                      <div class="col-9">
                      <Link
                          class="content-header-title float-left mb-0"
                          to="/orderlist"
                          style={{ display: 'block', fontSize: '1.74rem' }}
                        >
                          <i class="fas fa-long-arrow-alt-left"></i> View Order
                        </Link>
                        <div class="breadcrumb-wrapper">
                          <ol class="breadcrumb">
                            <li class="breadcrumb-item">
                              <Link to="/orderlist">Orders</Link>
                            </li>
                            <li
                              class="breadcrumb-item 
                            active"
                            >
                              Order Detail
                            </li>
                          </ol>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <section class="users-edit">
                <img src={this.state.url} height="100%" width="100%"></img>
              </section>
            </div>
          </div>
        </div>
        <div class="sidenav-overlay"></div>
        <div class="drag-target"></div>
        <Footer />
      </body>
    );
  }
}
export default ViewProduct;
