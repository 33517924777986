import React from "react";
import axios from "axios";
import { Link } from "react-router-dom";
// import {
//   Modal,
//   ModalHeader,
//   ModalBody,
//   ModalFooter,
//   Table,
//   Button,
// } from 'reactstrap';
import Footer from "../../../Component/Footer/Footer";
import global from "../../../global";
import { toast } from "react-toastify";
// import Paypal from './PaypalBtn';
// import StripeCheckout from 'react-stripe-checkout';
// import ReactPaginate from 'react-paginate';
// import ReactToPrint from 'react-to-print';
// import ReactToPdf from 'react-to-pdf';
// import * as FileSaver from 'file-saver';
// import * as XLSX from 'xlsx';
import "./ProductFreelisting.css";
import "react-toastify/dist/ReactToastify.css";
// import google from 'google';
import StarRatings from "react-star-ratings";
import Autocomplete from "react-google-autocomplete";

const targetRef = React.createRef();
const CURRENCY = "USD";
const fromDollarToCent = (amount) => parseInt(amount * 100);
const $ = require("jquery");
var autocomplete = "";
var googleAutocomplete = "";
$.DataTable = require("datatables.net");

var seller = {};
var charge = {};
var modal = false;
var modal2 = false;
var isActive = false;
var allProductCheck = false;
let mainProduct = [];
toast.configure();
const fileType =
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
const fileExtension = ".xlsx";

// class ComponentToPrint extends React.Component {
//     render() {
//         return (
//             <Table
//                 responsive
//                 hover
//                 className="display border-product-list-table"
//             >
//                 <thead>
//                     <tr>
//                         <th scope="col">Product Image</th>
//                         <th scope="col">Product Name</th>
//                         <th scope="col">SKU</th>
//                         <th scope="col">Stock</th>
//                         <th scope="col">Price</th>
//                     </tr>
//                 </thead>
//                 <tbody>
//                     {mainProduct.map((product, index) => (
//                         <tr key={product.id.toString()}>
//                             <td>
//                                 <img
//                                     src={product.image[0]}
//                                     alt=".."
//                                     className="product-first-image"
//                                 ></img>
//                             </td>
//                             <td>{product.product_name}</td>
//                             <td>{product.sku}</td>
//                             <td>{product.quantity}</td>
//                             <td>$ {product.price}</td>
//                         </tr>
//                     ))}
//                 </tbody>
//             </Table>);
//     }
// }
const CustomToast = () => {
  return (
    <div>
      <i class="fa fa-check success-toast"></i>
      you need to create sellerAccount.
    </div>
  );
};
// const WarnToast = () => {
//     return (
//         <div style={{ display: "flex" }}>
//             <i class="fa fa-info info-toast"></i>
//       You have insufficient amount in wallet.
//         </div>
//     );
// };
// const ProductWarnToast = () => {
//     return (
//         <div>
//             <i class="fa fa-info info-toast"></i>
//       Please select product.
//         </div>
//     );
// };
// const ActionWarnToast = () => {
//     return (
//         <div>
//             <i class="fa fa-info info-toast"></i>
//       Please select Action.
//         </div>
//     );
// };
// const AddProductWarnToast = () => {
//     return (
//         <div style={{ display: "flex" }}>
//             <i class="fa fa-info info-toast"></i>
//       You have exceed the limit to add please switch to retailer.
//         </div>
//     );
// };
// var locationInfo = {
//     geo: null,
//     country: null,
//     state: null,
//     city: null,
//     postalCode: null,
//     street: null,
//     streetNumber: null,
//     reset: function () {
//         this.geo = null;
//         this.country = null;
//         this.state = null;
//         this.city = null;
//         this.postalCode = null;
//         this.street = null;
//         this.streetNumber = null;
//     }
// };

//var Loader = require("react-loader");
class ProductFreelistingList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      wallerPaymentId: "",
      walletAmount: 0,
      productList: [],
      paypalPaymentId: "",
      transactionId: "",
      paymentType: "",
      loaded: true,
      seller: "",
      products: [],
      datatable: {
        columns: [],
        rows: [],
      },
      actionTask: "",
      gridData: [],
      listView: "1",
      productInfo: "",
      productCharge: "",
      imageCharge: "",
      productId: "",
      productImageCharge: 0,
      finalTotal: 0,
      offset: 0,
      data: [],
      perPage: 10,
      currentPage: 1,
      totalCount: 0,
      searchProduct: "",
      allProductCheck: false,
      allProduct: [],
      freeListingCount: 0,
      location: "",
    };
    seller = {};
    isActive = false;
    modal = false;
    modal2 = false;
    allProductCheck = false;
  }
  //#region methods
  componentWillMount() {
    console.log("freelisting product list");
    if (localStorage.getItem("sellerdata")) {
      this.setState({ seller: JSON.parse(localStorage.getItem("sellerdata")) });
      seller = JSON.parse(localStorage.getItem("sellerdata"));
    }

    let urlencoded = new URLSearchParams();
    urlencoded.append("sellerId", seller.id);
    axios
      .post(global.baseUrl + "SellerAllFreelistingProductList", urlencoded, {
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
      })
      .then((result) => {
        console.log(result.data, "freelisting");
        if (result.data) {
          this.setState({
            allProduct: result.data.data,
          });
        }
      });

    // this.productList()
  }

  // productList(){
  //     console.log("freelisting product list")
  //     if (localStorage.getItem("sellerdata")) {
  //         this.setState({ seller: JSON.parse(localStorage.getItem("sellerdata")) });
  //         seller = JSON.parse(localStorage.getItem("sellerdata"));

  //     }
  //     let urlencoded = new URLSearchParams();
  //     urlencoded.append("sellerId", seller.id);
  //     axios
  //         .post("" + "SellerAllFreelistingProductList", urlencoded, {
  //             headers: { "Content-Type": "application/x-www-form-urlencoded" }
  //         })
  //         .then(result => {
  //             console.log(result.data, "freelisting")
  //             if (result.data) {
  //                 this.setState({
  //                     allProduct: result.data.data
  //                 })
  //             }
  //         })
  // }

  listAPI() {
    mainProduct = [];
    console.log(this.state.allProduct.length, "Aaa");

    var urlencoded = new URLSearchParams();
    urlencoded.append("userId", seller.id);
    urlencoded.append("sortType", this.state.sortType);

    axios
      .post(global.baseUrl + "SellerProductFreelistingUserList", urlencoded, {
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
      })
      .then((result) => {
        console.log("Working", result.data);

        if (result.data.data) {
          this.setState({
            allProduct: result.data.data,
            postData: [],
            gridData: [],
          });

          const data = result.data.data;
          const postData = data.map((product, index) => (
            <tr key={product.id.toString()}>
              <td>
                {" "}
                <label for="checkbox1" class="checkbox">
                  <input
                    type="checkbox"
                    checked={product.checked}
                    onChange={() => this.productCheck(index)}
                  />
                </label>
                {product.checked + "check"}
              </td>
              <td>
                <img
                  src={product.image}
                  alt=".."
                  className="product-first-image"
                ></img>
              </td>
              <td>{product.product_name}</td>
            </tr>
          ));
          this.setState({
            totalCount: result.data.totalCount,
            pageCount: Math.ceil(result.data.totalCount / this.state.perPage),
            postData,
            // gridData
          });

          console.log(this.state.allProduct, "CopyItem");
        }
      });
  }

  LocationAPICall = (Place) => {
    console.log("location ");
    var urlencoded = new URLSearchParams();

    urlencoded.append("searchProduct", Place);

    axios
      .post(global.baseUrl + "productFreelistingLocation", urlencoded, {
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
      })
      .then((result) => {
        console.log("Working", result.data);

        if (result.data) {
          this.setState({
            allProduct: result.data.data,
          });
        }
      });
  };

  actionChange = (e) => {
    console.log(e);
    this.setState({ actionTask: e.target.value });
  };

  sortHandleChange = (e) => {
    this.setState({ sortType: e.target.value }, () => {
      this.listAPI();
    });
  };
  removefreeproduct = (item) => {
    var urlencoded = new URLSearchParams();

    urlencoded.append("productId", item);
    urlencoded.append("sellerId", seller.id);

    axios
      .post(global.baseUrl + "productFreelistingDelete", urlencoded, {
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
      })
      .then((result) => {
        console.log("Working", result.data);
        window.location.reload();
        // this.forceUpdate()
        // if (result.data) {
        //     // this.setState({
        //     //     allProduct: result.data.data
        //     // })
        //     this.forceUpdate()
        // }
      });
  };
  // removestorage=()=>{
  //     localStorage.removeItem("productId");
  // }

  copyproduct = (item, count) => {
    console.log(this.state.allProduct.length, "CopyItem");
    console.log(item, "CopyItem");
    // console.log(item , "CopyItem")
    if (this.state.allProduct.length > 19) {
      toast.error("You can't copy it");
    } else {
      var urlencoded = new URLSearchParams();

      // newProductImage.push(element);
      // var urlencoded = new URLSearchParams();
      urlencoded.append("sellerId", item.sellerId.id);
      urlencoded.append("product_name", `${item.product_name} (copy)`);
      urlencoded.append("super_categoryId", item.super_categoryId);
      urlencoded.append("categoryId", item.categoryId);
      urlencoded.append("sub_categoryId", item.sub_categoryId);
      urlencoded.append("quantity", item.quantity);
      urlencoded.append("condition", item.condition);
      urlencoded.append("size", item.size);
      urlencoded.append("color", item.color);
      urlencoded.append("city", item.city);
      urlencoded.append("zipCode", item.zipCode);
      urlencoded.append("price", item.price);
      urlencoded.append("available_for_shipping", item.available_for_shipping);
      urlencoded.append("pick_up_only", item.pick_up_only);
      urlencoded.append("image", JSON.stringify(item.image));

      // for (let i = 0; i < this.state.Descriptions.length; i++) {
      //     if (this.state.Descriptions[i] !== "") {
      urlencoded.append("product_description", item.product_description);
      //     }
      // }
      // if (this.state.productImage.length > 0) {
      //     for (let i = 0; i < this.state.productImage.length; i++) {
      //         if (this.state.productImage[i] !== "") {
      //             urlencoded.append("image", this.state.productImage);
      //         }
      //     }
      // } else {
      //     urlencoded.append("image", []);

      // }

      axios
        .post(global.baseUrl + "sellerAddNewProductFreelisting", urlencoded, {
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
        })
        .then((result) => {
          console.log(result.data);
          if (result.data) {
            // window.location.reload();
          }
        });
    }
  };

  openediteproduct = (item) => {
    // localStorage.setItem("productId",item);
    this.props.history.push("/addProductFreelisting", {
      state: { productId: item },
    });
    // var urlencoded = new URLSearchParams();
    // urlencoded.append("id", item);
    // // urlencoded.append("sortType", this.state.sortType);

    // axios
    //     .post(global.baseUrl + "sellerproductIdlist", urlencoded, {
    //         headers: { "Content-Type": "application/x-www-form-urlencoded" }
    //     })
    //     .then(result => {
    //         console.log("Working", result.data)

    //         if (result.data.data) {
    //             this.setState({
    //                 allProduct: result.data.data,
    //                 postData: [],
    //                 gridData: []
    //             })
    //         }
    //         })
  };

  //#endregion
  render() {
    return (
      <body
        class="vertical-layout vertical-menu-modern semi-dark-layout 2-columns  navbar-floating footer-static  "
        data-open="click"
        data-menu="vertical-menu-modern"
        data-col="2-columns"
        data-layout="semi-dark-layout"
      >
        {/* <Header />
        <Sidebar />  */}
        <div class="app-content content">
          <div class="content-overlay"></div>
          <div class="header-navbar-shadow"></div>
          <div class="content-wrapper">
            <div class="content-header row">
              <div class="content-header-left col-md-9 col-12 mb-2">
                <div class="row breadcrumbs-top">
                  <div class="col-12">
                    <h2 class="content-header-title float-left mb-0">
                      Products
                    </h2>
                    <div class="breadcrumb-wrapper col-12">
                      <ol class="breadcrumb">
                        <li class="breadcrumb-item">
                          <Link to="/dashboard">Home</Link>
                        </li>
                        <li class="breadcrumb-item active">Products</li>
                      </ol>
                    </div>
                  </div>
                </div>
              </div>
              <div class="content-header-right text-md-right col-md-3 col-12 d-md-block text-right">
                <div class="form-group breadcrum-right">
                  {console.log(this.state.allProduct.length, "dadadadad")}
                  {console.log(seller.selerpaymentstatus, "dadadadad")}
                  <Link
                    to={
                      this.state.allProduct.length >= 20
                        ? (seller.selerpaymentstatus !== "0"
                        ? `/information`
                        : `/addProductFreelisting`)
                        : (seller.selerpaymentstatus === "0"
                        ? `/information`
                        : `/addProductFreelisting`)
                    }
                    id="addRow"
                    class="btn btn-primary mb-2 add-product-link"
                    onClick={() =>
                      this.state.allProduct.length >= 20
                        ? toast.error("Product Is More than 20", {
                            position: toast.POSITION.TOP_RIGHT,
                          })
                        : seller.selerpaymentstatus === "0" &&
                          toast.success(<CustomToast />, {
                            position: toast.POSITION.TOP_RIGHT,
                          })
                    }
                  >
                    <i class="feather icon-plus"></i>Add Product
                  </Link>
                </div>
              </div>

              <div className="card w-100">
                <div class="card-content">
                  <div class="card-body">
                    <div class="row">
                      <div
                        class="col-md-12 sa-text-first sa-lists mb-2"
                        style={{ alignItems: "center" }}
                      >
                        <div
                          className="location-textbox-search"
                          style={{ width: "100%", maxWidth: "300px" }}
                        >
                          <Autocomplete
                            apiKey="AIzaSyATbKPsEPYxleEvoihnLzJ9kvL-ST2vaTE"
                            style={{ width: "90%" }}
                            onPlaceSelected={(place) => {
                              console.log(
                                place.address_components[0].long_name,
                                "place"
                              );
                              this.LocationAPICall(
                                place.address_components[0].long_name
                              );
                            }}
                            // types={['(regions)']}
                            // componentRestrictions={{ country: "In" }}
                          />
                        </div>
                        {/* <span className="ml-2 mr-2">|</span> */}
                        <Link to="" class="sa-links1 d-flex">
                          Filter
                        </Link>
                        {/* <span className="ml-2 mr-2">|</span> */}
                        <div class="col-lg-3 col-md-4 col-6">
                          <select
                            class="controls"
                            aria-label="Default select example"
                            style={{
                              borderColor: "#ccc",
                              padding: "5px",
                              borderRadius: "4px",
                            }}
                            value={this.state.sortTask}
                            onChange={(e) => this.sortHandleChange(e)}
                          >
                            <option selected>Select to sort</option>
                            <option value="sortNewToOld">
                              Sort by Newest to Oldest
                            </option>
                            <option value="sortOldToNew">
                              Sort by Oldest to Newest
                            </option>
                            <option value="PriceHighToLow">
                              Price High to Low
                            </option>
                            <option value="PriceLowToHigh">
                              Price Low to High
                            </option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div class="row sa-size-row">
                      <div class="col-md-12 col-12">
                        <div class="row row-cols-1 row-cols-md-3 g-4 ">
                          {console.log(this.state.allProduct, "all product")}

                          {this.state.allProduct &&
                          this.state.allProduct.length > 0 ? (
                            <>
                              {this.state.allProduct.map((item, index) => {
                                {
                                  /* console.log(index, "index") */
                                }
                                return (
                                  <div
                                    class="col col-6 col-lg-3 col-md-4 sa-col-right"
                                    key={index}
                                  >
                                    <div class="card sa-shop-card">
                                      <i
                                        class="fa fa-trash gl-new-icon dlt"
                                        onClick={() => {
                                          this.removefreeproduct(item.id);
                                        }}
                                      ></i>
                                      <i
                                        class="fa fa-solid fa-copy gl-new-icon cpy"
                                        onClick={() => {
                                          this.copyproduct(item, index);
                                        }}
                                      ></i>
                                      <Link
                                        to={`/editProductFreelisting/${item.id}`}
                                        style={{
                                          textAlign: "right",
                                          marginRight: "48px",
                                          marginTop: "6px",
                                          cursor: "pointer",
                                          fontSize: "16px",
                                        }}
                                      >
                                        <i
                                          class="fa fa-pencil gl-new-icon edit"
                                          onClick={() => {
                                            this.openediteproduct(item.id);
                                          }}
                                        ></i>
                                      </Link>

                                      <img
                                        src={item ? item.image[0] : null}
                                        class="card-img-top"
                                        alt="..."
                                      />
                                      <div class="card-body sa-cards-body">
                                        <h5
                                          class="card-title sa-title-text"
                                          style={{ fontSize: "20px" }}
                                        >
                                          {item ? item.product_name : null}
                                        </h5>
                                        <p class="card-text sa-text3">
                                          ${item.price}
                                        </p>
                                        {/* <p class="card-text2">Pomona, CA</p> */}
                                      </div>
                                      <div
                                        style={{
                                          marginTop: " -19px",
                                          marginLeft: "20px",
                                          marginBottom: "19px",
                                        }}
                                      >
                                        <div className="star-flexs">
                                          <StarRatings
                                            rating={parseFloat(
                                              item.tempcommunicationToSeller
                                                ? item.tempcommunicationToSeller
                                                : 0 +
                                                  item.tempproductAsDescribed
                                                ? item.tempproductAsDescribed
                                                : 0 + item.tempshippingCharges
                                                ? item.tempshippingCharges
                                                : 0 + item.tempshippingDelivery
                                                ? item.tempshippingDelivery
                                                : 0
                                            )}
                                            starDimension="15px"
                                            starSpacing="3px"
                                          />
                                          <span className="r-box">
                                            (
                                            {parseFloat(
                                              item.tempcommunicationToSeller
                                                ? item.tempcommunicationToSeller
                                                : 0 +
                                                  item.tempproductAsDescribed
                                                ? item.tempproductAsDescribed
                                                : 0 + item.tempshippingCharges
                                                ? item.tempshippingCharges
                                                : 0 + item.tempshippingDelivery
                                                ? item.tempshippingDelivery
                                                : 0
                                            ) * 5}
                                            )
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                );
                              })}
                            </>
                          ) : (
                            <div class="ddd" style={{ textAlign: "center" }}>
                              <div colspan="12">No Product Available</div>
                            </div>
                            // <p style={{ textAlign: 'center' }}>No Product</p>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div></div>
          </div>
        </div>
        <div class="sidenav-overlay"></div>
        <div class="drag-target"></div>

        <Footer />
      </body>
    );
  }
}

export default ProductFreelistingList;
