import React from "react";
import axios from "axios";
import { Link } from 'react-router-dom'
import "react-image-lightbox/style.css";
import Footer from "../../Component/Footer/Footer";
import global from "../../global";

class Term extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            termAndCondition: "No Detail Available"
        };
    }
    componentDidMount() {
        let url = global.baseUrl + 'SellerSettingDetail'
        axios
            .get(url).then(result => {
                if (result.data.status) {
                    this.setState({ termAndCondition: result.data.data.terms_and_condition });
                }
            });
    }

    render() {
        return (
            <body
                class="vertical-layout vertical-menu-modern semi-dark-layout 2-columns  navbar-floating footer-static  "
                data-open="click"
                data-menu="vertical-menu-modern"
                data-col="2-columns"
                data-layout="semi-dark-layout"
            >

                <div class="app-content content" style={{ minHeight: "91vh" }}>
                    <div class="content-overlay"></div>
                    <div class="header-navbar-shadow"></div>
                    <div class="content-wrapper">
                        <div class="content-header row">
                            <div class="content-header-left col-md-9 col-12 mb-2">
                                <div class="row breadcrumbs-top">
                                    <div class="col-12">
                                        <h2 class="content-header-title float-left mb-0">
                                            Terms & Condition
                                        </h2>
                                        <div class="breadcrumb-wrapper col-12">
                                            <ol class="breadcrumb">
                                                <li class="breadcrumb-item">
                                                    <Link to="/dashboard">Dashboard</Link>
                                                </li>
                                                <li class="breadcrumb-item active">
                                                    Terms & Condition
                                                </li>
                                            </ol>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="content-body">
                            <section class="users-edit">
                            <div className="card">
                                    <div className="card-body">

                                <p
                                    dangerouslySetInnerHTML={{
                                        __html: this.state.termAndCondition
                                    }}
                                />
                                </div>
                                </div>
                            </section>
                        </div>
                    </div>
                </div>

                <div class="sidenav-overlay"></div>
                <div class="drag-target"></div>
                <Footer />
            </body>
        );
    }
}
export default Term;
