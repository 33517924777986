import React from "react";
import { Route, Switch } from "react-router-dom";
import Login from "./Seller/Container/Login/Login";
import ForgotPassword from "./Seller/Container/ForgotPassword/ForgotPassword";
import ResetPassword from "./Seller/Container/ResetPassword/ResetPassword";
import Register from "./Seller/Container/Register/Register";
import SetOTP from "./Seller/Container/SetOTP/SetOTP";
import NotFound404 from "./Seller/404/404";
import LoginRoute from "./LoginRoute";
import PaymentDone from "./Seller/Container/AddBanner/Done"
import ProductPaymentDone from "./Seller/Container/Product/Done"
// import Address from "./Seller/Container/sellerPaymentInfo/Information"
import BusinessInformation from "./Seller/Container/sellerPaymentInfo/Information";
import Address from "./Seller/Container/sellerPaymentInfo/Address";
import PaymentMethod from "./Seller/Container/sellerPaymentInfo/PaymentMethod";
import TaxForm from "./Seller/Container/sellerPaymentInfo/TaxForm";
//import DemoList from "./Seller/Container/ProductList/ProductList"
import AfterLogin from './AfterLoginWrapper'
import Term from "./Term"

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      seller: ""
    };
    if (localStorage.getItem("sellerdata")) {
      this.state = {
        seller: JSON.parse(localStorage.getItem("sellerdata"))
      };
    }
  }
  componentWillMount() {
    if (localStorage.getItem("sellerdata")) {
      this.setState({ seller: JSON.parse(localStorage.getItem("sellerdata")) });
    }
  }
  render() {
    return (
      <LoginRoute>
        <Switch>
  
          <Route path="/login" component={Login} exact={true} />
          <Route
            path="/forgotpassword"
            component={ForgotPassword}
            exact={true}
          />
          <Route
            path="/resetpassword/:id"
            component={ResetPassword}
            exact={true}
          />
          <Route path="/register" component={Register} exact={true} />
          <Route path="/sellarinformation" component={BusinessInformation} exact={true} />
          <Route path="/sellaraddress" component={Address} exact={true} />
          <Route path="/sellarpaymentmethod" component={PaymentMethod} exact={true} />
          <Route path="/sellartaxform" component={TaxForm} exact={true} />
          <Route path="/setotp/:id" component={SetOTP} exact={true} />
          <Route path="/404" component={NotFound404} exact={true} />
          <Route path="/paymentdone" component={PaymentDone} exact={true} />
          <Route path="/productpaymentdone" component={ProductPaymentDone} exact={true} />
          <Route path="/garageterm" component={Term} exact={true} />
          <Route path="/" name="Home" render={props => <AfterLogin />} />
        </Switch>
      </LoginRoute>
    );
  }
}
export default App;
