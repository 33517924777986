import React from "react";
import axios from "axios";
import Select from "react-select";
import {Link} from 'react-router-dom'
// import Sidebar from "../../Component/Sidebar/Sidebar";
// import Header from "../../Component/Header/Header";
import Footer from "../../Component/Footer/Footer";
import global from "../../global";
//import Avtar from "./avtar.jpg";
import {toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import "./EditProfile.css";
var seller = {};
var perError = "";
var startDateError = "";
var endDateError = "";
var minQtyError = "";
var maxQtyError = "";
var offerTypeError = "";
var typeError = "";

var submitDisable = false;
toast.configure()
const CustomToast=()=>{
  return(
  <div>
    <i class="fa fa-check success-toast"></i>
    Offer Updated Successfully
  </div>
  )
}
class EditOffer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      percentage: "",
      startDate: "",
      endDate: "",
      minQty: "",
      maxQty: "",
      offerType: "",
      productId: "",
      categoryId: "",
      brandId: "",
      products: [],
      categories: [],
      brands: [],
      
      id: this.props.match.params.id,
    };
    perError = "";
    startDateError = "";
    endDateError = "";
    minQtyError = "";
    maxQtyError = "";
    submitDisable = false;
  }
  componentWillMount() {
    if (localStorage.getItem("sellerdata")) {
      seller = JSON.parse(localStorage.getItem("sellerdata"));
      this.setState({ seller: JSON.parse(localStorage.getItem("sellerdata")) });
      //this.dashboardAPI(seller.id);
    }
  }
  componentDidMount() {
    this.getOfferAPI();
  }
  getOfferAPI() {
    var urlencoded = new URLSearchParams();
    urlencoded.append("id", this.state.id);
    axios
      .post(global.baseUrl + "sellerofferIdlist", urlencoded, {
        headers: { "Content-Type": "application/x-www-form-urlencoded" }
      })
      .then(result => {
        console.log(result.data);
        if (result.data.status) {
          this.setState({
            percentage: result.data.data.percentage,
            startDate: result.data.data.start_date,
            endDate: result.data.data.end_date,
            minQty: result.data.data.minimum_quantity,
            maxQty: result.data.data.maximum_quantity,
            offerType: result.data.data.offer_type,
          });


          if (result.data.data.offer_type === "product") {
            this.setState({
              productId:result.data.data.productId
            });
            var urlencoded = new URLSearchParams();
            urlencoded.append("userId", this.state.seller.id);
      
            axios
              .post(global.baseUrl + "sellerproductuserlist", urlencoded, {
                headers: { "Content-Type": "application/x-www-form-urlencoded" }
              })
              .then(result => {
                if (result.data.status) {
                  var option = [];
                  option.push({
                    label: "Select",
                    value: ""
                  });
                  for (let i = 0; i < result.data.data.length; i++) {
                    option.push({
                      label: result.data.data[i].product_name,
                      value: result.data.data[i].id
                    });
                  }
                  this.setState({
                    products: option
                  });
                }
              });
          } else if (result.data.data.offer_type === "category") {
            this.setState({
              categoryId:result.data.data.categoryId
            });
            let urlencoded = new URLSearchParams();
      
            axios
              .post(global.baseUrl + "sellercategorylist", urlencoded, {
                headers: { "Content-Type": "application/x-www-form-urlencoded" }
              })
              .then(result => {
                console.log(result.data);
                if (result.data.status) {
                  var option = [];
                  option.push({
                    label: "Select",
                    value: ""
                  });
                  for (let i = 0; i < result.data.data.length; i++) {
                    option.push({
                      label: result.data.data[i].name,
                      value: result.data.data[i].id
                    });
                  }
                  this.setState({
                    categories: option
                  });
                }
              });
          } else if (result.data.data.offer_type === "brand") {
            
            this.setState({
              brandId:result.data.data.brandId
            });
            let urlencoded1 = new URLSearchParams();
            axios
              .post(global.baseUrl + "sellerbrandlist", urlencoded1, {
                headers: { "Content-Type": "application/x-www-form-urlencoded" }
              })
              .then(result => {
                console.log(result.data);
                if (result.data.status) {
                  var option = [];
                  option.push({
                    label: "Select",
                    value: ""
                  });
                  for (let i = 0; i < result.data.data.length; i++) {
                    option.push({
                      label: result.data.data[i].name,
                      value: result.data.data[i].id
                    });
                  }
                  this.setState({
                    brands: option
                  });
                }
              });
          }

        }  
      });
  }
  handleChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };
  handleOfferChange = event => {
    this.setState({ [event.target.name]: event.target.value });
    if (event.target.value === "product") {
      var urlencoded = new URLSearchParams();
      urlencoded.append("userId", this.state.seller.id);

      axios
        .post(global.baseUrl + "sellerproductuserlist", urlencoded, {
          headers: { "Content-Type": "application/x-www-form-urlencoded" }
        })
        .then(result => {
          if (result.data.status) {
            var option = [];
            option.push({
              label: "Select",
              value: ""
            });
            for (let i = 0; i < result.data.data.length; i++) {
              option.push({
                label: result.data.data[i].name,
                value: result.data.data[i].id
              });
            }
            this.setState({
              products: option
            });
          }
        });
    } else if (event.target.value === "category") {
      let urlencoded = new URLSearchParams();

      axios
        .post(global.baseUrl + "sellercategorylist", urlencoded, {
          headers: { "Content-Type": "application/x-www-form-urlencoded" }
        })
        .then(result => {
          console.log(result.data);
          if (result.data.status) {
            var option = [];
            option.push({
              label: "Select",
              value: ""
            });
            for (let i = 0; i < result.data.data.length; i++) {
              option.push({
                label: result.data.data[i].name,
                value: result.data.data[i].id
              });
            }
            this.setState({
              categories: option
            });
          }
        });
    } else if (event.target.value === "brand") {
      let urlencoded1 = new URLSearchParams();

      axios
        .post(global.baseUrl + "sellerbrandlist", urlencoded1, {
          headers: { "Content-Type": "application/x-www-form-urlencoded" }
        })
        .then(result => {
          console.log(result.data);
          if (result.data.status) {
            var option = [];
            option.push({
              label: "Select",
              value: ""
            });
            for (let i = 0; i < result.data.data.length; i++) {
              option.push({
                label: result.data.data[i].name,
                value: result.data.data[i].id
              });
            }
            this.setState({
              brands: option
            });
          }
        });
    }
  };

  dropCategoryChangeHandler = event => {
    this.setState({
      categoryId: event.value,
      brandId: "",
      productId: ""
    });
  };
  dropBrandChangeHandler = event => {
    this.setState({
      brandId: event.value,
      productId: "",
      categoryId: ""
    });
  };
  dropProductChangeHandler = event => {
    this.setState({
      productId: event.value,
      brandId: "",
      categoryId: ""
    });
  };
  APICall = () => {
    var urlencoded = new URLSearchParams();
    urlencoded.append("id", this.state.id);
    urlencoded.append("percentage", this.state.percentage);
    urlencoded.append("start_date", this.state.startDate);
    urlencoded.append("end_date", this.state.endDate);
    urlencoded.append("offer_type", this.state.offerType);
    urlencoded.append("minimum_quantity", this.state.minQty);
    urlencoded.append("maximum_quantity", this.state.maxQty);
    urlencoded.append("brandId", this.state.brandId);
    urlencoded.append("categoryId", this.state.categoryId);
    urlencoded.append("productId", this.state.productId);

    axios
      .post(global.baseUrl + "sellerofferupdate", urlencoded, {
        headers: { "Content-Type": "application/x-www-form-urlencoded" }
      })
      .then(result => {
        console.log(result.data);
        if (result.data.status) {
          //localStorage.setItem('userdata', JSON.stringify(result.data))
          toast.success(<CustomToast/>,{position:toast.POSITION.TOP_RIGHT})
         
          this.props.history.push("/offerlist");
        }
      });
  };

  handleSubmit = e => {
    let numberCheck=/^[0-9]+$/;

    if (this.state.percentage === "") {
      this.setState({ perError: "Please enter percentage" });
      perError = "Please enter percentage";
    } else if (this.state.percentage < 0 || this.state.percentage > 100  || !numberCheck.test(this.state.percentage)) {
      this.setState({ perError: "Please enter valid percentage" });
      perError = "Please enter valid percentage";
    } else {
      this.setState({ perError: "" });
      perError = "";
    }
    if (this.state.startDate === "") {
      this.setState({ startDateError: "Please select start date" });
      startDateError = "Please select start date";
    } else {
      this.setState({ startDateError: "" });
      startDateError = "";
    }
    if (this.state.endDate === "") {
      this.setState({ endDateError: "Please select end date" });
      endDateError = "Please select end date";
    } else {
      if(Date.parse(this.state.endDate)<Date.parse(this.state.startDate)){
        this.setState({ endDateError: "End date less then start date" });
        endDateError = "End date less then start date";
      }
      else{
      this.setState({ endDateError: "" });
      endDateError = "";
      }
    }
    if (this.state.minQty === "") {
      this.setState({ minQtyError: "Please enter minimum quantity" });
      minQtyError = "Please enter minimum quantity";
    }
    else if (!numberCheck.test(this.state.minQty)) {
      this.setState({ minQtyError: "Please enter valid  quantity" });
      minQtyError = "Please enter valid quantity";
    }
    else {
      this.setState({ minQtyError: "" });
      minQtyError = "";
    }
    if (this.state.maxQty === "") {
      this.setState({ maxQtyError: "Please enter maximum quantity" });
      maxQtyError = "Please enter maximum quantity";
    }
    else if (!numberCheck.test(this.state.maxQty)) {
      this.setState({ maxQtyError: "Please enter valid quantity" });
      maxQtyError = "Please enter valid quantity";
    }
    else {
      if (parseInt(this.state.maxQty) < parseInt(this.state.minQty)) {
        this.setState({ maxQtyError: "Please enter valid maximum quantity" });
        maxQtyError = "Please enter valid maximum quantity";
      } else {
        this.setState({ maxQtyError: "" });
        maxQtyError = "";
      }
    }
    if (this.state.offerType==="product" && this.state.productId === "") {
      this.setState({ offerTypeError: "Please select product" });
      offerTypeError = "Please select product";
    }
    else if (this.state.offerType==="brand" && this.state.brandId === "") {
      this.setState({ offerTypeError: "Please select brand" });
      offerTypeError = "Please select brand";
    }
    else if (this.state.offerType==="category" && this.state.categoryId === "") {
      this.setState({ offerTypeError: "Please select category" });
      offerTypeError = "Please select category";
    }
    // if (this.state.productId === "" && this.state.categoryId==="" && this.state.brandId==="") {
    //   this.setState({ offerTypeError: "Please select type" });
    //   offerTypeError = "Please select type";
    // }
     else {
      this.setState({ offerTypeError: "" });
      offerTypeError = "";
    }
    
    if (
      this.state.productId === "" &&
      this.state.categoryId === "" &&
      this.state.brandId === ""
    ) {
      this.setState({ offerTypeError: "Please select type" });
      offerTypeError = "Please select type";
    } else {
      this.setState({ offerTypeError: "" });
      offerTypeError = "";
    }

    if (
      this.state.percentage &&
      this.state.startDate &&
      this.state.endDate &&
      this.state.minQty &&
      this.state.maxQty &&
      typeError === "" &&
      offerTypeError === "" &&
      perError === "" &&
      startDateError === "" &&
      endDateError === "" &&
      minQtyError === "" &&
      maxQtyError === ""
    ) {
      submitDisable = true;
      this.APICall();
    }
  };
  resetHandler = () => {
    this.setState({
      percentage: "",
      startDate: "",
      endDate: "",
      minQty: "",
      maxQty: "",
      offerType: "",
      productId: "",
      categoryId: "",
      brandId: "",
      products: [],
      categories: [],
      brands: []
    });
    perError = "";
    startDateError = "";
    endDateError = "";
    minQtyError = "";
    maxQtyError = "";
    typeError = "";
    offerTypeError = "";
    submitDisable = false;
  };
  render() {
    return (
      <body
        class="vertical-layout vertical-menu-modern semi-dark-layout 2-columns  navbar-floating footer-static  "
        data-open="click"
        data-menu="vertical-menu-modern"
        data-col="2-columns"
        data-layout="semi-dark-layout"
      >
        {/* <Header />
        <Sidebar /> */}
        <div class="app-content content">
          <div class="content-overlay"></div>
          <div class="header-navbar-shadow"></div>
          <div class="content-wrapper app-c">
            <div class="content-header row">
              <div class="content-header-left col-md-9 col-12 mb-2">
                <div class="row breadcrumbs-top">
                  <div class="col-12">
                    <h2 class="content-header-title float-left mb-0">
                    <Link to={"/offerlist"} className="back-arrow  mr-2"><i className="feather icon-arrow-left"></i></Link> 
                    

                      Edit Offer
                    </h2>
                    <div class="breadcrumb-wrapper col-12">
                      <ol class="breadcrumb">
                        <li class="breadcrumb-item">
                          <Link to="/offerlist">Offers</Link>
                        </li>
                        
                        <li class="breadcrumb-item active">Edit Offer</li>
                      </ol>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="content-body">
              <section class="users-edit">
                <div class="card">
                  <div class="card-content">
                    <div class="card-body">
                      {/* <ul class="nav nav-tabs mb-3" role="tablist">
                        <li class="nav-item">
                          <a
                            class="nav-link d-flex align-items-center active"
                            id="account-tab"
                            data-toggle="tab"
                            href="#account"
                            aria-controls="account"
                            role="tab"
                            aria-selected="true"
                          >
                            <i class="feather icon-user mr-25"></i>
                            <span class="d-none d-sm-block">Account</span>
                          </a>
                        </li>
                        <li class="nav-item">
                          <a
                            class="nav-link d-flex align-items-center"
                            id="information-tab"
                            data-toggle="tab"
                            href="#information"
                            aria-controls="information"
                            role="tab"
                            aria-selected="false"
                          >
                            <i class="feather icon-info mr-25"></i>
                            <span class="d-none d-sm-block">Information</span>
                          </a>
                        </li>
                        <li class="nav-item">
                          <a
                            class="nav-link d-flex align-items-center"
                            id="social-tab"
                            data-toggle="tab"
                            href="#social"
                            aria-controls="social"
                            role="tab"
                            aria-selected="false"
                          >
                            <i class="feather icon-share-2 mr-25"></i>
                            <span class="d-none d-sm-block">Social</span>
                          </a>
                        </li>
                      </ul> */}
                      {/* <div class="tab-content"> */}
                      <div
                        class="tab-pane active"
                        id="account"
                        aria-labelledby="account-tab"
                        role="tabpanel"
                      >
                        <div class="row">
                          <div class="col-12 col-sm-4">
                            <div class="form-group">
                              <div class="controls">
                                <label>Percentage<span class="required-span">*</span></label>
                                <input
                                  type="text"
                                  class="form-control"
                                  placeholder="Offer In percentage"
                                  value={this.state.percentage}
                                  name="percentage"
                                  onChange={this.handleChange}
                                />
                                <p class="error-msg">{perError}</p>
                              </div>
                            </div>
                          </div>
                          <div class="col-12 col-sm-4">
                            <div class="form-group">
                              <label>Start Date<span class="required-span">*</span></label>
                              <input
                                type="date"
                                class="form-control"
                                placeholder="Start date"
                                name="startDate"
                                value={this.state.startDate}
                                onChange={this.handleChange}
                                maxLength="10"
                              />
                              <p class="error-msg">{startDateError} </p>
                            </div>
                          </div>
                          <div class="col-12 col-sm-4">
                            <div class="form-group">
                              <label>End Date<span class="required-span">*</span></label>
                              <input
                                type="date"
                                class="form-control"
                                placeholder="End date"
                                name="endDate"
                                value={this.state.endDate}
                                onChange={this.handleChange}
                                maxLength="10"
                              />
                              <p class="error-msg">{endDateError} </p>
                            </div>
                          </div>
                          <div class="col-12 col-sm-4">
                            <div class="form-group">
                              <label>Offer On<span class="required-span">*</span></label>
                              <select
                                class="form-control"
                                id="basicSelect"
                                placeholder="Select seller type "
                                name="offerType"
                                value={this.state.offerType}
                                onChange={this.handleOfferChange}
                              >
                                <option value="">Select</option>
                                <option value="product">Product</option>
                                <option value="category">Category</option>
                                <option value="brand">Brand</option>
                              </select>

                              <p class="error-msg">{typeError}</p>
                            </div>
                          </div>
                          <div class="col-12 col-sm-4">
                            <div class="form-group">
                              <label>Minimum Quantity<span class="required-span">*</span></label>
                              <input
                                type="text"
                                class="form-control"
                                placeholder="Minimum Quantity"
                                name="minQty"
                                value={this.state.minQty}
                                onChange={this.handleChange}
                              />
                              <p class="error-msg">{minQtyError}</p>
                            </div>
                          </div>
                          <div class="col-12 col-sm-4">
                            <div class="form-group">
                              <label>Maximum Quantity<span class="required-span">*</span></label>
                              <input
                                type="text"
                                class="form-control"
                                placeholder="Maximum Quantity"
                                name="maxQty"
                                value={this.state.maxQty}
                                onChange={this.handleChange}
                              />
                              <p class="error-msg">{maxQtyError}</p>
                            </div>
                          </div>
                          {this.state.offerType === "product" ? (
                            <div class="col-12 col-sm-4">
                              <div class="form-group">
                                <label>Select Product<span class="required-span">*</span></label>
                                <Select
                                  value={this.state.products.filter(
                                    option =>
                                      option.value === this.state.productId
                                  )}
                                  theme={theme => ({
                                    ...theme,
                                    borderRadius: "5px",
                                    border: "0px solid",
                                    colors: {
                                      ...theme.colors,
                                      text: "orangered",
                                      primary25: "#9cf59c",
                                      primary: "#47A547"
                                    }
                                  })}
                                  options={this.state.products}
                                  name="category"
                                  onChange={val =>
                                    this.dropProductChangeHandler(val)
                                  }
                                />
                                <p class="error-msg">{offerTypeError}</p>
                              </div>
                            </div>
                          ) : this.state.offerType === "category" ? (
                            <div class="col-12 col-sm-4">
                              <div class="form-group">
                                <label>Select category<span class="required-span">*</span></label>
                                <Select
                                  value={this.state.categories.filter(
                                    option =>
                                      option.value === this.state.categoryId
                                  )}
                                  theme={theme => ({
                                    ...theme,
                                    borderRadius: "5px",
                                    border: "0px solid",
                                    colors: {
                                      ...theme.colors,
                                      text: "orangered",
                                      primary25: "#9cf59c",
                                      primary: "#47A547"
                                    }
                                  })}
                                  options={this.state.categories}
                                  name="category"
                                  onChange={val =>
                                    this.dropCategoryChangeHandler(val)
                                  }
                                />
                                <p class="error-msg">{offerTypeError}</p>
                              </div>
                            </div>
                          ) : this.state.offerType === "brand" ? (
                            <div class="col-12 col-sm-4">
                              <div class="form-group">
                                <label>Select Brand<span class="required-span">*</span></label>
                                <Select
                                  value={this.state.brands.filter(
                                    option =>
                                      option.value === this.state.brandId
                                  )}
                                  theme={theme => ({
                                    ...theme,
                                    borderRadius: "5px",
                                    border: "0px solid",
                                    colors: {
                                      ...theme.colors,
                                      text: "orangered",
                                      primary25: "#9cf59c",
                                      primary: "#47A547"
                                    }
                                  })}
                                  options={this.state.brands}
                                  name="brand"
                                  onChange={val =>
                                    this.dropBrandChangeHandler(val)
                                  }
                                />
                                <p class="error-msg">{offerTypeError}</p>
                              </div>
                            </div>
                          ) : null}
                          <div class="col-12 d-flex flex-sm-row flex-column justify-content-end mt-1">
                            <button
                              type="submit"
                              disabled={submitDisable}
                              class="btn btn-primary glow mb-1 mb-sm-0 mr-0 mr-sm-1 login-btn"
                              onClick={this.handleSubmit}
                            >
                              Edit Offer
                            </button>
                            <button
                              type="reset"
                              onClick={this.resetHandler}
                              class="btn btn-outline-primary float-left btn-inline res-btn"
                            >
                              Reset
                            </button>
                          </div>
                        </div>
                      </div>
                      {/* <div
                          class="tab-pane"
                          id="information"
                          aria-labelledby="information-tab"
                          role="tabpanel"
                        >
                          <form novalidate>
                            <div class="row mt-1">
                              <div class="col-12 col-sm-6">
                                <h5 class="mb-1">
                                  <i class="feather icon-user mr-25"></i>
                                  Personal Information
                                </h5>
                                <div class="row">
                                  <div class="col-12">
                                    <div class="form-group">
                                      <div class="controls">
                                        <label>Birth date</label>
                                        <input
                                          type="text"
                                          class="form-control birthdate-picker"
                                          required
                                          placeholder="Birth date"
                                          data-validation-required-message="This birthdate field is required"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div class="form-group">
                                  <div class="controls">
                                    <label>Mobile</label>
                                    <input
                                      type="text"
                                      class="form-control"
                                      value="&#43;6595895857"
                                      placeholder="Mobile number here..."
                                      data-validation-required-message="This mobile number is required"
                                    />
                                  </div>
                                </div>

                                <div class="form-group">
                                  <div class="controls">
                                    <label>Website</label>
                                    <input
                                      type="text"
                                      class="form-control"
                                      required
                                      placeholder="Website here..."
                                      value="https://rowboat.com/insititious/Angelo"
                                      data-validation-required-message="This Website field is required"
                                    />
                                  </div>
                                </div>
                                <div class="form-group">
                                  <label>Languages</label>
                                  <select
                                    class="form-control"
                                    id="users-language-select2"
                                    multiple="multiple"
                                  >
                                    <option value="English" selected>
                                      English
                                    </option>
                                    <option value="Spanish">Spanish</option>
                                    <option value="French">French</option>
                                    <option value="Russian">Russian</option>
                                    <option value="German">German</option>
                                    <option value="Arabic" selected>
                                      Arabic
                                    </option>
                                    <option value="Sanskrit">Sanskrit</option>
                                  </select>
                                </div>
                                <div class="form-group">
                                  <label>Gender</label>
                                  <ul class="list-unstyled mb-0">
                                    <li class="d-inline-block mr-2">
                                      <fieldset>
                                        <div class="vs-radio-con">
                                          <input
                                            type="radio"
                                            name="vueradio"
                                            checked
                                            value="false"
                                          />
                                          <span class="vs-radio">
                                            <span class="vs-radio--border"></span>
                                            <span class="vs-radio--circle"></span>
                                          </span>
                                          Male
                                        </div>
                                      </fieldset>
                                    </li>
                                    <li class="d-inline-block mr-2">
                                      <fieldset>
                                        <div class="vs-radio-con">
                                          <input
                                            type="radio"
                                            name="vueradio"
                                            value="false"
                                          />
                                          <span class="vs-radio">
                                            <span class="vs-radio--border"></span>
                                            <span class="vs-radio--circle"></span>
                                          </span>
                                          Female
                                        </div>
                                      </fieldset>
                                    </li>
                                    <li class="d-inline-block mr-2">
                                      <fieldset>
                                        <div class="vs-radio-con">
                                          <input
                                            type="radio"
                                            name="vueradio"
                                            value="false"
                                          />
                                          <span class="vs-radio">
                                            <span class="vs-radio--border"></span>
                                            <span class="vs-radio--circle"></span>
                                          </span>
                                          Other
                                        </div>
                                      </fieldset>
                                    </li>
                                  </ul>
                                </div>
                                <div class="form-group">
                                  <label>Contact Options</label>
                                  <ul class="list-unstyled mb-0">
                                    <li class="d-inline-block mr-2">
                                      <fieldset>
                                        <div class="custom-control custom-checkbox">
                                          <input
                                            type="checkbox"
                                            class="custom-control-input"
                                            checked
                                            name="customCheck1"
                                            id="customCheck1"
                                          />
                                          <label
                                            class="custom-control-label"
                                            for="customCheck1"
                                          >
                                            Email
                                          </label>
                                        </div>
                                      </fieldset>
                                    </li>
                                    <li class="d-inline-block mr-2">
                                      <fieldset>
                                        <div class="custom-control custom-checkbox">
                                          <input
                                            type="checkbox"
                                            class="custom-control-input"
                                            checked
                                            name="customCheck2"
                                            id="customCheck2"
                                          />
                                          <label
                                            class="custom-control-label"
                                            for="customCheck2"
                                          >
                                            Message
                                          </label>
                                        </div>
                                      </fieldset>
                                    </li>
                                    <li class="d-inline-block mr-2">
                                      <fieldset>
                                        <div class="custom-control custom-checkbox">
                                          <input
                                            type="checkbox"
                                            class="custom-control-input"
                                            name="customCheck3"
                                            id="customCheck3"
                                          />
                                          <label
                                            class="custom-control-label"
                                            for="customCheck3"
                                          >
                                            Phone
                                          </label>
                                        </div>
                                      </fieldset>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                              <div class="col-12 col-sm-6">
                                <h5 class="mb-1 mt-2 mt-sm-0">
                                  <i class="feather icon-map-pin mr-25"></i>
                                  Address
                                </h5>
                                <div class="form-group">
                                  <div class="controls">
                                    <label>Address Line 1</label>
                                    <input
                                      type="text"
                                      class="form-control"
                                      value="A-65, Belvedere Streets"
                                      required
                                      placeholder="Address Line 1"
                                      data-validation-required-message="This Address field is required"
                                    />
                                  </div>
                                </div>
                                <div class="form-group">
                                  <div class="controls">
                                    <label>Address Line 2</label>
                                    <input
                                      type="text"
                                      class="form-control"
                                      required
                                      placeholder="Address Line 2"
                                      data-validation-required-message="This Address field is required"
                                    />
                                  </div>
                                </div>
                                <div class="form-group">
                                  <div class="controls">
                                    <label>Postcode</label>
                                    <input
                                      type="text"
                                      class="form-control"
                                      required
                                      placeholder="postcode"
                                      value="1868"
                                      data-validation-required-message="This Postcode field is required"
                                    />
                                  </div>
                                </div>
                                <div class="form-group">
                                  <div class="controls">
                                    <label>City</label>
                                    <input
                                      type="text"
                                      class="form-control"
                                      required
                                      value="New York"
                                      data-validation-required-message="This Time Zone field is required"
                                    />
                                  </div>
                                </div>
                                <div class="form-group">
                                  <div class="controls">
                                    <label>State</label>
                                    <input
                                      type="text"
                                      class="form-control"
                                      required
                                      value="New York"
                                      data-validation-required-message="This Time Zone field is required"
                                    />
                                  </div>
                                </div>
                                <div class="form-group">
                                  <div class="controls">
                                    <label>Country</label>
                                    <input
                                      type="text"
                                      class="form-control"
                                      required
                                      value="United Kingdom"
                                      data-validation-required-message="This Time Zone field is required"
                                    />
                                  </div>
                                </div>
                              </div>
                              <div class="col-12 d-flex flex-sm-row flex-column justify-content-end mt-1">
                                <button
                                  type="submit"
                                  class="btn btn-primary glow mb-1 mb-sm-0 mr-0 mr-sm-1"
                                >
                                  Save Changes
                                </button>
                                <button
                                  type="reset"
                                  class="btn btn-outline-warning"
                                >
                                  Reset
                                </button>
                              </div>
                            </div>
                          </form>
                        </div>
                        <div
                          class="tab-pane"
                          id="social"
                          aria-labelledby="social-tab"
                          role="tabpanel"
                        >
                          <form novalidate>
                            <div class="row">
                              <div class="col-12 col-sm-6">
                                <fieldset>
                                  <label>Twitter</label>
                                  <div class="input-group mb-75">
                                    <div class="input-group-prepend">
                                      <span
                                        class="input-group-text feather icon-twitter"
                                        id="basic-addon3"
                                      ></span>
                                    </div>
                                    <input
                                      type="text"
                                      class="form-control"
                                      value="https://www.twitter.com/adoptionism744"
                                      placeholder="https://www.twitter.com/"
                                      aria-describedby="basic-addon3"
                                    />
                                  </div>

                                  <label>Facebook</label>
                                  <div class="input-group mb-75">
                                    <div class="input-group-prepend">
                                      <span
                                        class="input-group-text feather icon-facebook"
                                        id="basic-addon4"
                                      ></span>
                                    </div>
                                    <input
                                      type="text"
                                      class="form-control"
                                      value="https://www.facebook.com/adoptionism664"
                                      placeholder="https://www.facebook.com/"
                                      aria-describedby="basic-addon4"
                                    />
                                  </div>
                                  <label>Instagram</label>
                                  <div class="input-group mb-75">
                                    <div class="input-group-prepend">
                                      <span
                                        class="input-group-text feather icon-instagram"
                                        id="basic-addon5"
                                      ></span>
                                    </div>
                                    <input
                                      type="text"
                                      class="form-control"
                                      value="https://www.instagram.com/adopt-ionism744"
                                      placeholder="https://www.instagram.com/"
                                      aria-describedby="basic-addon5"
                                    />
                                  </div>
                                </fieldset>
                              </div>
                              <div class="col-12 col-sm-6">
                                <label>Github</label>
                                <div class="input-group mb-75">
                                  <div class="input-group-prepend">
                                    <span
                                      class="input-group-text feather icon-github"
                                      id="basic-addon9"
                                    ></span>
                                  </div>
                                  <input
                                    type="text"
                                    class="form-control"
                                    value="https://www.github.com/madop818"
                                    placeholder="https://www.github.com/"
                                    aria-describedby="basic-addon9"
                                  />
                                </div>
                                <label>Codepen</label>
                                <div class="input-group mb-75">
                                  <div class="input-group-prepend">
                                    <span
                                      class="input-group-text feather icon-codepen"
                                      id="basic-addon12"
                                    ></span>
                                  </div>
                                  <input
                                    type="text"
                                    class="form-control"
                                    value="https://www.codepen.com/adoptism243"
                                    placeholder="https://www.codepen.com/"
                                    aria-describedby="basic-addon12"
                                  />
                                </div>
                                <label>Slack</label>
                                <div class="input-group mb-75">
                                  <div class="input-group-prepend">
                                    <span
                                      class="input-group-text feather icon-slack"
                                      id="basic-addon11"
                                    ></span>
                                  </div>
                                  <input
                                    type="text"
                                    class="form-control"
                                    value="@adoptionism744"
                                    placeholder="https://www.slack.com/"
                                    aria-describedby="basic-addon11"
                                  />
                                </div>
                              </div>
                              <div class="col-12 d-flex flex-sm-row flex-column justify-content-end mt-1">
                                <button
                                  type="submit"
                                  class="btn btn-primary glow mb-1 mb-sm-0 mr-0 mr-sm-1"
                                >
                                  Save Changes
                                </button>
                                <button
                                  type="reset"
                                  class="btn btn-outline-warning"
                                >
                                  Reset
                                </button>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div> */}
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>

        <div class="sidenav-overlay"></div>
        <div class="drag-target"></div>
        <Footer />
      </body>
    );
  }
}
export default EditOffer;
