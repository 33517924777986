import React from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import moment from 'moment'
//import Sidebar from "../../Component/Sidebar/Sidebar";
//import Header from "../../Component/Header/Header";
import Footer from "../../Component/Footer/Footer";
import global from "../../global";
import "./Product.css";


const $ = require("jquery");
$.DataTable = require("datatables.net");
var sellerData = {};
class SoldProduct extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      seller: "",
      products: []
    };
  }

  componentWillMount() {
    if (localStorage.getItem("sellerdata")) {
      this.setState({ seller: JSON.parse(localStorage.getItem("sellerdata")) });
      sellerData = JSON.parse(localStorage.getItem("sellerdata"));
    }
    this.listAPI();
  }
  dataTable() {
    $(document).ready(function() {
      $("#list").DataTable();
    });
  }
  listAPI() {
    console.log(this.state.seller);
    var urlencoded = new URLSearchParams();
    console.log(sellerData.id)
    urlencoded.append("id", sellerData.id);

    axios
      .post(global.baseUrl + "sellersoldproductdetails", urlencoded, {
        headers: { "Content-Type": "application/x-www-form-urlencoded" }
      })
      .then(result => {
        console.log(result.data);
        if (result.data.status) {
        //  products = result.data.data;
          this.setState({ products: result.data.data });
          //localStorage.setItem('userdata', JSON.stringify(result.data))
          //this.props.history.push("/setotp/" + result.data.data.id);
     //   this.dataTable()
        }
        this.dataTable()     
      });
  }
  render() {
    return (
      <body
        class="vertical-layout vertical-menu-modern semi-dark-layout 2-columns  navbar-floating footer-static  "
        data-open="click"
        data-menu="vertical-menu-modern"
        data-col="2-columns"
        data-layout="semi-dark-layout"
      >
        {/* <Header />
        <Sidebar /> */}
        <div class="app-content content">
          <div class="content-overlay"></div>
          <div class="header-navbar-shadow"></div>
          <div class="content-wrapper">
            <div class="content-header row">
              <div class="content-header-left col-md-9 col-12 mb-2">
                <div class="row breadcrumbs-top">
                  <div class="col-12">
                    <h2 class="content-header-title float-left mb-0">
                      Sold Products
                    </h2>
                    <div class="breadcrumb-wrapper col-12">
                      <ol class="breadcrumb">
                        <li class="breadcrumb-item">
                          <Link to="/dashboard">Home</Link>
                        </li>
                        <li class="breadcrumb-item active">Sold Products</li>
                      </ol>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="content-body">
              <section id="add-row">
                <div class="row">
                  <div class="col-12">
                    <div class="card">
                      {/* <div class="card-header">
                                    <h4 class="card-title">Add rows</h4>
                                </div> */}
                      <div class="card-content">
                        <div class="card-body">
                          {/* <button id="addRow" class="btn btn-primary mb-2"><i class="feather icon-plus"></i>&nbsp; Add new row</button> */}
                          <div class="table-responsive">
                            <table id="list">
                              <thead>
                                <tr>
                                  <th>Id</th>
                                  <th>User Name</th>
                                  <th>Product Name</th>
                                  <th>Product Image</th>
                                  <th>Sold Date</th>
                                </tr>
                              </thead>
                              <tbody>
                                {this.state.products.map((product, index) => (
                                  <tr key={index}>
                                    <td>{index+1}</td>
                                    
                                    <td>{product.buyerId?
                                    product.buyerId.name:null}
                                    </td>
                                    <td>
                                      {product.productId?
                                      product.productId.product_name:null}
                                    </td>
                                    <td>
                                      {product.productId?
                                      <img
                                        src={product.productId.image}
                                        class="round-p-img"
                                        alt=".."
                                      />:null}
                                    </td>

                                    <td>{moment(product.createdAt).format("DD/MM/YYYY")}</td>
                                  </tr>
                                ))}
                              </tbody>
                              {/* <tfoot>
                                <tr>
                                  <th>Id</th>
                                  <th>User Name</th>
                                  <th>Product Name</th>
                                  <th>Product Image</th>
                                  <th>Sold Date</th>
                                </tr>
                              </tfoot> */}
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
        <div class="sidenav-overlay"></div>
        <div class="drag-target"></div>

        <Footer />
      </body>
    );
  }
}

export default SoldProduct;
