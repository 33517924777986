import React from 'react';
//import ReactDOM from "react-dom";
import axios from 'axios';
import { Link } from 'react-router-dom';
import moment from 'moment';
import LoadingOverlay from 'react-loading-overlay';
//import scriptLoader from 'react-async-script-loader';
//import PropTypes from 'prop-types';
// import Sidebar from "../../Component/Sidebar/Sidebar";
// import Header from "../../Component/Header/Header";
import Footer from '../../Component/Footer/Footer';
import global from '../../global';
import Paypal from './PaypalBtn';
import StripeCheckout from 'react-stripe-checkout';

import './style.css';

const CURRENCY = 'USD';

const fromDollarToCent = (amount) => parseInt(amount * 100);

var seller = '';
var payDisable = true;
var isActive = false;
var isBanner = false;
var bannerMsg = '';
class ViewBanner extends React.Component {
  constructor(props) {
    super(props);
    // window.React = React;
    // window.ReactDOM = ReactDOM;
    // this.state = {
    //   showButton: false,
    //   env: 'sandbox', // Or 'sandbox'
    //   client: {
    //     sandbox:    'AfhXynr5d0EW6OygnhXrEz1sAJvOYJynrFiy3gT0vpeuqrZTepqm0Q5jvOWOIHT5dN2ESSs9xwUdELme', // sandbox client ID
    //     production: 'xxxxxxxxx' // production client ID
    //   },
    //   commit: true, // Show a 'Pay Now' button
    // };
    this.state = {
      id: this.props.match.params.id,
      bannerPages: {},
      bannerTotal: 0,
      paymentMethod: 'credit_debit',
      seller: '',
      bannerproductTotal: 0,
      bannerproduct: {},
    };
    bannerMsg = '';
    payDisable = true;
    isActive = false;
    isBanner = false;
  }
  componentWillMount() {
    if (localStorage.getItem('sellerdata')) {
      seller = JSON.parse(localStorage.getItem('sellerdata'));
      this.setState({ seller: JSON.parse(localStorage.getItem('sellerdata')) });
      //this.dashboardAPI(seller.id);
    }
    var urlencoded = new URLSearchParams();
    urlencoded.append('sellerId', seller.id);
    urlencoded.append('bannerId', this.state.id);

    axios
      .post(global.baseUrl + 'sellergetbannerdetail', urlencoded, {
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      })
      .then((result) => {
        console.log(result.data);
        if (result.data.status) {
          isBanner = true;

          this.setState({ bannerPages: result.data.data });
          let total = 0;
          if (result.data.data) {
            total = result.data.data.price;
          }
          if (total > 0) {
            payDisable = false;
          }
          if (result.data.data.paymentstatus === '1') {
            payDisable = true;
          }
          this.setState({ bannerTotal: total });

          //localStorage.setItem('userdata', JSON.stringify(result.data))
          // this.props.history.push("/offerlist");
        } else {
          bannerMsg = 'No banner available';
          this.setState({ a: '' });
        }
      });

    var urlencoded2 = new URLSearchParams();
    urlencoded2.append('sellerId', seller.id);
    urlencoded2.append('productbannerId', this.state.id);

    axios
      .post(global.baseUrl + 'sellergetproductbannerdetail', urlencoded2, {
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      })
      .then((result) => {
        console.log(result.data);
        if (result.data.status) {
          isBanner = true;

          this.setState({ bannerproduct: result.data.data });
          let total = 0;
          if (result.data.data) {
            total = result.data.data.price;
          }
          if (total > 0) {
            payDisable = false;
          }
          if (result.data.data.paymentstatus === '1') {
            payDisable = true;
          }
          this.setState({ bannerproductTotal: total });
          this.setState({ bannerTotal: total });

          //localStorage.setItem('userdata', JSON.stringify(result.data))
          // this.props.history.push("/offerlist");
        } else {
          bannerMsg = 'No banner available';
          this.setState({ a: '' });
        }
      });
  }
  payPopUpOpen() {}
  // componentDidMount() {
  //   const { isScriptLoaded, isScriptLoadSucceed } = this.props;
  //   if (isScriptLoaded && isScriptLoadSucceed) {
  //     this.setState({ showButton: true });
  //   }
  // }
  // componentWillReceiveProps({ isScriptLoaded, isScriptLoadSucceed }) {
  //   if (!this.state.show) {
  //     if (isScriptLoaded && !this.props.isScriptLoaded) {
  //       if (isScriptLoadSucceed) {
  //         this.setState({ showButton: true });
  //       } else {
  //         console.log('Cannot load Paypal script!');
  //         this.props.onError();
  //       }
  //     }
  //   }
  // }
  handleChange = (event) => {
    this.setState({ paymentMethod: event.target.value });
    console.log(event.target.value);
  };
  onSuccess = (data) => {
    var urlencoded = new URLSearchParams();
    urlencoded.append('sellerId', seller.id);
    if (this.state.bannerPages.id) {
      urlencoded.append('bannerId', this.state.id);
    } else {
      urlencoded.append('bannerproductId', this.state.id);
    }
    urlencoded.append('payment_type', 'paypal');
    urlencoded.append('paymentID', data.paymentID);

    axios
      .post(global.baseUrl + 'SellerBannerPaypal', urlencoded, {
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      })
      .then((result) => {
        this.closeHandler();
        this.props.history.push('/paymentdone');
      });
  };
  closeHandler = () => {
    document.getElementById('success').style.display = 'none';
    for (
      let i = 0;
      i < document.getElementsByClassName('modal-backdrop').length;
      i++
    ) {
      document.getElementsByClassName('modal-backdrop')[i].style.zIndex = '-1';
      document.getElementsByClassName('modal-backdrop')[i].style.width = '0px';
    }
    document.getElementsByClassName('modal-open')[0].style.padding = '0px';
    document.getElementsByTagName('body')[0].classList.remove('modal-open');

    this.props.history.push('/paymentdone');
    //  setTimeout(function() { document.getElementsByClassName('modal').display="none" }, 4000);
  };
  transactionCanceled = () => {
    console.log('transaction cancel');
  };
  transactionError = () => {
    console.log('transaction error');
  };
  onToken = (amount, description, bannerId) => (token) => {
    isActive = true;
    this.setState({ a: '' });
    let urlencoded = new URLSearchParams();
    console.log(token, 'token');
    urlencoded.append('email', token.email);
    urlencoded.append('token', JSON.stringify(token));
    urlencoded.append('description', description);
    urlencoded.append('source', token.id);
    urlencoded.append('amount', parseInt(amount * 100));
    urlencoded.append('currency', CURRENCY);
    urlencoded.append('cardName', token.card.name);
    urlencoded.append('sellerId', seller.id);
    if (this.state.bannerPages.id) {
      urlencoded.append('bannerId', this.state.id);
    } else {
      urlencoded.append('bannerproductId', this.state.id);
    }
    urlencoded.append('payment_type', 'credit_debit');
    axios
      .post(global.baseUrl + 'SellerStripePayment', urlencoded)
      .then((result) => {
        console.log(result);
        this.props.history.push('/paymentdone');

        if (result.data.status) {
          isActive = false;
          this.setState({ a: '' });
        }
        //successPayment();
      })
      .catch();
  };

  // render() {
  //       const payment = () => paypal.rest.payment.create(this.props.env, this.props.client, {
  //         transactions: [
  //                       { amount: { total: this.props.total, currency: this.props.currency } },
  //         ],
  //       });

  //       const onAuthorize = (data, actions) => actions.payment.execute().then(() => {
  //         const payment = Object.assign({}, this.props.payment);
  //         payment.paid = true;
  //         payment.cancelled = false;
  //         payment.payerID = data.payerID;
  //         payment.paymentID = data.paymentID;
  //         payment.paymentToken = data.paymentToken;
  //         payment.returnUrl = data.returnUrl;
  //         this.props.onSuccess(payment);
  //       });

  //       let ppbtn = '';
  //       if (this.state.showButton) {
  //         ppbtn = (<paypal.Button.react
  //           env={this.state.env}
  //           client={this.state.client}
  //           payment={payment}
  //           commit
  //           onAuthorize={onAuthorize}
  //           onCancel={this.props.onCancel}
  //         />);
  //       }
  //       return <div>{ppbtn}</div>;
  //     }
  //   }

  //   PaypalButton.propTypes = {
  //     currency: PropTypes.string.isRequired,
  //     total: PropTypes.number.isRequired,
  //     client: PropTypes.object.isRequired,
  //   };

  //   PaypalButton.defaultProps = {
  //     env: 'sandbox',
  //     onSuccess: (payment) => {
  //       console.log('The payment was succeeded!', payment);
  //     },
  //     onCancel: (data) => {
  //       console.log('The payment was cancelled!', data);
  //     },
  //     onError: (err) => {
  //       console.log('Error loading Paypal script!', err);
  //     },

  render() {
    return (
      <body
        class="vertical-layout vertical-menu-modern semi-dark-layout 2-columns  navbar-floating footer-static  "
        data-open="click"
        data-menu="vertical-menu-modern"
        data-col="2-columns"
        data-layout="semi-dark-layout"
      >
        <LoadingOverlay
          active={isActive}
          spinner
          text="Please wait we are processing your payment"
        ></LoadingOverlay>
        <div class="app-content content">
          <div class="content-overlay"></div>
          <div class="header-navbar-shadow"></div>
          <div class="content-wrapper">
            <div class="content-header-left col-md-9 col-12 mb-2">
              <div class="row breadcrumbs-top">
                <div class="col-12">
                  <h2 class="content-header-title float-left mb-0">
                    View Banner
                  </h2>
                  <div class="breadcrumb-wrapper col-12">
                    <ol class="breadcrumb">
                      <li class="breadcrumb-item">
                        <Link to="/bannerlist">Banners</Link>
                      </li>
                      <li class="breadcrumb-item active">View Banner</li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>
            <div class="content-body">
              <section id="dashboard-analytics">
                <div class="card">
                  <div class="card-body">
                    <div
                      class="tab-pane active"
                      id="account"
                      aria-labelledby="account-tab"
                      role="tabpanel"
                      style={{overflowX:"auto"}}
                    >
                      {this.state.bannerPages.id ? (
                        ''
                      ) : this.state.bannerproduct.id ? (
                        ''
                      ) : (
                        <div class="col-lg-11 col-12 pb-2">
                          <label className="b-label">Banner Detail </label>
                          <p>
                            {' '}
                            (Your banner will not run until they are reviewed by
                            GaragaLane.We review most ads within 3 business
                            days)
                          </p>
                        </div>
                      )}
                      {this.state.bannerPages.id ? (
                        <>
                          <div class="row mb-1">
                            <div class="col-lg-11 col-12">
                              {this.state.bannerPages ? (
                                <table class="table table-bordered">
                                  <thead>
                                    <tr>
                                      <th>Duration</th>
                                      <th>Desired location</th>
                                      <th>Cost</th>
                                      {/* <th>Days</th> */}
                                      {/* <th>Total</th> */}
                                      <th>Status</th>
                                      <th>Reason</th>
                                      <th>View</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr>
                                      <td>
                                        {moment(
                                          this.state.bannerPages.start_date
                                        ).format('DD-MM-YYYY')}{' '}
                                        to{' '}
                                        {moment(
                                          this.state.bannerPages.end_date
                                        ).format('DD-MM-YYYY')}
                                      </td>
                                      <td>
                                        {this.state.bannerPages.banner_location
                                          ? this.state.bannerPages
                                              .banner_location
                                          : null}
                                      </td>
                                      <td>${this.state.bannerPages.price}</td>
                                      {/* <td>{page.days}</td>
                                      <td>${page.price}</td> */}
                                      <td>
                                        {this.state.bannerPages
                                          .ApprovedStatus === '1' ? (
                                          <b>Approved</b>
                                        ) : this.state.bannerPages
                                            .ApprovedStatus === '0' ? (
                                          <b>Denied</b>
                                        ) : (
                                          <b>Pending</b>
                                        )}
                                      </td>
                                      <td>{this.state.bannerPages.reason}</td>
                                      <td>
                                        <button
                                          data-toggle="modal"
                                          data-target="#success1"
                                          class="btn btn-primary float-right mr-1 btn-inline waves-effect waves-light login-btn "
                                          style={{ padding: '6px' }}
                                        >
                                          View
                                        </button>
                                      </td>
                                    </tr>

                                    <tr>
                                      <td
                                        colSpan="4"
                                        style={{ textAlign: 'right' }}
                                      >
                                        <span style={{ fontWeight: 'bold' }}>
                                          Total
                                        </span>
                                      </td>
                                      <td colSpan={3}>
                                        <b>${this.state.bannerTotal}</b>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              ) : null}
                            </div>
                          </div>
                        </>
                      ) : this.state.bannerproduct.id ? (
                        <>
                          <div class="row mb-1">
                            <div class="col-lg-11 col-12">
                              {this.state.bannerproduct ? (
                                <table class="table table-bordered">
                                  <thead>
                                    <tr>
                                      <th>Duration</th>
                                      <th>Desired location</th>
                                      <th>Cost</th>
                                      {/* <th>Days</th> */}
                                      {/* <th>Total</th> */}
                                      <th>Status</th>
                                      <th>Reason</th>
                                      <th>View</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr>
                                      <td>
                                        {moment(
                                          this.state.bannerproduct.start_date
                                        ).format('DD-MM-YYYY')}{' '}
                                        to{' '}
                                        {moment(
                                          this.state.bannerproduct.end_date
                                        ).format('DD-MM-YYYY')}
                                      </td>
                                      <td>
                                        {this.state.bannerproduct
                                          .banner_location
                                          ? this.state.bannerproduct
                                              .banner_location
                                          : null}
                                      </td>
                                      <td>${this.state.bannerproduct.price}</td>
                                      {/* <td>{page.days}</td>
                                    <td>${page.price}</td> */}
                                      <td>
                                        {this.state.bannerproduct
                                          .ApprovedStatus === '1' ? (
                                          <b>Approved</b>
                                        ) : this.state.bannerproduct
                                            .ApprovedStatus === '0' ? (
                                          <b>Denied</b>
                                        ) : (
                                          <b>Pending</b>
                                        )}
                                      </td>
                                      <td>{this.state.bannerproduct.reason}</td>
                                      <td>
                                        <button
                                          data-toggle="modal"
                                          data-target="#success1"
                                          class="btn btn-primary float-right mr-1 btn-inline waves-effect waves-light login-btn "
                                          style={{ padding: '6px' }}
                                        >
                                          View
                                        </button>
                                      </td>
                                    </tr>

                                    <tr>
                                      <td
                                        colSpan="4"
                                        style={{ textAlign: 'right' }}
                                      >
                                        <span style={{ fontWeight: 'bold' }}>
                                          Total
                                        </span>
                                      </td>
                                      <td colSpan={3}>
                                        <b>${this.state.bannerTotal}</b>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              ) : null}
                            </div>
                          </div>
                        </>
                      ) : (
                        ''
                      )}
                      <div class="modal-success mr-1 mb-1 d-inline-block ">
                        <div
                          class="modal fade text-left "
                          id="success"
                          tabindex="-1"
                          role="dialog"
                          aria-labelledby="myModalLabel110"
                          aria-hidden="true"
                        >
                          <div
                            class="modal-dialog modal-dialog-centered modal-dialog-scrollable"
                            role="document"
                          >
                            <div class="modal-content vertical-modal">
                              <div class="modal-header bg-success white">
                                <h5 class="modal-title" id="myModalLabel110">
                                  Select Payment Method
                                </h5>
                                <button
                                  type="button"
                                  class="close"
                                  data-dismiss="modal"
                                  aria-label="Close"
                                >
                                  <span aria-hidden="true">&times;</span>
                                </button>
                              </div>
                              <div
                                class="modal-body"
                                style={{ paddingBottom: '0px' }}
                              >
                                <ul class="list-unstyled mb-0">
                                  <li>
                                    <div class="vs-radio-con model-resize-div">
                                      <Paypal
                                        totalPay={this.state.bannerTotal}
                                        onSuccess={this.onSuccess}
                                        transactionError={this.transactionError}
                                        transactionCanceled={
                                          this.transactionCanceled
                                        }
                                      />
                                      {/* <span class="vs-radio">
                                          <span class="vs-radio--border"></span>
                                          <span class="vs-radio--circle"></span>
                                        </span>
                                        Credit Debit */}
                                    </div>
                                  </li>
                                  <li>
                                    <fieldset>
                                      <div class="vs-radio-con model-resize-div">
                                        <StripeCheckout
                                          stripeKey="pk_test_51GtSo6BPY6CGrhq8PbbsO8Cd70i2F9wD7u4qk4gwCugvjMakwViTZIfsUVwmnU6qfQacoJUCpaGFSnvognvlK3HI00CIAYrPMY"
                                          token={this.onToken(
                                            this.state.bannerTotal,
                                            'Banner Payment',
                                            this.state.id
                                          )}
                                          name="Garage Lane"
                                          email={seller.email}
                                          amount={fromDollarToCent(
                                            this.state.bannerTotal
                                          )}
                                          currency={CURRENCY}
                                        >
                                          <button
                                            className="btn btn-success btn-primary resize-credit-debit-btn float-right mr-1 btn-inline waves-effect waves-light pay-btn"
                                            data-dismiss="modal"
                                          >
                                            Credit/Debit Card
                                          </button>
                                        </StripeCheckout>
                                        {/* <span class="vs-radio">
                                          <span class="vs-radio--border"></span>
                                          <span class="vs-radio--circle"></span>
                                        </span>
                                        Credit Debit */}
                                      </div>
                                    </fieldset>
                                  </li>
                                  {/* <li>
                                    
                                    <fieldset>
                                      <div class="vs-radio-con">
                                        <input
                                          type="radio"
                                          value="paypal"
                                          onChange={this.handleChange}
                                          checked={
                                            this.state.paymentMethod ===
                                            "paypal"
                                              ? true
                                              : false
                                          }
                                        />
                                        <span class="vs-radio">
                                          <span class="vs-radio--border"></span>
                                          <span class="vs-radio--circle"></span>
                                        </span>
                                        PayPal
                                      </div>
                                    </fieldset>
                                  </li>
                                  <li>
                                    <fieldset>
                                      <div class="vs-radio-con">
                                        <input
                                          type="radio"
                                          value="venmo"
                                          onChange={this.handleChange}
                                          checked={
                                            this.state.paymentMethod === "venmo"
                                              ? true
                                              : false
                                          }
                                        />
                                        <span class="vs-radio">
                                          <span class="vs-radio--border"></span>
                                          <span class="vs-radio--circle"></span>
                                        </span>
                                        Venmo
                                      </div>
                                    </fieldset>
                                  </li> */}
                                </ul>
                                <p class="error-msg"> </p>
                              </div>
                              <div
                                class="modal-footer modal-resize-footer"
                                style={{ paddingTop: '0px' }}
                              >
                                <button
                                  type="button"
                                  class="btn btn-success"
                                  data-dismiss="modal"
                                  onClick={() => this.payPopUpOpen()}
                                >
                                  OK
                                </button>
                                <button
                                  type="button"
                                  class="btn btn-success "
                                  data-dismiss="modal"
                                  aria-label="Close"
                                >
                                  Cancel
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* view model */}
                      <div class="modal-success1 mr-1 mb-1 d-inline-block ">
                        <div
                          class="modal fade text-left "
                          id="success1"
                          tabindex="-1"
                          role="dialog"
                          aria-labelledby="myModalLabel110"
                          aria-hidden="true"
                        >
                          <div
                            class="modal-dialog modal-dialog-centered modal-dialog-scrollable"
                            role="document"
                          >
                            <div class="modal-content vertical-view-model">
                              <div class="modal-header bg-success white">
                                <h5 class="modal-title" id="myModalLabel110">
                                  Sponsor Ads
                                </h5>
                                <button
                                  type="button"
                                  class="close"
                                  data-dismiss="modal"
                                  aria-label="Close"
                                >
                                  <span aria-hidden="true">&times;</span>
                                </button>
                              </div>
                              <div
                                class="modal-body view-model-body"
                                style={{ paddingBottom: '0px' }}
                              >
                                <img
                                  width="350"
                                  height="200"
                                  src={
                                    this.state.bannerPages.banner_image_app
                                      ? this.state.bannerPages.banner_image_app
                                      : this.state.bannerPages.banner_image_web
                                      ? this.state.bannerPages.banner_image_web
                                      : this.state.bannerproduct.image
                                      ? this.state.bannerproduct.image
                                      : this.state.bannerproduct.Link
                                  }
                                  alt=".."
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-11 d-flex flex-sm-row flex-column justify-content-end mt-1">
                          <button
                            type="button"
                            data-toggle="modal"
                            data-target="#success"
                            disabled={payDisable}
                            class="btn btn-primary float-right mr-1 btn-inline waves-effect waves-light login-btn "
                          >
                            Payment
                          </button>
                          <button
                            type="reset"
                            onClick={this.resetHandler}
                            class="btn btn-outline-primary float-left btn-inline res-btn"
                          >
                            Reset
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>

        <div class="sidenav-overlay"></div>
        <div class="drag-target"></div>
        <Footer />
      </body>
    );
  }
}
export default ViewBanner;
//export default scriptLoader('https://www.paypalobjects.com/api/checkout.js')(PaypalButton);
